import React from "react";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import { withStyles } from "@material-ui/core/styles";
import { BlStockStatus, BlState } from "./../../../../models/Bl";
import { SPECIAL_THREE_PLS, WMS_STATUS } from "@constants";
import { connect } from "react-redux";
import { setSelectedBls } from "../actions/batchActions";
import {
  toggleDetailDialog,
  changeGroupType,
  changeShipMethod,
} from "../../bl_group/actions";
import memoize from "memoize-one";
import { openNotiDialog } from "../../shared";

const styles = (theme) => ({
  text: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: "Noto Sans, sans-serif",
  },
  baseButton: {
    fontFamily: "Noto Sans, sans-serif",
    textTransform: "none",
    margin: "0 8px 8px 0",
  },
  confirmOkButton: {
    color: "#fff",
    backgroundColor: "#03a9f4",
  },
  confirmPickedButton: {
    color: "#fff",
    backgroundColor: "#F57F17",
  },
  confirmImportedButton: {
    color: "#fff",
    backgroundColor: "#03a9f4",
  },
  confirmReturnedButton: {
    color: "#fff",
    backgroundColor: "#ba68c8",
  },
  note: {
    color: "indianred",
    fontSize: 13,
  },
});

const getBlsList = memoize((bls) => {
  let newList = [];
  if (bls && Object.keys(bls).length > 0) {
    for (let page in bls) {
      for (let i = 0; i < bls[page].length; i++) {
        const element = bls[page][i];
        newList.push(element);
      }
    }
  }
  return newList;
});

class BatchActionButtons extends React.Component {
  renderPickedBtn = (list) => {
    const blInitStockStatus = list.filter(
      (x) => x.stockStatus === BlStockStatus.init
    );
    const blInitStatus = list.filter((x) => x.state === BlState.init);
    const blWms = [...new Set(list.map((x) => x.exportSource))];
    const { classes, toggleBatchDialog } = this.props;
    let title = "Giao hàng cho 3PLs nhanh";
    let isStockRequest = false;
    if (blWms.length === 1 && blWms[0].toLowerCase() === "wms") {
      if (
        blInitStockStatus.length === list.length &&
        blInitStatus.length === list.length
      ) {
        isStockRequest = true;
        title = "Lấy hàng nhanh";
      }
    }
    return (
      <Button
        variant="contained"
        className={classes.confirmOkButton}
        onClick={() =>
          toggleBatchDialog(
            isStockRequest ? "confirmStockRequest" : "confirmPicked"
          )
        }
      >
        {title}
      </Button>
    );
  };

  createAdidiTransport = () => {
    const {
      setSelectedBls,
      bls,
      toggleDetailDialog,
      changeGroupType,
      openNotiDialog,
      settings,
    } = this.props;
    const { BL_GROUP_LIMIT } = settings;
    const list = getBlsList(bls);
    if (list.length > parseInt(BL_GROUP_LIMIT)) {
      openNotiDialog(
        "failed",
        <span>Chỉ được gộp tối đa {BL_GROUP_LIMIT} đơn</span>
      );
    } else {
      changeGroupType(list[0].requestType.toLowerCase());
      changeShipMethod("adidi");
      setSelectedBls(list);
      toggleDetailDialog(true);
    }
  };

  render() {
    const {
      showMultipleChoices,
      bls,
      totalBls,
      classes,
      toggleBatchDialog,
      settings,
    } = this.props;
    const { ALLOW_GROUP_HUB, BL_GROUP_LIMIT } = settings;

    /* get list of bl */
    let list = getBlsList(bls);
    return (
      <Collapse in={showMultipleChoices} unmountOnExit>
        <p className={classes.text}>Đã chọn {totalBls} vận đơn.</p>
        {totalBls > 0 && canBatchConfirmOK(list) && (
          <Button
            variant="contained"
            className={`${classes.baseButton} ${classes.confirmOkButton}`}
            onClick={() => toggleBatchDialog("confirmOK")}
          >
            Xác nhận vận chuyển thành công
          </Button>
        )}
        {totalBls > 0 &&
          canBatchConfirmPicked(list) &&
          this.renderPickedBtn(list)}

        {totalBls > 0 && canBatchConfirmImport(list) && (
          <Button
            variant="contained"
            className={`${classes.baseButton} ${classes.confirmImportedButton}`}
            onClick={() => toggleBatchDialog("confirmImport")}
          >
            Xác nhận nhập hub
          </Button>
        )}
        {totalBls > 0 && canBatchConfirmReturn(list) && (
          <Button
            variant="contained"
            className={`${classes.baseButton} ${classes.confirmReturnedButton}`}
            onClick={() => toggleBatchDialog("confirmReturn")}
          >
            Xác nhận hub hoàn hàng
          </Button>
        )}

        {totalBls > 0 && canCreateAdidiTransport(list, ALLOW_GROUP_HUB) && (
          <div>
            <Button
              variant="contained"
              color="primary"
              className={classes.baseButton}
              onClick={() => this.createAdidiTransport()}
            >
              Tạo lộ trình với Adidi
            </Button>
            <span className={classes.note}>
              *Chỉ được gộp tối đa {BL_GROUP_LIMIT} đơn
            </span>
          </div>
        )}
      </Collapse>
    );
  }
}

const mapStateToProps = (state) => ({
  settings: state.admin.shared.settings,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedBls: (bls) => dispatch(setSelectedBls(bls)),
  toggleDetailDialog: (state) => dispatch(toggleDetailDialog(state)),
  changeGroupType: (groupType) => dispatch(changeGroupType(groupType)),
  changeShipMethod: (shipMethod) => dispatch(changeShipMethod(shipMethod)),
  openNotiDialog: (type, text) => dispatch(openNotiDialog(type, text)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(BatchActionButtons)
);

const commonCheck = (list) => {
  const threePls = list.map((x) => x.currentTransport.shipMethod);
  for (let i = 0; i < threePls.length; i++) {
    if (SPECIAL_THREE_PLS.includes(threePls[i])) {
      return false;
    }
  }
  return true;
};

const canBatchConfirmOK = (list) => {
  let can = false;
  const canConfirmOKList = list.filter((x) => x.canConfirmOK);
  if (canConfirmOKList.length === list.length) {
    can = true;
  }
  return can && commonCheck(list);
};

/* Điều kiện gộp
  - Cùng trạng thái: chờ lấy hàng
    + Riêng CKNB: cùng trạng thái stock_status: init hoặc wms_to_lo_request
  - Cùng đơn vị vận chuyển

  Hiển thị:
  - VĐKH (chờ lấy hàng) --> Giao hàng cho 3PLs nhanh
  - CKNB (chờ lấy hàng + init) --> Lấy hàng nhanh
  - CKNB (chờ lấy hàng + wms_to_lo_request) --> Giao hàng cho 3PLs nhanh
*/
const canBatchConfirmPicked = (list) => {
  let can = false;
  const canConfirmPickedList = list.filter((x) => x.canConfirmPicked);
  const blTypeSet = [...new Set(list.map((x) => x.requestType))];
  const blStockStatus = [...new Set(list.map((x) => x.stockStatus))];
  const blPackingStatus = list.filter((x) => x.wms_status === WMS_STATUS.pack);
  if (
    canConfirmPickedList.length === list.length &&
    blTypeSet.length === 1 &&
    blStockStatus.length === 1 &&
    blPackingStatus.length === list.length
  ) {
    can = true;
  }
  return can && commonCheck(list);
};

const canBatchConfirmReturn = (list) => {
  let can = false;
  const ableToConfirmReturnList = list.filter((x) => x.ableToConfirmReturn);
  if (ableToConfirmReturnList.length === list.length) {
    can = true;
  }
  return can && commonCheck(list);
};

const canBatchConfirmImport = (list) => {
  let can = false;
  const canConfirmImportList = list.filter((x) => x.canConfirmImportOK);
  if (canConfirmImportList.length === list.length) {
    can = true;
  }
  return can && commonCheck(list);
};

const canCreateAdidiTransport = (list, allowGroupHub) => {
  let can = false;
  const canCreateAdidiTransportList = list.filter((x) =>
    x.canCreateAdidiTransport(allowGroupHub)
  );
  if (canCreateAdidiTransportList.length === list.length) {
    can = true;
  }
  return can;
};
