import React from "react";
import Select from "react-select";
import Dialog from "../Dialog";

class ShipMethodSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
    };
  }

  toggleErrorDialog = () =>
    this.setState({
      error: !this.state.error,
    });

  render() {
    const { error } = this.state;
    const {
      shipMethod,
      threePls,
      all,
      isClearable = true,
      disabled = false,
    } = this.props;
    const shipMethods = threePls.map((x) => ({
      value: x.code_name,
      label: x.estimate_fee
        ? `${x.name} (${x.estimate_fee.toLocaleString("en-US")}đ)`
        : x.name,
    }));
    if (all) {
      shipMethods.push({ label: "Kỹ thuật viên", value: "ktv" });
    }
    return (
      <div>
        <Select
          value={shipMethod}
          onChange={(v) => this.props.changeShipMethod(v ? v.value : "")}
          options={shipMethods}
          placeholder="Chọn phương thức vận chuyển"
          style={{ height: 40 }}
          clearable={isClearable}
          disabled={disabled}
        />
        <Dialog
          open={error}
          closeDialog={this.toggleErrorDialog}
          title="Có lỗi xảy ra!"
        >
          Khu vực này chưa được hỗ trợ vận chuyển qua VNPost. Vui lòng chọn
          phương thức vận chuyển khác hoặc liên hệ Logistic team để được hỗ trợ!
        </Dialog>
      </div>
    );
  }
}

export default ShipMethodSelection;
