import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { getBusLinesList } from "./actions";
import { BusLineTable, BusLineFilter } from "./components";

const styles = (theme) => ({
  text: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
  },
});

class BusLineManagement extends React.Component {
  handleShortKey = (e) => {
    const { getBusLinesList } = this.props;
    const key = e.keyCode;
    const alt = e.altKey ? e.altKey : key === 18 ? true : false;
    if (key === 65 && alt) {
      this.setState({ open: true, selected: null });
    }
    if (key === 70 && alt) {
      e.preventDefault();
      getBusLinesList();
    }
  };
  async componentDidMount() {
    const { getBusLinesList } = this.props;
    //TODO: get bus lines
    await getBusLinesList();
    window.addEventListener("keydown", this.handleShortKey);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleShortKey);
  }

  handleSearchOnEnter = (e) => {
    const { getBusLinesList } = this.props;
    if (e.keyCode === 13) {
      getBusLinesList();
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Grid container className={classes.text}>
          <Grid item md={12}>
            <BusLineFilter />
            <BusLineTable />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getBusLinesList: () => dispatch(getBusLinesList()),
});

export default withStyles(styles)(
  connect(() => ({}), mapDispatchToProps)(BusLineManagement)
);
