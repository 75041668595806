import React from "react";
import moment from "moment";
import { getShipperSummaryFuelCostReport, openNotiDialog } from "@shared";
import { Button } from "@material-ui/core";

export const changeFilter = (data) => ({
  type: "SHIPPER_SUMMARY_FUEL_COST_REPORT_CHANGE_FILTER",
  payload: data,
});

export const onSearch = () => async (dispatch, getState) => {
  const state = getState().admin.ShipperSummaryFuelCostReport;
  const minDate = new Date("08/10/2019");

  if (state.filter.start_date < minDate) {
    const customActions = (props) => {
      return (
        <React.Fragment>
          <a href="/transports/export-request">
            <Button style={{ color: "#0078d7" }} onClick={props.onClose}>
              <i className="fas fa-sign-in-alt" />
              &nbsp;&nbsp;Đi tới trang
            </Button>
          </a>
          <Button onClick={props.onClose}>OK</Button>
        </React.Fragment>
      );
    };
    dispatch(
      openNotiDialog(
        "alert",
        <div>
          <span>Vui lòng chọn </span>
          <span style={{ fontWeight: "bold", color: "indianred" }}>
            xuất danh sách theo yêu cầu
          </span>
          <span> để xem được cách tính cũ</span>
        </div>,
        null,
        "custom",
        null,
        customActions
      )
    );
  } else {
    const params = {
      ...state.filter,
    };
    params.start_date = moment(params.start_date).format("YYYY-MM-DD");
    params.end_date = moment(params.end_date).format("YYYY-MM-DD");
    dispatch(changeLoading(true));
    const wait = (ms) => new Promise((rs, rj) => setTimeout(() => rs(), ms));
    const response = await getShipperSummaryFuelCostReport(params);
    await wait(2000);
    dispatch(changeLoading(false));
    dispatch(processResponse(response.data));
  }
};

export const processResponse = (response) => (dispatch) => {
  if (response.code !== 200) {
    dispatch(openNotiDialog("failed", response.message));
  } else {
    if (response.data.link !== null) {
      window.location.href = response.data.link;
    } else {
      dispatch(openNotiDialog("success", response.message));
    }
  }
};

export const changeLoading = (state) => ({
  type: "SHIPPER_SUMMARY_FUEL_COST_REPORT_CHANGE_LOADING",
  payload: state,
});
