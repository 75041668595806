import React from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import {
  RequestTypeFilter,
  BlStatusFilter,
  ShipMethodFilter,
  ShipperFilter,
  FromHubFilter,
  ToHubFilter,
  DeliveryTypeFilter,
  CurrentGoodsHubFilter,
  AreaFilter,
  BlCodFilter,
  SaleChannelFilter,
  OtherSystemStatusFilter,
  ShipperStatusFilter,
  FilterTab,
} from "../components";
import {
  setFilter,
  clearFilters,
  fetchBlList,
  fetchAllDistrict,
  fetchUserFilter,
  getCategoriesList,
  loadFilter,
  setTab,
} from "../actions";
import { ShipMethod } from "../../../../models";
import CreateTransportUserFilter from "./../components/filters/CreateTransportUserFilter";
import { Collapse, Tooltip } from "@material-ui/core";
import { DateTimeRangeSelection, BookmarkBar, SellerSelection } from "@shared";
import EditShipdateReasons from "../components/filters/EditShipdateReasons";
import memoize from "memoize-one";
import { paramBuilder } from "@utils";
import { updateUserFilter } from "@api";
import { CategoriesSelection } from "../../shared";
import { filterStyles as styles } from "./styles";

class FilterContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidate: false,
      focusInput: false,
      showBookmark: false,
      collapsed: false,
    };
  }

  async componentDidMount() {
    this.props.fetchAllDistrict();
    this.props.fetchUserFilter();
    this.props.getCategories();
    window.addEventListener("keydown", this.handleShortKey);
  }

  handleShortKey = (e) => {
    const key = e.keyCode;
    const alt = e.altKey ? e.altKey : key === 17;
    if (key === 66 && alt) {
      this.setState({ showBookmark: !this.state.showBookmark });
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.filter.keyword !== this.props.filter.keyword) {
      return false;
    }
    return true;
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleShortKey);
  }

  formatShipMethodList = memoize((list, seller_id) => {
    let newList = seller_id
      ? list.filter((x) => seller_id.includes(x.seller_id.toString()))
      : list;
    newList = newList.map((x) => ({ label: x.name, value: x.code_name }));
    if (seller_id.includes("1") || !seller_id) {
      newList.push({ label: "Kĩ thuật viên", value: "ktv" });
    }
    return newList;
  });

  changeFilter = async (filter) => {
    const { setTab, fetchBlList, loadFilter, visibleFilter, fetchUserFilter } =
      this.props;

    if (!filter.display_state) {
      const body = {
        display_state: true,
      };
      await updateUserFilter(filter.id, body);
      const filters = await fetchUserFilter();
      const visible = filters.some(
        (item) => item.id === filter.id && item.display_state
      );
      if (visible) {
        setTab(filters.length + 2);
        loadFilter(filters.length + 2);
      }
    } else {
      const index = visibleFilter.findIndex((x) => x.id === filter.id);
      setTab(index + 2);
      loadFilter(index + 2);
      fetchBlList();
    }
  };

  handleSaveFilter = async () => {
    const { setTab, savedFilter, fetchUserFilter } = this.props;
    await fetchUserFilter();
    const available = savedFilter.filter((x) => x.display_state).length;
    setTab(available + 2);
  };

  beforeDeleteCallback = async (filter) => {
    const { visibleFilter, currentTab } = this.props;
    const index = visibleFilter.findIndex((x) => x.id === filter.id);
    if (currentTab === index + 2) {
      setTab(0);
      setFilter("quickFilter", "all");
      fetchBlList();
    }
  };

  render() {
    const {
      filter,
      hubs,
      ktvList,
      user,
      classes,
      allThreePls,
      setFilter,
      districtList,
      rejectReasons,
      savedFilter,
    } = this.props;
    const { collapsed } = this.state;

    return (
      <React.Fragment>
        <div id="blListFilter">
          <div className={classes.header}>
            <FilterTab
              collapsed={collapsed}
              setCollapse={(visibility) =>
                this.setState({ collapsed: visibility })
              }
            />
          </div>
          <div className={classes.container}>
            <Collapse in={!collapsed} id="collapsed">
              <div style={{ display: "flex" }}>
                <div style={{ padding: "1rem", flex: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item md={2} sm={4} xs={12}>
                      <span className={classes.label}>Loại vận đơn</span>
                      <RequestTypeFilter
                        value={filter.requestType}
                        multi={true}
                        onChange={(e) => setFilter("requestType", e ? e : "")}
                        sellerId={filter.seller_id}
                      />
                    </Grid>
                    <Grid item md={2} sm={4} xs={12}>
                      <span className={classes.label}>Loại giao hàng</span>
                      <DeliveryTypeFilter
                        seller_id={filter.seller_id}
                        value={filter.deliveryTypes}
                        setFilter={setFilter}
                      />
                    </Grid>
                    <Grid item md={2} sm={4} xs={12}>
                      <span className={classes.label}>Kênh bán hàng</span>
                      <SaleChannelFilter
                        seller_id={filter.seller_id}
                        value={filter.channel}
                        onChange={(e) => setFilter("channel", e)}
                      />
                    </Grid>
                    <Grid item md={2} sm={4} xs={12}>
                      <span className={classes.label}>Theo COD</span>
                      <BlCodFilter
                        value={filter.is_cod_bl}
                        onChange={(e) => setFilter("is_cod_bl", e)}
                        label="COD"
                        placeholder=""
                      />
                    </Grid>

                    <Grid item md={2} sm={4} xs={12}>
                      <span className={classes.label}>Ngày tạo lộ trình</span>
                      <DateTimeRangeSelection
                        value={[
                          filter.createTransportFrom,
                          filter.createTransportTo,
                        ]}
                        placeholder="Chọn ngày..."
                        label="createTransportFrom"
                        onChange={(e) => {
                          setFilter("createTransportFrom", e[0]);
                          setFilter("createTransportTo", e[1]);
                        }}
                      />
                    </Grid>
                    <Grid item md={2} sm={4} xs={12}>
                      <span className={classes.label}>Người tạo lộ trình</span>
                      <CreateTransportUserFilter
                        value={filter.transportCreatedUser}
                        ktvList={ktvList}
                        setFilter={setFilter}
                        multi={true}
                        label="transportCreatedUser"
                        placeholder="Chọn..."
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={2} sm={4} xs={12}>
                      <span className={classes.label}>Trạng thái vận đơn</span>
                      <BlStatusFilter
                        filter={filter}
                        value={filter.status}
                        setFilter={setFilter}
                        fetchBlList={this.props.fetchBlList}
                      />
                    </Grid>
                    <Grid item md={2} sm={4} xs={12}>
                      <span className={classes.label}>Trạng thái khác</span>
                      <OtherSystemStatusFilter
                        value={filter.packed_status}
                        onChange={(e) => setFilter("packed_status", e)}
                      />
                    </Grid>
                    <Grid item md={2} sm={4} xs={12}>
                      <span className={classes.label}>Trạng thái KTV</span>
                      <ShipperStatusFilter
                        filter={filter}
                        value={filter.shipper_state}
                        setFilter={setFilter}
                      />
                    </Grid>
                    <Grid item md={2} sm={4} xs={12}>
                      <span className={classes.label}>Đơn vị vận chuyển</span>
                      <ShipMethodFilter
                        seller_id={filter.seller_id}
                        value={filter.shipMethod}
                        setFilter={setFilter}
                        options={this.formatShipMethodList(
                          allThreePls,
                          filter.seller_id
                        )}
                      />
                    </Grid>
                    <Grid item md={2} sm={4} xs={12}>
                      <span className={classes.label}>
                        Kĩ thuật viên giao hàng
                      </span>
                      <ShipperFilter
                        value={filter.shipperId}
                        ktvList={ktvList}
                        onChange={(ktv) =>
                          setFilter("shipperId", ktv ? ktv : "")
                        }
                        disabled={!filter.shipMethod.includes(ShipMethod.ktv)}
                      />
                    </Grid>
                    <Grid item md={2} sm={4} xs={12}>
                      <span className={classes.label}>Ngày hẹn giao</span>
                      <DateTimeRangeSelection
                        value={[filter.shipDateFrom, filter.shipDateTo]}
                        placeholder="Chọn ngày"
                        label="shipDateFrom"
                        onChange={(e) => {
                          setFilter("shipDateFrom", e[0]);
                          setFilter("shipDateTo", e[1]);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={2} sm={4} xs={12}>
                      <span className={classes.label}>Khu vực gửi đến</span>
                      <AreaFilter
                        value={filter.to_area}
                        options={districtList}
                        onChange={(e) => setFilter("to_area", e)}
                      />
                    </Grid>
                    <Grid item md={2} sm={4} xs={12}>
                      <span className={classes.label}>Kho gửi</span>
                      <FromHubFilter
                        tab={filter.tab}
                        user={user}
                        value={filter.fromHub}
                        hubs={hubs}
                        setFilter={setFilter}
                        sellerId={filter.seller_id}
                      />
                    </Grid>
                    <Grid item md={2} sm={4} xs={12}>
                      <span className={classes.label}>Kho nhận</span>
                      <ToHubFilter
                        tab={filter.tab}
                        user={user}
                        value={filter.toHub}
                        hubs={hubs}
                        setFilter={setFilter}
                        sellerId={filter.seller_id}
                      />
                    </Grid>
                    <Grid item md={2} sm={4} xs={12}>
                      <span className={classes.label}>Hub cần thao tác</span>
                      <CurrentGoodsHubFilter
                        value={filter.toTransitHubId}
                        hubs={hubs}
                        setFilter={setFilter}
                      />
                    </Grid>
                    <Grid item md={2} sm={4} xs={12}>
                      <span className={classes.label}>Ngày xuất kho</span>
                      <DateTimeRangeSelection
                        value={[filter.exportDateFrom, filter.exportDateTo]}
                        placeholder="Chọn ngày..."
                        label="exportDateFrom"
                        onChange={(e) => {
                          setFilter("exportDateFrom", e[0]);
                          setFilter("exportDateTo", e[1]);
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={2}
                      sm={4}
                      xs={12}
                      style={{ display: "flex", alignItems: "flex-end" }}
                    >
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={filter.isLastTransportOnly === 0}
                              onChange={(e, v) =>
                                setFilter(
                                  "isLastTransportOnly",
                                  filter.isLastTransportOnly === 0 ? 1 : 0
                                )
                              }
                            />
                          }
                          label="Tìm kiếm toàn chặng"
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={2} sm={4} xs={12}>
                      <span className={classes.label}>Lý do hẹn giao</span>
                      <EditShipdateReasons
                        value={filter.edit_ship_date_reason}
                        options={rejectReasons}
                        setFilter={setFilter}
                        multi={true}
                        label="edit_ship_date_reason"
                        placeholder="Chọn..."
                      />
                    </Grid>
                    <Grid item md={2} sm={4} xs={12}>
                      <span className={classes.label}>Nhóm ngành hàng</span>
                      <CategoriesSelection seller_id={filter.seller_id} />
                    </Grid>
                    <Grid item md={2} sm={4} xs={12}>
                      <span className={classes.label}>Tên công ty</span>
                      <SellerSelection
                        value={filter.seller_id}
                        onChange={(e) => setFilter("seller_id", e)}
                      />
                    </Grid>
                    <Grid item md={6} xs={12} className={classes.buttons}>
                      <div>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          onClick={() => this.props.fetchBlList()}
                          style={{ marginRight: 8 }}
                        >
                          Tìm kiếm
                        </Button>
                        <Button
                          variant="contained"
                          color="default"
                          onClick={() => {
                            this.props.clearFilters();
                            this.props.fetchBlList();
                          }}
                          className={classes.button}
                        >
                          Xóa
                        </Button>
                        &nbsp;&nbsp;
                        <Tooltip title="ALT+B">
                          <Button
                            variant="contained"
                            color="default"
                            onClick={() =>
                              this.setState({
                                showBookmark: !this.state.showBookmark,
                              })
                            }
                            className={`${classes.button} ${classes.bookmarkBtn}`}
                          >
                            <i className="far fa-bookmark" /> &nbsp; Bộ lọc
                          </Button>
                        </Tooltip>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div
                  className={
                    this.state.showBookmark ? "showBookmark" : "hideBookmark"
                  }
                  style={{ overflow: "hidden", height: "18rem" }}
                >
                  <BookmarkBar
                    containerClass={classes.bookmarkContent}
                    paperClass={classes.bookmarkContainer}
                    listClass={classes.listClass}
                    width="auto"
                    currentFilter={paramBuilder(filter)}
                    type="bl_filter"
                    filters={savedFilter}
                    open={this.state.showBookmark}
                    saveCallBack={() => this.handleSaveFilter()}
                    beforeDeleteCallback={this.beforeDeleteCallback}
                    onDeleteCallback={() => this.props.fetchUserFilter()}
                    onSelect={this.changeFilter}
                  />
                </div>
              </div>
            </Collapse>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  filter: state.admin.list.filter,
  hubs: state.admin.shared.hubs,
  ktvList: state.admin.shared.ktvList,
  user: state.admin.shared.user,
  allThreePls: state.admin.shared.allThreePls,
  savedFilter: state.admin.list.savedFilter,
  rejectReasons: state.admin.shared.rejectReasons,
  visibleFilter: state.admin.list.visibleFilter,
  currentTab: state.admin.list.currentTab,
  districtList: state.admin.list.districtList,
});

const mapDispatchToProps = (dispatch) => ({
  fetchBlList: () => dispatch(fetchBlList()),
  setFilter: (property, value) => dispatch(setFilter(property, value)),
  clearFilters: () => dispatch(clearFilters()),
  fetchAllDistrict: () => dispatch(fetchAllDistrict()),
  getCategories: () => dispatch(getCategoriesList()),
  loadFilter: (filter) => dispatch(loadFilter(filter)),
  setTab: (index) => dispatch(setTab(index)),
  fetchUserFilter: () => dispatch(fetchUserFilter()),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(FilterContainer)
);
