import _ from "lodash";
import moment from "moment";

const _initialState = {
  filter: {
    from_hub: "",
    to_hub: "",
    start_date: moment().subtract(1, "months").startOf("month").toDate(),
    end_date: moment().subtract(1, "months").endOf("month").toDate(),
    asia_branch: "",
    shipper_id: "",
  },
  loading: false,
  data: [],
  total: {
    grand_total: null,
    distance: null,
    estimate_fee: null,
  },
  pagination: {
    limit: 10,
    offset: 0,
    totalRecords: 200,
  },
  lastPagination: {
    limit: 10,
    offset: 0,
    totalRecords: 200,
  },
};

const ShipmentDetailReport = (state = _initialState, action) => {
  switch (action.type) {
    case "SHIPMENT_DETAIL_REPORT_CHANGE_FILTER":
      return {
        ...state,
        filter: action.payload,
      };

    case "SHIPMENT_DETAIL_REPORT_CHANGE_PAGINATION":
      return {
        ...state,
        lastPagination: state.pagination,
        pagination: action.payload,
      };
    case "SHIPMENT_DETAIL_REPORT_CHANGE_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "SHIPMENT_DETAIL_REPORT_SET_DATA":
      const data = action.payload.results;
      const summary = action.payload.shippers_summary;
      let newData = [];
      if (data && data.length > 0) {
        const groupedList = _.groupBy(data, "shipper_id");
        const keys = Object.keys(groupedList);

        keys.forEach((key, index) => {
          const shipper_summary = summary.find(
            (x) => x.shipper_id === Number(key)
          );
          const shipper = {
            data: groupedList[key],
            ...shipper_summary,
          };
          newData.push(shipper);
        });
      }
      return {
        ...state,
        data: newData,
        total: {
          grand_total: summary
            .map((x) => x.sum_grand_total)
            .reduce((a, b) => a + b, 0),
          bl_distance: summary
            .map((x) => x.sum_bl_distance)
            .reduce((a, b) => a + b, 0),
          real_distance: summary
            .map((x) => x.sum_real_distance)
            .reduce((a, b) => a + b, 0),
          bl_cost: summary.map((x) => x.sum_bl_cost).reduce((a, b) => a + b, 0),
          real_cost: summary
            .map((x) => x.sum_real_cost)
            .reduce((a, b) => a + b, 0),
        },
        pagination: {
          ...state.pagination,
          totalRecords: action.payload.total_records,
        },
      };

    default:
      return state;
  }
};

export default ShipmentDetailReport;
