import React from "react";
import { ShipType, ShipMethod } from "../../../models";
import _ from "lodash";
import { validateUtils } from "@utils";
import {
  getThreePlsAvailable,
  getBls,
  groupAdidiTransportApi,
  groupTransportApi,
} from "@api";
import { Button } from "@material-ui/core";
import { openNotiDialog } from "@shared";
import { getBlDetailApi } from "../api";
import { BlRequestType } from "../constants";
import { fetchError } from "../shared";

export const getGroupingBls = () => async (dispatch, getState) => {
  // query string
  const state = getState().admin.group;
  const filter = state.filters;
  const groupType = state.groupType;
  const params = _.omitBy(filter[groupType], (x) => x === "");
  const rules = {
    from_hub_id: {
      required: true,
    },
  };
  const message = {
    from_hub_id: {
      required: "Bạn chưa chọn kho xuất",
    },
  };
  const errMsg = validateUtils(filter[groupType], rules, message);
  if (errMsg.length > 0) {
    const message = errMsg.map((x, key) => <li key={key}>{x}</li>);
    dispatch(openNotiDialog("failed", <ul>{message}</ul>));
    return;
  }
  // start progress
  dispatch(setSearchProgress(true));

  const response = await getBls(params);
  dispatch(setLastSearchGroupType(groupType));
  const data = response.data;
  dispatch(setSearchProgress(false));
  if (response.status === 200) {
    if (data.code === 200) {
      dispatch(getBlListSuccess(data.data));
      //default chọn hết kết quả search được
      dispatch(selectAll(true));
    } else {
      dispatch(openNotiDialog("failed", data ? data.message : "Có lỗi xảy ra"));
    }
  } else {
    dispatch(openNotiDialog("failed", data ? data.message : "Có lỗi xảy ra"));
  }
};

export const getBlListSuccess = (data) => ({
  type: "GET_BL_LIST_BY_ID",
  payload: data,
});

export const setSearchProgress = (val) => ({
  type: "SET_SEARCH_BTN_PROGRESS",
  payload: val,
});

export const changeSelection = (ele, val) => ({
  type: "CHANGE_SELECTION",
  payload: {
    ele,
    val,
  },
});

export const toggleDetailDialog = (state) => ({
  type: "TOGGLE_BL_GROUP_DETAIL_DIALOG",
  payload: state,
});

export const selectAll = (val) => ({
  type: "SELECT_ALL",
  payload: val,
});

export const groupBls = (data) => async (dispatch, getState) => {
  let requestBody = {
    ship_method: data.shipMethod,
    bl_ids: data.bl_ids,
    type: data.ship_type ? data.ship_type : "customer_customer",
    note: data.note ? data.note.trim() : "",
  };
  if (data.weight) {
    requestBody.weight = data.weight.toString();
  }
  if (data.height) {
    requestBody.height = data.height.toString();
  }
  if (data.width) {
    requestBody.width = data.width.toString();
  }
  if (data.length) {
    requestBody.length = data.length.toString();
  }
  if (data.inventory_count) {
    requestBody.inventory_count = Number(data.inventory_count);
  }
  let currentBl = getState().admin.group.bls[0];
  if (data.edt) {
    requestBody.estimated_delivery_time = data.edt;
  }
  if (data.areaCodeSelectValue && data.areaCodeSelectValue.value) {
    requestBody.manual_group = data.areaCodeSelectValue.value;
  }
  requestBody.support_ktvs = data.shipperExtra;
  if (data.shipMethod === ShipMethod.ktv) {
    requestBody.shipper = data.shipper;
    requestBody.vehicle_gas_cost = data.vehicle_gas_cost;
  }
  if (data.plRef) {
    requestBody.pl_ref = data.plRef;
  }
  if (
    [
      ShipType.hub_hub,
      ShipType.ktv_hub,
      ShipType.customer_customer,
      ShipType.customer_hub,
    ].includes(data.shipType) ||
    ([ShipType.hub_customer].includes(data.shipType) &&
      currentBl.request_type.toLowerCase() === "wh_transfer")
  ) {
    requestBody.to_hub_id = data.toHub.id;
  }
  if (ShipType.hub_ktv === data.shipType) {
    requestBody.to_shipper_id = data.toKTV.id;
  }
  if (data.shipMethod !== "ktv" && data.service_id) {
    requestBody.service_id = data.service_id;
  }
  if (data.shipMethod === ShipMethod.vtpost) {
    requestBody.allow_stock_view = data.allow_stock_view;
  }
  if (data.shipMethod === ShipMethod.xe_khach) {
    requestBody.bus_line = data.bus_line;
  }
  if (data.estimate_fee) {
    requestBody.estimate_fee = data.estimate_fee;
  }
  if (data.suggestion_id) {
    requestBody.suggestion_id = data.suggestion_id;
  }
  if (data.points) {
    requestBody.points = data.points;
  }
  if (data.partnerNote) {
    requestBody.partner_note = data.partnerNote;
  }
  if (data.extra && data.extra.length > 0) {
    requestBody.delivery_type = data.extra[0].value;
    requestBody.category_group = _.get(
      data.extra[0],
      "settings.categories.settings[0].value"
    );
    requestBody.vehicle_type = _.get(
      data.extra[0],
      "settings.vehicleTypes.settings[0].value"
    );
  }
  dispatch(setBlGroupSaveLoading(true));
  // lấy danh sách vận đơn CKNB có thể gộp với mã vận đơn/yêu cầu vừa nhập
  const response =
    data.shipMethod == "adidi"
      ? await groupAdidiTransportApi(requestBody)
      : await groupTransportApi(requestBody);
  dispatch(setBlGroupSaveLoading(false));
  const responseData = response.data;
  const state = getState().admin.group;
  const request_ids = data.points.reduce(
    (acc, cur) => [...new Set([...acc, ...cur.requestId])],
    []
  );
  const blIds = (data.bl_ids || []).map((x) => x).join(",");
  if (response.status === 200) {
    if (responseData.code === 200) {
      dispatch(
        openNotiDialog(
          "success",
          "Tạo lộ trình thành công",
          null,
          "custom",
          null,
          (onClose) => (
            <Button
              onClick={() => {
                window.localStorage.setItem("currentTab", 0);
                window.location.href =
                  request_ids !== ""
                    ? `/?keyword=${request_ids}`
                    : `/?keyword=${blIds}&field=bl_id`;
              }}
            >
              Xem chi tiết
            </Button>
          )
        )
      );
    } else {
      dispatch(
        openNotiDialog(
          "failed",
          responseData
            ? responseData.errors
              ? _getFormErrorMessage(responseData.errors)
              : responseData.message
            : ""
        )
      );
    }
  } else {
    dispatch(
      openNotiDialog(
        "failed",
        responseData
          ? responseData.errors
            ? _getFormErrorMessage(responseData.errors)
            : responseData.message
          : ""
      )
    );
  }
};

export const _getFormErrorMessage = (errors) => {
  const fields = Object.keys(errors);
  let msg = [];
  if (fields.indexOf("shipper") > -1) {
    msg.push("Bạn chưa chọn KTV giao hàng.");
  }
  if (fields.indexOf("to_shipper_id") > -1) {
    msg.push("Bạn chưa chọn KTV nhận hàng.");
  }
  if (fields.indexOf("to_hub_id") > -1) {
    msg.push("Bạn chưa chọn kho nhận.");
  }
  if (fields.indexOf("ship_method") > -1) {
    msg.push("Bạn chưa chọn nhà vận chuyển.");
  }
  if (fields.indexOf("shipper_match") > -1) {
    msg.push("Trùng KTV. Vui lòng chọn lại KTV Phụ.");
  }
  if (fields.indexOf("weight") > -1) {
    msg.push("Bạn chưa nhập khối lượng đơn hàng");
  }
  if (fields.indexOf("nv_phongvu") > -1) {
    msg.push("Bạn chưa nhập mã nhân viên giao hàng");
  }
  return msg;
};

export const blGroupFilterChange = (obj) => ({
  type: "BL_GROUP_FILTER_CHANGE",
  payload: obj,
});

export const fetchBlGroup3PlsList = (bl_id, data) => async (dispatch) => {
  const response = await getThreePlsAvailable(bl_id, data);
  const result = response.data;
  if (response.status === 200) {
    if (result) {
      dispatch(fetch3PlsListSuccess(result.data.ThreePls));
    } else {
      dispatch(openNotiDialog("failed", data ? data.message : "Có lỗi xảy ra"));
    }
  } else {
    if (response.status === 404) {
      window.location.assign("/error/404");
    }
    dispatch(fetchError(response.status, result.data.message));
  }
  return result;
};

const fetch3PlsListSuccess = (threePls) => ({
  type: "GET_BL_GROUP_3PLS_LIST",
  payload: threePls,
});

export const changeGroupType = (type) => ({
  type: "BL_GROUP_CHANGE_GROUP_TYPE",
  payload: type,
});

export const setBlGroupSaveLoading = (state) => ({
  type: "BL_GROUP_SET_SAVE_LOADING",
  payload: state,
});

const setLastSearchGroupType = (state) => ({
  type: "BL_GROUP_SET_LAST_SEARCH_GROUP_TYPE",
  payload: state,
});

export const changeShipMethod = (shipMethod) => ({
  type: "BL_GROUP_SET_SHIP_METHOD",
  payload: shipMethod,
});

export const setCurrentBl = (bl) => ({
  type: "BL_GROUP_SET_CURRENT_BL",
  payload: bl,
});

export const setSelectedBls = (selectedBls) => ({
  type: "BL_GROUP_SET_SELECTED_BLS",
  payload: selectedBls,
});

export const updateReceiverAddress =
  (response, address) => async (dispatch, getState) => {
    const state = getState().admin.group;
    const currentBl = state.currentBl;
    if (response.status === 200) {
      const blDetailResponse = await getBlDetailApi(currentBl.id);
      if (blDetailResponse.status === 200) {
        const newBl = blDetailResponse.data.data.bl;
        //update bl in selectedBls
        let selectedBls = _.cloneDeep(state.selectedBls);
        const index = selectedBls.findIndex((x) => x.id === currentBl.id);
        selectedBls[index] = newBl;
        //update bl in bls
        let bls = _.cloneDeep(state.bls);
        const _index = bls.findIndex((x) => x.id === currentBl.id);
        bls[_index] = newBl;
        dispatch(setSelectedBls(selectedBls));
        dispatch(getBlListSuccess({ bls: bls }));
        dispatch(setCurrentBl(newBl));
      }
    }
  };
