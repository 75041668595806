import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import NumberFormat from "react-number-format";

const styles = (theme) => ({
  input: {
    height: 34,
    border: "1px solid #ccc",
    borderRadius: 4,
    paddingLeft: 8,
    maxWidth: 50,
  },
  unit: {
    alignSelf: "center",
  },
  container: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
  },
});

class SizeInput extends React.Component {
  onChange(type, value) {
    let measurementObj = {
      height: this.props.height,
      width: this.props.width,
      length: this.props.length,
    };
    measurementObj[type] = value;
    this.props.onChange(measurementObj);
  }
  render() {
    const { height, width, length, classes } = this.props;
    return (
      <Grid item className={classes.container}>
        <NumberFormat
          value={height}
          thousandSeparator={true}
          className={classes.input}
          onValueChange={(e) => this.onChange("height", e.value)}
        />
        &nbsp;X&nbsp;
        <NumberFormat
          value={width}
          thousandSeparator={true}
          className={classes.input}
          onValueChange={(e) => this.onChange("width", e.value)}
        />
        &nbsp;X&nbsp;
        <NumberFormat
          value={length}
          thousandSeparator={true}
          className={classes.input}
          onValueChange={(e) => this.onChange("length", e.value)}
        />
        &nbsp; cm
      </Grid>
    );
  }
}

export default withStyles(styles)(SizeInput);
