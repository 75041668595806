import {
  BlDirection,
  BlState,
  ShipMethod,
  ShipType,
  TransportState,
} from "../../../models";
import { store } from "../../..";

export const mapShipMethodToText = (method) => {
  //Trả về text tương ứng với mã đvvc
  const threePls = store.getState().admin.shared.allThreePls;
  if (threePls && threePls.length > 0) {
    let result = "";
    if (method === ShipMethod.ktv) {
      result = "Kĩ thuật viên";
    } else {
      const found = threePls.filter((x) => x.code_name === method);
      if (found.length > 0) {
        result = found[0].name;
      }
    }
    return result;
  } else {
    switch (method) {
      case ShipMethod.direct:
        return "Khách đến nhận";
      case ShipMethod.ghn:
        return "Giao hàng nhanh";
      case ShipMethod.vnpost:
        return "VNPost";
      case ShipMethod.vnpost_manual:
        return "VNPost Manual";
      case ShipMethod.vtpost_manual:
        return "Viettel Post Manual";
      case ShipMethod.vtpost:
        return "Viettel Post";
      case ShipMethod.ktv:
        return "Kĩ thuật viên";
      case ShipMethod.xe_khach:
        return "Xe khách";
      case ShipMethod.xe_tai:
        return "Xe tải";
      case ShipMethod.ghtk:
        return "Giao hàng tiết kiệm";
      case ShipMethod.ahamove:
        return "AhaMove";
      case ShipMethod.nhat_tin:
        return "Nhất Tín";
      case ShipMethod.nhat_tin_manual:
        return "Nhất Tín Manual";
      case ShipMethod.tan_son_nhat:
        return "Tân Sơn Nhất";
      case ShipMethod.kerry_express_manual:
        return "Kerry Express Manual";
      case ShipMethod.viet_star:
        return "Vietstar";
      case ShipMethod.nv_phongvu:
        return "Nhân viên giao hàng Phong Vũ";
      case ShipMethod.nv_umbala:
        return "Nhân viên Umbala";
      case ShipMethod.nv_umbala_manual:
        return "Nhân viên Umbala Manual";
      case ShipMethod.nv_adidi_manual:
        return "Nhân viên Adidi Manual";
      case ShipMethod.nv_kinhdo:
        return "Nhân viên Kinh Đô";
      case ShipMethod.hoang_trong_manual:
        return "Hoàng Trọng Manual";
      case ShipMethod.kien_vang_manual:
        return "Kiến Vàng Manual";
      case ShipMethod.adidi:
        return "ADiDi";
      case ShipMethod.vtphn_manual:
        return "VnTrading - ViettelPost Manual Thái Hà (Đống Đa)";
      case ShipMethod.vtbt_manual:
        return "Tổng công ty cổ phần Bưu chính Viettel (Viettel Post) - CN Bình Thạnh - Bưu cục Đinh Bộ Lĩnh";
      case ShipMethod.kl_manual:
        return "VnTrading - VnShop Manual";
      case ShipMethod.eton:
        return "Eton";
      case ShipMethod.eton_vnt:
        return "VnTrading - Eton";
      case ShipMethod.eton_vin:
        return "VIN - Eton";
      case ShipMethod.tran_an_manual:
        return "VnTrading - Trân An Manual";
      case ShipMethod.vnt_adidi_manual:
        return "VnTrading - ADiDi Manual";
      case ShipMethod.tkl_eton:
        return "TokyoLife - Eton";
      case ShipMethod.tkl_manual:
        return "TokyoLife Manual";
      case ShipMethod.vin_manual:
        return "VIN manual";
      default:
        return "";
    }
  }
};
/* 
  Xác định thời điểm hoàn, sau lộ trình này các lộ trình shipping|ok => returning|returned
*/
const lastReturnTransport = (transports) => {
  for (let i = transports.length - 1; i >= 0; i--) {
    //case 1: Hoàn 1 phần (partial_ok)
    const case1 = transports[i].state === "partial_ok";
    //case 2: Đang Hoàn toàn phần (returning)
    const case2 = transports[i].state === "returning";
    //case 3: Đã hoàn 1 phần (returned) *Note: phân biệt với hủy lộ trình đang giao
    const case3 = transports[i].state === "returned";
    if (["returned", "partial_ok", "returning"].includes(transports[i].state))
      return i;
  }
  return null;
};

/**
 * Thay đổi hiển thị trạng thái lộ trình hoàn hàng cho dễ hiểu.
 * Đối với vận đơn hoàn hàng có lộ trình shipping|retry|ok => returning|returned
 * @param bl
 */
export const changeStateLabel = (bl) => {
  if (bl.direction === BlDirection.returning) {
    let transports = bl.transports;
    const returnTr = lastReturnTransport(bl.transports);
    return transports.map((transport, index) => {
      if (index === returnTr) {
        //đảo ngược trạng thái của các transport hoàn hàng
        for (let i = index + 1; i < transports.length; i++) {
          if (
            [TransportState.shipping, TransportState.retry].includes(
              transports[i].state
            )
          ) {
            transports[i].state = TransportState.returning;
          } else if (
            [TransportState.ok, TransportState.partial_ok].includes(
              transports[i].state
            )
          ) {
            transports[i].state = TransportState.returned;
          }
        }
      }
      return transport;
    });
  }

  return bl.transports;
};

/**
 * Lọc các transport returned ktv_customer
 * @param bl
 * @param transports
 * @returns {*}
 */
export const filterTransports = (bl, transports) => {
  if (
    [BlState.partial_returning, BlState.partial_returned].includes(bl.state)
  ) {
    return transports.filter((transport) => {
      return !(
        transport.state === TransportState.returned &&
        transport.type === ShipType.ktv_customer
      );
    });
  }

  return transports;
};

export const mapTransportStateColor = (display_state) => {
  // Trả về color hex code tương ứng với trạng thái lộ trình
  switch (display_state) {
    case TransportState.confirmed:
      return "#F57F17";
    case TransportState.shipping:
      return "#03a9f4";
    case TransportState.retry:
      return "#a52a2a";
    case TransportState.ok:
      return "#4caf50";
    case TransportState.partial_ok:
      return "#4caf50";
    case TransportState.returned:
      return "#000000";
    case TransportState.cancelled:
      return "#f05050";
    case TransportState.returning:
      return "#ba68c8";
    default:
      return "";
  }
};

export const mapTransportStateToText = (display_state) => {
  // Trả về text tương ứng với trạng thái lộ trình
  switch (display_state) {
    case TransportState.confirmed:
      return "Chờ lấy hàng";
    case TransportState.shipping:
      return "Đang giao hàng";
    case TransportState.ok:
      return "Giao thành công";
    case TransportState.partial_ok:
      return "Giao thành công";
    case TransportState.retry:
      return "Đang giao lại";
    case TransportState.cancelled:
      return "Lộ trình bị huỷ";
    case TransportState.returning:
      return "Đang hoàn hàng";
    case TransportState.returned:
      return "Đã hoàn hàng";
    default:
      return "";
  }
};

export const mapShipperState = (shipper_state, transport_state) => {
  switch (shipper_state) {
    case "waiting_for_being_accepted":
      return {
        label: "Chờ xác nhận yêu cầu",
        background: "#CE6000",
        list_background: "#ABB54A",
        color: "#FFFFFF",
      };
    case "rejected":
      return {
        label: "Từ chối yêu cầu",
        background: "#d43f3f",
        list_background: "#d43f3f",
        color: "#FFFFFF",
      };
    case "accepted":
      return {
        label: "Đã xác nhận yêu cầu",
        background: "#CE6000",
        list_background: "#ABB54A",
        color: "#FFFFFF",
      };
    case "at_warehouse":
      return {
        label: "Đã tới điểm lấy hàng",
        background: "#CE6000",
        list_background: "#ABB54A",
        color: "#FFFFFF",
      };
    case "on_the_way":
      return {
        label: "Đang trên đường",
        background: "#2378b6",
        list_background: "#2378b6",
        color: "#FFFFFF",
      };
    case "at_delivery_point":
      return {
        label: "Đã đến nơi",
        background: "#2378b6",
        list_background: "#2378b6",
        color: "#FFFFFF",
      };
    case "on_the_way_back":
      return {
        label: "Đang trên đường về",
        background: "#923BA1",
        list_background: "#923BA1",
        color: "#FFFFFF",
      };
    case "waiting_for_being_cancelled":
      return {
        label: "Đang trên đường về",
        background: "#c7451d",
        list_background: "#c7451d",
        color: "#FFFFFF",
      };
    case "ready_to_return":
      if (transport_state === "returning")
        return {
          label: "Đã về điểm lấy hàng",
          background: "#923BA1",
          list_background: "#923ba1",
          color: "#FFFFFF",
        };
      if (transport_state === "shipping")
        return {
          label: "Đã về điểm lấy hàng",
          background: "#5f5d59",
          list_background: "#5f5d59",
          color: "#FFFFFF",
        };
    default:
      return null;
  }
};
