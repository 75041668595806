import React from "react";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import {
  mapShipMethodToText,
  getShortenName,
  validateSuggestionList,
} from "../../../utils";
import { connect } from "react-redux";
import { getServiceListByShipMethod } from "../../actions";
import { ShipMethod, ShipType, BlChannel } from "../../../constants";
import { List, ListItem, Divider, Collapse } from "@material-ui/core";

const styles = (theme) => ({
  text: {
    fontSize: 13,
    fontFamily: "Noto Sans, sans-serif",
  },
  suggestBar: {
    paddingTop: 0,
    paddingBottom: 0,
    maxHeight: "38rem",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
  groupTitle: {
    background: "cornflowerblue",
    color: "white",
    fontFamily: "Noto Sans, sans-serif",
    borderRadius: 3,
    fontSize: 14,
    padding: 8,
  },
  listItemContainer: {
    minWidth: 70,
    display: "flex",
    justifyContent: "center",
  },
});

class CreateTransportSuggestions extends React.Component {
  constructor(props) {
    super(props);
    const ktvSuggestIndex = this.props.suggestions.find(
      (x) => x.ship_method === ShipMethod.ktv
    );
    this.state = {
      collapsed: ktvSuggestIndex ? ktvSuggestIndex.id : -1,
    };
  }

  renderShipMethodIcon = (ship_method) => {
    const logo = {
      nhat_tin: `nhattin_logo.png`,
      nhat_tin_manual: `nhattin_logo.png`,
      vtpost: `vtpost_logo.png`,
      vtpost_manual: `vtpost_logo.png`,
      vtphn_manual: `vtpost_logo.png`,
      vtbt_manual: `vtpost_logo.png`,
      vnpost: `vnpost_logo.jpg`,
      vnpost_manual: `vnpost_logo.jpg`,
      kerry_express_manual: `kerry_logo.png`,
      ahamove: `ahamove_logo.png`,
      tan_son_nhat: `tansonnhat_logo.png`,
      ghn: `ghn_logo.png`,
      viet_star: `vietstar_logo.jpg`,
      adidi: `adidi_logo.png`,
      eton: `eton_logo.png`,
      eton_vnt: `eton_logo.png`,
      tkl_eton: `eton_logo.png`,
      eton_vin: `eton_logo.png`,
    };
    const icon = {
      hoang_trong_manual: `xekhach_logo.png`,
      kien_vang_manual: `xekhach_logo.png`,
      xe_khach: `xekhach_logo.png`,
      xe_tai: `xetai_logo.png`,
      ktv: `ktv_logo.png`,
      nv_kinhdo: `ktv_logo.png`,
      nv_umbala_manual: `ktv_logo.png`,
      nv_adidi_manual: `ktv_logo.png`,
      kl_manual: `xetai_logo.png`,
      vnt_adidi_manual: `ktv_logo.png`,
      tran_an_manual: `xetai_logo.png`,
      tkl_manual: `xetai_logo.png`,
      vin_manual: `xetai_logo.png`,
    };
    if (logo[ship_method])
      return (
        <img
          src={require(`@images/${logo[ship_method]}`)}
          height="23"
          width="70"
        />
      );
    else return <img src={require(`@images/${icon[ship_method]}`)} />;
  };

  renderKtvSuggestList = (x, toAddress) => {
    const { shipperList, onSelect, classes, value, ktv } = this.props;
    const { collapsed } = this.state;
    let mainKtv = ktv.find((x) => x.isMainKtv);
    mainKtv = mainKtv ? mainKtv.id : null;
    let ktvSuggestList = x.ktv.map((ktv, id) => {
      const shipper = ktv ? shipperList.find((k) => k.value === ktv) : null;
      const shipperLabel = shipper
        ? `KTV ${getShortenName(shipper.name)}`
        : "KTV";
      let newSuggestions = _.cloneDeep(x);
      newSuggestions.ktv = ktv;
      return (
        <ListItem
          key={`ktv-${id}`}
          button
          onClick={() => onSelect(newSuggestions)}
          style={{
            background:
              x.id == value && ktv === mainKtv ? "#92a1f32e" : "white",
          }}
        >
          <div className={classes.listItemContainer}>
            {this.renderShipMethodIcon(ShipMethod.ktv)}
          </div>
          <div className={classes.text} style={{ margin: "5px 13px" }}>
            Giao bằng <strong>{shipperLabel}</strong> tới{" "}
            <strong>{toAddress}</strong>
          </div>
        </ListItem>
      );
    });
    return (
      <React.Fragment>
        <ListItem
          className={classes.groupTitle}
          onClick={() =>
            this.setState({ collapsed: collapsed === x.id ? -1 : x.id })
          }
        >
          <span style={{ flex: 1 }}>
            {x.suggest_name ? x.suggest_name : "KTV chuyên giao hàng"}
          </span>
          {collapsed === x.id ? (
            <i className="material-icons">expand_more</i>
          ) : (
            <i className="material-icons">keyboard_arrow_right</i>
          )}
        </ListItem>
        <Collapse in={collapsed === x.id}>{ktvSuggestList}</Collapse>
      </React.Fragment>
    );
  };

  renderSuggestionsList = () => {
    const { onSelect, suggestions, bl, value, classes } = this.props;
    return suggestions.map((x, id) => {
      const shipMethod = mapShipMethodToText(x.ship_method);
      const toAddressType = x.transport_type.split("_")[1];
      let toAddress = "";
      switch (toAddressType) {
        case "hub":
          toAddress = "Kho";
          break;
        case "ktv":
          toAddress = "KTV";
          break;
        case "customer":
          toAddress = bl.isWhTransfer ? "Kho nhận" : "Khách hàng";
          break;
        default:
          return;
      }
      return (
        <React.Fragment key={id}>
          {x.ship_method === ShipMethod.ktv ? (
            x.ktv.length > 0 ? (
              this.renderKtvSuggestList(x, toAddress)
            ) : (
              ""
            )
          ) : (
            <ListItem
              button
              onClick={() => onSelect(x)}
              style={{ background: x.id == value ? "#92a1f32e" : "white" }}
            >
              <div className={classes.listItemContainer}>
                {this.renderShipMethodIcon(x.ship_method)}
              </div>
              <div className={classes.text} style={{ margin: "5px 13px" }}>
                Giao bằng <strong>{shipMethod}</strong> tới{" "}
                <strong>{toAddress}</strong>
              </div>
            </ListItem>
          )}
          <Divider />
        </React.Fragment>
      );
    });
  };

  render() {
    const { suggestions, maxHeight = "38rem", classes } = this.props;
    return (
      <React.Fragment>
        <h4 style={{ fontFamily: "Noto Sans, sans-serif", margin: "6px 0px" }}>
          <i className="far fa-lightbulb" style={{ color: "#8a8a2c" }} />
          {"  "}Có {validateSuggestionList(suggestions)} gợi ý dành cho bạn
        </h4>
        <List
          component="nav"
          className={classes.suggestBar}
          style={{ maxHeight: maxHeight }}
        >
          {this.renderSuggestionsList()}
        </List>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  allThreePls: state.admin.shared.allThreePls,
});

const mapDispatchToProps = (dispatch) => ({
  getServiceListByShipMethod: (id, data) =>
    dispatch(getServiceListByShipMethod(id, data)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(CreateTransportSuggestions)
);
