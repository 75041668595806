import React, { useEffect, useState } from "react";
import Select from "react-select";
import { ShipMethod } from "../../../../../models";

const ShipMethodFilter = ({ value, setFilter, options, seller_id }) => {
  const [prevSeller, setPrevSeller] = useState(seller_id);

  useEffect(() => {
    if (prevSeller != seller_id) {
      const selected = value.split(",") || [];
      const short_options = options.map((x) => x.value);
      const filtered = selected.filter((x) => short_options.includes(x));
      setFilter("shipMethod", filtered.toString());
    }
    setPrevSeller(seller_id);
  }, [options, prevSeller, seller_id, setFilter, value]);
  return (
    <Select
      value={value}
      onChange={(method) => setFilter("shipMethod", method ? method : "")}
      options={options}
      multi
      simpleValue
      closeOnSelect={value === ShipMethod.ktv}
      placeholder="Chọn đơn vị vận chuyển..."
    />
  );
};

export default ShipMethodFilter;
