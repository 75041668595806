import * as actionType from "./actionTypes";
import * as services from "./services";

export const changeBlStatusFilter = (filter) => ({
  type: actionType.CHANGE_BL_STATUS_FILTER,
  payload: filter,
});

export const getStatistic = () => async (dispatch, getState) => {
  const filter = getState().admin.dashboard.blStatusFilter;
  dispatch(setBlStatusLoading(true));
  const response = await services.getBlStatus(filter);
  if (response.status === 200) {
    const responseBody = response.data;
    dispatch(getStatisticSuccess(responseBody.data));
  }
  dispatch(setBlStatusLoading(false));
};

export const getSystemStatus = () => async (dispatch, getState) => {
  const response = await services.getSystemStatus();
  if (response.status === 200) {
    const responseBody = response.data;
    dispatch(getSystemStatusSuccess(responseBody.data));
  }
};

export const setBlStatusLoading = (state) => ({
  type: actionType.SET_BL_STATUS_LOADING,
  payload: state,
});

export const getStatisticSuccess = (data) => ({
  type: actionType.GET_STATISTIC_SUCCESS,
  payload: data,
});

export const getSystemStatusSuccess = (data) => ({
  type: actionType.GET_SYSTEM_STATUS_SUCCESS,
  payload: data,
});
