import React from "react";
import Button from "@material-ui/core/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import { RadioGroup, Radio } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { openNotiDialog } from "@shared";

const styles = (theme) => ({
  button: {
    textTransform: "none",
    backgroundColor: "#ba68c8",
    color: "#fff",
    marginLeft: 8,
  },
  dialogText: {
    color: "#8e8a8a",
    lineHeight: "1.7rem",
  },
});

const REASON_TYPE = "default";

class ConfirmPicked extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      updating: false,
      selectedReason: "",
    };
  }

  toggleDialog = () => {
    this.setState({ open: !this.state.open }, () => {
      if (this.state.updating) {
        this.setState({
          updating: false,
        });
      }
    });
  };

  componentDidUpdate() {
    const { reasons } = this.props;
    const { selectedReason } = this.state;
    const customerRefundReasons = reasons.filter((x) => x.type === REASON_TYPE);
    if (customerRefundReasons.length > 0 && !selectedReason) {
      this.setState({ selectedReason: customerRefundReasons[0].id.toString() });
    }
  }

  confirmReturn = async () => {
    const { transport, reasons, confirmTransportReturned } = this.props;
    const { selectedReason } = this.state;
    let reason = "";
    if (selectedReason && reasons.length > 0) {
      const selected = reasons.filter((x) => x.id === Number(selectedReason));
      reason = selected[0].name;
    }

    this.setState({
      updating: true,
    });
    await confirmTransportReturned(transport.id, reason);
    this.setState({
      updating: false,
    });
  };

  renderReasonSelection = () => {
    const { reasons } = this.props;
    const { selectedReason } = this.state;
    return (
      <RadioGroup
        aria-label="Lí do hoàn hàng"
        name="rejectReason"
        value={selectedReason}
        onChange={(e, value) => this.setState({ selectedReason: value })}
      >
        {reasons
          .filter((x) => x.type === REASON_TYPE)
          .map((item) => {
            return (
              <FormControlLabel
                value={item.id.toString()}
                key={item.id}
                control={<Radio />}
                label={item.name}
              />
            );
          })}
      </RadioGroup>
    );
  };

  renderConfirmDialog = () => {
    const { open, updating } = this.state;
    const { classes, confirmMsg, showReason = true } = this.props;
    return (
      <Dialog open={open} onClose={this.toggleDialog} scroll="paper">
        <DialogTitle>Xác nhận lộ trình</DialogTitle>
        <DialogContent>
          <span className={classes.dialogText}>
            {confirmMsg
              ? confirmMsg
              : "Bạn muốn xác nhận hoàn hàng cho lộ trình hiện tại?"}
          </span>
          {showReason && (
            <React.Fragment>
              <span className={classes.dialogText}> Vui lòng chọn lí do.</span>
              <br />
              {this.renderReasonSelection()}
            </React.Fragment>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.toggleDialog()}>Hủy</Button>
          <Button
            color="primary"
            onClick={() => this.confirmReturn()}
            disabled={updating}
          >
            {updating && <CircularProgress size={20} />} &nbsp; Đồng ý
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  render() {
    const { classes, title } = this.props;
    return (
      <React.Fragment>
        <Button
          variant="contained"
          className={classes.button}
          onClick={this.toggleDialog}
        >
          {title ? title : "Xác nhận hoàn hàng"}
        </Button>
        {this.renderConfirmDialog()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  reasons: state.admin.shared.rejectReasons,
});

const mapDispatchToProps = (dispatch) => ({
  openNotiDialog: (type, text) => dispatch(openNotiDialog(type, text)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ConfirmPicked)
);
