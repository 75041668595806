import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
  Input,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  SPECIAL_THREE_PLS,
  BlRequestType,
  TransportState,
  BlStockStatus,
} from "@constants";
import { openNotiDialog } from "@shared";

const styles = (theme) => ({
  button: {
    textTransform: "none",
    backgroundColor: "#ff0000",
    color: "#fff",
    marginLeft: 8,
  },
});

function ConfirmCancel({
  bl,
  transport,
  openNotiDialog,
  confirmTransportCancelled,
  classes,
  groupedBls,
}) {
  const [open, setOpen] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [withSamePl, setwithSamePl] = useState(null);

  const validate = (event) => {
    if (
      bl.exportSource.toLowerCase() === "wms" &&
      bl.stockStatus === BlStockStatus.wmsToLoRequest
    ) {
      let message = "";
      if (transport.shipMethod === "ktv") {
        message = (
          <span>
            Cần thực hiện tiếp thao tác <strong>Xác nhận lấy hàng</strong> trên{" "}
            <strong>App</strong> trước khi <strong>Hủy lộ trình</strong>
          </span>
        );
      } else {
        message = (
          <span>
            Cần thực hiện tiếp thao tác <strong>Giao hàng cho 3Pls</strong>{" "}
            trước khi <strong>Hủy lộ trình</strong>
          </span>
        );
      }
      openNotiDialog("failed", message);
    } else {
      if (
        SPECIAL_THREE_PLS.includes(bl.currentTransport.shipMethod) &&
        !bl.currentTransport.able_to_cancel
      ) {
        openNotiDialog(
          "failed",
          <span>
            Vui lòng liên hệ <span className="stressPink">quản lý</span> để hủy
            lộ trình
          </span>
        );
      } else {
        //Nếu PTVC là Adidi, đơn CKNB đc gộp chung với những đơn khác, đang giao -> cho chọn hủy 1 hay hủy tất cả
        if (
          groupedBls.length > 1 &&
          transport.state === TransportState.shipping &&
          bl.requestType.toLowerCase() === BlRequestType.wh_transfer
        ) {
          setAnchorEl(event.currentTarget);
        } else {
          setOpen(true);
        }
      }
    }
  };

  const confirmCancelled = async () => {
    setUpdating(true);
    const params = {
      id: transport.id,
      reason: cancelReason,
      withSamePl,
    };
    await confirmTransportCancelled(params);
    setUpdating(false);
  };

  const confirmWithSamePfRef = (value) => {
    setwithSamePl(value);
    setOpen(true);
  };

  const renderConfirmMsg = () => {
    return (
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Hủy lộ trình</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Bạn muốn xác nhận hủy lộ trình vận chuyển? Vui lòng nhập lí do.
          </DialogContentText>
          <Input
            value={cancelReason}
            fullWidth
            autoFocus={true}
            onChange={(e) => setCancelReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Hủy</Button>
          <Button
            color="primary"
            onClick={confirmCancelled}
            disabled={updating}
          >
            {updating && <CircularProgress size={20} />} &nbsp; Đồng ý
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const isNotAllowed = () => {
    if (
      bl.exportSource.toLowerCase() === "wms" &&
      bl.stockStatus === BlStockStatus.wmsToLoRequest
    )
      return true;
    if (
      SPECIAL_THREE_PLS.includes(bl.currentTransport.shipMethod) &&
      !bl.currentTransport.able_to_cancel
    )
      return true;
    return false;
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Button
        aria-controls="fade-menu"
        aria-haspopup="true"
        variant="contained"
        className={classes.button}
        onClick={validate}
      >
        {isNotAllowed() && <i className="fas fa-exclamation-triangle" />}
        &nbsp; Hủy lộ trình
      </Button>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        // TransitionComponent={Fade}
      >
        <MenuItem onClick={() => confirmWithSamePfRef(false)}>
          Hủy 1 đơn tại điểm
        </MenuItem>
        <MenuItem onClick={() => confirmWithSamePfRef(true)}>
          Hủy tất cả các đơn tại điểm
        </MenuItem>
      </Menu>
      {renderConfirmMsg()}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  reasons: state.admin.shared.rejectReasons,
  groupedBls: state.admin.detail.groupedBls,
});

const mapDispatchToProps = (dispatch) => ({
  openNotiDialog: (type, text) => dispatch(openNotiDialog(type, text)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ConfirmCancel)
);
