import React from "react";
import { EnvLabel, SnackbarNotification, NotificationDialog } from "@shared";
import Sidebar from "./sidebar";
import AppHeader from "./AppHeader";

function Layout({ children }) {
  return (
    <React.Fragment>
      <EnvLabel />
      <Sidebar />
      <AppHeader />
      {children}
      <NotificationDialog />
      <SnackbarNotification />
    </React.Fragment>
  );
}

export default Layout;
