const _initialState = {
  filter: {
    asia_branch: "",
    shipper_id: "",
  },
  pagination: {
    limit: 10,
    offset: 0,
    totalRecords: 0,
  },
  data: [],
  loading: false,
  summary: {
    total_transport: 0,
    declined: 0,
    delivered: 0,
    on_delivering: 0,
    confirmed: 0,
    unfinished: 0,
  },
};

const ShipperStatusReport = (state = _initialState, action) => {
  switch (action.type) {
    case "SHIPPER_STATUS_REPORT_CHANGE_FILTER":
      return {
        ...state,
        filter: action.payload,
      };
    case "SHIPPER_STATUS_RESET_PAGINATION":
      return {
        ...state,
        pagination: action.payload,
      };
    case "SHIPPER_STATUS_REPORT_CHANGE_PAGINATION":
      return {
        ...state,
        pagination: action.payload,
      };
    case "SHIPPER_STATUS_REPORT_CHANGE_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "SHIPPER_STATUS_REPORT_SET_DATA":
      const results = action.payload.results;
      return {
        ...state,
        data: results,
        pagination: {
          ...state.pagination,
          totalRecords: action.payload.total_records,
        },
        summary: {
          total_transport: results
            .map((x) => x.total_transport)
            .reduce((a, b) => a + b, 0),
          declined: results.map((x) => x.declined).reduce((a, b) => a + b, 0),
          delivered: results.map((x) => x.delivered).reduce((a, b) => a + b, 0),
          on_delivering: results
            .map((x) => x.on_delivering)
            .reduce((a, b) => a + b, 0),
          confirmed: results.map((x) => x.confirmed).reduce((a, b) => a + b, 0),
          unfinished: results
            .map((x) => x.unfinished)
            .reduce((a, b) => a + b, 0),
        },
      };
    default:
      return state;
  }
};

export default ShipperStatusReport;
