import { getRejectReasonsByType, getRetryShippingSummaryReport } from "@shared";
import moment from "moment";
import { API_URL } from "@config";
import qs from "qs";
import * as detail from "../ShipDateDetailReport/actions";
export const changeFilter = (data) => ({
  type: "SHIP_DATE_SUMMARY_REPORT_CHANGE_FILTER",
  payload: data,
});

export const changePagination = (pagination) => async (dispatch) => {
  dispatch(setPagination(pagination));
  dispatch(onSearch());
};

export const setPagination = (pagination) => ({
  type: "SHIP_DATE_SUMMARY_REPORT_CHANGE_PAGINATION",
  payload: pagination,
});

export const onSearch = () => async (dispatch, getState) => {
  dispatch(changeLoading(true));
  const state = getState().admin.ShipDateSummaryReport;
  let params = {
    ...state.filter,
  };
  params.start_date = moment(state.filter.start_date).format("YYYY-MM-DD");
  const response = await getRetryShippingSummaryReport(params);
  dispatch(changeLoading(false));
  if (response.status === 200) {
    const result = response.data;
    dispatch(setData(result.data));
  }
};

export const changeLoading = (state) => ({
  type: "SHIP_DATE_SUMMARY_REPORT_CHANGE_LOADING",
  payload: state,
});

export const getReasons = (type) => async (dispatch) => {
  const response = await getRejectReasonsByType(type);
  if (response.status === 200) {
    const result = response.data;
    dispatch(setReasons(result.reasons));
  }
};

export const setReasons = (state) => ({
  type: "SHIP_DATE_SUMMARY_REPORT_SET_REASONS",
  payload: state,
});

export const setData = (state) => ({
  type: "SHIP_DATE_SUMMARY_REPORT_SET_DATA",
  payload: state,
});

export const onExcel = () => async (dispatch, getState) => {
  const state = getState().admin.ShipDateSummaryReport;
  let params = {
    ...state.filter,
  };
  params.start_date = moment(state.filter.start_date).format("YYYY-MM-DD");
  params.limit = -1;
  window.location.href = `${API_URL}/admin/report/retry_shipping/excel?${qs.stringify(
    params
  )}`;
};

export const viewDetail = (reason_id) => (dispatch, getState) => {
  const state = getState().admin.ShipDateSummaryReport;
  const newState = {
    ...state.filter,
    reason_id: reason_id,
  };
  dispatch(detail.changeFilter(newState));
  dispatch(detail.onSearch());
};
