import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  CircularProgress,
} from "@material-ui/core";
import { Chart } from "react-google-charts";
import moment from "moment";

//styles
const styles = (theme) => ({
  card: {
    borderRadius: "0.5rem",
    height: "6rem",
    background: "indianred",
    width: "25rem",
  },
  container: {
    fontFamily: "sans-serif",
  },
  itemContainer: {
    height: "100%",
  },
  list: {
    fontSize: "1.2rem",
  },
});

class KtvStatusContainer extends React.Component {
  events = () => {
    const { start_date, end_date } = this.props;

    return [
      {
        eventName: "select",
        callback: ({ chartWrapper }) => {
          const chart = chartWrapper.getChart();
          const selection = chart.getSelection();
          if (selection.length > 0) {
            let url = `/?last_transport_ship_method=ktv&export_date_from=${moment(
              start_date
            ).format("YYYY-MM-DD")}&export_date_to=${moment(end_date).format(
              "YYYY-MM-DD"
            )}`;
            switch (selection[0].row) {
              case 0:
                url += "&quick_filter=on_delivering";
                break;
              case 1:
                url += "&quick_filter=waiting_for_pick";
                break;
              case 2:
                url += "&quick_filter=on_returning,on_partial_returning";
                break;
              case 3:
                url += "&quick_filter=returned,partial_returned";
                break;
              case 4:
                url += "&quick_filter=cancelled";
                break;
              case 5:
                url += "&quick_filter=delivered";
                break;
              default:
                return;
            }
            window.open(url, "_blank");
          }
        },
      },
    ];
  };

  options = {
    legend: "none",
    title: "Biểu đồ thể hiện tình trạng giao hàng của KTV",
    is3D: true,
    slices: {
      1: { color: "#CDDC39" },
      0: { color: "#53C2FF" },
      2: { color: "#BA68C8" },
      4: { color: "#FF0000" },
      3: { color: "#333333" },
      5: { color: "#4CAF50" },
    },
  };

  render() {
    const { ktvStatus, classes } = this.props;

    const ktvsData = [
      ["Trạng thái", "Số đơn"],
      ["Đang giao", ktvStatus.on_delivering],
      ["Chờ lấy hàng", ktvStatus.waiting_for_pick],
      ["Đang hoàn hàng", ktvStatus.total_returning],
      ["Đã hoàn hàng", ktvStatus.total_returned],
      ["Đã hủy", ktvStatus.cancelled],
      ["Giao thành công", ktvStatus.delivered],
    ];

    return (
      <Grid container>
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <Grid container style={{ paddingLeft: "2rem" }}>
            <Grid item xs={12}>
              <h2>Tổng đơn gán cho Kỹ thuật viên: {ktvStatus.total} đơn</h2>
            </Grid>
            <Grid item xs={6}>
              <List component="nav" className={classes.list}>
                <ListItem>
                  <ListItemIcon>
                    <i className="material-icons" style={{ color: "#CDDC39" }}>
                      stop
                    </i>
                  </ListItemIcon>
                  Chờ lấy hàng: {ktvStatus.waiting_for_pick}
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <i className="material-icons" style={{ color: "#53C2FF" }}>
                      stop
                    </i>
                  </ListItemIcon>
                  Đang giao: {ktvStatus.on_delivering}
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <i className="material-icons" style={{ color: "#BA68C8" }}>
                      stop
                    </i>
                  </ListItemIcon>
                  Đang hoàn hàng: {ktvStatus.total_returning}
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={6}>
              <List component="nav" className={classes.list}>
                <ListItem>
                  <ListItemIcon>
                    <i className="material-icons" style={{ color: "#333333" }}>
                      stop
                    </i>
                  </ListItemIcon>
                  Đã hoàn hàng: {ktvStatus.total_returned}
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <i className="material-icons" style={{ color: "#4CAF50" }}>
                      stop
                    </i>
                  </ListItemIcon>
                  Giao thành công: {ktvStatus.delivered}
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} style={{ minHeight: "40rem" }}>
          <Chart
            width="100%"
            height="100%"
            chartType="PieChart"
            chartEvents={this.events()}
            loader={
              <React.Fragment>
                <CircularProgress /> Đang lấy dữ liệu
              </React.Fragment>
            }
            data={ktvsData}
            options={this.options}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  ktvStatus: state.admin.dashboard.statistic.ktv_stat,
  start_date: state.admin.dashboard.statistic.start_date,
  end_date: state.admin.dashboard.statistic.end_date,
});

const mapDispatchToProps = (dispatch) => ({});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(KtvStatusContainer)
);
