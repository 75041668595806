import React from "react";
import { TablePagination } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  pagination: {
    borderRadius: "0 0 1rem 1rem",
    background: "white",
    borderTop: "1px solid #8c8c8c1c",
  },
});

function CustomPagination({
  pagination,
  defaultProps,
  onChange,
  loading,
  classes,
}) {
  const currentPage = Math.floor(pagination.offset / pagination.limit);
  const maxPage = Math.ceil(pagination.totalRecords / pagination.limit);

  const handleChangeLimit = (e) => {
    const limit = e.target.value;
    const newPage = Math.floor(pagination.offset / limit);
    changePagination({
      limit: limit,
      offset: newPage * limit,
    });
  };

  const handleChangePage = (e, p) => {
    //Change offset
    if (e) {
      changePagination({ offset: p * pagination.limit });
    }
  };

  const renderPagination = ({ from, to, count }) => {
    return (
      <React.Fragment>
        <span style={{ marginRight: 20 }}>
          Trang {Math.round(pagination.offset / pagination.limit) + 1}{" "}
        </span>
        <span>
          Từ {from} đến {to} trên tổng {count}
        </span>
      </React.Fragment>
    );
  };

  const changePagination = (item) => {
    onChange({
      ...pagination,
      ...item,
    });
  };

  return (
    <TablePagination
      className={classes.pagination}
      rowsPerPageOptions={[10, 50, 100]}
      count={pagination.totalRecords}
      rowsPerPage={pagination.limit}
      page={currentPage}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeLimit}
      labelDisplayedRows={renderPagination}
      labelRowsPerPage={"Số dòng hiển thị"}
      {...defaultProps}
      backIconButtonProps={{ disabled: loading || currentPage === 0 }}
      nextIconButtonProps={{ disabled: loading || currentPage + 1 === maxPage }}
    />
  );
}

export default withStyles(styles)(CustomPagination);
