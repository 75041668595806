import _ from "lodash";

import { Bl, Filter, ShipMethod } from "../../../models";
import { getParamsFromUrl } from "../utils";

const currentTab = parseInt(window.localStorage.getItem("currentTab")) || 0;
const filterData = window.localStorage.getItem("SavedFilter");
const backupFilter = window.localStorage.getItem("backupFilter");
const savedFilter =
  filterData === "" || filterData === null ? [] : JSON.parse(filterData);
if (backupFilter === null && savedFilter.length > 0) {
  window.localStorage.setItem("backupFilter", JSON.stringify(savedFilter));
}

let searchParams =
  window.location.search || window.localStorage.getItem("queryString");

if (currentTab === 1)
  searchParams = window.localStorage.getItem("queryStringCXL");

const params = getParamsFromUrl(searchParams);

const _initialState = {
  bls: [],
  batchActions: {
    groupedBls: {},
    response: {},
  },
  filter: new Filter(params),
  currentTab: currentTab,
  savedFilter: savedFilter,
  visibleFilter: [],
  showMsg: {
    show: false,
    id: "",
  },
  districtList: [],
  selected: null,
  selectedBl3Pls: [],
  ktvList: [],
  categories: [],
  categories_default: [],
  flatCategories: [],
  loading: false,
  lastSearch: new Filter(params),
};

const list = (state = _initialState, action) => {
  let newState;
  switch (action.type) {
    case "SET_FILTER":
      newState = _.cloneDeep(state);

      if (action.payload.property === "quickFilter") {
        if (action.payload.value === "waiting") {
          newState.filter = new Filter(
            getParamsFromUrl(window.localStorage.getItem("queryStringCXL"))
          );
          newState.filter.status = "waiting_for_confirm";
          newState.filter.sorting = {
            columnName: "shipDate",
            direction: "asc",
          };
        } else if (action.payload.value === "all") {
          newState.filter = new Filter(
            getParamsFromUrl(window.localStorage.getItem("queryString"))
          );
          // newState.filter.status = "";
        } else {
          const paramsSaveFilter = getParamsFromUrl(window.location.href);
          newState.filter = new Filter(paramsSaveFilter);
        }
        let newCategories = _.cloneDeep(state.categories_default);
        if (newState.filter.category_code) {
          const list = newState.filter.category_code.split(",");
          list.forEach((x) => setNodeChecked(newCategories, x));
        }
        newState.categories = newCategories;
      }
      if (action.payload.property === "shipMethod") {
        if (!action.payload.value.includes(ShipMethod.ktv)) {
          newState.filter.shipperId = "";
          newState.filter.shipper_state = "";
        }
      }
      if (action.payload.property === "shipperId") {
        if (state.filter.shipMethod.search("ktv") === -1) {
          let arr = state.filter.shipMethod
            ? state.filter.shipMethod.split(",")
            : [];
          arr.push("ktv");
          newState.filter.shipMethod = arr.toString();
        }
      }
      newState.filter[action.payload.property] = action.payload.value;
      newState.filter.currentPage = 0;
      newState.filter.selection = { 0: [] };
      newState.batchActions.groupedBls = {};

      return newState;

    case "CLEAR_FILTERS":
      window.localStorage.setItem("queryString", "");
      return {
        ...state,
        filter: new Filter(),
        batchActions: {
          groupedBls: {},
          response: {},
        },
        categories: state.categories_default,
      };

    case "FETCH_BL_LIST_SUCCESS":
      // FIXME: window.scroll shouldn't place here
      window.scroll(0, 0);
      let bls = action.payload.data.bls.map((bl, idx) => new Bl(bl, idx));
      newState = _.cloneDeep(state);
      newState = {
        ...state,
        lastSearch: _.cloneDeep(state.filter),
        bls: bls,
      };
      newState.filter.expandedRows = [];
      newState.filter.totalCount = action.payload.data.total_records;
      return newState;

    case "SET_TABLE_SELECTION":
      newState = _.cloneDeep(state);
      newState.filter.selection[newState.filter.currentPage] =
        action.payload.selection;
      newState.batchActions.groupedBls[newState.filter.currentPage] =
        action.payload.rows;
      newState.batchActions.response = {};
      return newState;

    case "CHANGE_TABLE_PAGE":
      newState = _.cloneDeep(state);
      newState.filter.currentPage = action.payload.page;
      if (!newState.filter.selection[action.payload.page]) {
        newState.filter.selection[action.payload.page] = [];
      }
      return newState;

    case "CHANGE_TABLE_PAGE_SIZE":
      newState = _.cloneDeep(state);
      newState.filter.currentPage = 0;
      newState.filter.pageSize = action.payload.pageSize;
      if (!newState.filter.selection[action.payload.page]) {
        newState.filter.selection[action.payload.page] = [];
      }
      return newState;

    case "EXPAND_TABLE_ROWS":
      newState = _.cloneDeep(state);
      newState.filter.expandedRows = action.payload.rows;
      return newState;

    case "BATCH_UPDATE_SUCCESS":
      return {
        ...state,
        batchActions: {
          ...state.batchActions,
          response: action.payload.response,
        },
      };

    case "REMOVE_SELECTED_BL":
      newState = _.cloneDeep(state);
      newState.filter.selection[action.payload.page] =
        newState.filter.selection[action.payload.page].filter((idx) => {
          return idx !== action.payload.index;
        });
      newState.batchActions.groupedBls[action.payload.page] =
        newState.batchActions.groupedBls[action.payload.page].filter((bl) => {
          return bl.id !== action.payload.id;
        });
      return newState;

    case "CREATE_TRANSPORT_SUCCESS":
      newState = _.cloneDeep(state);
      let bl = newState.bls
        .map((bl, idx) => {
          bl.index = idx;
          return bl;
        })
        .filter((bl) => bl.id === action.payload.id)[0];
      newState.filter.expandedRows = newState.filter.expandedRows.filter(
        (row) => row !== bl.index
      );
      newState.bls = newState.bls.filter((bl) => bl.id !== action.payload.id);
      newState.showMsg = {
        show: true,
        id: action.payload.id,
      };
      return newState;

    case "DISMISS_SNACKBAR":
      return {
        ...state,
        showMsg: {
          show: false,
          id: "",
        },
      };
    case "DELETE_SAVE_FILTER":
      let _currentTab = state.currentTab;
      let newFilter = state.filter;
      if (action.payload.id + 2 === _currentTab) {
        _currentTab = 0;
        newFilter = new Filter(
          getParamsFromUrl(window.localStorage.getItem("queryString"))
        );
      } else if (action.payload.id + 2 < _currentTab) {
        _currentTab--;
      }
      window.localStorage.setItem("currentTab", _currentTab);
      return {
        ...state,
        currentTab: _currentTab,
        filter: newFilter,
      };
    case "SAVE_FILTER":
      return {
        ...state,
        savedFilter: action.payload.filters,
        visibleFilter: action.payload.filters
          .filter((x) => x.display_state)
          .sort(function (a, b) {
            return new Date(b.updated_at) - new Date(a.updated_at);
          })
          .reverse(),
      };
    case "SET_USER_FILTER":
      let currentFilter = state.filter;
      if (
        currentTab >= 2 &&
        currentTab - 2 >= 0 &&
        savedFilter.length > 0 &&
        savedFilter[currentTab - 2]
      ) {
        searchParams = savedFilter[currentTab - 2].query;
        currentFilter = new Filter(getParamsFromUrl(searchParams));
      }
      return {
        ...state,
        filter: currentFilter,
        savedFilter: action.payload.filters,
        visibleFilter: action.payload.filters
          .filter((x) => x.display_state)
          .sort(function (a, b) {
            return new Date(b.updated_at) - new Date(a.updated_at);
          })
          .reverse(),
      };
    case "LOAD_FILTER":
      const loadFilter = state.visibleFilter;
      let newCat = _.cloneDeep(state.categories_default);
      let filter;
      if (
        typeof action.payload.index != "undefined" &&
        action.payload.index - 2 >= 0
      ) {
        const newparams = getParamsFromUrl(
          loadFilter[action.payload.index - 2].query
        );
        filter = new Filter(newparams);
        if (filter.category_code) {
          const list = filter.category_code.split(",");
          list.forEach((x) => setNodeChecked(newCat, x));
        }
      } else {
        filter = new Filter({});
      }
      return {
        ...state,
        filter,
        categories: newCat,
      };
    case "SET_TAB":
      window.localStorage.setItem("currentTab", action.payload.index);
      return {
        ...state,
        currentTab: action.payload.index,
      };

    case "SET_ALL_DISTRICT":
      return {
        ...state,
        districtList: action.payload,
      };
    case "SET_SELECTED_BL":
      return {
        ...state,
        selected: action.payload,
      };
    case "SET_SELECTED_BL_3PL":
      return {
        ...state,
        selectedBl3Pls: action.payload,
      };
    case "FETCH_BL_LIST_SHIPPER_LIST_SUCCESS":
      return {
        ...state,
        ktvList: action.payload,
      };
    case "BL_LIST_SET_CATEGORIES_LIST":
      return {
        ...state,
        categories: action.payload,
      };
    case "BL_LIST_SET_CATEGORIES_LIST_DEFAULT":
      return {
        ...state,
        categories_default: action.payload,
      };
    case "BL_LIST_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "BL_LIST_SET_FLAT_CATEGORIES_LIST":
      return {
        ...state,
        flatCategories: action.payload,
      };

    default:
      return state;
  }
};

export default list;

function setNodeChecked(categories, code) {
  categories.forEach((element) => {
    if (element.value === code) {
      setAllChildElement(element, true);
    } else if (element.children) {
      setNodeChecked(element.children, code);
    }
  });
}

function setAllChildElement(node, checked) {
  node.checked = checked;
  if (node.children) {
    node.children.forEach((element) => {
      setAllChildElement(element, checked);
    });
  }
}
