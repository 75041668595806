import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  AppBar,
  Toolbar,
  IconButton,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { toggleDetailDialog } from "../../actions";
import { BlGroupDetail } from "../detail";

//styles
const styles = (theme) => ({
  flex: {
    flex: 1,
    fontFamily: "sans-serif",
  },
  detailContainer: {
    paddingTop: "1rem",
  },
});

class DetailDialog extends React.Component {
  render() {
    const { classes, detailDialog, toggleDetailDialog } = this.props;
    return (
      <Dialog
        fullScreen
        open={detailDialog}
        onClose={() => toggleDetailDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar>
          <Toolbar>
            <Typography type="title" color="inherit" className={classes.flex}>
              TẠO LỘ TRÌNH NHANH
            </Typography>
            <IconButton
              color="inherit"
              onClick={() => toggleDetailDialog(false)}
              aria-label="Close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogTitle id="alert-dialog-title">Tạo lộ trình nhanh</DialogTitle>
        <DialogContent className={classes.detailContainer}>
          <BlGroupDetail />
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  detailDialog: state.admin.group.detailDialog,
});

const mapDispatchToProps = (dispatch) => ({
  toggleDetailDialog: (state) => dispatch(toggleDetailDialog(state)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(DetailDialog)
);
