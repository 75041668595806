import React from "react";
import Select from "react-select";

class DistrictSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      districtList: [],
    };
  }
  componentDidMount() {
    const { province, fetchArea, district, setDefaultDistrict } = this.props;
    if (province) {
      const list = [];
      fetchArea(province).then((areas) => {
        if (areas) {
          areas.forEach((area) => {
            if (area.code === district) {
              setDefaultDistrict(area.name);
            }
            list.push({
              value: area.code,
              label: area.name,
            });
          });
          this.setState({
            districtList: list,
          });
        }
      });
    } else {
      this.setState({
        districtList: [],
      });
    }
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { province, fetchArea } = nextProps;
    if (province) {
      if (province !== this.props.province) {
        await fetchArea(province).then((areas) => {
          let list = [];
          areas.forEach((area) => {
            list.push({
              value: area.code,
              label: area.name,
            });
          });
          this.setState({
            districtList: list,
          });
        });
      }
    } else {
      this.setState({
        districtList: [],
      });
    }
  }

  _handleSelectChange = (e) => {
    const label = e && e.label;
    const value = e && e.value;
    this.props.changeDistrict(value, label);
  };

  render() {
    const { districtList } = this.state;
    const { district } = this.props;
    return (
      <Select
        value={district}
        onChange={this._handleSelectChange}
        options={districtList}
        wrapperStyle={{ flex: 1 }}
        placeholder="Quận/Huyện"
        style={{ height: 40 }}
      />
    );
  }
}

export default DistrictSelector;
