import React from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import BlNotification from "../../../bl_detail/components/bl/BlNotification";

const styles = (theme) => ({
  subtitle: {
    fontSize: 14,
    padding: "8px 0 16px 0",
    fontFamily: "Source Sans Pro, sans-serif",
    textTransform: "uppercase",
    fontWeight: 600,
    letterSpacing: 1,
    color: "#5d9cec",
  },
  rowDetail: {
    fontSize: 14,
    display: "flex",
    paddingBottom: 16,
    fontFamily: "Noto Sans, sans-serif",
    direction: "row",
    alignItems: "center",
  },
  input: {
    height: 34,
    border: "1px solid #ccc",
    borderRadius: 4,
    paddingLeft: 8,
  },
  text: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
  },
  button: {
    textTransform: "none",
    marginLeft: "10px",
  },
});
class BlInfo extends React.Component {
  getSalesmanInfo = (salesman) => {
    const id = salesman.asia_id
      ? `${salesman.asia_id.toString().padStart(0, 4)} - ${salesman.id}`
      : salesman.id;
    const department = salesman.extra_info
      ? salesman.extra_info.filter((x) => x.type === "accounting_code")
      : "";
    return (
      <span>
        {id} - {salesman.name}{" "}
        {department.length > 0 ? `- ${department[0].value}` : ""} -{" "}
        {salesman.phone}
      </span>
    );
  };

  render() {
    let { bl, classes } = this.props;
    return (
      <div className={classes.text}>
        <Typography type="subheading" className={classes.subtitle}>
          Thông tin vận đơn
        </Typography>
        <div className={classes.text}>
          <Grid container>
            <Grid item md={6}>
              <span className={classes.rowDetail}>
                <strong>Kho gửi: </strong>&nbsp; {bl.fromAddr.displayName}
              </span>
              <span className={classes.rowDetail}>
                <strong>Ngày xuất kho: </strong>&nbsp; {bl.requestCreatedAt}
              </span>
              <span className={classes.rowDetail}>
                <strong>Mã gói hàng: </strong>&nbsp; {bl.document_id}
              </span>
              <span className={classes.rowDetail}>
                <strong>Ghi chú: </strong>&nbsp; {bl.note ? bl.note : "Không"}
              </span>
              {bl.exportSource.toLowerCase() === "wms" && !bl.isWhTransfer && (
                <span className={classes.rowDetail}>
                  <strong>Nhân viên bán hàng: </strong>
                  {bl.salesman && bl.salesman.id
                    ? this.getSalesmanInfo(bl.salesman)
                    : ""}
                </span>
              )}
            </Grid>
            <Grid item md={6}>
              <span className={classes.rowDetail}>
                <strong>Người liên hệ: </strong>
                &nbsp; {bl.fromAddr.name} - {bl.fromAddr.phone}
              </span>
              <span className={classes.rowDetail}>
                <strong>Ngày hẹn giao: </strong>
                &nbsp; {bl.shipDate}
              </span>
              {bl.requestType !== "WH_TRANSFER" && (
                <span className={classes.rowDetail}>
                  <strong>Mã đơn hàng: </strong>&nbsp; {bl.saleOrderId}
                </span>
              )}
              <span className={classes.rowDetail}>
                <strong>Số hóa đơn: </strong>&nbsp; {bl.invoiceId}
              </span>
            </Grid>
          </Grid>
        </div>
        {bl.exportSource.toLowerCase() === "wms" && <BlNotification bl={bl} />}
      </div>
    );
  }
}

export default withStyles(styles)(BlInfo);
