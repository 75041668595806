import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  ConfirmPicked,
  ConfirmOK,
  ConfirmImportOK,
  ConfirmReturn,
  ConfirmCancel,
} from "../components";
import {
  confirmTransportDelivered,
  confirmTransportPicked,
  confirmTransportImported,
  confirmTransportCancelled,
  confirmTransportReturned,
  confirmShippingTransportCancelled,
  stockRequest,
} from "../actions";
import { BlStockStatus, BlState, TransportState } from "../../../../models";
import { ShipMethod, ShipType } from "@constants";

const styles = (theme) => ({
  row: {
    display: "flex",
    justifyContent: "flex-start",
  },
});

class TransportActionContainer extends React.Component {
  /*
  Case 1: Vận đơn từ WH (vận đơn KH / vận đơn nội bộ):
    + transport xuất phát từ kho: 3 bước lấy hàng (lấy hàng --> giao hàng cho 3pls)
  Case 2: Còn lại: giao hàng cho 3pls

  */
  renderPickButton = () => {
    const { bl } = this.props;
    const transport = bl.lastTransport;
    let title = "Giao hàng cho 3PLs";
    let isStockRequest = false;

    if (bl.exportSource.toUpperCase() === "WMS") {
      if (bl.stockStatus === BlStockStatus.init && bl.state === BlState.init) {
        isStockRequest = true;
        title = "Lấy hàng";
      }
    }

    if (title)
      return (
        <ConfirmPicked
          bl={bl}
          title={title}
          isStockRequest={isStockRequest}
          transport={transport}
          confirmTransportDelivered={this.props.confirmTransportDelivered}
          confirmTransportPicked={this.props.confirmTransportPicked}
          stockRequest={() => this.props.stockRequest(bl.id)}
        />
      );
  };

  /* BuTTON HOÀN HÀNG
  Case 1: Vận đơn từ WH (vận đơn KH):
    Nếu có yêu cầu hủy từ OM
      + transport đầu tiên (xuất phát từ kho): Gửi yêu cầu hoàn hàng
      + transport t2 trở đi (xuất phát từ hub/ktv): Xác nhận hub hoàn hàng
    Ngược lại
      + Ẩn nút hoàn hàng
  Case 2: Vận đơn từ ASIA:
    + transport đầu tiên + gửi bằng 3pl đến KTV/KH/Hub : Gửi yêu cầu hoàn hàng
    + transport t2 trở đi: Xác nhận hoàn hàng
  Case 3: CKNB: không cho hoàn hàng
  */
  renderReturnButton = () => {
    const { bl } = this.props;
    const { last_om_cancel_request } = bl;

    const transport = bl.lastTransport;
    let title = "",
      confirmMsg = "";

    if (!bl.isWhTransfer) {
      if (bl.exportSource.toLowerCase() === "wms") {
        if (!last_om_cancel_request) {
          return null;
        }
      }
      if (
        bl.lastTransport.fromAddress.objId === bl.fromAddr.objId &&
        ![ShipMethod.ktv, ShipMethod.direct].includes(
          bl.lastTransport.shipMethod
        ) &&
        [ShipType.hub_customer, ShipType.hub_hub, ShipType.hub_ktv].includes(
          bl.lastTransport.type
        )
      ) {
        title = "Gửi yêu cầu hoàn hàng";
        //Nếu shipMethod = adidi, hiển thị rõ Gửi yêu cầu hoàn 1 phần hay toàn phần
        const { action } = last_om_cancel_request;
        if (
          [
            ShipMethod.adidi,
            ShipMethod.nv_adidi_manual,
            ShipMethod.vnt_adidi_manual,
          ].includes(transport.shipMethod) &&
          action === "partial"
        ) {
          title = `Gửi yêu cầu hoàn hàng một phần`;
        }
        confirmMsg = "Bạn muốn gửi yêu cầu hoàn hàng cho lộ trình hiện tại?";
      } else {
        title = "Xác nhận hub hoàn hàng";
      }
      return (
        <ConfirmReturn
          transport={transport}
          title={title}
          confirmMsg={confirmMsg}
          confirmTransportReturned={this.props.confirmTransportReturned}
        />
      );
    }
    return null;
  };

  renderImportOkButton = () => {
    const { bl } = this.props;
    const transport = bl.lastTransport;
    let title = "",
      confirmMsg = "";
    if (
      [BlState.returning, BlState.partial_returning].includes(bl.state) &&
      [TransportState.shipping, TransportState.retry].includes(
        bl.lastTransport.state
      )
    ) {
      title = "Xác nhận hoàn tới hub";
    } else {
      title = "Xác nhận nhập hub";
    }
    return (
      <ConfirmImportOK
        title={title}
        confirmMsg={confirmMsg}
        transport={transport}
        confirmTransportImported={this.props.confirmTransportImported}
      />
    );
  };

  render() {
    const { bl, user, classes } = this.props;
    const transport = bl.currentTransport;
    return (
      <div className={classes.row}>
        {user.canConfirmPicked(transport) &&
          bl.canConfirmPicked &&
          this.renderPickButton()}

        {user.canConfirmOK(transport) && bl.canConfirmOK && (
          <ConfirmOK
            transport={transport}
            confirmTransportDelivered={this.props.confirmTransportDelivered}
          />
        )}

        {user.canConfirmOK(transport) &&
          bl.canConfirmImportOK &&
          this.renderImportOkButton()}

        {user.canConfirmImportForShippingCancel(transport) &&
          bl.canConfirmImportForShippingCancel && (
            <ConfirmReturn
              title={"Xác nhận hub hoàn hàng"}
              transport={transport}
              confirmTransportReturned={this.props.confirmTransportImported}
              showReason={false}
            />
          )}

        {/* Ẩn nút hoàn hàng với vận đơn chuyển kho nội bộ  */}
        {user.canConfirmReturn(transport) &&
          bl.canConfirmReturn &&
          bl.ableToConfirmReturn &&
          this.renderReturnButton()}

        {user.canConfirmTransportCancel(bl) &&
          ((bl.canConfirmTransportCancel && ( // hủy lộ trình khi hàng đang ở hub và đang chờ lấy  hàng
            <ConfirmCancel
              bl={bl}
              transport={transport}
              confirmTransportCancelled={this.props.confirmTransportCancelled}
            />
          )) ||
            (bl.canConfirmShippingTransportCancel && ( //hủy lộ trình với trạng thái đang giao hàng
              <ConfirmCancel
                bl={bl}
                transport={transport}
                confirmTransportCancelled={
                  this.props.confirmShippingTransportCancelled
                }
              />
            )))}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  bl: state.admin.detail.bl,
  user: state.admin.shared.user,
});

const mapDispatchToProps = (dispatch) => ({
  confirmTransportDelivered: (id) => dispatch(confirmTransportDelivered(id)),
  confirmTransportPicked: (id) => dispatch(confirmTransportPicked(id)),
  confirmTransportImported: (id) => dispatch(confirmTransportImported(id)),
  confirmTransportCancelled: (params) =>
    dispatch(confirmTransportCancelled(params)),
  confirmTransportReturned: (id, reason) =>
    dispatch(confirmTransportReturned(id, reason)),
  confirmShippingTransportCancelled: (params) =>
    dispatch(confirmShippingTransportCancelled(params)),
  stockRequest: (bl_id) => dispatch(stockRequest(bl_id)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(TransportActionContainer)
);
