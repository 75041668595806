export default class Hub {
  constructor(hub) {
    this.id = hub.id;
    this.name = hub.name;
    this.fullAddress = hub.full_address;
    this.active = hub.active;
    this.contactName = hub.contact_name;
    this.contactPhone = hub.contact_phone;
    this.areaCode = hub.area_code;
    this.area = hub.area ? hub.area : { region: null };
    this.sellerId = hub.seller_id ? hub.seller_id : { sellerId: null };
  }
}
