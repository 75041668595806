import _ from "lodash";

export const TABLE_COLUMN = {
  actions: "actions",
  document_id: "document_id",
  id: "id",
  blOrderId: "blOrderId",
  status: "status",
  shipMethod: "shipMethod",
  note: "note",
  fromHub: "fromHub",
  addr: "addr",
  addrDistrict: "addrDistrict",
  addrWard: "addrWard",
  toTransitHub: "toTransitHub",
  lastTransportCreatedAt: "lastTransportCreatedAt",
  requestCreatedAt: "requestCreatedAt",
  estimatedTime: "estimatedTime",
  coordinator_note: "coordinator_note",
  seller_id: "seller_id",
  systemStatus: "systemStatus",
  shipDate: "shipDate",
};

export const mapBlsToTableRows = (bls) => {
  return bls.map((bl) => {
    let row = bl;
    const blExtends = {
      saleOrderId: bl.blOrderId,
      shipMethod: bl.lastTransport.shipMethod,
      fromHub: bl.fromAddr.displayName,
      to: bl.toAddr.displayName,
      addr: bl.toAddr.fullAddress,
      addrDistrict: bl.toAddr.area ? bl.toAddr.area.province : "",
      addrWard:
        bl.toAddr.area && bl.toAddr.areaCode && bl.toAddr.areaCode.length === 6
          ? bl.toAddr.area.name
          : "",
      plRef: bl.lastTransport.plRef,
      lastTransportToAddr: bl.lastTransport.toAddress,
      lastTransportCreatedAt: bl.lastTransport.createdAt,
      shipper: bl.lastTransport.shipper,
      estimatedTime: bl.lastTransport.edt,
    };
    return _.merge(row, blExtends); //deep clone & clone in order not to lose any getters and setters
  });
};

export const columnTemplate = [
  { name: TABLE_COLUMN.actions, title: " " },
  { name: TABLE_COLUMN.id, title: "Mã vận đơn" },
  { name: TABLE_COLUMN.document_id, title: "Mã gói hàng" },
  { name: TABLE_COLUMN.blOrderId, title: "Mã đơn hàng" },
  { name: TABLE_COLUMN.status, title: "Trạng thái" },
  { name: TABLE_COLUMN.systemStatus, title: "Trạng thái khác" },
  { name: TABLE_COLUMN.shipMethod, title: "Đ.vị vận chuyển" },
  { name: TABLE_COLUMN.fromHub, title: "Kho xuất phát" },
  { name: TABLE_COLUMN.addr, title: "Nơi nhận" },
  { name: TABLE_COLUMN.addrDistrict, title: "Quận/Huyện" },
  { name: TABLE_COLUMN.addrWard, title: "Phường/Xã" },
  { name: TABLE_COLUMN.toTransitHub, title: "Hub cần thao tác" },
  { name: TABLE_COLUMN.estimatedTime, title: "Thời gian giao dự kiến" },
  { name: TABLE_COLUMN.lastTransportCreatedAt, title: "Ngày tạo lộ trình" },
  { name: TABLE_COLUMN.requestCreatedAt, title: "Ngày xuất kho" },
  { name: TABLE_COLUMN.shipDate, title: "Ngày hẹn giao" },
  { name: TABLE_COLUMN.note, title: "Ghi chú" },
  {
    name: TABLE_COLUMN.coordinator_note,
    title: "Ghi chú của điều vận",
  },
  { name: TABLE_COLUMN.seller_id, title: "Công ty" },
];

export const columnExtension = [
  { columnName: TABLE_COLUMN.actions, width: 40, wordWrapEnabled: true },
  {
    columnName: TABLE_COLUMN.id,
    width: 70,
    wordWrapEnabled: true,
  },
  {
    columnName: TABLE_COLUMN.document_id,
    width: 160,
    wordWrapEnabled: true,
  },
  {
    columnName: TABLE_COLUMN.blOrderId,
    width: 70,
    wordWrapEnabled: true,
  },
  {
    columnName: TABLE_COLUMN.status,
    width: 130,
    wordWrapEnabled: true,
  },
  {
    columnName: TABLE_COLUMN.systemStatus,
    width: 100,
    wordWrapEnabled: true,
  },
  {
    columnName: TABLE_COLUMN.shipMethod,
    width: 160,
    wordWrapEnabled: true,
  },
  {
    columnName: TABLE_COLUMN.fromHub,
    width: 230,
    wordWrapEnabled: true,
  },
  {
    columnName: TABLE_COLUMN.addr,
    width: 230,
    wordWrapEnabled: true,
  },
  {
    columnName: TABLE_COLUMN.addrDistrict,
    width: 160,
    wordWrapEnabled: true,
  },
  {
    columnName: TABLE_COLUMN.addrWard,
    width: 160,
    wordWrapEnabled: true,
  },
  {
    columnName: TABLE_COLUMN.toTransitHub,
    width: 230,
    wordWrapEnabled: true,
  },
  {
    columnName: TABLE_COLUMN.estimatedTime,
    width: 100,
    wordWrapEnabled: true,
  },
  {
    columnName: TABLE_COLUMN.lastTransportCreatedAt,
    width: 100,
    wordWrapEnabled: true,
  },
  {
    columnName: TABLE_COLUMN.requestCreatedAt,
    width: 100,
    wordWrapEnabled: true,
  },
  {
    columnName: TABLE_COLUMN.shipDate,
    width: 100,
    wordWrapEnabled: true,
  },
  { columnName: TABLE_COLUMN.note, wordWrapEnabled: false, width: 200 },
  {
    columnName: TABLE_COLUMN.coordinator_note,
    width: 200,
    wordWrapEnabled: true,
  },
  {
    columnName: TABLE_COLUMN.seller_id,
    wordWrapEnabled: true,
  },
];
