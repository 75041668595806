import React from "react";
import {
  Paper,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { PageTitle } from "@shared";
import { getVersionsApi } from "@api";
import { connect } from "react-redux";

//styles
const styles = (theme) => ({
  page: {
    fontFamily: "Noto Sans, sans-serif",
  },
  paper: {
    margin: "0.5rem",
    padding: "1rem",
    width: "100%",
  },
  headerCell: {
    fontWeight: "bold",
    color: "black",
    fontSize: "0.8rem",
  },
});

class VersionManagement extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
    };
  }

  async componentDidMount() {
    const response = await getVersionsApi();
    if (response.status === 200) {
      const data = response.data;
      this.setState({ data: data.data.versions.reverse() });
    }
  }

  render() {
    const { classes, settings } = this.props;
    const { data } = this.state;
    return (
      <Grid container spacing={1} className={classes.page}>
        <Paper className={classes.paper} elevation={0}>
          <Grid item xs={12}>
            <PageTitle>Thông báo cập nhật hệ thống logistics</PageTitle>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.headerCell}>
                      Phiên bản
                    </TableCell>
                    <TableCell className={classes.headerCell}>
                      Ngày cập nhật
                    </TableCell>
                    <TableCell className={classes.headerCell}>
                      Mô tả &amp; Tài liệu
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.name}{" "}
                        {settings &&
                          settings.CURRENT_VERSION &&
                          settings.CURRENT_VERSION.name === row.name && (
                            <span style={{ color: "#4ed569" }}>&#10004;</span>
                          )}
                      </TableCell>
                      <TableCell>
                        {row.released_date.toLocaleString("vi-VN")}
                      </TableCell>
                      <TableCell>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: row.description,
                          }}
                        ></div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Paper>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  settings: state.admin.shared.settings,
});

export default withStyles(styles)(connect(mapStateToProps)(VersionManagement));
