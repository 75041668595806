import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { formatDateTime, getKtvLabelFromModel } from "@utils";
import { connect } from "react-redux";
import { fetchBlHistory } from "../../actions/blActions";
import { BlHistoryAction } from "@constants";
import { Typography, Collapse, IconButton } from "@material-ui/core";
import classnames from "classnames";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export const styles = (theme) => ({
  productHeading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  nameCell: {
    maxWidth: "12rem",
  },
  addressCell: {
    maxWidth: "20rem",
  },
  createdDateCell: {
    maxWidth: "7rem",
  },
  createdUserCell: {
    maxWidth: "10rem",
  },
  phoneCell: {
    minWidth: "5rem",
  },
  subTitle: {
    fontSize: 14,
    fontFamily: "Source Sans Pro, sans-serif",
    textTransform: "uppercase",
    fontWeight: 600,
    letterSpacing: 1,
    color: "#f05050",
  },
  "@global": {
    ".historyTable": {
      borderSpacing: 0,
      width: "100%",
      fontSize: 13,
    },
    "table.historyTable>thead>tr": {
      fontWeight: "bold",
      color: "rgba(0, 0, 0, 0.54)",
    },
    "table.historyTable>tbody>tr>td, table.historyTable>thead>tr>td": {
      borderBottom: "1px solid #8b888830",
      fontFamily: "Noto Sans, sans-serif",
      padding: 10,
    },
  },
});

const mapActionToImage = (action) => {
  switch (action) {
    case BlHistoryAction.create_transport:
      return "create";
    case BlHistoryAction.to_3pls:
    case BlHistoryAction.pick_up:
      return "pick";
    case BlHistoryAction.transport_done:
    case BlHistoryAction.bl_done:
      return "delivered";
    case BlHistoryAction.cancel_transport:
    case BlHistoryAction.cancel_bl:
    case BlHistoryAction.system_cancel_transport:
      return "cancel";
    case BlHistoryAction.print_label:
    case BlHistoryAction.print_bill:
      return "print";
    case BlHistoryAction.confirm_return_hub:
    case BlHistoryAction.ktv_returning:
    case BlHistoryAction.request_return:
      return "return";
    default:
      return "info";
  }
};

class BlHistory extends React.Component {
  blLinesHeight = null;
  state = {
    open: true,
  };

  componentDidMount() {
    const { getBlHistory, bl } = this.props;
    getBlHistory(bl.id);
    const blLines = document.getElementById("blLines");
    this.blLinesHeight = blLines.offsetHeight;
  }

  render() {
    const { classes, history } = this.props;
    const { open } = this.state;
    return (
      <React.Fragment>
        <div
          className={classes.productHeading}
          onClick={() => this.setState({ open: !open })}
        >
          <Typography type="title" className={classes.subTitle}>
            Lịch sử vận đơn
          </Typography>
          <IconButton
            aria-label="Xem thêm"
            className={classnames(classes.expand, {
              [classes.expandOpen]: !open,
            })}
          >
            <ExpandMoreIcon />
          </IconButton>
        </div>
        <br />
        <Collapse
          in={open}
          unmountOnExit
          style={{ maxHeight: this.blLinesHeight, overflowY: "auto" }}
        >
          <table className="historyTable">
            <thead>
              <tr>
                <td style={{ width: "5%" }} />
                <td style={{ width: "15%" }}>Thao tác</td>
                <td style={{ width: "15%" }}>Người thao tác</td>
                <td style={{ width: "10%" }}>Kênh</td>
                {/* <td>Giá trị ban đầu</td>
                <td>Giá trị sau khi thao tác</td> */}
                <td>Ghi chú</td>
                <td style={{ width: "15%" }}>Thời gian</td>
              </tr>
            </thead>
            <tbody>
              {history.length > 0 ? (
                history.map((x, index) => (
                  <tr key={index}>
                    <td className={classes.createdDateCell}>
                      <img
                        width="25"
                        height="25"
                        src={require(`@images/${mapActionToImage(
                          x.action
                        )}_48.png`)}
                      />
                    </td>
                    <td className={classes.nameCell}>{x.action_label}</td>
                    <td className={classes.phoneCell}>
                      {getKtvLabelFromModel(x.user)}
                    </td>
                    <td className={classes.nameCell}>{x.display_channel}</td>
                    {/* <td className={classes.nameCell}>{x.data_before}</td>
                    <td className={classes.nameCell}>{x.data_after}</td> */}
                    <td style={{ maxWidth: "30px", wordBreak: "break-all" }}>
                      {x.note === null ? "" : x.note}
                    </td>
                    <td className={classes.addressCell}>
                      {formatDateTime(x.created_at)}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5}>Không có</td>
                </tr>
              )}
            </tbody>
          </table>
        </Collapse>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  history: state.admin.detail.history,
  bl: state.admin.detail.bl,
  ktvList: state.admin.shared.allKtvList,
});

const mapDispatchToProps = (dispatch) => ({
  getBlHistory: (bl_id) => dispatch(fetchBlHistory(bl_id)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(BlHistory)
);
