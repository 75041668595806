import React from "react";
import { DateTimePicker } from "@material-ui/pickers";

class EstimatedDeliveryTimePicker extends React.Component {
  handleDateChange = (date) => {
    const { changeEDT } = this.props;
    const formattedDate = date ? date.format("YYYY-MM-DD HH:mm") : null;
    changeEDT(formattedDate);
  };

  render() {
    const { value } = this.props;
    return (
      <DateTimePicker
        value={value}
        ampm={false}
        disablePast
        format="DD/MM/YYYY HH:mm"
        onChange={this.handleDateChange}
        clearable
        invalidLabel="Chọn ngày giờ"
        TextFieldComponent={DateTimeInput}
      />
    );
  }
}

const DateTimeInput = (props) => {
  return (
    <input
      value={props.value}
      onClick={props.onClick}
      {...props.InputProps}
      className="input"
    />
  );
};

export default EstimatedDeliveryTimePicker;
