export const openNotiDialog = (
  type /* success | failed | info | alert */,
  text,
  closeCallback,
  options /* single | ? */,
  confirmCallback,
  customActionButtons
) => {
  return {
    type: "OPEN_SHARED_NOTIFICATION_DIALOG",
    payload: {
      type: type,
      text: text,
      closeCallback: closeCallback,
      confirmCallback: confirmCallback,
      options: options,
      customActionButtons: customActionButtons,
    },
  };
};

export const closeNotiDialog = () => ({
  type: "CLOSE_NOTIFICATION_DIALOG",
});

export const toggleSnackBar = (open, message, type = "") => ({
  type: "TOGGLE_SNACKBAR",
  payload: {
    open,
    message,
    type,
  },
});
