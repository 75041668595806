import _ from "lodash";

export default class Filter {
  // Lưu thông tin tìm kiếm của user. Khởi tạo từ url hiện tại
  constructor(params = {}) {
    this.keyword = params.keyword || "";
    this.tab = params.tab || 0;
    this.requestType = params.request_type ? params.request_type : "";
    this.saleOrderId = params.sale_order_id || "";
    this.bl_to_addr = params.bl_to_addr || "";
    this.id = params.bl_id || "";
    this.plRef = params.pl_ref || "";
    this.status = params.status || "";
    this.state = params.bl_state || "";
    this.shipMethod = params.last_transport_ship_method
      ? params.last_transport_ship_method
      : "";
    this.shipperId = params.shipper_id || "";
    this.fromHub = params.from_hub_id || "";
    this.toHub = params.to_hub_id || "";
    this.isLastTransportOnly = Number(params.is_last_transport_only) || 0;
    this.exportDateFrom = params.export_date_from
      ? params.export_date_from
      : "";
    this.exportDateTo = params.export_date_to || "";
    this.shipDateFrom = params.ship_date_from || "";
    this.shipDateTo = params.ship_date_to || "";
    this.expandedRows = [];
    this.totalCount = 0;
    this.currentPage =
      params.offset && params.limit
        ? parseInt(params.offset, 10) / parseInt(params.limit, 10)
        : 0;
    this.pageSize = 10;
    this.allowedPageSizes = [10, 30, 50, 100, 150];
    const field = params.field === "bl_id" ? "id" : params.field;
    this.sorting = {
      columnName: field ? _.camelCase(field) : "shipDate",
      direction: params.order || "asc",
    };
    this.selection = { 0: [] };
    this.quickFilter = params.tab && params.tab === "waiting" ? "waiting" : "";
    this.requestId = params.request_id || "";
    this.deliveryTypes = params.delivery_types || "";
    this.toTransitHubId = params.to_transit_hub_id || "";
    this.createTransportFrom = params.create_transport_from || "";
    this.createTransportTo = params.create_transport_to || "";
    this.transportCreatedUser = params.transport_created_user || "";
    this.to_area = params.to_area || null;
    this.is_cod_bl = params.is_cod_bl || "";
    this.channel = params.channel || "";
    this.packed_status = params.packed_status || "";
    this.edit_ship_date_reason = params.edit_ship_date_reason || "";
    this.shipper_state = params.shipper_state || "";
    this.category_code = params.category_code || "";
    this.seller_id = params.seller_id || "";
  }
}
