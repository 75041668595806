import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";

import {
  ProvinceSelector,
  DistrictSelector,
  WardSelector,
  Dialog,
} from "../../../../../shared";
import { Typography } from "@material-ui/core";

const styles = (theme) => ({
  actions: {
    display: "flex",
  },
  subTitle: {
    fontSize: 14,
    fontFamily: "Source Sans Pro, sans-serif",
    textTransform: "uppercase",
    fontWeight: 600,
    letterSpacing: 1,
    color: "#f05050",
  },
  productHeading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  text: {
    fontFamily: "sans-serif",
  },
});

class AddCustomerAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      province: "",
      district: "",
      ward: "",
      updating: false,
      error: false,
    };
  }

  changeProvince = (province) =>
    this.setState({
      province: province,
      district: "",
      ward: "",
    });

  changeDistrict = (district) =>
    this.setState({
      district: district,
      ward: "",
    });

  changeWard = (ward) =>
    this.setState({
      ward,
    });

  addCustomerAddress = async () => {
    const { ward, district } = this.state;
    const { bl } = this.props;
    this.setState({
      updating: true,
    });

    if (ward) {
      let response = await this.props.addCustomerAddress(bl.id, ward);
      if (!response.error) {
        this.props.confirmAreaCodeAdded();
      }
    } else if (district) {
      let response = await this.props.addCustomerAddress(bl.id, district);
      if (!response.error) {
        this.props.confirmAreaCodeAdded();
      }
    } else {
      this.toggleErrorDialog();
    }
  };

  toggleErrorDialog = () => {
    this.setState({
      error: !this.state.error,
      updating: false,
    });
  };

  render() {
    const { province, district, ward, updating, error } = this.state;
    const { bl, classes } = this.props;
    return (
      <React.Fragment>
        <div className={classes.text}>
          <span>Địa chỉ thực tế: {bl.toAddr.fullAddress}</span>
          <br />
          <br />
          <Grid container>
            <Grid item md={3}>
              <ProvinceSelector
                province={province}
                changeProvince={this.changeProvince}
                fetchArea={this.props.fetchArea}
              />
            </Grid>

            <Grid item md={3}>
              <DistrictSelector
                province={province}
                district={district}
                changeDistrict={this.changeDistrict}
                fetchArea={this.props.fetchArea}
              />
            </Grid>

            <Grid item md={3}>
              <WardSelector
                district={district}
                ward={ward}
                changeWard={this.changeWard}
                fetchArea={this.props.fetchArea}
              />
            </Grid>
            <Grid item md={1}>
              <div className={classes.actions}>
                <IconButton onClick={this.addCustomerAddress}>
                  {updating ? (
                    <CircularProgress size={20} style={{ color: "#3f51b5" }} />
                  ) : (
                    <i className="material-icons" style={{ color: "#3f51b5" }}>
                      save
                    </i>
                  )}
                </IconButton>
              </div>
            </Grid>
          </Grid>
          <Dialog
            open={error}
            closeDialog={this.toggleErrorDialog}
            title="Oops!"
          >
            Bạn cần điền đầy đủ thông tin địa chỉ để tiếp tục!
          </Dialog>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(AddCustomerAddress);
