import React from "react";
import Select from "react-select";
import { connect } from "react-redux";

export const options = [
  {
    value: {
      val: "5",
      type: "urban",
    },
    label: "Giao hàng tận nơi",
  },
  {
    value: {
      val: "1",
      type: "urban",
    },
    label: "Giao hàng tận nơi và hỗ trợ kỹ thuật",
  },
  {
    value: {
      val: "13",
      type: "urban",
    },
    label: "Giao hàng bảo hành tận nơi",
  },
  {
    value: {
      val: "3",
      type: "urban",
    },
    label: "Giao hàng để lắp đặt camera trong nhà",
  },
  {
    value: {
      val: "4",
      type: "urban",
    },
    label: "Giao hàng để lắp đặt camera ngoài trời",
  },
  {
    value: {
      val: "7",
      type: "urban",
    },
    label: "Giao hàng cho phòng máy",
  },
  {
    value: {
      val: "8",
      type: "urban",
    },
    label: "Bảo hành tận nơi",
  },
  {
    value: {
      val: "10,11,12",
      type: "urban",
    },
    label: "Dịch vụ sửa chữa",
  },
  {
    value: {
      val: "9",
      type: "urban",
    },
    label: "Phí dịch vụ khảo sát",
  },
  {
    value: {
      val: "2",
      type: "urban",
    },
    label: "Phí kiểm tra vận hành mới sau bán hàng",
  },
  {
    value: {
      val: "6",
      type: "urban",
    },
    label: "Phí dịch vụ kỹ thuật",
  },

  {
    value: {
      val: "18",
      type: "rural",
    },
    label: "Giao hàng tận nơi",
  },
  {
    value: {
      val: "14",
      type: "rural",
    },
    label: "Giao hàng tận nơi và hỗ trợ kỹ thuật",
  },
  {
    value: {
      val: "26",
      type: "rural",
    },
    label: "Giao hàng bảo hành tận nơi",
  },
  {
    value: {
      val: "16",
      type: "rural",
    },
    label: "Giao hàng để lắp đặt camera trong nhà",
  },
  {
    value: {
      val: "17",
      type: "rural",
    },
    label: "Giao hàng để lắp đặt camera ngoài trời",
  },
  {
    value: {
      val: "20",
      type: "rural",
    },
    label: "Giao hàng cho phòng máy",
  },
  {
    value: {
      val: "21",
      type: "rural",
    },
    label: "Bảo hành tận nơi",
  },
  {
    value: {
      val: "23,24,25",
      type: "rural",
    },
    label: "Dịch vụ sửa chữa",
  },
  {
    value: {
      val: "22",
      type: "rural",
    },
    label: "Phí dịch vụ khảo sát",
  },
  {
    value: {
      val: "15",
      type: "rural",
    },
    label: "Phí kiểm tra vận hành mới sau bán hàng",
  },
  {
    value: {
      val: "19",
      type: "rural",
    },
    label: "Phí dịch vụ kỹ thuật",
  },
];

class DeliveryTypeFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _options: [],
    };
    const type = ["urban", "rural"];
    let temp = [];
    const { delivery_types } = this.props;
    for (let i = 0; i < type.length; i++) {
      for (let j = 0; j < delivery_types.length; j++) {
        temp.push({
          label: delivery_types[j].description,
          value: {
            val:
              type[i] === "urban"
                ? delivery_types[j].id
                : delivery_types[j].id + 1,
            type: type[i],
          },
        });
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.seller_id === 2 && props.value && props.value.length > 0) {
      props.setFilter("deliveryTypes", []);
    }
  }

  render() {
    const { value, setFilter, seller_id } = this.props;
    return (
      <Select
        options={options}
        placeholder="Chọn loại giao hàng..."
        value={value}
        multi
        autosize
        closeOnSelect={false}
        onChange={(v) => setFilter("deliveryTypes", v ? v : "")}
        wrapperStyle={{ width: "100%" }}
        optionComponent={SelectItem}
        valueComponent={SelectValue}
        disabled={seller_id === 2}
      />
    );
  }
}
class SelectItem extends React.Component {
  handleMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onSelect(this.props.option, event);
  };

  handleMouseEnter = (event) => {
    this.props.onFocus(this.props.option, event);
  };
  handleMouseMove = (event) => {
    if (this.props.isFocused) return;
    this.props.onFocus(this.props.option, event);
  };

  render() {
    const { option, className } = this.props;
    let label = option.label;
    label += option.value.type === "urban" ? " (nội thành)" : " (ngoại thành)";
    return (
      <div
        className={className}
        onMouseDown={this.handleMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseMove={this.handleMouseMove}
      >
        <span>{label}</span>
      </div>
    );
  }
}

class SelectValue extends React.Component {
  render() {
    const { value, onRemove } = this.props;
    let label = value.label;
    const valueStyles = {
      background: value.value.type === "urban" ? "#2fabfd38" : "#ff89897a",
      color: value.value.type === "urban" ? "#0a0ab7" : "#6b1111",
    };
    const closeStyles = {
      background: value.value.type === "urban" ? "#8ddff421" : "#ffd8e0",
      color: "black",
      border: "none",
    };

    return (
      <div className="Select-value" style={{ border: "none" }}>
        <span
          className="Select-value-icon"
          aria-hidden="true"
          style={closeStyles}
          onClick={() => onRemove(value)}
        >
          ×
        </span>
        <div className="Select-value-label" style={valueStyles}>
          {label}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  delivery_types: state.admin.shared.delivery_types,
});

export default connect(mapStateToProps, null)(DeliveryTypeFilter);
