/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Button, CircularProgress, Typography } from "@material-ui/core";
import {
  ServiceTypeSelection,
  ThreePlsCategorySelection,
  VehicleSelection,
} from "../../../bl_detail/components";
import { groupBls, fetchBlGroup3PlsList, setCurrentBl } from "../../actions";
import { getThreePlsSetting } from "@api";
import AdidiServiceSelection from "../../../bl_detail/components/create_transport/AdidiServiceSelection";
import _ from "lodash";
import { NoteInput } from "@shared";
import { Point } from "../../../../../models";

const styles = (theme) => ({
  subTitle: {
    fontSize: 14,
    padding: "8px 0 16px 0",
    fontFamily: "Source Sans Pro, sans-serif",
    textTransform: "uppercase",
    fontWeight: 600,
    letterSpacing: 1,
    color: "#7266ba",
  },
  actionButtonRow: {
    textAlign: "center",
    marginBottom: 20,
  },
  pointConfigContainer: {
    [theme.breakpoints.up("md")]: {
      borderLeft: "1px solid lightgray",
    },
  },
});

const getServiceSetting = (type, settings) => {
  const found = settings[type];
  return found ? found.settings : [];
};

const assignObjectPaths = (obj, stack) => {
  obj.forEach((el, id) => {
    if (stack) el.path = [...stack];
    if (!el.path) el.path = [];
    el.path.push(id);
    el.label =
      el.extra && el.extra.value ? `${el.name} - ${el.extra.value}` : el.name;
    el.checked = false;
    el.expanded = false;
    if (el.extra && el.extra.need_quantity) {
      el.quantity = 1;
    }
    if (el.children && el.children.length > 0) {
      assignObjectPaths(el.children, el.path);
    }
  });
  return obj;
};

const mappingServices = (services, is_children) => {
  let results = [];
  services &&
    services.forEach((service) => {
      let children = [];
      if (service["children"] && service["children"].length > 0) {
        children = mappingServices(service["children"], true);
      }
      if (
        (children.length === 0 && service["checked"]) ||
        children.length > 0
      ) {
        results.push({
          ...service,
          children: children,
        });
      }
    });
  return results;
};

function mapTypeToInt(type) {
  switch (type) {
    case "installation":
      return 3;
    case "deliveryInstallation":
    case "delivery":
      return 2;
    default:
      return type;
  }
}

function GroupAdidiTransport({
  selectedBls,
  groupBls,
  setCurrentBl,
  saveLoading,
  currentBl,
  classes,
}) {
  const [form, setForm] = useState({
    height: 1,
    width: 1,
    length: 1,
    weight: 1,
    inventory_count: 1,
    shipMethod: "adidi",
    ship_type: "hub_customer",
    services: {
      type: "delivery",
    },
  });
  const [extra, setExtra] = useState([]);
  const [settings, setSettings] = useState({});
  const [categories, setCategories] = useState([]);
  const [vehicleTypes, setVechileTypes] = useState([]);
  const [services, setServices] = useState([]);
  const [group, setGroup] = useState([]);
  const [points, setPoints] = useState([]);
  const [partnerNote, setPartnerNote] = useState("");

  useEffect(() => {
    const get3plsSetting = async () => {
      const response = await getThreePlsSetting("adidi");
      if (response.status == 200) {
        setSettings(response.data.data.settings);
      }
    };
    get3plsSetting();
    //generate points from bls
    setPoints(generatePoints(selectedBls));
  }, []);

  useEffect(() => {
    if (Object.keys(settings).length > 0) {
      const type = form.services.type;
      handleChangeType(type);
    }
  }, [settings]);

  useEffect(() => {
    if (currentBl && currentBl.id) {
      const bl_id = currentBl.id;
      const current = selectedBls.find((x) => x.id === bl_id);
      let newPoints = _.cloneDeep(points);
      if (newPoints.length > 0) {
        newPoints.forEach((point) => {
          if (point.bl_id.includes(bl_id) && point.type !== 1) {
            point.contact = {
              name: current.to_addr.display_name,
              phone: current.to_addr.phone,
            };
            point.location = {
              address: current.to_addr.full_address,
            };
          }
        });
        setPoints(newPoints);
      }
    }
  }, [currentBl]);

  const generatePoints = (bls) => {
    const allPoints = [];
    let group = 0;
    bls.forEach((bl) => {
      const blPoints = [
        new Point(bl.from_addr, 1, {
          id: bl.id,
          requestId: bl.request_id,
          services: _.cloneDeep(services),
        }),
        new Point(bl.to_addr, 2, {
          id: bl.id,
          requestId: bl.request_id,
          services: _.cloneDeep(services),
        }),
      ];
      //check if exists
      blPoints.forEach((blPoint) => {
        if (blPoint.type === 1) {
          const exist = allPoints.findIndex(
            (point) =>
              point.objId &&
              point.objId === blPoint.objId &&
              point.type === blPoint.type
          );
          if (exist >= 0) {
            //add distinct bl_id to exist point
            allPoints[exist].bl_id = [
              ...allPoints[exist].bl_id,
              ...blPoint.bl_id,
            ];
            //add distinct request_id to exist point
            allPoints[exist].requestId = [
              ...allPoints[exist].requestId,
              ...blPoint.requestId,
            ];
          } else {
            blPoint.group = group;
            group = group + 1;
            allPoints.push(blPoint);
          }
        } else {
          //find its corresponding start point group
          const startPointIdx = allPoints.findIndex((point) =>
            point.bl_id.includes(blPoint.bl_id[0])
          );
          const startPoint = allPoints[startPointIdx];
          const exist = allPoints.findIndex(
            (point) =>
              point.group === startPoint.group &&
              point.type === 2 &&
              blPoint.objId === point.objId &&
              point.objId
          );
          if (exist >= 0) {
            //add distinct bl_id to exist point
            allPoints[exist].bl_id = [
              ...allPoints[exist].bl_id,
              ...blPoint.bl_id,
            ];
            //add distinct request_id to exist point
            allPoints[exist].requestId = [
              ...allPoints[exist].requestId,
              ...blPoint.requestId,
            ];
          } else {
            blPoint.group = startPoint.group;
            allPoints.push(blPoint);
          }
        }
      });
    });
    return allPoints;
  };

  const changeValue = (item) => {
    setForm({
      ...form,
      ...item,
    });
  };

  const handleChangeType = (v) => {
    changeValue({
      services: { ...form.services, type: v },
    });
    const extraOptions = getServiceSetting(v, _.cloneDeep(settings));
    const group = Object.keys(extraOptions);
    setGroup(group);
    if (extraOptions.categories) {
      setCategories(extraOptions.categories.settings);
    }
    if (extraOptions.vehicleTypes) {
      setVechileTypes(extraOptions.vehicleTypes.settings);
    }
    if (extraOptions.services) {
      assignObjectPaths(extraOptions.services.settings);
      const newServices = extraOptions.services.settings;
      setServices(newServices);
      const newPoints = _.cloneDeep(points);
      newPoints.forEach((x) => {
        x.services = _.cloneDeep(newServices);
        x.type = x.type !== 1 ? mapTypeToInt(v) : x.type;
      });
      setPoints(newPoints);
    }
    //generate extra
    const selectedSetting = settings[v];
    setExtra([selectedSetting]);
  };

  const changeExtra = (type, value) => {
    let newExtra = _.cloneDeep(extra);
    newExtra[0].settings[type].settings = value;
    setExtra(newExtra);
  };

  // Gộp 2 điểm giao hàng liền kề giống nhau
  const optimizeRoute = (route) => {
    let routes = _.cloneDeep(route);
    routes.forEach((point, index) => {
      if (index < routes.length - 1) {
        let nextPoint = routes[index + 1];
        while (
          nextPoint &&
          point.type === nextPoint.type &&
          point.objId &&
          point.objId === nextPoint.objId
        ) {
          const removed = routes.splice(index + 1, 1)[0];
          point.bl_id = [...point.bl_id, ...removed.bl_id];
          point.requestId = [...point.requestId, ...removed.requestId];
          nextPoint = routes[index + 1];
        }
      }
    });
    return routes;
  };

  const handleCreateTransports = () => {
    //map services
    let newPoints = _.cloneDeep(points);
    newPoints.forEach((value, index) => {
      newPoints[index].services = mappingServices(value.services);
    });
    let newForm = _.cloneDeep(form);
    newForm.partnerNote = partnerNote;
    newForm.points = newPoints;
    newForm.extra = extra;
    newForm.bl_ids = selectedBls.map((bl) => bl.id);
    groupBls(newForm);
  };

  const onChangeCurrentBl = (bl_id) => {
    const current = selectedBls.find((x) => x.id === bl_id[0]);
    setCurrentBl(current);
  };

  return (
    <div>
      <Typography type="subheading" className={classes.subTitle}>
        Tạo lộ trình với Adidi
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <Grid container className={classes.row} spacing={1}>
            <Grid item xs={12} md={3} className={classes.label}>
              <p className={classes.text}>
                <strong>
                  <span className="required" />
                  Loại dịch vụ
                </strong>
              </p>
            </Grid>
            <Grid item xs={12} md className={classes.right}>
              <ServiceTypeSelection
                value={form.services.type}
                settings={settings}
                onChange={handleChangeType}
              />
            </Grid>
          </Grid>
          {extra.length > 0 && form.services.type !== "installation" && (
            <Grid container className={classes.row} spacing={1}>
              <Grid item xs={12} md={3} className={classes.label}>
                <p className={classes.text}>
                  <strong>
                    <span className="required" />
                    Nhóm hàng
                  </strong>
                </p>
              </Grid>
              <Grid item xs={12} md={5} lg={5} className={classes.right}>
                <ThreePlsCategorySelection
                  data={categories}
                  value={extra[0].settings.categories.settings[0].value}
                  onChange={(e) => changeExtra("categories", [e])}
                />
              </Grid>
            </Grid>
          )}
          {extra.length > 0 && group.includes("vehicleTypes") && (
            <Grid container className={classes.row} spacing={1}>
              <Grid item xs={12} md={3} className={classes.label}>
                <p className={classes.text}>
                  <strong>
                    <span className="required" />
                    Phương tiện
                  </strong>
                </p>
              </Grid>
              <Grid item xs={12} md={5} lg={5} className={classes.right}>
                <VehicleSelection
                  value={extra[0].settings.vehicleTypes.settings[0].value}
                  data={vehicleTypes}
                  onChange={(e) => changeExtra("vehicleTypes", [e])}
                />
              </Grid>
            </Grid>
          )}
          <Grid container className={classes.row} spacing={1}>
            <Grid item xs={12} md={3} className={classes.label}>
              <p className={classes.text}>
                <strong>
                  <span className="required" />
                  Ghi chú cho đối tác
                </strong>
              </p>
            </Grid>
            <Grid item xs={12} md={5} lg={5} className={classes.right}>
              <NoteInput
                note={partnerNote}
                placeholder=""
                changeNote={(e) => setPartnerNote(e)}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={8} className={classes.pointConfigContainer}>
          <AdidiServiceSelection
            classes={classes}
            points={points}
            serviceType={form.services.type}
            onChange={(points) => setPoints(points)}
            onChangeCurrentBl={onChangeCurrentBl}
          />
        </Grid>
        <Grid item xs={false} md={5} />
        <Grid item xs={12} md={7} className={classes.actionButtonRow}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateTransports}
            disabled={saveLoading}
          >
            {saveLoading && (
              <CircularProgress
                style={{ color: "white", marginRight: 10 }}
                size={18}
              />
            )}
            Tạo lộ trình
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  selectedBls: state.admin.group.selectedBls,
  currentBl: state.admin.group.currentBl,
  saveLoading: state.admin.group.saveLoading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchBlGroup3PlsList: (bl_id, data) =>
    dispatch(fetchBlGroup3PlsList(bl_id, data)),
  setCurrentBl: (bl_id) => dispatch(setCurrentBl(bl_id)),
  groupBls: (data) => dispatch(groupBls(data)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(GroupAdidiTransport)
);
