import React from "react";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { formatDateTime } from "@utils";
import { WMS_STATUS } from "@constants";

const styles = {
  statusContainer: {
    marginTop: 10,
    display: "inline",
  },
  imgStatusIcon: {
    border: "1px solid #D3D3D378",
    padding: 4,
    borderRadius: 10,
    background: "white",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  statusIcon: {
    fontSize: 17,
    border: "1px solid #D3D3D378",
    padding: 4,
    borderRadius: 10,
    background: "white",
  },
  tooltipText: {
    fontSize: 15,
    padding: 5,
  },
};

const mapWmsStatusIconColor = (status) => {
  switch (status) {
    case WMS_STATUS.pack:
      return "#64ace5";
    default:
      return "gray";
  }
};

const OtherSystemStatusCell = ({ bl, classes }) => {
  let lastState = bl.lastTransport ? bl.lastTransport.state : null;
  return (
    <React.Fragment>
      <div className={classes.container}>
        {/* Chờ soạn hàng */}
        <Tooltip
          title={
            <div className={classes.tooltipText}>
              {bl.wmsStatus === WMS_STATUS.init
                ? "Chờ soạn hàng"
                : "Đã soạn hàng"}
            </div>
          }
          placement="right"
        >
          <div className={classes.statusContainer}>
            <img
              src={
                bl.wmsStatus === WMS_STATUS.init
                  ? require("@images/init.png")
                  : require("@images/picked.png")
              }
              width={18}
              className={classes.imgStatusIcon}
            />
          </div>
        </Tooltip>
        &nbsp;
        {/* Chờ đóng gói */}
        <Tooltip
          title={
            <div className={classes.tooltipText}>
              {bl.wmsStatus === WMS_STATUS.pack
                ? `Đã đóng gói - ${formatDateTime(bl.packed_at)}`
                : "Chưa đóng gói"}
            </div>
          }
          placement="right"
        >
          <div className={classes.statusContainer}>
            <i
              className={`fas fa-box-open ${classes.statusIcon}`}
              style={{
                color: mapWmsStatusIconColor(bl.wmsStatus),
              }}
            />
          </div>
        </Tooltip>
        &nbsp;
        {/* Chờ giao lại */}
        {lastState === "retry" && bl.status === "on_delivering" && (
          <Tooltip
            title={<div className={classes.tooltipText}>Chờ giao lại</div>}
            placement="right"
          >
            <div className={classes.statusContainer}>
              <i
                className={`fas fa-retweet ${classes.statusIcon}`}
                style={{
                  color: "#4c8daf",
                }}
              />
            </div>
          </Tooltip>
        )}
      </div>
    </React.Fragment>
  );
};

export default withStyles(styles)(OtherSystemStatusCell);
