import React from "react";
import Select from "react-select";
import memoize from "memoize-one";

const _options = [];

class AsiaBranchSelection extends React.Component {
  formatOptions = memoize((options) => {
    return options.map((x) => ({
      label: x.name,
      value: x.code,
    }));
  });

  render() {
    const {
      multi = true,
      clearable = true,
      simpleValue = true,
      wrapperStyle,
      value = [],
      options,
    } = this.props;

    return (
      <Select
        {...this.props}
        options={options.length > 0 ? this.formatOptions(options) : _options}
        value={value}
        placeholder="Chọn chi nhánh..."
        clearable={clearable}
        style={{ height: 40, width: "100%" }}
        multi={multi}
        simpleValue={simpleValue}
        menuContainerStyle={wrapperStyle}
      />
    );
  }
}

export default AsiaBranchSelection;
