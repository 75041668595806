import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { formatDateTime, getKtvLabel } from "../../../utils";
import { connect } from "react-redux";

export const styles = () => ({
  text: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 13,
  },
  nameCell: {
    maxWidth: "12rem",
  },
  addressCell: {
    maxWidth: "20rem",
  },
  createdDateCell: {
    maxWidth: "7rem",
  },
  createdUserCell: {
    maxWidth: "10rem",
  },
  phoneCell: {
    minWidth: "5rem",
  },
  "@global": {
    ".historyTable": {
      borderSpacing: 0,
    },
    "table.historyTable>thead>tr": {
      fontWeight: "bold",
      color: "rgba(0, 0, 0, 0.54)",
    },
    "table.historyTable>tbody>tr>td, table.historyTable>thead>tr>td": {
      borderBottom: "1px solid #8b888830",
      padding: 10,
    },
  },
});

class UpdateReceiverAddressHistory extends React.Component {
  render() {
    const { classes, open, history, onClose, ktvList } = this.props;
    return (
      <Dialog
        open={open}
        onClose={onClose}
        className={classes.text}
        maxWidth={"md"}
      >
        <DialogTitle>Lịch sử cập nhật địa chỉ khách hàng</DialogTitle>
        <DialogContent>
          <table className="historyTable">
            <thead>
              <tr>
                <td>Ngày thay đổi</td>
                <td>Họ tên mới</td>
                <td>SĐT mới</td>
                <td>Địa chỉ mới</td>
                <td>Người thay đổi</td>
              </tr>
            </thead>
            <tbody>
              {history.length > 0 ? (
                history.map((x, index) => (
                  <tr key={index}>
                    <td className={classes.createdDateCell}>
                      {formatDateTime(x.created_at)}
                    </td>
                    <td className={classes.nameCell}>{x.name}</td>
                    <td className={classes.phoneCell}>{x.phone}</td>
                    <td className={classes.addressCell}>
                      {x.full_address ? x.full_address.replace(/\|/g, ",") : ""}
                    </td>
                    <td className={classes.createdUserCell}>
                      {getKtvLabel(x.user_id, ktvList)}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5}>Không có</td>
                </tr>
              )}
            </tbody>
          </table>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Đóng{" "}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  ktvList: state.admin.shared.allKtvList,
});
export default withStyles(styles)(
  connect(mapStateToProps, null)(UpdateReceiverAddressHistory)
);
