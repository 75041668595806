import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { updateDeliveryType } from "../../../actions/blActions";
import { CircularProgress } from "@material-ui/core";
import { DeliveryTypeSelection, toggleSnackBar } from "@shared";
import DeliveryTypeChangeHistory from "./DeliveryTypeChangeHistory";
import { BlState } from "../../../../../../models";

const styles = (theme) => ({
  rowDetail: {
    fontSize: 14,
    display: "flex",
    fontFamily: "Noto Sans, sans-serif",
    direction: "row",
    alignItems: "center",
  },

  text: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
  },
  iconButton: {
    fontSize: "1.2rem",
  },
  icon: {
    cursor: "pointer",
    fontSize: 20,
  },
});

const EditIcon = ({ onClick, className, title = "Cập nhật" }) => (
  <i className={`material-icons ${className}`} title={title} onClick={onClick}>
    edit
  </i>
);

const HistoryIcon = ({ onClick, className, title = "Xem lịch sử" }) => (
  <i className={`material-icons ${className}`} title={title} onClick={onClick}>
    history
  </i>
);

const SaveIcon = ({ onClick, className, title = "Lưu" }) => (
  <i className={`material-icons ${className}`} title={title} onClick={onClick}>
    save
  </i>
);

const CloseIcon = ({ onClick, className, title = "Lưu" }) => (
  <i className={`material-icons ${className}`} title={title} onClick={onClick}>
    close
  </i>
);
class BlDeliveryType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      delivery_type: this.props.bl.bl_delivery_types.map(
        (x) => x.delivery_type_id
      ),
      historyDialog: false,
      actionButton: {
        edit: false,
        loading: false,
      },
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { bl } = this.props;
    if (
      JSON.stringify(nextProps.bl.bl_delivery_types) !==
      JSON.stringify(bl.bl_delivery_types)
    ) {
      this.setState({
        delivery_type: nextProps.bl.bl_delivery_types.map(
          (x) => x.delivery_type_id
        ),
      });
    }
  }

  saveDeliveryType = async () => {
    const { toggleSnackBar, bl, updateDeliveryType } = this.props;
    const { delivery_type } = this.state;
    //check if delivery type is empty
    if (delivery_type.length === 0) {
      toggleSnackBar(
        true,
        <span>
          Phải chọn ít nhất <strong>1 loại giao hàng</strong>
        </span>,
        "warning"
      );
      return;
    }
    //check if delivery type is the same
    const bl_delivery_types_id = bl.bl_delivery_types.map(
      (x) => x.delivery_type_id
    );
    const equal =
      bl_delivery_types_id.length === delivery_type.length &&
      bl_delivery_types_id.sort().every(function (value, index) {
        return value === delivery_type.sort()[index];
      });
    if (equal) {
      this.handleCloseEdit();
      return;
    }
    //save
    this.setState({
      actionButton: {
        edit: true,
        loading: true,
      },
    });
    const params = {
      delivery_type_id: delivery_type,
    };

    const response = await updateDeliveryType(bl.id, params);
    setTimeout(() => {
      if (response.status === 200) {
        toggleSnackBar(
          true,
          <span>
            Cập nhật <strong>loại giao hàng</strong> thành công !
          </span>,
          "success"
        );
        this.setState({
          actionButton: {
            edit: false,
            loading: false,
          },
        });
      } else {
        toggleSnackBar(
          true,
          <span>
            Cập nhật <strong>loại giao hàng</strong> thất bại !
          </span>,
          "failed"
        );
        this.setState({
          actionButton: {
            edit: true,
            loading: false,
          },
        });
      }
    }, 1000);
  };

  handleCloseEdit = () => {
    this.setState({
      actionButton: { edit: false, loading: false },
      delivery_type: this.props.bl.bl_delivery_types.map(
        (x) => x.delivery_type_id
      ),
    });
  };

  canEditDeliveryType = () => {
    const { bl } = this.props;
    return (
      ![
        BlState.delivered,
        BlState.cancelled,
        BlState.returned,
        BlState.partial_returned,
      ].includes(bl.state) && !bl.isWhTransfer
    );
  };

  render() {
    const { bl, classes } = this.props;
    const { delivery_type, actionButton, historyDialog } = this.state;
    const label = bl.bl_delivery_types.map((x) => x.description).join(", ");
    return (
      <div className={classes.rowDetail}>
        {actionButton.edit ? (
          <div style={{ width: "70%" }}>
            <DeliveryTypeSelection
              placeholder="Chọn ít nhất 1 loại giao hàng"
              value={delivery_type}
              onChange={(e) =>
                this.setState({ delivery_type: e ? e.map((x) => x.value) : [] })
              }
            />
          </div>
        ) : bl.bl_delivery_types.length < 2 ? (
          label
        ) : (
          <ul>
            {bl.bl_delivery_types.map((x, key) => (
              <li key={key}>{x.description}</li>
            ))}
          </ul>
        )}
        &nbsp;
        {this.canEditDeliveryType() &&
          (actionButton.edit ? (
            actionButton.loading ? (
              <CircularProgress size={20} />
            ) : (
              <React.Fragment>
                <SaveIcon
                  className={classes.icon}
                  onClick={this.saveDeliveryType}
                />
                <CloseIcon
                  className={classes.icon}
                  onClick={this.handleCloseEdit}
                />
              </React.Fragment>
            )
          ) : (
            <EditIcon
              className={classes.icon}
              onClick={() =>
                this.setState({
                  actionButton: {
                    edit: !actionButton.edit,
                    loading: false,
                  },
                })
              }
            />
          ))}
        {this.canEditDeliveryType() && (
          <React.Fragment>
            <HistoryIcon
              className={classes.icon}
              onClick={() =>
                this.setState({
                  historyDialog: true,
                })
              }
            />
            <DeliveryTypeChangeHistory
              open={historyDialog}
              history={bl.bl_delivery_type_log}
              onClose={() => this.setState({ historyDialog: false })}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  bl: state.admin.detail.bl,
  delivery_types: state.admin.shared.delivery_types,
});

const mapDispatchToProps = (dispatch) => ({
  toggleSnackBar: (state, message, type) =>
    dispatch(toggleSnackBar(state, message, type)),
  updateDeliveryType: (id, delivery_type) =>
    dispatch(updateDeliveryType(id, delivery_type)),
});
export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(BlDeliveryType)
);
