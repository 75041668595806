import React from "react";
import Select from "react-select";

const OPTIONS = [
  { value: "waiting_for_confirm", label: "Chờ gán vận chuyển" },
  { value: "waiting_for_retry", label: "Chờ giao lại" },
  // { value: "temp", label: "Chờ xác nhận yêu cầu" },
  // { value: "temp1", label: "Chờ xác nhận giao hàng" },
  { value: "waiting_for_pick", label: "Chờ lấy hàng" },
  { value: "on_delivering", label: "Đang giao" },
  { value: "on_returning", label: "Đang hoàn hàng" },
  { value: "on_partial_returning", label: "Đang hoàn một phần" },
  { value: "cancel_shipping_transport", label: "Đang hủy lộ trình" },
  { value: "returned", label: "Đã hoàn hàng" },
  { value: "partial_returned", label: "Đã hoàn một phần" },
  { value: "cancelled", label: "Hủy vận đơn" },
  { value: "delivered", label: "Giao thành công" },
];

export const mapBlStatusStyles = (status, type = "label") => {
  /**
   * Trả về color hex code tương ứng với quick filter
   */
  switch (status) {
    case "waiting_for_confirm":
      return {
        background: type === "label" ? "#e8e8e8" : "#f0edf094",
        color: "black",
        border: "none",
      };
    case "waiting_for_pick":
      return {
        background: type === "label" ? "#CDDC39" : "#cddc3975",
        color: "white",
        border: "none",
      };
    case "on_delivering":
      return {
        background: type === "label" ? "#03A9F4" : "#03a9f48a",
        color: "white",
        border: "none",
      };
    case "on_returning":
      return {
        background: type === "label" ? "#ba68c8" : "#ba68c894",
        color: "white",
        border: "none",
      };
    case "on_partial_returning":
      return {
        background: type === "label" ? "#ba68c8" : "#ba68c894",
        color: "white",
        border: "none",
      };
    case "cancel_shipping_transport":
      return {
        background: type === "label" ? "#EE9F00" : "#e8ab2fc9",
        color: "white",
        border: "none",
      };
    case "returned":
      return {
        background: type === "label" ? "#000" : "#00000096",
        color: "white",
        border: "none",
      };
    case "partial_returned":
      return {
        background: type === "label" ? "#000" : "#00000096",
        color: "white",
        border: "none",
      };
    case "cancelled":
      return {
        background: type === "label" ? "#ff0000" : "#ff000078",
        color: "white",
        border: "none",
      };
    case "delivered":
      return {
        background: type === "label" ? "#4CAF50" : "#4caf50ad",
        color: "white",
        border: "none",
      };
    case "waiting_for_retry":
      return {
        background: type === "label" ? "#4c8daf" : "#4cafac",
        color: "white",
        border: "none",
      };
    default:
      return "";
  }
};
class BlStatusFilter extends React.Component {
  handleChangeBlStatus = (status) => {
    const { filter, setFilter } = this.props;
    const shipperState = filter.shipper_state.split(",");
    const prevBlStatus = filter.status.split(",");
    const curBlStatus = status.split(",");
    let newShipperState = shipperState;
    if (
      prevBlStatus.includes("waiting_for_pick") &&
      !curBlStatus.includes("waiting_for_pick")
    ) {
      newShipperState = newShipperState.filter(
        (x) =>
          x !== "waiting_for_being_accepted" &&
          x !== "accepted" &&
          x !== "at_warehouse"
      );
    }
    if (
      prevBlStatus.includes("on_delivering") &&
      !curBlStatus.includes("on_delivering")
    ) {
      newShipperState = newShipperState.filter(
        (x) =>
          x !== "on_the_way" &&
          x !== "at_delivery_point" &&
          x !== "waiting_for_being_cancelled"
      );
    }
    if (
      prevBlStatus.includes("on_returning") &&
      !curBlStatus.includes("on_returning")
    ) {
      newShipperState = newShipperState.filter(
        (x) => x !== "on_the_way_back" && x !== "ready_to_return"
      );
    }
    setFilter("shipper_state", newShipperState.toString());
    setFilter("status", status ? status : "");
  };

  render() {
    const { value, placeholder = "Chọn trạng thái..." } = this.props;
    return (
      <Select
        value={value}
        onChange={this.handleChangeBlStatus}
        options={OPTIONS}
        multi
        simpleValue
        closeOnSelect={false}
        placeholder={placeholder}
        wrapperStyle={{ width: "100%" }}
        valueComponent={SelectValue}
      />
    );
  }
}

class SelectValue extends React.Component {
  render() {
    let { value, onRemove } = this.props;
    let label = value.label;
    const valueStyles = mapBlStatusStyles(value.value, "label");
    const closeStyles = mapBlStatusStyles(value.value, "close");
    return (
      <div className="Select-value" style={{ border: "none" }}>
        <span
          className="Select-value-icon"
          aria-hidden="true"
          style={closeStyles}
          onClick={() => onRemove(value)}
        >
          ×
        </span>
        <div className="Select-value-label" style={valueStyles}>
          {label}
        </div>
      </div>
    );
  }
}

export default BlStatusFilter;
