import React from "react";
import Dialog from "./Dialog";
import { getErrorMessage } from "@utils";
class ErrorDialog extends React.Component {
  handleCloseDialog = () => {
    const { error, closeErrorDialog } = this.props;
    switch (error.statusCode) {
      // case 401:
      //   TekoID.logIn();
      //   break;
      case 201:
        window.location.reload();
        break;
      default:
        closeErrorDialog();
    }
  };

  render() {
    const { error } = this.props;
    return (
      <Dialog
        open={error.hasError}
        closeDialog={this.handleCloseDialog}
        title="Có lỗi xảy ra!"
      >
        {getErrorMessage(error.httpStatus, error.message)}
      </Dialog>
    );
  }
}

export default ErrorDialog;
