import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Checkbox, Button, CircularProgress } from "@material-ui/core";
import {
  ShipTypeSelection,
  EstimatedDeliveryTimePicker,
  ToHubSelection,
  ShipperExtraSelection,
  DebtTransferOption,
} from "@shared";
import { createTransportStyles as styles } from "./styles";

const TransportForm = (props) => {
  const {
    bl,
    classes,
    form,
    hubs,
    ktvList,
    loading,
    changeForm,
    canShowToHubSelection,
    handleCreateTransport,
  } = props;
  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <strong className={classes.label}>Phương thức vận chuyển:</strong> Kỹ
        thuật viên
      </Grid>
      <Grid item md={12} className={classes.row}>
        <Grid container spacing={1}>
          <Grid item className={classes.row}>
            <strong className={classes.label}>Giao đến:</strong>
            <ShipTypeSelection
              bl={bl}
              shipType={form.shipType}
              shipMethod={form.shipMethod}
              changeShipType={(e) => {
                changeForm({
                  shipType: e,
                  toHub: ["customer_customer", "hub_customer"].includes(e)
                    ? { id: bl.toAddr.objId }
                    : "",
                });
              }}
            />
          </Grid>
          {canShowToHubSelection() && (
            <Grid item className={classes.row}>
              <strong className={classes.label}>Kho nhận</strong>
              <ToHubSelection
                bl={bl}
                hubs={hubs}
                toHub={form.toHub}
                changeHubTo={(e) => {
                  changeForm({
                    toHub: {
                      id: e.id,
                    },
                  });
                  fetchBlShipperList(bl.id, e.id);
                }}
                style={{ minWidth: "15rem" }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item md={12}>
        <ShipperExtraSelection
          changeExtraShipper={(e) => changeForm({ shipperExtra: e })}
          shipperExtra={form.shipperExtra}
          ktvList={ktvList}
          shipper={form.shipper}
        />
      </Grid>
      {!bl.must_transfer_debt && (
        <Grid item md={12} className={classes.row}>
          <strong className={classes.label}>Gán nợ :</strong>{" "}
          <DebtTransferOption
            value={form.is_debit}
            onChange={(e) => changeForm({ is_debit: e })}
          />
        </Grid>
      )}
      <Grid item md={12} className={classes.row}>
        <Grid item>
          <strong className={classes.label}>
            Khoảng cách từ kho đến khách hàng:
          </strong>
          {bl.current_distance
            ? `${bl.current_distance} km`
            : "Không tính được"}
        </Grid>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Grid item className={classes.row}>
          <strong className={classes.label}>Tính phí xăng xe</strong>
          <Checkbox
            color="primary"
            checked={form.vehicle_gas_cost}
            onChange={(e) => changeForm({ vehicle_gas_cost: e.target.checked })}
          />
        </Grid>
      </Grid>
      <Grid item md={12} className={classes.row}>
        <strong className={classes.label}>Thời gian dự kiến</strong>
        <EstimatedDeliveryTimePicker
          value={form.edt}
          changeEDT={(e) => changeForm({ edt: e })}
        />
      </Grid>
      <Grid item md={12} className={classes.row}>
        <strong className={classes.label}>Ghi chú</strong>
        <input
          className={classes.input}
          value={form.note}
          onChange={(e) => changeForm({ note: e.target.value })}
          style={{ minWidth: "20rem" }}
        />
        &nbsp;
        <Button
          onClick={handleCreateTransport}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading && <CircularProgress size={18} style={{ color: "white" }} />}
          &nbsp; Tạo lộ trình
        </Button>
      </Grid>
    </Grid>
  );
};

TransportForm.propTypes = {
  bl: PropTypes.object.isRequired,
  hubs: PropTypes.array.isRequired,
  ktvList: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  changeForm: PropTypes.func.isRequired,
  canShowToHubSelection: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  hubs: state.admin.shared.hubs,
  ktvList: state.admin.list.ktvList,
});

const mapDispatchToProps = (dispatch) => ({
  fetchBlShipperList: (bl_id, hub_id) =>
    dispatch(fetchBlShipperList(bl_id, hub_id)),
});

const Component = withStyles(styles)(TransportForm);

export default connect(mapStateToProps, mapDispatchToProps)(Component);
