export default class Point {
  constructor(address, type, { id, requestId, services }) {
    switch (arguments.length) {
      case 1:
        Object.keys(address).forEach((x) => (this[x] = address[x]));
        break;
      default: {
        this.type = type;
        this.contact = {
          name: address.display_name,
          phone: address.phone,
        };
        this.location = {
          address: address.full_address,
        };
        this.expectedTime = null;
        this.services = services || [];
        this.goodsCheckRequired = false;
        this.bl_id = [id];
        this.objId = address.obj_id;
        this.pointType = address.type;
        this.requestId = [requestId];
        this.note = "";
        this.id = address.id;
        this.group = "";
      }
    }
  }

  get formatPoint() {
    return {
      type: this.type,
      expectedTime: this.expectedTime,
      services: this.services,
      goodsCheckRequired: this.goodsCheckRequired,
      blId: this.blId,
      note: this.note,
      objId: this.objId,
    };
  }
}
