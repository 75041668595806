import React from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";

//styles
const styles = (theme) => ({
  div: {
    boxShadow: "0px 0px 10px 0px rgba(77,70,77,0.5)",
    borderRadius: "0.3rem",
    margin: "1rem",
    flex: 1,
  },
  number: {
    fontSize: "2rem",
    alignSelf: "center",
    textAlign: "center",
    minWidth: "5rem",
    borderRadius: "0.3rem 0.3rem 0 0",
    padding: "0.6rem 0",
  },
  title: {
    fontSize: "1.1rem",
    textAlign: "center",
    background: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: "0 0 0.3rem 0.3rem",
    padding: "0.5rem 0",
  },
});

class Ticket extends React.Component {
  render() {
    const { value, text, link, textColor, background, loading, classes } =
      this.props;
    return (
      <Link to={link} target="_blank" className={classes.div}>
        <div
          className={classes.number}
          style={{ color: textColor, background: background }}
        >
          {loading ? <CircularProgress color="inherit" /> : value}
        </div>
        <div className={classes.title}>{text}</div>
      </Link>
    );
  }
}

export default withStyles(styles)(Ticket);
