import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import DropdownTreeSelect from "react-dropdown-tree-select";
import _ from "lodash";

const styles = (theme) => ({
  row: {
    padding: 5,
  },
  label: {
    textAlign: "right",
    paddingLeft: 10,
  },
  right: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  mustHave: {
    color: "#ff0000",
    fontSize: 10,
  },
});

const set = (categories, path, checked, expanded) => {
  if (path.length === 1) {
    categories[path[0]].expanded = expanded;
    setAllChildElement(categories[path[0]], checked);
    return;
  }
  const index = path[path.length - 1];
  path.pop();
  categories[index].expanded = true;
  set(categories[index].children, path, checked, expanded);
};

function setAllChildElement(node, checked) {
  node.checked = checked;
  if (node.children) {
    node.children.forEach((element) => {
      setAllChildElement(element, checked);
    });
  }
}

const ThreePlsServiceSelection = ({ classes, data, ...props }) => {
  const handleChangeServices = (currentNode, selectedNodes) => {
    let newData = _.cloneDeep(data);
    const checked = selectedNodes.includes(currentNode);
    const expanded = currentNode.expanded;
    set(newData, currentNode.path.reverse(), checked, expanded);
    props.onChange(newData);
  };

  return (
    <DropdownTreeSelect
      data={data}
      onChange={handleChangeServices}
      texts={{
        placeholder: "Chọn loại dịch vụ...",
        noMatches: "Không tìm thấy",
      }}
      className="mdl-demo"
    />
  );
};

export default compose(withStyles(styles))(ThreePlsServiceSelection);
