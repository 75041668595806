import React from "react";
import Select from "react-select";

import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  popoverContent: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
  },
  confirmedStyle: {
    backgroundColor: "#FFFEBE",
    margin: theme.spacing.unit,
  },
  deliveringStyle: {
    backgroundColor: "#A8CDF1",
    margin: theme.spacing.unit,
  },
  deliveredStyle: {
    backgroundColor: "#D5EED5",
    margin: theme.spacing.unit,
  },
});

class MultipleShipperSelection extends React.Component {
  render() {
    let {
      options,
      value,
      onChange,
      multi = false,
      ignoreList,
      disabled,
    } = this.props;
    const newOptions = options.filter((x) => !ignoreList.includes(x.value));
    return (
      <Select
        value={value}
        onChange={onChange}
        options={newOptions}
        placeholder="Chọn KTV giao hàng"
        style={{ height: 40, minWidth: "14rem" }}
        multi={multi}
        className="quick-create-transport-shipper-selection"
        disabled={disabled}
      />
    );
  }
}

export default withStyles(styles)(MultipleShipperSelection);
