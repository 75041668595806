import _ from "lodash";

const _initialState = {
  bus_lines: [],
  tableLoading: false,
  filter: {
    region: "northern,central,southern",
    name: "",
    active: "1",
    created_by_user_id: "",
    last_updated_by_user_id: "",
    offset: 0,
    limit: 10,
  },
  dialog: {
    open: false,
    selected: null,
  },
  total_records: 0,
};

const busLineManagement = (state = _initialState, action) => {
  switch (action.type) {
    case "SET_BUS_LINES_LIST":
      return {
        ...state,
        bus_lines: action.payload.bus_lines,
        total_records: action.payload.total_records,
      };
    case "BUS_LINES_CHANGE_FILTER":
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      };
    case "BUS_LINE_CHANGE_TABLE_LOADING":
      return {
        ...state,
        tableLoading: action.payload,
      };
    case "TOGGLE_BUS_LINE_DIALOG":
      return {
        ...state,
        dialog: action.payload,
      };
    default:
      return state;
  }
};

export default busLineManagement;
