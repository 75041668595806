import apiService from "./base";

export const getUserManual = async () => {
  return await apiService({
    url: `/admin/user_manual`,
    method: "GET",
  });
};

export const getDataSamePlref = async (data) => {
  return await apiService({
    url: `/admin/bls/group_same_pl_ref`,
    params: { pl_ref: data },
    method: "GET",
  });
};
