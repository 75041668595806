import {
  getUserSavedFilter,
  deleteUserFilter,
  saveUserFilter,
  getAreaListApi,
  updateFilterListApi,
} from "@api";
import { fetchError } from "../../shared";

export const setFilter = (property, value) => {
  return {
    type: "SET_FILTER",
    payload: {
      property,
      value,
    },
  };
};

export const clearFilters = () => ({
  type: "CLEAR_FILTERS",
});

export const saveFilter = (filter) => async (dispatch) => {
  let data = [];
  filter.forEach((itm) => {
    if (!itm.id) {
      data.push(itm);
    }
  });
  if (data.length > 0) {
    const response = await updateFilterListApi(data);
    if (response.status === 200) {
      dispatch(fetchUserFilter());
    }
  }
};

export const loadFilter = (index) => ({
  type: "LOAD_FILTER",
  payload: {
    index,
  },
});

export const setTab = (index) => ({
  type: "SET_TAB",
  payload: {
    index,
  },
});

export const deleteTabSaveFilter = (index, item) => async (dispatch) => {
  const response = await deleteUserFilter({ data: [item.id] });
  if (response.status === 200) {
    dispatch(fetchUserFilter());
    dispatch(deleteSaveFilter(index));
  }
};

export const deleteSaveFilter = (id) => ({
  type: "DELETE_SAVE_FILTER",
  payload: {
    id,
  },
});

export const fetchAllDistrict = () => async (dispatch) => {
  const response = await getAreaListApi();
  const result = response.data;
  if (response.status === 200) {
    if (result) {
      dispatch(setAllDistrict(result.data));
    } else {
      dispatch(
        fetchError(
          response.status,
          "Có lỗi xảy ra! Vui lòng liên hệ Logistic team để được hỗ trợ!"
        )
      );
    }
  } else {
    dispatch(fetchError(response.status, result.message));
  }
};

const setAllDistrict = (districtList) => ({
  type: "SET_ALL_DISTRICT",
  payload: districtList,
});

export const syncUserFilter = (filter) => async () => {
  await saveUserFilter({ data: filter });
};

export const fetchUserFilter = () => async (dispatch) => {
  const filterData = window.localStorage.getItem("SavedFilter");
  const response = await getUserSavedFilter();
  let data = [];
  const result = response.data;
  if (response.status === 200) {
    if (result) {
      if (filterData !== null && filterData.length > 0) {
        let webFilter = JSON.parse(filterData);
        data = webFilter;
        window.localStorage.removeItem("SavedFilter");
        dispatch(syncUserFilter(webFilter));
        dispatch(setUserFilter(webFilter));
      } else {
        data = result.data.Filters;
        dispatch(setUserFilter(result.data.Filters));
      }
    }
  }
  return data;
};

const setUserFilter = (filterList) => ({
  type: "SET_USER_FILTER",
  payload: {
    filters: filterList,
  },
});
