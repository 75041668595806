import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  input: {
    height: 34,
    border: "1px solid #ccc",
    borderRadius: 4,
    paddingLeft: 8,
    width: "100%",
  },
});

const NoteInput = ({ note, changeNote, classes, style, placeholder }) => (
  <input
    type="text"
    value={note}
    style={style}
    className={classes.input}
    placeholder={placeholder || "Ghi chú vận chuyển..."}
    onChange={(e) => changeNote(e.target.value)}
  />
);

export default withStyles(styles)(NoteInput);
