import Hub from "./Hub";
import { getArea } from "../app/admin/shared";

export const AddressType = {
  tekshop_wh: "tekshop_wh",
  asia_wh: "asia_wh",
  hub: "hub",
  customer: "customer",
  supplier: "supplier",
  ktv: "ktv",
};

export default class Address {
  constructor(address) {
    this.id = address.id;
    this.name = address.name;
    this.displayName = address.display_name;
    this.type = address.type;
    this.objId = address.obj_id;
    this.phone = address.phone;
    this.email = address.email;
    this.googleAddress = address.googleAddress;
    this.fullAddress = address.full_address
      ? address.full_address.replace(/\|/g, ",")
      : "";
    this.areaCode = address.area_code;
    this.asiaId = address.asia_id;
    this.hub = address.hub;
    this.rawFullAddress = address.full_address;
    this.area = address.area ? address.area : null;
    this.street = address.street
      ? address.street
      : address.full_address
      ? this.getStreet(address.full_address)
      : "";
    this.gg_address = address.gg_address ? address.gg_address : "";
  }

  getStreet(fullAddress) {
    let s = "";
    for (let i = 0; i < fullAddress.length; i++) {
      if (fullAddress[i] == "|") {
        break;
      }
      s += fullAddress[i];
    }
    return s;
  }

  async getAddressPart() {
    const response = await getArea(this.areaCode);
    return response.data.data.area;
  }
}
