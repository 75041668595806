import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import { changeFilter, changePagination, onSearch } from "./actions";
import { VehicleReportFilter, VehicleReportTable } from "./components";

//styles
const styles = (theme) => ({
  text: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
  },
});

class VehicleReport extends React.Component {
  render() {
    const {
      filter,
      loading,
      data,
      changeFilter,
      changePagination,
      onSearch,
      classes,
    } = this.props;
    return (
      <React.Fragment>
        <Grid item md={12} className={classes.text}>
          <VehicleReportFilter
            filter={filter}
            onSearch={onSearch}
            onChange={changeFilter}
          />
        </Grid>
        <Grid
          item
          md={12}
          className={classes.text}
          style={{
            overflow: "auto",
            boxShadow: "0 1px 5px rgba(0, 0, 0, 0.15)",
            borderRadius: "0 0 1rem 1rem",
          }}
        >
          <VehicleReportTable
            loading={loading}
            data={data}
            onChangePagination={changePagination}
          />
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  filter: state.admin.VehicleReport.filter,
  loading: state.admin.VehicleReport.loading,
  data: state.admin.VehicleReport.data,
});

const mapDispatchToProps = (dispatch) => ({
  changeFilter: (filter) => dispatch(changeFilter(filter)),
  changePagination: (pagination) => dispatch(changePagination(pagination)),
  onSearch: () => dispatch(onSearch()),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(VehicleReport)
);
