import * as actionTypes from "./actionTypes";

const _initialState = {
  statistic: {
    start_date: "",
    end_date: "",
    bls_stat: {
      None: 4,
      waiting_for_pick: 10,
      waiting_for_confirm: 36,
      on_delivering: 8,
      cancel_shipping_transport: 1,
      on_returning: 17,
      returned: 22,
      partial_returned: 14,
      cancelled: 2,
      delivered: 44,
      on_partial_returning: 0,
      total_returning: 17,
      total_returned: 36,
      total: 158,
    },
    ktv_stat: {
      None: 4,
      waiting_for_pick: 6,
      on_delivering: 5,
      returned: 6,
      partial_returned: 14,
      delivered: 29,
      on_returning: 0,
      on_partial_returning: 0,
      total_returning: 0,
      total_returned: 20,
      total: 64,
    },
    threepls_stat: {
      waiting_for_pick: 4,
      on_delivering: 3,
      cancel_shipping_transport: 1,
      on_returning: 17,
      returned: 16,
      delivered: 15,
      on_partial_returning: 0,
      total_returning: 17,
      partial_returned: 0,
      total_returned: 16,
      total: 56,
    },
  },
  system: {
    user_stat: {
      admin: 49,
      disabled: 5,
      ktv: 696,
      mod: 96,
      onlyview: 52,
      sktv: 43,
      super_admin: 34,
    },
    error_stat: 244,
  },
  blStatusFilter: {
    start_date: `${new Date().getMonth() + 1}/01/${new Date().getFullYear()}`,
    end_date: new Date(),
  },
  blStatusLoading: false,
};

const dashboard = (state = _initialState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_BL_STATUS_FILTER: {
      return {
        ...state,
        blStatusFilter: {
          ...state.blStatusFilter,
          ...action.payload,
        },
      };
    }
    case actionTypes.SET_BL_STATUS_LOADING: {
      return {
        ...state,
        blStatusLoading: action.payload,
      };
    }
    case actionTypes.GET_STATISTIC_SUCCESS: {
      return {
        ...state,
        statistic: {
          ...action.payload,
          start_date: state.blStatusFilter.start_date,
          end_date: state.blStatusFilter.end_date,
        },
      };
    }
    case actionTypes.GET_SYSTEM_STATUS_SUCCESS: {
      return {
        ...state,
        system: action.payload,
      };
    }
    default:
      return state;
  }
};

export default dashboard;
