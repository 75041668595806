import React, { useEffect, useState } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import memoize from "memoize-one";

function SaleChannelFilter(props) {
  const {
    value,
    onChange,
    seller_id,
    channels,
    placeholder = "Chọn kênh...",
  } = props;
  const [prevSeller, setPrevSeller] = useState(seller_id);
  const formatChannel = memoize((channels) => {
    const sellers = seller_id ? seller_id.split(",") : [];
    if (sellers && sellers.length > 0) {
      return channels
        .filter((x) => sellers.includes((x.seller_id || "").toString()))
        .map((x) => ({
          label: x.name,
          value: x.id.toString(),
        }));
    } else {
      return channels.map((x) => ({
        label: x.name,
        value: x.id.toString(),
      }));
    }
  });

  //Remove channels that's not belong to selected seller
  useEffect(() => {
    if (prevSeller !== seller_id) {
      const selectedChannel = value ? value.split(",") : [];
      const formattedChannels = formatChannel(channels);
      const filterdChannels = formattedChannels
        .filter((x) => selectedChannel.includes(x.value))
        .map((x) => x.value)
        .toString();
      onChange(filterdChannels);
    }
    setPrevSeller(seller_id);
  }, [
    channels,
    formatChannel,
    onChange,
    prevSeller,
    props.seller_id,
    seller_id,
    value,
  ]);
  return (
    <Select
      value={value}
      onChange={(value) => onChange(value ? value : "")}
      options={formatChannel(props.channels)}
      multi
      simpleValue
      closeOnSelect={false}
      placeholder={placeholder}
      wrapperStyle={{ width: "100%" }}
      style={{ height: 41 }}
    />
  );
}

function mapStateToProps(state) {
  return {
    channels: state.admin.shared.channels,
  };
}

export default connect(mapStateToProps)(SaleChannelFilter);
