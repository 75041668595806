import apiService from "./base";

export const getRetryShippingSummaryReport = (params) => {
  return apiService({
    url: "/admin/report/retry_shipping",
    params: params,
  });
};

export const getRetryShippingDetailReport = (params) => {
  return apiService({
    url: "/admin/report/retry_shipping_detail",
    params: params,
  });
};

export const getVehicleReport = (params) => {
  return apiService({
    url: "/admin/report/vehicles",
    method: "get",
    params: params,
  });
};

export const getShipperDailyFuelCostReport = (params) => {
  return apiService({
    url: "/admin/report/shipper_daily_fuel_cost",
    params: params,
  });
};

export const getShipperSummaryFuelCostReport = (params) => {
  return apiService({
    url: "/admin/report/shipper_summary_fuel_cost",
    params: params,
  });
};

export const getShipperStatus = (data) => {
  return apiService({
    url: "/admin/report/shipper_status",
    params: data,
  });
};

export const dumpReportApi = null;
