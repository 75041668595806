import React from "react";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";

const styles = (theme) => ({
  "@global": {
    ".react-datetimerange-picker__wrapper": {
      border: "1px solid #cccccc8a",
      borderRadius: 4,
      display: "flex",
      height: 38,
      alignItems: "center",
      fontSize: "14px",
      paddingLeft: 3,
    },
    ".react-datetimerange-picker": {
      display: "block",
    },
  },
});

class DateTimeRangeSelection extends React.Component {
  render() {
    return (
      <React.Fragment>
        <DateTimeRangePicker
          onChange={(date) => {
            this.props.onChange(
              date
                ? [
                    date[0] ? moment(date[0]).format() : null,
                    date[1] ? moment(date[1]).format() : null,
                  ]
                : [null, null]
            );
          }}
          value={[
            this.props.value[0]
              ? new Date(this.props.value[0].replace(" ", "+"))
              : null,
            this.props.value[1]
              ? new Date(this.props.value[1].replace(" ", "+"))
              : null,
          ]}
          locale="vi-VN"
          calendarIcon={null}
          disableClock={true}
          showLeadingZeros={true}
          clearIcon={
            <i
              className="material-icons"
              style={{ fontSize: 11, color: "#999999", fontWeight: "bold" }}
            >
              clear
            </i>
          }
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(DateTimeRangeSelection);
