import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { openNotiDialog } from "../../../shared/actions";
import { UpdateAddressDialog } from "../../../shared";
import { UpdateReceiverAddressHistory } from "../dialogs";
import { updateCustomerAddress } from "@api";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { ShipMethod } from "@constants";

const styles = (theme) => ({
  editButton: {
    float: "right",
    cursor: "pointer",
  },
  confirmDialog: {
    borderRadius: "1rem",
  },
  text: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
  },
});

class EditAndSaveInfo extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
      confirmDialog: false,
      historyDialog: false,
      data: null,
      loading: false,
    };
  }

  handleUpdateReceiverAddress = async (data) => {
    this.setState({ data: data });
    const { bl } = this.props;
    if (["waiting_for_pick", "on_delivering"].includes(bl.status)) {
      this.setState({ confirmDialog: true });
    } else {
      this.updateAddress(data);
    }
  };

  updateAddress = async (data) => {
    this.setState({ loading: true });
    const { bl, onUpdated } = this.props;
    const requestBody = {
      google_address: data.useGoogleMap ? data.googleResponse : null,
      name: data.name.trim(),
      phone: data.phone,
      street: data.useGoogleMap
        ? data.customerAddress.trim()
        : data.street.trim(),
      area_code: data.useGoogleMap
        ? null
        : data.ward
        ? data.ward
        : data.district,
      type: "customer",
    };
    const response = await updateCustomerAddress(bl.id, requestBody);
    this.setState({ loading: false });
    if (response.status === 200) {
      this.setState({ open: false, confirmDialog: false });
    }
    if (onUpdated) onUpdated(response, requestBody);
  };

  handleEditAddressClick = () => {
    const { bl, openNotiDialog } = this.props;
    if (
      (bl.currentTransport || {}).shipMethod === ShipMethod.eton ||
      (bl.currentTransport || {}).shipMethod === ShipMethod.eton_vnt ||
      (bl.currentTransport || {}).shipMethod === ShipMethod.tkl_eton
    ) {
      openNotiDialog(
        "failed",
        <span>
          Không thể <strong>cập nhật</strong> thông tin khách hàng với lộ trình
          giao bởi <span className="stressPink">Eton</span>
        </span>
      );
    } else {
      this.setState({ open: true });
    }
  };

  render() {
    const { bl, address, classes, showHistoryButton = true } = this.props;
    const { loading, historyDialog, open, confirmDialog } = this.state;
    return (
      <React.Fragment>
        {showHistoryButton && (
          <i
            className={`material-icons ${classes.editButton}`}
            onClick={() => this.setState({ historyDialog: true })}
          >
            history
          </i>
        )}
        <i
          className={`material-icons ${classes.editButton}`}
          onClick={this.handleEditAddressClick}
        >
          edit
        </i>
        {bl.must_valid_address && (
          <span style={{ float: "right" }} className="label-danger">
            Cần cập nhật địa chỉ
          </span>
        )}
        {open && (
          <UpdateAddressDialog
            bl={bl}
            open={open}
            onClose={() => this.setState({ open: false })}
            defaultContact={address}
            onUpdate={(data) => this.handleUpdateReceiverAddress(data)}
            loading={loading}
          />
        )}
        {historyDialog && (
          <UpdateReceiverAddressHistory
            open={historyDialog}
            onClose={() => this.setState({ historyDialog: false })}
            history={bl.customer_addresses}
          />
        )}
        <Dialog
          open={confirmDialog}
          onClose={() => this.setState({ confirmDialog: false })}
          className={classes.text}
          classes={{ paper: classes.confirmDialog }}
        >
          <DialogTitle>Chú ý</DialogTitle>
          <DialogContent style={{ fontSize: 16, lineHeight: 2 }}>
            Việc <strong>cập nhật địa chỉ</strong> sẽ{" "}
            <strong style={{ color: "indianred" }}>
              hủy lộ trình mới nhất
            </strong>{" "}
            của vận đơn.
            <br /> Bạn có muốn tiếp tục thực hiện ?
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={() => this.updateAddress(this.state.data)}
            >
              {loading && <CircularProgress size={20} />}&nbsp;Đồng ý
            </Button>
            <Button
              color="primary"
              onClick={() => this.setState({ confirmDialog: false })}
            >
              Hủy
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  openNotiDialog: (type, text) => dispatch(openNotiDialog(type, text)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(EditAndSaveInfo)
);
