import React from "react";
import Select from "react-select";

const EditShipdateReasons = ({
  value,
  options,
  setFilter,
  multi = true,
  placeholder,
  label,
}) => {
  let reasons = options.map((rejectReasons) => {
    rejectReasons.label = rejectReasons.name;
    rejectReasons.value = rejectReasons.id.toString();
    return rejectReasons;
  });
  reasons = reasons.filter((x) => ["edit_ship_date"].includes(x.type));
  return (
    <Select
      value={value}
      options={reasons}
      onChange={(rejectReasons) => {
        setFilter(
          label ? label : "edit_ship_date_reason",
          rejectReasons ? rejectReasons : ""
        );
      }}
      multi={multi}
      simpleValue
      closeOnSelect={multi ? false : true}
      wrapperStyle={{ width: "100%" }}
      placeholder={placeholder ? placeholder : "Lý do hẹn giao"}
    />
  );
};
export default EditShipdateReasons;
