import { API_URL } from "@config";
import { fetchError } from "@shared";
import { Bl } from "../../../../models";
import {
  getCategories,
  getThreePlsAvailable,
  getShipperListByBlApi,
  getBls,
} from "@api";
import { queryStringBuilder, flatToHierarchy, paramBuilder } from "@utils";
import _ from "lodash";

let ccr; // set time out

const fetchBlList = () => async (dispatch, getState) => {
  dispatch(setLoading(true));
  let currentState = getState();
  let queryString = queryStringBuilder(currentState.admin.list.filter);
  // Save queryString to localStorage
  if (currentState.admin.list.currentTab < 2) {
    if (currentState.admin.list.filter.quickFilter === "waiting") {
      window.localStorage.setItem("queryStringCXL", queryString);
    } else {
      window.localStorage.setItem("queryString", queryString);
    }
  }

  clearTimeout(ccr);
  if (window.location.href.indexOf("van-don") > -1) {
    window.location.assign(window.location.origin + "/" + queryString);
  } else {
    let url = window.location.href;
    if (url.endsWith("/")) {
      url = url.slice(0, -1);
    }
    window.history.pushState(
      "",
      "Logistic Admin",
      url.includes("?") ? queryString : url + queryString
    );
  }

  ccr = setTimeout(async () => {
    const minifiedParams = paramBuilder(currentState.admin.list.filter);
    const response = await getBls(minifiedParams);
    dispatch(setLoading(false));
    const result = response.data;
    if (response.status === 200) {
      if (result) {
        dispatch(fetchBlListSuccess(result.data));
      } else {
        dispatch(
          fetchError(
            response.status,
            "Có lỗi xảy ra! Vui lòng liên hệ Logistic team để được hỗ trợ!"
          )
        );
      }
    } else {
      dispatch(fetchError(response.status, result.message));
    }
  });
};

export const printBlList = () => async (dispatch, getState) => {
  const currentState = getState();
  let params = _.cloneDeep(currentState.admin.list.filter);
  delete params.pageSize;
  delete params.currentPage;
  params.limit = params.totalCount;
  const minifiedParams = paramBuilder(params);
  const response = await getBls(minifiedParams);
  if (response.status === 200) {
    const data = response.data;
    const bls =
      data.data.bls.length > 0 ? data.data.bls.map((x) => new Bl(x)) : [];
    return bls;
  }
};

const fetchBlListSuccess = (data, currentState) => ({
  type: "FETCH_BL_LIST_SUCCESS",
  payload: {
    data,
  },
});

export const exportBlListExcel = () => async (dispatch, getState) => {
  let currentState = getState();
  let params = Object.assign({}, currentState.admin.list.filter);
  delete params.pageSize;
  delete params.currentPage;
  let queryString = queryStringBuilder(params);
  window.location.href = `${API_URL}/admin/bls/export_excel${queryString}`;
};

export const setBlDetail = (bl) => ({
  type: "SET_SELECTED_BL",
  payload: bl,
});

export const setLoading = (state) => ({
  type: state ? "BEGIN_FETCH" : "STOP_LOADING",
});

export const fetchBl3PlsList = (bl_id, data) => async (dispatch) => {
  const response = await getThreePlsAvailable(bl_id, data);

  const result = response.data;
  if (response.status === 200) {
    if (result) {
      dispatch(fetch3PlsListSuccess(result.data.ThreePls));
    }
  } else {
    if (response.status === 404) {
      window.location.assign("/error/404");
    }
    dispatch(fetchError(response.status, result.data.message));
  }
};

const fetch3PlsListSuccess = (threePls) => ({
  type: "SET_SELECTED_BL_3PL",
  payload: threePls,
});

export const fetchBlShipperList = (bl_id, hub_id) => async (dispatch) => {
  const response = await getShipperListByBlApi(bl_id, hub_id);
  const result = response.data;

  if (response.status === 200) {
    if (result) {
      let ktvs = result.data.ktv_list.map((ktv) => {
        const asiaId = ktv?.asia_id?.toString().padStart(4, 0);

        return {
          value: ktv?.id,
          label:
            [asiaId, ktv?.name, ktv?.phone].filter(Boolean).join(" - ") ||
            ktv?.email,
          name: ktv?.name,
          areaCode: ktv?.area_code,
          fullAddress: ktv?.full_address,
          phone: ktv?.phone,
          status: ktv?.status,
          roleId: ktv?.role_id,
        };
      });
      dispatch(fetchShipperListSuccess(ktvs));
    } else {
      dispatch(
        fetchError(
          response.status,
          "Có lỗi xảy ra! Vui lòng liên hệ Logistic team để được hỗ trợ!"
        )
      );
    }
  } else {
    dispatch(fetchError(response.status));
  }
};

const fetchShipperListSuccess = (shipperList) => ({
  type: "FETCH_BL_LIST_SHIPPER_LIST_SUCCESS",
  payload: shipperList,
});

export const getCategoriesList = () => async (dispatch, getState) => {
  const filter = getState().admin.list.filter;
  const selectedCategories = filter.category_code.split(",");
  const response = await getCategories();
  const responseData = response.data;
  if (response.status === 200) {
    const categories = responseData.data.categories;
    if (categories.length > 0) {
      //convert flat list to hierarchical
      dispatch(setCategoriesFlatList(categories));
      const hierarchyDefault = flatToHierarchy(categories);
      dispatch(setDefaultCategories(hierarchyDefault));
      const hierarchy = flatToHierarchy(categories, selectedCategories);
      dispatch(setCategories(hierarchy));
    } else {
      const noResults = [
        {
          label: "Không có dữ liệu",
          value: "noresult",
          disabled: true,
        },
      ];
      dispatch(setCategories(noResults));
    }
  } else {
    dispatch(
      fetchError(response.status, (responseData && responseData.message) || "")
    );
  }
};

export const setCategories = (hierarchy) => ({
  type: "BL_LIST_SET_CATEGORIES_LIST",
  payload: hierarchy,
});

export const setDefaultCategories = (hierarchy) => ({
  type: "BL_LIST_SET_CATEGORIES_LIST_DEFAULT",
  payload: hierarchy,
});

export const setCategoriesFlatList = (list) => ({
  type: "BL_LIST_SET_FLAT_CATEGORIES_LIST",
  payload: list,
});

export default fetchBlList;
