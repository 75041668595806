import apiService from "./base";

export const getBusLinesV2 = async (data) => {
  return await apiService({
    url: "/admin/bus_lines",
    method: "get",
    params: data,
  });
};

export const createBusLine = async (data) => {
  return await apiService({
    url: "/admin/bus_lines",
    method: "put",
    data: data,
  });
};

export const updateBusLine = async (id, data) => {
  return apiService({
    url: `/admin/bus_lines/${id}`,
    method: "post",
    data: data,
  });
};

export const deleteBusLine = async (id) => {
  return apiService({
    url: `/admin/bus_lines/${id}`,
    method: "delete",
  });
};
