import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

const KTVAddress = ({ address, clickEditCustomer, classes }) => (
  <div>
    <span>{address || ""}</span>
    <Tooltip
      placement="top"
      title="Chỉnh sửa thông tin người nhận"
      aria-label="Add"
    >
      <IconButton
        style={{ marginTop: -8, height: 22 }}
        onClick={clickEditCustomer}
      >
        <EditIcon />
      </IconButton>
    </Tooltip>
  </div>
);

export default KTVAddress;
