import React from "react";
import Select from "react-select";

const _options = [
  { label: "Xe tải PV", value: "xe_tai_pv" },
  { label: "Shippper PV", value: "shipper_pv" },
  { label: "Kỹ thuật viên", value: "ktv" },
  { label: "Đại lí", value: "dai_ly" },
  { label: "CPN", value: "3pl" },
  { label: "Khác", value: "others" },
];

class VehicleTypeSelection extends React.Component {
  render() {
    const { value, onChange } = this.props;

    return (
      <Select
        value={value}
        onChange={(v) => onChange(v ? v : "")}
        options={_options}
        placeholder="Chọn phương tiện..."
        clearable={false}
        multi={true}
        simpleValue
        style={{ height: 40 }}
      />
    );
  }
}

export default VehicleTypeSelection;
