import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {
  Grid,
  CircularProgress,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { DistrictSelector, ProvinceSelector, WardSelector } from ".";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { fetchArea, GoogleMapLocationPicker, getDistance } from "..";
import { validateUtils } from "../../utils";
import AddressImg from "@images/address.png";
import { openNotiDialog, closeNotiDialog } from "@shared";

export const styles = () => ({
  text: {
    fontFamily: "Noto Sans, sans-serif",
  },
  title: {
    padding: "1rem 1.7rem",
  },
  dialog: {
    fontFamily: "Noto Sans, sans-serif",
    overflowY: "visible",
    "&Paper": {
      overflowY: "visible",
    },
  },
  label: {
    color: "rgba(0, 0, 0, 0.76)",
    fontWeight: 600,
  },
  inputRow: {
    display: "flex",
    flexDirection: "column",
  },
  root: {
    overflow: "visible",
    borderRadius: "1rem",
    transition: "all 0.4s linear !important",
  },
});

class UpdateAddressDialog extends React.Component {
  constructor(props) {
    super(props);
    const contact = this.props.defaultContact;
    this.areaCode = this.props.defaultContact.areaCode;
    this.state = {
      name: contact.name ? contact.name : "",
      phone: contact.phone ? contact.phone : "",
      street: contact.fullAddress ? contact.street : "",
      ward: this.areaCode && this.areaCode.length === 6 ? this.areaCode : "",
      wardName: "",
      district:
        this.areaCode && this.areaCode.length >= 4
          ? this.areaCode.slice(0, 4)
          : "",
      districtName: "",
      province: this.areaCode ? this.areaCode.slice(0, 2) : "",
      provinceName: "",
      customerAddress: contact.fullAddress ? contact.street : "",
      googleAddress: contact.gg_address ? contact.gg_address : "",
      mapState: "",
      googleResponse: null,
      useGoogleMap: this.props.bl.use_google_map,
      distance: null,
      type: "init",
      distanceLoading: false,
    };
    this.initialState = Object.assign({}, this.state);
  }

  detectChanges = () => {
    const { useGoogleMap } = this.state;
    const key = useGoogleMap
      ? ["name", "phone", "googleAddress", "customerAddress"]
      : ["name", "phone", "street", "ward", "district", "province"];
    for (let i = 0; i < key.length; i++) {
      if (
        this.initialState[key[i]] &&
        this.state[key[i]] &&
        this.initialState[key[i]] !== this.state[key[i]]
      ) {
        return true;
      }
    }
    return false;
  };

  checkForUpdateCustomerAddress = () => {
    const { customerAddress, googleAddress } = this.state;
    if (
      this.initialState.googleAddress !== googleAddress &&
      this.initialState.customerAddress === customerAddress
    ) {
      return true;
    }
    return false;
  };

  validate = () => {
    const { useGoogleMap, type } = this.state;
    const { onUpdate, onClose, openNotiDialog, closeNotiDialog } = this.props;
    let err = [];
    const rules = {
      name: {
        required: true,
      },
      phone: {
        required: true,
        regex: /^(0|84)\d{9,10}$/g,
      },
      street: {
        required: !useGoogleMap,
      },
      province: {
        required: !useGoogleMap,
      },
      district: {
        required: !useGoogleMap,
      },
      customerAddress: {
        required: useGoogleMap,
      },
      suggestions: {
        required: type === "input" && useGoogleMap,
      },
      googleAddress: {
        required: useGoogleMap,
      },
    };
    const message = {
      name: {
        required: { id: "name", msg: "Bạn chưa nhập tên khách hàng" },
      },
      phone: {
        required: { id: "phone", msg: "Bạn chưa nhập số điện thoại" },
        regex: { id: "phone", msg: "Số điện thoại không hợp lệ" },
      },
      street: {
        required: { id: "street", msg: "Bạn chưa nhập số nhà, tên đường" },
      },
      customerAddress: {
        required: {
          id: "customerAddress",
          msg: "Bạn chưa nhập địa chỉ khách hàng",
        },
      },
      suggestions: {
        required: {
          id: "googleAddress",
          msg: "Vui lòng nhập địa chỉ hợp lệ",
        },
      },
      googleAddress: {
        required: {
          id: "googleAddress",
          msg: "Bạn chưa chọn địa chỉ ",
        },
      },
      province: {
        required: { id: "province", msg: "Bạn chưa chọn tỉnh/thành" },
      },
      district: {
        required: { id: "district", msg: "Bạn chưa chọn quận/huyện" },
      },
    };
    err = validateUtils(this.state, rules, message);
    Object.keys(rules).forEach((x) => {
      let ele = document.getElementById(x);
      if (ele) ele.innerHTML = "";
    });
    if (err.length > 0) {
      err.forEach((x) => {
        let ele = document.getElementById(x.id);
        if (ele)
          ele.innerHTML = `<span style="color: red; font-size:12px">${x.msg}</span>`;
      });
    } else {
      const hasChange = this.detectChanges();
      if (!hasChange) {
        onClose();
        return;
      }
      if (this.checkForUpdateCustomerAddress()) {
        openNotiDialog(
          "alert",
          "Bạn vừa cập nhật địa chỉ Google Map nhưng chưa cập nhật địa chỉ khách hàng. Bạn có muốn tiếp tục cập nhật ?",
          null,
          "multi",
          () => {
            onUpdate(this.state);
            closeNotiDialog();
          }
        );
        return;
      }
      onUpdate(this.state);
    }
  };

  handleGoogleMapChange = async (e) => {
    this.setState(e);
    const keys = Object.keys(e);
    const stateKeys = ["", "wardName", "districtName", "provinceName"];
    if (keys.includes("googleResponse")) {
      if (e.googleResponse) {
        const addressComponents = e.googleResponse.address_components;
        this.setState({
          googleAddress:
            this.state.type === "init"
              ? this.state.googleAddress
              : e.googleResponse.formatted_address,
          distanceLoading: true,
        });
        for (let i = 1; i <= 3; i++) {
          if (addressComponents[i]) {
            this.setState({ [stateKeys[i]]: addressComponents[i].long_name });
          }
        }
        const distance = await getDistance({
          bl_id: this.props.bl.id,
          google_address: e.googleResponse,
        });
        this.setState({
          distance: distance.status === 200 ? distance.data.data.distance : "-",
          distanceLoading: false,
        });
      }
    }
  };

  handleCopyAddress = () => {
    const { googleResponse, googleAddress } = this.state;
    if (googleResponse) {
      const components = googleResponse.address_components;
      const street = components.filter((x) =>
        ["premise", "route", "street_number"].some(
          (r) => r.indexOf(x.types) >= 0
        )
      );
      this.setState({
        customerAddress: street.map((x) => x.long_name).join(" "),
      });
    } else {
      this.setState({ customerAddress: googleAddress });
    }
  };

  render() {
    const { classes, open, onClose, loading, fetchAreaAction, bl } = this.props;
    const {
      name,
      phone,
      street,
      ward,
      district,
      province,
      customerAddress,
      googleAddress,
      useGoogleMap,
      distance,
      distanceLoading,
    } = this.state;
    return (
      <Dialog
        open={open}
        onClose={onClose}
        className={classes.dialog}
        classes={{ paper: classes.root }}
        maxWidth={useGoogleMap ? "md" : "sm"}
        id="updateCustomerAddressDialog"
      >
        <DialogTitle className={classes.title}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={AddressImg} height={30} width={30} alt="address icon" />
            &nbsp;
            <span className={classes.text} style={{ flex: 1 }}>
              Cập nhật địa chỉ
            </span>
            {bl.use_google_map && (
              <FormControlLabel
                control={
                  <Switch
                    checked={useGoogleMap}
                    onChange={(e) =>
                      this.setState({ useGoogleMap: e.target.checked })
                    }
                  />
                }
                label="Google Map"
              />
            )}
          </div>
        </DialogTitle>
        <DialogContent
          style={{ overflowY: useGoogleMap ? "auto" : "visible", fontSize: 14 }}
        >
          <Grid container spacing={1}>
            <Grid item md={12}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item md={useGoogleMap ? 2 : 3} className={classes.label}>
                  Họ tên&nbsp;
                  <span className="required" />
                </Grid>
                <Grid item md={9} className={classes.inputRow}>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => this.setState({ name: e.target.value })}
                  />
                  <span id="name" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item md={useGoogleMap ? 2 : 3} className={classes.label}>
                  SĐT&nbsp;
                  <span className="required" />
                </Grid>
                <Grid item md={9} className={classes.inputRow}>
                  <input
                    value={phone}
                    onChange={(e) => this.setState({ phone: e.target.value })}
                    type="text"
                  />
                  <span id="phone" />
                </Grid>
              </Grid>
            </Grid>
            {!useGoogleMap ? (
              <React.Fragment>
                {" "}
                <Grid item md={12}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item md={3} className={classes.label}>
                      Số nhà - Đường&nbsp;
                      <span className="required" />
                    </Grid>
                    <Grid item md={9} className={classes.inputRow}>
                      <input
                        value={street}
                        onChange={(e) =>
                          this.setState({ street: e.target.value })
                        }
                        type="text"
                      />
                      <span id="street" />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item md={3} className={classes.label}>
                      Tỉnh/Thành phố&nbsp;
                      <span className="required" />
                    </Grid>
                    <Grid item md={9}>
                      <ProvinceSelector
                        province={province}
                        changeProvince={(value, label) =>
                          this.setState({
                            province: value,
                            provinceName: label,
                            district: null,
                          })
                        }
                        setDefaultProvince={(name) =>
                          this.setState({ provinceName: name })
                        }
                        fetchArea={fetchAreaAction}
                      />
                      <span id="province" />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item md={3} className={classes.label}>
                      Quận&nbsp;
                      <span className="required" />
                    </Grid>
                    <Grid item md={9}>
                      <DistrictSelector
                        province={province}
                        district={district}
                        changeDistrict={(value, label) =>
                          this.setState({
                            district: value,
                            districtName: label,
                            ward: null,
                          })
                        }
                        setDefaultDistrict={(name) =>
                          this.setState({ districtName: name })
                        }
                        fetchArea={fetchAreaAction}
                      />
                      <span id="district" />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item md={3} className={classes.label}>
                      Phường/Xã
                    </Grid>
                    <Grid item md={9}>
                      <WardSelector
                        district={district}
                        ward={ward}
                        changeWard={(value, label) =>
                          this.setState({ ward: value, wardName: label })
                        }
                        setDefaultWard={(name) =>
                          this.setState({ wardName: name })
                        }
                        fetchArea={fetchAreaAction}
                      />
                      <span id="ward" />
                    </Grid>
                  </Grid>
                </Grid>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Grid item md={12}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item md={2} className={classes.label}>
                      Địa chỉ khách hàng&nbsp;
                      <span className="required" />
                    </Grid>
                    <Grid item md={9} className={classes.inputRow}>
                      <input
                        type="text"
                        value={customerAddress}
                        onChange={(e) =>
                          this.setState({
                            customerAddress: e.target.value,
                            street: e.target.value,
                          })
                        }
                      />
                      <span id="customerAddress" />
                    </Grid>
                  </Grid>
                </Grid>
                <GoogleMapLocationPicker
                  address={googleAddress}
                  onChange={this.handleGoogleMapChange}
                  renderDistance={
                    googleAddress && (
                      <span>
                        Khoảng cách:{" "}
                        {distanceLoading ? (
                          <CircularProgress size={15} />
                        ) : (
                          distance
                        )}
                        km -{" "}
                      </span>
                    )
                  }
                  onCopyAddress={this.handleCopyAddress}
                />
              </React.Fragment>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => this.validate()}>
            {loading && <CircularProgress size={20} />}&nbsp; Cập nhật
          </Button>
          <Button color="primary" onClick={onClose}>
            Đóng
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  fetchAreaAction: (area) => dispatch(fetchArea(area)),
  openNotiDialog: (type, text, closeCallback, options, confirmCallback) =>
    dispatch(
      openNotiDialog(type, text, closeCallback, options, confirmCallback)
    ),
  closeNotiDialog: () => dispatch(closeNotiDialog()),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(UpdateAddressDialog)
);
