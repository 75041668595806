import moment from "moment";
import { getTransportStatisticApi, getSystemStatisticApi } from "@api";

export const getBlStatus = (filter) => {
  const params = {
    start_date: moment(filter.start_date).format("YYYY-MM-DD") + " 00:00:00",
    end_date: moment(filter.end_date).format("YYYY-MM-DD") + " 23:59:59",
  };
  return getTransportStatisticApi(params);
};

export const getSystemStatus = () => {
  return getSystemStatisticApi();
};
