import React, { useState } from "react";
import { connect } from "react-redux";
import { Tabs, Tab, Icon, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  setFilter,
  clearFilters,
  fetchUserFilter,
  setTab,
  fetchBlList,
  loadFilter,
} from "../../actions";
import { updateUserFilter } from "@api";
import { toggleSnackBar } from "@shared";
import { compose } from "recompose";

const styles = (theme) => ({
  extraBtn: {
    width: "100%",
    display: "inline-flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
  closeBtn: {
    position: "absolute",
    top: 5,
    right: 5,
    fontSize: 16,
    borderRadius: "50%",
    padding: 0,
    opacity: 0,
    background: "#80808036",
    transition: "opacity 0.25s ease-in-out",
    WebkitTransition: "opacity 0.25s ease-in-out",
    MozTransition: "opacity 0.25s ease-in-out",
  },
  enableButtonCloseTab: {
    opacity: 1,
  },
});

const FilterTab = ({
  classes,
  currentTab,
  visibleFilter,
  collapsed,
  setCollapse,
  ...props
}) => {
  const [tabHover, setTabHover] = useState();
  const {
    setTab,
    fetchUserFilter,
    fetchBlList,
    setFilter,
    showSnackbar,
    loadFilter,
  } = props;

  const hideFilter = async (filter, e) => {
    e.stopPropagation();
    const body = {
      display_state: false,
    };
    const index = visibleFilter.findIndex((x) => x.id === filter.id);
    const hideResponse = await updateUserFilter(filter.id, body);
    if (hideResponse.status === 200) {
      if (currentTab >= index + 2) {
        setTab(0);
        setFilter("quickFilter", "all");
        fetchBlList();
      }
      fetchUserFilter();
    } else {
      showSnackbar(
        true,
        <span>Có lỗi xảy ra ! Không thể ẩn bộ lọc</span>,
        "failed"
      );
    }
  };

  const changeFilter = (filter) => {
    /**
     * Thay đổi quick filter cho mod
     */
    setTab(filter);
    if (filter === 0) {
      setFilter("quickFilter", "all");
      fetchBlList();
    } else if (filter === 1) {
      setFilter("quickFilter", "waiting");
      fetchBlList();
    } else {
      loadFilter(filter);
      fetchBlList();
    }
  };

  return (
    <React.Fragment>
      <Tabs
        value={currentTab}
        onChange={(e, v) => changeFilter(v)}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        style={{ flex: 1 }}
      >
        <Tab label="Tất cả" />
        <Tab label="Chờ xử lý" />
        {visibleFilter.map((filter, index) => {
          return (
            <Tab
              onMouseEnter={(e) => setTabHover(index)}
              onMouseLeave={(e) => setTabHover(-1)}
              label={
                <div className={classes.extraBtn}>
                  <span>{filter.name}</span>
                  <Icon
                    className={
                      tabHover === index
                        ? `${classes.button} ${classes.closeBtn} ${classes.enableButtonCloseTab}`
                        : `${classes.button} ${classes.closeBtn}`
                    }
                    onClick={(e) => hideFilter(filter, e)}
                  >
                    close
                  </Icon>
                </div>
              }
              className={classes.extra}
              key={index}
            />
          );
        })}
      </Tabs>
      <IconButton onClick={() => setCollapse(!collapsed)}>
        <i className="material-icons">
          {collapsed ? "expand_less" : "expand_more"}
        </i>
      </IconButton>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  filter: state.admin.list.filter,
  user: state.admin.shared.user,
  savedFilter: state.admin.list.savedFilter,
  visibleFilter: state.admin.list.visibleFilter,
  currentTab: state.admin.list.currentTab,
});

const mapDispatchToProps = (dispatch) => ({
  setFilter: (property, value) => dispatch(setFilter(property, value)),
  loadFilter: (filter) => dispatch(loadFilter(filter)),
  clearFilters: () => dispatch(clearFilters()),
  setTab: (index) => dispatch(setTab(index)),
  fetchBlList: () => dispatch(fetchBlList()),
  showSnackbar: (state, message, type) =>
    dispatch(toggleSnackBar(state, message, type)),
  fetchUserFilter: () => dispatch(fetchUserFilter()),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(FilterTab);
