import React from "react";
import HubFilter from "./HubFilter";

// tab value
const ALL = 0;

class FromHubFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hubs: [],
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { tab, hubs, user, sellerId } = nextProps;
    if (tab === "all" || tab === ALL) {
      this.setState({
        hubs: hubs,
      });
    } else {
      this.setState(
        {
          hubs: user.getManageHubs(),
        },
        () => {
          if (this.state.hubs.length === 1 && nextProps.value === "") {
            this.props.setFilter("fromHub", `${this.state.hubs[0].id}`);
          }
        }
      );
    }
  }

  render() {
    const { hubs } = this.state;
    const { value, sellerId } = this.props;
    let newHubs = sellerId === "" ? hubs : [];
    const sellerIds = sellerId.split(",");
    for (let i = 0; i < sellerIds.length; i++) {
      for (let j = 0; j < hubs.length; j++) {
        if (hubs[j].sellerId && hubs[j].sellerId.toString() === sellerIds[i]) {
          newHubs = [...newHubs, hubs[j]];
        }
      }
    }
    return (
      <HubFilter
        value={value}
        hubs={newHubs}
        type="fromHub"
        setFilter={this.props.setFilter}
      />
    );
  }
}

export default FromHubFilter;
