import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid, Paper, Typography, Tab, Tabs } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import {
  getGroupingBls,
  changeSelection,
  selectAll,
  blGroupFilterChange,
  changeGroupType,
  changeShipMethod,
} from "../../actions";
import Select from "react-select";
import { SellerSelection } from "@shared";
import { BL_GROUP_OTHER_SYSTEM_OPTIONS, BlRequestType } from "@constants";
//styles
const styles = (theme) => ({
  paper: {
    margin: "0.5rem",
    padding: "1rem",
    width: "100%",
  },
  picker: {
    height: 32,
    borderLeft: "1px solid #ccc",
    borderBottom: "1px solid #ccc",
    borderTop: "1px solid #ccc",
    borderRadius: "4px 0 0 4px",
    paddingLeft: 8,
    flex: 1,
  },
  closeIcon: {
    height: 38,
    borderTop: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    borderBottom: "1px solid #ccc",
    borderRadius: "0 4px 4px 0",
    display: "flex",
    alignItems: "center",
    paddingRight: 6,
    color: "#797979",
    fontSize: 16,
    cursor: "pointer",
  },
  title: {
    textTransform: "uppercase",
    fontFamily: "Source Sans Pro, sans-serif",
    fontWeight: 600,
    letterSpacing: 1,
    marginBottom: "1.5rem",
    color: "#6464b5",
  },
  label: {
    textAlign: "right",
    fontWeight: "bold",
  },
  tabs: {
    borderBottom: "1px solid #a6a6b926",
  },
});

function BlGroupFilter({
  classes,
  hubs,
  getGroupingBls,
  filters,
  groupType,
  settings,
  blGroupFilterChange,
  changeGroupType,
}) {
  const [allHubOptions, setAllHubOptions] = useState([]);

  const handleChangeGroupType = (e, v) => {
    changeGroupType(v);
    changeShipMethod(v === "sale_order" ? "adidi" : "others");
  };

  const getHubOptions = () => {
    const sellerId = filters[groupType].seller_id;
    const hubBySellerId = allHubOptions.filter(
      (hub) => sellerId && hub.sellerId && hub.sellerId === sellerId
    );
    if (groupType === BlRequestType.wh_transfer) {
      return hubBySellerId;
    } else {
      const allowGroupHub = settings["ALLOW_GROUP_HUB"];
      return allowGroupHub
        ? allowGroupHub === "all"
          ? hubBySellerId
          : hubBySellerId.filter((x) => allowGroupHub.includes(x.value))
        : [];
    }
  };

  useEffect(() => {
    const formattedHubs = hubs.map((hub) => ({
      value: hub.id.toString(),
      label: hub.name,
      sellerId: hub.sellerId.toString(),
    }));

    setAllHubOptions(formattedHubs);
  }, [hubs]);

  const fromHubOptions = getHubOptions();
  return (
    <Grid
      container
      className={classes.text}
      spacing={1}
      onKeyPress={(e) => e.key === "Enter" && getGroupingBls()}
    >
      <Paper className={classes.paper} elevation={0}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom className={classes.title}>
            Gộp vận đơn
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.tabs}>
          <Tabs value={groupType} onChange={handleChangeGroupType}>
            <Tab label="Đơn Khách hàng" value="sale_order" />
            <Tab label="Đơn CKNB" value="wh_transfer" />
          </Tabs>
        </Grid>
        <br />
        <Grid item xs={10}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} md={1} className={classes.label}>
              <span className="errorMsg"> * </span>Công ty
            </Grid>
            <Grid item xs={12} md={4}>
              <SellerSelection
                value={filters[groupType].seller_id}
                multi={false}
                onChange={(v) =>
                  blGroupFilterChange({ seller_id: v === null ? "" : v })
                }
                clearable={false}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={10}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} md={1} className={classes.label}>
              Trạng thái khác
            </Grid>
            <Grid item xs={12} md={4}>
              <Select
                value={filters[groupType].packed_status}
                onChange={(status) =>
                  blGroupFilterChange({ packed_status: status })
                }
                options={BL_GROUP_OTHER_SYSTEM_OPTIONS}
                simpleValue
                fullWidth
                placeholder={"Trạng thái khác"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={10}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} md={1} className={classes.label}>
              <span className="errorMsg"> * </span>Kho gửi
            </Grid>
            <Grid item xs={12} md={4}>
              <Select
                value={filters[groupType].from_hub_id}
                onChange={(hub) => blGroupFilterChange({ from_hub_id: hub })}
                options={fromHubOptions}
                simpleValue
                fullWidth
                multi={groupType !== "wh_transfer"}
                placeholder={"Kho gửi"}
              />
            </Grid>
          </Grid>
        </Grid>
        {groupType === "wh_transfer" && (
          <Grid item xs={10}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} md={1} className={classes.label}>
                <span className="errorMsg"> * </span>Kho nhận
              </Grid>
              <Grid item xs={12} md={4}>
                <Select
                  value={filters[groupType].to_hub_id}
                  onChange={(hub) => blGroupFilterChange({ to_hub_id: hub })}
                  options={allHubOptions}
                  simpleValue
                  fullWidth
                  placeholder={"Kho nhận"}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={10}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={1} className={classes.label}>
              Ngày xuất kho
            </Grid>
            <Grid item xs={12} md={4}>
              <span className="pickers">
                <DatePicker
                  value={filters[groupType].export_date_from}
                  className={classes.picker}
                  invalidLabel={"Từ ngày xuất kho"}
                  format="DD-MM-YYYY"
                  onChange={(e) =>
                    blGroupFilterChange({
                      export_date_from: e.format("YYYY-MM-DD"),
                    })
                  }
                />
                <i
                  className={`material-icons ${classes.closeIcon}`}
                  onClick={() =>
                    blGroupFilterChange({
                      export_date_from: null,
                    })
                  }
                >
                  clear
                </i>
                &nbsp;-&nbsp;
                <DatePicker
                  value={filters[groupType].export_date_to}
                  className={classes.picker}
                  invalidLabel={"Đến ngày xuất kho"}
                  format="DD-MM-YYYY"
                  onChange={(e) =>
                    blGroupFilterChange({
                      export_date_to: e.format("YYYY-MM-DD"),
                    })
                  }
                />
                <i
                  className={`material-icons ${classes.closeIcon}`}
                  onClick={() =>
                    blGroupFilterChange({
                      export_date_to: null,
                    })
                  }
                >
                  clear
                </i>
              </span>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => getGroupingBls()}
              >
                Tìm
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  selected: state.admin.group.selected,
  searchBtn: state.admin.group.searchBtn,
  hubs: state.admin.shared.hubs,
  settings: state.admin.shared.settings,
  groupType: state.admin.group.groupType,
  filters: state.admin.group.filters,
});

const mapDispatchToProps = (dispatch) => ({
  getGroupingBls: (filter) => dispatch(getGroupingBls(filter)),
  changeSelection: (id, val) => dispatch(changeSelection(id, val)),
  selectAll: (val) => dispatch(selectAll(val)),
  changeGroupType: (type) => dispatch(changeGroupType(type)),
  blGroupFilterChange: (obj) => dispatch(blGroupFilterChange(obj)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(BlGroupFilter)
);
