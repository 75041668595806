import { ShipType } from "./Transport";
import { AddressType } from "./Address";
import Hub from "./Hub";

export const UserRole = {
  super_admin: "super_admin",
  admin: "admin",
  mod: "mod",
  sktv: "sktv",
  ktv: "ktv",
  onlyview: "onlyview",
};

export default class User {
  /**
   * Lưu thông tin user
   */
  constructor(user, areas = []) {
    this.role = user.role_id;
    this.id = user.id;
    this.name = user.name;
    this.asiaBranch = user.asia_branch;
    this.isKTV = user.role_id === "ktv" || user.role_id === "sktv";
    this.roleId = user.role_id;
    this.manageAreas = areas;
    this._manageHubs = user.manage_hubs
      ? user.manage_hubs.map((hub) => new Hub(hub))
      : [];
    this._manageHubIds = user.manage_hubs
      ? user.manage_hubs.map((hub) => hub.id)
      : [];
    this.areaCode = user.area_code;
    this.asiaId = user.asia_id;
    this.phone = user.phone ? user.phone : "";
  }

  /**
   * Kiểm tra user có quyền truy cập trang quản lý vận đơn hay không
   * @returns {boolean}
   */
  get adminPagePermission() {
    return [
      UserRole.mod,
      UserRole.admin,
      UserRole.super_admin,
      UserRole.onlyview,
    ].includes(this.role);
  }

  /**
   * Trả về list danh sách các hub mà user quản lý
   * @returns {Array}
   */
  getManageHubs = () => {
    return this._manageHubs;
  };

  /**
   * Trả về list id các hub mà user quản lý
   * @returns {Array}
   */
  getManageHubIds = () => {
    return this._manageHubIds;
  };

  /**
   * Kiểm tra nếu user có quyền xác nhận đã giao hàng cho bên vận chuyển hay không:
   *  + super_admin, admin: pass
   *  + mod: pass nếu hàng đang ở hub mà user quản lý
   *  + others: fail
   * @param lastTransport
   * @returns {boolean}
   */
  canConfirmPicked = (lastTransport) => {
    if ([UserRole.super_admin, UserRole.admin].includes(this.role)) {
      return true;
    } else if (this.role === UserRole.mod) {
      if (this.getManageHubIds().includes(lastTransport.fromAddress.objId)) {
        return true;
      }
    }

    return false;
  };

  /**
   * Kiểm tra nếu user có quyền xác nhận đã nhập hàng về kho hay không:
   *  + super_admin, admin: pass
   *  + mod: pass nếu hàng chuyển đến hub mà user quản lý
   *  + others: fail
   * @param lastTransport
   * @returns {boolean}
   */
  canConfirmOK = (lastTransport) => {
    if ([UserRole.super_admin, UserRole.admin].includes(this.role)) {
      return true;
    } else if (this.role === UserRole.mod) {
      if (lastTransport.toAddress.type === AddressType.hub) {
        if (this.getManageHubIds().includes(lastTransport.toAddress.objId)) {
          return true;
        }
      } else if (lastTransport.type === ShipType.hub_customer) {
        if (this.getManageHubIds().includes(lastTransport.fromAddress.objId)) {
          return true;
        }
      }
    }
    return false;
  };
  /**
   * Kiểm tra quyền xác nhận nhập kho với lộ trình đang giao thì bị hủy và đang hoàn hàng về kho
   *  - Transport đang hoàn hàng
   *  - Vận đơn đang giao
   * @returns {boolean}
   */
  canConfirmImportForShippingCancel = (lastTransport) => {
    if ([UserRole.super_admin, UserRole.admin].includes(this.role)) {
      return true;
    }
    if (
      UserRole.mod === this.role &&
      this.getManageHubIds().includes(lastTransport.fromAddress.objId)
    ) {
      return true;
    }
    return false;
  };

  /**
   * Kiểm tra nếu user có quyền xác nhận đã nhập hàng về kho hay không:
   *  + super_admin, admin: pass
   *  + mod: pass nếu hàng chuyển đến hub mà user quản lý
   *  + others: fail
   * @param lastTransport
   * @returns {boolean}
   */
  canConfirmImport = (lastTransport) => {
    if ([UserRole.super_admin, UserRole.admin].includes(this.role)) {
      return true;
    } else if (this.role === UserRole.mod) {
      if (lastTransport.toAddress.type === AddressType.hub) {
        if (this.getManageHubIds().includes(lastTransport.toAddress.objId)) {
          return true;
        }
      } else if (lastTransport.type === ShipType.hub_customer) {
        if (this.getManageHubIds().includes(lastTransport.fromAddress.objId)) {
          return true;
        }
      }
    }

    return false;
  };

  /**
   * Kiểm tra nếu user có quyền xác nhận hủy lộ trình hay không:
   *  + super_admin, admin: pass
   *  + mod: pass nếu hàng đang ở hub mà user quản lý
   *  + others: fail
   * @param lastTransport
   * @returns {boolean}
   */
  canConfirmTransportCancel = (bl) => {
    let lastTransport = bl.lastTransport;
    if ([UserRole.super_admin, UserRole.admin].includes(this.role)) {
      return true;
    } else if (this.role === UserRole.mod) {
      if (this.getManageHubIds().includes(lastTransport.fromAddress.objId)) {
        return true;
      } else if (bl.isWhTransfer) {
        if (this.getManageHubIds().includes(bl.toAddr.objId)) {
          return true;
        }
      }
    }

    return false;
  };

  /**
   * Kiểm tra nếu user có quyền xác nhận hoàn hàng hay không:
   *  + super_admin, admin: pass
   *  + mod: pass nếu hàng chuyển từ hub mà user quản lý
   *  + others: fail
   * @param lastTransport
   * @returns {boolean}
   */
  canConfirmReturn = (lastTransport) => {
    if ([UserRole.super_admin, UserRole.admin].includes(this.role)) {
      return true;
    } else if (this.role === UserRole.mod) {
      if (
        lastTransport.fromAddress.type === AddressType.hub &&
        this.getManageHubIds().includes(lastTransport.fromAddress.objId)
      ) {
        return true;
      } else if (
        lastTransport.fromAddress.type === AddressType.ktv &&
        this.getManageHubIds().includes(lastTransport.toAddress.objId)
      ) {
        return true;
      }
    }

    return false;
  };

  /**
   * Kiểm tra nếu user có quyền tạo lộ trình hay không:
   *  + super_admin, admin: pass
   *  + mod: pass nếu hàng đang ở khu vực user quản lý
   *  + others: fail
   *  + CKNB: mod của kho nhận có quyền tạo transport ở mọi lộ trình
   * @param currentGoodsAddress
   * @returns {boolean}
   */
  canCreateTransport = (bl) => {
    let currentGoodsAddress = bl.currentGoodsAddress;
    if ([UserRole.super_admin, UserRole.admin].includes(this.role)) {
      return true;
    } else if (this.role === UserRole.mod) {
      if (currentGoodsAddress.type === AddressType.hub) {
        if (this.getManageHubIds().includes(currentGoodsAddress.objId)) {
          return true;
        }
      } else if (bl.isWhTransfer) {
        if (this.getManageHubIds().includes(bl.toAddr.objId)) {
          return true;
        }
        if (this.getManageHubIds().includes(bl.fromAddr.objId)) {
          return true;
        }
      }
    }

    return false;
  };

  /**
   * Kiểm tra nếu user có quyền xác nhận hủy vận đơn:
   *  + super_admin, admin: pass
   *  + mod: pass nếu hàng đang ở khu vực user quản lý --> 11/9/18 : ko cho phép mod hủy vận đơn
   *  + others: fail
   * @param currentGoodsAddress
   * @param lastTransportHub
   * @param initialHub
   * @returns {boolean}
   */
  canConfirmCancel = (currentGoodsAddress, lastTransportHub, initialHub) => {
    if ([UserRole.super_admin, UserRole.admin].includes(this.role)) {
      return true;
    } /* else if (this.role === UserRole.mod) {
      if (currentGoodsAddress) {
        if (this.getManageHubIds().includes(currentGoodsAddress.objId) > -1) {
          return true;
        }
      } else if (lastTransportHub) {
        if (this.getManageHubIds().includes(lastTransportHub.objId)) {
          return true;
        }
      } else {
        if (this.getManageHubIds().includes(initialHub.id)) {
          return true;
        }
      }
    } */
    return false;
  };

  get canAccessBusLineManagement() {
    return ["admin", "super_admin"].includes(this.role);
  }
}
