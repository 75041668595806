import React from "react";

const ShipToAddressCell = ({ rowData }) => {
  return (
    <React.Fragment>
      <strong>{rowData.toAddr.displayName}</strong>
      <br />
      <span>
        {rowData.toAddr.fullAddress}
        {"."}
      </span>
      {rowData.toAddr.phone && (
        <React.Fragment>
          <br />
          <span>SĐT: {rowData.toAddr.phone}</span>{" "}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ShipToAddressCell;
