import React from "react";
import Select from "react-select";

const ShipMethodFilter = ({
  value,
  ktvList,
  onChange,
  placeholder = "Chọn kĩ thuật viên...",
  simpleValue = true,
  multi = true,
}) => {
  let ktvs = ktvList.map((ktv) => {
    ktv.value = ktv.value.toString();
    return ktv;
  });

  return (
    <Select
      value={value}
      onChange={onChange}
      options={ktvs}
      multi={multi}
      simpleValue={simpleValue}
      closeOnSelect={multi ? false : true}
      placeholder={placeholder}
      wrapperStyle={{ width: "100%" }}
    />
  );
};

export default ShipMethodFilter;
