import React from "react";
import Select from "react-select";
import { connect } from "react-redux";
class DeliveryTypes extends React.Component {
  handleChange = (e) => {
    const { options, onChange } = this.props;
    if (e) {
      const found = options.find((x) => x.service_code === e.value);
      onChange(found);
    } else {
      onChange(null);
    }
  };

  render() {
    const { value, options } = this.props;
    const _options =
      options && options.length > 0
        ? options.map((x) => ({
            value: x.service_code,
            label: x.fee
              ? `${x.service_name} (${x.fee.toLocaleString("en-US")}đ)`
              : x.service_name,
          }))
        : [];
    return (
      <Select
        value={value}
        options={_options}
        onChange={this.handleChange}
        placeholder="Chọn loại dịch vụ"
        style={{ height: 40 }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  threePls: state.admin.detail.threePls,
});
export default connect(mapStateToProps)(DeliveryTypes);
