import moment from "moment";

const _initialState = {
  filter: {
    from_hub: "",
    to_hub: "",
    start_date: moment().subtract(4, "months").startOf("month").toDate(),
    frequency: 3,
    vehicles_id: "",
    seller_id: "",
    group_type: "month",
  },
  loading: false,
  data: [],
  summary: [],
};

const VehicleReport = (state = _initialState, action) => {
  switch (action.type) {
    case "VEHICLE_REPORT_CHANGE_FILTER":
      return {
        ...state,
        filter: action.payload,
      };

    case "VEHICLE_REPORT_CHANGE_PAGINATION":
      return {
        ...state,
        pagination: action.payload,
      };
    case "VEHICLE_REPORT_CHANGE_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "VEHICLE_REPORT_SET_DATA":
      const result = action.payload.results;
      const summary = action.payload.summary;
      return {
        ...state,
        data: result,
        summary: summary,
      };
    default:
      return state;
  }
};

export default VehicleReport;
