import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import DateShipping from "./DateShipping";
import React from "react";

const styles = (theme) => ({
  dateBlock: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dateShip: {
    marginLeft: 10,
    fontSize: 14,
    fontFamily: "Noto Sans, sans-serif",
    textTransform: "uppercase",
    fontWeight: 600,
    letterSpacing: 1,
    color: "black",
    display: "inline",
  },
  blockDate: {
    "&:hover": {
      opacity: 0.8,
      background: "white",
    },
    cursor: "pointer",
    background: "#959fc738",
  },
  iconButton: {
    width: "50px",
    display: "inline-block",
  },
});

class DateBlock extends React.Component {
  constructor() {
    super();
    this.state = {
      switchOpen: false,
    };
  }
  switchOpen = () => {
    const { setMap, data } = this.props;
    this.setState({
      switchOpen: !this.state.switchOpen,
    });
    if (data.actions.length > 0) {
      setMap({ center: data.actions[0].location, zoom: 18 });
    }
  };
  render() {
    const { classes, data } = this.props;
    const { switchOpen } = this.state;
    return (
      <React.Fragment>
        <tr
          className={classes.blockDate}
          style={
            switchOpen === true
              ? { background: "#959fc738" }
              : { background: "white" }
          }
          onClick={this.switchOpen}
        >
          <td colSpan={9}>
            <IconButton aria-label="Xem thêm" className={classes.iconButton}>
              {switchOpen === false ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButton>
            <p className={classes.dateShip}>{data.date}</p>
          </td>
        </tr>
        {switchOpen && <DateShipping data={data.actions} />}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(DateBlock);
