import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Paper, Collapse, Popover } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import classnames from "classnames";

//styles
const styles = (theme) => ({
  blStatusChart: {
    padding: "2rem",
    background: "#ECF0F5",
  },
  productHeading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "1rem",
    borderRadius: "0.5rem 0.5rem 0 0",
  },
  paper: {
    borderRadius: "0.5rem",
  },
  subTitle: {
    fontFamily: "Source Sans Pro, sans-serif",
    textTransform: "uppercase",
    fontWeight: 600,
    letterSpacing: 1,
  },
});

class ExpandCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blStatusCollapse: true,
      threePlsCollapse: true,
      anchorEl: null,
    };
  }

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  render() {
    const { title, children, filter, classes } = this.props;
    const { blStatusCollapse, anchorEl } = this.state;
    return (
      <Paper className={classes.paper} elevation={0}>
        <div className={classes.productHeading}>
          <Typography type="title" className={classes.subTitle}>
            {title}
          </Typography>
          <div>
            {filter && (
              <React.Fragment>
                <IconButton aria-label="Bộ lọc" onClick={this.handleClick}>
                  <FilterListIcon />
                </IconButton>
                <Popover
                  elevation={5}
                  open={Boolean(anchorEl)}
                  onClose={this.handleClose}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  {filter}
                </Popover>
              </React.Fragment>
            )}
            <IconButton
              aria-label="Xem thêm"
              className={classnames(classes.expand, {
                [classes.expandOpen]: blStatusCollapse,
              })}
              onClick={() =>
                this.setState({ blStatusCollapse: !blStatusCollapse })
              }
            >
              <ExpandMoreIcon />
            </IconButton>
          </div>
        </div>

        <div className={classes.container}>
          <Collapse in={blStatusCollapse}>{children}</Collapse>
        </div>
      </Paper>
    );
  }
}

export default withStyles(styles)(ExpandCard);
