import React from "react";
import Button from "@material-ui/core/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import {
  TableRow,
  TableCell,
  TableHead,
  Table,
  TableBody,
} from "@material-ui/core";
import { formatDateTime, getKtvLabel } from "../../../utils";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  "@global": {
    "#shipDateChangeHistory td": {
      padding: "4px 10px",
    },
  },
});
class ShipDateChangeHistory extends React.Component {
  render() {
    const { open, history, onClose, allKtvList } = this.props;
    return (
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>Lịch sử hẹn giao</DialogTitle>
        <DialogContent>
          <Table id="shipDateChangeHistory">
            <TableHead>
              <TableRow>
                <TableCell>Ngày thay đổi</TableCell>
                <TableCell>Ngày hẹn giao lại</TableCell>
                <TableCell>Lí do</TableCell>
                <TableCell>KTV thay đổi</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.length > 0 ? (
                history.map((x, index) => (
                  <TableRow key={index}>
                    <TableCell>{formatDateTime(x.created_at)}</TableCell>
                    <TableCell>{formatDateTime(x.time)}</TableCell>
                    <TableCell>{x.reason}</TableCell>
                    <TableCell>{getKtvLabel(x.user_id, allKtvList)}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>Không có</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Đóng </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  allKtvList: state.admin.shared.allKtvList,
});

export default withStyles(styles)(
  connect(mapStateToProps, null)(ShipDateChangeHistory)
);
