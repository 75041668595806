import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { changeSelection, selectAll } from "../../actions";
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Checkbox,
  Paper,
  LinearProgress,
  Tooltip,
} from "@material-ui/core";
import { BlGroupStatusCell } from "./index";
import { withRouter } from "react-router-dom";
import { getStatus } from "../../../utils/BlUtils";
import { WMS_STATUS, BlRequestType } from "@constants";
import InitImg from "@images/init.png";
import PickedImg from "@images/picked.png";
import { formatDateTime } from "@utils";
//styles
const styles = (theme) => ({
  buttonProgress: {
    color: "#FFFFFF",
  },
  input: {
    height: 37,
    border: "1px solid #ccc",
    borderRadius: 4,
    paddingLeft: 8,
    width: "100%",
  },
  title: {
    fontFamily: "Noto Sans, sans-serif",
    fontWeight: 800,
    color: "#797979",
    paddingBottom: 16,
  },
  statusLabel: {
    color: "#fff",
    fontSize: 11,
    fontWeight: 800,
    padding: 4,
    borderRadius: 4,
  },
  text: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
  },
  dialogText: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: 16,
    lineHeight: 2,
    color: "rgba(0, 0, 0, 0.54)",
    width: "31rem",
  },
  blStatusCell: {
    color: "#ffffff",
    textAlign: "left",
    minWidth: "7rem",
  },
  clickable: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  hubCell: {
    lineHeight: "1.2rem",
    maxWidth: 250,
  },
  addrSection: {
    maxHeight: 150,
    overflow: "auto",
  },
  flex: {
    flex: 1,
    fontFamily: "sans-serif",
  },
  detailContainer: {
    paddingTop: "1rem",
  },
  statusContainer: {
    marginTop: 10,
    display: "inline",
  },
  imgStatusIcon: {
    border: "1px solid #D3D3D378",
    padding: 4,
    borderRadius: 10,
    background: "white",
  },
  statusCell: {
    display: "flex",
  },
  statusIcon: {
    fontSize: 14,
    border: "1px solid #D3D3D378",
    padding: "0.3rem",
    borderRadius: 14,
    background: "white",
  },
  textCenter: {
    textAlign: "center",
  },
  requestIdCell: {
    wordBreak: "break-all",
  },
});

function ClickableCell({ children, id, ...props }) {
  return (
    <TableCell
      {...props}
      onClick={() => window.open(`/van-don/${id}`, "_blank")}
    >
      {children}
    </TableCell>
  );
}

function BlGroupTable({
  bls,
  groupType,
  classes,
  changeSelection,
  selectAll,
  searchBtn,
  selectedBls,
}) {
  const renderHubAddr = (address) => {
    if (address.hub.asia_branch.code) {
      return (
        <React.Fragment>
          <div>
            <strong>{address.hub.asia_branch.code}</strong>
          </div>
          <div className={`${classes.addrSection} styledScrollbar`}>
            {address.hub.asia_branch.address}
          </div>
        </React.Fragment>
      );
    } else {
      return <strong>{address.display_name}</strong>;
    }
  };

  const renderToAddr = (requestType, address) => {
    if (requestType !== BlRequestType.wh_transfer)
      return (
        <React.Fragment>
          <div>
            <strong>{address.display_name}</strong>
          </div>
          <div className={`${classes.addrSection} styledScrollbar`}>
            {address.full_address}
          </div>
          <div>
            <strong>SĐT:</strong> {address.phone}
          </div>
        </React.Fragment>
      );
    else {
      return renderHubAddr(address);
    }
  };

  return (
    <Paper elevation={0}>
      {searchBtn && <LinearProgress />}
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell
              onClick={() =>
                selectAll(
                  selectedBls.length === bls.length && bls.length !== 0
                    ? false
                    : true
                )
              }
              className={classes.clickable}
            >
              <Checkbox
                color="primary"
                onChange={(e) => selectAll(e.target.checked)}
                checked={selectedBls.length === bls.length && bls.length !== 0}
              />
            </TableCell>
            <TableCell>Mã vận đơn</TableCell>
            <TableCell>Mã gói hàng</TableCell>
            <TableCell>Mã đơn hàng</TableCell>
            <TableCell>Trạng thái</TableCell>
            <TableCell>Trạng thái khác</TableCell>
            <TableCell>Kho xuất phát</TableCell>
            <TableCell>
              {groupType !== BlRequestType.wh_transfer
                ? "Nơi nhận"
                : "Kho nhận"}
            </TableCell>
            <TableCell>Ghi chú</TableCell>
            <TableCell>Ngày xuất kho</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bls.length > 0 ? (
            bls.map((element, id) => {
              return (
                <TableRow key={id} className={classes.clickable}>
                  <TableCell
                    onClick={() =>
                      changeSelection(
                        element,
                        selectedBls.includes(element) ? false : true
                      )
                    }
                  >
                    <Checkbox
                      color="primary"
                      checked={selectedBls.includes(element)}
                      onChange={(e) =>
                        changeSelection(element, e.target.checked)
                      }
                    />
                  </TableCell>
                  <ClickableCell id={element.id}>{element.id}</ClickableCell>
                  <ClickableCell
                    id={element.id}
                    className={classes.requestIdCell}
                  >
                    {element.document_id}
                  </ClickableCell>
                  <ClickableCell id={element.id}>
                    {element.order_code}
                  </ClickableCell>
                  <ClickableCell
                    id={element.id}
                    className={classes.blStatusCell}
                  >
                    <BlGroupStatusCell status={getStatus(element)} />
                  </ClickableCell>
                  <ClickableCell
                    id={element.id}
                    style={{ textAlign: "center", width: 30 }}
                  >
                    <div className={classes.statusCell}>
                      <Tooltip
                        title={
                          element.wms_status === WMS_STATUS.init
                            ? "Chờ soạn hàng"
                            : "Đã soạn hàng"
                        }
                        placement="right"
                      >
                        <div className={classes.statusContainer}>
                          <img
                            src={
                              element.wms_status === WMS_STATUS.init
                                ? InitImg
                                : PickedImg
                            }
                            width={18}
                            className={classes.imgStatusIcon}
                            alt="status"
                          />
                        </div>
                      </Tooltip>
                      &nbsp;
                      <Tooltip
                        title={
                          element.wms_status === WMS_STATUS.pack
                            ? `Đã đóng gói - ${formatDateTime(
                                element.packed_at
                              )}`
                            : "Chưa đóng gói"
                        }
                        placement="right"
                      >
                        <div className={classes.statusContainer}>
                          <i
                            className={`fas fa-box-open ${classes.statusIcon}`}
                            style={{
                              color:
                                element.wms_status === WMS_STATUS.pack
                                  ? "#64ace5"
                                  : "gray",
                            }}
                          />
                        </div>
                      </Tooltip>
                    </div>
                  </ClickableCell>
                  <ClickableCell id={element.id} className={classes.hubCell}>
                    {renderHubAddr(element.from_addr)}
                  </ClickableCell>
                  <ClickableCell id={element.id} className={classes.hubCell}>
                    {renderToAddr(element.request_type, element.to_addr)}
                  </ClickableCell>
                  <ClickableCell id={element.id}>{element.note}</ClickableCell>
                  <ClickableCell id={element.id}>
                    {element.request_created_at}
                  </ClickableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={6} className={styles.textCenter}>
                Không tìm thấy
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
}

const mapStateToProps = (state) => ({
  groupType: state.admin.group.groupType,
  bls: state.admin.group.bls,
  searchBtn: state.admin.group.searchBtn,
  selectedBls: state.admin.group.selectedBls,
});

const mapDispatchToProps = (dispatch) => ({
  changeSelection: (id, val) => dispatch(changeSelection(id, val)),
  selectAll: (val) => dispatch(selectAll(val)),
});

export default withRouter(
  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(BlGroupTable))
);
