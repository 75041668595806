import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import { CreateTransport } from "../../../bl_detail/components";
import { groupBls, fetchBlGroup3PlsList } from "../../actions";
import { Bl } from "../../../../../models";
const styles = () => ({
  subTitle: {
    fontSize: 14,
    padding: "8px 0 16px 0",
    fontFamily: "Source Sans Pro, sans-serif",
    textTransform: "uppercase",
    fontWeight: 600,
    letterSpacing: 1,
    color: "#7266ba",
  },
});

class BlGroupCreateTransport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: true,
    };
  }
  groupTransport = async (params) => {
    params.bl_ids = this.props.selectedBls.map((x) => x.id);
    await this.props.groupBls(params);
  };

  render() {
    const {
      selectedBls,
      user,
      hubs,
      ktvList,
      allKtvList,
      threePls,
      classes,
      fetchBlGroup3PlsList,
    } = this.props;
    return (
      <div>
        <Typography type="subheading" className={classes.subTitle}>
          Tạo lộ trình
        </Typography>
        <Grid container>
          <Grid
            style={{ marginLeft: "auto", marginRight: "auto", width: "70%" }}
          >
            <CreateTransport
              bl={new Bl(selectedBls[0])}
              user={user}
              ktvList={ktvList}
              allKtvList={allKtvList}
              hubs={hubs}
              createTransport={this.groupTransport}
              isGroupBl={true}
              threePls={threePls}
              fetch3PlsList={fetchBlGroup3PlsList}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  bl: state.admin.detail.bl,
  user: state.admin.shared.user,
  hubs: state.admin.shared.hubs,
  ktvList: state.admin.shared.ktvList,
  allKtvList: state.admin.shared.allKtvList,
  threePls: state.admin.group.threePls,
  selectedBls: state.admin.group.selectedBls,
});

const mapDispatchToProps = (dispatch) => ({
  fetchBlGroup3PlsList: (bl_id, data) =>
    dispatch(fetchBlGroup3PlsList(bl_id, data)),
  groupBls: (data) => dispatch(groupBls(data)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(BlGroupCreateTransport)
);
