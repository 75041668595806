import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { mapBlStatusText, mapBlStatusColor, mapSellerIdToText } from "@utils";
import { connect } from "react-redux";
import { WMS_STATUS } from "@constants";

const styles = (theme) => ({
  cod: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },

  statusLabel: {
    color: "#fff",
    fontSize: 12,
    fontWeight: 800,
    padding: 4,
    borderRadius: 4,
  },
  badge: {
    color: "white",
    textTransform: "uppercase",
    borderRadius: 3,
    padding: "0.2rem 0.5rem",
    background: "#5eacf1",
  },
  inverseStatusLabel: {
    fontSize: 12,
    fontWeight: 800,
    padding: 4,
    borderRadius: 4,
  },
  whImportLabel: {
    color: "#084673",
    fontSize: 12,
    fontWeight: 800,
    padding: 4,
    borderRadius: 4,
    lineHeight: 4,
  },
  statusColLabel: {
    textAlign: "right",
  },
});
const BlStatus = ({ bl, sellers, classes }) => (
  <div id="status-bar">
    <p className={classes.blId}>
      <strong className={classes.badge}>
        {mapSellerIdToText(bl.seller_id, sellers)}
      </strong>
      <br />
      <br />
      <strong>Mã vận đơn: {bl.id}</strong>
    </p>
    {bl.requestType !== "WH_TRANSFER" && (
      <p className={classes.cod}>
        <strong>
          COD:{" "}
          {bl.cod &&
            bl.cod.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " VNĐ"}
        </strong>
        <span>
          <br />
          <strong>
            COD đã thu:{" "}
            {bl.paidCOD
              ? bl.paidCOD.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                " VNĐ"
              : "0 VNĐ"}
          </strong>
        </span>
      </p>
    )}
    <p className={classes.statusColLabel}>
      {bl.status === "waiting_for_confirm" ? (
        <span className={classes.inverseStatusLabel}>
          {mapBlStatusText(bl.status)}
        </span>
      ) : (
        <span
          className={classes.statusLabel}
          style={{ backgroundColor: mapBlStatusColor(bl.status) }}
        >
          {mapBlStatusText(bl.status)}
        </span>
      )}
      {bl.wms_status === WMS_STATUS.partial_import && (
        <span>
          <br />
          <strong className={classes.whImportLabel}>Đã nhập kho 1 phần</strong>
        </span>
      )}
    </p>
  </div>
);

const mapStateToProps = (state) => ({
  sellers: state.admin.shared.sellers,
});

export default withStyles(styles)(connect(mapStateToProps)(BlStatus));
