import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import NumberFormat from "react-number-format";
const styles = (theme) => ({
  input: {
    height: 34,
    border: "1px solid #ccc",
    borderRadius: 4,
    paddingLeft: 8,
    width: "90%",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
  },
});

const WeightInput = ({ weight, onChange, classes }) => (
  <Grid item md={2} className={classes.inputContainer}>
    <NumberFormat
      value={weight}
      thousandSeparator={true}
      className={classes.input}
      onValueChange={(e) => onChange(e.value)}
    />
    &nbsp;g
  </Grid>
);

export default withStyles(styles)(WeightInput);
