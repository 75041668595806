import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  ListItem,
  List,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { compose } from "recompose";
import {
  VehicleSelection,
  ThreePlsServiceSelection,
  ThreePlsCategorySelection,
} from ".";
import { connect } from "react-redux";
import { ServiceTypeSelection } from "..";
import _ from "lodash";
import InputNumber from "rc-input-number";

const styles = (theme) => ({
  row: {
    padding: 5,
  },
  label: {
    textAlign: "right",
    paddingLeft: 10,
  },
  right: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  mustHave: {
    color: "#ff0000",
    fontSize: 10,
  },
  numberInput: {
    width: "2rem",
    right: 10,
    position: "absolute",
  },
  selectedServiceList: {
    maxHeight: "11rem",
    overflow: "auto",
  },
});

const SET_DEFAULT_FIELD = ["categories", "vehicleTypes"];
const getServiceSetting = (type, settings) => {
  const found = settings[type];
  return found ? found.settings : [];
};

const assignObjectPaths = (obj, stack) => {
  obj.forEach((el, id) => {
    if (stack) el.path = [...stack];
    if (!el.path) el.path = [];
    el.path.push(id);
    el.label =
      el.extra && el.extra.value ? `${el.name} - ${el.extra.value}` : el.name;
    el.checked = false;
    el.expanded = false;
    if (el.extra && el.extra.need_quantity) {
      el.quantity = 1;
    }
    if (el.children && el.children.length > 0) {
      assignObjectPaths(el.children, el.path);
    }
  });
  return obj;
};

const getSelectedNode = (obj, list) => {
  obj.forEach((el, id) => {
    if (el.children && el.children.length > 0) {
      getSelectedNode(el.children, list);
    }
    if (el.checked && el.extra.need_quantity) {
      list.push(el);
    }
  });
};

const setValue = (tree, key, value) => {
  for (let i = 0; i < tree.length; i++) {
    if (tree[i].value === key) {
      tree[i].children = value;
      break;
    } else if (tree[i].children && tree[i].children.length > 0) {
      setValue(tree[i].children, key, value);
    }
  }
};

/* Remove unselected node from tree */
const getSelected = (node, parentNode) => {
  for (let i = 0; i < node.length; i++) {
    const x = node[i];
    if (x.children && x.children.length > 0) {
      getSelected(x.children, x);
      if (!x.checked && x.children.length === 0) {
        node.splice(i, 1);
        i--;
      }
    } else {
      if (!x.checked) {
        if (parentNode) {
          parentNode.children.splice(i, 1);
        } else {
          node.splice(i, 1);
        }
        i--;
      }
    }
  }
};

const ThreePlsExtraOptions = ({ classes, ...props }) => {
  const [type, setType] = useState("");
  //STATE
  const [extra, setExtra] = useState(null); //state used for create transport body request
  const [flatExtra, setFlatExtra] = useState(null); //state used for UI display
  const [services, setService] = useState([]);
  const [group, setGroup] = useState([]);
  const [categories, setCategories] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [selectedNodes, setSelectedNodes] = useState([]);

  //Update options on type change
  useEffect(() => {
    const setting = _.cloneDeep(props.settings);
    const extraOptions = getServiceSetting(type, setting);
    const options = extraOptions.services
      ? assignObjectPaths(extraOptions.services.settings)
      : [];
    const group = Object.keys(extraOptions);
    setService(options);
    setGroup(group);
    if (extraOptions.categories) {
      setCategories(extraOptions.categories.settings);
    }
    if (extraOptions.vehicleTypes) {
      setVehicleTypes(extraOptions.vehicleTypes.settings);
    }
    setSelectedNodes([]);
    if (group.length > 0) {
      let selectedSetting = _.cloneDeep(props.settings[type]);
      delete selectedSetting.settings;
      let newExtra = {
        ...selectedSetting,
        children: [],
      };
      let newFlatExtra = {};
      group.forEach((x) => {
        const defaultVal =
          SET_DEFAULT_FIELD.includes(x) &&
          extraOptions[x].settings &&
          extraOptions[x].settings.length > 0
            ? extraOptions[x].settings[0]
            : null;
        newFlatExtra[x] = defaultVal ? defaultVal.value : null;
        //object type model
        let object_type = _.cloneDeep(extraOptions[x]);
        delete object_type.settings;
        newExtra.children.push({
          ...object_type,
          children: defaultVal ? [defaultVal] : [],
        });
      });
      setExtra(newExtra);
      setFlatExtra(newFlatExtra);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  //update extra to create transport component
  useEffect(() => {
    props.onChange(extra);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extra]);

  const handleChangeService = (e) => {
    setService(e);
    changeExtra("services", e);
    const selectedNode = [];
    //get all child selected nodes as flat list
    getSelectedNode(e, selectedNode);
    setSelectedNodes(selectedNode);
  };

  const handleChangeQuantity = (value, index) => {
    const list = [...selectedNodes];
    list[index].quantity = value;
    setSelectedNodes(list);
    updateNode(list[index].path, "quantity", value);
  };

  const updateNode = (path, key, value) => {
    const pathStr = path
      .map((x, index) =>
        index === path.length - 1 ? `[${x}]` : `[${x}].children`
      )
      .join("");
    const tree = [...services];
    eval(`tree${pathStr}.${key} = ${value || "null"}`);
    setService(tree);
    changeExtra("services", tree);
  };

  const changeExtra = async (nodeName, value) => {
    let newExtra = _.cloneDeep(extra);
    let newVal = _.cloneDeep(value);
    if (nodeName === "services") {
      getSelected(newVal);
    }
    setValue(newExtra.children, nodeName, newVal);
    setExtra(newExtra);
    let newFlatExtra = { ...flatExtra };
    switch (nodeName) {
      case "services":
        newFlatExtra[nodeName] = newVal;
        break;
      default:
        newFlatExtra[nodeName] = newVal[0].value;
    }
    setFlatExtra(newFlatExtra);
  };

  return (
    <React.Fragment>
      <Grid container className={classes.row} spacing={1} alignItems="center">
        <Grid item md={3} className={classes.label}>
          <strong>
            <span className="required" />
            Loại dịch vụ
          </strong>
        </Grid>
        <Grid item className={classes.right}>
          <ServiceTypeSelection
            value={type}
            onChange={(v) => setType(v)}
            settings={props.settings}
          />
        </Grid>
      </Grid>

      {group.includes("categories") && (
        <Grid container className={classes.row} spacing={1} alignItems="center">
          <Grid item md={3} className={classes.label}>
            <strong>
              <span className="required" />
              Nhóm hàng
            </strong>
          </Grid>
          <Grid item md={5} lg={5} className={classes.right}>
            <ThreePlsCategorySelection
              data={categories}
              value={flatExtra.categories}
              onChange={(e) => changeExtra("categories", [e])}
            />
          </Grid>
        </Grid>
      )}
      {group.includes("vehicleTypes") && (
        <Grid container className={classes.row} spacing={1} alignItems="center">
          <Grid item md={3} className={classes.label}>
            <strong>
              <span className="required" />
              Phương tiện
            </strong>
          </Grid>
          <Grid item md={5} lg={5} className={classes.right}>
            <VehicleSelection
              value={flatExtra.vehicleTypes}
              data={vehicleTypes}
              onChange={(e) => changeExtra("vehicleTypes", [e])}
            />
          </Grid>
        </Grid>
      )}
      {group.includes("services") && (
        <Grid
          container
          className={classes.row}
          wrap="nowrap"
          spacing={1}
          alignItems="center"
        >
          <Grid item md={3} className={classes.label}>
            {type === "installation" && <span className="required" />}
            <strong>Dịch vụ</strong>
          </Grid>
          <Grid item md={9} className={classes.right}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <ThreePlsServiceSelection
                data={services}
                onChange={handleChangeService}
              />
              &nbsp;&nbsp;
              {type !== "installation" && (
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={props.allow_stock_view}
                      onChange={(e) =>
                        props.changeValue({
                          allow_stock_view: e.target.checked,
                        })
                      }
                    />
                  }
                  label="Cho xem hàng"
                />
              )}
            </div>
            <List className={classes.selectedServiceList}>
              {selectedNodes.map((x, key) => (
                <ListItem key={key} role={undefined} dense>
                  <span style={{ maxWidth: "70%" }}>{x.label}</span>
                  <InputNumber
                    className={classes.numberInput}
                    min={1}
                    style={{ width: 100 }}
                    value={x.quantity}
                    onChange={(e) => handleChangeQuantity(e, key)}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  settings: state.admin.detail.settings,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(ThreePlsExtraOptions);
