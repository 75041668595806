const getErrorMessage = (httpStatus, message) => {
  // Render message lỗi tương ứng với http status code
  // Đối với lỗi api, trả về custom msg
  if (httpStatus === 500) {
    return "Lỗi hệ thống. Vui lòng liên hệ Logistic team để được hỗ trợ";
  } else {
    return message;
  }
};

export default getErrorMessage;

export const validateUtils = (data, rules, message) => {
  let errMsg = [];
  Object.keys(data).forEach((key) => {
    if (rules[key]) {
      let rulesList = Object.keys(rules[key]);
      for (let i = 0; i < rulesList.length; i++) {
        let hasError = false;
        let rule = rulesList[i];
        switch (rule) {
          case "required":
            if (rules[key][rule])
              if (!data[key]) {
                errMsg.push(message[key][rule]);
                hasError = true;
              }
            break;
          case "type":
            if (data[key]) {
              switch (rules[key].type) {
                case "integer":
                  if (!Number.isInteger(Number(data[key]))) {
                    errMsg.push(message[key][rule]);
                    hasError = true;
                  }
                  break;
                default:
                  return;
              }
            }
            break;
          case "range":
            let min = "";
            let max = "";
            const temp = rules[key].range.split("-");
            min = Number(temp[0]);
            max = Number(temp[1]);
            if (data[key]) {
              const val = Number(data[key]);
              if (val < min || val > max) {
                errMsg.push(message[key][rule]);
                hasError = true;
              }
            }
            break;
          case "custom":
            if (rules[key].custom()) {
              errMsg.push(message[key][rule]);
              hasError = true;
            }
            break;
          case "regex":
            if (data[key]) {
              if (!data[key].match(rules[key].regex)) {
                errMsg.push(message[key][rule]);
                hasError = true;
              }
            }
            break;
          default:
            return;
        }
        if (hasError) break;
      }
    }
  });
  return errMsg;
};

/* VALIDATION RULES:
  {
    field_name: {
      required: true|false,
      range: "min-max", (type default: integer)
      type: "integer|string",
      custom: function () { return true|false }
    }
  }
  NOTE: rule must be in order : required > type > range
*/
export const measurementValidationRules = {
  height: {
    type: "integer",
    range: "1-5000",
  },
  width: {
    type: "integer",
    range: "1-5000",
  },
  length: {
    type: "integer",
    range: "1-5000",
  },
  weight: {
    type: "integer",
    range: "1-10000000",
  },
  inventory_count: {
    type: "integer",
    range: "1-1000",
  },
};

export const measurementValidationKtvRules = {
  shipper: {
    required: true,
  },
  height: {
    type: "integer",
    range: "1-5000",
  },
  width: {
    type: "integer",
    range: "1-5000",
  },
  length: {
    type: "integer",
    range: "1-5000",
  },
  weight: {
    type: "integer",
    range: "1-10000000",
  },
  inventory_count: {
    type: "integer",
    range: "1-1000",
  },
};

export const measurementValidationMsg = {
  height: {
    required: "Bạn chưa nhập chiều cao kiện hàng",
    range: "Chiều cao kiện hàng phải nằm trong khoảng 1~5,000cm",
    type: "Chiều cao không hợp lệ",
  },
  width: {
    required: "Bạn chưa nhập chiều rộng kiện hàng",
    range: "Chiều rộng kiện hàng phải nằm trong khoảng 1~5,000cm",
    type: "Chiều rộng không hợp lê",
  },
  length: {
    required: "Bạn chưa nhập chiều dài kiện hàng",
    range: "Chiều dài kiện hàng phải nằm trong khoảng 1~5,000cm",
    type: "Chiều dài không hợp lệ",
  },
  weight: {
    required: "Bạn chưa nhập khối lượng kiện hàng",
    range: "Khối lượng kiện hàng phải nằm trong khoảng 1~10,000,000g",
    type: "Khối lượng không hợp lệ",
  },
  inventory_count: {
    required: "Bạn chưa nhập số kiện hàng",
    range: "Số kiện hàng phải nằm trong khoảng 1~1,000",
    type: "Số kiện không hợp lệ",
  },
  service_id: {
    custom: "Bạn chưa chọn loại dịch vụ",
  },
  shipper: {
    required: "Bạn chưa chọn KTV giao hàng",
  },
  bus_line: {
    custom: "Bạn chưa chọn Nhà xe",
  },
  extra: {
    custom: "Vui lòng chọn dịch vụ cho phương thức vận chuyển Adidi",
  },
};
