import React from "react";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

const styles = (theme) => ({
  "@global": {
    ".react-daterange-picker__wrapper": {
      border: "1px solid #cccccc8a",
      borderRadius: 4,
      display: "flex",
      height: 38,
      alignItems: "center",
      fontSize: "14px",
      paddingLeft: 3,
    },
    ".react-daterange-picker": {
      display: "block",
    },
  },
});

class DateRangeSelection extends React.Component {
  render() {
    return (
      <React.Fragment>
        <DateRangePicker
          onChange={(date) => {
            this.props.onChange(
              date
                ? [moment(date[0]).format(), moment(date[1]).format()]
                : [null, null]
            );
          }}
          value={
            this.props.value[0]
              ? [new Date(this.props.value[0]), new Date(this.props.value[1])]
              : [null, null]
          }
          locale="vi-VN"
          calendarIcon={null}
          showLeadingZeros={true}
          clearIcon={
            <i
              className="material-icons"
              style={{ fontSize: 11, color: "#999999", fontWeight: "bold" }}
            >
              clear
            </i>
          }
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(DateRangeSelection);
