import React, { useState, useEffect } from "react";
import DropdownTreeSelect from "react-dropdown-tree-select";
import { connect } from "react-redux";
import { setCategories } from "../../bl_list/actions/fetchBlList";
import { setFilter } from "../../bl_list";
import { assignObjectPaths } from "../../utils";
import _ from "lodash";

function setAllChildElement(node, checked) {
  node.checked = checked;
  if (node.children) {
    node.children.forEach((element) => {
      setAllChildElement(element, checked);
    });
  }
}

function resetNodePath(node) {
  node.forEach((x) => {
    x.path = [];
    if (x.children && x.children.length > 0) {
      resetNodePath(x.children);
    }
  });
}

//Only used in bl-list
function CategoriesSelection(props) {
  const {
    categories,
    setCategories,
    setFilter,
    seller_id,
    flatCategories,
    filter,
  } = props;

  const handleChangeCategories = (currentNode, selectedNodes) => {
    const checked = selectedNodes.includes(currentNode);
    const expanded = currentNode.expanded;
    set(categories, currentNode.path.reverse(), checked, expanded);
    setCategories(categories);
    setFilter("category_code", selectedNodes.map((x) => x.value).join(","));
  };

  const set = (categories, path, checked, expanded) => {
    if (path.length === 1) {
      categories[path[0]].expanded = expanded;
      setAllChildElement(categories[path[0]], checked);
      return;
    }
    const index = path[path.length - 1];
    path.pop();
    categories[index].expanded = true;
    set(categories[index].children, path, checked, expanded);
  };

  const [prevCategories, setPrevCategories] = useState([]);
  const [prevSellerId, setPrevSellerId] = useState();
  const [categoriesBySeller, setCategoriesBySeller] = useState({});
  const [allSellers, setAllSellers] = useState([]);

  //Handle on init
  useEffect(() => {
    if (prevCategories.length === 0 && categories.length > 0) {
      //Get distinct seller id in categories list
      const sellers = [...new Set(categories.map((x) => x.seller_id))];
      setAllSellers(sellers);
      let categoriesTree = {};
      //Get categories by seller id
      sellers.forEach((x) => {
        categoriesTree[x] = categories.filter((k) => k.seller_id === x);
      });
      setCategoriesBySeller(categoriesTree);

      //Step 1: build new categories tree
      let newCategories = [];
      let selectedSellers = seller_id ? seller_id.split(",") : allSellers;
      selectedSellers = selectedSellers.map((sellerId) => Number(sellerId));
      if (selectedSellers.length > 0) {
        selectedSellers.sort();
        selectedSellers.forEach((x) => {
          if (prevSellerId && prevSellerId.includes(x)) {
            newCategories = [
              ...newCategories,
              ...categories.filter((k) => k.seller_id === x),
            ];
          } else {
            if (categoriesBySeller[x]) {
              newCategories = [...newCategories, ...categoriesBySeller[x]];
            }
          }
        });
        resetNodePath(newCategories);
        assignObjectPaths(newCategories);
        setCategories(newCategories);
      }
    }
    setPrevCategories(categories);
  }, [
    allSellers,
    categories,
    categoriesBySeller,
    prevCategories.length,
    prevSellerId,
    seller_id,
    setCategories,
  ]);

  //Handle on change seller_id
  useEffect(() => {
    if (prevSellerId !== seller_id) {
      //Step 1: build new categories tree
      let newCategories = [];
      const selectedSellers = seller_id ? seller_id.split(",") : allSellers;
      if (selectedSellers.length > 0) {
        selectedSellers.sort();
        selectedSellers.forEach((x) => {
          if (prevSellerId && prevSellerId.includes(x)) {
            newCategories = [
              ...newCategories,
              ...categories.filter((k) => k.seller_id === x),
            ];
          } else {
            if (categoriesBySeller[x]) {
              newCategories = [...newCategories, ...categoriesBySeller[x]];
            }
          }
        });
        resetNodePath(newCategories);
        assignObjectPaths(newCategories);
        setCategories(newCategories);
      }

      //Step 2: remove selected nodes not belong to selected seller
      const flatCat = _.cloneDeep(flatCategories);
      if (seller_id && flatCat && flatCat.length > 0) {
        const selectedCategories = filter.category_code
          ? filter.category_code.split(",")
          : [];

        const newSelectedCategories = flatCat.filter((x) => {
          return (
            selectedSellers.includes(x.companyId) &&
            selectedCategories.includes(x.code)
          );
        });
        setFilter(
          "category_code",
          newSelectedCategories.map((x) => x.code).join(",")
        );
      }
    }

    setPrevSellerId(seller_id);
  }, [
    allSellers,
    categories,
    categoriesBySeller,
    filter.category_code,
    flatCategories,
    prevSellerId,
    seller_id,
    setCategories,
    setFilter,
  ]);

  return (
    <DropdownTreeSelect
      data={categories}
      onChange={handleChangeCategories}
      texts={{
        placeholder: "Chọn nhóm ngành...",
        noMatches: "Không tìm thấy",
      }}
      className="mdl-demo"
    />
  );
}

const mapStateToProps = (state) => ({
  categories: state.admin.list.categories,
  flatCategories: state.admin.list.flatCategories,
  filter: state.admin.list.filter,
});

const mapDispatchToProps = (dispatch) => ({
  setFilter: (property, value) => dispatch(setFilter(property, value)),
  setCategories: (list) => dispatch(setCategories(list)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoriesSelection);
