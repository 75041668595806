import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import {
  ShipTypeSelection,
  ToHubSelection,
  ShipperExtraSelection,
  EstimatedDeliveryTimePicker,
  NoteInput,
  WeightInput,
  SizeInput,
  InventoryCount,
  ShipMethodSelection,
  ToKTVSelection,
  KTVAddress,
  DeliveryTypes,
  PlRefInput,
  BusLineSelection,
  DebtTransferOption,
} from "../../../shared";
import {
  BlDirection,
  RequestType,
  ShipType,
  ShipMethod,
} from "../../../../../models";
import { ThreePlsExtraOptions } from "..";

const styles = (theme) => ({
  row: {
    padding: 5,
  },
  label: {
    textAlign: "right",
    paddingLeft: 10,
  },
  right: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  mustHave: {
    color: "#ff0000",
    fontSize: 10,
  },
  text: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
  },
  ktvNote: {
    color: "#f44336",
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
  },
  selectKTV: {
    fontFamily: "Noto Sans, sans-serif",
    height: 40,
  },
  justifyTop: {
    justifyContent: "flex-start",
  },
  justifyTopText: {
    justifyContent: "flex-start",
    marginTop: 14,
  },
  baseLine: {
    alignItems: "baseline",
  },
  align: {
    margin: "0px 0px 3px",
  },
  heightMax: {
    maxHeight: 51,
  },
});

class TransportByAll extends React.Component {
  async UNSAFE_componentWillReceiveProps(nextProp) {
    const { height, width, length, toHub, weight, bl, shipMethod, shipType } =
      nextProp;
    if (
      toHub.id !== this.props.toHub.id ||
      height !== this.props.height ||
      width !== this.props.width ||
      length !== this.props.length ||
      (shipType !== this.props.shipType && this.props.shipType !== "")
    ) {
      const isMeasurementValid =
        height && height > 0 && width && width > 0 && length && length > 0;
      const isToHubValid = ["customer_hub", "hub_hub"].includes(shipType)
        ? Boolean(toHub && toHub.id && toHub.id !== bl.toAddr.objId)
        : true;
      if (isMeasurementValid && isToHubValid) {
        const data = {
          ship_type: shipType,
          weight,
          height,
          width,
          length,
          to_hub: toHub ? toHub.id : null,
          ship_method: shipMethod,
        };
        let result = await this.props.fetch3PlsList(bl.id, data);

        if (shipMethod && shipMethod !== "ktv") {
          let serviceListResult = await this.props.getServiceListByShipMethod(
            this.props.bl.id,
            data
          );
          this.props.changeValue({
            serviceList: serviceListResult.data,
          });
          const found = result.data.ThreePls.find(
            (x) => x.code_name === nextProp.shipMethod
          );
          this.props.changeValue({
            estimate_fee: found ? found.estimate_fee : nextProp.estimate_fee,
          });
        }
      } else {
        const isPrevMeasurementValid =
          this.props.height &&
          this.props.height > 0 &&
          this.props.width &&
          this.props.width > 0 &&
          this.props.length &&
          this.props.length > 0;
        const isPrevToHubValid = ["customer_hub", "hub_hub"].includes(
          this.props.shipType
        )
          ? Boolean(
              this.props.toHub &&
                this.props.toHub.id &&
                this.props.toHub.id !== this.props.bl.toAddr.objId
            )
          : true;
        const data = {
          ship_type: shipType,
          weight,
          height,
          width,
          length,
          to_hub: toHub ? toHub.id : null,
          ship_method: shipMethod,
        };
        if (isPrevMeasurementValid || isPrevToHubValid) {
          await this.props.fetch3PlsList(bl.id, data);
          if (shipMethod !== "ktv" && shipMethod)
            await this.props.getServiceListByShipMethod(bl.id, {
              ship_method: nextProp.shipMethod,
            });
        }
        this.props.changeValue({ estimate_fee: null });
      }
    }
  }

  manualShipMethod = () => {
    return this.props.threePls
      .filter((x) => x.type.toLowerCase() === "manual")
      .map((x) => x.code_name);
  };

  canSelectToHub = () => {
    const { bl, shipType } = this.props;
    if ([ShipType.customer_hub, ShipType.hub_hub].includes(shipType))
      return true;

    if (
      bl.direction === BlDirection.returning &&
      ![ShipType.customer_customer].includes(shipType)
    )
      return true;
    if (bl.direction === BlDirection.returning) return true;
    return false;
  };

  canShowToHubAddr = () => {
    const { bl, shipType, toHub } = this.props;
    if (toHub.id) {
      if (!bl.isWhTransfer) return true;
      if (
        [ShipType.customer_hub, ShipType.hub_hub].includes(shipType) &&
        bl.isWhTransfer
      )
        return true;
    }
    return false;
  };

  getThreePlsService = (ship_method) => {
    const { threePls } = this.props;
    const selected = threePls.filter((x) => x.code_name === ship_method);
    return selected.length > 0 ? selected[0].services : [];
  };

  getMissingParamForEDF = () => {
    const { height, width, length, toHub, shipType } = this.props;
    const str = [];
    if (!height || height === 0) str.push(" chiều cao");
    if (!width || width === 0) str.push(" chiều rộng");
    if (!length || length === 0) str.push(" chiều dài");
    if (
      ["hub_hub", "customer_hub"].includes(shipType) &&
      (!toHub || !toHub.id)
    ) {
      str.push(" kho nhận");
    }
    return str.length > 0 ? `Không thể tạm tính (thiếu ${str.toString()})` : "";
  };

  render() {
    const {
      bl,
      hubs,
      ktvList,
      shipType,
      shipMethod,
      toHub,
      shipperExtra,
      classes,
      changeValue,
      height,
      width,
      length,
      weight,
      inventoryCount,
      vehicleGasCost,
      isGroupBl,
      threePls,
      service_id,
      toKTV,
      allKtvList,
      alter_address,
      allow_stock_view,
      plRef,
      changeShipMethod,
      serviceList,
      bus_line,
      is_debit,
      edt,
      bus_line_list,
    } = this.props;
    return (
      <div className={classes.text}>
        <Grid container alignItems="center" className={classes.row} spacing={1}>
          <Grid item md={3} className={classes.label}>
            <strong>Số kiện</strong>
          </Grid>
          <Grid item className={classes.right}>
            <InventoryCount
              onChange={(e) => changeValue({ inventory_count: e })}
              inventoryCount={inventoryCount}
            />
          </Grid>
          <Grid item>
            <strong>Khối lượng</strong>
          </Grid>
          <WeightInput
            onChange={(e) => changeValue({ weight: e })}
            weight={weight}
          />
        </Grid>

        <Grid container alignItems="center" className={classes.row} spacing={1}>
          <Grid item md={3} className={classes.label}>
            <strong>Kích thước</strong>
          </Grid>
          <Grid item className={classes.right}>
            <SizeInput
              height={height}
              width={width}
              length={length}
              onChange={(e) => changeValue(e)}
            />
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          className={classes.row}
          wrap="nowrap"
          spacing={1}
        >
          <Grid md={3} item className={classes.label}>
            <strong>
              <span className="required" />
              Nhà vận chuyển
            </strong>
          </Grid>
          <Grid item md={5} className={classes.right}>
            <ShipMethodSelection
              shipMethod={shipMethod}
              bl={bl}
              threePls={threePls}
              changeShipMethod={(e) => changeShipMethod(e)}
              isClearable={false}
            />
          </Grid>
          <Grid item md={4}>
            <span>{this.getMissingParamForEDF()}</span>
          </Grid>
        </Grid>

        {shipMethod === "xe_khach" && (
          <Grid container className={classes.row} spacing={1}>
            <Grid item md={3} className={classes.label}>
              <p className={classes.text}>
                <strong>
                  <span className="required" />
                  Nhà xe
                </strong>
              </p>
            </Grid>
            <Grid item md={5} lg={5} className={classes.right}>
              <BusLineSelection
                shipMethod={shipMethod}
                value={bus_line}
                options={bus_line_list}
                onChange={(e) => changeValue({ bus_line: e })}
              />
            </Grid>
          </Grid>
        )}

        {!isGroupBl &&
          (bl.direction === BlDirection.shipping ||
            bl.requestType === RequestType.wh_transfer.toUpperCase()) && (
            <Grid container className={classes.row} spacing={1}>
              <Grid item md={3} className={classes.label}>
                <strong>
                  <span className="required" />
                  Gửi đến
                </strong>
              </Grid>
              <Grid item className={classes.right}>
                <ShipTypeSelection
                  bl={bl}
                  shipType={shipType}
                  shipMethod={shipMethod}
                  changeShipType={this.props.changeShipType}
                />
              </Grid>
            </Grid>
          )}

        {this.canSelectToHub() && (
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            className={classes.row}
            spacing={1}
          >
            <Grid md={3} item className={classes.label}>
              <strong>
                <span className="required" />
                Kho nhận
              </strong>
            </Grid>
            <Grid item md={5} className={classes.right}>
              <ToHubSelection
                bl={bl}
                hubs={hubs}
                toHub={toHub}
                changeHubTo={this.props.changeHubTo}
              />
            </Grid>
          </Grid>
        )}

        {this.canShowToHubAddr() && (
          <Grid
            container
            alignItems="center"
            className={classes.row}
            spacing={1}
          >
            <Grid item md={3} className={classes.label}>
              <strong>Địa chỉ kho</strong>
            </Grid>
            <Grid item style={{ maxWidth: "25rem" }}>
              {toHub.fullAddress}
            </Grid>
          </Grid>
        )}
        {shipType === ShipType.hub_ktv && (
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            className={classes.row}
            spacing={1}
          >
            <Grid md={3} item className={classes.label}>
              <strong>
                <span className="required" />
                KTV nhận
              </strong>
            </Grid>
            <Grid item md={5} className={classes.right}>
              <ToKTVSelection
                toKTV={toKTV}
                allowEdit
                ktvList={allKtvList}
                changeKTVTo={this.props.changeKTVTo}
              />
            </Grid>
          </Grid>
        )}
        {toKTV.id && shipType === ShipType.hub_ktv && (
          <Grid container className={classes.row} spacing={1}>
            <Grid item md={3} className={classes.label}>
              <strong>Địa chỉ KTV</strong>
            </Grid>
            <Grid item md={5}>
              <KTVAddress
                address={alter_address.label}
                clickEditCustomer={(e) =>
                  changeValue({ openUpdateAddrDialog: true })
                }
              />
            </Grid>
          </Grid>
        )}

        {["adidi"].includes(shipMethod) && (
          <ThreePlsExtraOptions
            onChange={(e) => changeValue({ extra: [e] })}
            allow_stock_view={allow_stock_view}
            changeValue={changeValue}
          />
        )}

        {this.manualShipMethod().includes(shipMethod) && (
          <Grid
            container
            className={classes.row}
            alignItems="center"
            spacing={1}
          >
            <Grid item md={3} className={classes.label}>
              <strong>
                {shipMethod === ShipMethod.nv_phongvu
                  ? "Mã nhân viên"
                  : "Mã vận chuyển"}
              </strong>
            </Grid>
            <Grid item md={5} className={classes.right}>
              <PlRefInput
                plRef={plRef}
                shipMethod={shipMethod}
                shipType={shipType}
                toHub={toHub}
                changePlRef={(e) => changeValue({ plRef: e })}
              />
            </Grid>
          </Grid>
        )}
        {shipMethod && this.getThreePlsService(shipMethod).length > 0 && (
          <Grid
            container
            alignItems="center"
            className={classes.row}
            spacing={1}
          >
            <Grid md={3} item className={classes.label}>
              <strong>
                <span className="required" />
                Loại dịch vụ
              </strong>
            </Grid>
            <Grid item md={5} className={classes.right}>
              <DeliveryTypes
                value={service_id}
                options={serviceList}
                onChange={(e) =>
                  changeValue({
                    service_id: e ? e.service_code : "",
                    estimate_fee: e ? e.fee : null,
                  })
                }
              />
            </Grid>
          </Grid>
        )}
        {shipMethod === "ktv" && (
          <Grid
            container
            alignItems="center"
            className={classes.row}
            spacing={1}
          >
            <Grid md={3} item className={classes.label}>
              <span className="required" />
              <strong>KTV giao hàng</strong>
            </Grid>
            <Grid item md={9} className={classes.right}>
              <ShipperExtraSelection
                changeExtraShipper={(e) => changeValue({ shipperExtra: e })}
                shipperExtra={shipperExtra}
                ktvList={ktvList}
              />
            </Grid>
          </Grid>
        )}
        {!bl.must_transfer_debt && (
          <Grid
            container
            alignItems="center"
            className={classes.row}
            spacing={1}
          >
            <Grid item md={3} className={classes.label}>
              <strong>Gán nợ</strong>
            </Grid>
            <DebtTransferOption
              md={9}
              value={is_debit}
              onChange={(e) => changeValue({ is_debit: e })}
            />
          </Grid>
        )}
        {!["adidi", "ktv"].includes(shipMethod) && (
          <Grid
            container
            alignItems="center"
            className={classes.row}
            spacing={1}
          >
            <Grid item md={3} className={classes.label} />
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={allow_stock_view}
                    onChange={(e) =>
                      changeValue({ allow_stock_view: e.target.checked })
                    }
                  />
                }
                label="Cho xem hàng"
              />
            </Grid>
          </Grid>
        )}
        {shipMethod === "ktv" && (
          <Grid
            container
            alignItems="center"
            className={classes.row}
            wrap="nowrap"
            spacing={1}
          >
            <Grid md={3} item className={classes.label}>
              <strong>Khoảng cách từ kho đến khách hàng</strong>
            </Grid>
            <Grid item className={classes.right}>
              {bl.current_distance
                ? `${bl.current_distance} km`
                : "Không tính được"}
            </Grid>
          </Grid>
        )}

        <Grid container alignItems="center" className={classes.row} spacing={1}>
          <Grid md={3} item className={classes.label}>
            <p className={classes.text}>
              <strong>Thời gian giao dự kiến</strong>
            </p>
          </Grid>
          <Grid item md={5}>
            <EstimatedDeliveryTimePicker
              value={edt}
              changeEDT={(e) => changeValue({ edt: e })}
            />
          </Grid>
        </Grid>

        <Grid container alignItems="center" className={classes.row} spacing={1}>
          <Grid md={3} item className={classes.label}>
            <strong>Ghi chú</strong>
          </Grid>
          <Grid item md={5} className={classes.right}>
            <NoteInput changeNote={(e) => changeValue({ note: e })} />
          </Grid>
        </Grid>
        {!isGroupBl && shipMethod === "ktv" && (
          <Grid
            container
            alignItems="center"
            className={classes.row}
            spacing={1}
          >
            <Grid item md={3} className={classes.label} />
            <Grid item className={classes.label}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={vehicleGasCost}
                    onChange={(e) =>
                      changeValue({ vehicle_gas_cost: e.target.checked })
                    }
                  />
                }
                label="Tính phí xăng xe"
              />
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(TransportByAll);
