import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import fetchSuccess from "../../../shared/actions/fetchSuccess";
import { Grid, Paper } from "@material-ui/core";
import BlGroupToAddr from "./blGroupToAddr";
import BlGroupInfo from "./blGroupInfo";
import BlGroupLines from "./blGroupLines";
import BlGroupCreateTransport from "./blGroupCreateTransport";
import GroupAdidiTransport from "./createAdidiTransport";
import Address from "../../../../../models/Address";
import { updateReceiverAddress } from "../../actions";
//styles
const styles = (theme) => ({
  text: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
  },
  paper: {
    padding: "1rem",
    height: "calc(100% - 32px)",
  },
});

class BlGroupDetail extends React.Component {
  render() {
    const {
      selectedBls,
      groupType,
      classes,
      shipMethod,
      updateReceiverAddress,
      currentBl,
    } = this.props;
    return (
      <Grid container className={classes.text} spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Paper className={classes.paper}>
                <BlGroupToAddr
                  address={new Address(currentBl.to_addr)}
                  groupType={groupType}
                  bl={currentBl}
                  onUpdate={updateReceiverAddress}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper className={classes.paper}>
                <BlGroupInfo
                  bl={currentBl}
                  requestIds={selectedBls.map((x) => x.request_id)}
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <BlGroupLines bls={selectedBls} />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            {shipMethod !== "adidi" ? (
              <BlGroupCreateTransport />
            ) : (
              <GroupAdidiTransport />
            )}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  currentBl: state.admin.group.currentBl,
  selectedBls: state.admin.group.selectedBls,
  groupType: state.admin.group.groupType,
  updateAddress: state.admin.group.updateAddress,
  shipMethod: state.admin.group.shipMethod,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSuccess: () => dispatch(fetchSuccess()),
  updateReceiverAddress: (response, request) =>
    dispatch(updateReceiverAddress(response, request)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(BlGroupDetail)
);
