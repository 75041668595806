import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import fetchSuccess from "../../shared/actions/fetchSuccess";
import { Button, Grid } from "@material-ui/core";
import { BlGroupTable } from "./table";
import { BlGroupFilter } from "./filter";
import { DetailDialog } from "./dialog";
import {
  toggleDetailDialog,
  blGroupFilterChange,
  changeShipMethod,
} from "./../actions";
import { openNotiDialog } from "@shared";
import { BlRequestType } from "../../constants";

//styles
const styles = (theme) => ({
  buttonProgress: {
    color: "#FFFFFF",
  },
  input: {
    height: 37,
    border: "1px solid #ccc",
    borderRadius: 4,
    paddingLeft: 8,
    width: "100%",
  },
  title: {
    fontFamily: "Noto Sans, sans-serif",
    fontWeight: 800,
    color: "#797979",
    paddingBottom: 16,
  },
  statusLabel: {
    color: "#fff",
    fontSize: 11,
    fontWeight: 800,
    padding: 4,
    borderRadius: 4,
  },
  text: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
  },
  dialogText: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: 16,
    lineHeight: 2,
    color: "rgba(0, 0, 0, 0.54)",
    width: "31rem",
  },
  blStatusCell: {
    color: "#ffffff",
    textAlign: "left",
    minWidth: "7rem",
  },
  clickable: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  hubCell: {
    lineHeight: "1.2rem",
  },
  flex: {
    flex: 1,
    fontFamily: "sans-serif",
  },
  detailContainer: {
    paddingTop: "1rem",
  },
});

class BlGroupContainer extends React.Component {
  componentDidMount() {
    const { fetchSuccess } = this.props;
    fetchSuccess();
  }

  navigateToDetail = (id) => {
    window.open(`/van-don/${id}`, "_blank");
  };

  renderHubAddr = (address) => {
    if (address.hub.asia_branch.code) {
      return (
        <div>
          <strong>{address.hub.asia_branch.code}</strong>
          <br />
          <div>{address.hub.asia_branch.address}</div>
        </div>
      );
    } else {
      return address.display_name;
    }
  };

  process = (type) => {
    const {
      toggleDetailDialog,
      openNotiDialog,
      selectedBls,
      lastSearchGroupType,
      changeShipMethod,
      settings,
    } = this.props;
    if (
      selectedBls.length > settings.BL_GROUP_LIMIT &&
      lastSearchGroupType !== BlRequestType.wh_transfer
    ) {
      openNotiDialog(
        "failed",
        <div>
          Bạn không thể chọn với số lượng đơn nhiều hơn{" "}
          {settings.BL_GROUP_LIMIT} đơn
        </div>
      );
    } else {
      changeShipMethod(
        lastSearchGroupType === BlRequestType.wh_transfer ? "others" : "adidi"
      );
      toggleDetailDialog(true);
    }
  };

  renderActionButtons = (groupType) => {
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={() => this.process()}
      >
        {groupType === BlRequestType.wh_transfer
          ? "Tạo lộ trình nhanh"
          : "Tạo lộ trình nhanh với Adidi"}
      </Button>
    );
  };

  render() {
    const { classes, selectedBls, settings, lastSearchGroupType } = this.props;
    return (
      <div>
        <Grid container className={classes.text}>
          <Grid item xs={12}>
            <BlGroupFilter />
          </Grid>
          <Grid item xs={12}>
            {selectedBls.length > 1 && (
              <React.Fragment>
                <br />
                {this.renderActionButtons(lastSearchGroupType)}
                {selectedBls.length > settings.BL_GROUP_LIMIT &&
                  lastSearchGroupType !== BlRequestType.wh_transfer && (
                    <div
                      style={{
                        color: "#d42e00",
                        paddingTop: "10px",
                        paddingLeft: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      Chỉ được chọn tối đa {settings.BL_GROUP_LIMIT} đơn
                    </div>
                  )}
              </React.Fragment>
            )}
            {selectedBls.length <= settings.BL_GROUP_LIMIT && <br />}
            <br />
            <BlGroupTable />
          </Grid>
        </Grid>
        {selectedBls.length > 0 && <DetailDialog />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lastSearchGroupType: state.admin.group.lastSearchGroupType,
  settings: state.admin.shared.settings,
  groupType: state.admin.group.groupType,
  selectedBls: state.admin.group.selectedBls,
  detailDialog: state.admin.group.detailDialog,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSuccess: () => dispatch(fetchSuccess()),
  blGroupFilterChange: (obj) => dispatch(blGroupFilterChange(obj)),
  toggleDetailDialog: (state) => dispatch(toggleDetailDialog(state)),
  openNotiDialog: (type, text) => dispatch(openNotiDialog(type, text)),
  changeShipMethod: (shipMethod) => dispatch(changeShipMethod(shipMethod)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(BlGroupContainer)
);
