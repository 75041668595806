import fetchError from "./fetchError";
import { ShipMethod, ShipType } from "../../../../models";
import { createTransportApi } from "..";
import { addBlCustomerAddressApi, getArea } from "@api";

export const addCustomerAddress = (id, code) => async (dispatch, getState) => {
  const response = await addBlCustomerAddressApi(id, code);
  const result = response.data;
  if (response.status === 200) {
    if (result) {
      return {
        error: false,
      };
    } else {
      dispatch(
        fetchError(
          response.status,
          "Có lỗi xảy ra! Vui lòng liên hệ Logistic team để được hỗ trợ!"
        )
      );
    }
  } else {
    dispatch(fetchError(result.code, result.message));
  }
  return response;
};

export const fetchArea =
  (area = "", parent = false) =>
  async (dispatch) => {
    const response = await getArea(area);
    const data = await response.data;
    if (response.status === 200) {
      if (data && !parent) {
        return data.data.area.children;
      } else if (data && parent) {
        return data.data.area;
      } else {
        dispatch(
          fetchError(
            response.status,
            "Có lỗi xảy ra! Vui lòng liên hệ Logistic team để được hỗ trợ!"
          )
        );
      }
    } else {
      dispatch(fetchError(data.code, data.message));
    }
  };

export const createTransport = (data) => async (dispatch, getState) => {
  const currentBl = getState().admin.detail.bl;

  let requestBody = {
    ship_method: data.shipMethod,
    bl_id: data.id,
    type: data.shipType,
    note: data.note.trim(),
    suggestion_id: data.suggestion_id,
    estimate_fee: data.estimate_fee,
    pl_ref: data.plRef,
    extra: data.extra,
    is_debit:
      typeof data.is_debit !== "undefined" ? data.is_debit === "true" : true,
  };
  if (data.weight) {
    requestBody.weight = data.weight.toString();
  }
  if (data.height) {
    requestBody.height = data.height.toString();
  }
  if (data.width) {
    requestBody.width = data.width.toString();
  }
  if (data.length) {
    requestBody.length = data.length.toString();
  }
  if (data.inventory_count) {
    requestBody.inventory_count = Number(data.inventory_count);
  }
  if (data.edt) {
    requestBody.estimated_delivery_time = data.edt;
  }
  if (data.areaCodeSelectValue && data.areaCodeSelectValue.value) {
    requestBody.manual_group = data.areaCodeSelectValue.value;
  }
  requestBody.support_ktvs = data.shipperExtra;
  if (data.shipMethod === ShipMethod.ktv) {
    requestBody.shipper = data.shipper;
    requestBody.vehicle_gas_cost = data.vehicle_gas_cost;
  }
  if (
    [
      ShipType.hub_hub,
      ShipType.ktv_hub,
      ShipType.customer_customer,
      ShipType.customer_hub,
    ].includes(data.shipType) ||
    ([ShipType.hub_customer].includes(data.shipType) && currentBl.isWhTransfer)
  ) {
    requestBody.to_hub_id = data.toHub.id;
  }
  if (ShipType.hub_ktv === data.shipType) {
    requestBody.to_shipper_id = data.toKTV.id;
    if (data.alter_address && data.alter_address.hasChange) {
      const useGGMap = data.alter_address.useGoogleMap;
      requestBody.alter_address = {
        google_address: useGGMap ? data.alter_address.googleResponse : "",
        name: data.alter_address.name,
        street: data.alter_address.street,
        phone: data.alter_address.phone,
        area_code: useGGMap ? "" : data.alter_address.area_code,
        update:
          data.alter_address.update && data.alter_address.type === "ktv"
            ? 1
            : 0,
      };
      if (useGGMap) {
        delete requestBody.alter_address.area_code;
      } else {
        delete requestBody.alter_address.google_address;
        delete requestBody.alter_address.place_id;
      }
    }
  }
  if (data.shipMethod !== "ktv" && data.service_id) {
    requestBody.service_id = data.service_id;
  }
  // if ([ShipMethod.vtpost, ShipMethod.adidi].includes(data.shipMethod)) {
  requestBody.allow_stock_view = data.allow_stock_view;
  // }
  if (data.shipMethod === ShipMethod.xe_khach) {
    requestBody.bus_line = data.bus_line;
  }
  const response = await createTransportApi(requestBody);
  const result = response.data;
  if (response.status === 200) {
    if (result) {
      if (window.location.href.includes("van-don")) {
        window.location.reload();
      } else {
        dispatch(createTransportSuccess(data.id));
      }
    } else {
      dispatch(
        fetchError(
          response.status,
          "Có lỗi xảy ra! Vui lòng liên hệ Logistic team để được hỗ trợ!"
        )
      );
    }
  } else {
    if (result.errors) {
      return {
        error: true,
        msg: result.errors,
      };
    } else {
      dispatch(fetchError(result.code, result.message));
    }
  }
  return result;
};

const createTransportSuccess = (id) => ({
  type: "CREATE_TRANSPORT_SUCCESS",
  payload: {
    id: id,
  },
});
