import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Paper, Button } from "@material-ui/core";
import { ShipperFilter } from "../../../bl_list/components";
import DatePicker from "react-date-picker";
import {
  changeFilter,
  onSearch,
  setDefaultUser,
  switchRenderLocation,
} from "../actions";

//styles
const styles = (theme) => ({
  "@global": {
    ".searchBtn": {
      background: "cornflowerblue",
      color: "white",
    },
    ".multiselect .Select-multi-value-wrapper": {
      maxHeight: "5rem",
      overflowY: "scroll",
      display: "block",
    },
    ".datePicker": {
      borderRadius: 5,
      width: "100%",
      height: 36,
    },
    ".react-date-picker .react-date-picker__wrapper input[name='year']": {
      flex: 1,
    },
    ".react-date-picker__wrapper": {
      borderTop: "thin solid #80808054",
      borderRight: "none",
      borderBottom: "thin solid #80808054",
      borderLeft: "none",
      padding: "0.3rem 0.4rem",
    },
    ".rowStretch": {
      display: "flex",
      alignItems: "stretch",
      height: 38,
    },
    ".rowStretch > div.dateRangeLabel": {
      flex: 1,
    },
    ".dateRangeLabel": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "#324b7814",
      borderTop: "thin solid #80808054",
      borderBottom: "thin solid #80808054",
    },
    ".dateRangeFrom": {
      borderRadius: "5px 0 0 5px",
      borderLeft: "thin solid #80808054",
    },
    ".dateRangeTo > .react-date-picker__wrapper": {
      borderRadius: "0 5px 5px 0",
      borderRight: "thin solid #80808054",
    },
  },
});

class ShipperDailyReportFilter extends React.Component {
  constructor() {
    super();
    this.state = {
      error: 0,
      done: false,
    };
  }

  setFilter = (obj) => {
    const { filter, changeFilter } = this.props;
    changeFilter({
      ...filter,
      ...obj,
    });
  };

  search = async () => {
    const { filter, onSearch } = this.props;
    onSearch(filter);
  };

  validate = async () => {
    const { filter, switchRenderLocation } = this.props;
    let oldError = 0;
    if (!filter.shipper_id) {
      oldError = oldError + 1;
    }
    if (filter.to_date - filter.from_date > 30 * 86400000) {
      oldError = oldError + 2;
    }
    const flag = filter.to_date - filter.from_date === 0 && oldError === 0;
    switchRenderLocation(flag);
    await this.setState({ error: oldError });
    if (this.state.error === 0) {
      this.search();
    }
  };

  handleStartDateChange = (e) => {
    const { filter } = this.props;
    this.setFilter({
      from_date: e,
      to_date: e > filter.to_date ? e : filter.to_date,
    });
  };

  static getDerivedStateFromProps = (props, state) => {
    if (state.done === false) {
      if (props.allKtvList.length === 1) {
        props.changeFilter({
          ...props.filter,
          shipper_id: props.allKtvList[0],
        });
        return {
          done: true,
        };
      } else {
        return null;
      }
    }
    return null;
  };

  render() {
    const { filter, allKtvList } = this.props;
    const { error } = this.state;
    return (
      <React.Fragment>
        <Paper
          className="filterContainer"
          style={{ padding: "2rem 1rem 1rem 2rem", boxShadow: "none" }}
        >
          <Grid container alignItems="flex-end" spacing={1}>
            <Grid item xs={12}>
              <span className="title">BÁO CÁO PHÍ XĂNG XE THEO NGÀY</span>
            </Grid>
          </Grid>

          <Grid container alignItems="baseline" spacing={1}>
            <Grid item xs={12} md={3}>
              <span className="label">Nhân viên</span>
              <div className="multiselect">
                <ShipperFilter
                  value={filter.shipper_id}
                  ktvList={allKtvList}
                  multi={false}
                  simpleValue={false}
                  placeholder="Chọn nhân viên giao hàng..."
                  onChange={(v) => {
                    this.setFilter({ shipper_id: v });
                  }}
                />
              </div>
              {error % 2 === 1 && (
                <span className="errorMsg">
                  Bạn chưa chọn nhân viên giao hàng
                </span>
              )}
            </Grid>
            <Grid item xs={12} md={3}>
              <span className="label ">Thời gian báo cáo</span>
              <div className="rowStretch">
                <div className="dateRangeLabel dateRangeFrom">Từ </div>
                <DatePicker
                  value={filter.from_date}
                  showLeadingZeros
                  onChange={this.handleStartDateChange}
                  locale="vi-VN"
                  calendarIcon={null}
                  clearIcon={null}
                />
                <div className="dateRangeLabel">đến</div>
                <DatePicker
                  className="dateRangeTo"
                  value={filter.to_date}
                  minDate={filter.from_date}
                  showLeadingZeros
                  onChange={(e) => this.setFilter({ to_date: e })}
                  locale="vi-VN"
                  calendarIcon={null}
                  clearIcon={null}
                />
              </div>
              {error >= 2 && (
                <span className="errorMsg">
                  Bạn cần chọn ngày bắt đầu và ngày kết thúc không vượt quá 30
                  ngày
                </span>
              )}
            </Grid>
            <Grid item xs={12} md={3}>
              <span className="label " />
              {/* dummy span to keep filter horizontal aligned */}
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.validate}
                >
                  Tìm kiếm
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  filter: state.admin.ShipperDailyReport.filter,
  user: state.admin.shared.user,
  allKtvList: state.admin.shared.allKtvList,
});

const mapDispatchToProps = (dispatch) => ({
  changeFilter: (filter) => dispatch(changeFilter(filter)),
  setDefaultUser: (userId) => dispatch(setDefaultUser(userId)),
  onSearch: () => dispatch(onSearch()),
  switchRenderLocation: (flag) => dispatch(switchRenderLocation(flag)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ShipperDailyReportFilter)
);
