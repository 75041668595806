import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import CreateTransportContainer from "./CreateTransportContainer";
import { fetchBlDetail, cancelBl, fetch3PlsList, printBill } from "../actions";
import { ConfirmBlCancelDialog } from "../components";
import {
  BlStatus,
  ToAddressInfo,
  BlInfo,
  BlLines,
  TransportInfo,
} from "../components";
import { ShipMethod } from "@constants";
import { RequestType, TransportState } from "../../../../models";
import { BlHistory, BlRating } from "../components/bl";
import DeliveryMap from "../components/transport/DeliveryMap";

//styles
const styles = (theme) => ({
  paper: {
    padding: "8px 16px",
    marginBottom: 16,
    borderRadius: 4,
    color: "#797979",
    height: "calc(100% - 32px)",
  },
  customWidthPaper: {
    padding: "8px 0px 8px 16px",
    borderRadius: 4,
    color: "#797979",
    height: "100%",
  },
  title: {
    fontFamily: "Noto Sans, sans-serif",
    fontWeight: 800,
    color: "#797979",
    paddingBottom: 16,
  },
  heading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  button: {
    textTransform: "none",
    marginBottom: 16,
  },
});

class BlDetailContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  toggleDialog = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  componentDidMount() {
    const { match, location, fetchBlDetail } = this.props;
    const search = location.search ? location.search.split("?")[1] : "";
    const queryParams = new URLSearchParams(search);
    const type = queryParams.get("type");
    fetchBlDetail(match.params.id, type ? { type } : null); // fetch thông tin vận đơn
  }

  /* Hủy vận đơn:
  - Case 1:
    +Nếu trạng thái vận đơn là: chờ gán vận chuyển, chờ lấy hàng, đang giao
    +Nếu user là admin, super_admin
  - Case 2:
    +Nếu trạng thái vận đơn là: chờ gán vận chuyển, chờ lấy hàng, đang giao và đang nhận yêu cầu hủy từ OM
    +Nếu user là mod, admin, super_admin
  */
  canCancelBl = () => {
    const { bl, user } = this.props;
    if (
      user.canConfirmCancel(
        bl.currentGoodsAddress,
        bl.lastTransport.fromAddress.objId,
        bl.fromAddr.objId
      ) &&
      bl.canConfirmCancel
    ) {
      return true;
    }
    return false;
  };

  renderRightSite = () => {
    const { groupedBls, bl } = this.props;
    const { current_transport } = bl;
    return [TransportState.shipping, TransportState.confirmed].includes(
      current_transport.state
    ) && current_transport.ship_method === ShipMethod.adidi ? (
      <DeliveryMap bls={groupedBls} currentBl={bl} />
    ) : (
      <CreateTransportContainer />
    );
  };

  render() {
    const { open } = this.state;
    const { bl, printBill, classes } = this.props;
    const cod = {
      cod: bl.formattedCOD,
      paidCOD: bl.formattedPaidCOD,
      grandTotal: bl.formattedGrandTotal,
      prePaid: bl.formattedPrePaid,
    };
    if (bl && bl.id) {
      // FIXME: use react way
      document.querySelector("title").innerText = `${bl.id} - Logistics Admin`;
      return (
        <div>
          <div className={classes.heading}>
            <Typography variant="h5" className={classes.title}>
              CHI TIẾT VẬN ĐƠN{" "}
              {bl.requestType === RequestType.wh_transfer.toUpperCase()
                ? "CHUYỂN KHO"
                : bl.requestType === RequestType.promotion.toUpperCase()
                ? "KHUYẾN MÃI"
                : "KHÁCH HÀNG"}
            </Typography>

            {bl.ableToCancel && (
              <div>
                <Button
                  variant="contained"
                  style={{ color: "#fff", backgroundColor: "#ff0000" }}
                  className={classes.button}
                  onClick={this.toggleDialog}
                >
                  Hủy vận đơn
                </Button>
                <ConfirmBlCancelDialog
                  id={bl.id}
                  open={open}
                  toggleDialog={this.toggleDialog}
                  cancelBl={this.props.cancelBl}
                />
              </div>
            )}
          </div>

          <div>
            <BlStatus bl={bl} />
          </div>

          <div>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Paper elevation={0} className={classes.paper}>
                  <ToAddressInfo
                    address={bl.toAddr}
                    requestType={bl.requestType}
                    bl={bl}
                  />
                </Paper>
              </Grid>
              <Grid item md={6} xs={12}>
                <Paper elevation={0} className={classes.paper}>
                  <BlInfo bl={bl} />
                </Paper>
              </Grid>
            </Grid>
          </div>

          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Paper elevation={0} className={classes.paper}>
                <BlLines
                  blLines={bl.blLines}
                  cod={cod}
                  requestType={bl.requestType}
                  bl={bl}
                />
              </Paper>
            </Grid>
            <Grid item md={6} xs={12}>
              <Paper elevation={0} className={classes.paper}>
                <BlHistory />
              </Paper>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={bl.ableToCreateTransport ? 5 : 6} xs={12}>
              <Paper
                elevation={0}
                className={classes.paper}
                style={{ height: "100%" }}
              >
                <TransportInfo bl={bl} printBill={printBill} />
              </Paper>
            </Grid>
            <Grid item md={bl.ableToCreateTransport ? 7 : 6} xs={12}>
              <Paper elevation={0} className={classes.customWidthPaper}>
                {bl.canShowRating ? (
                  <BlRating bl={bl} />
                ) : (
                  this.renderRightSite()
                )}
              </Paper>
            </Grid>
          </Grid>
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  bl: state.admin.detail.bl,
  cancelState: state.admin.detail.cancelState,
  user: state.admin.shared.user,
  groupedBls: state.admin.detail.groupedBls,
});

const mapDispatchToProps = (dispatch) => ({
  fetchBlDetail: (id, params) => dispatch(fetchBlDetail(id, params)),
  fetch3PlsList: (bl_id) => dispatch(fetch3PlsList(bl_id)),
  printBill: (ship_method, pl_ref) => dispatch(printBill(ship_method, pl_ref)),
  cancelBl: (id, reason) => dispatch(cancelBl(id, reason)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(BlDetailContainer)
);
