import Transport, { ShipType, ShipMethod, TransportState } from "./Transport";
import Address from "./Address";
import { formatNumber } from "../app/admin/utils/misc";
import { WMS_STATUS } from "../app/admin/constants";

export const RequestType = {
  asia_order: "asia_order",
  sale_order: "sale_order",
  wh_transfer: "wh_transfer",
  promotion: "promotion",
};

/**
 * Cho biết hướng vận đơn đang giao hàng hay hoàn hàng
 */
export const BlDirection = {
  shipping: "shipping",
  returning: "returning",
};

/*  Trạng thái lấy hàng từ WH
*     init: khởi tạo
      wms_to_lo_request: đã gửi yêu cầu lấy hàng sang WH
*/
export const BlStockStatus = {
  init: "init",
  wmsToLoRequest: "wms_to_lo_request",
};
/**
  Trạng thái của Bl:
    init: khởi tạo
    delivering: đang trên đường
    delivered: đã giao thành công
    returning: đang hoàn hàng
    returned: đã hoàn hàng
    partial_returning: đang hoàn 1 phần
    partial_returned: đã hoàn 1 phần
 */
export const BlState = {
  init: "init",
  delivering: "delivering",
  returning: "returning",
  partial_returning: "partial_returning",
  returned: "returned",
  partial_returned: "partial_returned",
  cancelled: "cancelled",
  delivered: "delivered",
};

/**
  Xác định đối tượng cầm hàng
 */
export const Holder = {
  sender: "sender", // Người gửi cầm hàng
  hub: "hub", // Hàng đang trong Hub
  ktv: "ktv", // KTV đang cầm hàng
  receiver: "receiver", // Người nhận đang cầm hàng
  ghn: "ghn", // Giao hàng nhanh đang cầm hàng
  vnpost: "vnpost", // VN Post đang cầm hàng
  vtpost: "vtpost", // Viettel Post đang cầm hàng
  xe_khach: "xe_khach", // Xe khách
  xe_tai: "xe_tai", // Xe tải
  ghtk: "ghtk", // Giao hàng tiết kiệm
  ahamove: "ahamove", // Ahavmove
  nhat_tin: "nhat_tin", // Nhất tín
  tan_son_nhat: "tan_son_nhat", // Tân sơn nhất
  vtpost_manual: "vtpost_manual", // Viettel Post (manual)
  kerry_express_manual: "kerry_express_manual", // Kerry express
  nv_phongvu: "nv_phongvu", // Nhân viên phong vũ
};

export default class Bl {
  constructor(bl, index = 0) {
    Object.keys(bl).forEach((key) => (this[key] = bl[key]));
    this.index = index;
    this.document_id = bl.document_id;
    this.saleOrderId = bl.sale_order_id;
    this.state = bl.state;
    this.fromAddr = bl.from_addr ? new Address(bl.from_addr) : bl.from_addr;
    this.toAddr = bl.to_addr ? new Address(bl.to_addr) : bl.to_addr;
    this.paidCOD = bl.paid_cod;
    this.requestCreatedAt = bl.request_created_at;
    this.holderType = bl.holder_type;
    this.lastTransport = bl.last_transport
      ? new Transport(bl.last_transport)
      : bl.last_transport;
    this.currentTransport = bl.current_transport
      ? new Transport(bl.current_transport)
      : bl.current_transport;
    this.requestType = bl.request_type;
    this.blLines = bl.bl_lines;
    this.ableToCancel = bl.able_to_cancel;
    this.ableToCreateTransport = bl.able_to_create_transport;
    this.ableToConfirmReturn = bl.able_to_confirm_return;
    this.transports = bl.transports;
    this.totalDiscount = bl.total_discount;
    this.shipDate = bl.ship_date;
    this.shipDate = bl.ship_date;
    this.grandTotal = bl.grand_total;
    this.prePaid = bl.prepaid;
    this.follower = this.formattedFollower(bl.follower);
    this.stockStatus = bl.stock_status;
    this.orderCode = bl.order_code;
    this.exportSource = bl.export_source;
    this.invoiceId = bl.invoice_id;
    this.invoiceAsia = bl.is_invoice_asia;
    this.transitHub = bl.transit_hub
      ? new Address(bl.transit_hub)
      : bl.transit_hub;
    this.wmsStatus = bl.wms_status;
    this.inventory_count = bl.package_info
      ? bl.package_info.inventory_count
      : "";
    this.weight = bl.package_info ? bl.package_info.weight : "";
    this.height = bl.package_info ? bl.package_info.height : "";
    this.width = bl.package_info ? bl.package_info.width : "";
    this.length = bl.package_info ? bl.package_info.length : "";
    this.package_info = bl.package_info ? bl.package_info : {};
    this.bl_delivery_types = bl.bl_delivery_types ? bl.bl_delivery_types : null;
    this.packed_at = bl.packed_at ? bl.packed_at : "";
    this.rating = bl.rating || null;
    this.rating_time = bl.rating_time || null;
    this.install = bl.install || null;
    this.last_om_cancel_request = bl.last_om_cancel_request;
  }

  /**
   * Quick filter cho vận đơn, phục vụ hiển thị trạng thái cho user. Có các filters sau:
   *  + waiting_for_confirm: Chờ gán vận chuyển
   *  + waiting_for_pick: Chờ lấy hàng
   *  + on_delivering: Đang vận chuyển
   *  + delivered: Đã giao hàng
   *  + on_returning: Đang hoàn hàng
   *  + on_partial_returning: Đang hoàn hàng một phần
   *  + cancel_shipping_transport: Đang hủy lộ trình
   *  + returned: Đã hoàn hàng
   *  + partial_returned: Đã hoàn hàng một phần
   *  + cancelled: Hủy
   *  + temp: Chờ xác nhận yêu cầu
   *  + temp1: Chờ xác nhận giao hàng
   * @returns {string}
   */
  formattedFollower = (follower) => {
    if (follower) {
      if (follower.id || follower.value) {
        const asiaId = follower?.asia_id?.toString().padStart(4, 0);

        return {
          value: follower.id || follower.value,
          label:
            [asiaId, follower?.name, follower?.phone]
              .filter(Boolean)
              .join(" - ") || follower?.email,
          areaCode: follower.area_code,
          fullAddress: follower.full_address || follower.fullAddress,
          phone: follower.phone,
          status: follower.status,
        };
      }
    }

    return null;
  };
  get isRetry() {
    let lastState = this.currentTransport ? this.currentTransport.state : null;
    return lastState === "retry";
  }
  /* Nhớ sửa func getStatus trong BlUtils*/
  get status() {
    const trState = this.lastTransport.state;
    switch (this.state) {
      case BlState.init:
        if (trState && trState === TransportState.confirmed) {
          return "waiting_for_pick";
        }
        return "waiting_for_confirm";

      case BlState.delivering:
        if (
          trState &&
          [TransportState.shipping, TransportState.retry].includes(trState)
        ) {
          return "on_delivering";
        } else if (TransportState.returning === trState) {
          return "cancel_shipping_transport";
        } else {
          if ([Holder.hub, Holder.sender].includes(this.holderType)) {
            if (trState === TransportState.confirmed) {
              return "waiting_for_pick";
            }
            return "waiting_for_confirm";
          }

          return "on_delivering";
        }

      case BlState.returning:
        if (
          trState &&
          ([
            TransportState.returning,
            TransportState.returned,
            TransportState.shipping,
            TransportState.retry,
            TransportState.ok,
          ].includes(trState) ||
            (TransportState.confirmed === trState &&
              this.holderType === Holder.hub))
        ) {
          return "on_returning";
        }
        break;

      case BlState.partial_returning:
        if (
          trState &&
          ([
            TransportState.returning,
            TransportState.returned,
            TransportState.shipping,
            TransportState.retry,
            TransportState.ok,
            TransportState.partial_ok,
          ].includes(trState) ||
            (TransportState.confirmed === trState &&
              [Holder.hub, Holder.ktv].includes(this.holderType)))
        ) {
          return "on_partial_returning";
        }
        break;

      case BlState.returned:
        if (
          trState &&
          [TransportState.ok, TransportState.returned].includes(trState)
        ) {
          return "returned";
        }
        break;

      case BlState.partial_returned:
        if (
          trState &&
          [TransportState.ok, TransportState.returned].includes(trState)
        ) {
          return "partial_returned";
        }
        break;

      case BlState.cancelled:
        return "cancelled";

      case BlState.delivered:
        if (trState && trState === TransportState.ok) {
          return "delivered";
        }
        break;

      default:
        return "";
    }
  }

  get direction() {
    if (
      [
        BlState.partial_returning,
        BlState.returning,
        BlState.partial_returned,
        BlState.returned,
      ].includes(this.state)
    ) {
      return BlDirection.returning;
    }

    return BlDirection.shipping;
  }

  /**
   * Kiểm tra địa chỉ giao hàng nội thành hay ngoại thành bằng cách so sánh 2 ký tự đầu của mã vùng
   *
   * @readonly
   * @memberof Bl
   * @returns {boolean}
   */
  get isInsideProvince() {
    if (this.fromAddr.areaCode !== null && this.toAddr.areaCode !== null) {
      return (
        this.fromAddr.areaCode.slice(0, 2) === this.toAddr.areaCode.slice(0, 2)
      );
    }
    return false;
  }

  /**
   * Cho biết lộ trình hiện tại của vận đơn có in nhãn đc không
   * @returns {boolean}
   */
  get canExport() {
    if (this.lastTransport.id) {
      if (
        ![
          BlState.partial_returning,
          BlState.returning,
          BlState.cancelled,
          BlState.returned,
          BlState.partial_returned,
          BlState.delivered,
        ].includes(this.state)
      ) {
        return true;
      } else if (
        [BlState.returning, BlState.partial_returning].includes(this.state) &&
        [TransportState.shipping, TransportState.retry].includes(
          this.lastTransport.state
        )
      ) {
        return true;
      }
    }

    return false;
  }

  /**
   * Cho biết trạng thái hiện tại của hàng đang ở đâu
   *  - Bl đang ở hub => hàng ở hub
   *  - Bl vận chuyển thành công => hàng ở điểm đến
   *  - BL đang vận chuyển => null
   * @returns {Object|null}
   */
  get currentGoodsAddress() {
    if (this.state === BlState.init) {
      return this.fromAddr;
    } else if (
      [BlState.returning, BlState.partial_returning].includes(this.state) &&
      this.lastTransport.state === TransportState.returned
    ) {
      return this.lastTransport.fromAddress;
    } else if (
      [BlState.returned, BlState.partial_returned].includes(this.state)
    ) {
      return this.fromAddr;
    } else if (
      BlState.delivering === this.state &&
      TransportState.returned === this.lastTransport.state
    ) {
      return this.lastTransport.fromAddress; //trường hợp hoàn hàng do hủy lộ trình đang giao
    } else if (
      [
        TransportState.returned,
        TransportState.ok,
        TransportState.partial_ok,
      ].includes(this.lastTransport.state)
    ) {
      return this.lastTransport.toAddress;
    } else if (
      [TransportState.confirmed, TransportState.cancelled].includes(
        this.lastTransport.state
      )
    ) {
      return this.lastTransport.fromAddress;
    }
    return null;
  }

  /**
   * Kiểm tra quyền xác nhận pickup hàng trên trang quản lý vận đơn
   *  -Transport vận chuyển bởi 3pls
   * @returns {boolean}
   */
  get canConfirmPicked() {
    if (
      this.lastTransport.state === TransportState.confirmed &&
      [
        BlState.init,
        BlState.delivering,
        BlState.returning,
        BlState.partial_returning,
      ].includes(this.state) &&
      this.lastTransport.shipMethod !== ShipMethod.ktv
    ) {
      return true;
    }
    return false;
  }

  /**
   * Kiểm tra quyền xác nhận vận chuyển thành công với transport đang giao trên trang quản lý vận đơn
   *  - Transport giao bởi 3pls hướng giao hàng
   *  - Transport do khách đến nhận trực tiếp
   * @returns {boolean}
   */
  get canConfirmOK() {
    if (
      [TransportState.shipping, TransportState.retry].includes(
        this.lastTransport.state
      ) &&
      BlState.delivering === this.state &&
      this.lastTransport.shipMethod !== ShipMethod.ktv &&
      ![ShipType.hub_ktv, ShipType.hub_hub, ShipType.customer_hub].includes(
        this.lastTransport.type
      )
    ) {
      return true;
    } else if (
      this.lastTransport.shipMethod === ShipMethod.direct &&
      [BlState.init, BlState.delivering].includes(this.state)
    ) {
      return true;
    }

    return false;
  }

  /**
   * Kiểm tra quyền xác nhận nhập kho thành công trên trang quản lý vận đơn đối với transport đang giao có
   *  - Hướng giao hàng hub_hub
   *  - Hoàn hàng
   * - Nếu là lộ trình hoàn về tới kho xuất (chỉ với vận đơn KH)
   * @returns {boolean}
   */
  get canConfirmImportOK() {
    /*Ko được xác nhận nhập kho thành công đối với vận đơn CKNB giao tới KHO NHẬN */
    if (
      this.isWhTransfer &&
      [ShipType.customer_customer, ShipType.hub_customer].includes(
        this.lastTransport.type
      )
    )
      return false;
    if (
      this.fromAddr.objId === this.lastTransport.toAddress.objId &&
      [BlState.returning, BlState.partial_returning].includes(this.state) &&
      this.lastTransport.state === TransportState.shipping
    ) {
      return false;
    }
    if (
      [TransportState.shipping, TransportState.retry].includes(
        this.lastTransport.state
      ) &&
      ([BlState.returning, BlState.partial_returning].includes(this.state) ||
        (BlState.delivering === this.state &&
          [ShipType.hub_hub, ShipType.customer_hub].includes(
            this.lastTransport.type
          )))
    ) {
      return true;
    }

    // trường hợp hoàn hàng với vận đơn chuyển kho
    if (
      [BlState.returning, BlState.partial_returning].includes(this.state) &&
      this.requestType === RequestType.wh_transfer.toUpperCase() &&
      this.lastTransport.state === TransportState.returning
    ) {
      return true;
    }
    return false;
  }

  /**
   * Kiểm tra quyền xác nhận nhập kho với lộ trình đang giao thì bị hủy và hoàn hàng về kho
   *  - Transport đang hoàn hàng
   *  - Vận đơn đang giao
   * @returns {boolean}
   */
  get canConfirmImportForShippingCancel() {
    if (
      BlState.delivering === this.state &&
      TransportState.returning === this.currentTransport.state
    ) {
      return true;
    }
    return false;
  }

  /**
   * Kiểm tra quyền xác nhận hủy lộ trình trên trang quản lý vận đơn
   *  - Transport chưa được giao|hoàn hàng thành công
   *  - Vận đơn chưa hoàn thành(giao thành công|hoàn hàng thành công)
   * Không cho phép hủy lộ trình với trường hợp:
   *  - Vận đơn giao hàng từ KHO, đã lấy hàng nhưng chưa xác nhận lấy hàng
   * @returns {boolean}
   */
  get canConfirmTransportCancel() {
    if (
      this.state === BlState.init &&
      this.lastTransport.state &&
      !this.lastTransport.isDone
    ) {
      return true;
    } else if (
      [
        BlState.delivering,
        BlState.partial_returning,
        BlState.returning,
      ].includes(this.state) &&
      [Holder.hub, Holder.sender].includes(this.holderType) &&
      !this.lastTransport.isDone
    ) {
      return true;
    } else if (
      this.currentTransport.state === TransportState.confirmed &&
      BlState.delivering === this.state
    ) {
      return true;
    }

    return false;
  }

  /**
   * Kiểm tra quyền xác nhận hoàn hàng trên trang quản lý vận đơn
   *  Đơn KH:
   *    - Transport do 3pl vận chuyển hướng giao hàng
   *    - Transport đang hoàn hàng
   *    - Đơn qua WMS: nếu có yêu cầu hủy từ OM mới cho phép hoàn, ngược lại ko
   *  Đơn Chuyển kho nội bộ: ko cho phép
   * @returns {boolean}
   */
  get canConfirmReturn() {
    if (this.isWhTransfer) {
      return false;
    }

    if (
      [TransportState.returning, TransportState.partial_returning].includes(
        this.lastTransport.state
      ) &&
      this.lastTransport.fromAddress.objId === this.fromAddr.objId
    ) {
      return false;
    } else if (
      this.state === BlState.delivering &&
      this.lastTransport.shipMethod !== ShipMethod.ktv &&
      this.lastTransport.state === TransportState.shipping &&
      (this.requestType.toLowerCase() === "sale_order"
        ? ["partial", "full"].includes(this.last_om_cancel_request.action)
        : true)
    ) {
      return true;
    } else if (
      [BlState.returning, BlState.partial_returning].includes(this.state) &&
      TransportState.returning === this.lastTransport.state
    ) {
      return true;
    }
    return false;
  }

  /**
   * Kiểm tra quyền xác nhận hủy vận đơn trên trang quản lý vận đơn
   *  - Vận đơn chưa hoàn thành
   * - CKNB: ko đc hủy vận đơn sau khi gửi yêu cầu lấy hàng
   * @returns {boolean}
   */
  get canConfirmCancel() {
    if (
      [
        BlState.returning,
        BlState.delivered,
        BlState.partial_returning,
        BlState.partial_returned,
        BlState.returned,
        BlState.cancelled,
      ].includes(this.state)
    ) {
      return false;
    }
    if (this.isWhTransfer) {
      if (this.stockStatus === "init" && this.state === "init") {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  /**
   * Kiểm tra quyền xác nhận hủy lộ trình đang giao trên trang quản lý vận đơn
   *  - Transport đang được giao
   *  - Lộ trình từ hub → hub với phương thức vận chuyển là 3PL
   *  - Lộ trình từ hub → KTV  với phương thức vận chuyển là 3PL
   *  - Lộ trình từ hub → KH với phương thức vận chuyển là 3PL
   *  - Lộ trình từ hub → KH với phương thức vận chuyển là KTV.
   * @returns {boolean}
   */
  get canConfirmShippingTransportCancel() {
    if (
      (BlState.delivering === this.state &&
        TransportState.shipping === this.lastTransport.state &&
        (([
          ShipType.hub_hub,
          ShipType.hub_ktv,
          ShipType.hub_customer,
          ShipType.customer_customer,
          ShipType.customer_hub,
          ShipType.hub_customer,
        ].includes(this.lastTransport.type) &&
          ![ShipMethod.direct, ShipMethod.ktv].includes(
            this.lastTransport.shipMethod
          )) ||
          ([
            ShipType.hub_customer,
            ShipType.customer_customer,
            ShipType.customer_hub,
            ShipType.hub_hub,
          ].includes(this.lastTransport.type) &&
            ShipMethod.ktv === this.lastTransport.shipMethod))) ||
      (TransportState.shipping === this.currentTransport.state &&
        ShipType.hub_customer === this.currentTransport.type &&
        ShipMethod.ktv === this.currentTransport.shipMethod)
    ) {
      return true;
    }
    return false;
  }

  get canShipByKTV() {
    return ![BlState.returning, BlState.partial_returning].includes(this.state);
  }

  get formattedCOD() {
    return formatNumber(this.cod);
  }

  get formattedPaidCOD() {
    return formatNumber(this.paidCOD);
  }

  get formattedGrandTotal() {
    return formatNumber(this.grandTotal);
  }

  get formattedPrePaid() {
    return formatNumber(this.prePaid);
  }

  get cantAssignTransportFollower() {
    return [
      BlState.returned,
      BlState.partial_returned,
      BlState.delivered,
      BlState.cancelled,
    ].includes(this.status);
  }

  get isWhTransfer() {
    return this.requestType.toLowerCase() === RequestType.wh_transfer;
  }

  get hasFirstDoneTransport() {
    for (let trans of this.transports) {
      let newTrans = new Transport(trans);
      if (
        [
          TransportState.ok,
          TransportState.partial_ok,
          TransportState.returned,
        ].includes(newTrans.state)
      ) {
        return true;
      }
    }
    return false;
  }
  // Hiển thị mã đơn hàng OM order code
  // với order cũ: hiển thị sale_order_id
  // với order mới: hiển thị order_code
  get blOrderId() {
    return this.orderCode;
  }

  // Hiện đánh giá nếu: đơn KH + giao bởi ktv + trạng thái (giao thành công, đã hoàn toàn phần, đã hoàng 1 phần, đang hoàn toàn phần, đang hoàn 1 phần)
  get canShowRating() {
    const allowedState = [
      BlState.delivered,
      BlState.returned,
      BlState.returning,
      BlState.partial_returned,
      BlState.partial_returning,
    ];
    const shipMethod = this.lastTransport.shipMethod;
    const requestType = this.requestType.toLowerCase();
    return (
      !this.ableToCreateTransport &&
      allowedState.includes(this.state) &&
      shipMethod === "ktv" &&
      requestType !== RequestType.wh_transfer
    );
  }

  //Kiểm tra đơn có thể được giao bởi ADiDi hay không
  canCreateAdidiTransport(allowedHubs) {
    const allowedState = this.status === "waiting_for_confirm";
    const fromAddress = this.transitHub.objId;
    const allowedHub =
      allowedHubs === "all" || (allowedHubs || []).includes(fromAddress);
    const allowedRequestType =
      this.requestType.toLowerCase() !== RequestType.asia_order;
    const allowdPackageState = this.wmsStatus === WMS_STATUS.pack;
    return (
      allowedState && allowedHub && allowedRequestType && allowdPackageState
    );
  }
}
