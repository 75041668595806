export const flatToHierarchy = (list, defaultSelections) => {
  var roots = []; // things without parent

  // make them accessible by wms_id on this map
  var all = {};

  list.forEach(function (item) {
    all[item.wmsId] = formatItem(item, defaultSelections);
  });
  // connect childrens to its parent, and split roots apart
  Object.keys(all).forEach(function (wms_id) {
    var item = all[wms_id];
    if (item.parentWmsId === null) {
      roots.push(item);
    } else if (item.parentWmsId in all) {
      var p = all[item.parentWmsId];
      if (!("children" in p)) {
        p.children = [];
      }
      p.children.push(item);
    }
  });

  // done!
  return assignObjectPaths(roots);
};

export const assignObjectPaths = (obj, stack) => {
  obj.forEach((el, id) => {
    if (stack) el.path = [...stack];
    el.path.push(id);
    if (el.children) assignObjectPaths(el.children, el.path);
  });
  return obj;
};

const formatItem = (item, defaultSelections) => ({
  label: `${item.code} - ${item.name}`,
  value: item.code,
  wmsId: item.wmsId,
  parentWmsId: item.parentWmsId,
  checked: defaultSelections ? defaultSelections.includes(item.code) : false,
  expanded: false,
  seller_id: item.companyId,
  path: [],
});
