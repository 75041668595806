import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from "@material-ui/core";
import { getBusLinesList, toggleBusLineDialog } from "../actions";
import _ from "lodash";
import {
  ProvinceSelector,
  DistrictSelector,
  fetchArea,
  RegionSelection,
  createBusLine,
  openNotiDialog,
  updateBusLine,
} from "../../shared";

const styles = (theme) => ({
  text: {
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: 16,
    overflowY: "visible",
  },
  label: {
    lineHeight: 2,
    fontSize: 15,
    letterSpacing: 0.8,
    color: "#000000ab",
  },
  saveBtn: {
    color: "white",
    background: "cornflowerblue",
    "&:disabled": {
      background: "#8080807d",
    },
  },
  cancelBtn: {
    border: "1px solid #80808029",
  },
  input: {
    height: 34,
    border: "1px solid #cccccc63",
    borderRadius: 9,
    paddingLeft: 8,
    width: "calc(100% - 10px)",
    fontSize: 15,
    color: "#000000bd",
  },
  paper: {
    maxWidth: "38rem",
    overflow: "visible",
    borderRadius: "1rem",
  },
  actionBtn: {
    borderRadius: "1rem",
  },
  "@global": {
    "#addBusLineForm .Select-control": {
      borderRadius: 9,
      border: "1px solid #cccccc63",
    },
    "#addBusLineForm .Select-control:hover": {
      boxShadow: "none",
      "-webkit-box-shadow": "none",
    },
  },
});

const blankForm = {
  id: "",
  name: "",
  region: "northern",
  bus_code: "",
  address: {
    name: "",
    phone: "",
    email: "",
    street: "",
    province: "",
    district: "",
    area_code: "",
  },
};

class AddBusLineDialog extends React.Component {
  constructor(props) {
    super(props);
    const data = this.props.dialog.selected;
    this.initialData = data
      ? {
          id: data.id,
          name: data.name,
          bus_code: data.bus_code,
          region: data.region,
          address: {
            ...data.address,
            district:
              data.address.area_code && data.address.area_code.length === 4
                ? data.address.area_code
                : "",
            province: data.address.area_code
              ? data.address.area_code.slice(0, 2)
              : "",
            street: data.address.full_address
              ? data.address.full_address.split("|")[0]
              : "",
          },
        }
      : blankForm;
    this.state = {
      type: data ? "edit" : "create",
      bus_line: this.initialData,
      confirm: false,
      saveLoading: false,
      validate: {
        bus_line_name_required: false,
        bus_line_name_max_length: false,
        phone: false,
        street: false,
      },
    };
  }

  changeInfo = (obj) => {
    const { bus_line } = this.state;
    this.setState({
      bus_line: {
        ...bus_line,
        ...obj,
      },
    });
  };

  handleShortKey = (e) => {
    const key = e.keyCode;
    const alt = e.altKey;
    if (key === 83 && alt) {
      this.handleSave();
    }
  };

  async componentDidMount() {
    window.addEventListener("keydown", this.handleShortKey);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleShortKey);
  }

  hanleClose = () => {
    const { toggleBusLineDialog } = this.props;
    const { bus_line } = this.state;
    if (JSON.stringify(bus_line) == JSON.stringify(this.initialData)) {
      toggleBusLineDialog(false);
    } else {
      this.setState({ confirm: true });
    }
  };

  validate = () => {
    const { bus_line } = this.state;
    let flag = false;
    let validate = {
      bus_line_name_required: false,
      bus_line_name_max_length: false,
      phone: false,
    };
    if (!bus_line.name) {
      validate.bus_line_name_required = true;
      flag = true;
    } else if (bus_line.name.length > 255) {
      validate.bus_line_name_max_length = true;
      flag = true;
    }
    if (
      bus_line.address.phone &&
      !bus_line.address.phone.match(/^(0|84)\d{9,10}$/g)
    ) {
      validate.phone = true;
      flag = true;
    }
    if (bus_line.address.street && bus_line.address.street.length > 255) {
      validate.street = true;
      flag = true;
    }
    this.setState({ validate });
    return flag;
  };

  handleSave = async () => {
    const { type, bus_line } = this.state;
    const { toggleBusLineDialog, openNotiDialog, getBusLinesList } = this.props;
    //validate
    const hasError = this.validate();
    if (hasError) return;
    const data = this.reprocessData(bus_line);
    this.setState({ saveLoading: true });
    let response = null;
    if (type === "create") {
      response = await createBusLine(data);
    } else {
      response = await updateBusLine(data.id, data);
    }
    const onClose = () => toggleBusLineDialog(false);
    const displayText = (errMsg) => (
      <div>
        {type === "create" ? "Thêm" : "Cập nhật"} nhà xe{" "}
        <strong>{response.status === 200 ? "thành công" : "thất bại"}</strong>!{" "}
        {response.status !== 200 && errMsg && <div>{errMsg}</div>}
      </div>
    );
    if (response.status === 200) {
      openNotiDialog("success", displayText(), onClose);
      await getBusLinesList();
    } else {
      const errMsg = response.data.message;
      openNotiDialog("failed", displayText(errMsg));
    }
    this.setState({ saveLoading: false });
  };

  reprocessData(bus_line) {
    //Set request body
    let data = { ...bus_line };
    data.address.area_code = data.address.district
      ? data.address.district
      : data.address.province;
    delete data.address.province;
    delete data.address.district;
    Object.keys(data.address).forEach((element) => {
      if (!data.address[element]) delete data.address[element];
      else if (typeof data.address[element] == "string")
        data.address[element] = data.address[element].trim();
    });
    Object.keys(data).forEach((element) => {
      if (typeof data[element] == "string")
        data[element] = data[element].trim();
    });
    return data;
  }

  changeAddress = (addr) => {
    const { bus_line } = this.state;
    this.setState({
      bus_line: {
        ...bus_line,
        address: {
          ...bus_line.address,
          ...addr,
        },
      },
    });
  };

  render() {
    const { dialog, toggleBusLineDialog, fetchArea, data, classes } =
      this.props;
    const { bus_line, confirm, validate, saveLoading } = this.state;
    return (
      <Dialog
        open={dialog.open}
        onClose={this.hanleClose}
        fullWidth={true}
        maxWidth={false}
        className={classes.text}
        classes={{ paper: classes.paper }}
        id="addBusLineForm"
      >
        <DialogTitle>
          {data ? "Cập nhật thông tin nhà xe" : "Thêm nhà xe"}
        </DialogTitle>
        <DialogContent style={{ overflowY: "visible", paddingBottom: 13 }}>
          <Grid container alignItems="center">
            <Grid item sm={12}>
              <strong className={classes.label}>
                Tên nhà xe <span className="required" />
              </strong>
              <input
                autoFocus={true}
                className={classes.input}
                value={bus_line.name}
                onChange={(e) => {
                  this.changeInfo({
                    name: e.target.value,
                  });
                  this.setState({
                    validate: { ...validate, bus_line_name_required: false },
                  });
                }}
              />
              {bus_line.name.length > 255 && (
                <span className="errorMsg">
                  Tên nhà xe không được vượt quá 255 kí tự
                </span>
              )}
              {validate.bus_line_name_required && (
                <span className="errorMsg">Bạn chưa nhập tên nhà xe</span>
              )}
            </Grid>
          </Grid>
          <Grid container alignItems="center">
            <Grid item sm={12}>
              <strong className={classes.label}>
                Miền <span className="required" />
              </strong>
              <RegionSelection
                value={bus_line.region}
                onChange={(val) => this.changeInfo({ region: val })}
                clearable={false}
                wrapperStyle={{ borderRadius: 9 }}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center">
            <Grid item sm={12}>
              <strong className={classes.label}>Tên người liên hệ</strong>
              <input
                className={classes.input}
                value={bus_line.address.name}
                onChange={(e) => this.changeAddress({ name: e.target.value })}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center">
            <Grid item sm={12}>
              <strong className={classes.label}>Số điện thoại &nbsp;</strong>
              <input
                type="number"
                className={classes.input}
                value={bus_line.address.phone}
                placeholder="--- --- ----"
                onChange={(e) => {
                  this.changeAddress({ phone: e.target.value });
                  this.setState({
                    validate: { ...validate, phone: false },
                  });
                }}
              />
              {validate.phone && (
                <span className="errorMsg">Số điện thoại không hợp lệ</span>
              )}
            </Grid>
          </Grid>
          <Grid container alignItems="center">
            <Grid item sm={12}>
              <strong className={classes.label}>Địa chỉ</strong>
              <input
                className={classes.input}
                value={bus_line.address.street}
                onChange={(e) => {
                  this.changeAddress({ street: e.target.value });
                  this.setState({
                    validate: { ...validate, street: false },
                  });
                }}
              />
              {bus_line.address.street &&
                bus_line.address.street.length > 255 && (
                  <span className="errorMsg">
                    Địa chỉ không được vượt quá 255 kí tự
                  </span>
                )}
            </Grid>
          </Grid>
          <Grid container alignItems="center">
            <Grid item sm={12}>
              <strong className={classes.label}>Tỉnh/Thành</strong>
              <ProvinceSelector
                province={bus_line.address.province}
                changeProvince={(e) => this.changeAddress({ province: e })}
                fetchArea={fetchArea}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center">
            <Grid item sm={12}>
              <strong className={classes.label}>Quận/Huyện</strong>
              <DistrictSelector
                province={bus_line.address.province}
                district={bus_line.address.district}
                changeDistrict={(e) => this.changeAddress({ district: e })}
                fetchArea={fetchArea}
              />
            </Grid>
          </Grid>
          <br />
          <Grid container alignItems="center">
            <Grid item md={12} style={{ textAlign: "right" }}>
              <Button
                className={`${classes.saveBtn} ${classes.actionBtn}`}
                onClick={this.handleSave}
              >
                {saveLoading && <CircularProgress size={20} color="inherit" />}
                {"  "}Lưu
              </Button>
              &nbsp;
              <Button
                className={`${classes.cancelBtn} ${classes.actionBtn}`}
                onClick={this.hanleClose}
              >
                Đóng
              </Button>
            </Grid>
          </Grid>
          <Dialog
            open={confirm}
            maxWidth="xs"
            classes={{ paper: classes.paper }}
          >
            <DialogContent>
              Những thay đổi sẽ không được lưu lại. Bạn có chắc chắn muốn thoát
              ?
              <br />
              <br />
              <div style={{ textAlign: "right" }}>
                <Button
                  style={{ background: "cornflowerblue", color: "white" }}
                  className={classes.actionBtn}
                  onClick={(e) => {
                    this.setState({ confirm: false });
                    toggleBusLineDialog(false, null);
                  }}
                >
                  Có
                </Button>
                <Button
                  onClick={(e) => this.setState({ confirm: false })}
                  className={classes.actionBtn}
                >
                  Không
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        </DialogContent>
      </Dialog>
    );
  }
}
const mapStateToProps = (state) => ({
  bus_lines: state.admin.busLineManagement.bus_lines,
  dialog: state.admin.busLineManagement.dialog,
  filter: state.admin.busLineManagement.filter,
});

const mapDispatchToProps = (dispatch) => ({
  getBusLinesList: () => dispatch(getBusLinesList()),
  fetchArea: (areaCode) => dispatch(fetchArea(areaCode)),
  toggleBusLineDialog: (state, selected) =>
    dispatch(toggleBusLineDialog(state, selected)),
  openNotiDialog: (type, text, callback) =>
    dispatch(openNotiDialog(type, text, callback)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(AddBusLineDialog)
);
