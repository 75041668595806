export const filterStyles = (theme) => ({
  container: {
    marginBottom: "1rem",
    fontFamily: "Noto Sans, sans-serif",
    background: "white",
    borderRadius: "0 0 0.5rem 0.5rem",
    fontSize: 13,
  },
  header: {
    background: "white",
    borderRadius: "0.5rem 0.5rem 0 0",
    borderBottom: "1px solid #E8E8E8",
    display: "flex",
    borderTop: "5px solid #2f71c454",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  label: {
    fontSize: 12,
    paddingLeft: 2,
    fontWeight: 600,
    color: "#52525b",
    display: "block",
    paddingBottom: 2,
  },
  row: {
    marginBottom: "0.3rem",
  },
  bookmarkContent: {
    paddingTop: "0.5rem",
    paddingLeft: "0.5rem",
  },
  bookmarkContainer: {
    border: "none",
  },
  listClass: {
    maxHeight: "15rem",
  },
  bookmarkBtn: {
    background: "#ffa240",
    color: "white",
  },
  "@global": {
    "#blListFilter .Select-control": {
      height: 40,
    },
    "#blListFilter .Select--multi .Select-value": {
      marginTop: 0,
      margin: 2,
      verticalAlign: "middle",
    },
    "#blListFilter .Select--multi .Select-multi-value-wrapper": {
      maxHeight: 50,
      overflow: "auto",
      display: "block",
      height: "100%",
      "&::-webkit-scrollbar": {
        height: "7px",
        width: "5px",
      },
      /* Track */
      "&::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },

      /* Handle */
      "&::-webkit-scrollbar-thumb": {
        background: "#2f71c42e",
        borderRadius: "0.5rem",
      },

      /* Handle on hover */
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#7E9DC5",
      },
    },
    "div.dropdown,ul.tag-list": {
      width: "100%",
    },
    "a.dropdown-trigger": {
      display: "flex !important",
      alignItems: "center",
    },
    "ul.tag-list": {
      height: 32,
    },
    ".react-dropdown-tree-select .dropdown .dropdown-trigger.arrow": {
      borderRadius: 5,
    },
  },
});

export const createTransportStyles = (theme) => ({
  flex: {
    flex: 1,
    fontFamily: "sans-serif",
  },
  subTitle: {
    fontSize: 14,
    fontFamily: "Source Sans Pro, sans-serif",
    textTransform: "uppercase",
    fontWeight: 600,
    letterSpacing: 1,
    color: "#f05050",
  },
  productHeading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  paper: {
    padding: "1rem",
    height: "calc(100% - 32px)",
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  input: {
    height: 34,
    border: "1px solid #ccc",
    borderRadius: 4,
    paddingLeft: 8,
  },
  label: {
    whiteSpace: "nowrap",
    marginRight: "1rem",
  },
});

export default {
  filterStyles,
  createTransportStyles,
};
