import apiService from "./base";

export const dumpThreePlsApi = "";

export const getThreePlsAvailable = async (bl_id, data) => {
  return apiService({
    url: `/threepl/threepls_available/bl/${bl_id}`,
    method: "get",
    params: data,
  });
};

export const getThreePlsSetting = async (threepls_code) => {
  return apiService({
    url: `/admin/threepls/settings`,
    method: "get",
    params: {
      threepls_code: threepls_code,
    },
  });
};

export const getAllThreePlsApi = async () => {
  return apiService({
    url: "/threepl/threepls/all",
    method: "get",
  });
};

export const printBillApi = (ship_method, encoded_pl_ref) => {
  return apiService({
    url: `/threepl/${ship_method}/print-bill/${encoded_pl_ref}`,
    method: "get",
  });
};
