import React from "react";
import Button from "@material-ui/core/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import {
  TableRow,
  TableCell,
  TableHead,
  Table,
  TableBody,
} from "@material-ui/core";
import { connect } from "react-redux";
import { formatDateTime } from "../../../../utils";

class DeliverTypeChangeHistory extends React.Component {
  render() {
    const { open, history, onClose } = this.props;
    return (
      <Dialog open={open} onClose={onClose} maxWidth="md">
        <DialogTitle>Lịch sử cập nhật Loại giao hàng</DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Ngày thay đổi</TableCell>
                <TableCell>Loại giao hàng mới</TableCell>
                <TableCell>Người thay đổi</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.length > 0 ? (
                history.map((x, index) => (
                  <TableRow key={index}>
                    <TableCell>{formatDateTime(x[0].created_at)}</TableCell>
                    <TableCell>
                      <ul>
                        {x.map((k, id) => (
                          <li key={id}>{k.description}</li>
                        ))}
                      </ul>
                    </TableCell>
                    <TableCell>{x[0].user_name}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>Không có</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Đóng </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  allKtvList: state.admin.shared.allKtvList,
});

export default connect(mapStateToProps, null)(DeliverTypeChangeHistory);
