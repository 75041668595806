import React from "react";
import { Grid, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  radio: {
    height: 15,
  },
});

class DebtTransferSelection extends React.Component {
  render() {
    const { value, onChange, classes, md } = this.props;
    return (
      <Grid item md={md}>
        <RadioGroup
          aria-label="debtor"
          name="debtor"
          value={value}
          style={{ flexDirection: "row" }}
          onChange={(e, v) => onChange(v)}
        >
          <FormControlLabel
            style={{
              marginBottom: "5px",
            }}
            value="true"
            control={<Radio className={classes.radio} />}
            label="Gán nợ cho KTV lộ trình này"
          />
          <FormControlLabel
            style={{
              marginBottom: "5px",
            }}
            value="false"
            control={<Radio className={classes.radio} />}
            label="Không gán nợ cho KTV lộ trình này"
          />
        </RadioGroup>
      </Grid>
    );
  }
}

export default withStyles(styles)(DebtTransferSelection);
