import { fetchError } from "@shared";
import {
  getSaleChannelsApi,
  getUserProfile,
  getHubListApi,
  getAllThreePlsApi,
  getKtvListApi,
  getAllKtvListApi,
  getRejectReasonsByType,
  getDeliveryTypes,
  getWebSettingsApi,
  getUserManual,
} from "@api";
import TekoID from "teko-oauth2";

/* GET CHANNEL */
export const setChannels = (channels) => ({
  type: "SET_CHANNELS",
  payload: channels,
});

export const getChannels = (params) => async (dispatch, getState) => {
  const response = await getSaleChannelsApi();
  const body = response.data;
  if (response.status === 200) {
    if (body) {
      dispatch(setChannels(body.data.channels));
    }
  }
};

/* GET USER PROFILE */
const setForbiddenState = (state) => ({
  type: "SET_FORBIDDEN",
  payload: state,
});

const fetchUserSuccess = (user) => ({
  type: "FETCH_USER_SUCCESS",
  payload: {
    user,
  },
});

export const fetchUser = () => async (dispatch) => {
  try {
    const response = await getUserProfile();
    if (response.status === 200) {
      dispatch(fetchUserSuccess(response.data.data.user));
      dispatch(setForbiddenState(false));
    } else {
      if ([400].includes(response.status)) {
        TekoID.user.login();
      } else if (response.status === 403) {
        dispatch(setForbiddenState(true));
      } else {
        dispatch(fetchError(response.status));
      }
    }
  } catch (error) {
    dispatch(fetchError(error.response.status));
  }
};

/* GET HUB LIST */
export const fetchHubList = () => async (dispatch) => {
  const response = await getHubListApi();
  const result = response.data;
  if (response.status === 200) {
    dispatch(fetchHubListSuccess(result.data.hubs));
  } else {
    dispatch(fetchError(response.status, result.data.message));
  }
};

const fetchHubListSuccess = (hubs) => ({
  type: "FETCH_HUB_LIST_SUCCESS",
  payload: {
    hubs,
  },
});

/* GET KTV LIST */
export const fetchKTVList = () => async (dispatch, getState) => {
  const response = await getKtvListApi();
  const data = response.data;
  if (response.status === 200) {
    if (data.data) {
      dispatch(fetchKTVListSuccess(data.data));
    } else {
      dispatch(
        fetchError(
          response.status,
          "Có lỗi xảy ra! Vui lòng liên hệ Logistic team để được hỗ trợ!"
        )
      );
    }
  } else {
    dispatch(fetchError(response.status, data.message));
  }
};

const fetchKTVListSuccess = (data) => {
  let ktvs = data.ktv_list.map((ktv) => {
    const asiaId = ktv?.asia_id?.toString().padStart(4, 0);

    return {
      value: ktv?.id,
      label:
        [asiaId, ktv?.name, ktv?.phone].filter(Boolean).join(" - ") ||
        ktv?.email,
      areaCode: ktv?.area_code,
      fullAddress: ktv?.full_address,
      phone: ktv?.phone,
      status: ktv?.status,
      roleId: ktv?.role_id,
      asia_branch: ktv?.asia_branch || "",
      name: ktv?.name,
    };
  });

  return {
    type: "FETCH_KTV_LIST_SUCCESS",
    payload: {
      ktvList: ktvs,
    },
  };
};

/* FETCH ALL KTV LIST */
export const fetchAllKTVList = () => async (dispatch, getState) => {
  const response = await getAllKtvListApi();
  const data = response.data;

  if (response.status === 200) {
    if (data.data) {
      dispatch(fetchAllKTVListSuccess(data.data));
    } else {
      dispatch(
        fetchError(
          response.status,
          "Có lỗi xảy ra! Vui lòng liên hệ Logistic team để được hỗ trợ!"
        )
      );
    }
  } else {
    dispatch(fetchError(response.status, data.message));
  }
};

const fetchAllKTVListSuccess = (data) => {
  let ktvs = data.ktv_list.map((ktv) => {
    const asiaId = ktv?.asia_id?.toString().padStart(4, 0);

    return {
      value: ktv?.id,
      label:
        [asiaId, ktv?.name, ktv?.phone].filter(Boolean).join(" - ") ||
        ktv?.email,
      areaCode: ktv?.area_code,
      fullAddress: ktv?.full_address,
      phone: ktv?.phone,
      status: ktv?.status,
      roleId: ktv?.role_id,
      asia_branch: ktv?.asia_branch || "",
      name: ktv?.name,
    };
  });

  return {
    type: "FETCH_ALL_KTV_LIST_SUCCESS",
    payload: {
      ktvList: ktvs,
    },
  };
};

/* FETCH REJECT REASONS */
export const fetchRejectReasons = () => async (dispatch) => {
  const response = await getRejectReasonsByType();
  const result = response.data;
  if (response.status === 200) {
    if (result) {
      dispatch(fetchRejectReasonsSuccess(result.reasons));
    } else {
      dispatch(
        fetchError(
          response.status,
          "Có lỗi xảy ra! Vui lòng liên hệ Logistic team để được hỗ trợ!"
        )
      );
    }
  } else {
    dispatch(fetchError(response.status, result.message));
  }
};

export const fetchRejectReasonsSuccess = (data) => {
  return {
    type: "FETCH_REJECT_REASONS_SUCCESS",
    payload: {
      reasons: data,
    },
  };
};

/* FETCH ALL THREEPLS */
export const fetchAll3Pls = () => async (dispatch) => {
  const response = await getAllThreePlsApi();
  const result = response.data;
  if (response.status === 200) {
    if (result) {
      dispatch(fetchAll3PlsSuccess(result.data.ThreePls));
    } else {
      dispatch(
        fetchError(
          response.status,
          "Có lỗi xảy ra! Vui lòng liên hệ Logistic team để được hỗ trợ!"
        )
      );
    }
  } else {
    dispatch(fetchError(response.status, result.message));
  }
};

const fetchAll3PlsSuccess = (threepls) => ({
  type: "FETCH_ALL_3PLS_SUCCESS",
  payload: threepls,
});

/* FETCH DELIVERY TYPES */
export const fetchDeliveryTypes = () => async (dispatch) => {
  const response = await getDeliveryTypes();
  const result = response.data;

  if (response.status === 200) {
    if (result) {
      dispatch(fetchDeliveryTypesSuccess(result.data.delivery_types));
    }
  }
};

const fetchDeliveryTypesSuccess = (delivery_types) => ({
  type: "FETCH_ALL_DELIVERY_TYPES_SUCCESS",
  payload: delivery_types,
});

/* FETCH WEB SETTINGS */
export const setWebSettings = (settings) => ({
  type: "SET_WEB_SETTINGS",
  payload: settings,
});

export const getWebSettings = () => async (dispatch) => {
  const response = await getWebSettingsApi();
  const body = response.data;
  if (response.status === 200) {
    const data = body.data;
    dispatch(setWebSettings(data));
  }
};

/* FETCH USER MANUAL */
export const fetchUserManual = () => async (dispatch) => {
  const response = await getUserManual();
  const result = response.data;

  if (response.status === 200) {
    if (result && result.data) {
      dispatch(fetchUserManualSuccess(result.data));
    }
  }
};

const fetchUserManualSuccess = (data) => ({
  type: "FETCH_USER_MANUAL_SUCCESS",
  payload: data,
});
