import { fetchError } from "@shared";
import { batchActionsApi } from "@api";

export const batchUpdate = (bls, action) => async (dispatch, getState) => {
  let ids = bls.map((bl) => bl.lastTransport.id);
  let bl_ids = bls.map((bl) => bl.id);
  let url = `/admin/transport/multiple`;
  switch (action) {
    case "confirmOK":
    case "confirmImport":
      url += "/ok";
      break;
    case "confirmPicked":
      url += "/pick";
      break;
    case "confirmReturn":
      url += "/confirm_return";
      break;
    case "confirmStockRequest":
      url = `/admin/bls/multiple/stock_request`;
      break;
    default:
      return "";
  }

  const response = await batchActionsApi(url, {
    ids: action === "confirmStockRequest" ? bl_ids : ids,
  });
  const result = response.data;

  if (response.status === 200) {
    if (result) {
      let responses = {};
      result.data.result.forEach((resp) => {
        responses[resp.id] = {
          msg: resp.debug,
          error: resp.error,
        };
      });
      dispatch(batchUpdateSuccess(responses));
      return {
        error: false,
      };
    } else {
      dispatch(
        fetchError(
          response.status,
          "Có lỗi xảy ra! Vui lòng liên hệ Logistic team để được hỗ trợ!"
        )
      );
      return {
        error: true,
      };
    }
  } else {
    dispatch(fetchError(response.status, result.message));
    return {
      error: true,
    };
  }
};

const batchUpdateSuccess = (response) => ({
  type: "BATCH_UPDATE_SUCCESS",
  payload: {
    response,
  },
});

export const removeSelectedBl = (page, id, index) => ({
  type: "REMOVE_SELECTED_BL",
  payload: {
    page,
    id,
    index,
  },
});

export const setSelectedBls = (bls) => ({
  type: "BL_GROUP_SET_SELECTED_BLS",
  payload: bls,
});
