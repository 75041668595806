import React from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { EditAndSaveInfo } from "../../../bl_detail/components";
import { BlRequestType } from "@constants";

const styles = (theme) => ({
  subtitle: {
    fontSize: 14,
    padding: "8px 0 16px 0",
    fontFamily: "Source Sans Pro, sans-serif",
    textTransform: "uppercase",
    fontWeight: 600,
    letterSpacing: 1,
    color: "#5d9cec",
  },
  rowDetail: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 14,
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
  },
  row: {
    paddingBottom: 14,
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
  },
});

class BlGroupToAddress extends React.Component {
  render() {
    const { address, groupType, classes, bl, onUpdate } = this.props;
    return (
      <div>
        <Typography type="subheading" className={classes.subtitle}>
          Thông tin{" "}
          {groupType === BlRequestType.wh_transfer ? "kho nhận" : "người nhận"}
          {groupType !== BlRequestType.wh_transfer && (
            <EditAndSaveInfo
              address={address}
              showHistoryButton={false}
              bl={bl}
              onUpdated={onUpdate}
            />
          )}
        </Typography>
        <Grid container>
          <Grid item md={6}>
            <span className={classes.rowDetail}>
              <strong>Người nhận:</strong>
              <span>&nbsp;&nbsp;{address.displayName}</span>
            </span>
            <span className={classes.row}>
              <strong>Địa chỉ:</strong>
              <span>&nbsp;&nbsp;{address.fullAddress}</span>
            </span>
          </Grid>
          <Grid item md={6}>
            <span className={classes.rowDetail}>
              <strong>Số điện thoại:</strong>
              <span>&nbsp;&nbsp;{address.phone}</span>
            </span>
            <span className={classes.rowDetail}>
              <strong>Email:</strong>
              <span>
                &nbsp;&nbsp;{address.email ? address.email : "Không có"}
              </span>
            </span>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(BlGroupToAddress);
