import React from "react";
import Select from "react-select";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  popoverContent: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
  },
  container: {
    display: "flex",
    alignItems: "center",
  },
});

class ShipperSelection extends React.Component {
  render() {
    let { ktvList, value, changeShipper, classes, closeOnSelect } = this.props;
    return (
      <React.Fragment>
        <div className={classes.container}>
          <Select
            value={value}
            onChange={changeShipper}
            options={ktvList}
            placeholder="Chọn KTV giao hàng"
            wrapperStyle={{ height: 40, flex: 1 }}
            optionComponent={ShipperItem}
            valueComponent={ShipperLabel}
            closeOnSelect={closeOnSelect}
          />
        </div>
      </React.Fragment>
    );
  }
}

class ShipperItem extends React.Component {
  handleMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onSelect(this.props.option, event);
  };

  handleMouseEnter = (event) => {
    this.props.onFocus(this.props.option, event);
  };
  handleMouseMove = (event) => {
    if (this.props.isFocused) return;
    this.props.onFocus(this.props.option, event);
  };

  render() {
    let { option, className } = this.props;
    return (
      <div
        className={className}
        onMouseDown={this.handleMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseMove={this.handleMouseMove}
      >
        <span>{option.label}&nbsp;</span>
      </div>
    );
  }
}

class ShipperLabel extends React.Component {
  render() {
    let { value } = this.props;
    let labelStyle = {
      overflow: "hidden",
      textOverflow: "ellipsis",
    };
    return (
      <div className="Select-value">
        <div className="Select-value-label" style={labelStyle}>
          {value.label}
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(ShipperSelection);
