import apiService from "./base";

export const dumpAreaApi = "";

export const getArea = async (code, region) => {
  return apiService({
    url: "/admin/area",
    method: "get",
    params: { code, region },
  });
};
