import React from "react";
import Select from "react-select";

class HubFilter extends React.Component {
  render() {
    const {
      type,
      hubs,
      value,
      setFilter,
      placeholder = "Chọn kho...",
      multi = true,
      hasRegion = true,
    } = this.props;
    let formattedHubs = [];
    if (hasRegion) {
      const southernHubs = this.props.hubs
        .filter((x) => x.area.region === "southern")
        .map((x) => x.id)
        .toString();
      const centralHubs = hubs
        .filter((x) => x.area.region === "central")
        .map((x) => x.id)
        .toString();
      const northernHubs = hubs
        .filter((x) => x.area.region === "northern")
        .map((x) => x.id)
        .toString();

      if (value.search(northernHubs) === -1) {
        formattedHubs.push({ value: northernHubs, label: "Miền Bắc" });
      }
      if (value.search(centralHubs) === -1) {
        formattedHubs.push({ value: centralHubs, label: "Miền Trung" });
      }
      if (value.search(southernHubs) === -1) {
        formattedHubs.push({ value: southernHubs, label: "Miền Nam" });
      }
      formattedHubs = [
        ...formattedHubs,
        ...hubs.map((hub) => ({
          value: hub.id.toString(),
          label: hub.name,
        })),
      ];
    } else {
      formattedHubs = hubs.map((hub) => ({
        value: hub.id.toString(),
        label: hub.name,
      }));
    }

    return (
      <Select
        value={value}
        onChange={(hub) => setFilter(type, hub ? hub : "")}
        options={formattedHubs}
        multi={multi}
        simpleValue
        closeOnSelect={multi ? false : true}
        placeholder={placeholder}
      />
    );
  }
}

export default HubFilter;
