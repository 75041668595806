import apiService from "./base";

export const dumpUserApi = null;

/* Lấy danh sách filter đã lưu của user */
export const getUserSavedFilter = async (filter_type) => {
  return apiService({
    url: `/admin/user/filters`,
    params: { type: filter_type },
  });
};

/* Lưu filter  */
export const saveUserFilter = async (body) => {
  return await apiService({
    url: `/admin/user/filters`,
    method: "POST",
    data: body,
  });
};

/* Xóa filter  */
export const deleteUserFilter = async (body) => {
  return apiService({
    url: `/admin/user/filters`,
    method: "DELETE",
    data: body,
  });
};

/* Lấy danh sách asia branch */
export const getAsiaBranches = async () => {
  return apiService({
    url: "/admin/user/asia_branches",
  });
};

/* Cập nhật filter  */
export const updateUserFilter = async (id, body) => {
  return await apiService({
    url: `/admin/user/filters/${id}`,
    method: "PUT",
    data: body,
  });
};

export const getKtvListApi = async () => {
  return apiService({
    url: "/admin/user/ktv-list",
    method: "get",
  });
};

export const getAllKtvListApi = async () => {
  return apiService({
    url: "/admin/user/ktv-all",
    method: "get",
  });
};

export const updateFilterListApi = async (data) => {
  return apiService({
    url: "/admin/user/filters",
    method: "put",
    data: data,
  });
};
