import React from "react";
import Button from "@material-ui/core/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import { ShipMethod, WMS_STATUS, SPECIAL_THREE_PLS } from "@constants";
import { mapShipMethodToText } from "@utils";
import PackingImg from "@images/packing.png";

const styles = (theme) => ({
  button: {
    textTransform: "none",
    backgroundColor: "#F57F17",
    color: "#fff",
    marginLeft: 8,
  },
  stressPink: {
    fontWeight: "bold",
    color: "indianred",
  },
  errorContent: {
    minWidth: "20rem",
  },
  paper: {
    borderRadius: "1rem",
  },
});

class ConfirmPicked extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      updating: false,
      errDialog: false,
      errMsg: "",
    };
  }

  toggleDialog = () => {
    const { bl, transport, classes } = this.props;
    let errMsg = "";
    if (bl.wmsStatus === WMS_STATUS.init) {
      errMsg = (
        <span>
          Không thể <strong>lấy hàng</strong> vì{" "}
          <strong className={classes.stressPink}>đang chờ soạn hàng</strong>
        </span>
      );
    } else if (bl.wmsStatus === WMS_STATUS.pick) {
      errMsg = (
        <span>
          Không thể <strong>lấy hàng</strong> vì kiện hàng{" "}
          <strong className={classes.stressPink}>đang được đóng gói</strong>
        </span>
      );
    } else if (
      bl.wmsStatus === WMS_STATUS.pack &&
      SPECIAL_THREE_PLS.includes(transport.shipMethod) &&
      !transport.able_to_pick
    ) {
      errMsg = (
        <span>
          Vui lòng liên hệ{" "}
          <strong className={classes.stressPink}>
            {mapShipMethodToText(transport.shipMethod)}
          </strong>{" "}
          để thực hiện thao tác
        </span>
      );
    }
    if (errMsg) {
      this.setState({ errDialog: true, errMsg: errMsg });
    } else {
      this.setState({ open: true });
    }
  };

  closeDialog = (type) => {
    this.setState({ [type]: false });
  };

  confirmPicked = async () => {
    const {
      confirmTransportDelivered,
      confirmTransportPicked,
      isStockRequest,
      stockRequest,
    } = this.props;
    this.setState({
      updating: true,
    });
    const { transport } = this.props;
    if (isStockRequest) {
      await stockRequest();
    } else {
      if (transport.shipMethod === ShipMethod.direct) {
        await confirmTransportDelivered(transport.id);
      } else {
        await confirmTransportPicked(transport.id);
      }
    }
    this.setState({
      updating: false,
    });
  };

  renderConfirmMessage = () => {
    const { transport, isStockRequest, bl } = this.props;
    const cancelState = bl.last_om_cancel_request;
    let message = isStockRequest;
    if (isStockRequest) {
      message = <span>Bạn muốn gửi yêu cầu lấy hàng?</span>;
    } else {
      if (
        cancelState.action &&
        transport.fromAddress.objId === bl.fromAddr.objId
      ) {
        message = (
          <span>
            Khách đang có <strong>yêu cầu hủy đơn hàng</strong> nên sau thao tác
            xác nhận giao hàng cho 3PLs này vận đơn sẽ bị hủy, hàng{" "}
            <strong>cần nhập thu hồi về kho</strong>
          </span>
        );
      } else {
        message = (
          <span>
            Bạn muốn xác nhận {mapShipMethodToText(transport.shipMethod)} đã lấy
            hàng?
          </span>
        );
      }
    }
    return message;
  };

  isNotAllowed = () => {
    const { bl, transport } = this.props;
    if ([WMS_STATUS.init, WMS_STATUS.pick].includes(bl.wmsStatus)) return true;
    if (
      SPECIAL_THREE_PLS.includes(transport.shipMethod) &&
      !transport.able_to_pick
    )
      return true;
    return false;
  };

  renderPickButton = () => {
    const { classes, title } = this.props;
    return (
      <Button
        variant="contained"
        className={classes.button}
        onClick={this.toggleDialog}
      >
        {this.isNotAllowed() && <i className="fas fa-exclamation-triangle" />}
        &nbsp;
        {title ? title : "Xác nhận giao hàng"}
      </Button>
    );
  };

  renderErrorDialog = () => {
    const { errDialog, errMsg } = this.state;
    const { classes } = this.props;
    return (
      <Dialog
        open={errDialog}
        onClose={() => this.closeDialog("errDialog")}
        classes={{ paper: classes.paper }}
      >
        <DialogContent className={classes.errorContent}>
          <div style={{ textAlign: "center" }}>
            <img src={PackingImg} />
          </div>
          <DialogContentText style={{ textAlign: "center" }}>
            {errMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.closeDialog("errDialog")}>OK</Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderConfirmDialog = () => {
    const { open, updating } = this.state;
    return (
      <Dialog open={open} onClose={() => this.closeDialog("open")}>
        <DialogTitle>Xác nhận lộ trình</DialogTitle>
        <DialogContent>
          <DialogContentText>{this.renderConfirmMessage()}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.closeDialog("open")}>Hủy</Button>
          <Button
            color="primary"
            onClick={() => this.confirmPicked()}
            disabled={updating}
          >
            {updating && <CircularProgress size={20} />} &nbsp; Đồng ý
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.renderPickButton()}
        {this.renderConfirmDialog()}
        {this.renderErrorDialog()}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ConfirmPicked);
