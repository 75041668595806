import { getAsiaBranches } from "@api";

export const getAsiaBranchesList = () => async (dispatch) => {
  const response = await getAsiaBranches();
  const body = response.data;
  if (response.status === 200) {
    dispatch({
      type: "REPORT_SET_ASIA_BRANCHES",
      payload: body.data.branches,
    });
  }
};
