import React from "react";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { Chip } from "@material-ui/core";
import { mapShipMethodToText, mapBlStatusText } from "../../utils";

const styles = (theme) => ({
  chips: {
    marginRight: 5,
    marginBottom: 8,
  },
});

const RequestType = {
  sale_order: "Vận đơn WMS",
  asia_order: "Vận đơn Asia",
  wh_transfer: "Chuyển kho nội bộ",
};

class QuickFilterBar extends React.Component {
  renderRequestTypeFilter = () => {
    const { filter, onChange, fetchBlList, classes } = this.props;
    const requestTypes = filter.requestType
      ? filter.requestType.split(",")
      : [];
    return (
      requestTypes.length > 0 &&
      requestTypes.map((r, id) => (
        <Chip
          className={classes.chips}
          key={id}
          label={RequestType[r]}
          onDelete={() => {
            onChange(
              "requestType",
              requestTypes.filter((x) => x !== r).toString()
            );
            fetchBlList();
          }}
        />
      ))
    );
  };

  renderKeyword = () => {
    const { filter, onChange, fetchBlList, classes } = this.props;
    const keyword = filter.keyword;
    if (keyword)
      return (
        <Chip
          className={classes.chips}
          label={`Từ khóa ${keyword}`}
          onDelete={() => {
            onChange("keyword", "");
            fetchBlList();
          }}
        />
      );
    else return <span />;
  };

  renderShipDate = () => {
    const { filter, onChange, fetchBlList, classes } = this.props;
    const fromDate = filter.shipDateFrom;
    const toDate = filter.shipDateTo;
    const label =
      "Ngày hẹn giao từ " +
      moment(fromDate).format("DD/MM/YY") +
      " đến " +
      moment(toDate).format("DD/MM/YY");
    return (
      fromDate &&
      toDate && (
        <Chip
          className={classes.chips}
          label={label}
          onDelete={() => {
            onChange("shipDateFrom", null);
            onChange("shipDateTo", null);
            fetchBlList();
          }}
        />
      )
    );
  };

  renderCreateTransportDate = () => {
    const { filter, onChange, fetchBlList, classes } = this.props;
    const fromDate = filter.createTransportFrom;
    const toDate = filter.createTransportTo;
    const label =
      "Ngày tạo lộ trình từ " +
      moment(fromDate).format("DD/MM/YY") +
      " đến " +
      moment(toDate).format("DD/MM/YY");
    return (
      fromDate &&
      toDate && (
        <Chip
          className={classes.chips}
          label={label}
          onDelete={() => {
            onChange("createTransportFrom", null);
            onChange("createTransportTo", null);
            fetchBlList();
          }}
        />
      )
    );
  };

  renderExportDate = () => {
    const { filter, onChange, fetchBlList, classes } = this.props;
    const fromDate = filter.exportDateFrom;
    const toDate = filter.exportDateTo;
    const label =
      "Ngày xuất kho từ " +
      moment(fromDate).format("DD/MM/YY") +
      " đến " +
      moment(toDate).format("DD/MM/YY");
    return (
      fromDate &&
      toDate && (
        <Chip
          className={classes.chips}
          label={label}
          onDelete={() => {
            onChange("exportDateFrom", null);
            onChange("exportDateTo", null);
            fetchBlList();
          }}
        />
      )
    );
  };

  renderBlStatus = () => {
    const { filter, onChange, fetchBlList, classes } = this.props;
    const status = filter.status ? filter.status.split(",") : [];
    return (
      status.length > 0 &&
      status.map((s, index) => (
        <Chip
          className={classes.chips}
          key={index}
          label={mapBlStatusText(s)}
          onDelete={() => {
            onChange("status", status.filter((x) => x !== s).toString());
            fetchBlList();
          }}
        />
      ))
    );
  };

  renderShipMethod = () => {
    const { filter, onChange, fetchBlList, classes } = this.props;
    const shipMethods = filter.shipMethod ? filter.shipMethod.split(",") : [];
    return (
      shipMethods.length > 0 &&
      shipMethods.map((s, index) => (
        <Chip
          className={classes.chips}
          key={index}
          label={mapShipMethodToText(s)}
          onDelete={() => {
            onChange(
              "shipMethod",
              shipMethods.filter((x) => x !== s).toString()
            );
            fetchBlList();
          }}
        />
      ))
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.renderRequestTypeFilter()}
        {this.renderKeyword()}
        {this.renderBlStatus()}
        {this.renderShipMethod()}
        {this.renderShipDate()}
        {this.renderCreateTransportDate()}
        {this.renderExportDate()}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(QuickFilterBar);
