import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Popover, Button, CircularProgress } from "@material-ui/core";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";
import { connect } from "react-redux";
import { updateShipDate } from "../../../actions/blActions";
import { formatDateTime } from "@utils";
import { ShipDateChangeHistory } from "../..";
import Select from "react-select";
import { toggleSnackBar } from "@shared";
import { getEditableShipDateOptions } from "@utils/BlUtils";

const styles = () => ({
  "@global": {
    ".react-datetime-picker__wrapper": {
      border: "thin solid #80808078",
      borderRadius: 4,
      padding: 4,
    },
  },
  input: {
    border: "thin solid #80808078",
    borderRadius: 4,
    padding: "6px 4px",
  },
  popoverHeader: {
    fontSize: 18,
    background: "cornflowerblue",
    color: "white",
    padding: "0.5rem",
    borderRadius: "6px 6px 0 0",
    display: "flex",
    alignItems: "center",
  },
  ".react-datetime-picker__calendar": {
    position: "absolute",
  },
  popover: {
    overflow: "visible",
  },
  label: {
    fontWeight: "bold",
    color: "gray",
  },
  icon: {
    cursor: "pointer",
    fontSize: 20,
  },
});

const EditIcon = ({ onClick, className, title = "Cập nhật" }) => (
  <i className={`material-icons ${className}`} title={title} onClick={onClick}>
    edit
  </i>
);

const HistoryIcon = ({ onClick, className, title = "Xem lịch sử" }) => (
  <i className={`material-icons ${className}`} title={title} onClick={onClick}>
    history
  </i>
);

class BlShipDate extends React.Component {
  initialState = {
    ship_date: moment().format("YYYY-MM-DD HH:mm:ss"),
    reason: "",
    confirmAnchorEl: null,
    shipDateHistoryDiaglog: false,
    editShipDate: false,
    anchorEl: null,
    loading: false,
  };
  confirmRef = React.createRef();
  state = this.initialState;

  componentDidMount() {
    const editShipDateOptions = getEditableShipDateOptions(
      this.props.rejectReasons
    );

    this.initialState.reason = editShipDateOptions[0];
    this.setState({ reason: editShipDateOptions[0] });
  }

  handleClose = () => {
    this.setState(this.initialState);
    this.setState({ editShipDate: false });
  };

  handleSave = async () => {
    const { ship_date, reason } = this.state;
    const { bl, changeShipDate, toggleSnackBar } = this.props;
    if (reason) {
      const params = {
        ship_date,
        reason: reason.label,
        reason_id: reason.value,
        bl_id: bl.id,
      };
      this.setState({ loading: true });
      const response = await changeShipDate(params);
      if (response.status === 200) {
        toggleSnackBar(true, "Cập nhật ngày hẹn giao thành công", "success");
        this.setState({ editShipDate: false });
        this.handleClose();
      } else {
        toggleSnackBar(
          true,
          "Có lỗi xảy ra! Cập nhật không thành công.",
          "failed"
        );
      }
    }
    this.setState({ loading: false });
  };

  canEditShipDate = () => {
    const { bl } = this.props;
    return [
      "waiting_for_pick",
      "on_delivering",
      "waiting_for_confirm",
    ].includes(bl.status);
  };

  render() {
    const { classes, bl, rejectReasons } = this.props;
    const {
      ship_date,
      reason,
      anchorEl,
      confirmAnchorEl,
      editShipDate,
      shipDateHistoryDiaglog,
      loading,
    } = this.state;

    const editShipDateOptions = getEditableShipDateOptions(rejectReasons);
    return (
      <React.Fragment>
        <Popover
          open={editShipDate}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          PaperProps={{
            style: {
              overflow: "visible",
              width: "25rem",
              fontFamily: "sans-serif",
              fontSize: 14,
              borderRadius: 5,
            },
          }}
          onClose={() => {
            if (ship_date !== this.initialState.ship_date || reason !== "")
              this.setState({ confirmAnchorEl: this.confirmRef.current });
            else this.handleClose();
          }}
        >
          <div className={classes.popoverHeader}>
            <span style={{ flex: 1 }}>Cập nhật ngày hẹn giao</span>
            <i
              className="material-icons"
              ref={this.confirmRef}
              onClick={(e) => {
                if (
                  ship_date !== this.initialState.ship_date ||
                  reason !== this.initialState.reason
                )
                  this.setState({ confirmAnchorEl: this.confirmRef.current });
                else this.handleClose();
              }}
              style={{ cursor: "pointer" }}
            >
              close
            </i>
          </div>
          <Popover
            open={Boolean(confirmAnchorEl)}
            anchorEl={confirmAnchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            PaperProps={{
              style: {
                fontFamily: "sans-serif",
                fontSize: 14,
                borderRadius: 5,
                padding: "1rem 0.5rem 0.5rem 0.5rem",
              },
            }}
            onClose={() => this.setState({ confirmAnchorEl: null })}
          >
            <div>Dữ liệu chưa được lưu. Bạn có muốn đóng ?</div>
            <br />
            <div style={{ textAlign: "right" }}>
              <Button color="primary" onClick={this.handleClose}>
                Đóng
              </Button>
              <Button onClick={() => this.setState({ confirmAnchorEl: null })}>
                Hủy
              </Button>
            </div>
          </Popover>
          <div style={{ padding: "0.8rem" }}>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <span className={classes.label}>Ngày hẹn giao mới</span>
              </Grid>
              <Grid item>
                <DateTimePicker
                  minDate={
                    new Date(moment().set({ minute: 0, millisecond: 0 }))
                  }
                  value={new Date(ship_date)}
                  clearIcon={null}
                  disableClock={true}
                  onChange={(e) =>
                    this.setState({
                      ship_date: moment(e).format("YYYY-MM-DD HH:mm:ss"),
                    })
                  }
                  locale="vi-VN"
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <span className={classes.label}>Lí do</span>
              </Grid>
              <Grid item md={8}>
                <Select
                  options={editShipDateOptions}
                  value={reason}
                  clearable={false}
                  onChange={(e) => this.setState({ reason: e })}
                />
              </Grid>
              <Grid item>
                {loading ? (
                  <CircularProgress size={20} />
                ) : (
                  <i
                    className="material-icons"
                    onClick={this.handleSave}
                    style={{ cursor: "pointer", color: "dodgerblue" }}
                  >
                    save
                  </i>
                )}
              </Grid>
            </Grid>
          </div>
        </Popover>
        {formatDateTime(bl.shipDate)}&nbsp;
        {this.canEditShipDate() && (
          <EditIcon
            className={classes.icon}
            onClick={(e) =>
              this.setState({
                editShipDate: !editShipDate,
                anchorEl: e.currentTarget,
              })
            }
          />
        )}
        <HistoryIcon
          className={classes.icon}
          onClick={() => this.setState({ shipDateHistoryDiaglog: true })}
        />
        <ShipDateChangeHistory
          open={shipDateHistoryDiaglog}
          onClose={() => this.setState({ shipDateHistoryDiaglog: false })}
          history={bl.ship_dates}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  bl: state.admin.detail.bl,
  rejectReasons: state.admin.shared.rejectReasons,
});

const mapDispatchToProps = (dispatch) => ({
  toggleSnackBar: (state, message, type) =>
    dispatch(toggleSnackBar(state, message, type)),
  changeShipDate: (data) => dispatch(updateShipDate(data)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(BlShipDate)
);
