import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import fetchSuccess from "../../shared/actions/fetchSuccess";
import {
  Grid,
  Typography,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  IconButton,
  CircularProgress,
  LinearProgress,
} from "@material-ui/core";
import {
  fetchBlList,
  changePagination,
  changeEditState,
  updateBlState,
  changeFilter,
} from "../actions";
import { BlStatusCell } from "../../bl_list/components";
import TablePagination from "@material-ui/core/TablePagination";
import _ from "lodash";
//styles
const styles = (theme) => ({
  input: {
    height: 37,
    borderRadius: "4px 0 0 4px",
    width: "30%",
    borderTop: "1px solid #483d8b47",
    borderBottom: "1px solid #483d8b47",
    borderLeft: "1px solid #483d8b47",
    borderRight: "none",
    paddingLeft: 7,
  },
  searchBtn: {
    height: 41,
    color: "white",
    borderRadius: "0 4px 4px 0",
    borderTop: "1px solid #483d8b47",
    borderBottom: "1px solid #483d8b47",
    borderRight: "1px solid #483d8b47",
    borderLeft: "none",
    background: "darkslateblue",
    width: "6rem",
  },
  title: {
    fontFamily: "Noto Sans, sans-serif",
    fontWeight: 800,
    color: "#797979",
    paddingBottom: 16,
  },
  text: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
  },
  paginationSelect: {
    paddingRight: 25,
  },
  "@global": {
    "#bl-state-management > thead > tr > th": {
      color: "#FFFFFF",
      fontSize: 14,
    },
  },
});

class BlStateManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: "",
      cloneBls: [],
      loadingState: [],
      loading: false,
    };
    this.inputRef = null;
  }

  componentDidMount() {
    const { fetchSuccess } = this.props;
    fetchSuccess();
    this.inputRef.focus();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.bls !== nextProps.bls)
      this.setState({
        cloneBls: _.cloneDeep(nextProps.bls),
        loadingState: Array(nextProps.bls.length).fill(false),
      });
  }

  handleChangeBl = (type, index, value) => {
    let newBls = [...this.state.cloneBls];
    newBls[index][type] = value;
    this.setState({ cloneBls: newBls });
  };

  handleSaveBl = async (state, idx) => {
    const { updateBlState, bls } = this.props;
    const { loadingState } = this.state;
    const { cloneBls } = this.state;
    const prevBl = bls[idx];
    if (state) {
      const data = {
        state: cloneBls[idx].state,
        note: cloneBls[idx].note,
      };

      if (prevBl.state !== data.state || prevBl.note !== data.note) {
        let newLoadingState = loadingState;
        newLoadingState[idx] = true;
        this.setState({ loadingState: newLoadingState });
        await updateBlState(cloneBls[idx].id, data);
        //call api then close
        newLoadingState[idx] = false;
        this.setState({ loadingState: newLoadingState });
      }
      this.handleEditState({
        index: idx,
        state: false,
      });
    } else {
      //reset bl state and note to default
      let newBls = [...this.state.cloneBls];
      newBls[idx] = this.props.bls[idx];
      this.setState({ cloneBls: newBls });
      this.handleEditState({
        index: idx,
        state: false,
      });
    }
  };

  renderActionButton = (idx, editState, status) => {
    const { loadingState } = this.state;
    if (["delivered", "returned"].includes(status)) {
      if (editState) {
        return (
          <React.Fragment>
            <IconButton onClick={(e) => this.handleSaveBl(true, idx)}>
              {loadingState[idx] ? (
                <CircularProgress style={{ color: "#E10050" }} size={25} />
              ) : (
                <i className="material-icons">save</i>
              )}
            </IconButton>
            <IconButton onClick={(e) => this.handleSaveBl(false, idx)}>
              <i className="material-icons">cancel</i>
            </IconButton>
          </React.Fragment>
        );
      } else {
        return (
          <IconButton
            onClick={(e) =>
              this.handleEditState({
                index: idx,
                state: true,
              })
            }
          >
            <i className="material-icons">edit</i>
          </IconButton>
        );
      }
    } else {
      return null;
    }
  };

  handleEditState = (state) => {
    const { changeEditState, editState } = this.props;
    let newEditState = [...editState];
    newEditState[state.index] = state.state;
    changeEditState(newEditState);
  };

  render() {
    const {
      classes,
      pagination,
      changePagination,
      editState,
      changeFilter,
      filter,
      tableLoading,
      fetchBlList,
    } = this.props;
    const { cloneBls } = this.state;
    return (
      <div>
        <Typography type="title" className={classes.title}>
          XỬ LÍ ĐƠN SAI TRẠNG THÁI
        </Typography>
        <Grid container className={classes.text}>
          <Grid item md={12}>
            <input
              ref={(input) => {
                this.inputRef = input;
              }}
              className={classes.input}
              value={filter.keyword}
              onChange={(e) => changeFilter({ keyword: e.target.value })}
              placeholder="Tìm theo mã vận đơn, mã yêu cầu, mã đơn hàng"
              onKeyPress={(e) => {
                if (e.charCode === 13) fetchBlList();
              }}
            />
            <button className={classes.searchBtn} onClick={() => fetchBlList()}>
              TÌM
            </button>
          </Grid>
          <Grid item md={12}>
            {tableLoading && <LinearProgress color="accent" />}
            <Table id="bl-state-management" className={classes.text}>
              <TableHead style={{ background: "darkslateblue" }}>
                <TableRow>
                  <TableCell>Mã vận đơn</TableCell>
                  <TableCell>Mã gói hàng</TableCell>
                  <TableCell>Mã đơn hàng</TableCell>
                  <TableCell>Trạng thái</TableCell>
                  <TableCell style={{ textAlign: "center" }}>Ghi chú</TableCell>
                  <TableCell>Thao tác</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ background: "#FFFFFF" }}>
                {cloneBls.length > 0 ? (
                  cloneBls.map((x, idx) => {
                    return (
                      <TableRow key={idx}>
                        <TableCell>
                          <a
                            href={`/van-don/${x.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {x.id}
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            href={`/van-don/${x.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {x.document_id}
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            href={`/van-don/${x.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {x.blOrderId}
                          </a>
                        </TableCell>
                        {!editState[idx] ? (
                          <BlStatusCell id={x.id} status={x.status} bl={x} />
                        ) : (
                          <TableCell>
                            <select
                              value={x.state}
                              onChange={(e) =>
                                this.handleChangeBl(
                                  "state",
                                  idx,
                                  e.target.value
                                )
                              }
                            >
                              <option value="delivered">Giao thành công</option>
                              <option value="returned">Đã hoàn hàng</option>
                            </select>
                          </TableCell>
                        )}
                        <TableCell>
                          {!editState[idx] ? (
                            x.note
                          ) : (
                            <textarea
                              style={{ width: "100%" }}
                              value={x.note}
                              onChange={(e) =>
                                this.handleChangeBl("note", idx, e.target.value)
                              }
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          {this.renderActionButton(
                            idx,
                            editState[idx],
                            x.state
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>Không có dữ liệu</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} trong ${count}`
              }
              style={{ background: "#FFFFFF" }}
              labelRowsPerPage={<span>Số dòng hiển thị</span>}
              rowsPerPageOptions={[10, 30, 50]}
              count={pagination.total_records}
              rowsPerPage={pagination.limit}
              page={pagination.offset / pagination.limit}
              onChangePage={(e, page) =>
                changePagination({ offset: page * pagination.limit })
              }
              onChangeRowsPerPage={(e) =>
                changePagination({ limit: e.target.value })
              }
              classes={{
                select: classes.paginationSelect,
              }}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  bls: state.admin.blManagement.bls,
  pagination: state.admin.blManagement.pagination,
  editState: state.admin.blManagement.editState,
  filter: state.admin.blManagement.filter,
  tableLoading: state.admin.blManagement.tableLoading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSuccess: () => dispatch(fetchSuccess()),
  fetchBlList: (keywords) => dispatch(fetchBlList(keywords)),
  changePagination: (pagination) => dispatch(changePagination(pagination)),
  changeEditState: (state) => dispatch(changeEditState(state)),
  updateBlState: (id, data) => dispatch(updateBlState(id, data)),
  changeFilter: (filter) => dispatch(changeFilter(filter)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(BlStateManagement)
);
