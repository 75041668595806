import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  ProvinceSelector,
  DistrictSelector,
  WardSelector,
  Dialog,
} from "../../../../shared";

const styles = (theme) => ({
  content: {
    width: "100%",
    fontFamily: "Noto Sans, sans-serif",
  },
  notification: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 20,
    color: "#ff0000",
  },
  notificationText: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 16,
    color: "#797979",
  },
  address: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
    color: "#000",
  },
  form: {
    width: "100%",
    margin: "32px 32px 160px 32px", // 160px for form select
  },
  row: {
    height: 48,
    width: "100%",
    margin: "8px 0",
  },
  action: {
    display: "flex",
    justifyContent: "flex-start",
    marginLeft: 16,
  },
  button: {
    marginTop: 4,
  },
});

class AddCustomerAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      province: "",
      district: "",
      ward: "",
      updating: false,
      error: false,
    };
  }

  changeProvince = (province) =>
    this.setState({
      province: province,
      district: "",
      ward: "",
    });

  changeDistrict = (district) =>
    this.setState({
      district: district,
      ward: "",
    });

  changeWard = (ward) =>
    this.setState({
      ward,
    });

  addCustomerAddress = async () => {
    const { ward, district } = this.state;
    const { bl } = this.props;
    this.setState({
      updating: true,
    });

    if (ward) {
      let response = await this.props.addCustomerAddress(bl.id, ward);
      if (!response.error) {
        this.props.confirmAreaCodeAdded();
        window.location.reload();
      }
    } else if (district) {
      let response = await this.props.addCustomerAddress(bl.id, district);
      if (!response.error) {
        this.props.confirmAreaCodeAdded();
        window.location.reload();
      }
    } else {
      this.toggleErrorDialog();
    }
  };

  toggleErrorDialog = () =>
    this.setState({
      error: !this.state.error,
      updating: false,
    });

  render() {
    const { province, district, ward, updating, error } = this.state;
    const { bl, classes } = this.props;
    return (
      <div className={classes.content}>
        <Typography type="subheading" className={classes.notification}>
          *Lưu ý:&nbsp;
          <span className={classes.notificationText}>
            Do hệ thống chưa thể tích hợp được địa chỉ khách hàng, bạn cần phải
            tạo địa chỉ khách hàng trên hệ thống bằng tay trước khi tạo lộ
            trình.
          </span>
          <p className={classes.address}>
            Địa chỉ khách(thực tế): {bl.toAddr.fullAddress}
          </p>
        </Typography>
        <div className={classes.form}>
          <div className={classes.row}>
            <Grid container>
              <Grid item md={3} className={classes.left}>
                <p className={classes.text}>
                  <strong>
                    <span className={classes.mustHave}> * </span>Tỉnh / Thành
                    phố
                  </strong>
                </p>
              </Grid>
              <Grid item md={7} className={classes.right}>
                <ProvinceSelector
                  province={province}
                  changeProvince={this.changeProvince}
                  fetchArea={this.props.fetchArea}
                />
              </Grid>
            </Grid>
          </div>

          <div className={classes.row}>
            <Grid container>
              <Grid item md={3} className={classes.left}>
                <p className={classes.text}>
                  <strong>
                    <span className={classes.mustHave}> * </span>Quận/Huyện
                  </strong>
                </p>
              </Grid>
              <Grid item md={7} className={classes.right}>
                <DistrictSelector
                  province={province}
                  district={district}
                  changeDistrict={this.changeDistrict}
                  fetchArea={this.props.fetchArea}
                />
              </Grid>
            </Grid>
          </div>

          <div className={classes.row}>
            <Grid container>
              <Grid item md={3} className={classes.left}>
                <p className={classes.text}>
                  <strong>
                    <span className={classes.mustHave}> * </span>Phường/Xã
                  </strong>
                </p>
              </Grid>
              <Grid item md={7} className={classes.right}>
                <WardSelector
                  district={district}
                  ward={ward}
                  changeWard={this.changeWard}
                  fetchArea={this.props.fetchArea}
                />
              </Grid>
            </Grid>
          </div>

          <div className={classes.row}>
            <Grid container>
              <Grid item md={3} className={classes.left} />
              <Grid item md={7} className={classes.action}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={this.addCustomerAddress}
                >
                  {updating ? (
                    <CircularProgress size={20} style={{ color: "#fff" }} />
                  ) : (
                    <i className="material-icons">save</i>
                  )}
                  &nbsp;Lưu thông tin
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>

        <Dialog open={error} closeDialog={this.toggleErrorDialog} title="Oops!">
          Bạn cần điền đầy đủ thông tin địa chỉ để tiếp tục!
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(AddCustomerAddress);
