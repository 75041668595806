/* Non-reusable component  */
import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import { GG_API_URL } from "@config";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import memoize from "memoize-one";
import { setMap } from "../actions";

const MyGoogleMap = withScriptjs(
  withGoogleMap((props) => {
    const {
      center,
      destinations,
      defaultZoom = 15,
      zoom,
      mapRef,
      onZoomChanged,
      onCenterChanged,
      onMarkerClick,
    } = props;
    return (
      <GoogleMap
        defaultZoom={defaultZoom}
        center={center}
        zoom={zoom}
        onCenterChanged={onCenterChanged}
        onZoomChanged={onZoomChanged}
        ref={mapRef}
      >
        {destinations &&
          destinations.length > 0 &&
          destinations.map((x, key) => {
            return (
              <Marker
                key={key}
                position={x.location}
                label={x.id.toString()}
                onClick={() => onMarkerClick(x)}
              />
            );
          })}
      </GoogleMap>
    );
  })
);

const styles = (theme) => ({
  map: {
    height: `100%`,
    borderRadius: "1rem",
  },
  paper: {
    padding: "1rem 2rem",
  },
});

class ShippingLocation extends React.Component {
  filter = memoize((data) => {
    const withId = data.map((x, id) => ({ ...x, id: id + 1 }));
    return withId.filter((x) => x.location && x.location.lat);
  });

  render() {
    const { data, map, setMap, classes } = this.props;
    return (
      <Grid container>
        <Grid item xs={12} style={{ paddingBottom: 0 }}>
          <Paper elevation={0} className={classes.paper}>
            <MyGoogleMap
              center={map.center}
              destinations={
                data && data.length === 1 ? this.filter(data[0].actions) : []
              }
              zoom={map.zoom}
              googleMapURL={GG_API_URL}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `380px` }} />}
              mapElement={<div className={`light-box-shadow ${classes.map}`} />}
              mapRef={(ref) => (this.mapRef = ref)}
              onZoomChanged={() => {
                setMap({
                  zoom: this.mapRef.getZoom(),
                });
              }}
              onCenterChanged={() =>
                setMap({
                  center: this.mapRef.getCenter(),
                })
              }
              onMarkerClick={(e) => setMap({ zoom: 18, center: e.location })}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.admin.ShipperDailyReport.data,
  map: state.admin.ShipperDailyReport.map,
});

const mapDispatchToProps = (dispatch) => ({
  setMap: (config) => dispatch(setMap(config)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ShippingLocation)
);
