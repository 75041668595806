import apiService from "./base";

export const getBusLines = async (data) => {
  return apiService({
    url: "/system/bus_lines",
    method: "get",
    params: data,
  });
};

export const getDeliveryTypes = async () => {
  return apiService({
    url: "/system/lo_delivery_types",
    method: "get",
  });
};

export const getRejectReasonsByType = async (type) => {
  return apiService({
    url: "/system/rejection_reasons",
    method: "get",
    params: { type: type },
  });
};

export const getAreaListApi = async () => {
  return apiService({
    url: "/thirdparty/areas",
    method: "get",
  });
};

export const dumpSystemApi = null;
