import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Dialog } from "@material-ui/core";
import { ASSETS_URL } from "@config";

const styles = (theme) => ({
  text: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
    color: "#797979",
  },
});

const GHNBill = ({ data, classes }) => {
  const ghnAssets = `${ASSETS_URL}/assets/GHN`;
  return (
    <React.Fragment>
      <Dialog open={false}>
        <div className="info-user">
          <div className="row">
            <div className="col-6">
              <p>
                <span>
                  Người <b>GỬI</b>:
                </span>
                <span id="ClientContactName">{data.ClientContactName}</span>
              </p>
              <p>
                <span id="ClientContactPhone">{data.ClientContactPhone}</span>
              </p>
              <p>
                <span id="ClientAddress">{data.ClientAddress}</span>
              </p>
            </div>
            <div className="col-5">
              <p>
                <span>
                  Người <b>NHẬN</b>:{" "}
                </span>
                <span id="CustomerName">{data.CustomerName}</span>
              </p>
              <p>
                <span id="CustomerPhone">{data.CustomerPhone}</span>
              </p>
              <p>
                <span id="ShippingAddress">{data.ShippingAddress}</span>
              </p>
            </div>
            <div className="col-1">
              <img src="./generate" alt="" />
            </div>
          </div>
        </div>
        <div className="info-packet">
          <div className="row">
            <div className="col-5">
              <div
                className="toAddress"
                flowtype=""
                min-font="10"
                max-font="12"
                line-ratio="1.45"
                minimum="300"
                maximum="450"
              >
                <h1 style={{ marginTop: 0 }}>
                  <span style={{ fontSize: 10 }}>Tuyến giao</span>
                  <b id="ToWardName" style={{ paddingBottom: 10 }} />
                  <b id="ToDistrictName">
                    <span
                      style={{
                        border: "solid 4px #000",
                        padding: "5px 5px",
                        fontSize: "inherit",
                      }}
                    >
                      Quận Gò Vấp
                    </span>
                  </b>
                </h1>
              </div>
            </div>
            <div className="col-4 hbarcode center">
              <img
                ng-src="https://barcode.ghn.vn/api/v1/barcode/generate?code=EARX79X3Q&amp;type=C128A&amp;width=250&amp;height=50"
                src="./generate(1)"
              />
              <p
                id="OrderCode"
                flowtype=""
                min-font="25"
                max-font="30"
                maximum="450"
              >
                {data.OrderCode}
              </p>
            </div>
            <div className="col-3 info-packet-service">
              <div>
                <p id="Sorting">{data.Sorting}</p>
              </div>
              <div>
                <label>Ngày giao hàng</label>
                <p id="Sorting">19</p>
              </div>
            </div>
          </div>
        </div>
        <div className="info-hub">
          <div className="row">
            <div className="col-5">
              <ul className="hul">
                <li>
                  <span>Giao</span>
                  <img src={`${ghnAssets}/print-checkbox.png`} atl="" />
                  <span>Trả</span>
                  <img src={`${ghnAssets}/print-checkbox.png`} atl="" />
                </li>
                <li style={{ height: 90 }}>
                  <p id="Note">
                    <strong>Ghi chú KH:</strong> {data.Note}
                  </p>
                </li>
                <li>
                  <img
                    src={`${ghnAssets}/signature.png`}
                    alt=""
                    width="170px"
                  />
                </li>
              </ul>
            </div>
            <div className="col-3">
              <ul className="hul" style={{ textAlign: "center" }}>
                <li>
                  <span>
                    <b id="SelectedNoteContent">{data.SelectedNoteContent}</b>
                  </span>
                </li>
                <li>
                  <span>Ngày gửi:</span>
                  <h2 id="CreatedDateS">{data.CreatedDateS}</h2>
                </li>
              </ul>
            </div>
            <div className="col-4">
              <div className="ads">
                <h1 className="center">TUYỂN DỤNG</h1>
                <h4 className="ng-binding">
                  Thường xuyên tuyển dụng nhân viên giao nhận thu nhập hấp dẫn
                  tại: Quận Gò Vấp, Hồ Chí Minh
                </h4>
                <p>
                  Truy cập <b>https://ghn.vn</b> mục <b>tuyển dụng</b>
                  <br />
                  Đăng ký tại : http://bit.ly/TDGHN2018{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default withStyles(styles)(GHNBill);
