import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { ShipperDailyReportFilter, ShipperDailyReportBody } from "./components";
import { setDefaultCenter } from "./actions";

//styles
const styles = (theme) => ({
  text: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
  },
});

class ShipperDailyReport extends React.Component {
  componentDidMount() {
    const { setDefaultCenter } = this.props;
    setDefaultCenter();
  }
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={12} className={classes.text}>
            <ShipperDailyReportFilter />
          </Grid>
        </Grid>
        <ShipperDailyReportBody />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setDefaultCenter: () => dispatch(setDefaultCenter()),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ShipperDailyReport)
);
