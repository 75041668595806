import React from "react";
import Select from "react-select";

class BusLineSelection extends React.Component {
  render() {
    const { value, options, onChange } = this.props;
    const _options = options.map((x) => ({
      label: x.name,
      value: x.id,
    }));

    return (
      <Select
        value={value}
        onChange={(v) => onChange(v ? v.value : "")}
        options={_options}
        placeholder="Chọn nhà xe..."
        clearable={false}
        style={{ height: 40 }}
      />
    );
  }
}

export default BusLineSelection;
