import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  noteCell: {
    maxHeight: "8rem",
    overflow: "auto",
    wordBreak: "break-word",
    whiteSpace: "normal",
    "&::-webkit-scrollbar": {
      width: "0.4em",
      height: "0.4rem",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
});

const BlNoteCell = ({ note, classes }) => {
  return <div className={classes.noteCell}>{note}</div>;
};

export default withStyles(styles)(BlNoteCell);
