import React from "react";
import { Stepper, Step, StepLabel, StepContent } from "@material-ui/core";
import { connect } from "react-redux";
import {
  Dialog,
  ImageDialog,
  getEstimateFee,
  WeightInput,
  SizeInput,
  InventoryCount,
} from "@shared";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import Label from "./Label";
import TransportAddress from "./TransportAddress";
import TransportLines from "./TransportLines";
import { TransportActionContainer } from "../../containers";
import {
  mapTransportStateColor,
  mapTransportStateToText,
  validateUtils,
  mapShipperState,
} from "@utils";
import { AddressType, ShipMethod, TransportState } from "../../../../../models";
import TransportShipMethod from "./TransportShipMethod";
import TransportBlSamePlref from "./TransportBlSamePlref";
import {
  updateMeasurement,
  updateKtvTimeKeeping,
  fetchBlDetail,
} from "../../actions";
import {
  measurementValidationRules,
  measurementValidationKtvRules,
  measurementValidationMsg,
} from "./../../../utils/getErrorMessage";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { CircularProgress } from "@material-ui/core";
import _ from "lodash";

//styles
const styles = (theme) => ({
  stepper: {
    display: "flex",
    justifyContent: "flex-start",
    marginLeft: 48,
  },
  step: {
    borderRadius: 2,
    cursor: "pointer",
  },
  stepLabel: {
    color: "#fff",
    fontSize: 12,
    fontWeight: 800,
    padding: 4,
    borderRadius: 4,
  },
  text: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
    color: "#797979",
  },
  note: {
    display: "block",
    whiteSpace: "pre-wrap",
    paddingLeft: 16,
    overflowWrap: "break-word",
  },
  label: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 16,
    fontWeight: 700,
    color: "#797979",
  },
  measureLabel: {
    alignSelf: "center",
  },
  mustHave: {
    color: "#ff0000",
    fontSize: 10,
  },
  imgContainer: {
    margin: "0.5rem",
  },
  handler: {
    cursor: "pointer",
    color: "#365899",
  },
  shipperlabel: {
    fontSize: 12,
    padding: 4,
    borderRadius: 4,
    fontWeight: 600,
    marginLeft: 5,
    display: "inline-block",
  },
});

class TransportList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {
        hasError: false,
        msg: [],
      },
      measurement: this.props.transports.map((x) => ({
        weight: x.package_info.weight,
        height: x.package_info.height,
        width: x.package_info.width,
        length: x.package_info.length,
        inventory_count: x.package_info.inventory_count,
        vehicle_gas_cost: x.vehicle_gas_cost,
        estimate_fee: x.estimate_fee,
        updateSuccess: false,
        loading: false,
      })),
      showImg: false,
      imgUrl: "",
    };
  }

  toggleFormErrorDialog = (errors = []) =>
    this.setState({
      error: {
        hasError: !this.state.error.hasError,
        msg: errors,
      },
    });

  async changeValue(index, measurement) {
    let newState = this.state.measurement;
    newState[index] = {
      ...newState[index],
      ...measurement,
    };
    const { transports } = this.props;
    const selected = transports[index];
    const data = {
      ...newState[index],
      ship_method: selected.shipMethod,
      hub_id:
        selected.toAddress.objId && selected.toAddress.type === "hub"
          ? selected.toAddress.objId
          : null,
      service_id: selected.service_id,
      ...measurement,
    };

    if (selected.shipMethod !== "ktv") {
      let result = await getEstimateFee(selected.id, data);
      newState[index].estimate_fee =
        result.data.data && result.data.data.fee ? result.data.data.fee : null;
    }

    this.setState({ measurement: newState });
  }

  mapNameKTV = (allKtvList, id, label) => {
    let result = allKtvList.find((element) => {
      return element.value === id;
    });
    if (result) {
      return result.name;
    } else {
      return label;
    }
  };

  async validate(transportId, index) {
    const { measurement } = this.state;
    const { updateMeasurement, transports, fetchBlDetail, bl } = this.props;
    const newMeasurement = _.cloneDeep(measurement);
    let errMsg = [];
    const validateRules =
      transports[index].shipMethod !== ShipMethod.ktv
        ? measurementValidationRules
        : measurementValidationKtvRules;
    errMsg = validateUtils(
      measurement[index],
      validateRules,
      measurementValidationMsg
    );

    if (errMsg.length > 0) {
      this.toggleFormErrorDialog(errMsg);
      return;
    } else {
      newMeasurement[index].loading = true;
      this.setState({ measurement: newMeasurement });
      const wait = (ms) => new Promise((r, j) => setTimeout(r, ms));
      await wait(1000);
      const data = _.cloneDeep(measurement[index]);
      newMeasurement[index].loading = false;
      this.setState({ measurement: newMeasurement });
      if (!data.estimate_fee || data.estimate_fee <= 0) {
        delete data.estimate_fee;
      }
      let result = await updateMeasurement(transportId, data);
      this.setState({ loading: false });
      if (result.code === 200) {
        newMeasurement[index].updateSuccess = true;
        this.setState({ measurement: newMeasurement });
        await wait(1000);
        newMeasurement[index].updateSuccess = false;
        this.setState({ measurement: newMeasurement });
        await fetchBlDetail(bl.id);
      }
    }
  }

  handleErrorMsg() {
    const { error } = this.state;
    let errMsg = [];
    if (error.msg.length > 0) {
      error.msg.forEach((element, index) => {
        errMsg.push(<span key={`error-${index}`}>- {element}</span>);
        errMsg.push(<br key={`error-br-${index}`} />);
      });
    }
    return errMsg;
  }

  showImage(url) {
    this.setState({
      showImg: true,
      imgUrl: url,
    });
  }

  getThreePlsService = (ship_method) => {
    const { threePls } = this.props;
    const selected = threePls.filter((x) => x.code_name === ship_method);
    return selected.length > 0 ? selected[0].services : [];
  };

  renderKtvStatus = (transport) => {
    const { classes } = this.props;
    if (transport.shipMethod === "ktv") {
      const shipperState = mapShipperState(
        transport.shipper_state,
        transport.state
      );
      if (shipperState)
        return (
          <div
            className={`${classes.shipperlabel}`}
            style={{
              background: shipperState.background,
              color: shipperState.color,
            }}
          >
            {shipperState.label}
          </div>
        );
    }
  };

  getServiceLabel = (ship_method, service_id) => {
    const { threePls } = this.props;
    const selected3Pls = threePls.filter((x) => x.code_name === ship_method);
    if (selected3Pls.length === 0) return ship_method;
    const service_list = selected3Pls[0].services;
    const selected = service_list.filter((x) => x.service_code === service_id);
    return selected.length > 0 ? selected[0].service_name : service_id;
  };

  canShowNewEstimateFee = (state, props) => {
    return (
      props.shipMethod !== "ktv" &&
      (state.weight !== props.weight ||
        state.height !== props.height ||
        state.width !== props.width ||
        state.length !== props.length)
    );
  };

  componentDidMount = () => {
    const { transports, changeOpenPlref } = this.props;
    if (transports) {
      changeOpenPlref(transports.length - 1);
    }
  };

  render() {
    const {
      step,
      transports,
      changeStep,
      classes,
      type,
      allKtvList,
      updateTimeKeeping,
      openPlref,
      bl,
      dataPlref = [],
      changeOpenPlref,
    } = this.props;
    const { measurement, error } = this.state;
    const lastTransport = transports[transports.length - 1];
    return (
      <div>
        <Stepper activeStep={step} orientation="vertical">
          {transports.map((transport, index) => {
            return (
              <Step key={index}>
                <StepLabel
                  onClick={() => {
                    changeStep(index);
                    changeOpenPlref(index);
                  }}
                  icon={
                    <i className="material-icons">
                      {transport.fromAddress.type === "hub" ? "home" : "person"}
                    </i>
                  }
                  className={classes.step}
                >
                  <Label
                    mapNameKTV={this.mapNameKTV}
                    allKtvList={allKtvList}
                    transport={transport}
                    type={type}
                  />
                </StepLabel>

                <StepContent
                  classes={{ transition: classes.entered }}
                  className={classes.text}
                >
                  <p>
                    <strong>
                      {transport.shipMethod === ShipMethod.nv_phongvu
                        ? "Mã nhân viên"
                        : "Mã vận chuyển"}
                      :{" "}
                    </strong>
                    {decodeURIComponent(transport.plRef)}
                    {". "}
                    {dataPlref && dataPlref.length > 1 && (
                      <span
                        className={classes.handler}
                        onClick={() => {
                          changeOpenPlref(index);
                        }}
                      >
                        <strong>Xem danh sách các đơn có cùng mã CPN</strong>
                      </span>
                    )}
                  </p>

                  <TransportBlSamePlref
                    openCollapse={index === openPlref}
                    id={bl.id}
                  />

                  <TransportShipMethod
                    transport={transport}
                    updateTimeKeeping={(list) =>
                      updateTimeKeeping(transport.id, list)
                    }
                  />

                  <Grid container spacing={1}>
                    <Grid item className={classes.measureLabel}>
                      <strong>Số kiện:</strong>
                    </Grid>
                    <Grid item>
                      <InventoryCount
                        inventoryCount={measurement[index].inventory_count}
                        onChange={(e) =>
                          this.changeValue(index, {
                            inventory_count: e,
                          })
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={1}>
                    <Grid item className={classes.measureLabel}>
                      <strong>Khối lượng:</strong>
                    </Grid>
                    <WeightInput
                      weight={measurement[index].weight}
                      onChange={(e) => this.changeValue(index, { weight: e })}
                    />
                  </Grid>

                  <Grid container alignItems="center" spacing={1}>
                    <Grid item className={classes.measureLabel}>
                      <strong>Kích thước:</strong>
                    </Grid>
                    <Grid item>
                      <SizeInput
                        height={measurement[index].height}
                        width={measurement[index].width}
                        length={measurement[index].length}
                        onChange={(e) => this.changeValue(index, e)}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          background: measurement[index].updateSuccess
                            ? "green"
                            : "#3F51B5",
                        }}
                        onClick={() => this.validate(transport.id, index)}
                      >
                        {measurement[index].loading && (
                          <CircularProgress
                            style={{ color: "hotpink" }}
                            size={20}
                          />
                        )}
                        {measurement[index].updateSuccess && (
                          <i
                            className="material-icons"
                            style={{ fontSize: 16 }}
                          >
                            check
                          </i>
                        )}
                        &nbsp;Cập nhật
                      </Button>
                    </Grid>

                    {/* this.canShowNewEstimateFee(measurement, transport) && (
                    <Grid item>
                      (Chi phí ước lượng mới:{" "}
                      {measurement[index].estimate_fee &&
                      measurement[index].estimate_fee > 0
                        ? `${measurement[index].estimate_fee.toLocaleString(
                            "en-US"
                          )}đ`
                        : "Không thể tạm tính"})
                    </Grid>
                  ) */}
                  </Grid>

                  {transport.shipMethod === ShipMethod.ktv && (
                    <div>
                      <strong>Hỗ trợ phí xăng xe:</strong>
                      &nbsp;&nbsp;
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={measurement[index].vehicle_gas_cost}
                            onChange={(e) =>
                              this.changeValue(index, {
                                vehicle_gas_cost: e.target.checked,
                              })
                            }
                            color="primary"
                          />
                        }
                      />
                    </div>
                  )}

                  {transport.shipMethod !== "ktv" &&
                    this.getThreePlsService(transport.shipMethod).length >
                      0 && (
                      <p>
                        <strong>Loại dịch vụ:&nbsp;</strong>
                        <span>
                          {this.getServiceLabel(
                            transport.shipMethod,
                            transport.service_id
                          )}
                        </span>
                      </p>
                    )}

                  <p>
                    <strong>Trạng thái: </strong>
                    <span
                      className={classes.stepLabel}
                      style={{
                        backgroundColor: mapTransportStateColor(
                          transport.display_state
                        ),
                      }}
                    >
                      {mapTransportStateToText(transport.display_state)}
                    </span>
                    {this.renderKtvStatus(transport)}
                  </p>

                  {transport.shipper_state ===
                    "waiting_for_being_cancelled" && (
                    <p>
                      <strong>Yêu cầu hủy lộ trình:&nbsp;</strong>
                      {transport.cancelled_reason}
                    </p>
                  )}

                  <TransportAddress transport={transport} />

                  {transport.shipMethod === "ktv" ? (
                    <React.Fragment>
                      <p className={classes.text}>
                        <strong>Khoảng cách theo vị trí đơn:</strong>
                        &nbsp;{" "}
                        {transport.bl_distance ? transport.bl_distance : "-"} km
                      </p>
                      <p className={classes.text}>
                        <strong>Khoảng cách theo vị trí thao tác:&nbsp;</strong>
                        {transport.real_distance && transport.real_distance > 0
                          ? transport.real_distance
                          : "-"}{" "}
                        km
                      </p>
                    </React.Fragment>
                  ) : (
                    <p className={classes.text}>
                      <strong>Khoảng cách theo vị trí đơn:</strong>
                      &nbsp; {transport.distance ? transport.distance : "-"} km
                    </p>
                  )}

                  {![
                    TransportState.returning,
                    TransportState.returned,
                  ].includes(transport.state) && (
                    <p>
                      <strong>Thời gian giao dự kiến:</strong>
                      &nbsp; {transport.edt}
                    </p>
                  )}

                  {transport.reason && (
                    <p>
                      <strong>Lý do hoàn hàng: </strong>
                      &nbsp;{transport.reason}
                    </p>
                  )}

                  <p>
                    <strong>Ngày tạo lộ trình:</strong>
                    &nbsp; {transport.createdAt}
                  </p>

                  {transport.leavedHub && (
                    <p>
                      <strong>Thời điểm bắt đầu lộ trình:</strong>
                      &nbsp; {transport.startedTime}
                    </p>
                  )}

                  {transport.completed && (
                    <p>
                      <strong>Thời điểm kết thúc lộ trình:</strong>
                      &nbsp; {transport.endedTime}
                    </p>
                  )}

                  {transport.deliveredToCustomer && (
                    <p>
                      <strong>COD đã thu:</strong>
                      &nbsp; {transport.formattedPaidCod}
                    </p>
                  )}

                  <p>
                    <strong>Người tạo:</strong>
                    &nbsp; {transport.createdByUser.name}
                  </p>

                  <TransportLines transport={transport} />

                  {transport.note && (
                    <p>
                      <strong>Ghi chú:</strong>
                      <br />
                      <span className={classes.note}>{transport.note}</span>
                    </p>
                  )}

                  {transport.imageUrl && (
                    <p>
                      <strong>Hình ảnh:</strong>
                      <br />
                      <div className={classes.imgContainer}>
                        <img
                          src={transport.imageUrl}
                          width="50"
                          height="50"
                          style={{ cursor: "pointer" }}
                          onClick={() => this.showImage(transport.imageUrl)}
                          title="Click vào để phóng to"
                        />
                      </div>
                    </p>
                  )}

                  {index === transports.length - 1 && (
                    <TransportActionContainer />
                  )}
                </StepContent>
              </Step>
            );
          })}
          <Step key={transports.length}>
            <StepLabel
              icon={
                <i className="material-icons">
                  {lastTransport.toAddress.type === "hub" ? "home" : "person"}
                </i>
              }
              className={classes.step}
            >
              <span
                className={classes.label}
                style={{
                  color: mapTransportStateColor(lastTransport.display_state),
                }}
              >
                <strong>
                  {lastTransport.toAddress.type === AddressType.customer
                    ? type.toLowerCase() === "wh_transfer"
                      ? lastTransport.toAddress.displayName
                      : `Khách hàng ${lastTransport.toAddress.displayName}`
                    : lastTransport.toAddress.type === AddressType.ktv
                    ? `KTV ${this.mapNameKTV(
                        allKtvList,
                        lastTransport.toAddress.objId,
                        lastTransport.toAddress.displayName
                      )}`
                    : lastTransport.toAddress.displayName}
                </strong>
              </span>
            </StepLabel>
          </Step>
        </Stepper>
        <Dialog
          open={error.hasError}
          title="Có lỗi xảy ra"
          closeDialog={this.toggleFormErrorDialog}
        >
          {this.handleErrorMsg()}
          <br />
        </Dialog>
        <ImageDialog
          open={this.state.showImg}
          closeDialog={() => this.setState({ showImg: false })}
          url={this.state.imgUrl}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dataPlref: state.admin.detail.dataPlref,
  threePls: state.admin.shared.allThreePls,
  allKtvList: state.admin.shared.allKtvList,
});

const mapDispatchToProps = (dispatch) => ({
  updateMeasurement: (id, data) => dispatch(updateMeasurement(id, data)),
  updateTimeKeeping: (transport_id, ktv_list) =>
    dispatch(updateKtvTimeKeeping(transport_id, ktv_list)),
  fetchBlDetail: (id) => dispatch(fetchBlDetail(id)),
});
export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(TransportList)
);
