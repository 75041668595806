const _initialState = {
  asia_branches: [],
};

const Report = (state = _initialState, action) => {
  switch (action.type) {
    case "REPORT_SET_ASIA_BRANCHES":
      return {
        ...state,
        asia_branches: action.payload,
      };
    default:
      return state;
  }
};

export default Report;
