import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import NumberFormat from "react-number-format";

const styles = (theme) => ({
  input: {
    height: 34,
    border: "1px solid #ccc",
    borderRadius: 4,
    paddingLeft: 8,
    width: "5rem",
  },
  container: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
  },
});

const InventoryCount = ({ inventoryCount, onChange, classes }) => (
  <Grid item>
    <NumberFormat
      value={inventoryCount}
      thousandSeparator={true}
      className={classes.input}
      onValueChange={(e) => onChange(e.value)}
    />
  </Grid>
);

export default withStyles(styles)(InventoryCount);
