import React from "react";
import {
  fetchError,
  changeBlShipDate,
  changeBlDeliveryTypes,
  getBlHistory,
  toggleSnackBar,
} from "@shared";
import fetchBlDetail from "./fetchBlDetail";
import {
  updateWmsStatusApi,
  cancelBlApi,
  changeBlFollowerApi,
  changeBlInvoiceIdApi,
  getShipperListByBlApi,
  updateBlCoordinatorNoteApi,
} from "@api";

export const cancelBl = (id, reason) => async (dispatch, getState) => {
  const response = await cancelBlApi(id, reason);
  const result = response.data;
  if (response.status === 200) {
    if (result) {
      window.location.reload();
    } else {
      dispatch(fetchError(response.status, result.message));
    }
  } else {
    dispatch(
      fetchError(
        response.status,
        result && result.message ? result.message : ""
      )
    );
  }
};

const btnLoading = (state) => ({
  type: "BTN_LOADING",
  payload: state,
});

export const changeBlFollower = (blId, ktv) => async (dispatch) => {
  dispatch(btnLoading(true));
  const response = await changeBlFollowerApi(blId, ktv ? ktv.value : "");
  const result = response.data;
  dispatch(btnLoading(false));

  if (response.status === 200) {
    if (result) {
      dispatch(changeBlFollowerSuccess(ktv));
    } else {
      dispatch(
        fetchError(
          response.status,
          "Có lỗi xảy ra! Vui lòng liên hệ Logistic team để được hỗ trợ!"
        )
      );
    }
  } else {
    dispatch(fetchError(response.status));
  }
};

export const changeBlFollowerSuccess = (ktv) => ({
  type: "CHANGE_BL_FOLLOWER_SUCCESS",
  payload: ktv,
});

export const changeInvoiceId = (blId, invoice) => async (dispatch) => {
  const response = await changeBlInvoiceIdApi(blId, invoice);

  const result = response.data;
  if (response.status === 200) {
    if (result) {
      dispatch(
        toggleSnackBar(
          true,
          <span>
            Cập nhật <strong>số hóa đơn</strong> thành công
          </span>,
          "success"
        )
      );
      dispatch(fetchBlDetail(blId));
    } else {
      dispatch(
        fetchError(
          response.status,
          "Có lỗi xảy ra! Vui lòng liên hệ Logistic team để được hỗ trợ!"
        )
      );
    }
  } else {
    dispatch(fetchError(response.status));
  }
};

export const getShipperList = (bl_id, hub_id) => async (dispatch) => {
  const response = await getShipperListByBlApi(bl_id, hub_id);
  const result = response.data;

  if (response.status === 200) {
    if (result) {
      let ktvs = result.data.ktv_list.map((ktv) => {
        const asiaId = ktv?.asia_id?.toString().padStart(4, 0);

        return {
          value: ktv?.id,
          name: ktv?.name,
          label:
            [asiaId, ktv?.name, ktv?.phone].filter(Boolean).join(" - ") ||
            ktv?.email,
          areaCode: ktv?.area_code,
          fullAddress: ktv?.full_address,
          phone: ktv?.phone,
          status: ktv?.status,
          roleId: ktv?.role_id,
        };
      });
      dispatch(fetchShipperListSuccess(ktvs));
    } else {
      dispatch(
        fetchError(
          response.status,
          "Có lỗi xảy ra! Vui lòng liên hệ Logistic team để được hỗ trợ!"
        )
      );
    }
  } else {
    dispatch(fetchError(response.status));
  }
};

const fetchShipperListSuccess = (shipperList) => ({
  type: "FETCH_SHIPPER_LIST_SUCCESS",
  payload: shipperList,
});

export const updateCoordinatorNote = (bl_id, note) => async (dispatch) => {
  dispatch(setCoordinatorNoteLoading(true));
  const response = await updateBlCoordinatorNoteApi(bl_id, note.trim());
  const result = response.data;

  if (response.status === 200) {
    if (result) {
      dispatch(fetchBlDetail(bl_id));
    } else {
      dispatch(fetchError(response.status, result.message));
    }
  } else {
    dispatch(fetchError(response.status, result.message));
  }
  dispatch(setCoordinatorNoteLoading(false));
  return result;
};

export const updateWmsStatus = (bl_id, wms_status) => async (dispatch) => {
  const response = await updateWmsStatusApi(bl_id, wms_status);
  const result = response.data;
  if (response.status === 200) {
    if (result) {
      dispatch(fetchBlDetail(bl_id));
    } else {
      dispatch(fetchError(response.status, result.message));
    }
  } else {
    dispatch(fetchError(response.status, result.message));
    dispatch(fetchBlDetail(bl_id));
  }
  return result;
};

const setCoordinatorNoteLoading = (state) => ({
  type: "UPDATE_COORDINATOR_NOTE_LOADING",
  payload: state,
});

export const updateReceiverAddress =
  (response, address) => async (dispatch, getState) => {
    const result = response.data;
    const state = getState().admin.detail;
    const id = state.bl.id;
    if (response.status === 200) {
      dispatch(fetchBlDetail(id));
    } else {
      dispatch(fetchError(response.status, result.message));
    }
    return result;
  };

export const updateShipDate = (data) => async (dispatch) => {
  const params = {
    reason: data.reason,
    reason_id: data.reason_id,
    ship_date: data.ship_date,
  };

  const response = await changeBlShipDate(data.bl_id, params);
  const result = response.data;
  if (response.status === 200) {
    if (result) {
      dispatch(fetchBlDetail(data.bl_id));
    }
  }
  return response;
};

export const updateDeliveryType = (id, delivery_type) => async (dispatch) => {
  const response = await changeBlDeliveryTypes(id, delivery_type);
  const result = response.data;
  if (response.status === 200) {
    if (result) {
      dispatch(fetchBlDetail(id));
    }
  }
  return response;
};

export const fetchBlHistory = (id) => async (dispatch) => {
  const response = await getBlHistory(id);
  const result = response.data;
  if (response.status === 200) {
    dispatch(fetchBlHistorySuccess(result.data.Logs));
  }
};

const fetchBlHistorySuccess = (data) => ({
  type: "BL_DETAIL_FETCH_HISTORY_SUCCESS",
  payload: data,
});

export const updateAddress = (data) => {
  return {
    type: "UPDATE_ADDRESS",
    payload: data,
  };
};
