import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";

const EnvironmentMap = {
  local: "LOCAL",
  dev: "DEV",
  stag: "STAG",
  prod: "PROD",
  test: "TEST",
};

function MapEnvToClassName(env) {
  const dict = {
    local: "devTool",
    dev: "devTool",
    test: "devTool",
    stag: "devToolStag",
    prod: "",
  };
  return dict[env];
}

const useStyles = makeStyles({
  label: {
    width: 120,
    height: 120,
    position: "fixed",
    right: -60,
    bottom: -60,
    zIndex: 9000,
    transform: "rotate(-45deg)",
    boxSizing: "border-box",
    textShadow: "1px 1px 1px rgba(0,0,0,.5)",
    paddingTop: 10,
    color: "#fff",
    textAlign: "center",
  },
  devTool: {
    background: "rgba(155,0,0,.5)",
  },
  devToolStag: {
    background: "#9b59b6",
  },
});

function EnvLabel() {
  const classes = useStyles();
  const env = (
    window.ENV_MODE ? window.ENV_MODE.toLowerCase() : "test-1"
  ).replace(/[\W\d_]/g, "");
  const visible = env !== "prod";
  return visible ? (
    <div className={classnames(classes.label, classes[MapEnvToClassName(env)])}>
      {EnvironmentMap[env]}
    </div>
  ) : (
    ""
  );
}

export default EnvLabel;
