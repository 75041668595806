import { getBusLinesV2 } from "../shared";
import _ from "lodash";

export const setBusLinesList = (data) => ({
  type: "SET_BUS_LINES_LIST",
  payload: {
    bus_lines: data.bus_lines,
    total_records: data.total_records,
  },
});

export const getBusLinesList = () => async (dispatch, getState) => {
  const state = getState().admin.busLineManagement;
  const data = _.cloneDeep(state.filter);
  dispatch(changeTableLoading(true));
  data.region = data.region.toString();
  const response = await getBusLinesV2(data);
  dispatch(changeTableLoading(false));
  const result = response.data;
  if (response.status === 200) {
    dispatch(setBusLinesList(result.data));
  }
};

export const changeFilter = (obj) => ({
  type: "BUS_LINES_CHANGE_FILTER",
  payload: obj,
});

export const changeTableLoading = (state) => ({
  type: "BUS_LINE_CHANGE_TABLE_LOADING",
  payload: state,
});

export const toggleBusLineDialog = (state, selected) => ({
  type: "TOGGLE_BUS_LINE_DIALOG",
  payload: {
    open: state,
    selected: selected,
  },
});
