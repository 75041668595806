import React from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

import {
  mapBlStatusColor,
  mapBlStatusText,
  mapShipMethodToText,
} from "../../utils";
import { withStyles } from "@material-ui/core/styles";

const _renderBatchActionTitle = (action) => {
  switch (action) {
    case "confirmOK":
      return "Xác nhận vận chuyển thành công";
    case "confirmImport":
      return "Xác nhận nhập hàng vào kho";
    case "confirmPicked":
      return "Xác nhận giao hàng cho 3PLs";
    case "confirmReturn":
      return "Xác nhận hoàn hàng";
    case "confirmStockRequest":
      return "Gửi yêu cầu lấy hàng";
    default:
      return "";
  }
};

const _renderBatchActionText = (action) => {
  switch (action) {
    case "confirmOK":
      return (
        <span>
          Bạn muốn <strong>xác nhận vận chuyển thành công</strong> cho các vận
          đơn đã chọn?
        </span>
      );
    case "confirmImport":
      return (
        <span>
          Bạn muốn <strong>xác nhận nhập hàng vào kho</strong> thành công cho
          các vận đơn đã chọn?
        </span>
      );
    case "confirmPicked":
      return (
        <span>
          Bạn muốn <strong>xác nhận giao hàng cho 3PLs</strong> thành công cho
          các vận đơn đã chọn?
        </span>
      );
    case "confirmReturn":
      return (
        <span>
          Bạn muốn <strong>xác nhận hoàn hàng</strong> cho các vận đơn đã chọn?
        </span>
      );
    case "confirmStockRequest":
      return (
        <span>
          Bạn muốn <strong>gửi yêu cầu lấy hàng</strong> cho các vận đơn đã
          chọn?
        </span>
      );
    default:
      return "";
  }
};

const styles = (theme) => ({
  waitingForConfirm: {
    fontSize: 11,
    fontWeight: 800,
    padding: 4,
    borderRadius: 4,
    color: "#797979",
  },
  returned: {
    fontSize: 11,
    fontWeight: 800,
    padding: 4,
    borderRadius: 4,
    color: "#fff",
  },
  others: {
    fontSize: 11,
    fontWeight: 800,
    padding: 4,
    borderRadius: 4,
    color: "#fff",
  },
  hubTitle: {
    marginBottom: "0.2rem",
  },
});
class BatchDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bls: [],
      collapseRows: [],
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.groupedBls !== this.props.groupedBls) {
      let bls = [];
      for (let page in nextProps.groupedBls) {
        let pageBls = nextProps.groupedBls[page].map((bl) => {
          bl.page = page;
          return bl;
        });
        bls = bls.concat(pageBls);
      }

      this.setState(
        {
          bls: bls,
        },
        () => {
          if (this.state.bls.length === 0 && this.props.openBatchDialog) {
            this.props.toggleBatchDialog("close");
          }
          let collapseRows = [];
          for (let i = 0; i < this.state.bls.length; i++) {
            collapseRows.push(false);
          }
        }
      );
    }
  }

  toggleCollapse = (id) => {
    let collapseRows = this.state.collapseRows.slice();
    collapseRows[id] = !this.state.collapseRows[id];
    this.setState({
      collapseRows: collapseRows,
    });
  };

  renderTableByAction = () => {
    const { batchAction } = this.props;
    switch (batchAction) {
      case "confirmOK":
        return this.renderOKTable();
      case "confirmImport":
        return this.renderImportTable();
      case "confirmPicked":
        return this.renderPickedTable();
      case "confirmReturn":
        return this.renderReturnTable();
      case "confirmStockRequest":
        return this.renderPickedTable();
      default:
        return "";
    }
  };

  renderBlStatusCell = (status) => {
    const { classes } = this.props;
    return (
      <span
        className={
          status === "waiting_for_confirm"
            ? classes.waitingForConfirm
            : status === "returned"
            ? classes.returned
            : classes.others
        }
        style={
          status !== "waiting_for_confirm" && {
            backgroundColor: mapBlStatusColor(status),
          }
        }
      >
        {mapBlStatusText(status)}
      </span>
    );
  };

  renderOKTable = () => {
    const { bls } = this.state;
    const { response } = this.props;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 25, padding: 0 }} />
            <TableCell>Mã vận đơn</TableCell>
            <TableCell style={{ width: 130, padding: 0 }}>Trạng thái</TableCell>
            <TableCell>Kho gửi</TableCell>
            <TableCell>Người nhận</TableCell>
            <TableCell>Địa chỉ nhận</TableCell>
            <TableCell>Số điện thoại</TableCell>
            <TableCell>Đ.vị vận chuyển</TableCell>
            <TableCell style={{ padding: 0 }}>
              {Object.keys(response).length > 0 && "Kết quả"}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bls.map((bl, idx) => (
            <TableRow key={idx}>
              <TableCell style={{ width: 25, padding: 0 }}>
                {this.renderUpdateStatus(bl)}
              </TableCell>
              <TableCell style={{ width: 70 }}>{bl.id}</TableCell>
              <TableCell style={{ width: 130, padding: 0 }}>
                {this.renderBlStatusCell(bl.status)}
              </TableCell>
              <TableCell>{bl.lastTransport.fromAddress.displayName}</TableCell>
              <TableCell>{bl.lastTransport.toAddress.displayName}</TableCell>
              <TableCell>{bl.lastTransport.toAddress.fullAddress}</TableCell>
              <TableCell>{bl.lastTransport.toAddress.phone}</TableCell>
              <TableCell>
                {mapShipMethodToText(bl.lastTransport.shipMethod)}
              </TableCell>
              <TableCell
                style={
                  response[bl.lastTransport.id]
                    ? { padding: 0, width: 300 }
                    : { padding: 0 }
                }
              >
                {this.renderActionButton(bl, idx)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  renderImportTable = () => {
    const { bls } = this.state;
    const { response } = this.props;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 25, padding: 0 }} />
            <TableCell>Mã vận đơn</TableCell>
            <TableCell style={{ width: 130, padding: 0 }}>Trạng thái</TableCell>
            <TableCell>Kho/KTV gửi</TableCell>
            <TableCell>Người liên hệ</TableCell>
            <TableCell>Số điện thoại</TableCell>
            <TableCell>Đ.vị vận chuyển</TableCell>
            <TableCell style={{ padding: 0 }}>
              {Object.keys(response).length > 0 && "Kết quả"}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bls.map((bl, idx) => (
            <TableRow key={idx}>
              <TableCell style={{ width: 25, padding: 0 }}>
                {this.renderUpdateStatus(bl)}
              </TableCell>
              <TableCell style={{ width: 70 }}>{bl.id}</TableCell>
              <TableCell style={{ width: 130, padding: 0 }}>
                {this.renderBlStatusCell(bl.status)}
              </TableCell>
              <TableCell>
                {bl.lastTransport.fromAddress.type === "ktv"
                  ? "KTV " +
                    bl.lastTransport.fromAddress.displayName +
                    "(" +
                    bl.lastTransport.fromAddress.obj_id +
                    ")"
                  : bl.lastTransport.fromAddress.displayName}
              </TableCell>
              <TableCell>{bl.lastTransport.toAddress.name}</TableCell>
              <TableCell>{bl.lastTransport.toAddress.phone}</TableCell>
              <TableCell>
                {mapShipMethodToText(bl.lastTransport.shipMethod)}
              </TableCell>
              <TableCell
                style={
                  response[bl.lastTransport.id]
                    ? { padding: 0, width: 300 }
                    : { padding: 0 }
                }
              >
                {this.renderActionButton(bl, idx)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  renderPickedTable = () => {
    const { bls } = this.state;
    const { response, classes } = this.props;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 25, padding: 0 }} />
            <TableCell style={{ width: 70, padding: 10 }}>Mã vận đơn</TableCell>
            <TableCell style={{ width: 70, padding: 10 }}>
              Mã gói hàng
            </TableCell>
            <TableCell style={{ width: 130, padding: 10 }}>
              Trạng thái
            </TableCell>
            <TableCell>Nơi gửi</TableCell>
            <TableCell>Kho/người nhận</TableCell>
            <TableCell>Người liên hệ</TableCell>
            <TableCell>Đ.vị vận chuyển</TableCell>
            <TableCell style={{ padding: 0 }}>
              {Object.keys(response).length > 0 && "Kết quả"}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bls.map((bl, idx) => (
            <TableRow key={idx}>
              <TableCell style={{ width: 25, padding: 0 }}>
                {this.renderUpdateStatus(bl)}
              </TableCell>
              <TableCell style={{ width: 70, padding: 10 }}>{bl.id}</TableCell>
              <TableCell style={{ width: 70, padding: 10 }}>
                {bl.document_id}
              </TableCell>
              <TableCell style={{ width: 130, padding: 10 }}>
                {this.renderBlStatusCell(bl.status)}
              </TableCell>
              <TableCell>
                <div className={classes.hubTitle}>
                  <strong>{bl.lastTransport.fromAddress.displayName}</strong>
                </div>
                <div>{bl.lastTransport.fromAddress.fullAddress}</div>
              </TableCell>
              <TableCell>
                <div className={classes.hubTitle}>
                  <strong>{bl.lastTransport.toAddress.displayName}</strong>
                </div>
                <div>{bl.lastTransport.toAddress.fullAddress}</div>
              </TableCell>
              <TableCell>{bl.toAddr.name}</TableCell>
              <TableCell>
                {mapShipMethodToText(bl.lastTransport.shipMethod)}
              </TableCell>
              <TableCell
                style={
                  response[bl.lastTransport.id]
                    ? { padding: 0, width: 300 }
                    : { padding: 0 }
                }
              >
                {this.renderActionButton(bl, idx)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  renderReturnTable = () => {
    const { bls } = this.state;
    const { response } = this.props;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 25, padding: 0 }} />
            <TableCell>Mã vận đơn</TableCell>
            <TableCell style={{ width: 130, padding: 0 }}>Trạng thái</TableCell>
            <TableCell>Kho hoàn</TableCell>
            <TableCell>Địa chỉ kho</TableCell>
            <TableCell>Người liên hệ</TableCell>
            <TableCell>Số điện thoại</TableCell>
            <TableCell>Đ.vị vận chuyển</TableCell>
            <TableCell style={{ padding: 0 }}>
              {Object.keys(response).length > 0 && "Kết quả"}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bls.map((bl, idx) => (
            <TableRow key={idx}>
              <TableCell style={{ width: 25, padding: 0 }}>
                {this.renderUpdateStatus(bl)}
              </TableCell>
              <TableCell style={{ width: 70 }}>{bl.id}</TableCell>
              <TableCell style={{ width: 130, padding: 0 }}>
                {this.renderBlStatusCell(bl.status)}
              </TableCell>
              <TableCell>{bl.lastTransport.fromAddress.displayName}</TableCell>
              <TableCell>{bl.lastTransport.fromAddress.fullAddress}</TableCell>
              <TableCell>{bl.lastTransport.fromAddress.name}</TableCell>
              <TableCell>{bl.lastTransport.fromAddress.phone}</TableCell>
              <TableCell>
                {mapShipMethodToText(bl.lastTransport.shipMethod)}
              </TableCell>
              <TableCell
                style={
                  response[bl.lastTransport.id]
                    ? { padding: 0, width: 300 }
                    : { padding: 0 }
                }
              />
              {this.renderActionButton(bl, idx)}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  renderUpdateStatus = (bl) => {
    const { response, batchAction } = this.props;
    const id =
      batchAction === "confirmStockRequest" ? bl.id : bl.lastTransport.id;
    if (response[id]) {
      if (response[id].error) {
        return (
          <i className="material-icons" style={{ color: "#da514c" }}>
            highlight_off
          </i>
        );
      } else {
        return (
          <i className="material-icons" style={{ color: "#58b757" }}>
            done
          </i>
        );
      }
    }
  };
  renderActionButton = (bl, idx) => {
    const { collapseRows } = this.state;
    const { response, removeSelectedBl, batchAction } = this.props;
    const id =
      batchAction === "confirmStockRequest" ? bl.id : bl.lastTransport.id;
    return response[id] ? (
      response[id].error ? (
        <div>
          <p>
            <span>Cập nhật thất bại</span>&nbsp;&nbsp;
            <span
              onClick={() => this.toggleCollapse(idx)}
              style={{ color: "#365899", cursor: "pointer" }}
            >
              Xem chi tiết
            </span>
          </p>
          <Collapse in={collapseRows[idx]} unmountOnExit>
            <p>{response[id].msg}</p>
          </Collapse>
        </div>
      ) : (
        "Cập nhật thành công"
      )
    ) : (
      <IconButton
        aria-label="Xóa"
        onClick={() => removeSelectedBl(bl.page, bl.id, bl.index)}
      >
        <i className="material-icons">delete</i>
      </IconButton>
    );
  };
  render() {
    const { bls } = this.state;
    const {
      batchAction,
      updating,
      response,
      batchUpdate,
      openBatchDialog,
      toggleBatchDialog,
      toggleLoading,
    } = this.props;
    return (
      <Dialog
        open={openBatchDialog}
        onClose={() => toggleBatchDialog("close")}
        maxWidth={false}
      >
        <DialogTitle>{_renderBatchActionTitle(batchAction)}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {_renderBatchActionText(batchAction)}
          </DialogContentText>
          {this.renderTableByAction()}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (Object.keys(response).length > 0) {
                window.location.reload();
              } else {
                toggleBatchDialog("close");
              }
            }}
          >
            Đóng
          </Button>
          {Object.keys(response).length === 0 && (
            <Button
              color="primary"
              onClick={() => {
                toggleLoading();
                if (!updating) {
                  batchUpdate(bls, batchAction);
                }
              }}
            >
              {updating && <CircularProgress size={20} />}&nbsp;Xác nhận
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(BatchDialog);
