import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { mapTransportStateColor } from "../../../utils";
import { AddressType, RequestType } from "../../../../../models";

const styles = (theme) => ({
  text: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 16,
    fontWeight: 700,
    color: "#797979",
    cursor: "pointer",
  },
});

const Label = ({ transport, type, classes, allKtvList, mapNameKTV }) => (
  <span
    className={classes.text}
    style={{ color: mapTransportStateColor(transport.display_state) }}
  >
    Từ{" "}
    {transport.fromAddress.type === AddressType.ktv
      ? transport.fromAddress.asiaId
        ? `KTV ${transport.fromAddress.displayName} - ${transport.fromAddress.objId} - ${transport.fromAddress.asiaId}`
        : `KTV ${transport.fromAddress.displayName} - ${transport.fromAddress.objId}`
      : transport.fromAddress.displayName}{" "}
    đến{" "}
    {transport.toAddress.type === AddressType.customer
      ? type === RequestType.wh_transfer.toUpperCase()
        ? transport.toAddress.displayName
        : "khách hàng"
      : transport.toAddress.type === AddressType.ktv
      ? transport.toAddress.asiaId
        ? `KTV ${mapNameKTV(
            allKtvList,
            transport.toAddress.objId,
            transport.toAddress.displayName
          )} - ${transport.toAddress.objId} - ${transport.toAddress.asiaId}`
        : `KTV ${mapNameKTV(
            allKtvList,
            transport.toAddress.objId,
            transport.toAddress.displayName
          )} - ${transport.toAddress.objId}`
      : mapNameKTV(
          allKtvList,
          transport.toAddress.objId,
          transport.toAddress.displayName
        )}
  </span>
);

export default withStyles(styles)(Label);
