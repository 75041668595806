import React from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const styles = (theme) => ({
  subtitle: {
    fontSize: 14,
    padding: "8px 0 16px 0",
    fontFamily: "Source Sans Pro, sans-serif",
    textTransform: "uppercase",
    fontWeight: 600,
    letterSpacing: 1,
    color: "#5d9cec",
  },
  rowDetail: {
    fontSize: 14,
    display: "flex",
    paddingBottom: 16,
    fontFamily: "Noto Sans, sans-serif",
  },
});
const BlGroupInfo = ({ bl, requestIds, classes }) => (
  <div>
    <Typography type="subheading" className={classes.subtitle}>
      Thông tin vận đơn
    </Typography>
    <div>
      <Grid container spacing={1}>
        <Grid item md={6}>
          <strong>Kho gửi: </strong>&nbsp; {bl.from_addr.display_name}
        </Grid>
        <Grid item md={6}>
          <strong>Ngày xuất kho: </strong>&nbsp; {bl.request_created_at}
        </Grid>
        {/* <Grid
          item
          md={6}
          style={{ overflow: "hidden", wordBreak: "break-word" }}
        >
          <strong>Mã gói hàng: </strong>&nbsp;
          {requestIds.map((x, key) => (
            <span key={key}>
              {x}
              {key !== requestIds.length - 1 && ","}&nbsp;
            </span>
          ))}
        </Grid> */}
        <Grid item md={6}>
          <strong>Ghi chú: </strong>&nbsp; {bl.note ? bl.note : "Không"}
        </Grid>
        <Grid item md={6}>
          <strong>Người liên hệ: </strong>
          &nbsp; {bl.from_addr.name} - {bl.from_addr.phone}
        </Grid>
        <Grid item md={6}>
          <strong>Ngày hẹn giao: </strong>
          &nbsp; {bl.ship_date}
        </Grid>
      </Grid>
    </div>
  </div>
);

export default withStyles(styles)(BlGroupInfo);
