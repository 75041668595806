import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import ShipperList from "./ShipperList";
import TransportPackages from "./TransportPackages";

const styles = (theme) => ({
  text: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
    color: "#797979",
    marginBottom: 8,
  },
  handler: {
    cursor: "pointer",
    color: "#365899",
  },
  table: {
    margin: 8,
  },
  entered: {
    overflow: "visible",
  },
});

class TransportShipMethod extends React.Component {
  constructor(props) {
    super(props);
    const { transport } = this.props;

    this.state = {
      collapse: false,
      editable: false,
      ktv_list: [...transport.main_ktv, ...transport.assigned_ktvs],
    };
  }

  toggleCollapse = () =>
    this.setState({
      collapse: !this.state.collapse,
    });

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      ktv_list: [
        ...nextProps.transport.main_ktv,
        ...nextProps.transport.assigned_ktvs,
      ],
    });
  }

  renderShipMethod = () => {
    const { transport, classes } = this.props;
    const shipper = transport.shipper;
    switch (transport.shipMethod) {
      case "ktv":
        return (
          <span>
            Kĩ thuật viên (
            {shipper.asiaId
              ? `${shipper.asiaId.toString().padStart(4, 0)} - `
              : ""}
            {shipper.id} - {shipper.name} - SĐT:
            {shipper.phone}
            {transport.assigned_ktvs.length > 0 ? (
              <span onClick={this.toggleCollapse}>
                & {transport.assigned_ktvs.length} KTV
              </span>
            ) : null}
            ).&nbsp;
            <span
              onClick={this.toggleCollapse}
              className={classes.handler}
              style={{ display: "inline" }}
            >
              <strong>Chi tiết</strong>
            </span>
          </span>
        );
      case "xe_khach":
        return (
          <span>
            Xe khách{" "}
            {transport.bus_line && transport.bus_line.id
              ? `- ${transport.bus_line.name}`
              : ""}
          </span>
        );
      default:
        return <TransportPackages transport={transport} />;
    }
  };

  render() {
    const { collapse, editable } = this.state;
    const { transport, classes } = this.props;
    return (
      <div className={classes.text}>
        <p className={classes.text}>
          <strong>Phương thức vận chuyển:&nbsp;</strong>
          {this.renderShipMethod()}
        </p>

        <Collapse
          in={collapse}
          unmountOnExit
          classes={{ entered: classes.entered }}
        >
          <ShipperList
            transport={transport}
            editable={editable}
            ktvList={this.state.ktv_list}
            updateTimeKeeping={this.props.updateTimeKeeping}
          />
        </Collapse>

        {/*  <p className={classes.text}>
          <strong>Chi phí tạm tính:</strong>{" "}
          {transport.estimate_fee
            ? transport.shipMethod === "ktv"
              ? transport.vehicle_gas_cost
                ? `${transport.estimate_fee.toLocaleString("en-US")}đ`
                : "0đ"
              : `${transport.estimate_fee.toLocaleString("en-US")}đ`
            : "Không có"}
        </p> */}
      </div>
    );
  }
}

export default withStyles(styles)(TransportShipMethod);
