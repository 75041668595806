import React from "react";
import _ from "lodash";
import moment from "moment";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { AddCustomerAddress } from "../components";
import { AddressType } from "../../../../models";
import {
  addCustomerAddress,
  fetchArea,
  createTransport,
  getCreateTransportSuggestions,
  updateSuggestionCount,
  getThreePlsAvailable,
} from "@shared";
import { Grid } from "@material-ui/core";
import { validateUtils, validateSuggestionList } from "../../utils";
import { Dialog } from "../../shared";
import { fetchBl3PlsList, fetchBlShipperList } from "../actions";
import { CreateTransportSuggestions } from "../../bl_detail/components";
import { createTransportStyles as styles } from "./styles";
import TransportForm from "./TransportForm";
class CreateTransportContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addAreaCode: false, //kiểm tra xem có cần thêm area code trước khi tạo lộ trình không,
      showMsg: false,
      form: {
        id: this.props.bl.id,
        shipper: "",
        toHub: this.props.bl.isWhTransfer
          ? { id: this.props.bl.toAddr.objId }
          : "",
        shipperExtra: [],
        note: "",
        edt: null,
        vehicle_gas_cost: !this.props.bl.isWhTransfer,
        shipType: this.props.bl.isWhTransfer
          ? this.props.bl.holderType === "hub"
            ? "hub_customer"
            : "customer_customer"
          : "hub_customer",
        estimate_fee: null,
        shipMethod: "ktv",
        is_debit: true,
      },
      suggestions: [],
      suggestion_id: null,
      loading: false,
      error: {
        hasError: false,
        msg: [],
      },
    };
  }

  async componentDidMount() {
    const { bl } = this.props;
    if (bl.toAddr.type === AddressType.customer) {
      this.setState({
        addAreaCode: bl.toAddr.areaCode === null,
      });
    } else {
      this.setState({
        addAreaCode: false,
      });
    }
    /* Chọn gán nợ */
    //TODO: check khi nào chọn
    if (["sale_order", "asia_order"].includes(bl.requestType.toLowerCase())) {
      this.changeForm({ is_debit: "true" });
    }
    this.props.fetchBlShipperList(bl.id, bl.toAddr.objId);
    //Lấy gợi ý
    const response = await getCreateTransportSuggestions(bl.id);
    const suggestions =
      response.status === 200 && response.data
        ? response.data.data.suggestions.filter((x) => x.ship_method === "ktv")
        : [];
    this.setState({ suggestions: suggestions });
    //Get estimate fee
    const threePlResponse = await getThreePlsAvailable(bl.id);
    if (threePlResponse.status === 200) {
      const result = threePlResponse.data;
      if (result && result.data && result.data.ThreePls) {
        const ktv = result.data.ThreePls.find((x) => x.code_name === "ktv");
        this.changeForm({ estimate_fee: ktv.estimate_fee });
      }
    }
  }

  confirmAreaCodeAdded = () =>
    this.setState({
      addAreaCode: false,
    });

  changeForm = (obj) => {
    this.setState({
      form: {
        ...this.state.form,
        ...obj,
      },
    });
  };

  canShowToHubSelection = () => {
    const { bl } = this.props;
    const { form } = this.state;
    return (
      bl.isWhTransfer && ["hub_hub", "customer_hub"].includes(form.shipType)
    );
  };

  handleCreateTransport = async () => {
    const { form } = this.state;
    const { bl, createTransport } = this.props;
    const rules = {
      toHub: {
        custom: function () {
          return (
            bl.isWhTransfer &&
            ["hub_hub", "customer_hub"].includes(form.shipType) &&
            !form.toHub.id
          );
        },
      },
      shipperExtra: {
        custom: function () {
          return form.shipperExtra.length === 0;
        },
      },
    };
    const message = {
      toHub: {
        custom: "Bạn chưa chọn Kho nhận",
      },
      shipperExtra: {
        custom: "Bạn chưa chọn KTV giao hàng",
      },
    };

    let error = validateUtils(form, rules, message);
    if (error.length === 0) {
      const data = { ...form };
      if (form.shipperExtra) {
        data.shipper = form.shipperExtra.filter((x) => x.isMainKtv)[0];
        data.shipperExtra = form.shipperExtra.filter((x) => !x.isMainKtv);
      }
      this.setState({ loading: true });
      await createTransport(data);
      this.setState({ loading: false });
    } else {
      this.toggleFormErrorDialog(error);
    }
  };

  toggleFormErrorDialog = (errors = []) =>
    this.setState({
      error: {
        hasError: !this.state.error.hasError,
        msg: errors,
      },
    });

  handleErrorMsg() {
    const { error } = this.state;
    let errMsg = [];
    if (error && error.msg) {
      error.msg.forEach((element, index) => {
        errMsg.push(<span key={`error-${index}`}>- {element}</span>);
        errMsg.push(<br key={`error-br-${index}`} />);
      });
    }
    return errMsg;
  }

  handleChangeSuggestSelection = (suggestion) => {
    updateSuggestionCount(suggestion.id);
    this.setState({ suggestion_id: suggestion.id });
    const ktv = {
      id: suggestion.ktv,
      value: suggestion.ktv,
      isMainKtv: true,
    };
    this.changeForm({ shipperExtra: [ktv] });
  };

  render() {
    const { addAreaCode, form, error, suggestions, loading, suggestion_id } =
      this.state;
    const { bl, classes, ktvList } = this.props;

    return (
      <React.Fragment>
        <Grid container className={classes.text} wrap="nowrap">
          <Grid item md={8} style={{ marginRight: "1rem" }}>
            {addAreaCode ? (
              <AddCustomerAddress
                bl={bl}
                confirmAreaCodeAdded={this.confirmAreaCodeAdded}
                addCustomerAddress={this.props.addCustomerAddress}
                fetchArea={this.props.fetchArea}
              />
            ) : (
              <TransportForm
                bl={bl}
                form={form}
                loading={loading}
                changeForm={this.changeForm}
                handleCreateTransport={this.handleCreateTransport}
                canShowToHubSelection={this.canShowToHubSelection}
              />
            )}
          </Grid>
          {validateSuggestionList(suggestions) > 0 && ktvList.length > 0 && (
            <Grid item>
              <CreateTransportSuggestions
                ktv={form.shipperExtra}
                bl={bl}
                value={suggestion_id}
                suggestions={suggestions}
                maxHeight="20rem"
                shipperList={ktvList}
                onSelect={(e) => this.handleChangeSuggestSelection(e)}
              />
            </Grid>
          )}
        </Grid>
        <Dialog
          open={error.hasError}
          title="Có lỗi xảy ra"
          closeDialog={(e) => this.toggleFormErrorDialog([])}
        >
          {this.handleErrorMsg()}
          <br />
          <span style={{ color: "#ff0000" }}>
            <span>*Lưu ý: </span>
            Các trường trên là bắt buộc. Vui lòng điền đầy đủ thông tin trước
            khi tạo lộ trình!
          </span>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  ktvList: state.admin.list.ktvList,
});

const mapDispatchToProps = (dispatch) => ({
  fetchArea: (area) => dispatch(fetchArea(area)),
  addCustomerAddress: (id, code) => dispatch(addCustomerAddress(id, code)),
  createTransport: (data) => dispatch(createTransport(data)),
  fetchBl3PlsList: (bl_id, shipType) =>
    dispatch(fetchBl3PlsList(bl_id, shipType)),
  fetchBlShipperList: (bl_id, hub_id) =>
    dispatch(fetchBlShipperList(bl_id, hub_id)),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(CreateTransportContainer);
