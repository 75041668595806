import React from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import BlFollower from "./BlFollower";
import EditAndSaveInfo from "./EditAndSaveInfo";
import { openNotiDialog } from "../../../shared/actions";
import { connect } from "react-redux";
import { updateReceiverAddress } from "../../actions";

const styles = (theme) => ({
  subtitle: {
    fontSize: 14,
    padding: "8px 0 16px 0",
    fontFamily: "Source Sans Pro, sans-serif",
    textTransform: "uppercase",
    fontWeight: 600,
    letterSpacing: 1,
    color: "#5d9cec",
  },
  rowDetail: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 14,
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
  },
  row: {
    paddingBottom: 14,
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
  },
  editButton: {
    float: "right",
    cursor: "pointer",
  },
  text: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
  },
  label: {
    fontWeight: "bold",
  },
  table: {
    lineHeight: "2",
    width: "100%",
    borderSpacing: 5,
  },
  addressContainer: {
    maxHeight: 77,
    overflowY: "auto",
  },
});

class ToAddressInfo extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
      confirmDialog: false,
      historyDialog: false,
      data: null,
      loading: false,
    };
  }

  canEditToAddress = () => {
    const { bl } = this.props;
    return Boolean(
      !bl.isWhTransfer &&
        ["waiting_for_confirm", "waiting_for_pick", "on_delivering"].includes(
          bl.status
        )
    );
  };

  render() {
    const { address, requestType, classes, bl, updateReceiverAddress } =
      this.props;
    const area = address.area;
    const canEdit = this.canEditToAddress();
    return (
      <div>
        <Typography type="subheading" className={classes.subtitle}>
          Thông tin{" "}
          {requestType === "WH_TRANSFER"
            ? "kho nhận"
            : requestType && "khách hàng"}
          {canEdit && (
            <EditAndSaveInfo
              address={address}
              bl={bl}
              onUpdated={updateReceiverAddress}
            />
          )}
        </Typography>
        <div className={classes.text}>
          {requestType === "WH_TRANSFER" ? (
            <table className={classes.table}>
              <tbody>
                <tr>
                  <td style={{ width: "15%" }} className={classes.label}>
                    Kho nhận:
                  </td>
                  <td>{address.displayName}</td>
                  <td style={{ width: "15%" }} className={classes.label}>
                    Người liên hệ:
                  </td>
                  <td style={{ width: "25%" }}>{address.name}</td>
                </tr>
                <tr>
                  <td className={classes.label}>Địa chỉ:</td>
                  <td>
                    <div className={classes.addressContainer}>
                      {address.street}
                    </div>
                  </td>
                  <td className={classes.label}>Email:</td>
                  <td>{address.email ? address.email : "Không có"}</td>
                </tr>
                <tr>
                  <td className={classes.label}>Tỉnh/Thành:</td>
                  <td>
                    {area.code
                      ? area.code.length === 2
                        ? area.name
                        : area.district
                      : "-"}
                  </td>
                  <td className={classes.label}>Số điện thoại:</td>
                  <td>{address.phone}</td>
                </tr>
                <tr>
                  <td className={classes.label}>Quận/Huyện:</td>
                  <td>
                    {area.code
                      ? area.code.length === 2
                        ? ""
                        : area.code.length === 4
                        ? area.name
                        : area.province
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className={classes.label}>Phường/Xã:</td>
                  <td>
                    {area.code && area.code.length === 6 ? area.name : "-"}
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table className={classes.table}>
              <tbody>
                <tr>
                  <td style={{ width: "15%" }} className={classes.label}>
                    Người nhận:
                  </td>
                  <td>{address.displayName}</td>
                  <td style={{ width: "30%" }} className={classes.label}>
                    Số điện thoại:
                  </td>
                  <td>{address.phone}</td>
                </tr>
                <tr>
                  <td className={classes.label}>Địa chỉ:</td>
                  <td style={{ paddingRight: 10 }}>
                    <div className={classes.addressContainer}>
                      {address.street}
                    </div>
                  </td>
                  <td className={classes.label}>Email:</td>
                  <td>{address.email ? address.email : "-"}</td>
                </tr>
                <tr>
                  <td className={classes.label}>Tỉnh/Thành:</td>
                  <td>
                    <span>
                      {area.code
                        ? area.code.length === 2
                          ? area.name
                          : area.district
                        : "-"}
                    </span>
                  </td>
                  <td className={classes.label}>
                    Khoảng cách từ kho đến khách hàng:
                  </td>
                  <td>{bl.current_distance}km</td>
                </tr>
                <tr>
                  <td className={classes.label}>Quận/Huyện:</td>
                  <td>
                    {area.code
                      ? area.code.length === 2
                        ? "-"
                        : area.code.length === 4
                        ? area.name
                        : area.province
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className={classes.label}>Phường/Xã:</td>
                  <td>
                    {area.code && area.code.length === 6 ? area.name : "-"}
                  </td>
                </tr>
                {address.gg_address && (
                  <tr>
                    <td className={classes.label}>Google Map:</td>
                    <td>{address.gg_address}</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
        <br />
        <BlFollower />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  openNotiDialog: (type, text) => dispatch(openNotiDialog(type, text)),
  updateReceiverAddress: (response, address) =>
    dispatch(updateReceiverAddress(response, address)),
});

export default withStyles(styles)(
  connect(null, mapDispatchToProps)(ToAddressInfo)
);
