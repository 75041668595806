import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import UserImg from "@images/user.png";
import { formatDateTime } from "@utils";

const styles = (theme) => ({
  ratingIcon: {
    fontSize: "3rem",
  },
  ratingSection: {
    display: "flex",
    alignItems: "center",
    color: "#000000c2",
    fontWeight: "bold",
  },
  ratingContainer: {
    margin: "1rem",
    borderRadius: "0.5rem",
    fontSize: 14,
  },
  ratingTime: {
    textAlign: "right",
  },
  flexBox: {
    display: "flex",
    alignItems: "center",
  },
});

const renderRating = (point, classes) => {
  switch (point) {
    case 0:
      return <span>Không có đánh giá</span>;
    case 1:
      return (
        <div className={classes.ratingSection}>
          <i
            className={`far fa-frown ${classes.ratingIcon}`}
            style={{ color: "red" }}
          />
          &nbsp;&nbsp; Cần cải thiện chất lượng dịch vụ tốt hơn
        </div>
      );
    case 2:
      return (
        <div className={classes.ratingSection}>
          <i
            className={`far fa-meh ${classes.ratingIcon}`}
            style={{ color: "#EE9F00" }}
          />
          &nbsp;&nbsp; Tạm chấp nhận được
        </div>
      );
    case 3:
      return (
        <div className={classes.ratingSection}>
          <i
            className={`far fa-smile ${classes.ratingIcon}`}
            style={{ color: "#9ACD32" }}
          />
          &nbsp;&nbsp; Rất hài lòng về chất lượng dịch vụ
        </div>
      );
    default:
      return <span>Chưa được đánh giá</span>;
  }
};

const mapRatingPointToClassName = (point) => {
  switch (point) {
    case 1:
      return "speech-bubble-red";
    case 2:
      return "speech-bubble-orange";
    case 3:
      return "speech-bubble-green";
    default:
      return "";
  }
};

const BlRating = ({ bl, classes }) => (
  <div style={{ marginRight: "1rem" }}>
    <Typography type="subheading" className="subTitle">
      Đánh giá chất lượng dịch vụ
    </Typography>
    <div className={classes.flexBox}>
      <img src={UserImg} />
      <div
        className={`speech-bubble ${mapRatingPointToClassName(bl.rating)} ${
          classes.ratingContainer
        }`}
      >
        {renderRating(bl.rating, classes)}

        {Boolean(bl.rating) && (
          <div className={classes.ratingTime}>
            {formatDateTime(bl.rating_time)}
          </div>
        )}
      </div>
    </div>
  </div>
);

export default withStyles(styles)(BlRating);
