import React from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import { geocodeByAddress } from "react-places-autocomplete";
import { DebounceInput } from "react-debounce-input";
import MapIcon from "@images/maps_32dp.ico";
import MyGoogleMap from "./GoogleMap";
import { withStyles } from "@material-ui/core/styles";

export const styles = () => ({
  text: {
    fontFamily: "Noto Sans, sans-serif",
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
  },
  option: {
    display: "flex",
    alignItems: "center",
    padding: "0.5rem",
    borderBottom: "1px solid #d3d3d38f",
    borderLeft: "1px solid #d3d3d38f",
    borderRight: "1px solid #d3d3d38f",
    cursor: "pointer",
  },
  input: {
    width: "98%",
  },
  label: {
    color: "rgba(0, 0, 0, 0.76)",
    fontWeight: 600,
  },
  suggestBox: {
    position: "absolute",
    background: "white",
    zIndex: 10,
    boxShadow: "0 1px 5px rgba(0, 0, 0, 0.15)",
  },
  mapState: {
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
    letterSpacing: 1,
    fontSize: "0.8rem",
    color: "#4c4848",
  },
  clickableLink: {
    color: "darkblue",
    fontWeight: "normal",
    textDecoration: "underline",
  },
  copyLink: {
    color: "darkblue",
    fontWeight: "normal",
    textDecoration: "underline",
    fontSize: 11,
    cursor: "pointer",
  },
  suggestList: {
    position: "absolute",
    background: "white",
    zIndex: 1,
    boxShadow: "0 1px 5px rgba(0, 0, 0, 0.15)",
    borderRadius: "0 0 1rem 1rem",
  },
  suggestListItem: {
    paddingTop: 9,
    paddingBottom: 9,
  },
});

const notFound = (
  <React.Fragment>
    <i className="material-icons" style={{ color: "#e49b2a", fontSize: 20 }}>
      warning
    </i>
    &nbsp;&nbsp;Không thể tìm thấy địa điểm!
  </React.Fragment>
);

class UpdateAddressDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      google_response: null,
      mapState: "",
      suggestions: null,
      inputState: false,
      inputWidth: null,
      searchState: null,
      search_addr: this.props.address,
    };
  }

  componentDidMount() {
    const { onChange, address } = this.props;
    geocodeByAddress(address)
      .then((response) => {
        this.setState({ mapState: "", google_response: response[0] });
        const autocomplete =
          new window.google.maps.places.AutocompleteService();
        autocomplete.getQueryPredictions(
          { input: response[0].formatted_address },
          (predictions, status) => {
            this.setState({ suggestions: predictions, searchState: status });
            onChange({ suggestions: predictions });
          }
        );
        onChange({ googleResponse: response[0] });
      })
      .catch((err) => {
        this.setState({ mapState: notFound });
        onChange({
          googleResponse: null,
        });
      });
    setTimeout(() => {
      const input = document.getElementById("autocompleteInput");
      this.setState({ inputWidth: input === null ? 0 : input.offsetWidth });
    }, 1000);
  }

  handleSelect = (address) => {
    const { onChange } = this.props;
    this.setState({ search_addr: address.description });
    geocodeByAddress(address.description)
      .then((response) => {
        this.setState({
          mapState: "",
        });
        this.setState({ google_response: response[0] });
        onChange({
          googleResponse: response[0],
        });
      })
      .catch((err) => {
        onChange({ googleResponse: null });
        this.setState({
          mapState: notFound,
        });
      });
  };

  handlePick = (e) => {
    const { onChange } = this.props;
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: e.latLng }, (results, status) => {
      if (status === "OK") {
        const match = results[0];
        this.setState({
          mapState: null,
          google_response: match,
          search_addr: match.formatted_address,
        });
        const autocomplete =
          new window.google.maps.places.AutocompleteService();
        autocomplete.getQueryPredictions(
          {
            input: match.formatted_address,
          },
          (predictions, status) => {
            this.setState({ suggestions: predictions, searchState: status });
            onChange({ suggestions: predictions, type: "pick" });
          }
        );
        onChange({
          googleResponse: match,
        });
      } else {
        console.warn(
          "Geocode was not successful for the following reason: " + status
        );
      }
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { onChange, address } = nextProps;
    const { google_response } = this.state;
    if (
      google_response &&
      google_response.formatted_address !== address &&
      this.props.address === google_response.formatted_address
    ) {
      geocodeByAddress(address)
        .then((response) => {
          this.setState({ mapState: null, google_response: response[0] });
          onChange({
            googleResponse: response[0],
          });
        })
        .catch((err) => {
          this.setState({ mapState: notFound });
        });
    }
  }

  handleChangeInput = (e) => {
    const { onChange } = this.props;
    const value = e.target.value;
    this.setState({ search_addr: value });
    const autocomplete = new window.google.maps.places.AutocompleteService();
    autocomplete.getQueryPredictions(
      { input: value },
      (predictions, status) => {
        this.setState({ suggestions: predictions, searchState: status });
        onChange({ suggestions: predictions, type: "input" });
      }
    );
  };

  render() {
    const {
      classes,
      address,
      labelSize = 2,
      onCopyAddress,
      renderDistance = null,
      copyText = "Cập nhật địa chỉ khách hàng",
    } = this.props;
    const {
      mapState,
      suggestions,
      inputState,
      inputWidth,
      google_response,
      search_addr,
    } = this.state;
    const marker = google_response
      ? [
          {
            position: google_response.geometry.location,
            infowindow: this.state.search_addr,
          },
        ]
      : [];
    return (
      <React.Fragment>
        <Grid item md={12} style={{ paddingTop: "1rem" }}>
          <img src={MapIcon} width={16} alt="map icon" />
          &nbsp;&nbsp;
          <span className={classes.title}>GOOGLE MAP</span>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Grid container alignItems="center">
            <Grid item md={labelSize} className={classes.label}>
              <i style={{ color: "dodgerblue" }} className="fas fa-search" />
              &nbsp;&nbsp; Tìm địa điểm &nbsp;
              <span className="required" />
            </Grid>
            <Grid item md={9}>
              <div>
                <div style={{ display: "flex" }}>
                  <DebounceInput
                    id="autocompleteInput"
                    value={search_addr}
                    placeholder="Nhập tên địa điểm hoặc địa chỉ cần tìm"
                    onChange={this.handleChangeInput}
                    debounceTimeout={100}
                    onFocus={(e) => this.setState({ inputState: true })}
                    onBlur={(e) =>
                      setTimeout(
                        () => this.setState({ inputState: false }),
                        300
                      )
                    }
                    style={{ flex: 1 }}
                  />
                </div>
                <span id="googleAddress" />
                {inputState && (
                  <List
                    className={classes.suggestList}
                    style={{
                      width: inputWidth,
                    }}
                  >
                    {suggestions ? (
                      suggestions.map((x, key) => (
                        <ListItem
                          button
                          onClick={(e) => {
                            this.handleSelect(x);
                          }}
                          key={key}
                          className={classes.suggestListItem}
                        >
                          <ListItemIcon>
                            <i
                              className="material-icons"
                              style={{ color: "indianred" }}
                            >
                              location_on
                            </i>
                          </ListItemIcon>
                          <ListItemText
                            primary={x.description}
                            disableTypography={true}
                          />
                        </ListItem>
                      ))
                    ) : (
                      <ListItem className={classes.suggestListItem}>
                        <ListItemText
                          primary="Không tìm thấy địa điểm trên Google Map"
                          disableTypography={true}
                        />
                      </ListItem>
                    )}
                  </List>
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid item md={labelSize} className={classes.label}>
              <i style={{ color: "darkorange" }} className="fas fa-map-pin" />
              &nbsp;&nbsp; Địa chỉ chi tiết
            </Grid>
            <Grid item md={9} style={{ display: "flex" }}>
              <input
                type="text"
                style={{ flex: 1 }}
                value={address}
                placeholder="Địa chỉ chi tiết của địa điểm cần tìm"
                disabled
              />
            </Grid>
            <Grid item md={1}>
              <span onClick={onCopyAddress}>
                <Tooltip title={copyText} placement="bottom">
                  <span className={classes.copyLink}>
                    <i className="fas fa-level-up-alt" />
                    &nbsp;Đồng bộ
                  </span>
                </Tooltip>
              </span>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div style={{ padding: "0.3rem", fontSize: 13 }}>
            {/* Display distance */}
            {renderDistance}
            {!mapState ? (
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${address}`}
                target="_blank"
                className="link"
                rel="noopener noreferrer"
              >
                Link Google Map
              </a>
            ) : (
              <div className={classes.mapState}>{this.state.mapState}</div>
            )}
          </div>
          <MyGoogleMap
            defaultZoom={18}
            markers={marker}
            center={
              google_response
                ? {
                    lat: google_response.geometry.location.lat(),
                    lng: google_response.geometry.location.lng(),
                  }
                : { lat: 0, lng: 0 }
            }
            handlePick={this.handlePick}
          />
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(UpdateAddressDialog);
