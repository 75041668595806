import React from "react";
import { connect } from "react-redux";
import { ShippingSummary, ShippingLocation, ShippingLog } from ".";

class ShipperDailyReportBody extends React.Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    this.state = {
      center: data && data.length > 0 ? data[0].location : { lat: 0, lng: 0 },
      zoom: 15,
    };
  }

  render() {
    const { renderLocation } = this.props;
    return (
      <React.Fragment>
        <ShippingSummary />
        {renderLocation === true && (
          <ShippingLocation
          // center={center}
          // zoom={zoom}
          // onChange={e => this.setState(e)}
          />
        )}
        <ShippingLog />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.admin.ShipperDailyReport.data,
  renderLocation: state.admin.ShipperDailyReport.renderLocation,
});

export default connect(mapStateToProps, null)(ShipperDailyReportBody);
