import User from "./User";
import Address from "./Address";
import { formatNumber } from "../app/admin/utils/misc";

export const TransportState = {
  confirmed: "confirmed",
  shipping: "shipping",
  retry: "retry",
  returning: "returning",
  cancelled: "cancelled",
  returned: "returned",
  ok: "ok",
  partial_ok: "partial_ok",
  delivering: "shipping,retry",
  delivered: "ok,partial_ok",
};

export const ShipMethod = {
  direct: "direct",
  ghn: "ghn",
  vnpost: "vnpost",
  vnpost_manual: "vnpost_manual",
  vtpost: "vtpost",
  ktv: "ktv",
  xe_khach: "xe_khach",
  xe_tai: "xe_tai",
  ghtk: "ghtk",
  ahamove: "ahamove",
  nhat_tin: "nhat_tin",
  nhat_tin_manual: "nhat_tin_manual",
  tan_son_nhat: "tan_son_nhat",
  vtpost_manual: "vtpost_manual",
  kerry_express_manual: "kerry_express_manual",
  viet_star: "viet_star",
  nv_phongvu: "nv_phongvu",
  nv_umbala: "nv_umbala",
  nv_umbala_manual: "nv_umbala_manual",
  nv_adidi_manual: "nv_adidi_manual",
  hoang_trong_manual: "hoang_trong_manual",
  kien_vang_manual: "kien_vang_manual",
  nv_kinhdo: "nv_kinhdo",
  adidi: "adidi",
  vtphn_manual: "vtphn_manual",
  vtbt_manual: "vtbt_manual",
  kl_manual: "kl_manual",
  eton: "eton",
  eton_vnt: "eton_vnt",
  eton_vin: "eton_vin",
  vin_manual: "vin_manual",
  tran_an_manual: "tran_an_manual",
  vnt_adidi_manual: "vnt_adidi_manual",
  tkl_eton: "tkl_eton",
  tkl_manual: "tkl_manual",
};

export const ShipType = {
  hub_hub: "hub_hub",
  hub_customer: "hub_customer",
  hub_ktv: "hub_ktv",
  ktv_customer: "ktv_customer",
  ktv_hub: "ktv_hub",
  ktv_ktv: "ktv_ktv",
  customer_ktv: "customer_ktv",
  customer_hub: "customer_hub",
  customer_customer: "customer_customer",
};

export default class Transport {
  constructor(transport) {
    this.id = transport.id;
    this.state = transport.state;
    this.display_state = transport.display_state;
    this.createdAt = transport.created_at;
    this.createdByUser = transport.created_by_user
      ? new User(transport.created_by_user)
      : "";
    this.startedTime = transport.started_time;
    this.endedTime = transport.ended_time;
    this.edt = transport.estimated_delivery_time;
    this.fromAddress = transport.from_address
      ? new Address(transport.from_address)
      : transport.from_address;
    this.toAddress = transport.to_address
      ? new Address(transport.to_address)
      : transport.to_address;
    this.note = transport.note;
    this.plRef = transport.pl_ref;
    this.paidCOD = transport.paid_cod;
    this.shipMethod = transport.ship_method;
    this.shipper = transport.shipper
      ? new User(transport.shipper)
      : transport.shipper;
    this.assigned_ktvs = transport.assigned_ktvs;
    this.distance = transport.distance;
    this.group = transport.group;
    this.transportLines = transport.transport_lines;
    this.type = transport.type;
    this.reason = transport.reason;
    this.transportLines = transport.transport_lines;
    this.codAmount = transport.cod_amount;
    this.weight =
      transport.package_info && transport.package_info.weight
        ? transport.package_info.weight
        : "";
    this.height =
      transport.package_info && transport.package_info.height
        ? transport.package_info.height
        : "";
    this.width =
      transport.package_info && transport.package_info.width
        ? transport.package_info.width
        : "";
    this.length =
      transport.package_info && transport.package_info.length
        ? transport.package_info.length
        : "";
    this.inventory_count =
      (transport.package_info
        ? transport.package_info.inventory_count
        : undefined) || 0;
    this.vehicle_gas_cost = transport.vehicle_gas_cost;
    this.service_id = transport.service_id;
    this.imageUrl = transport.image_url;
    this.main_ktv = transport.main_ktv;
    this.estimate_fee = transport.estimate_fee;
    this.shipper_state = transport.shipper_state ? transport.shipper_state : "";
    this.cancelled_reason = transport.cancelled_reason
      ? transport.cancelled_reason
      : "";
    this.bus_line = transport.bus_line ? transport.bus_line : "";
    this.package_info = transport.package_info ? transport.package_info : {};
    this.real_distance = transport.real_distance || "";
    this.able_to_cancel = transport.able_to_cancel;
    this.able_to_pick = transport.able_to_pick;
    this.bl_distance = transport.bl_distance;
    this.check_status_3pl_url = transport.check_status_3pl_url;
    this.three_pl_state = transport.three_pl_state;
  }

  get isDone() {
    return [
      TransportState.ok,
      TransportState.partial_ok,
      TransportState.returned,
      TransportState.cancelled,
    ].includes(this.state);
  }

  get leavedHub() {
    return ![TransportState.confirmed, TransportState.cancelled].includes(
      this.state
    );
  }

  get completed() {
    return [
      TransportState.ok,
      TransportState.partial_ok,
      TransportState.returned,
    ].includes(this.state);
  }

  get deliveredToCustomer() {
    return (
      [TransportState.ok, TransportState.partial_ok].includes(this.state) &&
      this.paidCOD !== null
    );
  }

  get formattedPaidCod() {
    return formatNumber(this.paidCOD);
  }
}
