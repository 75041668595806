import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { ShipType, TransportState } from "../../../../../models";

const styles = (theme) => ({
  text: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
    color: "#797979",
  },
});

const TransportAddress = ({ transport, classes }) => {
  if (
    [TransportState.returning, TransportState.returned].includes(
      transport.state
    )
  ) {
    if ([ShipType.ktv_customer, ShipType.ktv_hub].includes(transport.type)) {
      return (
        <p className={classes.text}>
          <strong>Địa chỉ hoàn hàng:</strong>
          &nbsp; {transport.toAddress.fullAddress}
        </p>
      );
    } else if (transport.type === ShipType.hub_hub) {
      return (
        <div>
          <p className={classes.text}>
            <strong>Địa chỉ hoàn hàng:</strong>
            &nbsp; {transport.toAddress.fullAddress}
          </p>
          <p className={classes.text}>
            <strong>Địa chỉ lấy hàng:</strong>
            &nbsp; {transport.fromAddress.fullAddress}
          </p>
        </div>
      );
    } else {
      return (
        <p className={classes.text}>
          <strong>Địa chỉ hoàn hàng:</strong>
          &nbsp; {transport.fromAddress.fullAddress}
        </p>
      );
    }
  } else {
    return (
      <p className={classes.text}>
        <strong>Địa chỉ nhận hàng:</strong>
        &nbsp; {transport.toAddress.fullAddress}
      </p>
    );
  }
};

export default withStyles(styles)(TransportAddress);
