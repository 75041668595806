import React from "react";
import Select from "react-select";
import { WMS_STATUS } from "@constants";

const OPTIONS = [
  { value: WMS_STATUS.pick, label: "Chưa đóng gói" },
  { value: WMS_STATUS.pack, label: "Đã đóng gói" },
];

class OtherSystemStatusFilter extends React.Component {
  render() {
    const { value, onChange, placeholder = "Chọn trạng thái..." } = this.props;
    return (
      <Select
        value={value}
        onChange={(value) => onChange(value ? value : "")}
        options={OPTIONS}
        simpleValue
        placeholder={placeholder}
        wrapperStyle={{ width: "100%" }}
      />
    );
  }
}

export default OtherSystemStatusFilter;
