import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { closeNotiDialog } from "..";

const styles = (theme) => ({
  dialog: {
    minWidth: "30rem",
  },
  paper: {
    borderRadius: "1rem",
  },
});

/*  Dialog thông báo Lưu/Cập nhật thành công hoặc thất bại
    Props: 
      open (Boolean) : trạng thái đóng/mở dialog
      onClose (func) : func đóng dialog
      type (success|failed|info|alert) : loại dialog
      text: text hiển thị trong dialog
*/
class NotificationDialog extends React.Component {
  constructor() {
    super();
    this.state = { loading: false };
  }
  getImageUrl = (type) => {
    switch (type) {
      case "success":
        return `ok_96.png`;
      case "failed":
        return `notallowed.png`;
      case "info":
        return `info.png`;
      case "alert":
        return `alert.png`;
      default:
        return `info.png`;
    }
  };

  handleClose = () => {
    const { onClose, notification } = this.props;
    onClose();
    if (notification.closeCallback) notification.closeCallback();
  };

  handleConfirm = async () => {
    const { notification } = this.props;
    this.setState({ loading: true });
    if (notification.confirmCallback) await notification.confirmCallback();
    this.setState({ loading: false });
    // onClose();
  };

  render() {
    const { notification, onClose, classes } = this.props;
    const { loading } = this.state;
    return (
      <Dialog
        open={notification.open}
        onClose={onClose}
        maxWidth="sm"
        classes={{ paper: classes.paper }}
      >
        <DialogContent className={classes.dialog}>
          <div style={{ textAlign: "center" }}>
            <img
              src={require(`@images/${this.getImageUrl(notification.type)}`)}
              alt="notification type"
            />
          </div>
          <br />
          <DialogContentText style={{ textAlign: "center", margin: "0 2rem" }}>
            {notification.text}
          </DialogContentText>
          <br />
        </DialogContent>
        <DialogActions>
          {notification.options === "single" ? (
            <Button onClick={this.handleClose}>OK</Button>
          ) : notification.options === "custom" ? (
            notification.customActionButtons({ onClose: this.handleClose })
          ) : (
            <React.Fragment>
              <Button onClick={this.handleConfirm} color="primary">
                {loading && <CircularProgress />} Đồng ý
              </Button>
              <Button onClick={this.handleClose} color="secondary">
                Hủy
              </Button>
            </React.Fragment>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  notification: state.admin.shared.notification,
});

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(closeNotiDialog()),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(NotificationDialog)
);
