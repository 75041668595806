import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";

//styles
const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    fontFamily: "sans-serif",
    justifyContent: "space-evenly",
    marginBottom: "3rem",
  },
  itemContainer: {
    height: "100%",
  },
  header: {
    fontWeight: "bold",
    background: "#37373D",
    color: "#FFFFFF",
  },
  cellOuterLeft: {
    borderLeft: "1px solid rgba(235,235,235,1)",
  },
  cellOuterRight: {
    borderRight: "2px solid rgba(235,235,235,1)",
  },
});

class SystemStatusContainer extends React.Component {
  render() {
    const { system, classes } = this.props;
    let total_user = 0;
    Object.keys(system.user_stat).forEach((element) => {
      total_user += system.user_stat[element];
    });
    return (
      <div className={classes.container}>
        <div className={classes.item}>
          <h2>Quản trị người dùng</h2>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.header}>
                  Tổng số người dùng
                </TableCell>
                <TableCell className={classes.header}>
                  Quản lý tổng - Admin
                </TableCell>
                <TableCell className={classes.header}>
                  Quản lý tỉnh - Mod
                </TableCell>
                <TableCell className={classes.header}>SKTV</TableCell>
                <TableCell className={classes.header}>Các role khác</TableCell>
                <TableCell className={classes.header}>Disabled user</TableCell>
                <TableCell className={classes.header}>
                  Tỉ lệ xử lí đơn hàng (đơn/người)
                </TableCell>
                <TableCell className={classes.header}>
                  Tỉ lệ đơn giao cho KTV (%)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.cellOuterLeft}>
                  {total_user}
                </TableCell>
                <TableCell>{system.user_stat.admin}</TableCell>
                <TableCell>{system.user_stat.mod}</TableCell>
                <TableCell>{system.user_stat.sktv}</TableCell>
                <TableCell>
                  {system.user_stat.onlyview +
                    system.user_stat.ktv +
                    system.user_stat.super_admin}
                </TableCell>
                <TableCell>{system.user_stat.disabled}</TableCell>
                <TableCell>{system.bl_per_staff_count}</TableCell>
                <TableCell className={classes.cellOuterRight}>
                  {(system.bl_by_ktv_rate || 0) * 100}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  system: state.admin.dashboard.system,
});

export default withStyles(styles)(
  connect(mapStateToProps, null)(SystemStatusContainer)
);
