import React from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import BlListContainer from "./bl_list";
import BlDetailContainer from "./bl_detail";
import { OperateDashboard } from "./dashboard";
import { BlGroupContainer } from "./bl_group";
import { Redirect, Switch } from "react-router";
import {
  fetchError,
  Forbidden,
  NotFound,
  ErrorDialog,
  getSellersList,
  UserManual,
  closeErrorDialog,
} from "@shared";
import { BlStateManagement } from "./admin-tool";
import { BusLineManagement } from "./bus_line_management";
import { ReportContainer } from "./report";
import { VersionManagement } from "./versions";
import {
  getChannels,
  fetchUser,
  fetchHubList,
  fetchKTVList,
  fetchAllKTVList,
  fetchRejectReasons,
  fetchAll3Pls,
  fetchDeliveryTypes,
  getWebSettings,
  fetchUserManual,
} from "./mainActions";
// styles
const drawerWidth = 280;
const styles = (theme) => ({
  contentShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  container: {
    padding: "1rem",
  },
  tooltip: {
    fontSize: 15,
    padding: 5,
  },
});

class AdminMain extends React.Component {
  constructor() {
    super();
    this.state = {
      activeDrags: 0,
      showManual: false,
    };
  }
  async componentDidMount() {
    const {
      fetchUser,
      fetchUserManual,
      getWebSettings,
      getChannels,
      getSellersList,
      fetchDeliveryTypes,
      fetchRejectReasons,
    } = this.props;
    await fetchUser();
    fetchUserManual();
    getWebSettings();
    getChannels();
    getSellersList();
    fetchDeliveryTypes();
    fetchRejectReasons();

    // phục vụ cho datetime picker
    moment.locale("vi");
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.user !== this.props.user &&
      nextProps.user.adminPagePermission
    ) {
      this.props.fetchHubList();
      this.props.fetchKTVList();
      this.props.fetchAllKTVList();
      this.props.fetchAll3Pls();
    }
    if (nextProps.user !== this.props.user && nextProps.user.isKTV === true) {
      this.props.fetchAllKTVList();
    }
  }

  checkAppearButton = (userManual) => {
    const path = window.location.pathname;
    let s = "";
    let key = "";
    let flag = false;
    for (let i = 0; i < path.length; i++) {
      s += path[i];
      if (userManual[s] !== undefined) {
        if (i < path.length - 1 && path[i + 1] !== "/") {
          continue;
        }
        flag = true;
        key = s;
      }
    }
    if (flag === true && key === "/" && path !== "/") {
      return { key: "", flag: false };
    } else {
      return { key: key, flag: flag };
    }
  };

  render() {
    const { user, fetching, error, forbidden, classes, userManual } =
      this.props;
    const manual = userManual.manual;
    const { key, flag } = manual
      ? this.checkAppearButton(manual)
      : { key: "", flag: false };
    if (user && user.isKTV) {
      return (
        <React.Fragment>
          <div className={classes.container}>
            <Switch>
              <Route path="/report">
                <ReportContainer isKTV={user.isKTV} />
              </Route>
              <Route path="*">
                <Redirect to="/report/shipper-daily-report-for-ktv" />
              </Route>
            </Switch>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {user && user.role && user.adminPagePermission ? (
            <div>
              {flag && <UserManual manual={manual} direct={key} />}
              <div className={classes.container}>
                <Switch>
                  <Route exact path="/">
                    <BlListContainer />
                  </Route>
                  <Route
                    path="/van-don/:id"
                    render={(props) => <BlDetailContainer {...props} />}
                  />
                  <Route
                    path="/bls-group"
                    render={(props) => <BlGroupContainer {...props} />}
                  />
                  <Route path="/dashboard/operate">
                    <OperateDashboard />
                  </Route>
                  <Route path="/versions">
                    <VersionManagement />
                  </Route>
                  <Route
                    path="/admin/bl-state-management"
                    component={BlStateManagement}
                  />
                  <Route
                    path="/admin/bus-line"
                    render={(props) =>
                      user.canAccessBusLineManagement ? (
                        <BusLineManagement {...props} />
                      ) : (
                        <Forbidden />
                      )
                    }
                  />
                  <Route
                    path="/report"
                    render={(props) => <ReportContainer {...props} />}
                  />
                  <Route path="*">
                    <NotFound />
                  </Route>
                </Switch>
              </div>
              <ErrorDialog
                error={error}
                closeErrorDialog={this.props.closeErrorDialog}
              />
            </div>
          ) : (
            forbidden && <Forbidden />
          )}
          {fetching && (
            <div>
              <div className="loading-shading-mui">
                <CircularProgress className="loading-icon-mui" />
              </div>
            </div>
          )}
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.admin.shared.user,
  fetching: state.admin.shared.fetching,
  error: state.admin.shared.error,
  forbidden: state.admin.shared.forbidden,
  userManual: state.admin.shared.manual,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUser: () => dispatch(fetchUser()),
  fetchHubList: () => dispatch(fetchHubList()),
  fetchError: (httpStatus, message) =>
    dispatch(fetchError(httpStatus, message)),
  fetchKTVList: () => dispatch(fetchKTVList()),
  closeErrorDialog: () => dispatch(closeErrorDialog()),
  fetchRejectReasons: () => dispatch(fetchRejectReasons()),
  fetchAll3Pls: () => dispatch(fetchAll3Pls()),
  fetchDeliveryTypes: () => dispatch(fetchDeliveryTypes()),
  getSellersList: () => dispatch(getSellersList()),
  getChannels: () => dispatch(getChannels()),
  fetchUserManual: () => dispatch(fetchUserManual()),
  getWebSettings: () => dispatch(getWebSettings()),
  fetchAllKTVList: () => dispatch(fetchAllKTVList()),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(AdminMain)
);
