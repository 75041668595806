import React from "react";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";
import classnames from "classnames";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { formatNumber } from "./../../../utils/misc";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
const styles = (theme) => ({
  productHeading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  subTitle: {
    fontSize: 14,
    fontFamily: "Source Sans Pro, sans-serif",
    textTransform: "uppercase",
    fontWeight: 600,
    letterSpacing: 1,
    color: "#f05050",
  },
  table: {
    margin: 8,
  },
  text: {
    fontFamily: "Noto Sans, sans-serif",
  },
  suggestPriceText: {
    fontFamily: "Noto Sans, sans-serif",
    color: "#0e34ff",
  },
  cell: {
    color: "#797979",
    fontWeight: 500,
    textAlign: "center",
  },
  nameCell: {
    color: "#797979",
    fontWeight: 500,
  },
  priceCell: {
    color: "#797979",
    fontWeight: 500,
    textAlign: "right",
  },
  blIdCell: {
    textTransform: "uppercase",
    fontWeight: "700",
    color: "#5D9FEE",
  },
});

class BlGroupLines extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: true,
    };
  }

  collapsePaper = () =>
    this.setState({
      collapse: !this.state.collapse,
    });

  render() {
    const { collapse } = this.state;
    const { bls, classes } = this.props;
    return (
      <div onClick={() => this.collapsePaper()}>
        <div className={classes.productHeading} onClick={this.collapsePaper}>
          <Typography type="title" className={classes.subTitle}>
            Danh sách sản phẩm
          </Typography>
          <IconButton
            aria-label="Xem thêm"
            className={classnames(classes.expand, {
              [classes.expandOpen]: !collapse,
            })}
          >
            <ExpandMoreIcon />
          </IconButton>
        </div>
        <Collapse in={!collapse} unmountOnExit>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell style={{ textAlign: "center" }}>STT</TableCell>
                <TableCell style={{ textAlign: "center" }}>SKU</TableCell>
                <TableCell>Tên sản phẩm</TableCell>
                <TableCell style={{ textAlign: "right" }}>
                  Đơn giá(VNĐ)
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>Số lượng</TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  Số lượng đã giao
                </TableCell>
              </TableRow>
            </TableHead>
            {bls &&
              bls.length &&
              bls.map((bl, idx) => (
                <TableBody key={idx}>
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Typography
                        variant="subtitle2"
                        className={classes.blIdCell}
                      >
                        Mã gói hàng {bl.document_id}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {bl.bl_lines.map((pr, _idx) => (
                    <TableRow key={`${idx}-${_idx}`}>
                      <TableCell className={classes.cell}>{_idx + 1}</TableCell>
                      <TableCell className={classes.cell}>{pr.sku}</TableCell>
                      <TableCell className={classes.nameCell}>
                        {pr.name}
                      </TableCell>
                      <TableCell className={classes.priceCell}>
                        {formatNumber(pr.price)}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {pr.quantity}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {pr.success_quantity}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ))}
          </Table>
        </Collapse>
      </div>
    );
  }
}

export default withStyles(styles)(BlGroupLines);
