import React from "react";
import Select from "react-select";

const OPTIONS = [
  { value: "1", label: "Đơn có thu COD" },
  { value: "0", label: "Đơn không thu COD" },
];

class BlCodFilter extends React.Component {
  render() {
    const { value, onChange } = this.props;
    return (
      <Select
        value={value}
        onChange={(status) => onChange(status ? status : "")}
        options={OPTIONS}
        simpleValue
        multi={true}
        placeholder="Chọn loại vận đơn..."
        wrapperStyle={{ width: "100%" }}
      />
    );
  }
}

export default BlCodFilter;
