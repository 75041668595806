import { Bl } from "../../../models";

const _initialState = {
  bls: [],
  filter: {
    keyword: "",
  },
  pagination: {
    total_records: 0,
    offset: 0,
    limit: 10,
  },
  tableLoading: false,
  editState: [], // -1: readonly, 0: loading, 1: editable
};

const blManagement = (state = _initialState, action) => {
  switch (action.type) {
    case "BL_MANAGEMENT_CHANGE_FILTER":
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      };
    case "BL_MANAGEMENT_FETCH_BL_LIST_SUCCESS":
      return {
        ...state,
        bls: action.payload.bls.map((x) => {
          return new Bl(x);
        }),
        pagination: {
          ...state.pagination,
          total_records: action.payload.total_records,
        },
        editState: Array(action.payload.bls.length).fill(false),
      };
    case "BL_MANAGEMENT_CHANGE_PAGINATION":
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
      };
    case "BL_MANAGEMENT_CHANGE_EDIT_STATE":
      return {
        ...state,
        editState: action.payload,
      };
    case "BL_MANAGEMENT_CHANGE_LOADING":
      return {
        ...state,
        tableLoading: action.payload,
      };
    default:
      return state;
  }
};

export default blManagement;
