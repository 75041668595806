import React from "react";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";

import {
  Dialog,
  ShipTypeSelection,
  ToHubSelection,
  ToKTVSelection,
  ShipperSelection,
  KTVAddress,
  ShipMethodSelection,
  PlRefInput,
  NoteInput,
  EstimatedDeliveryTimePicker,
} from "../../../../../shared";
import {
  BlDirection,
  RequestType,
  ShipMethod,
  ShipType,
} from "../../../../../../../models";

const _getFormErrorMessage = (errors) => {
  const fields = Object.keys(errors);
  let msg = [];
  if (fields.indexOf("shipper") > -1) {
    msg.push("Bạn chưa chọn KTV giao hàng.");
  }
  if (fields.indexOf("to_shipper_id") > -1) {
    msg.push("Bạn chưa chọn KTV nhận hàng.");
  }
  if (fields.indexOf("to_hub_id") > -1) {
    msg.push("Bạn chưa chọn kho nhận.");
  }
  if (fields.indexOf("ship_method") > -1) {
    msg.push("Bạn chưa chọn nhà vận chuyển.");
  }
  if (fields.indexOf("nv_phongvu") > -1) {
    msg.push("Bạn chưa nhập mã nhân viên giao hàng");
  }
  return msg;
};

const styles = (theme) => ({
  content: {
    display: "flex",
    justifyContent: "center",
    padding: "16px 32px 0 16px",
  },
  input: {
    height: 34,
    border: "1px solid #ccc",
    borderRadius: 4,
    paddingLeft: 8,
    width: "5rem",
  },
});

class CreateTransport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shipType: "",
      shipMethod: "",
      toHub: {
        id: "",
        fullAddress: "",
      },
      toKTV: {
        id: "",
        fullAddress: "",
      },
      shipper: {
        id: "",
        fullAddress: "",
      },
      plRef: "",
      note: "",
      error: {
        hasError: false,
        msg: "",
      },
      creating: false,
    };
    this.manualShipMethod = this.props.threePls
      .filter((x) => x.type.toLowerCase() === "manual")
      .map((x) => x.code_name);
  }

  canSelectKtv = () => {
    const { shipMethod } = this.state;
    return shipMethod === "ktv";
  };

  changeShipType = (shipType) => {
    this.setState(
      {
        shipType,
      },
      () => {
        if (shipType === ShipType.hub_ktv) {
          this.changeHubTo();
        } else if (shipType === ShipType.hub_hub) {
          this.changeKTVTo();
        } else if (shipType === ShipType.hub_customer) {
          this.changeHubTo();
          this.changeKTVTo();
        } else if (shipType === ShipType.customer_hub) {
          this.changeHubTo();
        }
        this.changePlRef();
      }
    );
  };

  changeShipMethod = (shipMethod) => {
    this.setState(
      {
        shipMethod: shipMethod,
      },
      () => {
        this.changePlRef();
        if (this.props.bl.isWhTransfer) {
          this.setState({
            shipType:
              this.props.bl.holderType === "hub"
                ? ShipType.hub_customer
                : ShipType.customer_customer,
          });
        }
      }
    );
  };

  changeHubTo = (toHub) => {
    if (toHub) {
      this.setState({
        toHub,
      });
    } else {
      this.setState({
        toHub: {
          id: "",
          fullAddress: "",
        },
      });
    }
  };

  changeKTVTo = (toKTV) => {
    if (toKTV) {
      this.setState({
        toKTV,
      });
    } else {
      this.setState({
        toKTV: {
          id: "",
          fullAddress: "",
        },
      });
    }
  };

  changeShipper = (shipper) => {
    if (shipper) {
      this.setState({
        shipper: {
          id: shipper.value,
          fullAddress: shipper.fullAddress,
        },
      });
    } else {
      this.setState({
        shipper: {
          id: "",
          fullAddress: "",
        },
      });
    }
  };

  changePlRef = (plRef) =>
    this.setState({
      plRef,
    });

  changeEDT = (time) =>
    this.setState({
      edt: time,
    });

  changeNote = (note) =>
    this.setState({
      note,
    });

  toggleFormErrorDialog = (errors = []) =>
    this.setState({
      error: {
        hasError: !this.state.error.hasError,
        msg: errors,
      },
      creating: false,
    });

  createTransport = async () => {
    const { shipType, shipMethod, toHub, toKTV, shipper, plRef, note } =
      this.state;
    const { bl } = this.props;
    this.setState({
      creating: true,
    });
    if (shipMethod === ShipMethod.nv_phongvu && (!plRef || !plRef.trim())) {
      this.toggleFormErrorDialog({ nv_phongvu: "required" });
      return;
    }
    let response = await this.props.createTransport({
      id: bl.id,
      shipType: shipType,
      shipMethod: shipMethod,
      toHub: toHub,
      toKTV: toKTV,
      shipper: shipper,
      plRef: plRef,
      note: note,
    });

    if (response) {
      if (response.error) {
        this.toggleFormErrorDialog(response.msg);
      }
    }
  };

  componentDidMount() {
    const { bl } = this.props;
    /**
     * Set ship type mặc định cho transport
     *  - Trường hợp vận đơn chuyển kho: mặc định là hub_hub
     *  - Trường hợp hoàn hàng: mặc định là hub_hub
     *  - Chuyển kho nội bộ: customer_customer nếu hàng tại kho, hub_customer nếu hàng tại hub
     *  - Others: hub_customer
     */

    if (bl.requestType.toLowerCase() === "wh_transfer") {
      this.setState({
        shipType:
          bl.holderType === "hub"
            ? ShipType.hub_customer
            : ShipType.customer_customer,
        toHub: {
          id: bl.toAddr.objId,
          fullAddress: bl.toAddr.fullAddress,
        },
      });
    } else if (bl.direction === BlDirection.returning) {
      this.setState({
        shipType: ShipType.hub_hub,
      });
    } else {
      this.setState({
        shipType: ShipType.hub_customer,
      });
    }
  }

  canSelectToHub = () => {
    const { bl } = this.props;
    const { shipType } = this.state;
    if (bl.direction === BlDirection.returning) return true;
    if (shipType === ShipType.hub_hub && !bl.isWhTransfer) return true;
    if (
      [ShipType.customer_hub, ShipType.hub_hub].includes(shipType) &&
      bl.requestType.toLowerCase() === "wh_transfer"
    )
      return true;
    return false;
  };

  canShowToHubAddr = () => {
    const { bl } = this.props;
    const { toHub, shipType } = this.state;
    if (toHub.id) {
      if (!bl.isWhTransfer) return true;
      if (
        [ShipType.customer_hub, ShipType.hub_hub].includes(shipType) &&
        bl.isWhTransfer
      )
        return true;
    }
    return false;
  };
  render() {
    const {
      shipType,
      shipMethod,
      toHub,
      toKTV,
      shipper,
      plRef,
      creating,
      error,
    } = this.state;
    const { bl, hubs, ktvList, classes, threePls, change } = this.props;
    return (
      <div className={classes.container}>
        <Grid container wrap="nowrap" alignItems="center">
          <Grid item>
            <strong>
              <span className={classes.mustHave}> * </span>Số kiện
            </strong>
          </Grid>
          <Grid item>
            <input className={classes.input} />
          </Grid>
          <Grid item>
            <strong>
              <span className={classes.mustHave}> * </span>Khối lượng
            </strong>
          </Grid>
          <Grid item>
            <input className={classes.input} />
          </Grid>
          <Grid item>
            <strong>Kích thước</strong>
          </Grid>
          <Grid item>
            <input className={classes.input} />X
            <input className={classes.input} />X
            <input className={classes.input} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={1}>
            <strong>Gửi đến</strong>
          </Grid>
          <Grid item>
            <ShipTypeSelection
              bl={bl}
              shipType={shipType}
              shipMethod={shipMethod}
              changeShipType={this.changeShipType}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <strong>Phương thức vận chuyển</strong>
          </Grid>
          <Grid item md={4}>
            <ShipMethodSelection
              shipMethod={shipMethod}
              bl={bl}
              changeShipMethod={this.changeShipMethod}
              all={true}
              threePls={threePls}
            />
          </Grid>
          {this.manualShipMethod.includes(shipMethod) && (
            <Grid item>
              <PlRefInput
                shipMethod={shipMethod}
                toHub={toHub}
                plRef={plRef}
                changePlRef={this.changePlRef}
              />
            </Grid>
          )}
        </Grid>
        {this.canSelectKtv() && (
          <Grid container>
            <Grid item>
              <strong>KTV chính</strong>
            </Grid>
            <Grid item>
              <ShipperSelection
                shipper={shipper}
                ktvList={ktvList}
                changeShipper={this.changeShipper}
              />
            </Grid>
            <Grid item>
              <strong>KTV phụ</strong>
            </Grid>
            <Grid item>
              <ShipperSelection
                shipper={shipper}
                ktvList={ktvList}
                changeShipper={this.changeShipper}
              />
            </Grid>
          </Grid>
        )}

        {this.canSelectToHub() && (
          <Grid container>
            <Grid item md={6}>
              <ToHubSelection
                bl={bl}
                hubs={hubs}
                toHub={toHub}
                changeHubTo={this.changeHubTo}
              />
            </Grid>

            {this.canShowToHubAddr() && (
              <Grid item>
                <span style={{ display: "inline-flex" }}>
                  Địa chỉ kho:&nbsp;<span>{toHub.fullAddress}</span>
                </span>
              </Grid>
            )}
          </Grid>
        )}
        {shipType === ShipType.hub_ktv && (
          <Grid container>
            <Grid item md={6}>
              <ToKTVSelection
                toKTV={toKTV}
                ktvList={ktvList}
                changeKTVTo={this.changeKTVTo}
              />
            </Grid>

            <Grid item>
              <span style={{ display: "inline-flex" }}>
                Địa chỉ KTV:&nbsp;
                <KTVAddress address={toKTV.fullAddress} />
              </span>
            </Grid>
          </Grid>
        )}
        <Grid container>
          <Grid item>
            <strong>Ngày hẹn giao</strong>
          </Grid>
          <Grid item md={4}>
            <EstimatedDeliveryTimePicker />
          </Grid>
          <Grid item>
            <strong>Ghi chú</strong>
          </Grid>
          <Grid item md={6}>
            <NoteInput changeNote={this.changeNote} />
          </Grid>
        </Grid>
        <IconButton onClick={this.createTransport}>
          {creating ? (
            <CircularProgress size={20} style={{ color: "#3f51b5" }} />
          ) : (
            <i className="material-icons" style={{ color: "#3f51b5" }}>
              save
            </i>
          )}
        </IconButton>

        <Dialog
          open={error.hasError}
          title="Có lỗi xảy ra"
          closeDialog={this.toggleFormErrorDialog}
        >
          {_getFormErrorMessage(error.msg).map((err, index) => (
            <p key={index}> - {err}</p>
          ))}
          <p style={{ color: "#ff0000" }}>
            <span>*Lưu ý: </span>
            Các trường trên là bắt buộc. Vui lòng điền đầy đủ thông tin trước
            khi tạo lộ trình!
          </p>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(CreateTransport);
