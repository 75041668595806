import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemIcon,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { Chart } from "react-google-charts";
import moment from "moment";

//styles
const styles = (theme) => ({
  list: {
    fontSize: "1.2rem",
  },
});

class ThreePlsStatusContainer extends React.Component {
  options = {
    legend: "none",
    title: "Biểu đồ thể hiện tình trạng giao hàng của 3PLs",
    is3D: true,
    slices: {
      1: { color: "#CDDC39" },
      0: { color: "#53C2FF" },
      2: { color: "#BA68C8" },
      4: { color: "#EE9F00" },
      3: { color: "#333333" },
      5: { color: "#4CAF50" },
    },
  };

  events = () => {
    const { start_date, end_date } = this.props;
    const threePlsList = this.props.allThreePls.map((x) => x.code_name);
    return [
      {
        eventName: "select",
        callback: ({ chartWrapper }) => {
          const chart = chartWrapper.getChart();
          const selection = chart.getSelection();
          if (selection.length > 0) {
            let url = `/?export_date_from=${moment(start_date).format(
              "YYYY-MM-DD"
            )}&export_date_to=${moment(end_date).format(
              "YYYY-MM-DD"
            )}&last_transport_ship_method=${threePlsList.toString()}`;

            switch (selection[0].row) {
              case 0:
                url += "&quick_filter=on_delivering";
                break;
              case 1:
                url += "&quick_filter=waiting_for_pick";
                break;
              case 2:
                url += "&quick_filter=on_returning,on_partial_returning";
                break;
              case 3:
                url += "&quick_filter=returned,on_partial_returned";
                break;
              case 4:
                url += "&quick_filter=cancel_shipping_transport";
                break;
              case 5:
                url += "&quick_filter=delivered";
                break;
            }
            window.open(url, "_blank");
          }
        },
      },
    ];
  };

  render() {
    const { threePlsStatus, classes } = this.props;
    const threePlsData = [
      ["Trạng thái", "Số đơn"],
      ["Đang giao", threePlsStatus.on_delivering],
      ["Chờ lấy hàng", threePlsStatus.waiting_for_pick],
      ["Đang hoàn hàng", threePlsStatus.total_returning],
      ["Đã hoàn hàng", threePlsStatus.total_returned],
      ["Đang hủy lộ trình", threePlsStatus.cancel_shipping_transport],
      ["Giao thành công", threePlsStatus.delivered],
    ];

    return (
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1, minHeight: "40rem" }}>
          <Chart
            width="100%"
            height="100%"
            chartType="PieChart"
            chartEvents={this.events()}
            loader={
              <React.Fragment>
                <CircularProgress /> Đang lấy dữ liệu
              </React.Fragment>
            }
            data={threePlsData}
            options={this.options}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <h2>Tổng đơn gán cho 3PLs: {threePlsStatus.total} đơn</h2>
            </Grid>
            <Grid item xs={6}>
              <List component="nav" className={classes.list}>
                <ListItem>
                  <ListItemIcon>
                    <i className="material-icons" style={{ color: "#CDDC39" }}>
                      stop
                    </i>
                  </ListItemIcon>
                  Chờ lấy hàng: {threePlsStatus.waiting_for_pick}
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <i className="material-icons" style={{ color: "#53C2FF" }}>
                      stop
                    </i>
                  </ListItemIcon>
                  Đang giao: {threePlsStatus.on_delivering}
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <i className="material-icons" style={{ color: "#BA68C8" }}>
                      stop
                    </i>
                  </ListItemIcon>
                  Đang hoàn hàng: {threePlsStatus.total_returning}
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={6}>
              <List component="nav" className={classes.list}>
                <ListItem>
                  <ListItemIcon>
                    <i className="material-icons" style={{ color: "#333333" }}>
                      stop
                    </i>
                  </ListItemIcon>
                  Đã hoàn hàng: {threePlsStatus.total_returned}
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <i className="material-icons" style={{ color: "#EE9F00" }}>
                      stop
                    </i>
                  </ListItemIcon>
                  Đang hủy lộ trình: {threePlsStatus.cancel_shipping_transport}
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <i className="material-icons" style={{ color: "#4CAF50" }}>
                      stop
                    </i>
                  </ListItemIcon>
                  Giao thành công: {threePlsStatus.delivered}
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  threePlsStatus: state.admin.dashboard.statistic.threepls_stat,
  ktvStatus: state.admin.dashboard.statistic.ktv_stat,
  blStatus: state.admin.dashboard.statistic.bls_stat,
  start_date: state.admin.dashboard.statistic.start_date,
  end_date: state.admin.dashboard.statistic.end_date,
  allThreePls: state.admin.shared.allThreePls,
});

const mapDispatchToProps = (dispatch) => ({});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ThreePlsStatusContainer)
);
