import React from "react";
import HubFilter from "./HubFilter";

const ToHubFilter = ({ value, hubs, setFilter, sellerId }) => {
  let newHubs = sellerId === "" ? hubs : [];
  const sellerIds = sellerId.split(",");
  for (let i = 0; i < sellerIds.length; i++) {
    for (let j = 0; j < hubs.length; j++) {
      if (hubs[j].sellerId && hubs[j].sellerId.toString() === sellerIds[i]) {
        newHubs = [...newHubs, hubs[j]];
      }
    }
  }
  return (
    <HubFilter
      value={value}
      hubs={newHubs}
      type="toHub"
      setFilter={setFilter}
    />
  );
};

export default ToHubFilter;
