import React from "react";
import { withStyles } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import {
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
} from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { mapBlStatusColor, mapBlStatusText } from "@utils";

const styles = (theme) => ({
  icon: {
    fontSize: 20,
    color: "#2f4f4fc4",
    "&:hover": {
      color: "#0078d7",
    },
  },
  tooltip: {
    fontSize: 15,
    padding: 5,
  },
  cellPlref: {
    fontSize: 14,
    textAlign: "center",
  },
  stepLabel: {
    color: "#fff",
    fontSize: 12,
    fontWeight: 800,
    padding: 4,
    fontFamily: "Noto Sans, sans-serif",
    borderRadius: 4,
  },
  headPlref: {
    position: "sticky",
    top: 0,
    textAlign: "center",
    color: "#fff",
    backgroundColor: "#6e78b1",
    zIndex: 1000,
  },
  tableWrapper: {
    maxHeight: 350,
    overflow: "auto",
  },
});

class TransportBlSamePlref extends React.Component {
  render() {
    const { openCollapse, groupedBls, classes, id } = this.props;
    const data = groupedBls.filter((element) => element.bl_id !== id);
    return (
      <React.Fragment>
        {data.length > 0 && (
          <Collapse in={openCollapse} unmountOnExit>
            {data.length > 0 && (
              <div className={classes.tableWrapper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.headPlref} />
                      <TableCell className={classes.headPlref}>
                        Mã vận đơn
                      </TableCell>
                      <TableCell className={classes.headPlref}>
                        Mã đơn hàng
                      </TableCell>
                      <TableCell className={classes.headPlref}>
                        Mã gói hàng
                      </TableCell>
                      <TableCell className={classes.headPlref}>
                        Trạng thái vận đơn
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((element, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell className={classes.cellPlref}>
                            <Tooltip
                              placement="right"
                              title={
                                <div className={classes.tooltip}>
                                  Click vào để xem chi tiết
                                </div>
                              }
                            >
                              <a
                                href={`/van-don/${element.bl_id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i
                                  className={`fas fa-search-plus ${classes.icon}`}
                                />
                              </a>
                            </Tooltip>
                          </TableCell>
                          <TableCell className={classes.cellPlref}>
                            <span>{element.bl_id}</span>
                          </TableCell>
                          <TableCell className={classes.cellPlref}>
                            {element.order_code}
                          </TableCell>
                          <TableCell className={classes.cellPlref}>
                            {element.document_id}
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            <span
                              className={classes.stepLabel}
                              style={{
                                backgroundColor: mapBlStatusColor(
                                  element.display_state
                                ),
                                color:
                                  mapBlStatusColor(element.display_state) ===
                                  "#fff"
                                    ? "#333"
                                    : "#fff",
                              }}
                            >
                              {mapBlStatusText(element.display_state)}
                            </span>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            )}
          </Collapse>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  groupedBls: state.admin.detail.groupedBls,
});

export default withStyles(styles)(
  connect(mapStateToProps, null)(TransportBlSamePlref)
);
