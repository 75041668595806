import apiService from "./base";

export const getTransportStatisticApi = (params) => {
  return apiService({
    url: "/statistic/bl-transport",
    method: "get",
    params: params,
  });
};

export const getSystemStatisticApi = (params) => {
  return apiService({
    url: "/statistic/system",
    method: "get",
    params: params,
  });
};

export const dumpStatisticmApi = null;
