import _ from "lodash";
import moment from "moment";

const _initialState = {
  filter: {
    shipper_id: "",
    from_date: moment().toDate(),
    to_date: moment().toDate(),
  },
  summary: {
    bls_total: {
      total_delivered: 0,
      partial_returned: 0,
      total_canceled: 0,
      returned: 0,
    },
    total_distance: 0,
    total_fee: 0,
  },
  loading: false,
  renderLocation: false,
  data: [],
  map: {
    center: {
      lat: 0,
      lng: 0,
    },
    zoom: 15,
  },
};

const ShipperDailyReport = (state = _initialState, action) => {
  switch (action.type) {
    case "SHIPPER_DAILY_REPORT_CHANGE_FILTER":
      return {
        ...state,
        filter: action.payload,
      };
    case "SHIPPER_DAILY_REPORT_CHANGE_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "SHIPPER_DAILY_REPORT_SET_DATA":
      const data = action.payload;
      return {
        ...state,
        summary: data.summary,
        data: data.items,
      };
    case "SHIPPER_DAILY_REPORT_SET_MAP": {
      return {
        ...state,
        map: {
          ...state.map,
          ...action.payload,
        },
      };
    }
    case "SET_DEFAULT_USER": {
      return {
        ...state,
        filter: {
          ...state.filter,
          shipper_id: action.payload,
        },
      };
    }
    case "SWITCH_RENDER_LOCATION": {
      return {
        ...state,
        renderLocation: action.payload,
      };
    }
    default:
      return state;
  }
};

export default ShipperDailyReport;
