import React from "react";
import _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  IconButton,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";
import DatePicker from "react-date-picker";
import { openNotiDialog } from "@shared";
import { connect } from "react-redux";
import { isMaxTrustUser } from "@utils";

const styles = () => ({
  "@global": {
    ".customDatePicker .react-date-picker__wrapper": {
      height: 39,
      border: "1px solid #ccc",
      borderRadius: 4,
    },
    "table#extraKtvTable": {
      borderSpacing: 0,
      width: "90%",
      fontSize: 12,
    },
    "#extraKtvTable thead > tr": {
      background: "#1d2480a3",
    },
    "#extraKtvTable thead > tr > td": {
      color: "white",
      borderBottom: "1px solid #ffffff6e",
      borderRight: "1px solid #ffffff6e",
    },
    "#extraKtvTable tbody > tr:nth-child(odd)": {
      background: "#d6e3f752",
    },
    "#extraKtvTable tbody > tr > td:first-child": {
      borderLeft: "1px solid #0000000d",
    },
    "#extraKtvTable tbody > tr > td:last-child": {
      borderRight: "1px solid #0000000d",
    },
    "#extraKtvTable tbody > tr:last-child > td": {
      borderBottom: "1px solid #0000000d",
    },
  },
});
const wait = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

class TransportShipperList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editable: false,
      ktvListClone: _.cloneDeep(this.props.ktvList),
      loading: false,
    };
  }

  handleSaveTimeKeeping = async () => {
    const { editable, ktvListClone } = this.state;
    const { updateTimeKeeping } = this.props;
    if (editable) {
      const data = ktvListClone.map((x) => ({
        id: x.ktv.id,
      }));
      this.setState({ loading: true });
      const response = await updateTimeKeeping(data);
      await wait(1500);
      this.setState({ loading: false });
      if (response.status === 200) {
        this.setState({ editable: false });
      }
    } else {
      this.setState({ editable: !editable });
    }
  };

  handleChangeTimeKeeping = (type, index, value) => {
    const { ktvListClone } = this.state;
    const { ktvList } = this.props;
    const newKtvList = _.cloneDeep(ktvListClone);
    const prevType = ktvList[index].timekeeping_type;
    const curType = ktvListClone[index].timekeeping_type;
    let newType = curType;
    switch (prevType) {
      case 1:
        newType = value ? 3 : 1;
        break;
      case 2:
        newType = value ? 3 : 2;
        break;
      case 0:
        if (curType === 1) newType = type === "isCheckin" ? 0 : 3;
        if (curType === 2) newType = type === "isCheckin" ? 3 : 0;
        if (curType === 3) newType = type === "isCheckin" ? 2 : 1;
        if (curType === 0) newType = type === "isCheckin" ? 1 : 2;
        break;
    }
    newKtvList[index].timekeeping_type = newType;
    this.setState({ ktvListClone: newKtvList });
  };

  canUpdateTimeKeeping = () => {
    const { ktvList } = this.props;
    const uniqueTimeKeepingType = new Set(
      ktvList.map((x) => x.timekeeping_type)
    );
    return !(uniqueTimeKeepingType.has(3) && uniqueTimeKeepingType.size === 1);
  };

  render() {
    const { editable, ktvListClone, loading } = this.state;
    const { transport, ktvList } = this.props;
    const isMaxTrust = isMaxTrustUser();
    return (
      <table id="extraKtvTable">
        <thead>
          <tr>
            <td rowSpan={2} align="center">
              KTV
            </td>
            <td rowSpan={2} align="center">
              KTV chính
            </td>
          </tr>
        </thead>
        <tbody>
          {ktvListClone.map((ktv, idx) => (
            <tr key={idx}>
              <td style={{ paddingLeft: 5 }}>
                {ktv.ktv.asia_id && `${ktv.ktv.asia_id} - `}
                {ktv.ktv.id} - {ktv.ktv.name} - SĐT: {ktv.ktv.phone}
              </td>
              <td align="center">
                <Checkbox
                  color="primary"
                  checked={ktv.ktv.id === transport.shipper.id}
                  disabled={true}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  openNotiDialog: (type, text, closeCallback, options, confirmCallback) =>
    dispatch(
      openNotiDialog(type, text, closeCallback, options, confirmCallback)
    ),
});

export default withStyles(styles)(
  connect(null, mapDispatchToProps)(TransportShipperList)
);
