import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";

import { TransportState } from "../../../../../models";

const styles = (theme) => ({
  text: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
    color: "#797979",
    marginBottom: 8,
  },
  handler: {
    cursor: "pointer",
    color: "#365899",
  },
  cell: {
    color: "#797979",
    padding: "4px 16px 4px 16px",
  },
  table: {
    margin: 8,
  },
});

class TransportLines extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
    };
  }

  toggleCollapse = () =>
    this.setState({
      collapse: !this.state.collapse,
    });

  render() {
    const { collapse } = this.state;
    const { transport, classes } = this.props;
    return (
      <div className={classes.text}>
        <strong>
          Danh sách sản phẩm&nbsp;&nbsp;
          <span onClick={this.toggleCollapse} className={classes.handler}>
            Xem chi tiết
          </span>
        </strong>
        <Collapse in={collapse} unmountOnExit>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 20, padding: "4px 16px 4px 16px" }}>
                  STT
                </TableCell>
                <TableCell style={{ width: 140, padding: "4px 16px 4px 16px" }}>
                  SKU
                </TableCell>
                <TableCell style={{ width: 140, padding: "4px 16px 4px 16px" }}>
                  Mã nhóm
                </TableCell>
                <TableCell style={{ padding: "4px 16px 4px 16px" }}>
                  Tên sản phẩm
                </TableCell>
                <TableCell style={{ width: 60, padding: "4px 16px 4px 16px" }}>
                  Số lượng
                </TableCell>
                <TableCell style={{ width: 60, padding: "4px 16px 4px 16px" }}>
                  {[TransportState.returning, TransportState.returned].includes(
                    transport.state
                  )
                    ? "Đã hoàn"
                    : "Đã giao"}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transport.transportLines.map((pr, idx) => (
                <TableRow key={idx}>
                  <TableCell className={classes.cell}>{idx + 1}</TableCell>
                  <TableCell className={classes.cell}>{pr.sku}</TableCell>
                  <TableCell className={classes.cell}>
                    {pr.category_code}
                  </TableCell>
                  <TableCell className={classes.cell}>{pr.name}</TableCell>
                  <TableCell className={classes.cell}>{pr.quantity}</TableCell>
                  <TableCell className={classes.cell}>
                    {pr.success_quantity}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Collapse>
      </div>
    );
  }
}

export default withStyles(styles)(TransportLines);
