import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  paper: {
    borderRadius: "1rem",
  },
});

export const ErrorDialog = ({
  classes,
  open,
  title,
  children,
  action,
  closeDialog,
  fullWidth,
}) => (
  <Dialog
    fullWidth={fullWidth}
    open={open}
    onClose={closeDialog}
    classes={{
      paper: classes.paper,
    }}
  >
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{children}</DialogContentText>
    </DialogContent>
    <DialogActions>
      {action && (
        <Button color="primary" onClick={action}>
          Xác nhận
        </Button>
      )}
      <Button color="primary" onClick={closeDialog}>
        Đóng
      </Button>
    </DialogActions>
  </Dialog>
);

export default withStyles(styles)(ErrorDialog);
