import React from "react";
import Select from "react-select";

import Dialog from "../Dialog";
import { checkAddress, checkPhoneNumber } from "../../../utils";

class ToKTVSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toKTV: {
        id: "",
        fullAddress: "",
      },
      error: {
        type: "",
        hasError: false,
        msg: [],
      },
    };
  }
  UNSAFE_componentWillReceiveProps(nextProp) {
    this.setState({
      toKTV: {
        id: nextProp.toKTV.id,
        fullAddress: nextProp.toKTV.fullAddress,
      },
    });
  }

  changeKTVTo = (ktv) => {
    const { allowEdit } = this.props;
    if (ktv) {
      const { ktvList } = this.props;
      let errors = [];
      let selectedKTV = ktvList.filter((k) => k.value === ktv.value)[0];

      if (!checkPhoneNumber(selectedKTV.phone)) {
        errors.push("số điện thoại liên hệ");
      }
      if (!checkAddress(selectedKTV.fullAddress, selectedKTV.areaCode)) {
        errors.push("địa chỉ");
      }

      if (errors.length && !allowEdit) {
        this.toggleErrorDialog(errors, "ktv");
      } else {
        this.setState(
          {
            toKTV: {
              id: ktv.value,
              fullAddress: ktv.fullAddress,
            },
          },
          () => {
            if (errors.length && allowEdit) {
              this.props.changeKTVTo(this.state.toKTV, true);
            } else {
              this.props.changeKTVTo(this.state.toKTV, false);
            }
          }
        );
      }
    } else {
      this.setState(
        {
          toKTV: {
            id: "",
            fullAddress: "",
          },
        },
        () => {
          this.props.changeKTVTo(this.state.toKTV);
        }
      );
    }
  };

  toggleErrorDialog = (errors = [], type = "") =>
    this.setState(
      {
        error: {
          type: type,
          hasError: !this.state.error.hasError,
          msg: errors,
        },
        toKTV: {
          id: "",
          fullAddress: "",
        },
      },
      () => {
        if (
          checkPhoneNumber(this.state.toKTV.phone) &&
          checkAddress(this.state.toKTV.fullAddress)
        )
          this.props.changeKTVTo(this.state.toKTV);
      }
    );

  render() {
    const { toKTV, error } = this.state;
    const { ktvList } = this.props;
    return (
      <div>
        <Select
          value={toKTV.id}
          onChange={(ktv) => this.changeKTVTo(ktv)}
          options={ktvList}
          placeholder="Chọn KTV nhận hàng"
          style={{ height: 40 }}
        />
        <Dialog
          open={error.hasError}
          closeDialog={this.toggleErrorDialog}
          title="Có lỗi xảy ra!"
        >
          {error.type === "ktv" ? "Kĩ thuật viên" : "Kho"} bạn vừa chọn có
          {error.msg.length &&
            error.msg.map((err, idx) => {
              if (idx === 0) return ` ${err} `;
              else if (idx === error.msg.length - 1) return ` và ${err} `;
              return `, ${err}`;
            })}
          không hợp lệ. Vui lòng liên hệ Logistic team để cập nhật thông tin{" "}
          {error.type === "ktv" ? "KTV" : "kho"}.
        </Dialog>
      </div>
    );
  }
}

export default ToKTVSelection;
