const _initialState = {
  bls: [], //all filtered bls
  selectedBls: [], //selected bls for grouping
  searchBtn: false,
  dialog: {
    isOpen: false,
    title: "",
    content: "",
    renderCustomButtons: null,
  },
  detailDialog: false,
  threePls: [],
  filters: {
    sale_order: {
      seller_id: "1",
      from_hub_id: "",
      export_date_from: null,
      export_date_to: null,
      request_type: "sale_order",
      quick_filter: "waiting_for_confirm",
      packed_status: "packed",
      offset: 0,
      limit: -1,
    },
    wh_transfer: {
      seller_id: "1",
      from_hub_id: "",
      to_hub_id: "",
      export_date_from: null,
      export_date_to: null,
      request_type: "wh_transfer",
      quick_filter: "waiting_for_confirm",
      packed_status: "packed",
      offset: 0,
      limit: -1,
    },
  },
  updateAddress: {},
  saveLoading: false,
  lastSearchGroupType: "sale_order",
  groupType: "sale_order",
  shipMethod: "adidi",
  currentBl: {},
};

const group = (state = _initialState, action) => {
  switch (action.type) {
    case "GET_BL_LIST_BY_ID":
      return {
        ...state,
        bls: action.payload.bls,
      };
    case "BL_GROUP_SET_SELECTED_BLS":
      return {
        ...state,
        selectedBls: action.payload,
      };
    case "CHANGE_SELECTION":
      return {
        ...state,
        selectedBls: action.payload.val
          ? state.selectedBls.includes(action.payload.ele)
            ? state.selectedBls
            : [...state.selectedBls, action.payload.ele]
          : state.selectedBls.filter((x) => x !== action.payload.ele),
      };
    case "SELECT_ALL":
      return {
        ...state,
        selectedBls: action.payload ? state.bls : [],
      };
    case "SET_SEARCH_BTN_PROGRESS":
      return {
        ...state,
        searchBtn: action.payload,
      };
    case "CHANGE_BL_GROUP_CREATE_TRANSPORT_FORM": {
      return {
        ...state,
        form: {
          ...state.form,
          ...action.payload,
        },
      };
    }
    case "BL_GROUP_FILTER_CHANGE": {
      const groupType = state.groupType;
      return {
        ...state,
        filters: {
          ...state.filters,
          [groupType]: {
            ...state.filters[groupType],
            ...action.payload,
          },
        },
      };
    }
    case "TOGGLE_BL_GROUP_DETAIL_DIALOG": {
      return {
        ...state,
        detailDialog: action.payload,
        currentBl: action.payload ? state.selectedBls[0] : state.currentBl,
      };
    }
    case "GET_BL_GROUP_3PLS_LIST": {
      return {
        ...state,
        threePls: action.payload,
      };
    }
    case "BL_GROUP_CHANGE_GROUP_TYPE": {
      return {
        ...state,
        groupType: action.payload,
      };
    }
    case "BL_GROUP_SET_SAVE_LOADING": {
      return {
        ...state,
        saveLoading: action.payload,
      };
    }
    case "BL_GROUP_SET_LAST_SEARCH_GROUP_TYPE": {
      return {
        ...state,
        lastSearchGroupType: action.payload,
      };
    }
    case "BL_GROUP_SET_SHIP_METHOD": {
      return {
        ...state,
        shipMethod: action.payload,
      };
    }
    case "BL_GROUP_SET_CURRENT_BL": {
      return {
        ...state,
        currentBl: action.payload,
      };
    }
    default:
      return state;
  }
};

export default group;
