import React from "react";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import fetchSuccess from "../shared/actions/fetchSuccess";
import BlStatusContainer from "./operate/blStatusContainer";
import ThreePlsStatusContainer from "./operate/threePlsStatusContainer";
import { ExpandCard } from "./common";
import BlstatusFilter from "./operate/blstatusFilter";
import moment from "moment";
import { getStatistic, getSystemStatus } from "./actions";
import KtvStatusContainer from "./operate/ktvStatusContainer";
import SystemStatusContainer from "./operate/SystemStatusContainer";

class OperateDashBoard extends React.Component {
  componentDidMount() {
    const { fetchSuccess, getStatistic, getSystemStatus } = this.props;
    getStatistic();
    getSystemStatus();
    fetchSuccess();
  }

  render() {
    const { statistic } = this.props;
    return (
      <Grid
        container
        style={{
          fontFamily: "sans-serif",
        }}
      >
        <Grid item xs={12}>
          <ExpandCard title="dashboard" filter={<BlstatusFilter />}>
            <div
              style={{
                textAlign: "center",
                fontSize: "2rem",
                paddingBottom: "2rem",
                color: "#585c5e",
              }}
            >
              Thống kê hệ thống từ ngày{" "}
              <strong>
                {moment(statistic.start_date).format("DD/MM/YYYY")}
              </strong>{" "}
              đến ngày{" "}
              <strong>{moment(statistic.end_date).format("DD/MM/YYYY")}</strong>
            </div>
            <BlStatusContainer />
            <ThreePlsStatusContainer />
            <KtvStatusContainer />
            <SystemStatusContainer />
          </ExpandCard>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  statistic: state.admin.dashboard.statistic,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSuccess: () => dispatch(fetchSuccess()),
  getStatistic: () => dispatch(getStatistic()),
  getSystemStatus: () => dispatch(getSystemStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OperateDashBoard);
