import React from "react";
import Select from "react-select";
import { withStyles } from "@material-ui/core/styles";
import { AddressType } from "../../../../../models";
import Dialog from "../Dialog";
import { checkPhoneNumber, checkAddress } from "../../../utils";

const styles = (theme) => ({
  disabledInput: {
    height: 32,
    paddingLeft: 8,
    borderTop: "1px solid #ccc",
    border: "1px solid #ccc",
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    color: "#797979",
    fontSize: 16,
    cursor: "pointer",
  },
});

class ToHubSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toHub: {
        id: "",
        fullAddress: "",
        name: "",
        areaCode: "",
      },
      error: {
        hasError: false,
        msg: [],
      },
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { toHub } = nextProps;
    if (JSON.stringify(toHub) !== JSON.stringify(this.props.toHub)) {
      this.setState({ toHub: toHub });
    }
  }

  changeHubTo = (hub) => {
    const { hubs } = this.props;
    if (hub) {
      let errors = [];
      let selectedHub = hubs.filter((h) => h.id === hub.value)[0];

      if (!checkPhoneNumber(selectedHub.contactPhone)) {
        errors.push("số điện thoại liên hệ");
      }
      if (!checkAddress(selectedHub.fullAddress, selectedHub.areaCode)) {
        errors.push("địa chỉ");
      }
      if (!selectedHub.contactName) {
        errors.push("tên");
      }

      if (errors.length) {
        this.toggleErrorDialog(errors);
      } else {
        this.setState(
          {
            toHub: {
              id: hub.value,
              fullAddress: hub.fullAddress,
              areaCode: hub.areaCode,
            },
          },
          () => {
            //TODO: ghn services
            this.props.changeHubTo(this.state.toHub);
          }
        );
      }
    } else {
      this.setState(
        {
          toHub: {
            id: "",
            fullAddress: "",
            areaCode: "",
          },
        },
        () => {
          this.props.changeHubTo(this.state.toHub);
        }
      );
    }
  };

  toggleErrorDialog = (errors = []) =>
    this.setState(
      {
        error: {
          hasError: !this.state.error.hasError,
          msg: errors,
          type: "hub",
        },
        toHub: {
          id: "",
          fullAddress: "",
          areaCode: "",
        },
      },
      () => this.props.changeHubTo(this.state.toHub)
    );

  render() {
    const { toHub, error } = this.state;
    const { hubs, bl, style } = this.props;
    let lastHub =
      bl.currentGoodsAddress.type === AddressType.hub
        ? bl.currentGoodsAddress.objId
        : ""; // thông tin hub lộ trình cuối
    let hubList = hubs.map((hub) => ({
      label: hub.name,
      value: hub.id,
      fullAddress: hub.fullAddress,
      active: hub.active,
      areaCode: hub.areaCode,
    }));
    hubList = hubList.filter((hub) => hub.value !== lastHub); //loại bỏ hub của lộ trình cuối
    hubList = hubList.filter((hub) => hub.active); //loại bỏ các hub bị deactive
    if (bl.isWhTransfer) {
      hubList = hubList.filter((hub) => hub.value !== bl.toAddr.objId);
    }

    return (
      <div>
        <Select
          value={toHub.id}
          onChange={this.changeHubTo}
          options={hubList}
          placeholder="Chọn hub nhận hàng"
          style={{ height: 40, ...style }}
        />

        <Dialog
          open={error.hasError}
          closeDialog={this.toggleErrorDialog}
          title="Có lỗi xảy ra!"
        >
          {error.type === "ktv" ? "Kĩ thuật viên" : "Kho"} bạn vừa chọn có
          {error.msg.length &&
            error.msg.map((err, idx) => {
              if (idx === 0) return ` ${err} `;
              else if (idx === error.msg.length - 1) return ` và ${err} `;
              return `, ${err}`;
            })}
          không hợp lệ. Vui lòng liên hệ Logistic team để cập nhật thông tin{" "}
          {error.type === "ktv" ? "KTV" : "kho"}.
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(ToHubSelection);
