import React from "react";
import Select from "react-select";
import { OTHER_SYSTEM_OPTIONS } from "@constants";

class OtherSystemStatusFilter extends React.Component {
  render() {
    const { value, onChange, placeholder = "Chọn trạng thái..." } = this.props;
    return (
      <Select
        value={value}
        onChange={(value) => onChange(value ? value : "")}
        options={OTHER_SYSTEM_OPTIONS}
        simpleValue
        multi={true}
        placeholder={placeholder}
        wrapperStyle={{ width: "100%" }}
      />
    );
  }
}

export default OtherSystemStatusFilter;
