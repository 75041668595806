import { Bl } from "../../../models";
import _ from "lodash";

const _initialState = {
  bl: {},
  ghnServices: [],
  error: {},
  shipperList: [],
  threePls: [],
  btnLoading: false,
  coordinator_note: "",
  history: [],
  settings: {},
  groupedBls: [],
  requestId: 0,
  coordinator_note_loading: false,
};

const detail = (state = _initialState, action) => {
  switch (action.type) {
    case "FETCH_BL_DETAIL_SUCCESS":
      return {
        ...state,
        bl: new Bl(action.payload.bl),
      };
    case "CHANGE_BL_FOLLOWER_SUCCESS":
      let newBl = _.cloneDeep(state.bl);
      newBl.follower = action.payload;
      return {
        ...state,
        bl: newBl,
      };
    case "BTN_LOADING":
      return {
        ...state,
        btnLoading: action.payload,
      };
    case "FETCH_BL_AVAILABLE_3PLS":
      return {
        ...state,
        threePls: action.payload,
      };
    case "FETCH_SHIPPER_LIST_SUCCESS":
      return {
        ...state,
        shipperList: action.payload,
      };
    case "UPDATE_COORDINATOR_NOTE_LOADING":
      return {
        ...state,
        coordinator_note_loading: action.payload,
      };
    case "BL_DETAIL_FETCH_HISTORY_SUCCESS":
      return {
        ...state,
        history: action.payload,
      };
    case "BL_DETAIL_SET_3PLS_SETTING":
      return {
        ...state,
        settings: action.payload,
      };
    case "SET_DATA_SAME_PLREF":
      return {
        ...state,
        groupedBls: action.payload,
      };
    case "GET_REQUEST_ID":
      return {
        ...state,
        requestId: action.payload,
      };
    default:
      return state;
  }
};

export default detail;
