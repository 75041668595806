import React from "react";
import Button from "@material-ui/core/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  button: {
    textTransform: "none",
    backgroundColor: "#03a9f4",
    color: "#fff",
    marginLeft: 8,
  },
});

class ConfirmImportOK extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      updating: false,
    };
  }

  toggleDialog = () =>
    this.setState(
      {
        open: !this.state.open,
      },
      () => {
        if (this.state.updating) {
          this.setState({
            updating: false,
          });
        }
      }
    );

  confirmImported = async () => {
    this.setState({
      updating: true,
    });
    const { transport, confirmTransportImported } = this.props;
    await confirmTransportImported(transport.id);
    this.setState({
      updating: false,
    });
  };

  render() {
    const { open, updating } = this.state;
    const { classes, title, confirmMsg } = this.props;
    return (
      <div>
        <Button
          variant="contained"
          className={classes.button}
          onClick={this.toggleDialog}
        >
          {title ? title : "Xác nhận nhập hub"}
        </Button>
        <Dialog open={open} onClose={() => this.toggleDialog()}>
          <DialogTitle>Xác nhận lộ trình</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {confirmMsg
                ? confirmMsg
                : "Bạn muốn xác nhận nhập hàng về hub thành công?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.toggleDialog()}>Hủy</Button>
            <Button
              color="primary"
              onClick={() => this.confirmImported()}
              disabled={updating}
            >
              {updating && <CircularProgress size={20} />} &nbsp; Đồng ý
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(ConfirmImportOK);
