import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Snackbar, SnackbarContent } from "@material-ui/core";
import FilterContainer from "./FilterContainer";
import TableContainer from "./TableContainer";
import { batchUpdate, removeSelectedBl } from "../actions";
import { BatchActionButtons, BatchDialog } from "../components";
import { DetailDialog } from "../../bl_group/components/dialog";

const styles = (theme) => ({
  title: {
    fontFamily: "Noto Sans, sans-serif",
    fontWeight: 700,
    color: "#797979",
    paddingBottom: 16,
    textTransform: "uppercase",
  },
  tabs: {
    margin: "16px 0 8px 0",
  },
  filters: {
    padding: "8px 0 16px 16px",
    margin: "8px 0 16px 0",
  },
  batchActions: {
    padding: "8px 16px",
  },
});

const _countTotalGroupedBls = (bls) => {
  let total = 0;
  for (let page in bls) {
    if (bls.hasOwnProperty(page)) {
      total += bls[page].length;
    }
  }

  return total;
};

const dismissSnackbar = () => ({
  type: "DISMISS_SNACKBAR",
});

class BlListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMultipleChoices: false, // collapse batch action buttons
      totalBls: 0, // tổng số bls đã chọn
      openBatchDialog: false, //hiển thị dialogs xác nhận,
      batchAction: "", //action tương ứng,
      updating: false, //loading indicator cho dialogs,
    };
  }

  toggleBatchDialog = (action) =>
    this.setState({
      openBatchDialog: !this.state.openBatchDialog,
      batchAction: action,
    });

  toggleLoading = () => {
    this.setState({
      updating: true,
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { groupedBls, response } = nextProps;
    if (groupedBls) {
      this.setState(
        {
          totalBls: _countTotalGroupedBls(groupedBls),
        },
        () => {
          this.setState({
            showMultipleChoices: this.state.totalBls > 0,
          });
        }
      );
    }
    if (response !== this.props.response) {
      this.setState({
        updating: false,
      });
    }
  }

  render() {
    const {
      batchAction,
      openBatchDialog,
      updating,
      showMultipleChoices,
      totalBls,
    } = this.state;
    const { response, groupedBls, showMsg, classes } = this.props;
    return (
      <div id="BlListContainer">
        {!window.isIFrame && (
          <Typography variant="h5" className={classes.title}>
            Quản lý vận đơn
          </Typography>
        )}

        <FilterContainer />
        <Paper elevation={0} className={classes.batchActions}>
          <BatchActionButtons
            showMultipleChoices={showMultipleChoices}
            bls={groupedBls}
            totalBls={totalBls}
            toggleBatchDialog={this.toggleBatchDialog}
          />
        </Paper>

        <TableContainer />
        <DetailDialog />
        <BatchDialog
          updating={updating}
          batchAction={batchAction}
          response={response}
          openBatchDialog={openBatchDialog}
          toggleBatchDialog={this.toggleBatchDialog}
          toggleLoading={this.toggleLoading}
          batchUpdate={this.props.batchUpdate}
          removeSelectedBl={this.props.removeSelectedBl}
          groupedBls={groupedBls}
        />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showMsg.show}
          onClose={this.props.dismissSnackbar}
        >
          <SnackbarContent
            message={"Tạo lộ trình thành công"}
            action={
              <Button
                color="secondary"
                onClick={() => {
                  window.location.assign("/van-don/" + showMsg.id);
                }}
              >
                Chi tiết
              </Button>
            }
          />
        </Snackbar>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  response: state.admin.list.batchActions.response,
  groupedBls: state.admin.list.batchActions.groupedBls,
  showMsg: state.admin.list.showMsg,
  filter: state.admin.list.filter,
});

const mapDispatchToProps = (dispatch) => ({
  batchUpdate: (bls, batchAction) => dispatch(batchUpdate(bls, batchAction)),
  removeSelectedBl: (page, id, index) =>
    dispatch(removeSelectedBl(page, id, index)),
  dismissSnackbar: () => dispatch(dismissSnackbar()),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(BlListContainer)
);
