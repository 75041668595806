import React from "react";
import HubFilter from "./HubFilter";

class FromHubFilter extends React.Component {
  render() {
    const { value, setFilter, hubs } = this.props;
    return (
      <HubFilter
        value={value}
        hubs={hubs}
        placeholder="Chọn hub..."
        setFilter={setFilter}
        type="toTransitHubId"
        multi={false}
        hasRegion={false}
      />
    );
  }
}

export default FromHubFilter;
