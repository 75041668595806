import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  heading: {
    fontFamily: "Noto Sans, sans-serif",
    fontWeight: 800,
    color: "#5d9cec",
  },
  subHeading: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 24,
    marginBottom: 16,
  },
  text: {
    fontFamily: "Noto Sans, sans-serif",
    marginBottom: 8,
    color: "#797979",
  },
};

const Forbidden = ({ classes }) => (
  <div
    style={{
      height: "100vh",
      overflow: "hidden",
    }}
  >
    <div
      style={{
        paddingTop: "10%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <span style={{ fontSize: 96, display: "flex", alignItems: "center" }}>
        <span className={classes.heading}>4</span>
        <i
          className="material-icons"
          style={{ color: "#fb6d9d", fontSize: 96 }}
        >
          sentiment_very_dissatisfied
        </i>
        <span className={classes.heading}>3</span>
      </span>
      <span className={classes.subHeading}>Forbidden</span>
      <span className={classes.text}>
        Bạn không có quyền truy cập khu vực này.
      </span>
      <span className={classes.text}>
        Vui lòng liên hệ Logistic team để được hỗ trợ.
      </span>
    </div>
  </div>
);

export default withStyles(styles)(Forbidden);
