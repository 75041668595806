import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { LinearProgress, Fab } from "@material-ui/core";
import { SHIPPER_DETAIL_REPORT } from "../../../constants";
import { formatCurrency, mapVehicleReportShipMethodType } from "../../../utils";
import { Zoom } from "@material-ui/core";
import { onExcel } from "../actions";

//styles
const styles = (theme) => ({
  "@global": {
    "table > thead > tr > td": {
      padding: "0.6rem",
      background: "#324B78",
      fontWeight: "bold",
      color: "white",
      border: "1px solid #515177",
    },
    "table > tbody > tr > td:not(:last-child)": {
      borderRight: "1px solid #51517717",
    },
    "table > tbody tr:nth-child(odd) > td": {
      background: "#ecf4fd5c",
    },
    "table > tbody tr:nth-child(even) > td": {
      background: "white",
    },
  },
});

class VehicleReportFilter extends React.Component {
  mapKeyToValue = (key, element, id) => {
    switch (key) {
      case "index":
        return id + 1;
      default:
        return key;
    }
  };

  changePagination = (type, value) => {
    const { pagination, onChangePagination } = this.props;
    onChangePagination({
      ...pagination,
      [type]: type === "offset" ? value * pagination.limit : value,
    });
  };

  render() {
    const { data, loading, summary, onExcel } = this.props;
    const frequency = summary.length;
    return (
      <React.Fragment>
        {loading && <LinearProgress />}
        {data.length > 0 && (
          <Zoom in={true} timeout={600}>
            <Fab color="primary" className="floatingButton" onClick={onExcel}>
              <i style={{ fontSize: 18 }} className="material-icons">
                get_app
              </i>
              <span style={{ fontSize: 14, textTransform: "none" }}>Excel</span>
            </Fab>
          </Zoom>
        )}
        <table>
          <thead>
            <tr>
              <td rowSpan={2}>Phương tiện vận chuyển</td>
              {new Array(frequency).fill(1).map((x, id) => (
                <td key={id} colSpan={3} align="center">
                  Kỳ {id + 1}
                </td>
              ))}
            </tr>
            <tr>
              {new Array(frequency).fill(1).map((x, id) => (
                <React.Fragment key={id}>
                  <td align="right">Số lượng đơn hàng</td>
                  <td align="right">Trị giá đơn hàng</td>
                  <td align="right">Số lượt vận chuyển</td>
                </React.Fragment>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              <React.Fragment>
                <tr className="totalRow">
                  <td>Tổng cộng</td>
                  {new Array(frequency).fill(1).map((x, id) => (
                    <React.Fragment key={id}>
                      <td align="right">
                        {formatCurrency(summary[id].total_bl)}
                      </td>
                      <td align="right">
                        {formatCurrency(summary[id].grand_totals)}
                      </td>
                      <td align="right">
                        {formatCurrency(summary[id].total_transport)}
                      </td>
                    </React.Fragment>
                  ))}
                </tr>
                {data.map((x, id) => (
                  <tr key={id}>
                    <td>{mapVehicleReportShipMethodType(x.metadata.name)}</td>
                    {new Array(frequency).fill(1).map((k, idx) => (
                      <React.Fragment key={id}>
                        <td align="right">
                          {formatCurrency(x.items[idx].total_bl)}
                        </td>
                        <td align="right">
                          {formatCurrency(x.items[idx].grand_totals)}
                        </td>
                        <td align="right">
                          {formatCurrency(x.items[idx].total_transport)}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                ))}
              </React.Fragment>
            ) : (
              <tr>
                <td colSpan={SHIPPER_DETAIL_REPORT.length}>
                  Không thể tìm thấy dữ liệu bạn yêu cầu!
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  filter: state.admin.VehicleReport.filter,
  data: state.admin.VehicleReport.data,
  summary: state.admin.VehicleReport.summary,
});

const mapDispatchToProps = (dispatch) => ({
  onExcel: () => dispatch(onExcel()),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(VehicleReportFilter)
);
