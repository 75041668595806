import React from "react";
import Select from "react-select";

class NumberSelection extends React.Component {
  render() {
    const { value, min, max, onChange } = this.props;
    const _options = Array(max - min + 1)
      .fill(1)
      .map((x, id) => ({
        label: min + id,
        value: min + id,
      }));
    return (
      <Select
        value={value}
        onChange={(v) => onChange(v ? v : "")}
        options={_options}
        placeholder="Chọn số kì"
        clearable={false}
        multi={false}
        simpleValue
        style={{ height: 40 }}
      />
    );
  }
}

export default NumberSelection;
