import {
  fetchError,
  getServiceList,
  updateTransportDetail,
  GHNTemplate,
  getDataSamePlref,
  updateTimeKeeping,
} from "@shared";
import _ from "lodash";
import fetchBlDetail from "./fetchBlDetail";
import { openNotiDialog } from "@shared";
import {
  getThreePlsSetting,
  cancelTransportAPI,
  confirmTransportPickedApi,
  confirmTransportOKApi,
  confirmTransportReturnedApi,
  confirmShippingTransportCancelledApi,
  stockRequestApi,
  printBillApi,
} from "@api";

export const getAllDataSamePlref = (plref) => async (dispatch, getState) => {
  const response = await getDataSamePlref(plref);
  if (response.status === 200) {
    const responseBody = response.data.data.bls;
    dispatch(setDataSamePlref(responseBody));
  }
};

const setDataSamePlref = (data) => ({
  type: "SET_DATA_SAME_PLREF",
  payload: data,
});

export const confirmTransportPicked = (id) => async (dispatch, getState) => {
  const response = await confirmTransportPickedApi(id);
  const data = response.data;
  if (response.status === 200) {
    if (data) {
      window.location.reload();
    } else {
      dispatch(fetchError(response.status, data.message));
    }
  } else {
    dispatch(fetchError(response.status, data.message));
  }
};

export const confirmTransportDelivered = (id) => async (dispatch) => {
  const response = await confirmTransportOKApi(id);
  const data = response.data;
  if (response.status === 200) {
    if (data) {
      window.location.reload();
    } else {
      dispatch(fetchError(response.status, data.message));
    }
  } else {
    dispatch(fetchError(response.status, data.message));
  }
};

export const confirmTransportImported = (id) => async (dispatch) => {
  const response = await confirmTransportOKApi(id);
  const data = response.data;
  if (response.status === 200) {
    if (data) {
      window.location.reload();
    } else {
      dispatch(fetchError(response.status, data.message));
    }
  } else {
    dispatch(fetchError(response.status, data.message));
  }
};

export const confirmTransportCancelled = (params) => async (dispatch) => {
  const response = await cancelTransportAPI(params);
  const data = response.data;
  if (response.status === 200) {
    if (data) {
      // pass
      if (data.code === 202) {
        dispatch(
          openNotiDialog("alert", data.message, () => {
            window.location.reload();
          })
        );
      } else {
        window.location.reload();
      }
    } else {
      dispatch(openNotiDialog("failed", data.message));
    }
  } else {
    dispatch(openNotiDialog("failed", data.message));
  }
};

export const confirmTransportReturned = (id, reason) => async (dispatch) => {
  const response = await confirmTransportReturnedApi(id, reason);
  const data = response.data;
  if (response.status === 200) {
    if (data) {
      window.location.reload();
    } else {
      dispatch(fetchError(response.status, data.message));
    }
  } else {
    dispatch(fetchError(response.status, data.message));
  }
};

export const confirmShippingTransportCancelled =
  (params) => async (dispatch) => {
    const response = await confirmShippingTransportCancelledApi(params);
    const data = response.data;
    if (response.status === 200) {
      if (data) {
        window.location.reload();
      } else {
        dispatch(fetchError(response.status, data.message));
      }
    } else {
      dispatch(fetchError(response.status, data.message));
    }
  };

export const updateMeasurement = (id, data) => async (dispatch, getState) => {
  let response = await updateTransportDetail(id, data);
  let result = response.data;
  if (response.status === 200) {
    if (!result) {
      dispatch(fetchError(response.status, result.message));
    }
  } else {
    let message = result ? result.message : "";
    dispatch(fetchError(response.status, message));
  }
  return result;
};

/* Gửi yêu cầu lấy hàng sang WMS, lưu log lại vào bảng stock */
export const stockRequest = (bl_id) => async (dispatch) => {
  const response = await stockRequestApi(bl_id);
  const data = response.data;
  if (response.status === 200) {
    if (data) {
      window.location.reload();
    } else {
      dispatch(fetchError(response.status, data.message));
    }
  } else {
    dispatch(fetchError(response.status, data.message));
  }
};

export const printBill = (ship_method, pl_ref) => async (dispatch) => {
  const pl_ref_enc = encodeURIComponent(encodeURIComponent(pl_ref));
  const response = await printBillApi(ship_method, pl_ref_enc);
  const data = response.data;
  if (response.status === 200) {
    if (data) {
      if (ship_method === "vtpost") {
        if (data && data.data && data.data.url) {
          window.location.href = data.data.url;
        }
      }
      if (ship_method === "nhattin") {
        if (data && data.data && data.data.url) {
          window.location.href = data.data.url;
        }
      }
      if (ship_method === "ghn") {
        const GHNData = data.OrderA5s[0];
        let template = _.cloneDeep(GHNTemplate);
        Object.keys(GHNData).forEach((x) => {
          const key = `#${x}`;
          switch (x) {
            case "CreatedDate":
            case "ExpectedDeliveryTime":
              return;
            case "Sorting":
              const part = GHNData[x].split("/");
              template = template.replace(
                new RegExp("#Sorting1", "g"),
                part[0]
              );
              template = template.replace(
                new RegExp("#Sorting2", "g"),
                part[1]
              );
              return;
            case "ExpectedDeliveryTimeS":
              const date = new Date(GHNData[x]);
              template = template.replace(new RegExp(key, "g"), date.getDate());
              break;
            default:
              template = template.replace(new RegExp(key, "g"), GHNData[x]);
              return;
          }
        });
        let print = window.open("", "", "");
        print.document.write(template);
        print.document.close();
      }
    } else {
      dispatch(fetchError(response.status, data.message));
    }
  } else {
    dispatch(fetchError(response.status, data.message));
  }
};

/* Lấy danh sách loại dịch vụ  */
export const getServiceListByShipMethod =
  (bl_id, params) => async (dispatch) => {
    let response = await getServiceList(bl_id, params);
    return response.data;
  };

/* Cập nhật thông tin chấm công */
export const updateKtvTimeKeeping =
  (id, data) => async (dispatch, getState) => {
    const body = { ktvs: [...data] };
    const response = await updateTimeKeeping(id, body);
    const result = response.data;
    if (response.status === 200) {
      if (result) {
        const state = getState().admin.detail;
        dispatch(fetchBlDetail(state.bl.id));
      } else {
        dispatch(fetchError(result.code, result.message));
      }
    } else {
      const message = result ? result.message : "";
      dispatch(fetchError(response.status, message));
    }
    return response;
  };

/* Lấy setting của 3pls */
export const fetchThreePlsSetting = (code) => async (dispatch) => {
  const response = await getThreePlsSetting(code);
  if (response.status === 200) {
    const responseBody = response.data.data.settings;
    dispatch(setThreePlsSetting(responseBody));
  }
};

const setThreePlsSetting = (data) => ({
  type: "BL_DETAIL_SET_3PLS_SETTING",
  payload: data,
});

export const getRequestId = (id) => ({
  type: "GET_REQUEST_ID",
  payload: id,
});
