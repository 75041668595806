import React from "react";
import { TableCell } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import QuickFilterBar from "../../../containers/QuickFilterBar";
import NotFoundImg from "@images/notfound.png";
import { connect } from "react-redux";
import { setFilter, clearFilters, fetchBlList } from "../../../actions";
import { columnTemplate } from "@utils";

const styles = (theme) => ({
  noDataRow: {
    textAlign: "center",
    padding: "40px 0",
    fontSize: "16px !important",
  },
  notFoundText: {
    fontWeight: "bold",
    color: "indianred",
  },
  clearFilterText: {
    color: "indianred",
    cursor: "pointer",
  },
  suggest: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "center",
  },
});

class NoDataRowBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      windowInnerWidth: window.innerWidth,
    };
  }

  countOthersFilter = () => {
    const { lastSearch } = this.props;
    const others = [
      "deliveryTypes",
      "channel",
      "is_cod_bl",
      "transportCreatedUser",
      "wms_status",
      "shipperId",
      "to_area",
      "fromHub",
      "toHub",
      "toTransitHubId",
    ];
    let count = 0;
    others.forEach((x) => {
      if (lastSearch[x]) {
        count = count + 1;
      }
    });
    return count;
  };

  isQuickFilterAvailable = () => {
    const { lastSearch } = this.props;
    const quickFilter = [
      "requestType",
      "status",
      "shipMethod",
      "shipDateFrom",
      "shipDateTo",
      "createTransportFrom",
      "createTransportTo",
      "exportDateFrom",
      "exportDateTo",
      "keyword",
    ];
    let available = 0;
    for (let i = 0; i < quickFilter.length; i++) {
      if (lastSearch[quickFilter[i]]) {
        available += 1;
      }
    }
    return !(available === 0);
  };

  noResult = () => {
    const { clearFilters, setFilter, fetchBlList, lastSearch, classes } =
      this.props;
    return (
      <React.Fragment>
        <div>
          <img src={NotFoundImg} alt="NotFound" />
        </div>
        <div className={classes.notFoundText}>
          Không tìm thấy kết quả phù hợp
        </div>
        <br />
        <div style={{ fontSize: 14 }}>
          Có phải bạn đang tìm kiếm theo &nbsp;
          {this.isQuickFilterAvailable() && (
            <React.Fragment>
              <QuickFilterBar
                onChange={setFilter}
                filter={lastSearch}
                fetchBlList={fetchBlList}
              />{" "}
            </React.Fragment>
          )}
          {this.countOthersFilter() > 0 && (
            <span>
              {this.isQuickFilterAvailable() && "và "}
              <strong>{this.countOthersFilter()} điều kiện</strong> khác
            </span>
          )}
          ?
        </div>
        <div className={classes.suggest} style={{ fontSize: 14 }}>
          <div>Đề xuất: </div>
          <ul>
            <li>
              <span
                className={classes.clearFilterText}
                onClick={() => {
                  clearFilters();
                  fetchBlList();
                }}
              >
                Xóa bộ lọc{" "}
              </span>{" "}
              và tìm kiếm lại.
            </li>
          </ul>
        </div>
      </React.Fragment>
    );
  };

  updateDimensions = () => {
    const width = document.getElementById("BlListContainer").clientWidth;
    this.setState({ windowInnerWidth: width - 20 });
  };

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    const { loading, classes } = this.props;
    const { windowInnerWidth } = this.state;
    return (
      <tr>
        <TableCell
          className={classes.noDataRow}
          colSpan={columnTemplate.length}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: windowInnerWidth,
            }}
          >
            <div style={{ maxWidth: "70rem" }}>
              {loading ? "" : this.noResult()}
            </div>
          </div>
        </TableCell>
      </tr>
    );
  }
}

const mapStateToProps = (state) => ({
  lastSearch: state.admin.list.lastSearch,
});

const mapDispatchToProps = (dispatch) => ({
  setFilter: (property, value) => dispatch(setFilter(property, value)),
  clearFilters: () => dispatch(clearFilters()),
  fetchBlList: () => dispatch(fetchBlList()),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(NoDataRowBase)
);
