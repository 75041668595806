import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import { Menu, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { MENU_SELECT_ID } from "@constants";
import TekoID from "teko-oauth2";
import { APP_NAME } from "@config";
import { toggleSidebar } from "./sidebar";
import { fetchBlList, setFilter } from "../admin/bl_list";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
const drawerWidth = 280;

const styles = (theme) => ({
  toolbar: {
    marginRight: 12,
    display: "flex",
  },
  flex: {
    flex: 1,
  },
  user: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  orangeAvatar: {
    margin: "0 8px 0 16px",
    color: "#fff",
    backgroundColor: "#ff5722",
    cursor: "pointer",
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  searchBox: {
    backgroundColor: "#fff",
    display: "flex",
    flex: 2,
    borderRadius: 4,
    marginLeft: 10,
    marginRight: 10,
  },
  searchInput: {
    borderRadius: 4,
    height: 30,
    border: "none",
    flex: 1,
    paddingLeft: 4,
  },
  searchButton: {
    width: 64,
    height: 32,
    cursor: "pointer",
    backgroundColor: "#f9f9f8",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "0 4px 4px 0",
  },
  xsToolbar: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-end",
  },
  appBar: {
    background: "linear-gradient(to right, #1e3c72, #2a5298)",
  },
  appBarDev: {
    background: "linear-gradient(to right, rgb(155,0,0), rgb(185,0,0))",
  },
  appBarstag: {
    background: "linear-gradient(to right, #8e44ad,#9b59b6)",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  content: {
    width: "100%",
    marginLeft: -drawerWidth,
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: "calc(100% - 56px)",
    marginTop: 56,
    [theme.breakpoints.up("sm")]: {
      content: {
        height: "calc(100% - 64px)",
        marginTop: 64,
      },
    },
  },
  versionTag: {
    fontSize: 12,
    marginLeft: 5,
  },
});

function MapEnvToClass(env) {
  const dict = {
    local: "appBarDev",
    dev: "appBarDev",
    test: "appBarDev",
    stag: "appBarstag",
    prod: "appBar",
  };
  return dict[env];
}

class AppHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      anchorSelectId: null,
      openSelectId: false,
      open: false,
      searchHeight: 0,
    };
  }

  handleCloseSelectId = () => this.setState({ openSelectId: false });

  handleClick = (e) => this.setState({ open: true, anchorEl: e.currentTarget });

  handleClose = () => this.setState({ open: false });

  componentDidMount = () => {
    let element = document.getElementById("search-box");
    if (element) {
      this.setState({
        searchHeight: element.offsetHeight,
      });
    }
  };

  handleClickSelectId = (e) =>
    this.setState({ openSelectId: true, anchorSelectId: e.currentTarget });

  handleIdType = async (event, type) => {
    const { filter } = this.props;
    if (filter.keyword) {
      let s = "/van-don/";
      s += filter.keyword;
      if (type !== "") {
        s += "?type=" + type;
      }
      window.open(s);
    }
    await this.setState({
      openSelectId: false,
    });
  };

  search = async (e) => {
    e.preventDefault();
    await this.props.fetchBlList();
    const { history, filter } = this.props;
    let query = "";
    if (filter.quickFilter === "waiting") {
      query = window.localStorage.getItem("queryStringCXL");
    } else {
      query = window.localStorage.getItem("queryString");
    }
    history.push("/" + query);
  };

  render() {
    const { openSelectId, anchorSelectId, searchHeight } = this.state;
    const {
      user,
      openSidebar,
      filter,
      classes,
      toggleSidebar,
      setFilter,
      settings,
      isKTV = false,
    } = this.props;
    const isLoggedIn = TekoID.user && TekoID.user.isLoggedIn();
    const env = (
      window.ENV_MODE ? window.ENV_MODE.toLowerCase() : "test-1"
    ).replace(/[\W\d_]/g, "");
    const appBarClass = classNames(
      classes[MapEnvToClass(env)],
      openSidebar && classes.appBarShift
    );
    const hideMenuToggler = user.isKTV || window.isIFrame;

    return (
      <div>
        <AppBar position="static" className={appBarClass}>
          <Toolbar disableGutters className={classes.toolbar}>
            {hideMenuToggler ? (
              <div style={{ width: 10 }} />
            ) : (
              <IconButton
                className={classes.menuButton}
                color="default"
                aria-label="Menu"
                onClick={toggleSidebar}
              >
                <i className="material-icons" style={{ color: "white" }}>
                  menu
                </i>
              </IconButton>
            )}
            <Hidden xsDown>
              <Typography variant="h5" color="inherit">
                {isKTV ? APP_NAME : <Link to="/">{APP_NAME}</Link>}
                <span className={classes.versionTag}>
                  v
                  {settings &&
                    settings.CURRENT_VERSION &&
                    settings.CURRENT_VERSION.name}
                </span>
              </Typography>
            </Hidden>
            {isLoggedIn && !user.isKTV && (
              <form onSubmit={this.search}>
                <div className={classes.searchBox} id="search-box">
                  <div
                    className={classes.searchButton}
                    onClick={(event) => this.handleClickSelectId(event)}
                  >
                    <i className="material-icons" style={{ color: "#848484" }}>
                      expand_more
                    </i>
                  </div>
                  <input
                    type="text"
                    placeholder="Nhập mã vận đơn, mã vận chuyển, mã yêu cầu, mã đơn hàng..."
                    onChange={(e) => setFilter("keyword", e.target.value)}
                    value={filter.keyword}
                    className={classes.searchInput}
                  />
                  <div className={classes.searchButton} onClick={this.search}>
                    <i className="material-icons" style={{ color: "#848484" }}>
                      search
                    </i>
                  </div>
                </div>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorSelectId}
                  keepMounted
                  open={openSelectId}
                  color="primary"
                  onClose={this.handleCloseSelectId}
                  style={{ top: searchHeight }}
                >
                  {MENU_SELECT_ID.map((option, index) => (
                    <MenuItem
                      key={option.label}
                      onClick={(event) => this.handleIdType(event, option.type)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Menu>
              </form>
            )}
            {isLoggedIn && <UserInfo />}
            <Hidden smUp>
              <div className={classes.xsToolbar}>
                <Hidden xsDown>
                  <i className="material-icons">search</i>
                </Hidden>
                <Avatar className={classes.orangeAvatar}>N</Avatar>
              </div>
            </Hidden>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  toggleSidebar: () => dispatch(toggleSidebar()),
  fetchBlList: () => dispatch(fetchBlList()),
  setFilter: (property, value) => dispatch(setFilter(property, value)),
});

const mapStateToProps = (state) => ({
  filter: state.admin.list.filter,
  user: state.admin.shared.user,
  settings: state.admin.shared.settings,
  sidebar: state.admin.shared.sidebar,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(AppHeader);

function UserInfo() {
  const profile = TekoID.user.getUserInfo();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  return (
    <Hidden xsDown>
      <div style={{ flex: 2 }} />
      {profile.name && (
        <React.Fragment>
          <Typography
            type="subheading"
            color="inherit"
            align="right"
            style={{ marginRight: 10 }}
          >
            {profile.name}
          </Typography>
          <Avatar
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
              setOpen(true);
            }}
            src={profile.picture}
          />
        </React.Fragment>
      )}
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setOpen(false)}
      >
        <MenuItem
          onClick={() =>
            TekoID.user.logout(
              `${window.location.protocol}//${window.location.hostname}${
                window.location.port ? `:${window.location.port}` : ""
              }`
            )
          }
        >
          Đăng xuất
        </MenuItem>
      </Menu>
    </Hidden>
  );
}
