export const setSelection = (selection, rows) => ({
  type: "SET_TABLE_SELECTION",
  payload: {
    selection,
    rows,
  },
});

export const changePage = (page) => ({
  type: "CHANGE_TABLE_PAGE",
  payload: {
    page,
  },
});

export const expandRows = (rows) => ({
  type: "EXPAND_TABLE_ROWS",
  payload: {
    rows,
  },
});

export const setFilter = (property, value) => ({
  type: "SET_FILTER",
  payload: {
    property,
    value,
  },
});

export const changePageSize = (pageSize) => ({
  type: "CHANGE_TABLE_PAGE_SIZE",
  payload: {
    pageSize,
  },
});
