import React from "react";
import Select from "react-select";

const OPTIONS = [
  { value: "waiting_for_being_accepted", label: "Chờ xác nhận yêu cầu" },
  { value: "accepted", label: "Chấp nhận yêu cầu" },
  { value: "rejected", label: "Từ chối yêu cầu" },
  // { value: "at_warehouse", label: "Đã tới điểm lấy hàng" },
  { value: "on_the_way", label: "Đang trên đường" },
  { value: "at_delivery_point", label: "Đã đến nơi" },
  {
    value: "waiting_for_being_cancelled",
    label: "Đang trên đường về (Yêu cầu hủy lộ trình)",
  },
  {
    value: "on_the_way_back",
    label: "Đang trên đường về",
  },
  { value: "ready_to_return", label: "Đã về điểm lấy hàng" },
];

export const mapBlStatusStyles = (status, type = "label") => {
  /**
   * Trả về color hex code tương ứng với quick filter
   */
  switch (status) {
    case "waiting_for_being_accepted":
    case "accepted":
    case "at_warehouse":
      return {
        background: type === "label" ? "#ABB54A" : "#abb54a99",
        color: "white",
        border: "none",
      };
    case "on_the_way":
    case "at_delivery_point":
      return {
        background: type === "label" ? "#2378b6" : "#2378b6c7",
        color: "white",
        border: "none",
      };
    case "on_the_way_back":
    case "ready_to_return":
      return {
        background: type === "label" ? "#923BA1" : "#923ba1b0",
        color: "white",
        border: "none",
      };
    case "waiting_for_being_cancelled":
      return {
        background: "#e234ab",
        color: "white",
        border: "none",
      };
    case "rejected":
      return {
        background: type === "label" ? "#d43f3f" : "#d43f3f99",
        color: "white",
        border: "none",
      };
    default:
      return "";
  }
};
class ShipperStatusFilter extends React.Component {
  handleChangeShipperStatus = (status) => {
    const { setFilter, filter } = this.props;
    const curShipperStatus = status ? status.split(",") : [];
    let blStatus = filter.status ? filter.status.split(",") : [];

    if (
      curShipperStatus.includes("waiting_for_being_accepted") ||
      curShipperStatus.includes("accepted") ||
      curShipperStatus.includes("at_warehouse")
    ) {
      if (!blStatus.includes("waiting_for_pick")) {
        blStatus.push("waiting_for_pick");
      }
    }
    if (
      curShipperStatus.includes("on_the_way") ||
      curShipperStatus.includes("at_delivery_point") ||
      curShipperStatus.includes("waiting_for_being_cancelled")
    ) {
      if (!blStatus.includes("on_delivering")) {
        blStatus.push("on_delivering");
      }
    }
    if (
      curShipperStatus.includes("on_the_way_back") ||
      curShipperStatus.includes("ready_to_return")
    ) {
      if (!blStatus.includes("on_returning")) {
        blStatus.push("on_returning");
      }
    }
    setFilter("status", blStatus.toString());
    setFilter("shipper_state", status);
  };
  render() {
    const { value, placeholder = "Chọn trạng thái KTV..." } = this.props;
    return (
      <Select
        value={value}
        onChange={this.handleChangeShipperStatus}
        options={OPTIONS}
        multi
        simpleValue
        closeOnSelect={false}
        placeholder={placeholder}
        wrapperStyle={{ width: "100%" }}
        valueComponent={SelectValue}
      />
    );
  }
}

class SelectValue extends React.Component {
  render() {
    let { value, onRemove } = this.props;
    let label = value.label;
    const valueStyles = mapBlStatusStyles(value.value, "label");
    const closeStyles = mapBlStatusStyles(value.value, "close");
    return (
      <div className="Select-value" style={{ border: "none" }}>
        <span
          className="Select-value-icon"
          aria-hidden="true"
          style={closeStyles}
          onClick={() => onRemove(value)}
        >
          ×
        </span>
        <div className="Select-value-label" style={valueStyles}>
          {label}
        </div>
      </div>
    );
  }
}

export default ShipperStatusFilter;
