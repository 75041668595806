import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Grid,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import { formatCurrency } from "@utils";
//styles
const styles = (theme) => ({
  summaryContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  blockInformation: {
    width: "14.78%",
  },
  card: {
    borderRadius: "1rem",
    boxShadow: "0px 0px 6px -1px rgba(168,166,168,1)",
  },
  numberCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
  },
  cardContainer: {
    padding: "1.5rem !important",
  },
  cardContent: {
    textAlign: "center",
    fontWeight: "bold",
  },
  cardNumber: {
    fontSize: "2rem",
    wordBreak: "break-all",
  },
  paper: {
    padding: "1rem 2rem",
    display: "flex",
    justifyContent: "space-between",
  },
  "@global": {
    "#ShipperSummary > tbody tr > td": {
      padding: "0.4rem",
    },
    "#ShipperSummary": {
      boxShadow: "none",
      fontSize: 14,
    },
  },
});

function mapSummaryTypeColor(type) {
  switch (type) {
    case "bls_total.total_delivered":
      return "darkseagreen";
    case "bls_total.returned":
      return "mediumpurple";
    case "bls_total.partial_returned":
      return "mediumpurple";
    case "bls_total.total_canceled":
      return "orange";
    case "total_distance":
      return "indianred";
    case "total_fee":
      return "cornflowerblue";
  }
}

class ShippingSummary extends React.Component {
  renderShipperInfo = () => {
    const { filter } = this.props;
    const shipper = filter.shipper_id;
    return (
      <table id="ShipperSummary">
        <tbody>
          <tr>
            <td>
              <span className="label" style={{ whiteSpace: "nowrap" }}>
                Mã nhân viên:
              </span>
            </td>
            <td>{shipper ? shipper.value : "-"}</td>
          </tr>
          <tr>
            <td>
              <span className="label">Họ tên:</span>
            </td>
            <td>{shipper ? shipper.name : "-"}</td>
          </tr>
          <tr>
            <td>
              <span className="label">SĐT:</span>
            </td>
            <td>{shipper ? shipper.phone : "-"}</td>
          </tr>
          <tr>
            <td>
              <span className="label">Địa chỉ:</span>{" "}
            </td>
            <td> {shipper ? shipper.fullAddress : "-"}</td>
          </tr>
          <tr>
            <td>
              <span className="label">Khu vực:</span>
            </td>
            <td>{shipper ? shipper.areaCode : "-"}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  render() {
    const { loading, summary, classes } = this.props;
    return (
      <React.Fragment>
        <Grid container spacing={1}>
          <Grid item xs={12} style={{ paddingBottom: 0 }}>
            <Paper elevation={0} className={classes.paper}>
              <div
                className={classes.blockInformation}
                style={{ width: "23%" }}
              >
                <Card className={classes.card}>
                  <CardContent>{this.renderShipperInfo()}</CardContent>
                </Card>
              </div>
              <div className={classes.blockInformation}>
                {/* Tổng số km */}
                <Card
                  className={`${classes.card} ${classes.numberCard}`}
                  style={{
                    color: mapSummaryTypeColor("total_distance"),
                  }}
                >
                  <CardContent className={classes.cardContent}>
                    <div>Tổng số km</div>
                    <div className={classes.cardNumber}>
                      {loading ? (
                        <CircularProgress
                          style={{
                            color: mapSummaryTypeColor("total_distance"),
                          }}
                        />
                      ) : (
                        summary.total_distance
                      )}
                    </div>
                  </CardContent>
                </Card>
              </div>

              <div className={classes.blockInformation}>
                {/* Tổng đơn giao thành công */}
                <Card
                  className={`${classes.card} ${classes.numberCard}`}
                  style={{
                    color: mapSummaryTypeColor("bls_total.total_delivered"),
                  }}
                >
                  <CardContent className={classes.cardContent}>
                    <div>Giao thành công</div>
                    <div className={classes.cardNumber}>
                      {loading ? (
                        <CircularProgress
                          style={{
                            color: mapSummaryTypeColor(
                              "bls_total.total_delivered"
                            ),
                          }}
                        />
                      ) : (
                        summary.bls_total.total_delivered
                      )}
                    </div>
                  </CardContent>
                </Card>
              </div>

              <div className={classes.blockInformation}>
                {/* Tổng đơn hoàn toàn bộ */}
                <Card
                  className={`${classes.card} ${classes.numberCard}`}
                  style={{
                    color: mapSummaryTypeColor("bls_total.returned"),
                  }}
                >
                  <CardContent className={classes.cardContent}>
                    <div>Đã hoàn toàn phần</div>
                    <div className={classes.cardNumber}>
                      {loading ? (
                        <CircularProgress
                          style={{
                            color: mapSummaryTypeColor("bls_total.returned"),
                          }}
                        />
                      ) : (
                        summary.bls_total.returned
                      )}
                    </div>
                  </CardContent>
                </Card>
              </div>

              <div className={classes.blockInformation}>
                {/* Tổng đơn hoàn 1 phần */}
                <Card
                  className={`${classes.card} ${classes.numberCard}`}
                  style={{
                    color: mapSummaryTypeColor("bls_total.partial_returned"),
                  }}
                >
                  <CardContent className={classes.cardContent}>
                    <div>Đã hoàn một phần</div>
                    <div className={classes.cardNumber}>
                      {loading ? (
                        <CircularProgress
                          style={{
                            color: mapSummaryTypeColor(
                              "bls_total.partial_returned"
                            ),
                          }}
                        />
                      ) : (
                        summary.bls_total.partial_returned
                      )}
                    </div>
                  </CardContent>
                </Card>
              </div>

              <div className={classes.blockInformation}>
                {/* Tổng đơn bị hủy lộ trình */}
                <Card
                  className={`${classes.card} ${classes.numberCard}`}
                  style={{
                    color: mapSummaryTypeColor("bls_total.total_canceled"),
                  }}
                >
                  <CardContent className={classes.cardContent}>
                    <div>Bị hủy lộ trình</div>
                    <div className={classes.cardNumber}>
                      {loading ? (
                        <CircularProgress
                          style={{
                            color: mapSummaryTypeColor(
                              "bls_total.total_canceled"
                            ),
                          }}
                        />
                      ) : (
                        summary.bls_total.total_canceled
                      )}
                    </div>
                  </CardContent>
                </Card>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  filter: state.admin.ShipperDailyReport.filter,
  summary: state.admin.ShipperDailyReport.summary,
  loading: state.admin.ShipperDailyReport.loading,
});

export default withStyles(styles)(
  connect(mapStateToProps, null)(ShippingSummary)
);
