import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { RadioGroup, FormControlLabel, Radio } from "@material-ui/core";

const styles = (theme) => ({
  radio: {
    height: 35,
  },
});

class DateRangeSelection extends React.Component {
  render() {
    const { value, onChange, classes } = this.props;
    return (
      <RadioGroup
        name="destination"
        value={value}
        style={{ flexDirection: "row" }}
        onChange={(e, v) => onChange(v)}
      >
        <FormControlLabel
          value="day"
          control={<Radio className={classes.radio} />}
          label="Ngày"
        />
        <FormControlLabel
          value="month"
          control={<Radio className={classes.radio} />}
          label="Tháng"
        />
        <FormControlLabel
          value="trimester"
          control={<Radio className={classes.radio} />}
          label="Quý"
        />
        <FormControlLabel
          value="year"
          control={<Radio className={classes.radio} />}
          label="Năm"
        />
      </RadioGroup>
    );
  }
}

export default withStyles(styles)(DateRangeSelection);
