import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { fetchArea, createTransport, addCustomerAddress } from "../../shared";
import { AddCustomerAddress, CreateTransport } from "../components";
import { AddressType } from "../../../../models";
import { fetch3PlsList, fetchThreePlsSetting } from "../actions";

const styles = () => ({
  subTitle: {
    fontSize: 14,
    padding: "8px 0 16px 0",
    fontFamily: "Source Sans Pro, sans-serif",
    textTransform: "uppercase",
    fontWeight: 600,
    letterSpacing: 1,
    color: "#7266ba",
  },
});

class CreateTransportContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: true,
      addAreaCode: false, //kiểm tra xem có cần thêm area code trước khi tạo lộ trình không
    };
  }

  async componentDidMount() {
    const { bl, fetchThreePlsSetting } = this.props;
    const area_code = bl.toAddr.areaCode;
    if (bl.toAddr.type === AddressType.customer) {
      this.setState({
        addAreaCode:
          area_code === null ||
          area_code.length > 6 ||
          !/^\d+$/.test(area_code),
      });
    } else {
      this.setState({
        addAreaCode: false,
      });
    }
    await fetchThreePlsSetting("adidi");
  }

  confirmAreaCodeAdded = () =>
    this.setState(
      {
        collapse: false,
      },
      () =>
        setTimeout(() => {
          this.setState({
            addAreaCode: false,
            collapse: true,
          });
        }, 400)
    );

  render() {
    const { addAreaCode } = this.state;
    const {
      bl,
      user,
      hubs,
      ktvList,
      allKtvList,
      threePls,
      classes,
      fetch3PlsList,
    } = this.props;

    return (
      <div>
        <Typography type="subheading" className={classes.subTitle}>
          Tạo lộ trình
        </Typography>
        {bl.ableToCreateTransport ? (
          addAreaCode ? (
            <AddCustomerAddress
              bl={bl}
              confirmAreaCodeAdded={this.confirmAreaCodeAdded}
              addCustomerAddress={this.props.addCustomerAddress}
              fetchArea={this.props.fetchArea}
            />
          ) : (
            <CreateTransport
              bl={bl}
              user={user}
              ktvList={ktvList}
              allKtvList={allKtvList}
              hubs={hubs}
              threePls={threePls}
              createTransport={this.props.createTransport}
              fetch3PlsList={fetch3PlsList}
            />
          )
        ) : (
          <p>Không tạo được</p>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  bl: state.admin.detail.bl,
  user: state.admin.shared.user,
  hubs: state.admin.shared.hubs,
  ktvList: state.admin.shared.ktvList,
  threePls: state.admin.detail.threePls,
  allKtvList: state.admin.shared.allKtvList,
});

const mapDispatchToProps = (dispatch) => ({
  fetchArea: (area) => dispatch(fetchArea(area)),
  addCustomerAddress: (id, code) => dispatch(addCustomerAddress(id, code)),
  createTransport: (data) => dispatch(createTransport(data)),
  fetchThreePlsSetting: (code) => dispatch(fetchThreePlsSetting(code)),
  fetch3PlsList: (bl_id, data) => dispatch(fetch3PlsList(bl_id, data)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(CreateTransportContainer)
);
