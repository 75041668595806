import { fetchError, fetchSuccess, getThreePlsAvailable } from "@shared";
import { getBlDetailApi } from "@api";

const beginFetch = () => ({
  type: "BEGIN_FETCH",
});

const stopLoading = () => ({
  type: "STOP_LOADING",
});

const fetchBlDetail = (id, params) => async (dispatch) => {
  dispatch(beginFetch());
  const response = await getBlDetailApi(id, params);
  const result = response.data;
  dispatch(stopLoading());
  if (response.status === 200) {
    if (result) {
      dispatch(fetchSuccess());
      dispatch(fetchBlDetailSuccess(result.data.bl));
    } else {
      dispatch(
        fetchError(
          response.status,
          "Có lỗi xảy ra! Vui lòng liên hệ Logistic team để được hỗ trợ!"
        )
      );
    }
  } else {
    if (response.status === 404) {
      window.location.assign("/error/404");
    }
    dispatch(
      fetchError(
        response.status,
        result.data ? result.data.message : "Có lỗi xảy ra"
      )
    );
  }
};
export default fetchBlDetail;

const fetchBlDetailSuccess = (bl) => ({
  type: "FETCH_BL_DETAIL_SUCCESS",
  payload: {
    bl,
  },
});

export const fetch3PlsList = (bl_id, data) => async (dispatch, getState) => {
  dispatch(beginFetch());

  const response = await getThreePlsAvailable(bl_id, data);
  const result = response.data;
  if (response.status === 200) {
    if (result) {
      dispatch(fetchSuccess());
      dispatch(fetch3PlsListSuccess(result.data.ThreePls));
    } else {
      dispatch(
        fetchError(
          response.status,
          "Có lỗi xảy ra! Vui lòng liên hệ Logistic team để được hỗ trợ!"
        )
      );
    }
  } else {
    if (response.status === 404) {
      window.location.assign("/error/404");
    }
    dispatch(fetchError(response.status, result.data.message));
  }
  return result;
};

const fetch3PlsListSuccess = (threePls) => ({
  type: "FETCH_BL_AVAILABLE_3PLS",
  payload: threePls,
});
