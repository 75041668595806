import React, { useEffect } from "react";
import { RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import memoize from "memoize-one";
const styles = () => ({
  radio: {
    //TODO
  },
});
const mapGroupFromSetting = memoize((setting) => {
  const arr = Object.keys(setting).map((x) => ({
    value: setting[x].value,
    label: setting[x].name,
  }));
  return arr;
});

const ServiceTypeSelection = (props) => {
  const { settings, onChange } = props;
  const options = mapGroupFromSetting(props.settings);
  useEffect(() => {
    if (settings && options && options.length > 0) {
      onChange(options[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  return (
    <RadioGroup
      aria-label="serviceType"
      name="serviceType"
      style={{ flexDirection: "row" }}
      value={props.value}
      className={props.classes.radio}
      onChange={(e, v) => props.onChange(v)}
    >
      {options.map((x, key) => (
        <FormControlLabel
          key={key}
          value={x.value}
          control={<Radio color="primary" />}
          label={x.label}
        />
      ))}
    </RadioGroup>
  );
};

export default withStyles(styles)(ServiceTypeSelection);
