import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./app/App";
import createStore from "./store";

export const store = createStore(window.__INITIAL_STATE__);

const iFrameData = window.name ? JSON.parse(window.name) : {};
window.localization = iFrameData.localization;
window.currentSeller = iFrameData.currentSeller;
window.isIFrame = Boolean(
  iFrameData && iFrameData.localization && iFrameData.currentSeller
);
window.iFrameData = iFrameData;

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);
