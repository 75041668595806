import React from "react";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import ScrollMenu from "react-horizontal-scrolling-menu";

const styles = (theme) => ({
  img: {
    display: "block",
    "&:hover": {
      filter:
        "grayscale(32%) sepia(25%) brightness(106%) hue-rotate(321deg) saturate(881%) contrast(110%)",
    },
  },
  active: {
    filter:
      "grayscale(32%) sepia(25%) brightness(106%) hue-rotate(321deg) saturate(881%) contrast(110%)",
  },
  container: {
    width: "100%",
  },
  item: {
    marginRight: 20,
    textAlign: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  label: {
    fontSize: 13,
  },
  itemWrapper: {
    "&:focus": {
      outline: "none !important",
    },
  },
});

const flatList = (tree, list) => {
  tree.forEach((element) => {
    if (element.children && element.children.length > 0) {
      flatList(element.children, list);
    } else {
      list.push(element);
    }
  });
};
const VehicleSelection = (props) => {
  // list of items
  const list = [];
  flatList(props.data, list);
  return (
    <div className={props.classes.container}>
      <ScrollMenu
        data={list.map((x, key) => (
          <div key={key} className={props.classes.item}>
            <img
              onClick={() => props.onChange(x)}
              src={x.extra.imgUrl}
              height={37}
              className={`${props.classes.img} ${
                props.value === x.value ? props.classes.active : ""
              }`}
              alt="vehicle"
            />
            <span className={props.classes.label}>{x.name}</span>
          </div>
        ))}
        arrowLeft={<i className="fas fa-chevron-circle-left" />}
        arrowRight={<i className="fas fa-chevron-circle-right" />}
        wrapperStyle={{ marginLeft: 10, marginRight: 10 }}
        itemClass={props.classes.itemWrapper}
      />
    </div>
  );
};

export default compose(withStyles(styles))(VehicleSelection);
