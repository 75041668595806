import React, { useState, useEffect } from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/moment";
import AdminMain from "./admin/Main";
import TekoID from "teko-oauth2";
import Lottie from "react-lottie";
import animationData from "./admin/animations/loading-bubble.json";
import Layout from "./layout";
import { getCurrentClientId, getCurrentOAuthDomain } from "./admin/utils/iam";

import "moment/locale/vi";
import "rc-input-number/assets/index.css";
import "react-select/dist/react-select.css";
import "react-virtualized-select/styles.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-dropdown-tree-select/dist/styles.css";

const App = () => {
  const [init, setInit] = useState(true);

  useEffect(() => {
    const clientId = getCurrentClientId(window.IAM_CLIENTS);
    const oauthDomain = getCurrentOAuthDomain(window.IAM_CLIENTS);

    const parentClientId = _.get(window.iFrameData, ["clientId"], clientId);
    const parentClientOauth = _.get(
      window.iFrameData,
      ["oauthDomain"],
      oauthDomain
    );

    TekoID.init({
      clientId: parentClientId,
      oauthDomain: parentClientOauth,
      redirectUri: `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ""
      }`,
      scopes: [
        "openid",
        "profile",
        "read:permissions",
        "op",
        "fms",
        "om",
        "wms",
        "lo",
      ],
    }).then(() => {
      TekoID.user
        .getFullUserInfo()
        .then((response) => {
          window.userInfo = response;
        })
        .finally(() => setInit(false));
    });
  }, []);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale="vi">
      <Layout>{init ? <LoadingScreen /> : <AppContent />}</Layout>
    </MuiPickersUtilsProvider>
  );
};

function AppContent() {
  if (TekoID.user.isLoggedIn()) {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale="vi">
        <AdminMain />
      </MuiPickersUtilsProvider>
    );
  } else {
    TekoID.user.login();
    return <div />;
  }
}

const useStyles = makeStyles({
  container: {
    background: "white",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
});

function LoadingScreen() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Lottie
        options={{
          loop: true,
          animationData: animationData,
        }}
        height={600}
        width={600}
      />
    </div>
  );
}

export default App;
