import React from "react";
import Button from "@material-ui/core/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import { SPECIAL_THREE_PLS } from "@constants";
import { mapShipMethodToText } from "@utils";
import { connect } from "react-redux";
import { openNotiDialog } from "@shared";

const styles = (theme) => ({
  button: {
    textTransform: "none",
    backgroundColor: "#03a9f4",
    color: "#fff",
    marginLeft: 8,
  },
});

class ConfirmOK extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      updating: false,
    };
  }

  /* Không cho phép thao tác nếu:
    - transport được giao bởi 3pls đặc biệt
  */
  toggleDialog = () => {
    const { transport, openNotiDialog } = this.props;
    if (this.isNotAllowed()) {
      openNotiDialog(
        "failed",
        <span>
          Vui lòng liên hệ{" "}
          <span className="stressPink">
            {mapShipMethodToText(transport.shipMethod)}
          </span>{" "}
          để thực hiện thao tác
        </span>
      );
    } else {
      this.setState(
        {
          open: !this.state.open,
        },
        () => {
          if (this.state.updating) {
            this.setState({
              updating: false,
            });
          }
        }
      );
    }
  };

  confirmDelivered = async () => {
    this.setState({
      updating: true,
    });
    const { transport, confirmTransportDelivered } = this.props;
    await confirmTransportDelivered(transport.id);
    this.setState({
      updating: false,
    });
  };

  isNotAllowed = () => {
    const { transport } = this.props;
    // if (SPECIAL_THREE_PLS.includes(transport.shipMethod)) return true;
    return false;
  };

  renderConfirmMsg = () => {
    const { open, updating } = this.state;
    return (
      <Dialog open={open} onClose={() => this.closeDialog("open")}>
        <DialogTitle>Xác nhận lộ trình</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Bạn muốn xác nhận lộ trình vận chuyển thành công?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.toggleDialog()}>Hủy</Button>
          <Button
            color="primary"
            onClick={() => this.confirmDelivered()}
            disabled={updating}
          >
            {updating && <CircularProgress size={20} />} &nbsp; Đồng ý
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  closeDialog = (type) => {
    this.setState({ [type]: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Button
          variant="contained"
          className={classes.button}
          onClick={this.toggleDialog}
        >
          {this.isNotAllowed() && (
            <span>
              <i className="fas fa-exclamation-triangle" />
              {"  "}
            </span>
          )}
          Xác nhận vận chuyển thành công
        </Button>
        {this.renderConfirmMsg()}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  openNotiDialog: (type, text) => dispatch(openNotiDialog(type, text)),
});

export default withStyles(styles)(connect(null, mapDispatchToProps)(ConfirmOK));
