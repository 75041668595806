import React from "react";
import fetchSuccess from "../shared/actions/fetchSuccess";
import { Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import ReportRoute from "./Route";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { getAsiaBranchesList } from "./actions";

//styles
const styles = (theme) => ({
  text: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
  },
  "@global": {
    ".filterContainer": {
      padding: "2rem 1rem 1rem 1rem",
      borderRadius: "1rem 1rem 0 0",
      boxShadow: "0 1px 5px rgba(0, 0, 0, 0.15)",
    },
    ".title": {
      fontWeight: "bold",
      fontSize: 25,
      color: "darkslateblue",
    },
    table: {
      width: "100%",
      borderSpacing: 0,
      boxShadow: "0 1px 5px rgba(0, 0, 0, 0.15)",
      borderRadius: "1rem",
    },
    ".label": {
      paddingRight: "1rem",
      width: "7%",
      lineHeight: 2,
      fontSize: 13,
      color: "#000000b8",
      fontWeight: "bold",
    },
    ".floatingButton": {
      position: "fixed",
      right: "50%",
      bottom: "0.7rem",
      background: "rgba(63,81,181,0.9)",
      zIndex: 1,
    },
    input: {
      border: "thin solid #80808054",
      height: 38,
      borderRadius: 5,
      width: "calc(100% - 15px)",
      padding: "0rem 5px",
    },
    "tr.totalRow > td": {
      background: "#ffff0073 !important",
      fontWeight: "bold",
    },
    "table > tbody tr > td": {
      padding: "1rem",
    },
    "tr.parentRow > td": {
      background: "#6686ff24 !important",
      fontWeight: "bold",
    },
    "tr.oddRow > td": {
      background: "#ecf4fd5c",
    },
    "tr.evenRow > td": {
      background: "white",
    },
    ".Select .Select-input > input": {
      padding: 0,
    },
    ".searchBtn": {
      background: "cornflowerblue",
      color: "white",
      "&:disabled": {
        background: "lightgray",
      },
    },
    ".saveFilterBtn": {
      background: "#ffa240",
      color: "white",
    },
  },
});

const options = [
  {
    value: "/report/vehicle-report",
    label: "Báo cáo theo phương tiện",
  },
  // {
  //   value: "/report/shipper-summary-report",
  //   label: "Báo cáo tổng hợp theo nhân viên",
  // },
  // {
  //   value: "/report/shipper-detail-report",
  //   label: "Báo cáo chi tiết theo nhân viên",
  // },
  {
    value: "/report/ship-date-summary-report",
    label: "Báo cáo tổng hợp lí do hẹn giao",
  },
  // {
  //   value: "/report/ship-date-detail-report",
  //   label: "Báo cáo chi tiết lí do hẹn giao",
  // },
  {
    value: "/report/shipper-daily-report",
    label: "Báo cáo phí xăng xe theo ngày",
  },
  {
    value: "/report/shipper-summary-fuel-cost-report",
    label: "Báo cáo phí xăng xe tổng hợp",
  },
  // {
  //   value: "/report/shipper-status-report",
  //   label: "Báo cáo trạng thái nhân viên",
  // },
];

class ReportContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      report: "",
    };
  }

  componentDidMount() {
    const { history, fetchSuccess, getAsiaBranchesList } = this.props;
    const loc = history.location.pathname;
    this.setState({ report: loc });
    fetchSuccess();
    if (loc === "/report")
      this.handleChangeReport({ value: "/report/vehicle-report" });
    getAsiaBranchesList();
  }

  handleChangeReport = (e) => {
    const { history } = this.props;
    this.setState({ report: e ? e.value : "" });
    history.push(e ? e.value : "/report");
  };

  componentDidUpdate(prevProp) {
    if (this.props.location.pathname !== prevProp.location.pathname) {
      this.setState({ report: this.props.location.pathname });
    }
  }

  render() {
    const { report } = this.state;
    const { classes, isKTV = false } = this.props;
    const reportOptions =
      isKTV === true
        ? [
            {
              value: "/report/shipper-daily-report-for-ktv",
              label: "Báo cáo phí xăng xe theo ngày",
            },
          ]
        : options;
    return (
      <React.Fragment>
        <Grid container alignItems="center" className={classes.text}>
          <Grid item md={1} xs={12}>
            Chọn loại báo cáo:
          </Grid>
          <Grid item md={3} xs={12}>
            <Select
              placeholder="Chọn loại báo cáo..."
              value={report}
              options={reportOptions}
              onChange={this.handleChangeReport}
              clearable={false}
            />
          </Grid>
        </Grid>
        <br />
        <ReportRoute />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  bls: state.admin.group.bls,
  selectedBls: state.admin.group.selectedBls,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSuccess: () => dispatch(fetchSuccess()),
  getAsiaBranchesList: () => dispatch(getAsiaBranchesList()),
});

export default withRouter(
  withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(ReportContainer)
  )
);
