import React from "react";
import { mapSnackbarBackground, mapSnackbarIcon } from "@utils";
import { Snackbar, SnackbarContent, IconButton } from "@material-ui/core";
import { toggleSnackBar } from "@shared";
import { connect } from "react-redux";

const SnackbarNotification = ({ snackBar, toggleSnackBar }) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={snackBar.open}
      autoHideDuration={5000}
      onClose={() => toggleSnackBar(false, "", snackBar.type)}
    >
      <SnackbarContent
        style={{
          background: mapSnackbarBackground(snackBar.type),
        }}
        message={
          <span>
            {snackBar.type ? (
              <i className={mapSnackbarIcon(snackBar.type)} />
            ) : (
              ""
            )}
            &nbsp;
            {snackBar.message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => toggleSnackBar(false, "", snackBar.type)}
          >
            <i className="material-icons">close</i>
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

const mapStateToProps = (state) => ({
  snackBar: state.admin.shared.snackBar,
});

const mapDispatchToProps = (dispatch) => ({
  toggleSnackBar: (state, message, type) =>
    dispatch(toggleSnackBar(state, message, type)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SnackbarNotification);
