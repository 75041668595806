import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import TransportList from "./TransportList";
import { Transport } from "../../../../../models";
import { changeStateLabel, filterTransports } from "@utils";
import GHNBill from "./GHNBill";
import { WMS_STATUS } from "@constants";
import { connect } from "react-redux";
import { getAllDataSamePlref } from "../../actions";

//styles
const styles = (theme) => ({
  row: {
    display: "flex",
    justifyContent: "space-between",
  },
  subTitle: {
    fontSize: 14,
    padding: "8px 0 16px 0",
    fontFamily: "Source Sans Pro, sans-serif",
    textTransform: "uppercase",
    fontWeight: 600,
    letterSpacing: 1,
    color: "#7266ba",
  },
  button: {
    textTransform: "none",
    marginBottom: 16,
    color: "#fff !important",
  },
});

const ghnbill = {
  CircleName: null,
  ClientAddress: "999 Liễu giai",
  ClientContactName: "Sony center Liễu Giai",
  ClientContactPhone: "0368781408",
  Content: "1 ban chai danh rang",
  CreatedDate: "/Date(1547697011797)/",
  CreatedDateS: "17/01/2019 10:50",
  CustomerName: "Đinh Thị Tuyết",
  CustomerPhone: "0966888888",
  ExpectedDeliveryTime: "/Date(1547913600000)/",
  ExpectedDeliveryTimeS: "19/01/2019",
  ExternalCode: null,
  FromDistrictID: 1482,
  FromProvinceID: 201,
  FromWardName: null,
  Hub: null,
  Note: "Giao giờ hành chính",
  OrderCode: "EARX79X3Q",
  SelectedNoteContent: "Cho xem hàng, không cho thử",
  ServiceName: "Gói t?m ph?c v? jobsync",
  ShippingAddress: "274/20 Nguyễn Văn Đậu, Phường 11, Quận Gò Vấp",
  ShippingOrderID: 101379807,
  ShippingServiceID: 0,
  Sorting: "15-01-06/19",
  ToDistrictID: 1461,
  ToDistrictName: "Quận Gò Vấp",
  ToDistrictNameOrigin: "Quận Gò Vấp",
  ToProvinceID: 202,
  ToProvinceName: "Hồ Chí Minh",
  ToWardName: null,
};

class TransportInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      openPlref: -1,
      transports: [],
    };
  }

  changeStep = (step) =>
    this.setState({
      step,
    });

  changeOpenPlref = (openPlref) => {
    let isOpen = this.state.openPlref !== openPlref;
    this.setState({
      openPlref: isOpen ? openPlref : -1,
    });
    if (isOpen) {
      this.props.getAllDataSamePlref(this.state.transports[openPlref]["plRef"]);
    }
  };

  componentDidMount() {
    const { bl } = this.props;
    let transports = changeStateLabel(bl);
    transports = filterTransports(bl, transports);
    this.setState(
      {
        transports: transports.map((tr) => new Transport(tr)),
      },
      () => {
        this.setState({
          step: this.state.transports.length - 1,
        });
      }
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { bl } = nextProps;
    let transports = changeStateLabel(bl);
    transports = filterTransports(bl, transports);
    this.setState({
      transports: transports.map((tr) => new Transport(tr)),
    });
  }
  render() {
    const { step, transports } = this.state;
    const { bl, printBill, classes } = this.props;
    return (
      <div>
        <div className={classes.row}>
          <Typography type="subheading" className={classes.subTitle}>
            Thông tin lộ trình
          </Typography>
          {bl.canExport && bl.wmsStatus === WMS_STATUS.pack && (
            <div>
              <Button
                variant="contained"
                color="secondary"
                href={`
                    ${window.API_URL.slice(0, -4)}/admin/bl/${
                  bl.id
                }/transport_label`}
                className={classes.button}
              >
                In nhãn
              </Button>
              &nbsp;&nbsp;
              {["vtpost", "nhat_tin", "ghn"].includes(
                bl.lastTransport.shipMethod
              ) &&
                bl.wmsStatus === WMS_STATUS.pack && (
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={() =>
                      printBill(
                        bl.lastTransport.shipMethod.replace("_", ""),
                        bl.lastTransport.plRef
                      )
                    }
                  >
                    In bill
                  </Button>
                )}
            </div>
          )}
        </div>
        {transports.length > 0 ? (
          <TransportList
            step={step}
            transports={transports}
            changeStep={this.changeStep}
            type={bl.requestType}
            bl={bl}
            changeOpenPlref={this.changeOpenPlref}
            openPlref={this.state.openPlref}
          />
        ) : (
          <p>Không có thông tin</p>
        )}
        <GHNBill data={ghnbill} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getAllDataSamePlref: (plref) => dispatch(getAllDataSamePlref(plref)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(TransportInfo)
);
