import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid, Paper } from "@material-ui/core";
import DatePicker from "react-date-picker";
import HubFilter from "../../../bl_list/components/filters/hub_filter/HubFilter";
import { ReportRange, NumberSelection, BookmarkBar } from "@shared";
import Select from "react-select";
import { getReportCycleRange } from "@utils";
import { getUserSavedFilter } from "@api";
import moment from "moment";

//styles
const styles = (theme) => ({
  "@global": {
    ".multiselect .Select-multi-value-wrapper": {
      maxHeight: "5rem",
      overflowY: "scroll",
      display: "block",
    },
    ".datePicker": {
      borderRadius: 5,
      width: "100%",
      height: 36,
    },
    ".react-date-picker__wrapper": {
      border: "1px solid #80808054",
      padding: "0.3rem 0.4rem",
      width: "100%",
      borderRadius: 5,
    },
    ".react-date-picker .react-date-picker__wrapper input[name='year']": {
      flex: 1,
    },
  },
});

class ShipDateSummaryReportFilter extends React.Component {
  state = {
    showBookmark: false,
    bmHeigh: null,
    filters: [],
  };

  async componentDidMount() {
    const filter = document.getElementById("filterContainer");
    this.setState({ bmHeigh: filter.clientHeight });
    this.getBookmark();
  }

  getBookmark = async () => {
    const response = await getUserSavedFilter("retry_shipping");
    if (response.status === 200) {
      this.setState({ filters: response.data.data.Filters });
    }
  };

  setFilter = (obj) => {
    const { filter, onChange } = this.props;
    onChange({
      ...filter,
      ...obj,
    });
  };

  handleChangeGroupType = (v) => {
    const { filter } = this.props;
    const range = getReportCycleRange(v);
    this.setFilter({
      group_type: v,
      frequency: filter.frequency > range[1] ? range[1] : filter.frequency,
    });
  };

  handleSelectFilter = (filter) => {
    const { onSearch } = this.props;
    let initialFilter = {
      from_hub: "",
      to_hub: "",
      start_date: moment().subtract(3, "months").startOf("month").toDate(),
      frequency: 3,
      group_type: "month",
      reason_id: "",
    };
    const params = new URLSearchParams(filter.query);
    let obj = Object.fromEntries(params.entries());
    obj.frequency = Number(obj.frequency);
    obj.start_date = new Date(obj.start_date);
    this.setFilter({ ...initialFilter, ...obj });
    onSearch();
  };

  render() {
    const { hubs, filter, onSearch, reasons } = this.props;
    const { showBookmark, filters } = this.state;
    const _reasons = reasons.map((x) => ({
      label: x.label,
      value: x.value.toString(),
    }));
    const range = getReportCycleRange(filter.group_type);
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Paper className="filterContainer" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} id="filterContainer">
              <Grid
                container
                alignItems="flex-end"
                className="filterRow"
                spacing={1}
              >
                <Grid item xs={12}>
                  <span className="title">BÁO CÁO TỔNG HỢP LÍ DO HẸN GIAO</span>
                </Grid>

                <Grid item xs={12} md={3}>
                  <span className="label">Kho xuất</span>
                  <div className="multiselect">
                    <HubFilter
                      value={filter.from_hub}
                      hubs={hubs}
                      type="fromHub"
                      setFilter={(e, f) => this.setFilter({ from_hub: f })}
                    />
                  </div>
                </Grid>
                {/* <Grid item xs={12} md={3}>
                <span className="label">Kho nhận</span>
                <div className="multiselect">
                  <HubFilter
                    value={filter.to_hub}
                    hubs={hubs}
                    type="fromHub"
                    setFilter={(e, f) => this.setFilter({ to_hub: f })}
                  />
                </div>
              </Grid> */}
                <Grid item xs={12} md={3}>
                  <span className="label ">Lí do hẹn</span>
                  <div>
                    <Select
                      options={_reasons}
                      value={filter.reason_id}
                      multi
                      simpleValue
                      placeholder="Chọn lí do..."
                      onChange={(e) => this.setFilter({ reason_id: e })}
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid
                container
                alignItems="flex-end"
                wrap="nowrap"
                className="filterRow"
                spacing={1}
              >
                <Grid item xs={12} md={3}>
                  <span className="label ">Ngày bắt đầu báo cáo</span>
                  <div>
                    <DatePicker
                      value={filter.start_date}
                      className="datePicker"
                      showLeadingZeros
                      onChange={(e) => this.setFilter({ start_date: e })}
                      locale="vi-VN"
                      calendarIcon={null}
                      clearIcon={null}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <span className="label">Loại báo cáo</span>
                  <div className="multiselect">
                    <ReportRange
                      value={filter.group_type}
                      onChange={this.handleChangeGroupType}
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={3}>
                  <span className="label">Số kỳ báo cáo</span>
                  <div>
                    <NumberSelection
                      min={range[0]}
                      max={range[1]}
                      value={filter.frequency}
                      onChange={(e) =>
                        this.setFilter({
                          frequency: e,
                        })
                      }
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Button className="searchBtn" onClick={onSearch}>
                    Tìm kiếm
                  </Button>
                  &nbsp;
                  <Button
                    className="saveFilterBtn"
                    onClick={() =>
                      this.setState({ showBookmark: !showBookmark })
                    }
                  >
                    <i className="far fa-bookmark" />
                    &nbsp;&nbsp;Bộ lọc
                  </Button>
                </Grid>
              </Grid>
            </div>
            <BookmarkBar
              currentFilter={filter}
              type="retry_shipping"
              filters={filters}
              open={showBookmark}
              saveCallBack={() => this.getBookmark()}
              onDeleteCallback={() => this.getBookmark()}
              onSelect={this.handleSelectFilter}
            />
          </Paper>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  hubs: state.admin.shared.hubs,
  reasons: state.admin.ShipDateSummaryReport.reasons,
});

const mapDispatchToProps = (dispatch) => ({});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ShipDateSummaryReportFilter)
);
