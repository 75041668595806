import apiService from "./base";

export const dumpAddrApi = null;

export const getDistance = async (data) => {
  return await apiService({
    url: "/admin/address/distance",
    method: "post",
    data: data,
  });
};
