import React from "react";
import Select from "react-select";

class WardSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      wardList: [],
    };
  }
  componentDidMount() {
    const { setDefaultWard, ward } = this.props;
    if (this.props.district) {
      const { district } = this.props;
      let list = [];
      this.props.fetchArea(district).then((areas) => {
        if (areas) {
          areas.forEach((area) => {
            if (area.code === ward) {
              setDefaultWard(area.name);
            }
            list.push({
              value: area.code,
              label: area.name,
            });
          });
          this.setState({
            wardList: list,
          });
        }
      });
    } else {
      this.setState({
        wardList: [],
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { setDefaultWard, ward } = this.props;
    if (nextProps.district) {
      if (nextProps.district && nextProps.district !== this.props.district) {
        const { district } = nextProps;
        let list = [];
        this.props.fetchArea(district).then((areas) => {
          if (areas) {
            areas.forEach((area) => {
              if (area.code === ward) {
                setDefaultWard(area.name);
              }
              list.push({
                value: area.code,
                label: area.name,
              });
            });
            this.setState({
              wardList: list,
            });
          }
        });
      }
    } else {
      this.setState({
        wardList: [],
      });
    }
  }

  _handleSelectChange = (e) => {
    const label = e && e.label;
    const value = e && e.value;
    this.props.changeWard(value, label);
  };

  render() {
    const { wardList } = this.state;
    const { ward } = this.props;
    return (
      <Select
        value={ward}
        onChange={this._handleSelectChange}
        options={wardList}
        wrapperStyle={{ flex: 1 }}
        placeholder="Phường/Xã"
        style={{ height: 40 }}
      />
    );
  }
}

export default WardSelector;
