import React from "react";
import Select from "react-select";

const CreateTransportUserFilter = ({
  value,
  ktvList,
  setFilter,
  placeholder,
  label,
  multi = true,
}) => {
  let ktvs = ktvList.map((ktv) => {
    ktv.value = ktv.value.toString();
    return ktv;
  });
  ktvs = ktvs.filter((x) => ["mod", "admin", "super_admin"].includes(x.roleId));
  return (
    <Select
      value={value}
      onChange={(ktv) =>
        setFilter(label ? label : "createTransportUser", ktv ? ktv : "")
      }
      options={ktvs}
      multi={multi}
      simpleValue
      closeOnSelect={multi ? false : true}
      placeholder={placeholder ? placeholder : "Người tạo lộ trình"}
      wrapperStyle={{ width: "100%" }}
    />
  );
};

export default CreateTransportUserFilter;
