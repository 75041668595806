import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { formatCurrency } from "@utils";
import { setMap } from "../actions";
import { connect } from "react-redux";
import { BlRequestType } from "../../../constants";

const styles = (theme) => ({
  row: {
    cursor: "pointer",
    "&:hover": {
      background: "#959fc738",
    },
  },
  label: {
    fontWeight: "bold",
    letterSpacing: 1,
  },
});

class DateShipping extends React.Component {
  mapActionTypeToStyle = (type) => {
    let style = {
      borderRadius: "0.5rem",
      padding: "0.1rem",
      lineHeight: "2rem",
    };
    switch (type) {
      case "pick":
        return {
          ...style,
          color: "darkorange",
          background: "#ffa5003d",
        };
      case "at_cs_house":
        return {
          ...style,
          color: "#1174d4",
          background: "#0078d75c",
        };
      case "retry":
        return { ...style, color: "#ff3d00", background: "#ff000029" };
      case "partial_returning":
      case "returning":
        return { ...style, color: "#875fda", background: "#80008030" };
      default:
        return {};
    }
  };
  mapActionTypeToLabel = (type) => {
    switch (type) {
      case "pick":
        return (
          <span style={this.mapActionTypeToStyle(type)}>
            Lấy hàng/Xác nhận lấy hàng
          </span>
        );
      case "at_cs_house":
        return (
          <span style={this.mapActionTypeToStyle(type)}> Xác nhận đến nơi</span>
        );
      case "retry":
        return (
          <span style={this.mapActionTypeToStyle(type)}>Yêu cầu giao lại</span>
        );
      case "partial_returning":
        return (
          <span style={this.mapActionTypeToStyle(type)}>
            Xác nhận hoàn hàng 1 phần
          </span>
        );
      case "returning":
        return (
          <span style={this.mapActionTypeToStyle(type)}>
            Xác nhận hoàn toàn bộ
          </span>
        );
      default:
        return <span></span>;
    }
  };
  mapOrderCode = (element) => {
    const request_type = element.request_type
      ? element.request_type.toLowerCase()
      : "";
    return request_type === BlRequestType.sale_order
      ? element.order_code
      : element.request_id;
  };

  render() {
    const { classes, data, setMap } = this.props;
    return (
      <React.Fragment>
        {data && data.length > 0 ? (
          data.map((x, key) => (
            <tr
              className={classes.row}
              key={key}
              onClick={() => setMap({ center: x.location, zoom: 18 })}
            >
              <td>{key + 1}</td>
              <td>{x.created_at}</td>
              <td className={classes.label}>
                {this.mapActionTypeToLabel(x.action_type)}
              </td>
              <td> {this.mapOrderCode(x)}</td>
              <td>
                {x.location && x.location.lat ? (
                  <a
                    className="link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://www.google.com/maps/search/?api=1&query=${x.location.lat},${x.location.lng}`}
                  >
                    {x.location.full_address || "Link Google Map"}
                  </a>
                ) : (
                  "Không xác định"
                )}
              </td>
              <td align="right">{x.distance}</td>
              <td align="right">{formatCurrency(x.warning_distance)}</td>
              <td align="right">{formatCurrency(x.warning_speed)}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td>Không có thao tác nào</td>
          </tr>
        )}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setMap: (config) => dispatch(setMap(config)),
});

export default withStyles(styles)(
  connect(null, mapDispatchToProps)(DateShipping)
);
