import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { formatNumber } from "../../../utils/misc";

const styles = (theme) => ({
  text: {
    fontFamily: "Noto Sans, sans-serif",
  },
  cell: {
    color: "#797979",
    fontWeight: 500,
    paddingRight: 5,
    paddingLeft: 5,
    background: "white",
  },
  nameCell: {
    maxWidth: "20rem",
    textAlign: "left",
  },
  priceCell: {
    textAlign: "right",
  },
  codTitle: {
    textAlign: "left",
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
  totalTable: {
    width: "30%",
  },
  totalRow: {
    height: 32,
  },
  header: {
    padding: "0.2rem 0.5rem",
    background: "#8d8d8d99",
    color: "white",
  },
  skuCell: {
    padding: "0.2rem 0.5rem",
  },
});

class BlLines extends React.Component {
  render() {
    const { blLines, classes, cod, requestType } = this.props;

    return (
      <div style={{ maxHeight: "22rem", overflow: "auto" }}>
        <Table className="rowDetailExpand">
          <TableHead>
            <TableRow>
              <TableCell
                className={classes.header}
                style={{ textAlign: "left" }}
              >
                SKU
              </TableCell>
              <TableCell className={classes.header}>Mã nhóm</TableCell>
              <TableCell className={classes.header}>Tên sản phẩm</TableCell>
              <TableCell className={`${classes.header} ${classes.priceCell}`}>
                Đơn giá(VNĐ)
              </TableCell>
              <TableCell className={`${classes.header} ${classes.priceCell}`}>
                Số lượng
              </TableCell>
              <TableCell className={`${classes.header} ${classes.priceCell}`}>
                Số lượng đã giao
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {blLines &&
              blLines.length &&
              blLines.map((pr, idx) => (
                <TableRow key={idx}>
                  <TableCell className={`${classes.skuCell} ${classes.cell}`}>
                    {pr.sku}
                  </TableCell>
                  <TableCell className={`${classes.nameCell} ${classes.cell}`}>
                    {pr.category_code || ""}
                  </TableCell>
                  <TableCell className={`${classes.nameCell} ${classes.cell}`}>
                    {pr.name}
                  </TableCell>
                  <TableCell className={`${classes.cell} ${classes.priceCell}`}>
                    {formatNumber(pr.price)}
                  </TableCell>
                  <TableCell className={`${classes.cell} ${classes.priceCell}`}>
                    {pr.quantity}
                  </TableCell>
                  <TableCell className={`${classes.cell} ${classes.priceCell}`}>
                    {pr.success_quantity}
                  </TableCell>
                </TableRow>
              ))}
            {requestType !== "WH_TRANSFER" && (
              <React.Fragment>
                <TableRow className={classes.totalRow}>
                  <TableCell colSpan={2} className={classes.cell} />
                  <TableCell className={`${classes.cell} ${classes.priceCell}`}>
                    <strong>Tổng cộng:</strong>
                  </TableCell>
                  <TableCell className={`${classes.cell} ${classes.priceCell}`}>
                    {cod.grandTotal}
                  </TableCell>
                  <TableCell className={`${classes.cell} ${classes.priceCell}`}>
                    <strong>COD:</strong>
                  </TableCell>
                  <TableCell className={`${classes.cell} ${classes.priceCell}`}>
                    {cod.cod}
                  </TableCell>
                </TableRow>
                <TableRow className={classes.totalRow}>
                  <TableCell colSpan={2} className={classes.cell} />
                  <TableCell className={`${classes.cell} ${classes.priceCell}`}>
                    <strong>Tiền cọc:</strong>
                  </TableCell>
                  <TableCell className={`${classes.cell} ${classes.priceCell}`}>
                    {cod.prePaid}
                  </TableCell>
                  <TableCell className={`${classes.cell} ${classes.priceCell}`}>
                    <strong>Paid COD:</strong>
                  </TableCell>
                  <TableCell className={`${classes.cell} ${classes.priceCell}`}>
                    {cod.paidCOD}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            )}
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default withStyles(styles)(BlLines);
