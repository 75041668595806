import _ from "lodash";

export const getCurrentClientId = (iam) =>
  _.get(iam.clients, [window.location.origin, "clientId"], iam.defaultClientId);

export const getCurrentOAuthDomain = (iam) =>
  _.get(
    iam.clients,
    [window.location.origin, "oauthDomain"],
    iam.defaultOAuthDomain
  );
