import _ from "lodash";
import moment from "moment";

const _initialState = {
  filter: {
    start_date: moment().subtract(1, "months").startOf("month").toDate(),
    end_date: moment().subtract(1, "months").endOf("month").toDate(),
  },
  loading: false,
};

const ShipperSummaryFuelCostReport = (state = _initialState, action) => {
  switch (action.type) {
    case "SHIPPER_SUMMARY_FUEL_COST_REPORT_CHANGE_FILTER":
      return {
        ...state,
        filter: action.payload,
      };
    case "SHIPPER_SUMMARY_FUEL_COST_REPORT_CHANGE_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default ShipperSummaryFuelCostReport;
