import apiService from "./base";

export const dumpTransportApi = "";

export const getEstimateFee = (id, data) => {
  return apiService({
    url: `/admin/transport/${id}/estimate_delivery_fee`,
    params: data,
  });
};

export const updateTransportDetail = (id, data) => {
  return apiService({
    url: `/admin/transport/${id}/update_measurement`,
    method: "post",
    params: data,
  });
};

export const updateTimeKeeping = (id, data) => {
  return apiService({
    url: `/admin/transport/${id}/update_timekeeping`,
    method: "post",
    data: data,
  });
};

export const updateSuggestionCount = (suggest_id, type) => {
  const params = {
    suggestion_id: suggest_id,
    type: type,
  };
  return apiService({
    url: `/admin/suggestions/logs`,
    params: params,
  });
};

export const createTransportApi = (data) => {
  return apiService({
    url: `/admin/transport`,
    method: "put",
    data: data,
  });
};

export const getSummarizeShipperKPI = (data) => {
  return apiService({
    url: `/transport/summarize-shipper-kpi`,
    params: data,
  });
};

export const getSummarizeShipperKPIExcel = (data) => {
  return apiService({
    url: `/transport/summarize-shipper-kpi-excel`,
    params: data,
  });
};

export const getDetailShipperKPI = (data) => {
  return apiService({
    url: `/transport/detail-shipper-kpi`,
    params: data,
  });
};

export const getDetailShipperKPIExcel = (data) => {
  return apiService({
    url: `/transport/detail-shipper-kpi-excel`,
    params: data,
  });
};

export const cancelTransportAPI = ({ id, reason, withSamePl }) => {
  return apiService({
    url: `/admin/transport/${id}/cancel`,
    method: "post",
    data: { reason, withSamePl },
  });
};

/* Xác nhận lấy hàng */
export const confirmTransportPickedApi = (transport_id) => {
  return apiService({
    url: `/admin/transport/${transport_id}/pick`,
    method: "post",
  });
};

/* Xác nhận giao thành công/nhập hub */
export const confirmTransportOKApi = (transport_id) => {
  return apiService({
    url: `/admin/transport/${transport_id}/ok`,
    method: "post",
  });
};

/* Xác nhận hoàn hàng/Yêu cầu hoàn */
export const confirmTransportReturnedApi = (transport_id, reason) => {
  return apiService({
    url: `/admin/transport/${transport_id}/confirm_return`,
    method: "post",
    data: { reason: reason },
  });
};

/* Xác nhận hủy lộ trình đang giao */
export const confirmShippingTransportCancelledApi = ({
  id,
  reason,
  withSamePl,
}) => {
  return apiService({
    url: `/admin/transport/${id}/cancel_shipping_transport`,
    method: "post",
    data: { reason, withSamePl },
  });
};

export const createMultipleTransportsApi = (data) => {
  return apiService({
    url: "/admin/transport/create_multiple_transports",
    method: "put",
    data: data,
  });
};
export const groupAdidiTransportApi = (data) => {
  return apiService({
    url: `/admin/transport/group_transports`,
    method: "put",
    data: data,
  });
};

export const batchActionsApi = (url, data) => {
  return apiService({
    url: url,
    method: "post",
    data: data,
  });
};

export const groupTransportApi = (data) => {
  return apiService({
    url: `/admin/transport/create_multiple_transports`,
    method: "put",
    data: data,
  });
};
