import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Grid, Button, Paper, Popover } from "@material-ui/core";
import { getBusLinesList, changeFilter, toggleBusLineDialog } from "../actions";
import Select from "react-select";

const styles = (theme) => ({
  filterInput: {
    border: "1px solid #a9a9a959",
    height: 40,
    borderRadius: 5,
    padding: "0px 5px",
    width: "100%",
  },
  filterSelect: {
    border: "1px solid #a9a9a959",
    height: 39,
    borderRadius: 5,
  },
  searchBtn: {
    background: "#5268bb",
    color: "white",
  },
  addBtn: {
    background: "#5268bb",
    color: "white",
  },
  text: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
  },
  filterElement: {
    marginRight: "1rem",
  },
  label: {
    fontWeight: "bold",
    fontFamily: "Noto Sans, sans-serif",
    lineHeight: 2,
    fontSize: 12,
    color: "#000000b3",
  },
  container: {
    fontSize: 20,
    textTransform: "uppercase",
    display: "flex",
  },
  title: {
    fontWeight: "bold",
    fontSize: 25,
    color: "darkslateblue",
    flex: 1,
  },
  filterContainer: {
    padding: "2rem 2rem 3rem 1rem",
    boxShadow: "0 1px 5px rgba(0, 0, 0, 0.15)",
    borderRadius: "1rem 1rem 0 0 ",
  },
});

const regionOtions = [
  { label: "Miền Bắc", value: "northern" },
  { label: "Miền Trung", value: "central" },
  { label: "Miền Nam", value: "southern" },
];

class BusLineFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hotKey: false,
    };
    this.hotKeyRef = null;
  }

  handleSearchOnEnter = (e) => {
    const { getBusLinesList } = this.props;
    if (e.keyCode === 13) {
      getBusLinesList();
    }
  };

  render = () => {
    const {
      filter,
      changeFilter,
      getBusLinesList,
      toggleBusLineDialog,
      classes,
    } = this.props;
    const { hotKey } = this.state;

    return (
      <Paper elevation={0} className={classes.filterContainer}>
        <Grid container>
          <Grid item md={12}>
            <div className={`${classes.text} ${classes.container}`}>
              <span className={classes.title}>Quản lý danh sách nhà xe</span>
              <i
                ref={(ref) => (this.hotKeyRef = ref)}
                className="fas fa-keyboard"
                onClick={(e) => this.setState({ hotKey: true })}
              />
              <Popover
                open={hotKey}
                onClose={(e) => this.setState({ hotKey: false })}
                anchorEl={this.hotKeyRef}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                cl
              >
                <div style={{ padding: "1rem" }}>
                  Phím tắt: Tìm kiếm (ALT+F), Thêm mới (ALT+A)
                </div>
              </Popover>
            </div>
          </Grid>
        </Grid>
        <br />
        <Grid
          container
          alignItems="flex-end"
          id="busLineFilter"
          onKeyDown={this.handleSearchOnEnter}
          spacing={1}
        >
          <Grid item xs={12} md={2} className={classes.filterElement}>
            <span className={classes.label}>Tên nhà xe</span>
            <div>
              <input
                autoFocus={true}
                value={filter.name}
                onChange={(e) => changeFilter({ name: e.target.value })}
                className={classes.filterInput}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={3} className={classes.filterElement}>
            <span className={classes.label}>Khu vực</span>
            <Select
              value={filter.region}
              options={regionOtions}
              multi={true}
              simpleValue
              clearable={true}
              placeholder="Chọn khu vực"
              onChange={(e) => changeFilter({ region: e })}
            />
          </Grid>

          <Grid item md xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => getBusLinesList()}
            >
              <i className="fas fa-search" />
              &nbsp; Tìm kiếm
            </Button>
            &nbsp;&nbsp;
            <Button
              variant="contained"
              color="primary"
              onClick={() => toggleBusLineDialog(true, null)}
            >
              <i className="fas fa-plus-circle" /> &nbsp;Thêm nhà xe
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  };
}
const mapStateToProps = (state) => ({
  filter: state.admin.busLineManagement.filter,
});
const mapDispatchToProps = (dispatch) => ({
  getBusLinesList: () => dispatch(getBusLinesList()),
  changeFilter: (pagination) => dispatch(changeFilter(pagination)),
  toggleBusLineDialog: (state, selected) =>
    dispatch(toggleBusLineDialog(state, selected)),
});
export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(BusLineFilter)
);
