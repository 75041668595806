import { combineReducers } from "redux";

//reducers
import { list } from "./bl_list";
import { detail } from "./bl_detail";
import group from "./bl_group/reducer";
import { User, Hub } from "../../models";
import dashboard from "./dashboard/reducer";
import blManagement from "./admin-tool/reducer";
import busLineManagement from "./bus_line_management/reducer";
import ShipmentDetailReport from "./report/ShipmentDetailReport/reducer";
import ShipmentSummaryReport from "./report/ShipmentSummaryReport/reducer";
import VehicleReport from "./report/VehicleReport/reducer";
import ShipDateSummaryReport from "./report/ShipDateSummaryReport/reducer";
import ShipDateDetailReport from "./report/ShipDateDetailReport/reducer";
import ShipperDailyReport from "./report/ShipperDailyReport/reducer";
import ShipperSummaryFuelCostReport from "./report/ShipperSummaryFuelCostReport/reducer";
import ShipperStatusReport from "./report/ShipperStatusReport/reducer";
import Report from "./report/reducer";

const _initialState = {
  user: {},
  hubs: [],
  ktvList: [],
  allKtvList: [],
  sidebar: {
    open: false,
  },
  appbar: {
    open: false,
  },
  error: {
    hasError: false,
    statusCode: "",
    message: "",
  },
  fetching: false,
  allThreePls: [],
  rejectReasons: [],
  notification: {
    open: false,
    text: "",
    type: "info",
    confirmCallback: null,
    options: "single",
    customActionButtons: null,
    closeCallback: null,
  },
  manual: {},
  delivery_types: [],
  sellers: [],
  snackBar: {
    open: false,
    type: "", // success | failed | none
    message: "",
  },
  forbidden: false,
  channels: [],
  settings: {},
  versions: [],
};

const shared = (state = _initialState, action) => {
  switch (action.type) {
    case "OPEN_SHARED_NOTIFICATION_DIALOG":
      return {
        ...state,
        notification: {
          open: true,
          type: action.payload.type,
          text: action.payload.text,
          closeCallback: action.payload.closeCallback
            ? action.payload.closeCallback
            : null,
          confirmCallback: action.payload.confirmCallback
            ? action.payload.confirmCallback
            : null,
          options: action.payload.options ? action.payload.options : "single",
          customActionButtons: action.payload.customActionButtons || null,
        },
      };
    case "CLOSE_NOTIFICATION_DIALOG":
      return {
        ...state,
        notification: {
          ...state.notification,
          open: false,
        },
      };
    case "BEGIN_FETCH":
      // Trigger loading indicator
      return {
        ...state,
        fetching: true,
      };
    case "FETCH_USER_SUCCESS":
      // Tạo 1 object User mới
      return {
        ...state,
        user: new User(action.payload.user),
      };

    case "STOP_LOADING":
      return {
        ...state,
        fetching: false,
      };

    case "TOGGLE_SIDEBAR":
      return {
        ...state,
        sidebar: {
          open: !state.sidebar.open,
        },
      };

    case "FETCH_ERROR":
      // Trigger error dialogs with message
      return {
        ...state,
        error: {
          hasError: true,
          statusCode: action.payload.statusCode,
          message: action.payload.msg,
        },
        fetching: false,
      };

    case "FETCH_SUCCESS":
      return {
        ...state,
        fetching: false,
      };

    case "FETCH_HUB_LIST_SUCCESS":
      // Lưu thông tin hubs
      return {
        ...state,
        hubs: action.payload.hubs.map((hub) => new Hub(hub)),
      };

    case "FETCH_KTV_LIST_SUCCESS":
      // Lưu thông tin ktv trong khu vực quản lý
      return {
        ...state,
        ktvList: action.payload.ktvList,
      };

    case "FETCH_ALL_KTV_LIST_SUCCESS":
      // Lưu thông tin ktv trong khu vực quản lý
      return {
        ...state,
        allKtvList: action.payload.ktvList,
      };
    case "CLOSE_ERROR_DIALOG":
      return {
        ...state,
        error: {
          hasError: false,
          statusCode: "",
          message: "",
        },
      };
    /* Lưu lí do hoàn hàng */
    case "FETCH_REJECT_REASONS_SUCCESS":
      return {
        ...state,
        rejectReasons: action.payload.reasons,
      };
    /* Luu danh sach tat ca 3pls */
    case "FETCH_ALL_3PLS_SUCCESS":
      return {
        ...state,
        allThreePls: action.payload,
      };
    case "FETCH_ALL_DELIVERY_TYPES_SUCCESS":
      return {
        ...state,
        delivery_types: action.payload,
      };
    case "SET_SELLERS":
      return {
        ...state,
        sellers: action.payload,
      };
    case "TOGGLE_SNACKBAR":
      return {
        ...state,
        snackBar: action.payload,
      };
    case "SET_FORBIDDEN":
      return {
        ...state,
        forbidden: action.payload,
      };
    case "SET_CHANNELS":
      return {
        ...state,
        channels: action.payload,
      };
    case "SET_WEB_SETTINGS":
      return {
        ...state,
        settings: action.payload,
      };
    case "FETCH_USER_MANUAL_SUCCESS":
      return {
        ...state,
        manual: action.payload,
      };
    case "SET_WEB_VERSIONS":
      return {
        ...state,
        versions: action.payload,
      };
    default:
      return state;
  }
};

const admin = combineReducers({
  shared: shared,
  list: list,
  detail: detail,
  group: group,
  dashboard: dashboard,
  blManagement,
  busLineManagement,
  ShipmentDetailReport,
  ShipmentSummaryReport,
  ShipDateSummaryReport,
  ShipDateDetailReport,
  ShipperDailyReport,
  ShipperSummaryFuelCostReport,
  ShipperStatusReport,
  Report,
  VehicleReport,
});

export default admin;
