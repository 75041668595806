import { getSellers } from "@api";

export const getSellersList = (params) => async (dispatch) => {
  const response = await getSellers(params);
  if (response.status === 200) {
    const result = response.data;
    dispatch(setSellers(result.data.sellers));
  }
};

const setSellers = (sellers) => ({
  type: "SET_SELLERS",
  payload: sellers,
});
