import { getBls, setBl, fetchError } from "../../shared";

/* ------------ FETCH BL LIST------------------------ */
export const fetchBlList = () => async (dispatch, getState) => {
  const state = getState().admin.blManagement;
  const params = {
    ...state.filter,
    offset: state.pagination.offset,
    limit: state.pagination.limit,
  };
  dispatch(changeLoading(true));
  const response = await getBls(params);
  const result = response.data;
  if (response.status === 200) {
    dispatch(fetchBlListSucces(result.data));
  }
  dispatch(changeLoading(false));
  return response;
};

const fetchBlListSucces = (data) => ({
  type: "BL_MANAGEMENT_FETCH_BL_LIST_SUCCESS",
  payload: data,
});

/* ---------------CHANGE PAGINATION------------------ */
export const changePagination = (pagination) => async (dispatch, getState) => {
  await dispatch(changePaginationData(pagination));
  await dispatch(fetchBlList());
};

const changePaginationData = (pagination) => ({
  type: "BL_MANAGEMENT_CHANGE_PAGINATION",
  payload: pagination,
});

/* ---------------CHANGE EDIT STATE------------------ */
export const changeEditState = (state) => ({
  type: "BL_MANAGEMENT_CHANGE_EDIT_STATE",
  payload: state,
});

/* ----------------UPDATE BL-------------------------  */
export const updateBlState = (id, data) => async (dispatch) => {
  const response = await setBl(id, data);
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  await delay(1000);
  const result = response.data;
  if (response.status !== 200)
    dispatch(fetchError(response.status, result.message));
};

/* ----------------CHANGE FILTER-----------------------  */
export const changeFilter = (filter) => ({
  type: "BL_MANAGEMENT_CHANGE_FILTER",
  payload: filter,
});

/* ----------------CHANGE LOADING STATE------------------  */
export const changeLoading = (state) => ({
  type: "BL_MANAGEMENT_CHANGE_LOADING",
  payload: state,
});
