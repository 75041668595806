import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { LinearProgress, Fab } from "@material-ui/core";
import { SHIPPER_DETAIL_REPORT } from "../../../constants";
import { withRouter } from "react-router-dom";
import { Zoom } from "@material-ui/core";
import { onExcel, viewDetail } from "../actions";

//styles
const styles = (theme) => ({
  "@global": {
    "table > thead > tr > td": {
      padding: "1rem",
      background: "#324B78",
      fontWeight: "bold",
      color: "white",
      border: "1px solid #515177",
    },
    "table > tbody > tr > td:not(:last-child)": {
      borderRight: "1px solid #51517717",
    },
    "table > tbody tr:nth-child(odd) > td": {
      background: "#ecf4fd5c",
    },
    "table > tbody tr:nth-child(even) > td": {
      background: "white",
    },
    ".showIcon": {
      visibility: "hidden",
    },
    ".row:hover .showIcon": {
      visibility: "visible",
    },
  },
});

class ShipDateSummaryReportTable extends React.Component {
  viewDetail = (id) => {
    const { history, viewRowDetail } = this.props;
    history.push("/report/ship-date-detail-report");
    viewRowDetail(id);
  };

  render() {
    const { data, summary, loading, onExcel, classes } = this.props;
    const frequency = summary.total_bl.length;
    return (
      <React.Fragment>
        {loading && <LinearProgress />}
        {data.length > 0 && (
          <Zoom in={true} timeout={600}>
            <Fab color="primary" className="floatingButton" onClick={onExcel}>
              <i style={{ fontSize: 18 }} className="material-icons">
                get_app
              </i>
              <span style={{ fontSize: 14, textTransform: "none" }}>Excel</span>
            </Fab>
          </Zoom>
        )}
        <table>
          <thead>
            <tr>
              <td rowSpan={2}>STT</td>
              <td rowSpan={2}>Mã lí do</td>
              <td rowSpan={2}>Tên lí do</td>
              {new Array(frequency).fill(1).map((x, id) => (
                <td key={id} colSpan={2} align="center">
                  Kỳ {id + 1}
                </td>
              ))}
            </tr>
            <tr>
              {new Array(frequency).fill(1).map((x, id) => (
                <React.Fragment key={id}>
                  <td align="center">Tổng số đơn phải hẹn lại</td>
                  <td align="center">Tỉ trọng theo lí do</td>
                </React.Fragment>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              <React.Fragment>
                {data.map((x, id) => (
                  <tr key={id} className="row">
                    <td>{id + 1}</td>
                    <td>{x.reason_id}</td>
                    <td>
                      {x.reason_name}
                      &nbsp;&nbsp;
                      <i
                        onClick={() => this.viewDetail(x.reason_id)}
                        className="far fa-eye showIcon"
                        style={{ fontSize: 16, color: "cornflowerblue" }}
                      />
                    </td>
                    {x.items.map((k, idx) => (
                      <React.Fragment key={idx}>
                        <td align="center">{k.total}</td>
                        <td align="center">{k.percent}%</td>
                      </React.Fragment>
                    ))}
                  </tr>
                ))}
              </React.Fragment>
            ) : (
              <tr>
                <td colSpan={SHIPPER_DETAIL_REPORT.length}>
                  Không thể tìm thấy dữ liệu bạn yêu cầu!
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  filter: state.admin.ShipDateSummaryReport.filter,
  data: state.admin.ShipDateSummaryReport.data,
  summary: state.admin.ShipDateSummaryReport.summary,
});

const mapDispatchToProps = (dispatch) => ({
  onExcel: () => dispatch(onExcel()),
  viewRowDetail: (reason_id) => dispatch(viewDetail(reason_id)),
});

export default withRouter(
  withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(ShipDateSummaryReportTable)
  )
);
