import React from "react";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";
import classnames from "classnames";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { formatNumber } from "../../../utils/misc";

const styles = (theme) => ({
  productHeading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  subTitle: {
    fontSize: 14,
    fontFamily: "Source Sans Pro, sans-serif",
    textTransform: "uppercase",
    fontWeight: 600,
    letterSpacing: 1,
    color: "#f05050",
  },
  table: {
    marginBottom: 0,
  },
  text: {
    fontFamily: "Noto Sans, sans-serif",
  },
  suggestPriceText: {
    fontFamily: "Noto Sans, sans-serif",
    color: "#0e34ff",
  },
  cell: {
    color: "#797979",
    fontWeight: 500,
    textAlign: "center",
  },
  nameCell: {
    color: "#797979",
    fontWeight: 500,
  },
  priceCell: {
    color: "#797979",
    fontWeight: 500,
    textAlign: "right",
  },
  codTitle: {
    textAlign: "left",
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
  totalTable: {
    width: "35%",
    float: "right",
  },
  totalRow: {
    height: "2.5rem",
  },
  noteText: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: "13px",
    fontWeight: "bold",
    color: "indianred",
  },
  redHightLight: {
    background: "rgba(255,221,221,0.5)",
  },
  entered: {
    overflow: "auto !important",
  },
  "@global": {
    "#blLines::-webkit-scrollbar-track": {
      borderRadius: 10,
      backgroundColor: "#F5F5F5",
    },
    "#blLines::-webkit-scrollbar": {
      width: 7,
      height: 7,
      backgroundColor: "#F5F5F5",
    },
    "#blLines::-webkit-scrollbar-thumb": {
      borderRadius: 10,
      backgroundColor: "#5555556b",
    },
  },
  newCOD: {
    color: "indianred",
  },
});

class BlLines extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
    };
  }

  collapsePaper = () =>
    this.setState({
      collapse: !this.state.collapse,
    });

  renderQuantity = (pr) => {
    const { bl, blLines, classes } = this.props;
    const cancelState = bl.last_om_cancel_request;
    const returnItems =
      cancelState && cancelState.returned_items
        ? cancelState.returned_items
        : [];
    let uniqueKey = blLines && blLines[0].line_item_id ? "line_item_id" : "sku";
    const returnItemUniqueKey = returnItems.map((x) => x[uniqueKey]);
    if (returnItemUniqueKey.includes(pr[uniqueKey])) {
      const returnQuantity = returnItems.find(
        (x) => x[uniqueKey] === pr[uniqueKey]
      );
      const newQuantity = pr.quantity - returnQuantity.quantity;
      return (
        <span>
          <span className={classes.newCOD}>{newQuantity}</span>
        </span>
      );
    } else {
      return <span>{pr.quantity}</span>;
    }
  };

  renderCOD = () => {
    const { cod, bl, classes } = this.props;
    const cancelState = bl.last_om_cancel_request;
    if (cancelState.action === "partial") {
      return (
        <React.Fragment>
          <TableRow className={classes.redHightLight}>
            <TableCell className={classes.codTitle}>Tổng cộng cũ</TableCell>
            <TableCell className={classes.priceCell}>
              {cod.grandTotal}
            </TableCell>
            <TableCell className={classes.codTitle}>Tổng cộng mới</TableCell>
            <TableCell className={`${classes.newCOD} ${classes.priceCell}`}>
              {formatNumber(cancelState.new_grand_total)}
            </TableCell>
          </TableRow>
          <TableRow className={classes.redHightLight}>
            <TableCell className={classes.codTitle}>COD cũ</TableCell>
            <TableCell className={classes.priceCell}>{cod.cod}</TableCell>
            <TableCell className={classes.codTitle}>COD mới</TableCell>
            <TableCell className={`${classes.newCOD} ${classes.priceCell}`}>
              {formatNumber(cancelState.new_cod)}
            </TableCell>
          </TableRow>
        </React.Fragment>
      );
    } else {
      return (
        <TableRow>
          <TableCell className={classes.codTitle}>Tổng cộng</TableCell>
          <TableCell>{cod.grandTotal}</TableCell>
          <TableCell className={classes.codTitle}>COD</TableCell>
          <TableCell>{cod.cod}</TableCell>
        </TableRow>
      );
    }
  };
  render() {
    const { collapse } = this.state;
    const { blLines, classes, cod, requestType, bl } = this.props;
    const cancelState = bl.last_om_cancel_request;
    const isPartial = cancelState.action === "partial";
    const uniqueItemKey =
      blLines && blLines.length > 0 && blLines[0].line_item_id
        ? "line_item_id"
        : "sku";
    const uniqueKeyList = isPartial
      ? cancelState.returned_items.map((x) => x[uniqueItemKey])
      : [];

    return (
      <React.Fragment>
        <div className={classes.productHeading} onClick={this.collapsePaper}>
          <Typography type="title" className={classes.subTitle}>
            Danh sách sản phẩm
          </Typography>
          <IconButton
            aria-label="Xem thêm"
            className={classnames(classes.expand, {
              [classes.expandOpen]: collapse,
            })}
          >
            <ExpandMoreIcon />
          </IconButton>
        </div>
        <Collapse
          in={!collapse}
          unmountOnExit
          id="blLines"
          classes={{ entered: classes.entered }}
        >
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell style={{ textAlign: "center" }}>STT</TableCell>
                <TableCell style={{ textAlign: "center" }}>SKU</TableCell>
                <TableCell style={{ textAlign: "center" }}>Mã nhóm</TableCell>
                <TableCell>Tên sản phẩm</TableCell>
                <TableCell style={{ textAlign: "right" }}>
                  Đơn giá(VNĐ)
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  {isPartial ? `Số lượng cũ` : `Số lượng`}
                </TableCell>
                {isPartial && (
                  <TableCell style={{ textAlign: "center" }}>
                    Số lượng mới
                  </TableCell>
                )}
                <TableCell style={{ textAlign: "center" }}>
                  Số lượng đã giao
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {blLines && blLines.length
                ? blLines.map((pr, idx) => (
                    <TableRow
                      key={idx}
                      className={
                        uniqueKeyList.includes(pr[uniqueItemKey])
                          ? classes.redHightLight
                          : ""
                      }
                    >
                      <TableCell className={classes.cell}>{idx + 1}</TableCell>
                      <TableCell className={classes.cell}>{pr.sku}</TableCell>
                      <TableCell className={classes.cell}>
                        {pr.category_code || ""}
                      </TableCell>
                      <TableCell className={classes.nameCell}>
                        {pr.name}
                      </TableCell>
                      <TableCell className={classes.priceCell}>
                        {formatNumber(pr.price)}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {pr.quantity}
                      </TableCell>
                      {isPartial && (
                        <TableCell className={classes.cell}>
                          {this.renderQuantity(pr)}
                        </TableCell>
                      )}
                      <TableCell className={classes.cell}>
                        {pr.success_quantity}
                      </TableCell>
                    </TableRow>
                  ))
                : ""}
            </TableBody>
          </Table>
          {requestType !== "WH_TRANSFER" && (
            <Table className={classes.totalTable}>
              <TableBody>
                {this.renderCOD()}
                <TableRow className={classes.totalRow}>
                  <TableCell className={classes.codTitle}>Tiền cọc:</TableCell>
                  <TableCell className={classes.priceCell}>
                    {cod.prePaid}
                  </TableCell>
                  <TableCell className={classes.codTitle}>Paid COD</TableCell>
                  <TableCell className={classes.priceCell}>
                    {cod.paidCOD}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
          <br />
          <br />
          {isPartial && (
            <div className={classes.noteText}>
              * Ghi chú: Những sản phẩm được tô đỏ là sản phẩm được yêu cầu hoàn
            </div>
          )}
        </Collapse>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(BlLines);
