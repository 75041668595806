import React, { useEffect, useState } from "react";
import Select from "react-select";
import { compose } from "recompose";
import { connect } from "react-redux";
import _ from "lodash";

const formatSellerList = (sellers) => {
  return sellers
    .filter((x) => x.status === 1)
    .map((x) => ({
      label: x.name,
      value: x.id.toString(),
    }));
};

const SellerSelection = (props) => {
  const { sellers, onChange } = props;
  const [sellerList, setSellerList] = useState([]);

  useEffect(() => {
    const userSellerList = formatSellerList(sellers);
    setSellerList(userSellerList);
  }, [sellers]);

  useEffect(() => {
    if (sellerList.length === 1) {
      onChange(sellerList[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerList]);

  return (
    <Select
      options={sellerList}
      multi={sellerList.length > 1}
      simpleValue={true}
      placeholder="Chọn công ty..."
      clearable={sellerList.length > 1}
      {...props}
    />
  );
};

const mapStateToProps = (state) => ({
  sellers: state.admin.shared.sellers,
});

export default compose(connect(mapStateToProps))(SellerSelection);
