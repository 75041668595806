import React from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const styles = (theme) => ({
  subtitle: {
    fontSize: 14,
    padding: "8px 0 16px 0",
    fontFamily: "Source Sans Pro, sans-serif",
    textTransform: "uppercase",
    fontWeight: 600,
    letterSpacing: 1,
    color: "#5d9cec",
  },
  rowDetail: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 14,
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
  },
  row: {
    paddingBottom: 14,
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
  },
});

const BlToInfo = ({ address, requestType, classes }) => (
  <div>
    <Typography type="subheading" className={classes.subtitle}>
      Thông tin{" "}
      {requestType === "WH_TRANSFER" ? "kho nhận" : requestType && "khách hàng"}
    </Typography>
    <div>
      {requestType === "WH_TRANSFER" ? (
        <div>
          <Grid container>
            <Grid item md={6}>
              <span className={classes.rowDetail}>
                <strong>Kho nhận:</strong>
                <span>&nbsp;&nbsp;{address.displayName}</span>
              </span>
              <span className={classes.rowDetail}>
                <strong>Số điện thoại:</strong>
                <span>&nbsp;&nbsp;{address.phone}</span>
              </span>
            </Grid>
            <Grid item md={6}>
              <span className={classes.rowDetail}>
                <strong>Người liên hệ:</strong>
                <span>&nbsp;&nbsp;{address.name}</span>
              </span>
              <span className={classes.rowDetail}>
                <strong>Email:</strong>
                <span>
                  &nbsp;&nbsp;{address.email ? address.email : "Không có"}
                </span>
              </span>
            </Grid>
          </Grid>
          <div>
            <strong className={classes.row}>Địa chỉ:</strong>
            <span className={classes.row}>
              <span style={{ whiteSpace: "wrap" }}>
                &nbsp;&nbsp;{address.fullAddress}
              </span>
            </span>
          </div>
        </div>
      ) : (
        <Grid container>
          <Grid item md={6}>
            <div className={classes.row}>
              <strong>Người nhận:</strong>
              <span>&nbsp;&nbsp;{address.displayName}</span>
            </div>
            <div className={classes.row}>
              <strong>Địa chỉ:</strong>
              <span>&nbsp;&nbsp;{address.fullAddress}</span>
            </div>
          </Grid>
          <Grid item md={6}>
            <span className={classes.rowDetail}>
              <strong>Số điện thoại:</strong>
              <span>&nbsp;&nbsp;{address.phone}</span>
            </span>
            <span className={classes.rowDetail}>
              <strong>Email:</strong>
              <span>
                &nbsp;&nbsp;{address.email ? address.email : "Không có"}
              </span>
            </span>
          </Grid>
        </Grid>
      )}
    </div>
    <br />
  </div>
);

export default withStyles(styles)(BlToInfo);
