import React from "react";
import { withStyles } from "@material-ui/core/styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const styles = (theme) => ({
  "@global": {
    ".react-datepicker-wrapper": {
      display: "flex",
      alignItems: "center",
      fontSize: "14px",
    },
    ".react-datepicker__input-container": {
      width: "55px",
      margin: "0px 5px",
    },
    ".react-datepicker__input-container input": {
      border: "1px solid #ccc",
      height: "35px",
      maxWidth: "95%",
      paddingLeft: "8px",
      borderRadius: "4px",
      fontSize: "14px",
    },
    ".react-datepicker__input-container input::placeholder": {
      fontSize: 11,
    },
    ".react-datepicker__close-icon::after": {
      backgroundColor: "transparent",
      color: "#999",
      fontSize: "16px",
    },
    ".react-datepicker__close-icon:hover::after": {
      color: "red",
    },
    ".react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item":
      {
        height: "20px",
      },
    ".react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover":
      {
        backgroundColor: "#aacbff52",
      },
    ".react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled":
      {
        display: "none",
      },
    ".react-datepicker-time__header": {
      fontSize: "14px",
    },
    ".react-datepicker__header": {
      display: "none",
    },
    ".react-datepicker__close-icon": {
      right: "-14px",
    },
  },
});

class TimeSelection extends React.Component {
  render() {
    return (
      <React.Fragment>
        <DatePicker
          selected={this.props.selected}
          onChange={(date) => this.props.onChange(date)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          dateFormat="HH:mm"
          timeFormat="HH:mm"
          {...this.props}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(TimeSelection);
