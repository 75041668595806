import React, { useState, useEffect } from "react";
import Select from "react-virtualized-select";

function AreaFilter({
  value,
  options,
  onChange,
  multi = true,
  placeholder = "Chọn khu vực...",
}) {
  const [formattedOptions, setFormattedOptions] = useState([]);
  const ref = React.createRef();
  useEffect(() => {
    const formatted = options.map((x) => ({
      label: x.text,
      value: x.code,
    }));
    setFormattedOptions(formatted);
  }, [options]);

  return (
    <Select
      value={value}
      ref={ref}
      onChange={onChange}
      options={formattedOptions}
      simpleValue
      placeholder={placeholder}
      wrapperStyle={{ width: "100%" }}
      noResultsText="Không tìm thấy"
      multi={multi}
      optionHeight={() => 40}
    />
  );
}

export default AreaFilter;
