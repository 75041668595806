import React from "react";
import { withStyles } from "@material-ui/core/styles";

import { mapBlStatusColor, mapBlStatusText } from "../../../utils";

const styles = {
  statusLabel: {
    color: "#fff",
    fontSize: 11,
    fontWeight: 800,
    padding: 4,
    borderRadius: 4,
  },
  inverseStatusLabel: {
    fontSize: 11,
    fontWeight: 800,
    padding: 4,
    borderRadius: 4,
    color: "#797979",
  },
};

const BlGroupStatusCell = ({ status, classes }) => {
  return (
    <span
      className={
        ["waiting_for_confirm", "returned"].includes(status)
          ? classes.inverseStatusLabel
          : classes.statusLabel
      }
      style={
        status !== "waiting_for_confirm"
          ? status === "returned"
            ? { backgroundColor: mapBlStatusColor(status), color: "#fff" }
            : { backgroundColor: mapBlStatusColor(status) }
          : null
      }
    >
      <a href="#">{mapBlStatusText(status)}</a>
    </span>
  );
};

export default withStyles(styles)(BlGroupStatusCell);
