import React from "react";

import { ShipMethod } from "../../../../../models";

class PlRefInput extends React.Component {
  componentDidMount() {
    const { shipMethod, toHub, plRef } = this.props;
    this.generateVTPostManualPlRef(shipMethod, toHub, plRef);
  }

  UNSAFE_componentWillReceiveProps(nextProps, prevProps) {
    if (
      this.props.shipMethod === nextProps.shipMethod && //if user change plref manually
      this.props.toHub === nextProps.toHub &&
      (nextProps.plRef || (nextProps.plRef === "" && this.props.plRef))
    ) {
      //pass
    } else {
      //if clear selected hub
      if (this.props.toHub.id && !nextProps.toHub.id) {
        this.props.changePlRef("");
      } else {
        // if selected hub and vtpost manual
        this.generateVTPostManualPlRef(
          nextProps.shipMethod,
          nextProps.toHub,
          nextProps.plRef
        );
      }
    }
  }

  generateVTPostManualPlRef = (shipMethod, toHub, plRef) => {
    if (shipMethod === ShipMethod.vtpost_manual && toHub.id) {
      const d = new Date();
      const today = ("0" + d.getDate()).slice(-2);
      const month = ("0" + (d.getMonth() + 1)).slice(-2);
      const year = d.getFullYear().toString().slice(-2);
      const genedCode = today + month + year + "PV" + toHub.id;

      if (plRef !== genedCode) {
        this.props.changePlRef(genedCode);
      }
    }
  };

  render() {
    const { plRef, shipMethod } = this.props;
    return (
      <input
        type="text"
        value={plRef ? plRef : ""}
        placeholder={
          shipMethod === ShipMethod.nv_phongvu
            ? "Mã nhân viên"
            : "Mã vận chuyển"
        }
        onChange={(e) => this.props.changePlRef(e.target.value)}
        style={{
          height: 34,
          border: "1px solid #ccc",
          borderRadius: 4,
          paddingLeft: 8,
        }}
      />
    );
  }
}

export default PlRefInput;
