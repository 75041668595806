import moment from "moment";
import { getShipperDailyFuelCostReport } from "@api";

export const setDefaultUser = (userId) => ({
  type: "SET_DEFAULT_USER",
  payload: userId,
});

export const changeFilter = (data) => ({
  type: "SHIPPER_DAILY_REPORT_CHANGE_FILTER",
  payload: data,
});

export const switchRenderLocation = (flag) => ({
  type: "SWITCH_RENDER_LOCATION",
  payload: flag,
});

const wait = (ms) => new Promise((rs, rj) => setTimeout(rs, ms));

export const onSearch = () => async (dispatch, getState) => {
  dispatch(changeLoading(true));
  const state = getState().admin.ShipperDailyReport;
  const params = {
    shipper_id: state.filter.shipper_id ? state.filter.shipper_id.value : "",
    from_date: moment(state.filter.from_date).format("YYYY-MM-DD"),
    to_date: moment(state.filter.to_date).format("YYYY-MM-DD"),
  };
  const response = await getShipperDailyFuelCostReport(params);
  await wait(1000);
  dispatch(changeLoading(false));
  if (response.status === 200) {
    const result = response.data;
    dispatch(setData(result.data));
    // set location
    const items = response.data.data.items;
    const locations = items.filter((x) =>
      Boolean(x.location && x.location.lat)
    );
    if (locations.length > 0) {
      dispatch(setMap({ center: locations[0].location }));
    } else {
      setDefaultCenter();
    }
  }
};

export const changeLoading = (state) => ({
  type: "SHIPPER_DAILY_REPORT_CHANGE_LOADING",
  payload: state,
});

export const setData = (data) => ({
  type: "SHIPPER_DAILY_REPORT_SET_DATA",
  payload: data,
});

export const setMap = (config) => ({
  type: "SHIPPER_DAILY_REPORT_SET_MAP",
  payload: config,
});

function getCurrentLocation() {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, {
      timeout: 5000,
    });
  });
}

export const setDefaultCenter = () => async (dispatch, getState) => {
  let defaultCenter = { lat: 0, lng: 0 };
  if (navigator.geolocation) {
    const pos = await getCurrentLocation();
    if (pos && pos.coords && pos.coords.latitude)
      defaultCenter = {
        lat: pos.coords.latitude,
        lng: pos.coords.longitude,
      };
  }
  dispatch(setMap({ center: defaultCenter }));
};
