import moment from "moment";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";

export const toTimeKeepingFormat = (ktv, type) => {
  switch (type) {
    case "in":
      if (ktv.timekeeping_date_in) {
        return moment(ktv.timekeeping_date_in).format("HH:mm:ss");
      }
      if (ktv.check_in_hour) {
        return ktv.check_in_hour;
      }
      break;
    case "out":
      if (ktv.timekeeping_date_out) {
        return moment(ktv.timekeeping_date_out).format("HH:mm:ss");
      }
      if (ktv.check_out_hour) {
        return ktv.check_out_hour;
      }
      break;
    default:
      return undefined;
  }
};

export const getTimeKeeping = (type, ktv) => {
  switch (type) {
    case "time_in_min":
      return setHours(setMinutes(new Date(), 0), 6);
    case "time_in_max":
      return ktv.timekeeping_date_out
        ? setHours(
            setMinutes(new Date(), ktv.timekeeping_date_out.getMinutes()),
            ktv.timekeeping_date_out.getHours()
          )
        : ktv.timekeeping_date && ktv.check_out_hour
        ? new Date(ktv.timekeeping_date + " " + ktv.check_out_hour)
        : setHours(setMinutes(new Date(), 0), 22);
    case "time_out_min":
      return ktv.timekeeping_date_in
        ? setHours(
            setMinutes(new Date(), ktv.timekeeping_date_in.getMinutes()),
            ktv.timekeeping_date_in.getHours()
          )
        : ktv.timekeeping_date && ktv.check_in_hour
        ? new Date(ktv.timekeeping_date + " " + ktv.check_in_hour)
        : setHours(setMinutes(new Date(), 0), 6);
    case "time_out_max":
      return setHours(setMinutes(new Date(), 0), 22);
    case "time_in_select":
      return ktv.timekeeping_date_in
        ? new Date(ktv.timekeeping_date_in)
        : ktv.timekeeping_date && ktv.check_in_hour
        ? new Date(ktv.timekeeping_date + " " + ktv.check_in_hour)
        : null;
    case "time_out_select":
      return ktv.timekeeping_date_out
        ? new Date(ktv.timekeeping_date_out)
        : ktv.timekeeping_date && ktv.check_out_hour
        ? new Date(ktv.timekeeping_date + " " + ktv.check_out_hour)
        : null;
    default:
      return null;
  }
};
