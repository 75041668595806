import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import * as actions from "../actions";
import moment from "moment";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
  },
  picker: {
    height: 32,
    borderRadius: "0.2rem",
    paddingLeft: 10,
    flex: 1,
    background: "rgb(241,243,244)",
  },
  item: {
    margin: "1rem",
    lineHeight: "2rem",
    flex: 1,
  },
  blueButton: {
    fontFamily: "sans-serif",
    padding: "5px 8px",
    color: "#FFFFFF",
    background: "#5f69e1d9",
    "&:hover": {
      background: "#100cf647",
    },
  },
});

class BlStatusFilter extends React.Component {
  constructor() {
    super();
    this.state = {
      dateSelection: "custom",
    };
  }
  handleChange = (e) => {
    const { changeBlStatusFilter, searchBlStatus } = this.props;
    this.setState({ dateSelection: e.target.value });
    switch (e.target.value) {
      case "week1":
        changeBlStatusFilter({
          start_date: moment().subtract(7, "days"),
          end_date: new Date(),
        });
        break;
      case "month1":
        changeBlStatusFilter({
          start_date: moment().subtract(1, "months"),
          end_date: new Date(),
        });
        break;
      case "month2":
        changeBlStatusFilter({
          start_date: moment().subtract(2, "months"),
          end_date: new Date(),
        });
        break;
    }
    if (e.target.value !== "custom") {
      searchBlStatus();
    }
  };

  render() {
    const { filter, changeBlStatusFilter, searchBlStatus, loading, classes } =
      this.props;
    return (
      <div className={classes.container}>
        <div className={classes.item}>
          <h3>Bộ lọc </h3>
          Từ ngày
          <span className="pickers">
            <DatePicker
              value={filter.start_date}
              className={classes.picker}
              disableFuture
              labelFunc={(date, invalidLabel) =>
                date.isValid() ? date.format("DD/MM/YYYY") : invalidLabel
              }
              maxDate={new Date()}
              onChange={(e) =>
                changeBlStatusFilter({
                  start_date: moment(e).format("YYYY-MM-DD"),
                })
              }
            />
          </span>
          Đến ngày
          <span className="pickers">
            <DatePicker
              value={filter.end_date}
              className={classes.picker}
              labelFunc={(date, invalidLabel) =>
                date.isValid() ? date.format("DD/MM/YYYY") : invalidLabel
              }
              onChange={(e) =>
                changeBlStatusFilter({
                  end_date: moment(e).format("YYYY-MM-DD"),
                })
              }
            />
          </span>
          <div style={{ marginTop: "0.5rem", marginBottom: "1rem" }}>
            <Button
              className={classes.blueButton}
              onClick={() => searchBlStatus()}
            >
              {loading ? (
                <CircularProgress size={30} color="inherit" />
              ) : (
                <i className="material-icons">search</i>
              )}
              &nbsp;Tìm kiếm
            </Button>
          </div>
        </div>
        <div
          className={classes.item}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginLeft: "2rem",
          }}
        >
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel>Hoặc chọn</FormLabel>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              className={classes.group}
              value={this.state.dateSelection}
              onChange={this.handleChange}
            >
              <FormControlLabel
                value="week1"
                control={<Radio />}
                label="Cách đây 1 tuần"
              />
              <FormControlLabel
                value="month1"
                control={<Radio />}
                label="Cách đây 1 tháng"
              />
              <FormControlLabel
                value="month2"
                control={<Radio />}
                label="Cách đây 2 tháng"
              />
              <FormControlLabel
                value="custom"
                control={<Radio />}
                label="Tùy chọn"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  filter: state.admin.dashboard.blStatusFilter,
  loading: state.admin.dashboard.blStatusLoading,
});

const mapDispatchToProps = (dispatch) => ({
  changeBlStatusFilter: (filter) =>
    dispatch(actions.changeBlStatusFilter(filter)),
  searchBlStatus: () => dispatch(actions.getStatistic()),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(BlStatusFilter)
);
