import React from "react";
import { FormControl, FormControlLabel } from "@material-ui/core";
import { Radio, RadioGroup } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { isMaxTrustUser } from "@utils";
import {
  BlDirection,
  RequestType,
  ShipMethod,
  ShipType,
} from "../../../../../models";

const styles = (theme) => ({
  radio: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 24,
    flexWrap: "nowrap",
  },
  label: {
    fontSize: 14,
  },
});

class ShipTypeSelection extends React.Component {
  renderShipTypeForWhTransfer = () => (
    <RadioGroup
      aria-label="destination"
      name="destination"
      value={this.props.shipType}
      className={this.props.classes.radio}
      onChange={(e, v) => this.props.changeShipType(v)}
    >
      <FormControlLabel
        value={
          this.props.bl.holderType === "hub"
            ? ShipType.hub_customer
            : ShipType.customer_customer
        }
        control={<Radio color="primary" />}
        label={<span className={this.props.classes.label}>Kho nhận</span>}
      />
      <FormControlLabel
        value={
          this.props.bl.holderType === "hub"
            ? ShipType.hub_hub
            : ShipType.customer_hub
        }
        control={<Radio color="primary" />}
        label={
          <span className={this.props.classes.label}>Kho trung chuyển</span>
        }
      />
    </RadioGroup>
  );

  renderShipTypeForReturning = () => (
    <RadioGroup
      aria-label="destination"
      name="destination"
      value={this.props.shipType}
      className={this.props.classes.radio}
      onChange={(e, v) => this.props.changeShipType(v)}
    >
      <FormControlLabel
        value={ShipType.hub_hub}
        control={<Radio color="primary" />}
        label={<span className={this.props.classes.label}>Kho khác</span>}
      />
    </RadioGroup>
  );

  renderShipTypeForKtv = () => (
    <RadioGroup
      aria-label="destination"
      name="destination"
      value={this.props.shipType}
      className={this.props.classes.radio}
      onChange={(e, v) => this.props.changeShipType(v)}
    >
      <FormControlLabel
        value={ShipType.hub_customer}
        control={<Radio color="primary" />}
        label={<span className={this.props.classes.label}>Khách hàng</span>}
      />
    </RadioGroup>
  );

  renderShipTypeFor3Pls = () => {
    const { bl, shipMethod, shipType, classes } = this.props;
    const isMaxTrust = isMaxTrustUser();

    if (bl.cod !== 0 && ["xe_tai", "xe_khach"].includes(shipMethod)) {
      return (
        <RadioGroup
          aria-label="destination"
          name="destination"
          value={shipType}
          className={classes.radio}
          onChange={(_, v) => this.props.changeShipType(v)}
        >
          {isMaxTrust ? (
            <FormControlLabel
              value={ShipType.hub_customer}
              control={<Radio color="primary" />}
              label={
                <span className={this.props.classes.label}>Khách hàng</span>
              }
            />
          ) : (
            <FormControlLabel
              value={ShipType.hub_ktv}
              control={<Radio color="primary" />}
              label={<span className={this.props.classes.label}>KTV</span>}
            />
          )}

          <FormControlLabel
            value={ShipType.hub_hub}
            control={<Radio color="primary" />}
            label={
              bl.exportSource.toUpperCase() === "WMS" ? (
                <span className={this.props.classes.label}>
                  Kho trung chuyển
                </span>
              ) : (
                <span className={this.props.classes.label}>Kho khác</span>
              )
            }
          />
        </RadioGroup>
      );
    } else {
      return (
        <RadioGroup
          aria-label="destination"
          name="destination"
          value={shipType}
          className={classes.radio}
          onChange={(e, v) => this.props.changeShipType(v)}
        >
          <FormControlLabel
            value={ShipType.hub_customer}
            control={<Radio color="primary" />}
            label={<span className={this.props.classes.label}>Khách hàng</span>}
          />
          {!isMaxTrust && (
            <FormControlLabel
              value={ShipType.hub_ktv}
              control={<Radio color="primary" />}
              label={<span className={this.props.classes.label}>KTV</span>}
            />
          )}
          <FormControlLabel
            value={ShipType.hub_hub}
            control={<Radio color="primary" />}
            label={
              bl.exportSource.toUpperCase() === "WMS" ? (
                <span className={this.props.classes.label}>
                  Kho trung chuyển
                </span>
              ) : (
                <span className={this.props.classes.label}>Kho khác</span>
              )
            }
          />
        </RadioGroup>
      );
    }
  };
  render() {
    let { bl, shipMethod } = this.props;
    return (
      <FormControl component="fieldset" fullWidth={true}>
        {shipMethod === ShipMethod.ktv
          ? bl.requestType === RequestType.wh_transfer.toUpperCase()
            ? this.renderShipTypeForWhTransfer()
            : this.renderShipTypeForKtv()
          : bl.requestType === RequestType.wh_transfer.toUpperCase()
          ? this.renderShipTypeForWhTransfer()
          : bl.direction === BlDirection.returning
          ? this.renderShipTypeForReturning()
          : this.renderShipTypeFor3Pls()}
      </FormControl>
    );
  }
}
export default withStyles(styles)(ShipTypeSelection);
