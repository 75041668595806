import React from "react";
import { mapShipMethodToText } from "@utils";

const ShipMethodCell = ({ shipMethod, plRef, shipper, transport }) => {
  const renderTransportDetail = () => {
    switch (shipMethod) {
      case "ktv":
        return (
          <span>
            {shipper.asiaId
              ? `${shipper.asiaId.toString().padStart(4, 0)} -`
              : ""}{" "}
            {shipper.name}
          </span>
        );
      default:
        return transport.check_status_3pl_url ? (
          <span>
            <a
              href={transport.check_status_3pl_url}
              target="_blank"
              rel="noreferrer"
            >
              {plRef}
            </a>
          </span>
        ) : (
          <span>{plRef}</span>
        );
    }
  };

  const renderTransportShipMethod = () => {
    const shipMethodLabel = mapShipMethodToText(shipMethod);
    switch (shipMethod) {
      case "xe_khach":
        return (
          <span>
            Xe Khách{" "}
            {transport.bus_line && transport.bus_line.id
              ? `- ${transport.bus_line.name}`
              : ""}
          </span>
        );
      default:
        return <span>{shipMethodLabel}</span>;
    }
  };

  return (
    <React.Fragment>
      <div>{renderTransportShipMethod()}</div>
      <div>{renderTransportDetail()}</div>
    </React.Fragment>
  );
};

export default ShipMethodCell;
