import apiService from "./base";

export const dumpBlApi = "";

export const getServiceList = async (id, data) => {
  return apiService({
    url: `/admin/bls/${id}/service_by_ship_method`,
    params: data,
  });
};

export const getBlBusLines = async (id, data) => {
  return apiService({
    url: `/admin/bls/${id}/bus_lines`,
  });
};

export const getBls = async (data) => {
  return apiService({
    url: `/admin/bls`,
    params: data,
  });
};

export const setBl = async (id, data) => {
  return apiService({
    url: `/admin/bls/${id}`,
    method: "post",
    params: data,
  });
  // return await axios.post(`/admin/bls/${id}`, null, { params: data });
};

export const getCreateTransportSuggestions = async (id) => {
  return apiService({
    url: `/admin/suggestions`,
    params: { bl_id: id },
  });
};

export const changeBlShipDate = async (id, data) => {
  return apiService({
    url: `/admin/bls/${id}/change_ship_date`,
    method: "post",
    data: data,
  });
};

export const changeBlDeliveryTypes = async (id, data) => {
  return apiService({
    url: `/admin/bls/${id}/delivery_type`,
    method: "post",
    data: data,
  });
};

export const updateCustomerAddress = async (id, data) => {
  return apiService({
    url: `admin/bls/${id}/update_receiver_address`,
    method: "post",
    data: data,
  });
};

export const getBlHistory = async (id) => {
  return apiService({
    url: `/admin/bls/${id}/history`,
  });
};

export const updateWmsStatusApi = async (id, status) => {
  return apiService({
    url: `/admin/bl/${id}/wms_status`,
    method: "post",
    data: { wms_status: status },
  });
};

export const getBlDetailApi = async (id, params) => {
  return apiService({
    url: `/admin/bls/${id}`,
    method: "get",
    params: params,
  });
};

export const cancelBlApi = async (bl_id, reason) => {
  return apiService({
    url: `/admin/bls/${bl_id}/cancel`,
    method: "post",
    data: { reason: reason },
  });
};

export const changeBlFollowerApi = async (bl_id, ktv_id) => {
  return apiService({
    url: `/admin/bls/${bl_id}/follower`,
    method: "post",
    params: { ktv_id: ktv_id },
  });
};

export const changeBlInvoiceIdApi = async (bl_id, invoice) => {
  return apiService({
    url: `/admin/bl/${bl_id}/invoice`,
    method: "post",
    data: { invoice_id: invoice },
  });
};

export const getShipperListByBlApi = async (bl_id, hub_id) => {
  return apiService({
    url: `/admin/bls/${bl_id}/shipper_list`,
    method: "get",
    params: { hub_id: hub_id },
  });
};

export const updateBlCoordinatorNoteApi = async (bl_id, note) => {
  return apiService({
    url: `/admin/bl/${bl_id}/coordinator_note`,
    method: "post",
    data: { note: note },
  });
};

export const addBlCustomerAddressApi = async (bl_id, area_code) => {
  return apiService({
    url: `/admin/bls/${bl_id}/to_addr_area_code`,
    method: "post",
    data: { area_code: area_code },
  });
};

export const stockRequestApi = (bl_id) => {
  return apiService({
    url: `/admin/bl/${bl_id}/stock_request`,
    method: "post",
  });
};

export const filterForGroupingBlsApi = (params) => {
  return apiService({
    url: "/admin/bls/filter_for_grouping",
    method: "get",
    params: params,
  });
};
