import _ from "lodash";
import { getVehicleReport } from "@shared";
import qs from "qs";
import moment from "moment";
import { API_URL } from "@config";

export const changeFilter = (data) => ({
  type: "VEHICLE_REPORT_CHANGE_FILTER",
  payload: data,
});

export const changePagination = (pagination) => ({
  type: "VEHICLE_REPORT_CHANGE_PAGINATION",
  payload: pagination,
});

export const onSearch = () => async (dispatch, getState) => {
  dispatch(changeLoading(true));
  const state = getState().admin.VehicleReport;
  const params = {
    ...state.filter,
  };
  params.start_date = moment(params.start_date).format("YYYY-MM-DD");
  const response = await getVehicleReport(params);
  dispatch(changeLoading(false));
  if (response.status === 200) {
    const result = response.data;
    dispatch(setData(result.data));
  }
};

export const changeLoading = (state) => ({
  type: "VEHICLE_REPORT_CHANGE_LOADING",
  payload: state,
});

export const setData = (data) => ({
  type: "VEHICLE_REPORT_SET_DATA",
  payload: data,
});

export const onExcel = () => async (dispatch, getState) => {
  const state = getState().admin.VehicleReport;
  const params = {
    ...state.filter,
  };
  params.start_date = moment(params.start_date).format("YYYY-MM-DD");
  delete params.limit;
  window.location.href = `${API_URL}/admin/report/vehicles/excel?${qs.stringify(
    params
  )}`;
};
