import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid, Paper } from "@material-ui/core";
import DatePicker from "react-date-picker";
import HubFilter from "../../../bl_list/components/filters/hub_filter/HubFilter";
import {
  VehicleTypeSelection,
  ReportRange,
  BookmarkBar,
  SellerSelection,
  NumberSelection,
} from "@shared";
import { getReportCycleRange } from "../../../utils";
import { getUserSavedFilter } from "@api";
import moment from "moment";

//styles
const styles = (theme) => ({
  "@global": {
    ".react-date-picker__wrapper": {
      border: "1px solid #80808054",
      padding: "0.3rem 0.4rem",
      width: "100%",
      borderRadius: 5,
    },
    ".dateRange": {
      borderRadius: 5,
      width: "100%",
    },
    ".multiselect .Select-multi-value-wrapper": {
      maxHeight: "5rem",
      overflowY: "scroll",
      display: "block",
    },
    ".react-date-picker .react-date-picker__wrapper input[name='year']": {
      flex: 1,
    },
  },
});

class VehicleReportTable extends React.Component {
  state = {
    showBookmark: false,
    bmHeigh: null,
    filters: [],
  };

  async componentDidMount() {
    const filter = document.getElementById("filterContainer");
    this.setState({ bmHeigh: filter.clientHeight });
    this.getBookmark();
  }

  getBookmark = async () => {
    const response = await getUserSavedFilter("vehicle_filter");
    if (response.status === 200) {
      this.setState({ filters: response.data.data.Filters });
    }
  };

  setFilter = (obj) => {
    const { filter, onChange } = this.props;
    onChange({
      ...filter,
      ...obj,
    });
  };

  handleChangeGroupType = (v) => {
    const { filter } = this.props;
    const range = getReportCycleRange(v);
    this.setFilter({
      group_type: v,
      frequency: filter.frequency > range[1] ? range[1] : filter.frequency,
    });
  };

  handleSelectFilter = (filter) => {
    const { onSearch } = this.props;
    let initialFilter = {
      from_hub: "",
      to_hub: "",
      start_date: moment().subtract(4, "months").startOf("month").toDate(),
      frequency: 3,
      vehicles_id: "",
      seller_id: "",
      group_type: "month",
    };
    const params = new URLSearchParams(filter.query);
    let obj = Object.fromEntries(params.entries());
    obj.frequency = Number(obj.frequency);
    obj.start_date = new Date(obj.start_date);
    this.setFilter({ ...initialFilter, ...obj });
    onSearch();
  };

  render() {
    const { hubs, filter, onSearch } = this.props;
    const { showBookmark, filters } = this.state;
    const range = getReportCycleRange(filter.group_type);
    return (
      <React.Fragment>
        <Grid item xs={12} spacing={1}>
          <Paper className="filterContainer" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} id="filterContainer">
              <Grid container alignItems="flex-end" spacing={1}>
                <Grid item xs={12}>
                  <span className="title">BÁO CÁO THEO PHƯƠNG TIỆN</span>
                </Grid>
              </Grid>
              <Grid container alignItems="flex-end" className="filterRow">
                <Grid item xs={12} md={3}>
                  <span className="label">Công ty</span>
                  <div className="multiselect">
                    <SellerSelection
                      value={filter.seller_id}
                      onChange={(e) => this.setFilter({ seller_id: e })}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="flex-end"
                className="filterRow"
                spacing={1}
              >
                <Grid item xs={12} md={3}>
                  <span className="label">Kho xuất</span>
                  <div className="multiselect">
                    <HubFilter
                      value={filter.from_hub}
                      hubs={hubs}
                      type="fromHub"
                      setFilter={(e, f) => this.setFilter({ from_hub: f })}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <span className="label">Kho nhận</span>
                  <div className="multiselect">
                    <HubFilter
                      value={filter.to_hub}
                      hubs={hubs}
                      type="fromHub"
                      setFilter={(e, f) => this.setFilter({ to_hub: f })}
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid container className="filterRow" spacing={1}>
                <Grid item xs={12} md={3}>
                  <span className="label">Ngày bắt đầu</span>
                  <div>
                    <DatePicker
                      className="dateRange"
                      value={filter.start_date}
                      showLeadingZeros
                      onChange={(e) => this.setFilter({ start_date: e })}
                      locale="vi-VN"
                      calendarIcon={null}
                      clearIcon={null}
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={5}>
                  <span className="label ">Loại báo cáo</span>
                  <div className="multiselect">
                    <ReportRange
                      value={filter.group_type}
                      onChange={this.handleChangeGroupType}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="flex-end"
                className="filterRow"
                spacing={1}
              >
                <Grid item xs={12} md={3}>
                  <span className="label">Số kỳ báo cáo</span>
                  <div>
                    <NumberSelection
                      min={range[0]}
                      max={range[1]}
                      value={filter.frequency}
                      onChange={(e) =>
                        this.setFilter({
                          frequency: e,
                        })
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <span className="label">Loại phương tiện</span>
                  <div>
                    <VehicleTypeSelection
                      value={filter.vehicle}
                      onChange={(e) => this.setFilter({ vehicle: e })}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Button className="searchBtn" onClick={onSearch}>
                    Tìm kiếm
                  </Button>
                  &nbsp;
                  <Button
                    className="saveFilterBtn"
                    onClick={() =>
                      this.setState({ showBookmark: !showBookmark })
                    }
                  >
                    <i className="far fa-bookmark" />
                    &nbsp;&nbsp;Bộ lọc
                  </Button>
                </Grid>
              </Grid>
            </div>
            <BookmarkBar
              currentFilter={filter}
              type="vehicle_filter"
              filters={filters}
              open={showBookmark}
              saveCallBack={() => this.getBookmark()}
              onDeleteCallback={() => this.getBookmark()}
              onSelect={this.handleSelectFilter}
            />
          </Paper>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  hubs: state.admin.shared.hubs,
  allKtvList: state.admin.shared.allKtvList,
});

const mapDispatchToProps = (dispatch) => ({});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(VehicleReportTable)
);
