import React from "react";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  icon: {
    fontSize: 20,
    color: "#2f4f4fc4",
    "&:hover": {
      color: "#0078d7",
    },
  },
  tooltip: {
    fontSize: 15,
    padding: 5,
  },
});
const ClickableCell = ({ id, classes }) => {
  return (
    <Tooltip
      placement="right"
      title={<div className={classes.tooltip}>Click vào để xem chi tiết</div>}
    >
      <a href={`/van-don/${id}`} target="_blank" rel="noreferrer">
        <i className={`fas fa-search-plus ${classes.icon}`} />
      </a>
    </Tooltip>
  );
};

export default withStyles(styles)(ClickableCell);
