import React, { useState, useEffect, useRef } from "react";

function MyGoogleMap({
  center,
  directions = null,
  defaultZoom = 15,
  markers = [],
}) {
  const [map, setMap] = useState(null);
  const [localMarkers, setLocalMarkers] = useState([]);
  const [localDirections, setLocalDirections] = useState(null);
  const mapRef = useRef();

  useEffect(() => {
    const myMap = new window.google.maps.Map(mapRef.current, {
      center: center,
      zoom: defaultZoom,
    });
    setMap(myMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (map) {
      map.setCenter(center);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center]);

  useEffect(() => {
    if (map) {
      if (localMarkers.length > 0) {
        localMarkers.forEach((element) => {
          element.setMap(null);
        });
      }

      const ggMakers = [];
      markers.forEach((marker) => {
        const mapMarker = new window.google.maps.Marker(marker);
        mapMarker.setMap(map);
        if (marker.infowindow) {
          const infowindow = new window.google.maps.InfoWindow({
            content: marker.infowindow,
          });
          mapMarker.addListener("click", () => {
            infowindow.open(map, mapMarker);
          });
          mapMarker.addListener("dblclick", () => {
            map.setCenter(marker.position);
            map.setZoom(18);
          });
        }
        ggMakers.push(mapMarker);
      });
      setLocalMarkers(ggMakers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markers]);

  useEffect(() => {
    if (map && directions) {
      if (localDirections) {
        localDirections.setMap(null);
      }
      const directionRenderer = new window.google.maps.DirectionsRenderer({
        suppressMarkers: true,
      });

      directionRenderer.setDirections(directions);
      directionRenderer.setMap(map);
      setLocalDirections(directionRenderer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directions]);

  return (
    <React.Fragment>
      <div
        id="map"
        ref={mapRef}
        style={{ width: "100%", height: "100%", minHeight: 400 }}
      ></div>
    </React.Fragment>
  );
}

export default MyGoogleMap;
