import qs from "qs";
import _ from "lodash";
import { options } from "../bl_list/components/filters/DeliveryTypeFilter";

const getParamsFromUrl = (url_str) => {
  /**
   * Tách params từ url query string. Trả về object chứa filters
   * @type {URL}
   */
  let queryParam = qs.parse(_.trimStart(url_str, "?"));
  let minifiedFilter = Object.assign(
    {},
    ...Object.keys(queryParam).map((key) => {
      if (key == "quick_filter") {
        return { status: queryParam[key] };
      } else if (key == "delivery_types") {
        let splitArr = _.split(queryParam[key], ",");
        const index10 = splitArr.indexOf("10");
        if (index10 !== -1) {
          splitArr = _.pull(splitArr, "11", "12"); //remove "11", "12" element
          splitArr.splice(index10, 1, "10,11,12"); // replace "10" to "10,11,12"
        }
        const index23 = splitArr.indexOf("23");
        if (index23 !== -1) {
          splitArr = _.pull(splitArr, "24", "25"); //remove "24", "25" element
          splitArr.splice(index23, 1, "23,24,25"); // replace "23" to "23,24,25"
        }
        let arr = [];
        arr = splitArr.map((x) => options.find((e) => e.value.val === x));
        return { [key]: arr };
      } else {
        return { [key]: queryParam[key] };
      }
    })
  );
  return minifiedFilter;
};

export default getParamsFromUrl;
