import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Card, CircularProgress, Grid } from "@material-ui/core";
import { Ticket } from "../common";
import BlstatusFilter from "./blstatusFilter";
import moment from "moment";
//styles
const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
  },
  totalBox: {
    borderRadius: "0.5rem",
    margin: "1rem",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    padding: "1rem",
  },
  blStatusContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  totalNumber: {
    fontSize: "3.5rem",
    fontWeight: "bold",
    color: "#b40611",
  },
});

class BlStatusContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toDate: new Date(),
      fromDate: `${new Date().getMonth() + 1}/01/${new Date().getFullYear()}`,
      open: false,
    };
  }

  getStatusList = () => {
    const { start_date, end_date } = this.props;
    const start = moment(start_date).format("YYYY-MM-DD");
    const end = moment(end_date).format("YYYY-MM-DD");
    return [
      {
        background: "#F4F3EF",
        text: "Chờ gán vận chuyển",
        key: "waiting_for_confirm",
        textColor: "#655d5d",
        link: `/?request_type=asia_order,sale_order,wh_transfer&quick_filter=waiting_for_confirm&export_date_from=${start}&export_date_to=${end}`,
      },
      {
        background: "#CDDC39",
        text: "Chờ lấy hàng",
        key: "waiting_for_pick",
        textColor: "#483C0C",
        link: `/?request_type=asia_order,sale_order,wh_transfer&quick_filter=waiting_for_pick&export_date_from=${start}&export_date_to=${end}`,
      },
      {
        background: "#53C2FF",
        text: "Đang giao",
        key: "on_delivering",
        textColor: "#2E456D",
        link: `/?request_type=asia_order,sale_order,wh_transfer&quick_filter=on_delivering&export_date_from=${start}&export_date_to=${end}`,
      },
      {
        background: "#BA68C8",
        text: "Đang hoàn hàng",
        key: "total_returning",
        textColor: "#532f61",
        link: `/?request_type=asia_order,sale_order,wh_transfer&quick_filter=on_returning,on_partial_returning&export_date_from=${start}&export_date_to=${end}`,
      },
      {
        background: "#EE9F00",
        text: "Đang hủy lộ trình",
        key: "cancel_shipping_transport",
        link: `/?request_type=asia_order,sale_order,wh_transfer&quick_filter=cancel_shipping_transport&export_date_from=${start}&export_date_to=${end}`,
      },
      {
        background: "#333333",
        text: "Đã hoàn hàng",
        textColor: "#FFFFFF",
        key: "total_returned",
        link: `/?request_type=asia_order,sale_order,wh_transfer&quick_filter=returned,partial_returned&export_date_from=${start}&export_date_to=${end}`,
      },
      {
        background: "#4CAF50",
        text: "Giao thành công",
        key: "delivered",
        textColor: "#152A23",
        link: `/?request_type=asia_order,sale_order,wh_transfer&quick_filter=delivered&export_date_from=${start}&export_date_to=${end}`,
      },
      {
        background: "#FF0000",
        text: "Đã hủy",
        key: "cancelled",
        textColor: "#FFFFFF",
        link: `/?request_type=asia_order,sale_order,wh_transfer&quick_filter=cancelled&export_date_from=${start}&export_date_to=${end}`,
      },
    ];
  };

  render() {
    const { loading, blStatus, classes } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={6}>
            <BlstatusFilter />
          </Grid>
          <Grid item xs={6} className={classes.blStatusContainer}>
            <Card elevation={0} className={classes.totalBox}>
              <h2>Tổng số vận đơn</h2>
              <span className={classes.totalNumber}>
                {loading ? (
                  <CircularProgress color="inherit" />
                ) : (
                  blStatus.total
                )}
              </span>
              <br />
            </Card>
            <div>
              <div className={classes.container}>
                {this.getStatusList()
                  .slice(0, 4)
                  .map((x, index) => {
                    return (
                      <Ticket
                        key={index}
                        value={blStatus[x.key]}
                        text={x.text}
                        textColor={x.textColor}
                        background={x.background}
                        link={x.link}
                        loading={loading}
                      />
                    );
                  })}
              </div>
              <div className={classes.container}>
                {this.getStatusList()
                  .slice(4, 8)
                  .map((x, index) => {
                    return (
                      <Ticket
                        key={index}
                        value={blStatus[x.key]}
                        text={x.text}
                        textColor={x.textColor}
                        background={x.background}
                        link={x.link}
                        loading={loading}
                      />
                    );
                  })}
              </div>
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.admin.dashboard.blStatusLoading,
  blStatus: state.admin.dashboard.statistic.bls_stat,
  start_date: state.admin.dashboard.statistic.start_date,
  end_date: state.admin.dashboard.statistic.end_date,
});

const mapDispatchToProps = (dispatch) => ({});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(BlStatusContainer)
);
