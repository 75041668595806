import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Paper, IconButton, LinearProgress } from "@material-ui/core";
import { getBusLinesList, changeFilter, toggleBusLineDialog } from "../actions";
import TablePagination from "@material-ui/core/TablePagination";
import AddBusLineDialog from "./AddBusLineDialog";
import { formatDateTime, getKtvLabelFromModel } from "../../utils";
import { deleteBusLine, openNotiDialog } from "../../shared";

const styles = (theme) => ({
  text: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
  },
  iconButton: {
    fontSize: "1.2rem",
  },
  container: {
    borderRadius: "0px 0px 1rem 1rem",
    boxShadow: "0 1px 5px rgba(0, 0, 0, 0.15)",
  },
  nameCell: {
    maxWidth: "25rem",
    wordBreak: "break-all",
  },
  noResultRow: {
    padding: "1rem !important",
  },
  "@global": {
    "#busLineManageTable": {
      width: "100%",
      borderSpacing: 0,
      boxShadow: "0 1px 5px rgba(0, 0, 0, 0.15)",
      borderRadius: "1rem",
    },
    "#busLineFilter .Select-control": {
      height: 40,
      border: "1px solid #ccc",
    },
    "#busLineManageTable td, #busLineManageTable th": {
      fontSize: 14,
    },
    "#busLineManageTable > tbody tr:nth-child(odd) > td": {
      background: "#ecf4fd5c",
    },
    "#busLineManageTable > tbody tr:nth-child(even) > td": {
      background: "white",
    },
    "#busLineManageTable > thead > tr > td": {
      padding: "1rem",
      background: "#324B78",
      fontWeight: "bold",
      color: "white",
    },
    "#busLineManageTable > tbody tr > td": {
      padding: "0.3rem 1rem",
    },
  },
});

const Region = {
  southern: "Miền Nam",
  northern: "Miền Bắc",
  central: "Miền Trung",
};

class BusLineTable extends React.Component {
  handleShortKey = (e) => {
    const key = e.keyCode;
    const alt = e.altKey ? e.altKey : key === 18 ? true : false;
    if (key === 65 && alt) {
      this.props.toggleBusLineDialog(true, null);
    }
  };
  async componentDidMount() {
    window.addEventListener("keydown", this.handleShortKey);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleShortKey);
  }

  handleDelete = async (item, event) => {
    const { openNotiDialog } = this.props;
    openNotiDialog(
      "alert",
      <span>
        Bạn có chắc chắn muốn xóa <strong>{item.name}</strong>?
      </span>,
      null,
      "multiple",
      () => this.deleteBusLine(item)
    );
  };

  deleteBusLine = async (item) => {
    const { getBusLinesList, openNotiDialog } = this.props;
    const response = await deleteBusLine(item.id);
    if (response.status === 200) {
      getBusLinesList();
      openNotiDialog(
        "success",
        <span>
          Xóa <strong>{item.name}</strong> thành công!
        </span>
      );
    }
  };

  render = () => {
    const {
      dialog,
      toggleBusLineDialog,
      bus_lines,
      filter,
      changeFilter,
      tableLoading,
      getBusLinesList,
      total_records,
      classes,
    } = this.props;
    return (
      <Paper elevation={0} className={classes.container}>
        <table id="busLineManageTable">
          <thead>
            {tableLoading && (
              <tr style={{ height: 4 }}>
                <td colSpan={10} style={{ padding: 0 }}>
                  <LinearProgress />
                </td>
              </tr>
            )}
            <tr>
              <td style={{ textAlign: "center" }}>STT</td>
              <td>Tên nhà xe</td>
              <td>Miền</td>
              <td>Người tạo</td>
              <td>Ngày tạo</td>
              <td>Người cập nhật</td>
              <td>Ngày cập nhật</td>
              <td style={{ textAlign: "center" }}>Thao tác</td>
            </tr>
          </thead>
          <tbody>
            {bus_lines.length > 0 ? (
              bus_lines.map((x, index) => (
                <tr key={index}>
                  <td style={{ textAlign: "center" }}>
                    {index + filter.offset + 1}
                  </td>
                  <td className={classes.nameCell}>{x.name}</td>
                  <td>{Region[x.region]}</td>
                  <td>{getKtvLabelFromModel(x.created_by_user)}</td>
                  <td>{formatDateTime(x.created_at)}</td>
                  <td>{getKtvLabelFromModel(x.last_updated_by_user)}</td>
                  <td>{formatDateTime(x.updated_at)}</td>
                  <td style={{ textAlign: "center" }}>
                    <IconButton onClick={(e) => toggleBusLineDialog(true, x)}>
                      <i
                        className="material-icons"
                        style={{ color: "cornflowerblue" }}
                      >
                        edit
                      </i>
                    </IconButton>
                    <IconButton onClick={(e) => this.handleDelete(x, e)}>
                      <i
                        className="material-icons"
                        style={{ color: "indianred" }}
                      >
                        delete
                      </i>
                    </IconButton>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={10} className={classes.noResultRow}>
                  Không tìm thấy kết quả
                </td>
              </tr>
            )}
            <tr>
              <TablePagination
                rowsPerPageOptions={[10, 30, 50]}
                count={total_records}
                rowsPerPage={filter.limit}
                page={filter.offset / filter.limit}
                labelRowsPerPage="Số dòng hiển thị"
                onChangePage={(e, newPage) => {
                  changeFilter({ offset: newPage * filter.limit });
                  getBusLinesList();
                }}
                onChangeRowsPerPage={(e) => {
                  changeFilter({ limit: e.target.value });
                  getBusLinesList();
                }}
              />
            </tr>
          </tbody>
        </table>

        {dialog.open && (
          <AddBusLineDialog
            open={dialog.open}
            onClose={() => this.setState({ open: false })}
            data={dialog.selected}
          />
        )}
      </Paper>
    );
  };
}

const mapStateToProps = (state) => ({
  bus_lines: state.admin.busLineManagement.bus_lines,
  filter: state.admin.busLineManagement.filter,
  tableLoading: state.admin.busLineManagement.tableLoading,
  dialog: state.admin.busLineManagement.dialog,
  total_records: state.admin.busLineManagement.total_records,
});
const mapDispatchToProps = (dispatch) => ({
  getBusLinesList: () => dispatch(getBusLinesList()),
  toggleBusLineDialog: (state, selected) =>
    dispatch(toggleBusLineDialog(state, selected)),
  openNotiDialog: (type, text, callback, options, confirmCallback) =>
    dispatch(openNotiDialog(type, text, callback, options, confirmCallback)),
  changeFilter: (pagination) => dispatch(changeFilter(pagination)),
});
export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(BusLineTable)
);
