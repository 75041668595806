/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import MyGoogleMap from "../../../shared/components/GoogleMap";
import DeliveryMan from "@images/shipper-md.png";
import ReactDomServer from "react-dom/server";

function DeliveryMap({ currentBl, bls }) {
  const [points, setPoints] = useState([]);
  const [directions, setDirections] = useState([]);
  const [deliveryMan, setDeliveryMan] = useState(null);

  const generatePoints = () => {
    let list = [];

    bls.forEach((bl) => {
      //pick point
      const existsPickPoint = list.findIndex(
        (point) => point.index === bl.pick_index
      );
      if (existsPickPoint >= 0) {
        list[existsPickPoint].request_id.push(bl.request_id);
      } else {
        list.push({
          index: bl.pick_index,
          request_id: [bl.request_id],
          location: bl.from_address,
          type: 1,
        });
      }
      // delivery point
      const existsDeliPoint = list.findIndex(
        (point) => point.index === bl.delivery_index
      );
      if (existsDeliPoint >= 0) {
        list[existsDeliPoint].request_id.push(bl.request_id);
      } else {
        list.push({
          index: bl.delivery_index,
          request_id: [bl.request_id],
          location: bl.to_address,
          type: 2,
        });
      }
    });
    setPoints(list);
    generateDirections(list);
  };

  const generateDirections = async (points) => {
    const waypoints = [];
    if (points.length > 2) {
      for (let i = 1; i < points.length - 1; i++) {
        waypoints.push({
          location: points[i].location,
          stopover: true,
        });
        const DirectionsService = new window.google.maps.DirectionsService();
        DirectionsService.route(
          {
            origin: points[0].location,
            destination: points[i + 1].location,
            travelMode: window.google.maps.TravelMode.DRIVING,
            waypoints: waypoints,
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              setDirections(result);
            }
          }
        );
      }
    } else if (points.length === 2) {
      const DirectionsService = new window.google.maps.DirectionsService();
      DirectionsService.route(
        {
          origin: points[0].location,
          destination: points[1].location,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result);
          }
        }
      );
    }
  };

  useEffect(() => {
    generatePoints();
  }, [bls]);

  useEffect(() => {
    generatePoints();
    const { current_location } = currentBl.current_transport;
    if (current_location.lat && current_location.lng) {
      const point = {
        position: current_location,
        icon: {
          url: DeliveryMan, // url
          scaledSize: new window.google.maps.Size(50, 70), // scaled size
        },
        infowindow: "Nhân viên giao hàng",
      };
      setDeliveryMan(point);
    }
  }, []);

  const listMarker = () => {
    let markers = points.map((x) => {
      const infoWindow = (
        <div>
          <div>
            <strong>
              Điểm {x.type === 1 ? "Lấy hàng" : "Giao hàng"} (
              {x.request_id.length} đơn):{" "}
            </strong>
            {x.request_id.join(", ")}
          </div>
          <div>{x.location.full_address}</div>
        </div>
      );
      return {
        position: x.location,
        label: {
          text: x.index.toString(),
          fontWeight: "bold",
          color: "white",
        },
        infowindow: ReactDomServer.renderToString(infoWindow),
      };
    });
    if (deliveryMan) markers.push(deliveryMan);
    return markers;
  };

  return (
    <MyGoogleMap
      markers={listMarker()}
      center={points[0] ? points[0].location : { lat: 0, lng: 0 }}
      defaultZoom={12}
      directions={directions}
    />
  );
}

export default DeliveryMap;
