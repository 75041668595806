import React from "react";
import { Dialog } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    top: "0.5rem",
    right: "0.5rem",
    backgroundColor: "transparent",
    border: "none",
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  closeIcon: {
    fontSize: "2rem",
    background: "white",
    borderRadius: "100%",
  },
  image: {
    maxWidth: 500,
    maxHeight: 500,
  },
});

export const ImageDialog = ({ open, url, closeDialog, classes }) => (
  <Dialog open={open} onClose={closeDialog}>
    <button className={classes.closeButton} onClick={closeDialog}>
      <i className={`${classes.closeIcon} material-icons`}>cancel</i>
    </button>
    <img className={classes.image} src={url} alt="" />
  </Dialog>
);

export default withStyles(styles)(ImageDialog);
