import React from "react";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

//styles
const styles = (theme) => ({
  title: {
    textTransform: "uppercase",
    fontFamily: "Asap Condensed, sans-serif",
    fontWeight: 600,
    letterSpacing: 1,
    wordSpacing: 1,
    marginBottom: "1.5rem",
    color: "#6464b5",
  },
});

class PageTitle extends React.Component {
  render() {
    const { children, classes } = this.props;
    return (
      <Typography variant="h5" gutterBottom className={classes.title}>
        {children}
      </Typography>
    );
  }
}

export default withStyles(styles)(PageTitle);
