import _ from "lodash";
import moment from "moment";

const _initialState = {
  filter: {
    from_hub: "",
    to_hub: "",
    start_date: moment().subtract(1, "months").startOf("month").toDate(),
    end_date: moment().subtract(1, "months").endOf("month").toDate(),
    asia_branch: "",
    shipper_id: "",
  },
  loading: false,
  data: [],
  summary: {
    total_bls: 0,
    grand_total: 0,
    total_distance: 0,
    total_estimate_fee: 0,
  },
  pagination: {
    limit: 10,
    offset: 0,
    totalRecords: 0,
  },
};

const ShipmentSummaryReport = (state = _initialState, action) => {
  switch (action.type) {
    case "SHIPMENT_SUMMARY_REPORT_CHANGE_FILTER":
      return {
        ...state,
        filter: action.payload,
      };
    case "SHIPMENT_SUMMARY_REPORT_CHANGE_PAGINATION":
      return {
        ...state,
        pagination: action.payload,
      };
    case "SHIPMENT_SUMMARY_REPORT_CHANGE_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "SHIPMENT_SUMMARY_REPORT_SET_DATA":
      const results = action.payload.results;
      return {
        ...state,
        data: results,
        pagination: {
          ...state.pagination,
          totalRecords: action.payload.total_records,
        },
        summary: {
          total_bls: results.map((x) => x.count_bls).reduce((a, b) => a + b, 0),
          grand_total: results
            .map((x) => x.sum_grand_total)
            .reduce((a, b) => a + b, 0),
          total_real_distance: results
            .map((x) => x.sum_real_distance)
            .reduce((a, b) => a + b, 0),
          total_bl_distance: results
            .map((x) => x.sum_bl_distance)
            .reduce((a, b) => a + b, 0),
          total_real_cost: results
            .map((x) => x.sum_real_cost)
            .reduce((a, b) => a + b, 0),
          total_bl_cost: results
            .map((x) => x.sum_bl_cost)
            .reduce((a, b) => a + b, 0),
          count_confirmed: results
            .map((x) => x.count_confirmed)
            .reduce((a, b) => a + b, 0),
          count_delivered: results
            .map((x) => x.count_delivered)
            .reduce((a, b) => a + b, 0),
          count_delivering: results
            .map((x) => x.count_delivering)
            .reduce((a, b) => a + b, 0),
          count_transport: results
            .map(
              (x) => x.count_delivering + x.count_delivered + x.count_confirmed
            )
            .reduce((a, b) => a + b, 0),
        },
      };
    default:
      return state;
  }
};

export default ShipmentSummaryReport;
