import React from "react";
import Select from "react-select";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { changeBlFollower } from "../../actions/blActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import _ from "lodash";

const styles = () => ({
  subtitle: {
    fontSize: 14,
    padding: "8px 0 16px 0",
    fontFamily: "Source Sans Pro, sans-serif",
    textTransform: "uppercase",
    fontWeight: 600,
    letterSpacing: 1,
    color: "#5d9cec",
  },
  BlFollower: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
  },
  note: {
    textAlign: "center",
    fontStyle: "italic",
    fontSize: "0.8rem",
    paddingTop: "0px !important",
  },
  button: {
    textTransform: "none",
  },
});

class BlFollower extends React.Component {
  state = {
    selectedKtv: this.props.bl.follower ? this.props.bl.follower.value : "",
    changed: false,
  };

  disabledBtn = () => {
    if (!this.state.changed && !this.props.btnLoading) return true;
    if (this.props.bl.cantAssignTransportFollower) return true;
    if (
      (this.state.selectedKtv &&
        this.props.bl.follower &&
        this.state.selectedKtv.value === this.props.bl.follower.value) ||
      this.state.selectedKtv === this.props.bl.follower
    )
      return true;
    return false;
  };

  render() {
    let { classes, ktvList, bl, changeBlFollower, btnLoading } = this.props;
    let { selectedKtv } = this.state;
    let newList = _.cloneDeep(ktvList);
    if (
      bl.follower &&
      newList.find((x) => x.value === bl.follower.value) === undefined
    ) {
      newList.push(bl.follower);
    }
    return (
      <div>
        <Typography type="subheading" className={classes.subtitle}>
          Gán theo dõi vận đơn
        </Typography>
        <div>
          <Grid
            container
            className={classes.BlFollower}
            direction="row"
            alignItems="flex-start"
            spacing={1}
          >
            <Grid item style={{ paddingTop: "1rem" }}>
              Chọn KTV theo dõi
            </Grid>
            <Grid item md={7}>
              <Grid container direction="column">
                <Grid item>
                  <Select
                    options={newList}
                    placeholder="Chọn KTV"
                    value={selectedKtv}
                    onChange={(v) => {
                      this.setState({ selectedKtv: v, changed: true });
                    }}
                    rapperStyle={{ width: "100%" }}
                    style={{ height: 41 }}
                    disabled={bl.cantAssignTransportFollower}
                  />
                </Grid>
                <Grid item className={classes.note}>
                  (KTV có thể theo dõi vận đơn này)
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={3}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => {
                  changeBlFollower(bl.id, selectedKtv);
                  this.setState({ changed: false });
                }}
                disabled={this.disabledBtn()}
              >
                {btnLoading ? (
                  <CircularProgress size={20} style={{ color: "#fff" }} />
                ) : (
                  <i className="material-icons">save</i>
                )}
                &nbsp;Lưu thông tin
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  ktvList: state.admin.shared.allKtvList,
  bl: state.admin.detail.bl,
  btnLoading: state.admin.detail.btnLoading,
});

const mapDispatchToProps = (dispatch) => ({
  changeBlFollower: (blId, ktv) => dispatch(changeBlFollower(blId, ktv)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(BlFollower)
);
