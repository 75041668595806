import React from "react";

const FromHubCell = ({ fromHub }) => {
  return (
    <React.Fragment>
      {fromHub.hub && fromHub.hub.asia_branch.code ? (
        <React.Fragment>
          <strong>{fromHub.hub.asia_branch.code}</strong>
          <br />
          <span>{fromHub.hub.asia_branch.address}</span>
        </React.Fragment>
      ) : (
        fromHub.displayName
      )}
    </React.Fragment>
  );
};

export default FromHubCell;
