import * as React from "react";
import Input from "@material-ui/core/Input";
import { MenuItem } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";

export const styles = (theme) => ({
  pageSizeSelector: {
    ...theme.typography.caption,
    float: "left",
    paddingLeft: theme.spacing(5),
  },
  label: {
    paddingRight: theme.spacing(3),
    lineHeight: `${theme.spacing(5)}px`,
    float: "left",
  },
  label2: {
    paddingLeft: theme.spacing(3),
    lineHeight: `${theme.spacing(5)}px`,
    float: "right",
  },
  pageSizes: {
    display: "inline-block",
  },
  pageSizeTitle: {
    width: "auto",
    marginRight: theme.spacing(0.5),
  },
  inputRoot: {
    paddingTop: theme.spacing(0.75),
    float: "right",
    fontSize: theme.spacing(1.75),
    textAlign: "right",
  },
  selectIcon: {
    top: 2,
  },
  "@media (max-width: 768px)": {
    label: {
      display: "none",
    },
    pageSizeSelector: {
      paddingLeft: theme.spacing(2),
    },
  },
});

const PageSizeSelector = ({ filter, onPageSizeChange, classes }) => {
  return (
    <div className={classes.pageSizeSelector}>
      <span className={classes.label}>Show:</span>
      <span className={classes.label2}>bản ghi/trang</span>
      <Select
        value={filter.pageSize}
        onChange={(event) => onPageSizeChange(event.target.value)}
        classes={{
          icon: classes.selectIcon,
        }}
        input={<Input disableUnderline classes={{ root: classes.inputRoot }} />}
      >
        {filter.allowedPageSizes.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default withStyles(styles)(PageSizeSelector);
