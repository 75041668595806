import React from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { VehicleReport } from "./VehicleReport";
import { ShipDateSummaryReport } from "./ShipDateSummaryReport";
import { ShipperDailyReport } from "./ShipperDailyReport";
import { ShipperSummaryFuelCostReport } from "./ShipperSummaryFuelCostReport";

function ReportRoute({ match }) {
  const { path } = match;
  return (
    <Switch>
      {/* <Route
        path={`${path}/shipper-detail-report`}
        component={ShipmentDetailReport}
      />
      <Route
        path={`${path}/shipper-summary-report`}
        component={ShipmentSummaryReport}
      /> */}
      <Route path={`${path}/vehicle-report`} component={VehicleReport} />
      <Route
        path={`${path}/ship-date-summary-report`}
        component={ShipDateSummaryReport}
      />
      {/* <Route
        path={`${path}/ship-date-detail-report`}
        component={ShipDateDetailReport}
      /> */}
      <Route
        path={`${path}/shipper-daily-report`}
        component={ShipperDailyReport}
      />
      <Route
        path={`${path}/shipper-daily-report-for-ktv`}
        component={ShipperDailyReport}
      />
      <Route
        path={`${path}/shipper-summary-fuel-cost-report`}
        component={ShipperSummaryFuelCostReport}
      />
      {/* <Route
        path={`${path}/shipper-status-report`}
        component={ShipperStatusReport}
      /> */}
      <Route path="*">
        <Redirect to={`${path}/vehicle-report`} />
      </Route>
    </Switch>
  );
}

export default withRouter(ReportRoute);
