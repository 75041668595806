import React from "react";
import Draggable from "react-draggable";
import { Fab, Dialog } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  shareButton: {
    right: "0.7rem",
    position: "fixed",
    background: "rgba(63,81,181,0.9)",
    zIndex: 10000,
  },
});

class UserManual extends React.Component {
  constructor() {
    super();
    this.state = {
      activeDrags: 0,
      showManual: false,
    };
  }

  onStart = () => {
    this.setState({ activeDrags: this.state.activeDrags + 1 });
  };

  onStop = () => {
    this.setState({ activeDrags: this.state.activeDrags - 1 });
  };

  render() {
    const dragHandlers = { onStart: this.onStart, onStop: this.onStop };
    const { manual, direct, classes } = this.props;
    return (
      <React.Fragment>
        <Draggable {...dragHandlers}>
          <Fab
            color="primary"
            className={classes.shareButton}
            onClick={() => this.setState({ showManual: true })}
          >
            <i style={{ fontSize: 20 }} className="fas fa-question" />
          </Fab>
        </Draggable>
        <div>
          <Dialog
            open={this.state.showManual}
            onClose={() => this.setState({ showManual: false })}
            maxWidth="md"
          >
            <div
              style={{ padding: "20px" }}
              dangerouslySetInnerHTML={{ __html: manual[direct] }}
            />
          </Dialog>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(UserManual);
