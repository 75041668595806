import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  mapBlStatusColor,
  mapBlStatusText,
  mapShipperState,
} from "../../../../utils";

const styles = {
  blStatusCell: {
    color: "#ffffff",
    textAlign: "left",
    minWidth: "8rem",
    whiteSpace: "nowrap",
  },
  statusLabel: {
    color: "#fff",
    fontSize: 11,
    fontWeight: 800,
    padding: 4,
    borderRadius: 4,
    display: "inline",
  },
  inverseStatusLabel: {
    fontSize: 11,
    fontWeight: 800,
    padding: 4,
    borderRadius: 4,
    color: "#797979",
    display: "inline",
  },
  label: {
    fontSize: 11,
    padding: 3,
    borderRadius: 4,
    fontWeight: 600,
    marginTop: 5,
    display: "inline-block",
  },
};

const BlStatusCell = ({ id, status, classes, className, bl }) => {
  const renderKtvStatus = () => {
    if (bl.lastTransport.shipMethod === "ktv") {
      const shipperState = mapShipperState(
        bl.lastTransport.shipper_state,
        bl.lastTransport.state
      );
      if (shipperState)
        return (
          <div
            className={classes.label}
            style={{
              background: shipperState.list_background,
              color: shipperState.color,
            }}
          >
            {shipperState.label}
          </div>
        );
    }
  };

  if (status === "waiting_for_confirm" || bl.isRetry) {
    return (
      <React.Fragment>
        <div className={classes.inverseStatusLabel}>
          {mapBlStatusText(status, bl.isRetry)}
        </div>
        <br />
        {renderKtvStatus()}
      </React.Fragment>
    );
  } else if (status === "returned") {
    return (
      <React.Fragment>
        <div
          className={classes.inverseStatusLabel}
          style={{ backgroundColor: mapBlStatusColor(status), color: "#fff" }}
        >
          {mapBlStatusText(status)}
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div
          className={classes.statusLabel}
          style={{
            backgroundColor: mapBlStatusColor(status, bl.isRetry),
          }}
        >
          {mapBlStatusText(status, bl.isRetry)}
        </div>
        <br />
        {renderKtvStatus()}
      </React.Fragment>
    );
  }
};

export default withStyles(styles)(BlStatusCell);
