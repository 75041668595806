export const BlRequestType = {
  wh_transfer: "wh_transfer" /* Chuyển kho nội bộ */,
  sale_order: "sale_order" /* Đơn KH từ OM */,
  asia_order: "asia_order" /* Đơn KH từ ASIA */,
};

export const DeliveryServiceSKU = {
  door_to_door: "1206838",
  door_to_door_with_technical_support: "1702079",
};

export const ShipMethod = {
  direct: "direct",
  ghn: "ghn",
  vnpost: "vnpost",
  vnpost_manual: "vnpost_manual",
  vtpost: "vtpost",
  ktv: "ktv",
  xe_khach: "xe_khach",
  xe_tai: "xe_tai",
  ghtk: "ghtk",
  ahamove: "ahamove",
  nhat_tin: "nhat_tin",
  nhat_tin_manual: "nhat_tin_manual",
  tan_son_nhat: "tan_son_nhat",
  vtpost_manual: "vtpost_manual",
  kerry_express_manual: "kerry_express_manual",
  viet_star: "viet_star",
  nv_phongvu: "nv_phongvu",
  nv_umbala: "nv_umbala",
  nv_umbala_manual: "nv_umbala_manual",
  adidi: "adidi",
  nv_adidi_manual: "nv_adidi_manual",
  hoang_trong_manual: "hoang_trong_manual",
  kien_vang_manual: "kien_vang_manual",
  nv_kinhdo: "nv_kinhdo",
  vtphn_manual: "vtphn_manual",
  vtbt_manual: "vtbt_manual",
  kl_manual: "kl_manual",
  eton: "eton",
  eton_vnt: "eton_vnt",
  eton_vin: "eton_vin",
  tran_an_manual: "tran_an_manual",
  vnt_adidi_manual: "vnt_adidi_manual",
  tkl_eton: "tkl_eton",
  tkl_manual: "tkl_manual",
  vin_manual: "vin_manual",
};

export const ShipType = {
  hub_hub: "hub_hub",
  hub_customer: "hub_customer",
  hub_ktv: "hub_ktv",
  customer_hub: "customer_hub",
  ktv_customer: "ktv_customer",
  ktv_hub: "ktv_hub",
  ktv_ktv: "ktv_ktv",
  customer_customer: "customer_customer",
};

export const BlChannel = {
  agent: "Agent",
  online: "Online",
  showroom: "Showroom",
  direct: "Direct",
};

export const SHIPPER_TOTAL_REPORT = [
  { key: "index", label: "STT", align: "center" },
  { key: "shipper_id", label: "Mã nhân viên", align: "center" },
  { key: "shipper_name", label: "Tên nhân viên", align: "left" },
  { key: "shipper_asia_branch", label: "Chi nhánh", align: "left" },
  { key: "count_bls", label: "Tổng số đơn hàng giao", align: "right" },
  { key: "sum_grand_total", label: "Trị giá giao hàng", align: "right" },
  {
    key: "sum_real_distance",
    label: "Tổng số km theo thao tác",
    align: "right",
  },
  { key: "sum_bl_distance", label: "Tổng số km theo đơn", align: "right" },
  {
    key: "average_real_distance",
    label: "Số km TB theo thao tác/ đơn hàng",
    align: "right",
  },
  {
    key: "average_bl_distance",
    label: "Số km TB theo đơn/ đơn hàng",
    align: "right",
  },
  { key: "sum_real_cost", label: "Tiền xăng xe theo thao tác", align: "right" },
  {
    key: "sum_bl_cost",
    label: "Tiền xăng xe giao hàng theo đơn",
    align: "right",
  },
  { key: "on_time_rate", label: "Tỉ lệ giao hàng đúng hẹn", align: "right" },
];

export const SHIPPER_DETAIL_REPORT = [
  { key: "index", label: "STT", align: "center" },
  { key: "request_id", label: "Số chứng từ", align: "left" },
  { key: "customer_name", label: "Tên khách hàng", align: "left" },
  { key: "shipping_address", label: "Địa chỉ giao hàng", align: "left" },
  { key: "grand_total", label: "Trị giá giao hàng", align: "right" },
  { key: "ship_date", label: "Giờ hẹn", align: "center" },
  { key: "start_time", label: "Giờ lấy hàng", align: "center" },
  { key: "arrived_time", label: "Giờ giao", align: "center" },
  { key: "ended_time", label: "Giờ hoàn thành", align: "center" },
  { key: "shipping_duration", label: "Khoảng thời gian", align: "center" },
  { key: "bl_distance", label: "Số km theo đơn", align: "right" },
  { key: "real_distance", label: "Số km theo thao tác", align: "right" },
  { key: "bl_cost", label: "Tiền xăng giao hàng theo đơn", align: "right" },
  {
    key: "real_cost",
    label: "Tiền xăng giao hàng theo thao tác",
    align: "right",
  },
];

export const SHIPPER_STATUS_REPORT = [
  { key: "index", label: "STT", align: "center" },
  { key: "shipper_id", label: "Mã nhân viên", align: "left" },
  { key: "shipper_name", label: "Tên nhân viên", align: "left" },
  { key: "shipper_asia_branch", label: "Chi nhánh", align: "left" },
  { key: "total_transport", label: "Tổng số chuyến được gán", align: "right" },
  {
    key: "assigned_in_day",
    label: "Số LT được gán trong ngày",
    align: "right",
  },
  {
    key: "delivered_in_day",
    label: "Số LT hoàn tất trong ngày",
    align: "right",
  },
  { key: "declined", label: "Số chuyến từ chối", align: "right" },
  { key: "delivered", label: "Số chuyến hoàn tất", align: "right" },
  { key: "on_delivering", label: "Số chuyến đang giao", align: "right" },
  {
    key: "confirmed",
    label: "Số chuyến chưa lấy hàng",
    align: "right",
  },
  { key: "unfinished", label: "Số chuyến tồn", align: "right" },
];

export const SHIP_DATE_SUMMARY_REPORT = [
  { key: "index", label: "STT", align: "center" },
  { key: "request_id", label: "Mã lí do", align: "left" },
  { key: "customer_name", label: "Tên lí do", align: "left" },
  { key: "shipping_address", label: "Tổng số đơn phải hẹn lại", align: "left" },
  { key: "grand_total", label: "Tỉ trọng theo lí do", align: "right" },
];

export const BlHistoryAction = {
  print_bill: "print_bill",
  print_label: "print_label",
  pick_up: "pick_up",
  to_3pls: "to_3pls",
  create_bl: "create_bl",
  cancel_bl: "cancel_bl",
  system_cancel_transport: "system_cancel_transport",
  cancel_transport: "cancel_transport",
  ktv_add_image: "ktv_add_image",
  ktv_decline: "ktv_decline",
  ktv_accepted: "ktv_accepted",
  ktv_pick_up: "ktv_pick_up",
  ktv_stock_request: "ktv_stock_request",
  ktv_at_customer_house: "ktv_at_customer_house",
  ktv_request_cancel: "ktv_request_cancel",
  ktv_partial_returning: "ktv_partial_returning",
  ktv_returning: "ktv_returning",
  transport_done: "transport_done",
  bl_done: "bl_done",
  create_transport: "create_transport",
  update_transport: "update_transport",
  confirm_return_hub: "confirm_return_hub",
  pick_up_now: "pick_up_now",
  create_transport_now: "create_transport_now",
  to_3pls_now: "to_3pls_now",
  bl_done_now: "bl_done_now",
  three_pls_done: "three_pls_done",
  three_pls_update: "three_pls_update",
  three_pls_returned: "three_pls_returned",
  request_return: "request_return",
  wh_partial_import: "wh_partial_import",
};

export const WMS_STATUS = {
  init: "init", //Chờ soạn hàng
  pick: "pick", //Chờ đóng gói
  pack: "pack", //Đã đóng gói
  cancelled: "cancelled", //Yêu cầu hủy từ Kho
  partial_import: "partial_import", //Yêu cầu nhập 1 phần từ kho
};

export const THREE_PL_STATE = {
  return_confirm: "return_confirm", // 3PLs xác nhận khách hoàn
  on_return: "on_return", // 3PLs đang đem hàng về kho
  return_received: "return_received", // 3Pls đã đem hàng tới kho
};

// Các 3pls đặc biệt
export const SPECIAL_THREE_PLS = [
  ShipMethod.eton,
  ShipMethod.eton_vnt,
  ShipMethod.tkl_eton,
  ShipMethod.eton_vin,
];

export const BL_GROUP_OTHER_SYSTEM_OPTIONS = [
  { value: "init", label: "Chờ soạn hàng" },
  { value: "packing", label: "Chưa đóng gói" },
  { value: "packed", label: "Đã đóng gói" },
  { value: "cancelled", label: "Yêu cầu hủy từ Kho" },
  { value: "partial_import", label: "Đã nhập kho 1 phần" },
];

export const THREE_PL_STATE_OPTONS = [
  { value: "3pl_return_confirm", label: "3PLs xác nhận khách hoàn" },
  { value: "3pl_on_return", label: "3PLs đang đem hàng về kho" },
  { value: "3pl_return_received", label: "3Pls đã đem hàng tới kho" },
];

export const MAX_TRUST_DOMAINS = [
  "https://logistics-maxtrust.dev.teko.vn",
  "https://logistics-maxtrust.stag.teko.vn",
  "https://logistics-maxtrust.teko.vn",
  "https://logistic.bachhoathuoc.com",
];

export const OTHER_SYSTEM_OPTIONS = BL_GROUP_OTHER_SYSTEM_OPTIONS.concat(
  THREE_PL_STATE_OPTONS
).concat([
  { value: "partial_cancelled", label: "Yêu cầu hoàn một phần" },
  { value: "full_cancelled", label: "Yêu cầu hoàn toàn phần" },
  { value: "area_code_null", label: "Chưa chọn khu vực" },
  { value: "area_code_not_null", label: "Đã chọn khu vực" },
]);

export const MENU_SELECT_ID = [
  { label: "Sử dụng mã vận đơn để đến chi tiết đơn hàng", type: "" },
  {
    label: "Sử dụng mã phiếu xuất/ mã đơn CKNB để đến chi tiết đơn hàng",
    type: "request_code",
  },
  { label: "Sử dụng mã đơn hàng để đến chi tiết đơn hàng", type: "order_code" },
];

export const TransportState = {
  confirmed: "confirmed",
  shipping: "shipping",
  retry: "retry",
  returning: "returning",
  cancelled: "cancelled",
  returned: "returned",
  ok: "ok",
  partial_ok: "partial_ok",
  delivering: "shipping,retry",
  delivered: "ok,partial_ok",
};

/*  Trạng thái lấy hàng từ WH
*     init: khởi tạo
      wms_to_lo_request: đã gửi yêu cầu lấy hàng sang WH
*/
export const BlStockStatus = {
  init: "init",
  wmsToLoRequest: "wms_to_lo_request",
};
