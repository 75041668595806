import React from "react";
import { BlState } from "../../../models";
import { TransportState } from "./../../../models/Transport";
import { Holder } from "./../../../models/Bl";
import { store } from "../../..";

export const mapBlStatusText = (status, last_state) => {
  /**
   * Trả về text hiển thị tương ứng với quick filter
   */
  switch (status) {
    case "waiting_for_confirm":
      return "Chờ gán vận chuyển";
    case "waiting_for_pick":
      return "Chờ lấy hàng";
    case "on_delivering":
      if (last_state) {
        return "Chờ gán vận chuyển";
      }
      return "Đang giao";
    case "on_returning":
      return "Đang hoàn hàng";
    case "on_partial_returning":
      return "Đang hoàn một phần";
    case "cancel_shipping_transport":
      return "Đang hủy lộ trình";
    case "returned":
      return "Đã hoàn hàng";
    case "partial_returned":
      return "Đã hoàn một phần";
    case "cancelled":
      return "Hủy vận đơn";
    case "delivered":
      return "Giao thành công";
    case "waiting_for_retry":
      return "Chờ giao lại";
    default:
      return <span />;
  }
};

export const mapBlStatusColor = (status, last_state) => {
  /**
   * Trả về color hex code tương ứng với quick filter
   */
  switch (status) {
    case "waiting_for_confirm":
      return "#fff";
    case "waiting_for_pick":
      return "#CDDC39";
    case "on_delivering":
      if (last_state) {
        return "#fff";
      }
      return "#03A9F4";
    case "on_returning":
      return "#ba68c8";
    case "on_partial_returning":
      return "#ba68c8";
    case "cancel_shipping_transport":
      return "#EE9F00";
    case "returned":
      return "#000";
    case "partial_returned":
      return "#000";
    case "cancelled":
      return "#ff0000";
    case "delivered":
      return "#4CAF50";
    default:
      return "";
  }
};

export const getStatus = (bl) => {
  const trState = bl.last_transport.state;
  switch (bl.state) {
    case BlState.init:
      if (trState && trState === TransportState.confirmed) {
        return "waiting_for_pick";
      }
      return "waiting_for_confirm";
    case BlState.delivering:
      if (
        trState &&
        [TransportState.shipping, TransportState.retry].includes(trState)
      ) {
        return "on_delivering";
      } else if (TransportState.returning === trState) {
        return "cancel_shipping_transport";
      } else {
        if ([Holder.hub, Holder.sender].includes(bl.holder_type)) {
          if (trState === TransportState.confirmed) {
            return "waiting_for_pick";
          }
          return "waiting_for_confirm";
        }

        return "on_delivering";
      }

    case BlState.returning:
      if (
        trState &&
        ([
          TransportState.returning,
          TransportState.returned,
          TransportState.shipping,
          TransportState.retry,
          TransportState.ok,
        ].includes(trState) ||
          (TransportState.confirmed === trState &&
            bl.holder_type === Holder.hub))
      ) {
        return "on_returning";
      }
      break;

    case BlState.partial_returning:
      if (
        trState &&
        ([
          TransportState.returning,
          TransportState.returned,
          TransportState.shipping,
          TransportState.retry,
          TransportState.ok,
          TransportState.partial_ok,
        ].includes(trState) ||
          (TransportState.confirmed === trState &&
            bl.holderType === Holder.hub))
      ) {
        return "on_partial_returning";
      }
      break;

    case BlState.returned:
      if (
        trState &&
        [TransportState.ok, TransportState.returned].includes(trState)
      ) {
        return "returned";
      }
      break;

    case BlState.partial_returned:
      if (
        trState &&
        [TransportState.ok, TransportState.returned].includes(trState)
      ) {
        return "partial_returned";
      }
      break;

    case BlState.cancelled:
      return "cancelled";

    case BlState.delivered:
      if (trState && trState === TransportState.ok) {
        return "delivered";
      }
      break;

    default:
      return "";
  }
};

export const validateSuggestionList = (suggestList) => {
  if (!suggestList || suggestList.length === 0) {
    return 0;
  }
  let _count = suggestList.length;
  suggestList.forEach((item) => {
    if (item.ship_method === "ktv" && item.ktv.length === 0) {
      _count -= 1;
    }
  });
  return _count;
};

export const mapSellerIdToText = (id, sellers) => {
  if (!id) {
    return "";
  }
  const sellersList = sellers || store.getState().admin.shared.sellers;
  if (sellersList && sellersList.length > 0) {
    const found = sellersList.find((x) => x.id === id);
    if (found) return found.name;
    else return id;
  } else {
    return id;
  }
};

export const mapWmsStatusToText = (status) => {
  switch (status) {
    case "init":
      return "Chờ soạn hàng";
    case "picked":
      return "Đang đóng gói";
    case "packed":
      return "Đã đóng gói";
    default:
      return status;
  }
};

export const getEditableShipDateOptions = (options) => {
  const editShipDateOptions = options
    .filter((x) => x.type === "edit_ship_date")
    .map((x) => ({
      label: x.name,
      value: x.id,
    }));

  return editShipDateOptions;
};
