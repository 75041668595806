import React from "react";
import Button from "@material-ui/core/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Input } from "@material-ui/core";

class ConfirmBlCancelDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: false,
      cancelReason: "",
    };
  }

  cancelBl = async () => {
    const { updating, cancelReason } = this.state;
    const { id, cancelBl } = this.props;
    if (!updating) {
      this.setState({
        updating: true,
      });
      await cancelBl(id, cancelReason);
      this.setState({
        updating: false,
      });
    }
  };

  toggleDialog = () => {
    const { updating } = this.state;
    if (updating) {
      this.setState({
        updating: false,
      });
    }
  };

  render() {
    const { updating } = this.state;
    const { open } = this.props;
    return (
      <Dialog open={open} onClose={() => this.props.toggleDialog()}>
        <DialogTitle>Hủy vận đơn</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Vận đơn đã bị hủy sẽ không thể thao tác được nữa. Bạn muốn xác nhận
            hủy vận đơn? Vui lòng nhập vào lí do.
          </DialogContentText>
          <Input
            fullWidth
            autoFocus={true}
            value={this.state.cancelReason}
            onChange={(e) => this.setState({ cancelReason: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.toggleDialog()}>Hủy</Button>
          <Button color="primary" onClick={() => this.cancelBl()}>
            {updating && <CircularProgress size={20} />} &nbsp; Đồng ý
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ConfirmBlCancelDialog;
