import React from "react";
import Select from "react-select";

const regions = [
  { label: "Miền Bắc", value: "northern" },
  { label: "Miền Trung", value: "central" },
  { label: "Miền Nam", value: "southern" },
];

class RegionSelection extends React.Component {
  render() {
    const {
      value,
      onChange,
      multi = false,
      clearable = true,
      className,
      wrapperStyle,
    } = this.props;
    return (
      <Select
        value={value}
        onChange={(v) => onChange(v ? v.value : "")}
        options={regions}
        placeholder="Chọn miền"
        clearable={clearable}
        style={{ height: 40, width: "100%" }}
        className={className}
        multi={multi}
        menuContainerStyle={wrapperStyle}
      />
    );
  }
}

export default RegionSelection;
