import { getRejectReasonsByType, getRetryShippingDetailReport } from "@shared";
import moment from "moment";
import { API_URL } from "@config";
import qs from "qs";

export const changeFilter = (data) => ({
  type: "SHIP_DATE_DETAIL_REPORT_CHANGE_FILTER",
  payload: data,
});

export const changePagination =
  (pagination, update = true) =>
  async (dispatch) => {
    dispatch(setPagination(pagination));
    if (update) {
      dispatch(onSearch(false));
    }
  };

export const setPagination = (pagination) => ({
  type: "SHIP_DATE_DETAIL_REPORT_CHANGE_PAGINATION",
  payload: pagination,
});

export const onSearch =
  (reset = true) =>
  async (dispatch, getState) => {
    dispatch(changeLoading(true));
    const state = getState().admin.ShipDateDetailReport;
    if (reset) {
      dispatch(
        setPagination(
          {
            ...state.pagination,
            offset: 0,
          },
          false
        )
      );
    }
    const params = {
      ...state.filter,
      ...state.pagination,
    };
    params.start_date = moment(state.filter.start_date).format("YYYY-MM-DD");
    params.offset = reset ? 0 : params.offset;
    const response = await getRetryShippingDetailReport(params);
    dispatch(changeLoading(false));
    if (response.status === 200) {
      const result = response.data;
      dispatch(setData(result.data));
    }
  };

export const changeLoading = (state) => ({
  type: "SHIP_DATE_DETAIL_REPORT_CHANGE_LOADING",
  payload: state,
});

export const getReasons = (type) => async (dispatch) => {
  const response = await getRejectReasonsByType(type);
  if (response.status === 200) {
    const result = response.data;
    dispatch(setReasons(result.reasons));
  }
};

export const setReasons = (state) => ({
  type: "SHIP_DATE_DETAIL_REPORT_SET_REASONS",
  payload: state,
});

export const setData = (data) => ({
  type: "SHIP_DATE_DETAIL_REPORT_SET_DATA",
  payload: data,
});

export const onExcel = () => async (dispatch, getState) => {
  const state = getState().admin.ShipDateSummaryReport;
  let params = {
    ...state.filter,
  };
  params.start_date = moment(state.filter.start_date).format("YYYY-MM-DD");
  params.limit = -1;
  window.location.href = `${API_URL}/admin/report/retry_shipping_detail/excel?${qs.stringify(
    params
  )}`;
};
