import _ from "lodash";
import moment from "moment";

const _initialState = {
  filter: {
    from_hub: "",
    to_hub: "",
    start_date: moment().subtract(3, "months").startOf("month").toDate(),
    frequency: 3,
    group_type: "month",
    reason_id: "",
  },
  loading: false,
  data: [],
  summary: {
    total_bl: [],
    total_percent: [],
  },
  reasons: [],
};

const ShipDateSummaryReport = (state = _initialState, action) => {
  switch (action.type) {
    case "SHIP_DATE_SUMMARY_REPORT_CHANGE_FILTER":
      return {
        ...state,
        filter: action.payload,
      };
    case "SHIP_DATE_SUMMARY_REPORT_CHANGE_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "SHIP_DATE_SUMMARY_REPORT_SET_REASONS":
      return {
        ...state,
        reasons: action.payload.map((x, id) => ({
          label: x.name,
          value: x.id,
        })),
      };
    case "SHIP_DATE_SUMMARY_REPORT_SET_DATA":
      return {
        ...state,
        data: action.payload.results,
        summary: action.payload.summary,
      };
    default:
      return state;
  }
};

export default ShipDateSummaryReport;
