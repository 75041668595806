import React from "react";
import Drawer from "@material-ui/core/Drawer";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { toggleSidebar } from "..";
import { isMaxTrustUser } from "@utils";

const styles = (theme) => ({
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    height: 56,
    [theme.breakpoints.up("sm")]: {
      height: 64,
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

function NavItem({
  link,
  icon,
  iconType = "material-icons",
  label,
  toggleSidebar,
  children,
}) {
  return (
    <ListItem component={Link} to={link} onClick={toggleSidebar} button>
      <ListItemIcon>
        <i className={iconType}>{icon}</i>
      </ListItemIcon>
      <ListItemText primary={label} />
      {children}
    </ListItem>
  );
}
const mapDispatchToPropsNavItem = (dispatch) => ({
  toggleSidebar: () => dispatch(toggleSidebar()),
});

const CustomListItem = connect(null, mapDispatchToPropsNavItem)(NavItem);

const BE_DOMAIN = window.API_URL.slice(0, -4); //remove '/api' endpoint

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openSubMenu: true,
    };
  }

  toggleSubMenu = () =>
    this.setState({
      openSubMenu: !this.state.openSubMenu,
    });

  render() {
    const { openSubMenu } = this.state;
    const { sidebar, user, toggleSidebar, classes } = this.props;
    const isMaxTrust = isMaxTrustUser();
    return (
      <Drawer open={sidebar.open} onClose={toggleSidebar}>
        <div className={classes.drawerHeader}>
          <IconButton onClick={toggleSidebar}>
            <i className="material-icons">chevron_left</i>
          </IconButton>
        </div>
        <List>
          <CustomListItem
            link="/dashboard/operate"
            label="Dashboard"
            icon="assessment"
          />
          <CustomListItem
            link="/?request_type=sale_order,asia_order,wh_transfer"
            label="Quản lý vận đơn"
            icon="people"
          />
          {!isMaxTrust && (
            <CustomListItem
              link="/bls-group"
              label="Gộp vận đơn"
              icon="library_add"
            />
          )}
          <CustomListItem link="/report" label="Báo cáo" icon="assessment" />

          <ListItem button onClick={this.toggleSubMenu}>
            <ListItemIcon>
              <i className="material-icons">insert_drive_file</i>
            </ListItemIcon>
            <ListItemText primary="Xuất danh sách" />
            {openSubMenu ? (
              <i className="material-icons">expand_more</i>
            ) : (
              <i className="material-icons">expand_less</i>
            )}
          </ListItem>
          <Collapse in={openSubMenu} unmountOnExit>
            <ListItem
              button
              className={classes.nested}
              component="a"
              href={`${BE_DOMAIN}/transports/export`}
            >
              <ListItemIcon>
                <i className="material-icons">people</i>
              </ListItemIcon>
              <ListItemText primary="Xuất danh sách bàn giao" />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              component="a"
              href={`${BE_DOMAIN}/transports/recheck`}
            >
              <ListItemIcon>
                <i className="material-icons">compare_arrows</i>
              </ListItemIcon>
              <ListItemText primary="Xuất danh sách đối soát" />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              component="a"
              href={`${BE_DOMAIN}/transports/export-request`}
            >
              <ListItemIcon>
                <i className="material-icons">insert_comment</i>
              </ListItemIcon>
              <ListItemText primary="Xuất danh sách theo yêu cầu" />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              component="a"
              href={`${BE_DOMAIN}/transports/estimate-fee-report`}
            >
              <ListItemIcon>
                <i className="material-icons">attach_money</i>
              </ListItemIcon>
              <ListItemText primary="Báo cáo chi phí vận chuyển" />
            </ListItem>
          </Collapse>
          <ListItem button component="a" href={`${BE_DOMAIN}/bls/label`}>
            <ListItemIcon>
              <i className="material-icons">print</i>
            </ListItemIcon>
            <ListItemText primary="In nhãn vận đơn" />
          </ListItem>
          {/* Duyệt đơn lỗi: Chỉ có super_admin có quyền */}
          {!isMaxTrust && (
            <ListItem
              button
              component="a"
              href={`${BE_DOMAIN}/message/approve-error-msg`}
            >
              <ListItemIcon>
                <i className="material-icons">done_all</i>
              </ListItemIcon>
              <ListItemText primary="Duyệt đơn lỗi" />
            </ListItem>
          )}

          {user.canAccessBusLineManagement && (
            <CustomListItem
              link="/admin/bus-line"
              label="Quản lý nhà xe"
              iconType="fas fa-truck-moving"
            />
          )}
          <CustomListItem
            link="/versions"
            label="Hướng dẫn sử dụng"
            icon="library_books"
          />
        </List>
      </Drawer>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  toggleSidebar: () => dispatch(toggleSidebar()),
});

const mapStateToProps = (state) => ({
  sidebar: state.admin.shared.sidebar,
  user: state.admin.shared.user,
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
