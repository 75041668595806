import _ from "lodash";
import moment from "moment";

const _initialState = {
  filter: {
    from_hub: "",
    to_hub: "",
    start_date: moment().subtract(3, "months").startOf("month").toDate(),
    frequency: 3,
    group_type: "month",
    reason_id: "",
  },
  loading: false,
  data: [],
  summary: {
    max_times: 0,
  },
  pagination: {
    limit: 10,
    offset: 0,
    totalRecords: 200,
  },
  reasons: [],
};

const ShipDateDetailReport = (state = _initialState, action) => {
  switch (action.type) {
    case "SHIP_DATE_DETAIL_REPORT_CHANGE_FILTER":
      return {
        ...state,
        filter: action.payload,
      };

    case "SHIP_DATE_DETAIL_REPORT_CHANGE_PAGINATION":
      return {
        ...state,
        pagination: action.payload,
      };
    case "SHIP_DATE_DETAIL_REPORT_CHANGE_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "SHIP_DATE_DETAIL_REPORT_SET_REASONS":
      return {
        ...state,
        reasons: action.payload,
      };
    case "SHIP_DATE_DETAIL_REPORT_SET_DATA":
      let newData = action.payload.results;
      const summary = action.payload.summary;
      newData = newData.map((x, id) => {
        return {
          ...x,
          ...summary.reason[id],
        };
      });
      return {
        ...state,
        data: newData,
        summary: action.payload.summary,
        pagination: {
          ...state.pagination,
          totalRecords: summary.total_record,
        },
      };
    default:
      return state;
  }
};

export default ShipDateDetailReport;
