import apiService from "./base";

export const getSellers = (params) => {
  return apiService({
    url: "/admin/sellers",
    params: params,
  });
};

export const getSaleChannelsApi = (params) => {
  return apiService({
    url: "/admin/sale_channels",
    params: params,
  });
};

export const getUserProfile = (accessToken) => {
  return apiService({
    url: "/admin/user/profile",
    params: { access_token: accessToken },
  });
};

export const getHubListApi = () => {
  return apiService({
    url: "/admin/hub",
    method: "get",
  });
};

export const getWebSettingsApi = () => {
  return apiService({
    url: "/admin/settings",
    method: "get",
  });
};

export const getVersionsApi = () => {
  return apiService({
    url: "/admin/versions",
    method: "get",
  });
};

export const dumpCommonApi = null;
