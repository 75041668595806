import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
} from "@material-ui/core";
import { validateUtils } from "../../../../utils";
import {
  ProvinceSelector,
  DistrictSelector,
  WardSelector,
  GoogleMapLocationPicker,
} from "../../../../shared";
import AddressImg from "@images/address.png";

const styles = (theme) => ({
  contentOverflow: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
    overflowY: "visible",
    paddingTop: 0,
  },
  content: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
    overflowY: "auto",
    paddingTop: 0,
  },
  labelInput: {
    color: "rgba(0, 0, 0, 0.76)",
    margin: 0,
    fontSize: "0.9rem",
    fontWeight: 600,
    lineHeight: "1.5em",
  },
  row: {
    width: "100%",
    margin: "-14px",
  },
  action: {
    display: "flex",
    justifyContent: "flex-start",
    marginLeft: 16,
  },
  left: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
    paddingRight: 16,
  },
  right: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  button: {
    marginTop: 4,
  },
  paperOverflow: {
    overflowY: "visible",
    borderRadius: "1rem",
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
  },
  paper: {
    overflowY: "auto",
    borderRadius: "1rem",
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
  },
  title: {
    fontFamily: "Noto Sans, sans-serif",
    flex: 1,
  },
});
const defaultInfo = {
  name: "",
  street: "",
  phone: "",
  province: "",
  district: "",
  ward: "",
  googleAddress: "",
  coordinatorAddress: "",
  position: null,
};

class DialogEditToAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultInfo,
      id: null,
      googleAddress: "",
      googleResponse: null,
      position: { lat: 0, lng: 0 },
      type: "ktv",
      useGoogleMap: false,
      hasError: [],
    };
  }

  handleOnEnter = () => {
    if (this.state.id !== this.props.currentKTV.value) {
      this.setState({ id: this.props.currentKTV.value });
      this.mapKTVInfo();
    }
  };

  mapCustomerInfo = () => {
    const { bl } = this.props;
    const address = bl.toAddr;
    this.setState({
      province: address.area.code ? address.area.code.slice(0, 2) : "",
      district:
        address.area.code && address.area.code.length >= 4
          ? address.area.code.slice(0, 4)
          : "",
      ward:
        address.area.code && address.area.code.length >= 4
          ? address.area.code
          : "",
      name: address.displayName,
      street: address.street,
      phone: address.phone,
      coordinatorAddress: address.fullAddress,
      googleAddress: address.fullAddress,
      position: null,
    });
  };

  mapKTVInfo = () => {
    const { currentKTV } = this.props;
    this.setState({
      name: currentKTV.name,
      street: currentKTV.fullAddress
        ? currentKTV.fullAddress.split("|")[0]
        : "",
      phone: currentKTV.phone,
      province:
        currentKTV.areaCode && currentKTV.areaCode.length >= 2
          ? currentKTV.areaCode.slice(0, 2)
          : "",
      district:
        currentKTV.areaCode && currentKTV.areaCode.length >= 4
          ? currentKTV.areaCode.slice(0, 4)
          : "",
      ward:
        currentKTV.areaCode && currentKTV.areaCode.length >= 6
          ? currentKTV.areaCode
          : "",
      coordinatorAddress: currentKTV.fullAddress,
      googleAddress: currentKTV.fullAddress,
      position: null,
    });
  };

  changeAddressType = (value) => {
    const { bl } = this.props;
    const { hasError } = this.state;
    this.setState(
      {
        type: value,
      },
      () => {
        if (value === "new") {
          this.setState({
            ...this.state,
            ...defaultInfo,
          });
        } else if (value === "customer") {
          this.mapCustomerInfo(bl.toAddr);
        } else {
          this.mapKTVInfo();
        }
        hasError.forEach((x) => {
          let ele = document.getElementById(x.id);
          if (ele) ele.innerHTML = "";
        });
      }
    );
  };

  validate = () => {
    const { useGoogleMap } = this.state;
    let err = [];
    const rules = {
      name: {
        required: true,
      },
      phone: {
        required: true,
        regex: /^(0|84)\d{9,10}$/g,
      },
      street: {
        required: !useGoogleMap,
      },
      province: {
        required: !useGoogleMap,
      },
      district: {
        required: !useGoogleMap,
      },
      coordinatorAddress: {
        required: useGoogleMap,
      },
      googleAddress: {
        required: useGoogleMap,
      },
    };
    const message = {
      name: {
        required: { id: "name", msg: "Bạn chưa nhập tên khách hàng" },
      },
      phone: {
        required: { id: "phone", msg: "Bạn chưa nhập số điện thoại" },
        regex: { id: "phone", msg: "Số điện thoại không hợp lệ" },
      },
      street: {
        required: { id: "fullAddress", msg: "Bạn chưa nhập số nhà, tên đường" },
      },
      coordinatorAddress: {
        required: { id: "coordinatorAddress", msg: "Bạn chưa nhập địa chỉ" },
      },
      googleAddress: {
        required: { id: "googleAddress", msg: "Bạn chưa chọn địa chỉ" },
      },
      province: {
        required: { id: "province", msg: "Bạn chưa chọn tỉnh/thành" },
      },
      district: {
        required: { id: "district", msg: "Bạn chưa chọn quận/huyện" },
      },
    };
    err = validateUtils(this.state, rules, message);
    Object.keys(rules).forEach((x) => {
      let ele = document.getElementById(x);
      if (ele) ele.innerHTML = "";
    });
    if (err.length > 0) {
      err.forEach((x) => {
        let ele = document.getElementById(x.id);
        if (ele)
          ele.innerHTML = `<span style="color: red; font-size:12px">${x.msg}</span>`;
      });
    }
    this.setState({ hasError: err });
    return err;
  };

  handleSave = () => {
    const err = this.validate();
    if (err.length === 0) this.save();
  };

  save = async () => {
    const {
      ward,
      district,
      name,
      street,
      type,
      useGoogleMap,
      phone,
      googleAddress,
      coordinatorAddress,
      googleResponse,
    } = this.state;
    const { onSave } = this.props;
    const areaCode = ward || district;
    const addrParts = await this.props.fetchArea(areaCode, true);
    const address = {
      hasChange: true,
      updateAddress: false,
      useGoogleMap: useGoogleMap,
      googleResponse: googleResponse,
      area_code: areaCode,
      name: name,
      street: useGoogleMap ? coordinatorAddress : street,
      phone: phone,
      type: type,
      label: useGoogleMap
        ? googleAddress
        : `${street ? street + ", " : ""}${ward ? addrParts.name + ", " : ""}${
            addrParts.province
          }, ${addrParts.district}`,
    };
    onSave(address);
  };

  GoogleMapMode = () => {
    const { position, googleAddress, coordinatorAddress } = this.state;
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Grid item md={12}>
          <Grid container alignItems="center">
            <Grid item md={2} lg={2}>
              <strong className={classes.labelInput}>
                <span className="required" />
                Địa chỉ
              </strong>
            </Grid>
            <Grid item md={9} lg={9} className={classes.right}>
              <input
                type="text"
                onChange={(e) =>
                  this.setState({ coordinatorAddress: e.target.value })
                }
                value={coordinatorAddress}
              />
              <span id="coordinatorAddress" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <Grid container>
            <GoogleMapLocationPicker
              labelSize={2}
              address={googleAddress}
              position={position}
              onChange={(e) => this.setState(e)}
              onCopyAddress={() =>
                this.setState({ coordinatorAddress: googleAddress })
              }
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  ManualMode = () => {
    const { province, district, ward, street } = this.state;
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Grid item md={12}>
          <Grid container alignItems="center">
            <Grid item md={3} lg={3}>
              <strong className={classes.labelInput}>
                <span className="required" />
                Số nhà, đường
              </strong>
            </Grid>
            <Grid item md={9} lg={9} className={classes.right}>
              <input
                type="text"
                onChange={(e) => this.setState({ street: e.target.value })}
                value={street}
              />
              <span id="fullAddress" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <Grid container alignItems="center">
            <Grid item md={3} lg={3}>
              <strong className={classes.labelInput} style={{ fontSize: 14 }}>
                <span className="required" />
                Tỉnh / Thành phố
              </strong>
            </Grid>
            <Grid item md={9} lg={9} className={classes.right}>
              <ProvinceSelector
                province={province}
                changeProvince={(e, v) => this.setState({ province: e })}
                fetchArea={this.props.fetchArea}
              />
              <span id="province" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <Grid container alignItems="center">
            <Grid item md={3} lg={3}>
              <strong className={classes.labelInput} style={{ fontSize: 14 }}>
                <span className="required" />
                Quận/Huyện
              </strong>
            </Grid>
            <Grid item md={9} lg={9} className={classes.right}>
              <DistrictSelector
                province={province}
                district={district}
                changeDistrict={(e, v) => this.setState({ district: e })}
                fetchArea={this.props.fetchArea}
              />
              <span id="district" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <Grid container alignItems="center">
            <Grid item md={3} lg={3}>
              <strong className={classes.labelInput} style={{ fontSize: 14 }}>
                Phường/Xã
              </strong>
            </Grid>
            <Grid item md={9} lg={9} className={classes.right}>
              <WardSelector
                district={district}
                ward={ward}
                changeWard={(e, v) => this.setState({ ward: e })}
                fetchArea={this.props.fetchArea}
              />
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  render() {
    const { name, phone, useGoogleMap, type } = this.state;
    const { classes, open, bl, onClose } = this.props;
    return (
      <div>
        <Dialog
          maxWidth="sm"
          id="DialogEditTo"
          open={open}
          onClose={onClose}
          onEntered={this.handleOnEnter}
          scroll="paper"
          classes={{
            paper: useGoogleMap ? classes.paper : classes.paperOverflow,
          }}
        >
          <DialogTitle style={{ padding: "1rem 1rem 0.3rem 1rem" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={AddressImg} height={30} width={30} />
              &nbsp;
              <div className={classes.title}>Cập nhật thông tin người nhận</div>
              {/* {bl.use_google_map && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={useGoogleMap}
                      onChange={e =>
                        this.setState({ useGoogleMap: e.target.checked })
                      }
                    />
                  }
                  label="Google Map"
                />
              )} */}
            </div>
          </DialogTitle>

          <DialogContent
            className={useGoogleMap ? classes.content : classes.contentOverflow}
          >
            <br />
            <Grid container>
              <Grid item md={12} style={{ padding: "0px 8px" }}>
                <Grid container alignItems="center">
                  <Grid
                    item
                    md={useGoogleMap ? 2 : 3}
                    lg={useGoogleMap ? 2 : 3}
                  >
                    <strong className={classes.labelInput}>
                      <span className="required" />
                      Người nhận
                    </strong>
                  </Grid>
                  <Grid item md={9} lg={9} className={classes.right}>
                    <RadioGroup
                      aria-label="destination"
                      name="destination"
                      style={{ flexDirection: "row" }}
                      value={type}
                      className={this.props.classes.radio}
                      onChange={(e, v) => this.changeAddressType(v)}
                    >
                      <FormControlLabel
                        value="ktv"
                        control={<Radio />}
                        label="KTV"
                      />
                      <FormControlLabel
                        value="customer"
                        control={<Radio />}
                        label="Khách hàng"
                      />
                      <FormControlLabel
                        value="new"
                        control={<Radio />}
                        label="Địa chỉ mới"
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12}>
                <Grid container alignItems="center">
                  <Grid
                    item
                    md={useGoogleMap ? 2 : 3}
                    lg={useGoogleMap ? 2 : 3}
                  >
                    <strong className={classes.labelInput}>
                      <span className="required" />
                      Tên người nhận
                    </strong>
                  </Grid>
                  <Grid item md={9} lg={9} className={classes.right}>
                    <input
                      type="text"
                      onChange={(e) => this.setState({ name: e.target.value })}
                      value={name}
                    />
                    <span id="name" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12}>
                <Grid container alignItems="center">
                  <Grid
                    item
                    md={useGoogleMap ? 2 : 3}
                    lg={useGoogleMap ? 2 : 3}
                  >
                    <strong className={classes.labelInput}>
                      <span className="required" />
                      SĐT
                    </strong>
                  </Grid>
                  <Grid item md={9} lg={9} className={classes.right}>
                    <input
                      type="text"
                      onChange={(e) => this.setState({ phone: e.target.value })}
                      value={phone}
                    />
                    <span id="phone" />
                  </Grid>
                </Grid>
              </Grid>
              {useGoogleMap ? this.GoogleMapMode() : this.ManualMode()}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this.handleSave}>
              Cập nhật
            </Button>
            <Button onClick={onClose} color="primary">
              Đóng
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(DialogEditToAddress);
