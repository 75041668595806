import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import _ from "lodash";
import { mapShipMethodToText } from "../../../utils";

const styles = (theme) => ({
  text: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
    color: "#797979",
    marginBottom: 8,
  },
  handler: {
    cursor: "pointer",
    color: "#365899",
  },
  cell: {
    color: "#797979",
    padding: "4px 16px 4px 16px",
    fontSize: 14,
  },
  header: {
    minWidth: 100,
    fontSize: 14,
    padding: "4px 16px 4px 16px",
  },
  table: {
    margin: 8,
  },
});

class TransportPackages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: true,
    };
  }

  toggleCollapse = () =>
    this.setState({
      collapse: !this.state.collapse,
    });

  renderStatusUrl = (url, classes) => {
    return url ? (
      <a
        href={url}
        target="_blank"
        className={classes.handler}
        style={{ display: "inline" }}
        rel="noreferrer"
      >
        <strong> Tra cứu trạng thái</strong>
      </a>
    ) : (
      ""
    );
  };

  render() {
    const { collapse } = this.state;
    const { transport, classes } = this.props;
    const packageList = _.get(transport, "package_info.package_info", []);
    if ((packageList || []).length === 0) {
      return (
        <span>
          {mapShipMethodToText(transport.shipMethod)}
          {this.renderStatusUrl(transport.check_status_3pl_url, classes)}
        </span>
      );
    }

    return (
      <span className={classes.text}>
        <strong>
          {mapShipMethodToText(transport.shipMethod)}&nbsp;&nbsp;
          <span onClick={this.toggleCollapse} className={classes.handler}>
            Xem chi tiết từng kiện
          </span>
        </strong>
        <Collapse in={collapse} unmountOnExit>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.header}>Mã kiện hàng</TableCell>
                <TableCell className={classes.header}>Mã vận chuyển</TableCell>
                <TableCell className={classes.header}>Nhà vận chuyển</TableCell>
                <TableCell className={classes.header}>Tra cứu</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {packageList.map((pk, idx) => (
                <TableRow key={idx}>
                  <TableCell className={classes.cell}>
                    {pk.package_no}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {pk.tracking_no}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {pk.transporter_name}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {this.renderStatusUrl(pk.check_status_3pl_url, classes)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Collapse>
      </span>
    );
  }
}

export default withStyles(styles)(TransportPackages);
