import React from "react";
import Select from "react-select";
import memoize from "memoize-one";
const mapToOptions = memoize((data) => {
  return data.map((x) => ({
    label: x.name,
    value: x.value,
  }));
});

const ThreePlsCategorySelection = (props) => {
  const handleChange = (e) => {
    if (e && e.value) {
      props.onChange(props.data.find((x) => x.value === e.value));
    } else {
      props.onChange();
    }
  };

  return (
    <Select
      {...props}
      options={mapToOptions(props.data)}
      onChange={handleChange}
      clearable={false}
      placeholder="Chọn nhóm hàng..."
    />
  );
};

export default ThreePlsCategorySelection;
