import React from "react";

const LastTransToAddr = ({ transitHub }) => {
  return transitHub.id ? (
    transitHub.hub &&
    transitHub.hub.asia_branch &&
    transitHub.hub.asia_branch.code ? (
      <div>
        <strong>{transitHub.hub.asia_branch.code}</strong>
        <br />
        <span>{transitHub.hub.asia_branch.address}</span>
      </div>
    ) : (
      <span>{transitHub.displayName}</span>
    )
  ) : (
    ""
  );
};

export default LastTransToAddr;
