import React from "react";
import classNames from "classnames";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import { firstRowOnPage, lastRowOnPage } from "@devexpress/dx-grid-core";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
  pagination: {
    float: "right",
    margin: 0,
  },
  rowsLabel: {
    ...theme.typography.caption,
    paddingRight: theme.spacing(5),
    lineHeight: `${theme.spacing(5)}px`,
  },
  button: {
    minWidth: theme.spacing(2),
    height: theme.spacing(5),
    fontWeight: "normal",
  },
  activeButton: {
    fontWeight: "bold",
    backgroundColor: "#eaeaea",
  },
  arrowButton: {
    display: "inline-block",
    verticalAlign: "middle",
  },
  prev: {
    marginRight: 0,
  },
  next: {
    marginLeft: 0,
  },
  "@media(max-width: 768px)": {
    button: {
      display: "none",
    },
    prev: {
      marginRight: theme.spacing(1),
    },
    next: {
      marginLeft: theme.spacing(1),
    },
    rowsLabel: {
      paddingRight: theme.spacing(2),
    },
  },
});

const PageButton = ({ text, isActive, isDisabled, classes, onClick }) => {
  const buttonClasses = classNames({
    [classes.button]: true,
    [classes.activeButton]: isActive,
  });

  return (
    <Button className={buttonClasses} disabled={isDisabled} onClick={onClick}>
      {text}
    </Button>
  );
};

const ellipsisSymbol = "\u2026";

const calculateStartPage = (currentPage, maxButtonCount, totalPageCount) =>
  Math.max(
    Math.min(
      currentPage - Math.floor(maxButtonCount / 2, 10),
      totalPageCount - maxButtonCount + 1
    ),
    1
  );

const renderPageButtons = (
  currentPage,
  totalPageCount,
  classes,
  onCurrentPageChange
) => {
  const pageButtons = [];
  const maxButtonCount = 3;
  let startPage = 1;
  let endPage = totalPageCount || 1;

  if (maxButtonCount < totalPageCount) {
    startPage = calculateStartPage(
      currentPage + 1,
      maxButtonCount,
      totalPageCount
    );
    endPage = startPage + maxButtonCount - 1;
  }
  if (startPage > 1) {
    pageButtons.push(
      <PageButton
        key={1}
        text={String(1)}
        classes={classes}
        onClick={() => onCurrentPageChange(0)}
      />
    );

    if (startPage > 2) {
      pageButtons.push(
        <PageButton
          key="ellipsisStart"
          text={ellipsisSymbol}
          classes={classes}
          isDisabled
        />
      );
    }
  }

  for (let page = startPage; page <= endPage; page += 1) {
    pageButtons.push(
      <PageButton
        key={page}
        text={String(page)}
        isActive={page === currentPage + 1}
        classes={classes}
        onClick={() => onCurrentPageChange(page - 1)}
        isDisabled={startPage === endPage}
      />
    );
  }

  if (endPage < totalPageCount) {
    if (endPage < totalPageCount - 1) {
      pageButtons.push(
        <PageButton
          key="ellipsisEnd"
          text={ellipsisSymbol}
          classes={classes}
          isDisabled
        />
      );
    }

    pageButtons.push(
      <PageButton
        key={totalPageCount}
        text={String(totalPageCount)}
        classes={classes}
        onClick={() => onCurrentPageChange(totalPageCount - 1)}
      />
    );
  }

  return pageButtons;
};

const Pagination = ({ filter, onCurrentPageChange, classes }) => {
  const from = firstRowOnPage(
    filter.currentPage,
    filter.pageSize,
    filter.totalCount
  );
  const to = lastRowOnPage(
    filter.currentPage,
    filter.pageSize,
    filter.totalCount
  );
  let totalPages = Math.ceil(filter.totalCount / filter.pageSize);
  return (
    <div className={classes.pagination}>
      <span className={classes.rowsLabel}>
        {from} - {to} trên {filter.totalCount}
      </span>
      <IconButton
        className={classNames(classes.arrowButton, classes.prev)}
        disabled={filter.currentPage === 0}
        onClick={() =>
          filter.currentPage > 0 && onCurrentPageChange(filter.currentPage - 1)
        }
      >
        <i className="material-icons">chevron_left</i>
      </IconButton>
      {renderPageButtons(
        filter.currentPage,
        totalPages,
        classes,
        onCurrentPageChange
      )}
      <IconButton
        className={classNames(classes.arrowButton, classes.next)}
        disabled={
          filter.currentPage === totalPages - 1 || filter.totalCount === 0
        }
        onClick={() =>
          filter.currentPage < totalPages - 1 &&
          onCurrentPageChange(filter.currentPage + 1)
        }
      >
        <i className="material-icons">chevron_right</i>
      </IconButton>
    </div>
  );
};

export default withStyles(styles)(Pagination);
