import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { RequestType } from "../../../../../../models";
import { CreateTransportContainer } from "../../../containers";
import { BlLines } from "../..";

const styles = (theme) => ({
  container: {
    padding: "16px 8px",
    fontSize: 13,
    lineHeight: "1.6",
    fontWeight: 400,
    fontFamily: "Noto Sans, sans-serif",
    wordWrap: "break-word",
  },
  stepLabel: {
    color: "#fff",
    fontSize: 12,
    fontWeight: 800,
    padding: 4,
    borderRadius: 4,
  },
  priceCell: {
    color: "#797979",
    fontWeight: 500,
    textAlign: "right",
    paddingTop: "4px !important",
    paddingBottom: "4px !important",
  },
  codTitle: {
    textAlign: "left",
    fontWeight: "bold",
    paddingTop: "4px !important",
    paddingBottom: "4px !important",
    paddingLeft: "8px !important",
    whiteSpace: "nowrap",
  },
  totalTable: {
    width: 400,
  },
  totalRow: {
    height: 32,
  },
});

const RowDetailWithForm = ({ row, user, classes }) => {
  const cod = {
    cod: row.formattedCOD,
    paidCOD: row.formattedPaidCOD,
    grandTotal: row.formattedGrandTotal,
    prePaid: row.formattedPrePaid,
  };

  return (
    <Grid container className={classes.container}>
      <Grid
        item
        md={3}
        style={{
          borderRight: "1px solid #90898914",
          padding: "1rem 2rem 1rem 0rem",
        }}
      >
        {row.requestType !== RequestType.promotion && (
          <BlLines
            blLines={row.blLines}
            cod={cod}
            requestType={row.requestType}
          />
        )}
        <br />
        <p>
          <strong>Ghi chú:</strong> {row.note}
        </p>
      </Grid>
      {row.ableToCreateTransport &&
        (row.direction !== "returning" && row.seller_id === 1 ? (
          <Grid item md={5} style={{ paddingLeft: "2rem" }}>
            <CreateTransportContainer bl={row} />
          </Grid>
        ) : (
          <div style={{ margin: 10 }}>
            Vui lòng vào chi tiết vận đơn để tạo lộ trình
          </div>
        ))}
    </Grid>
  );
};

export default withStyles(styles)(RowDetailWithForm);
