/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { ListItem, List } from "@material-ui/core";
import InputNumber from "rc-input-number";
import _ from "lodash";

function AdidiServiceQuantitySelection({ services, onChange }) {
  const [flatServices, setFlatServices] = useState([]);
  useEffect(() => {
    if (services) {
      const list = getSelectedServices(services);
      setFlatServices(list);
    } else {
      setFlatServices([]);
    }
  }, [services]);

  const getSelectedServices = (tree, list = []) => {
    tree.forEach((x) => {
      if (x.children && x.children.length > 0) {
        getSelectedServices(x.children, list);
      } else if (x.checked) {
        list.push(x);
      }
    });
    return list;
  };

  const handleChangeQuantity = (value, node) => {
    let paths = node.path;
    let newServices = _.cloneDeep(services);
    let selected = "newServices";
    for (let i = 0; i < paths.length - 1; i++) {
      selected += "[" + paths[i] + "].children";
    }
    selected += "[" + paths[paths.length - 1] + "].quantity = " + value;
    eval(selected);
    onChange(newServices);
  };

  return (
    <List className={`styledScrollbar`}>
      {flatServices.map((k, idx) => (
        <ListItem key={idx} role={undefined} dense>
          <span style={{ maxWidth: "70%" }}>{k.label}</span>
          <InputNumber
            min={1}
            style={{ width: 100 }}
            value={k.quantity}
            onChange={(e) => handleChangeQuantity(e, k)}
          />
        </ListItem>
      ))}
    </List>
  );
}

export default AdidiServiceQuantitySelection;
