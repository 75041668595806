import _ from "lodash";
import qs from "qs";

export const paramBuilder = (currentFilter) => {
  /**
   * Build url fetch bl list dựa theo filters của user
   * @type {string}
   */
  //switch key from camelCase to snake_case and replace key name to match api
  let minifiedFilter = Object.assign(
    {},
    ...Object.keys(currentFilter).map((key) => {
      switch (key) {
        case "sorting":
          return { order: currentFilter[key].direction };
        case "id":
          return { bl_id: currentFilter[key] };
        case "status":
          return { quick_filter: currentFilter[key] };
        case "shipMethod":
          return { last_transport_ship_method: currentFilter[key] };
        case "fromHub":
          return { from_hub_id: currentFilter[key] };
        case "toHub":
          return { to_hub_id: currentFilter[key] };
        case "state":
          return { bl_state: currentFilter[key] };
        case "quickFilter":
          return { tab: currentFilter[key] };
        case "pageSize":
          return { limit: currentFilter[key] };
        case "currentPage":
          return {
            offset: currentFilter[key] * currentFilter.pageSize,
          };
        case "deliveryTypes":
          return {
            delivery_types: currentFilter[key]
              ? currentFilter[key].map((x) => x.value.val).toString()
              : "",
          };
        default:
          return {
            [_.snakeCase(key)]: currentFilter[key],
          };
      }
    })
  );
  minifiedFilter.field =
    currentFilter.sorting.columnName === "id"
      ? "bl_id"
      : _.snakeCase(currentFilter.sorting.columnName);
  minifiedFilter.order = currentFilter.sorting.direction;

  //remove unecessary filter
  minifiedFilter = _.omit(minifiedFilter, [
    "expanded_rows",
    "total_count",
    "current_page",
    "allowed_page_sizes",
    "selection",
  ]);

  //remove null/empty string/undefined value
  minifiedFilter = _.omitBy(minifiedFilter, (i) => {
    return ["", null, undefined].includes(i);
  });
  return minifiedFilter;
};

export const queryStringBuilder = (currentFilter) => {
  const minifiedFilter = paramBuilder(currentFilter);
  //convert to query string
  let queryString = qs.stringify(minifiedFilter, { addQueryPrefix: true });

  return queryString;
};
