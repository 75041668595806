import React from "react";
import { connect } from "react-redux";
import {
  SortingState,
  PagingState,
  RowDetailState,
  DataTypeProvider,
  SelectionState,
  IntegratedSelection,
  IntegratedSorting,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail,
  PagingPanel,
  TableSelection,
} from "@devexpress/dx-react-grid-material-ui";
import {
  NoDataRow,
  BlStatusCell,
  ShipMethodCell,
  ShipToAddressCell,
  Pagination,
  PageSizeSelector,
  RowDetailWithForm,
} from "../components";
import * as TableHelper from "@utils";
import { TABLE_COLUMN } from "../../utils";
import {
  fetchBlList,
  setSelection,
  changePage,
  expandRows,
  setFilter,
  exportBlListExcel,
  changePageSize,
  setBlDetail,
  fetchBl3PlsList,
  printBlList,
} from "../actions";
import { withStyles } from "@material-ui/core/styles";
import {
  FromHubCell,
  LastTransToAddr,
  OtherSystemStatusCell,
  ClickableCell,
  BlNoteCell,
} from "../components/table/cell";
import {
  Button,
  CircularProgress,
  Checkbox,
  TableCell,
} from "@material-ui/core";
import { Zoom } from "@material-ui/core";
import CreateTransportContainer from "./CreateTransportContainer";
import { ASSETS_URL } from "@config";

const styles = (theme) => ({
  floatingBar: {
    position: "fixed",
    right: "45%",
    bottom: "0.7rem",
    background: "rgba(63, 81, 181, 0.83)",
    zIndex: 1,
    borderRadius: "1rem",
    boxShadow:
      "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)",
  },
  floatingButton: {
    padding: "0.6rem 0",
    borderRadius: "1rem",
    color: "white",
    width: "6rem",
  },
  striped: {
    "& tbody tr:nth-of-type(odd)": {
      backgroundColor: "#599be60f",
    },
    "& tbody tr td": {
      borderBottom: "1px solid #0000ff0a",
      paddingTop: 10,
      paddingBottom: 10,
      fontFamily: "Noto Sans, sans-serif",
      fontSize: 13,
      lineHeight: "1.7",
      letterSpacing: 0,
      wordBreak: "break-word",
    },
  },
  "@global": {
    ".bl-list": {
      background: "white",
    },
  },
});

const TableComponentBase = ({ classes, ...restProps }) => (
  <Table.Table {...restProps} className={classes.striped} />
);

const TableComponent = withStyles(styles, { name: "TableComponent" })(
  TableComponentBase
);

class TableContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      printLoad: false,
    };
  }

  componentDidMount() {
    this.props.fetchBlList();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.filter.keyword !== this.props.filter.keyword) {
      return false;
    }
    return true;
  }

  changeSort = (sorting) => {
    //Cho phép sort các cột mã vận đơn, mã phiếu xuất kho và ngày xuất kho
    if (
      [
        TABLE_COLUMN.id,
        TABLE_COLUMN.requestId,
        TABLE_COLUMN.blOrderId,
        TABLE_COLUMN.requestCreatedAt,
        TABLE_COLUMN.shipDate,
        TABLE_COLUMN.estimatedTime,
        TABLE_COLUMN.lastTransportCreatedAt,
        TABLE_COLUMN.status,
        TABLE_COLUMN.shipMethod,
        TABLE_COLUMN.toAddress,
        TABLE_COLUMN.fromHub,
        TABLE_COLUMN.addr,
        TABLE_COLUMN.addrDistrict,
        TABLE_COLUMN.addrWard,
      ].includes(sorting[0].columnName)
    ) {
      this.props.setFilter("sorting", sorting[0]);
      this.props.fetchBlList();
    }
  };

  changeSelection = (selection, rows) => {
    this.props.setSelection(
      selection,
      selection.map((x) => rows[x])
    );
  };

  changePage = (page) => {
    // Chuyển trang mới, sau đó load lại danh sách vận đơn
    this.props.changePage(page);
    this.props.fetchBlList();
  };

  onPageSizeChange = (pageSize) => {
    /**
     * Chuyển trang đầu, sau đó load lại danh sách vận đơn
     */
    this.props.changePageSize(pageSize);
    this.props.fetchBlList();
  };

  expandRows = (rows) => {
    // Xem chi tiết row(s) trong bảng
    this.props.expandRows(rows);
  };

  print = async () => {
    const { printBlList } = this.props;
    this.setState({ printLoad: true });
    const bls = await printBlList();
    this.setState({ printLoad: false });
    if (!bls || bls.length === 0) {
      alert("Có lỗi xảy ra. Không thể in được!");
      return;
    }
    const columns = [
      "STT",
      "Mã vận đơn",
      "Mã gói hàng",
      "Mã đơn hàng",
      "Trạng thái",
      "TT từ HT khác",
      "Đơn vị vận chuyển",
      "Kho xuất",
      "Nơi nhận",
      "Quận/Huyện",
      "Phường/Xã",
      "Hub cần thao tác",
      "Thời gian giao dự kiến",
      "Ngày tạo lộ trình",
      "Ngày xuất kho",
      "Ngày hẹn giao",
      "Ghi chú đơn",
      "Ghi chú của điều vận",
      "Công ty",
    ];
    const tableHead = columns.map((x, id) => `<th id=${id}>${x}</th>`).join("");
    const data = bls
      .map(
        (x, id) => `[
      "${id + 1}",
      "${x.id}",
      "${x.document_id}",
      "${x.blOrderId}",
      "${TableHelper.mapBlStatusText(x.status)}",
      "${TableHelper.mapWmsStatusToText(x.wmsStatus)}",
      "${TableHelper.mapShipMethodToText(x.lastTransport.shipMethod)} - ${
          x.lastTransport.shipMethod
            ? x.lastTransport.shipMethod === "ktv"
              ? `${
                  x.lastTransport.shipper.id
                } - ${x.lastTransport.shipper.name.replace(/\"/g, `\\"`)}`
              : x.lastTransport.plRef.replace(/\"/g, `\\"`)
            : ""
        }",
      "${
        x.fromAddr.hub &&
        x.fromAddr.hub.asia_branch &&
        x.fromAddr.hub.asia_branch.code
          ? `${
              x.fromAddr.hub.asia_branch.code
            } - ${x.fromAddr.hub.asia_branch.address.replace(/\"/g, `\\"`)}`
          : x.fromAddr.displayName.replace(/\"/g, `\\"`)
      }",
      "${x.toAddr.displayName.replace(
        /\"/g,
        `\\"`
      )}-${x.toAddr.fullAddress.replace(/\"/g, '\\"')}",
      "${
        x.transitHub.id
          ? x.transitHub.hub &&
            x.transitHub.hub.asia_branch &&
            x.transitHub.hub.asia_branch.code
            ? `${
                x.transitHub.hub.asia_branch.code
              } - ${x.transitHub.hub.asia_branch.address.replace(/\"/g, `\\"`)}`
            : `${x.transitHub.displayName.replace(/\"/g, `\\"`)}`
          : ""
      }",
      "${x.toAddr.area.province}",
      "${
        x.toAddr.areaCode && x.toAddr.areaCode.length === 6
          ? x.toAddr.area.name
          : ""
      }",
      "${x.lastTransport.edt || ""}",
      "${x.lastTransport.createdAt || ""}",
      "${x.requestCreatedAt}",
      "${x.shipDate}",
      "${x.note || "-"}",
      "${x.coordinator_note || "-"}",
      "${TableHelper.mapSellerIdToText(x.seller_id)}"
    ]`
      )
      .join(",");
    const html = `
    <!doctype html>
      <html>
        <meta charset="UTF-8">
        <link href="https://fonts.googleapis.com/css?family=Noto+Sans" rel="stylesheet">
        <title>Danh sách vận đơn ${new Date().toLocaleString()}</title>
        <style>
          body {
            font-family: Noto Sans;
            font-size: 13px;
          }

          table {
            border-collapse: collapse;
          }
          
          table td, table th {
            border: 1px solid #ddd;
            padding: 8px;
          }
          
          table tr:nth-child(even){background-color: #f2f2f2;}
              
          table th {
            padding-top: 12px;
            padding-bottom: 12px;
            text-align: left;
            background-color: lightblue;
            color: black;
          }
          .table {
            display: table;
          }
          .table-row {
            display: table-row;
          }
          .table-cell {
            display: table-cell;
            padding: 10px;
            border: 1px solid black;
          }
          .table-row:nth-child(even) {
            background: lightblue;
          }
        </style>
        <body>
        <div id="printList">
          <div class="heading">
            <h2>Danh sách vận đơn ${new Date().toLocaleString()}
          </div>
          <table id="table">
            <thead>
              <tr>
                ${tableHead}
              </tr>
            </thead>
            <tbody>
            ${bls
              .map(
                (x, id) =>
                  `<tr id=${id}>
                  <td>${id + 1}</td>
                  <td>${x.id}</td>
                  <td>${x.document_id}</td>
                  <td>${x.blOrderId}</td>
                  <td>${TableHelper.mapBlStatusText(x.status)}</td>
                  <td>
                    ${TableHelper.mapWmsStatusToText(x.wmsStatus)}
                  </td>
                  <td>
                    ${TableHelper.mapShipMethodToText(
                      x.lastTransport.shipMethod
                    )}
                    <br/>
                    ${
                      x.lastTransport.shipMethod
                        ? x.lastTransport.shipMethod === "ktv"
                          ? `${x.lastTransport.shipper.id} - ${x.lastTransport.shipper.name}`
                          : x.lastTransport.plRef
                        : ""
                    }
                  </td>
                  <td style="width: 15%">
                    ${
                      x.fromAddr.hub &&
                      x.fromAddr.hub.asia_branch &&
                      x.fromAddr.hub.asia_branch.code
                        ? `<strong>${x.fromAddr.hub.asia_branch.code}</strong>
                          <br/>
                          <span>${x.fromAddr.hub.asia_branch.address}</span>`
                        : x.fromAddr.displayName
                    }
                  </td>
                  <td style="width: 15%">
                    <strong>${x.toAddr.displayName}</strong>
                    <br />
                    <span>${x.toAddr.fullAddress}</span>
                  </td>
                  <td style="width: 15%">
                  ${
                    x.transitHub.id
                      ? x.transitHub.hub &&
                        x.transitHub.hub.asia_branch &&
                        x.transitHub.hub.asia_branch.code
                        ? `<div>
                          <strong>${x.transitHub.hub.asia_branch.code}</strong>
                          <br />
                          <span>${x.transitHub.hub.asia_branch.address}</span>
                        </div>`
                        : `<span>${x.transitHub.displayName}</span>`
                      : ""
                  }
                  </td>
                  <td>${x.toAddr.area.province || ""}</td>
                  <td>${
                    x.toAddr.areaCode &&
                    x.toAddr.areaCode &&
                    x.toAddr.areaCode.length === 6
                      ? x.toAddr.area.name
                      : ""
                  }</td>
                  <td>${x.lastTransport.edt || ""}</td>
                  <td>${x.lastTransport.createdAt || ""}</td>
                  <td>${x.requestCreatedAt}</td>
                  <td>${x.shipDate}</td>
                  <td>${x.note}</td>
                  <td>${x.coordinator_note}</td>
                  <td>${TableHelper.mapSellerIdToText(x.seller_id)}</td>
                </tr>`
              )
              .join("")}
            </tbody>
          </table>
        </div>
      </body>
      <script src="${ASSETS_URL}/assets/print/pdfmake.min.js"></script>
      <script src="${ASSETS_URL}/assets/print/vfs_fonts.js"></script>

      <script>
        window.onload = function () { 
          const dd = {
            pageOrientation: 'landscape',
            pageSize: 'A3',
            content: [
            {text: 'Danh sách vận đơn ${new Date().toLocaleString()}', style: 'header'},
            {
              style: 'tableExample',
              table: {
                body: [
                  [
                    "STT",
                    "Mã vận đơn",
                    "Mã gói hàng",
                    "Mã đơn hàng",
                    "Trạng thái",
                    "TT từ HT khác",
                    "Đơn vị vận chuyển",
                    "Kho xuất",
                    "Nơi nhận",
                    "Quận/Huyện",
                    "Phường/Xã",
                    "Hub cần thao tác",
                    "Thời gian giao dự kiến",
                    "Ngày tạo lộ trình",
                    "Ngày xuất kho",
                    "Ngày hẹn giao",
                    "Ghi chú đơn",
                    "Ghi chú của điều vận",
                    "Công ty"
                  ],
                  ${data}
                ]
              }
            }
          ],
          styles: {
            header: {
              fontSize: 18,
              bold: true
            }
          }
        };
        const doc = pdfMake.createPdf(dd).download('Danh_sach_van_don_${new Date().toLocaleString()}');

        }
      </script>
    </html>`;
    const win = window.open("", "_blank", "");
    win.document.write(html);
    win.document.close();
  };

  renderFormattedCell = (row, column) => {
    switch (column.name) {
      case TABLE_COLUMN.actions:
        return <ClickableCell id={row.id} />;
      case TABLE_COLUMN.fromHub:
        return (
          <FromHubCell id={row.id} fromHub={row.fromAddr} menuId="menu_id" />
        );
      case TABLE_COLUMN.status:
        return <BlStatusCell id={row.id} status={row.status} bl={row} />;
      case TABLE_COLUMN.shipMethod:
        return (
          <ShipMethodCell
            id={row.id}
            shipMethod={row.shipMethod}
            plRef={row.plRef}
            shipper={row.shipper}
            transport={row.lastTransport}
            check_status_3pl_url={row.lastTransport.check_status_3pl_url}
          />
        );
      case TABLE_COLUMN.addr:
        return <ShipToAddressCell rowData={row} />;
      case TABLE_COLUMN.toTransitHub:
        return (
          <LastTransToAddr
            id={row.id}
            type={row.lastTransport.type}
            transitHub={row.transitHub}
          />
        );
      case TABLE_COLUMN.systemStatus:
        return <OtherSystemStatusCell bl={row} />;
      case TABLE_COLUMN.actions:
        return <ClickableCell id={row.id} data={row[column.name]} />;
      case TABLE_COLUMN.seller_id:
        return TableHelper.mapSellerIdToText(row.seller_id);
      case TABLE_COLUMN.note:
      case TABLE_COLUMN.coordinator_note:
        return <BlNoteCell note={row[column.name]} />;
      default:
        return <span>{row[column.name]}</span>;
    }
  };

  render() {
    const { bls, filter, user, exportBlListExcel, classes } = this.props;
    const { printLoad } = this.state;
    const rows = TableHelper.mapBlsToTableRows(bls);
    return (
      <div>
        {rows.length > 0 && (
          <Zoom in={true} timeout={600}>
            <div className={classes.floatingBar}>
              <Button
                onClick={() => exportBlListExcel()}
                className={classes.floatingButton}
              >
                <span
                  style={{
                    fontSize: 14,
                    textTransform: "none",
                  }}
                >
                  <i className="fas fa-file-excel" />
                  Excel
                </span>
              </Button>
              <Button
                onClick={() => this.print()}
                className={classes.floatingButton}
              >
                <span style={{ fontSize: 14, textTransform: "none" }}>
                  {printLoad ? (
                    <CircularProgress size={15} style={{ color: "white" }} />
                  ) : (
                    <i className="fas fa-print" />
                  )}
                  {"  "}
                  Print
                </span>
              </Button>
            </div>
          </Zoom>
        )}
        <div className="bl-list">
          <Grid
            rows={rows}
            columns={TableHelper.columnTemplate}
            className="bl-list-container"
          >
            <DataTypeProvider
              for={Object.keys(TABLE_COLUMN)}
              formatterComponent={({ row, column }) => {
                return this.renderFormattedCell(row, column);
              }}
            />
            <Table
              tableComponent={TableComponent}
              columnExtensions={TableHelper.columnExtension}
              noDataRowComponent={NoDataRow}
            />
            <SortingState
              sorting={[filter.sorting]}
              onSortingChange={this.changeSort}
            />
            <IntegratedSorting />
            <SelectionState
              selection={filter.selection[filter.currentPage]}
              onSelectionChange={(v) => this.changeSelection(v, rows)}
            />
            <PagingState
              currentPage={filter.currentPage}
              onCurrentPageChange={this.changePage}
              pageSize={filter.pageSize}
              totalCount={filter.totalCount}
            />
            <RowDetailState
              expandedRows={filter.expandedRows}
              onExpandedRowsChange={(e) => this.expandRows(e)}
            />
            <IntegratedSelection />
            <TableSelection
              showSelectAll
              cellComponent={(props) => (
                <TableCell colSpan={props.colSpan}>
                  <Checkbox
                    color="primary"
                    checked={props.selected}
                    onChange={props.onToggle}
                  />
                </TableCell>
              )}
              headerCellComponent={(props) => (
                <TableCell colSpan={props.colSpan}>
                  <Checkbox
                    color="primary"
                    checked={props.allSelected}
                    indeterminate={props.someSelected}
                    onChange={(e) => props.onToggle(e.target.checked)}
                    disabled={props.disabled}
                  />
                </TableCell>
              )}
            />
            <TableHeaderRow showSortingControls />
            <TableRowDetail
              contentComponent={({ row }) => {
                return <RowDetailWithForm row={row} user={user} />;
              }}
            />
            <PagingPanel
              pageSizes={filter.allowedPageSizes}
              containerComponent={({ ...props }) => (
                <div style={{ overflow: "hidden", padding: "0.5rem" }}>
                  <Pagination
                    {...props}
                    filter={filter}
                    onCurrentPageChange={this.changePage}
                  />
                  <PageSizeSelector
                    {...props}
                    filter={filter}
                    onPageSizeChange={this.onPageSizeChange}
                  />
                </div>
              )}
            />
          </Grid>
        </div>
        {this.props.selectedBl && (
          <CreateTransportContainer
            bl={this.props.selectedBl}
            open={this.state.open}
            onClose={() => this.setState({ open: false })}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  bls: state.admin.list.bls,
  filter: state.admin.list.filter,
  user: state.admin.shared.user,
  selectedBl: state.admin.list.selected,
});

const mapDispatchToProps = (dispatch) => ({
  fetchBlList: () => dispatch(fetchBlList()),
  setSelection: (selection, row) => dispatch(setSelection(selection, row)),
  changePage: (page) => dispatch(changePage(page)),
  changePageSize: (pageSize) => dispatch(changePageSize(pageSize)),
  expandRows: (rows) => dispatch(expandRows(rows)),
  setFilter: (property, value) => dispatch(setFilter(property, value)),
  exportBlListExcel: () => dispatch(exportBlListExcel()),
  setBlDetail: (bl) => dispatch(setBlDetail(bl)),
  printBlList: () => dispatch(printBlList()),
  fetchBl3PlsList: (bl_id) => dispatch(fetchBl3PlsList(bl_id)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(TableContainer)
);
