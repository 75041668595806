import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import { saveUserFilter, deleteUserFilter } from "@api";

//styles
const styles = (theme) => ({
  bookmarkTitle: {
    display: "flex",
    alignItems: "center",
  },
  drawerPaper: {
    position: "relative",
    height: "100%",
    padding: "0.3rem",
  },
  title: {
    fontWeight: "bold",
    color: "#000000a8",
    fontSize: "1.1rem",
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  "@global": {
    ".saveFilterFabBtn": {
      background: "#ffffff00",
      color: "#00000082",
      transition: "all 1s ease",
    },
    ".saveFilterBtnSuccess": {
      color: "limegreen",
      transition: "all 1s ease",
    },
    ".listItems .closeIcon": {
      visibility: "hidden",
    },
    ".listItems:hover .closeIcon": {
      visibility: "visible",
    },
    "#list::-webkit-scrollbar-track": {
      borderRadius: 10,
      backgroundColor: "#F5F5F5",
    },
    "#list::-webkit-scrollbar": {
      width: 8,
      height: 8,
      backgroundColor: "#F5F5F5",
    },
    "#list::-webkit-scrollbar-thumb": {
      borderRadius: 10,
      backgroundColor: "#5555556b",
    },
  },
});

class BookmarkBar extends React.Component {
  state = {
    bmHeight: null,
    filterName: "",
    loading: false,
    success: false,
    showError: false,
    current: null,
  };
  inputRef = null;

  componentDidMount() {
    const filterContainer = document.getElementsByClassName("filterRow");
    const bmTitle = document.getElementById("bookmarkTitle")
      ? document.getElementById("bookmarkTitle").clientHeight
      : 0;
    let height = 0;
    for (let item of filterContainer) {
      height += item.clientHeight;
    }
    this.setState({ bmHeight: height - bmTitle - 50 });
  }

  handleSaveFilter = async () => {
    const { filterName } = this.state;
    const { currentFilter, type, saveCallBack } = this.props;
    if (!filterName || (filterName && filterName.trim().length === 0)) {
      this.setState({ showError: true });
      return;
    }
    let params = { ...currentFilter };
    // params.start_date = moment(params.start_date).format("YYYY-MM-DD");
    Object.keys(params).forEach((key) => {
      if (
        !params[key] ||
        params[key] == "" ||
        (Array.isArray(params[key]) && params[key].length === 0)
      ) {
        delete params[key];
      }
    });
    let part = [];
    Object.keys(params).forEach((key) => {
      part.push(
        `${key}=${
          Array.isArray(params[key]) ? params[key].toString() : params[key]
        }`
      );
    });
    const body = {
      data: [
        {
          name: filterName.trim(),
          query: part.join("&"),
          type: type,
        },
      ],
    };
    this.setState({ loading: true });
    const response = await saveUserFilter(body);
    const sleep = (m) => new Promise((r) => setTimeout(r, m));
    await sleep(700);
    if (response.status === 200) {
      this.setState({ loading: false, success: true });
      await sleep(1000);
      this.setState({
        success: false,
        current: response.data.data.Filters[0]
          ? response.data.data.Filters[0].id
          : null,
      });
    }
    this.setState({ loading: false });
    this.setState({ filterName: "" });
    saveCallBack && (await saveCallBack());
  };

  handleSelect = (filter) => {
    const { onSelect } = this.props;
    this.setState({ current: filter.id });
    if (!onSelect) {
      console.error(
        `BookmarkBar: onSelect must be a function. Received: ${typeof onSelect}`
      );
    } else {
      onSelect(filter);
    }
  };

  handleDelete = async (event, filter) => {
    const { onDeleteCallback, beforeDeleteCallback } = this.props;
    event.stopPropagation();
    if (beforeDeleteCallback) {
      beforeDeleteCallback(filter);
    }
    const body = {
      data: [filter.id],
    };
    await deleteUserFilter(body);
    if (onDeleteCallback) {
      onDeleteCallback(filter);
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { open } = this.props;
    if (!open && nextProps.open) {
      const list = document.getElementById("list");
      if (list) list.scrollTop = 0;
      if (this.inputRef) this.inputRef.focus();
    }
  }

  componentDidUpdate(prevProps) {
    const filterContainer = document.getElementsByClassName("filterRow");
    const bmTitle = document.getElementById("bookmarkTitle")
      ? document.getElementById("bookmarkTitle").clientHeight
      : 0;
    let height = 0;
    for (let item of filterContainer) {
      height += item.clientHeight;
    }
    if (this.state.bmHeight !== height - bmTitle - 50)
      this.setState({ bmHeight: height - bmTitle - 50 });
  }

  render() {
    const {
      open,
      filters,
      paperClass,
      containerClass,
      listClass,
      width = "25rem",
      onBlur,
      classes,
    } = this.props;
    const { filterName, bmHeight, loading, success, showError } = this.state;
    return (
      <Drawer
        variant="persistent"
        anchor="right"
        open={open}
        style={{ overflow: "hidden" }}
        classes={{ paper: `${classes.drawerPaper} ${paperClass}` }}
      >
        <div id="container" className={containerClass} onBlur={onBlur}>
          <div className={classes.bookmarkTitle} id="bookmarkTitle">
            <i className="material-icons" style={{ color: "#ffa700" }}>
              star
            </i>
            &nbsp;&nbsp;<span className={classes.title}>Bộ lọc</span>
          </div>
          <div
            style={{
              maxHeight: bmHeight,
              overflowY: "auto",
              marginTop: "1rem",
              width: width,
            }}
            id="list"
          >
            <List component="nav" className={`${classes.list} ${listClass}`}>
              <ListItem className={classes.listItem}>
                <div style={{ width: "16rem" }}>
                  <input
                    ref={(ref) => (this.inputRef = ref)}
                    className="input"
                    placeholder="Nhập tên bộ lọc cần lưu"
                    autoFocus
                    value={filterName}
                    onChange={(e) =>
                      this.setState({
                        filterName: e.target.value,
                        showError: false,
                      })
                    }
                  />
                  {showError && (
                    <span className="errorMsg">Vui lòng nhập tên bộ lọc</span>
                  )}
                </div>
                <IconButton
                  className={
                    success ? "saveFilterBtnSuccess" : "saveFilterFabBtn"
                  }
                  onClick={this.handleSaveFilter}
                >
                  {loading ? (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  ) : (
                    <i className="material-icons">
                      {success ? "check_circle" : "save"}
                    </i>
                  )}
                </IconButton>
              </ListItem>
              {filters.map((x, id) => (
                <ListItem
                  key={id}
                  button
                  className={`${classes.listItem} listItems`}
                  onClick={() => this.handleSelect(x)}
                  style={{
                    background:
                      this.state.current === x.id ? "#b4b6b93d" : "white",
                  }}
                >
                  <ListItemIcon>
                    <i className="material-icons">bookmark_border</i>
                  </ListItemIcon>
                  <ListItemText primary={x.name} />
                  <i
                    className="material-icons closeIcon"
                    onClick={(e) => this.handleDelete(e, x)}
                  >
                    close
                  </i>
                </ListItem>
              ))}
            </List>
          </div>
        </div>
      </Drawer>
    );
  }
}

export default withStyles(styles)(BookmarkBar);
