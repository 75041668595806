import React from "react";
import Select from "react-select";
import { connect } from "react-redux";

class BusLineSelection extends React.Component {
  render() {
    const {
      value,
      delivery_types,
      onChange,
      multi = true,
      placeholder = "Chọn loại giao hàng...",
    } = this.props;
    const _options = delivery_types.map((x) => ({
      label: x.description,
      value: x.id,
    }));

    return (
      <Select
        value={value}
        onChange={(v) => onChange(v)}
        options={_options}
        placeholder={placeholder}
        clearable={false}
        multi={multi}
        style={{ height: 40 }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  delivery_types: state.admin.shared.delivery_types,
});

export default connect(mapStateToProps, null)(BusLineSelection);
