import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { changeInvoiceId, updateShipDate } from "../../actions/blActions";
import BlNotification from "./BlNotification";
import { Typography, IconButton, CircularProgress } from "@material-ui/core";
import { BlDeliveryType, BlShipDate } from "./BlInfoComponents";

const styles = (theme) => ({
  subtitle: {
    fontSize: 14,
    padding: "8px 0 16px 0",
    fontFamily: "Source Sans Pro, sans-serif",
    textTransform: "uppercase",
    fontWeight: 600,
    letterSpacing: 1,
    color: "#5d9cec",
  },
  rowDetail: {
    display: "flex",
    fontFamily: "Noto Sans, sans-serif",
    alignItems: "center",
  },
  input: {
    height: 34,
    border: "1px solid #ccc",
    borderRadius: 4,
    paddingLeft: 8,
  },
  text: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
  },
  iconButton: {
    fontSize: "1.2rem",
  },
  icon: {
    cursor: "pointer",
    fontSize: "1.2rem",
    width: 30,
    height: 30,
  },
  label: {
    fontWeight: "bold",
  },
  table: {
    lineHeight: "2",
    width: "100%",
    borderSpacing: 5,
  },
});

const EditIcon = ({ onClick, className, title = "Cập nhật" }) => (
  <i className={`material-icons ${className}`} title={title} onClick={onClick}>
    edit
  </i>
);

const SaveIcon = ({ onClick, className, title = "Lưu" }) => (
  <i className={`material-icons ${className}`} title={title} onClick={onClick}>
    save
  </i>
);

class BlInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoice: this.props.bl.invoiceId ? this.props.bl.invoiceId : "",
      editState: false,
      invoiceLoading: false,
    };
  }

  handleChange = (prop) => (event) => {
    this.setState({
      [prop]: event.target.value,
    });
  };

  editInvoice = () => {
    this.setState({
      editState: true,
    });
  };

  getSalesmanInfo = (salesman) => {
    const id = salesman.asia_id
      ? `${salesman.asia_id.toString().padStart(0, 4)} - ${salesman.id}`
      : salesman.id;
    const department = salesman.extra_info
      ? salesman.extra_info.filter((x) => x.type === "accounting_code")
      : "";
    return (
      <span>
        {id} - {salesman.name}{" "}
        {department.length > 0 ? `- ${department[0].value}` : ""} -{" "}
        {salesman.phone}
      </span>
    );
  };

  updateInvoice = async () => {
    const { bl, changeInvoiceId } = this.props;
    const { invoice } = this.state;
    this.setState({ invoiceLoading: true });
    await changeInvoiceId(bl.id, invoice);
    this.setState({ invoiceLoading: false, editState: false });
  };

  renderInvoice = () => {
    const { bl, changeInvoiceId, classes } = this.props;
    const { invoice, invoiceLoading } = this.state;

    return (
      <React.Fragment>
        {bl.invoiceId ? (
          <span className={classes.rowDetail}>
            {this.state.editState ? (
              <input
                type="text"
                value={invoice}
                className={classes.input}
                onChange={this.handleChange("invoice")}
              />
            ) : (
              bl.invoiceId
            )}
            {!bl.invoiceAsia &&
              (!this.state.editState ? (
                <EditIcon
                  className={classes.icon}
                  onClick={() => {
                    this.editInvoice();
                  }}
                />
              ) : invoiceLoading ? (
                <CircularProgress size={20} />
              ) : (
                <SaveIcon
                  className={classes.icon}
                  onClick={() => {
                    if (invoice === bl.invoiceId) {
                      this.setState({
                        editState: false,
                      });
                    } else this.updateInvoice();
                  }}
                />
              ))}
          </span>
        ) : (
          <span className={classes.rowDetail}>
            <input
              type="text"
              value={invoice}
              className={classes.input}
              onChange={this.handleChange("invoice")}
            />
            &nbsp;
            <IconButton
              className={classes.iconButton}
              onClick={() => {
                if (invoice !== bl.invoiceId || invoice.length > 0) {
                  changeInvoiceId(bl.id, invoice);
                }
              }}
            >
              <SaveIcon />
            </IconButton>
          </span>
        )}
      </React.Fragment>
    );
  };

  render() {
    const { bl, classes } = this.props;
    return (
      <div className={classes.text}>
        <Typography type="subheading" className={classes.subtitle}>
          Thông tin vận đơn
        </Typography>
        <table className={classes.table}>
          <tbody>
            {bl.install === 1 && (
              <tr>
                <td
                  colSpan={3}
                  className={classes.label}
                  style={{ color: "#d42e00" }}
                >
                  Đơn có hỗ trợ cài đặt tại kho
                </td>
              </tr>
            )}
            <tr>
              <td style={{ width: "14%" }} className={classes.label}>
                Kho gửi:
              </td>
              <td>{bl.fromAddr.displayName}</td>
              <td style={{ width: "15%" }} className={classes.label}>
                Người liên hệ:
              </td>
              <td style={{ width: "27%" }}>
                {bl.fromAddr.name} - {bl.fromAddr.phone}
              </td>
            </tr>
            <tr>
              <td className={classes.label}>Ngày xuất kho:</td>
              <td>{bl.requestCreatedAt}</td>
              <td className={classes.label}>Ngày hẹn giao:</td>
              <td>
                <BlShipDate />
              </td>
            </tr>
            <tr>
              <td className={classes.label}>Mã gói hàng:</td>
              <td>{bl.document_id}</td>
              {!bl.isWhTransfer || bl.exportSource.toLowerCase() !== "wms" ? (
                <React.Fragment>
                  <td className={classes.label}>Mã đơn hàng:</td>
                  <td>{bl.blOrderId}</td>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <td className={classes.label}>Số hóa đơn:</td>
                  <td>{this.renderInvoice()}</td>
                </React.Fragment>
              )}
            </tr>
            <tr>
              <td className={classes.label}>Ghi chú:</td>
              <td>
                <div>{bl.note ? bl.note : "-"}</div>
                {bl.cancelled_reason && <div>{bl.cancelled_reason}</div>}
              </td>
              {!bl.isWhTransfer && (
                <React.Fragment>
                  <td className={classes.label}>Kênh bán hàng:</td>
                  <td>{bl.channel ? bl.channel : "-"}</td>
                </React.Fragment>
              )}
            </tr>
            {!bl.isWhTransfer && (
              <tr>
                <td className={classes.label}>NV bán hàng:</td>
                <td>
                  {bl.salesman.id
                    ? this.getSalesmanInfo(bl.salesman)
                    : "Không tìm thấy thông tin"}
                </td>
                <td className={classes.label}>Số hóa đơn:</td>
                <td>{this.renderInvoice()}</td>
              </tr>
            )}
            <tr>
              <td className={classes.label}>Loại giao hàng:</td>
              <td colSpan={2}>
                <BlDeliveryType />
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <BlNotification bl={bl} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  bl: state.admin.detail.bl,
});

const mapDispatchToProps = (dispatch) => ({
  changeInvoiceId: (blId, invoice) => dispatch(changeInvoiceId(blId, invoice)),
  changeShipDate: (data) => dispatch(updateShipDate(data)),
});
export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(BlInfo)
);
