import React from "react";
import {
  Typography,
  Collapse,
  Grid,
  CircularProgress,
  IconButton,
  Popover,
  Paper,
  List,
  ListItem,
  withStyles,
  ListItemIcon,
} from "@material-ui/core";
import { connect } from "react-redux";
import {
  updateCoordinatorNote,
  updateWmsStatus,
} from "../../actions/blActions";
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import OtherSystemStatusFilter from "./OtherSystemStatusFilter";
import { formatDateTime } from "@utils";
import { WMS_STATUS, THREE_PL_STATE, BlRequestType } from "@constants";

const styles = (theme) => ({
  productHeading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  subTitle: {
    fontSize: 14,
    fontFamily: "Source Sans Pro, sans-serif",
    textTransform: "uppercase",
    fontWeight: 600,
    letterSpacing: 1,
    color: "#5D9CEC",
  },
  text: {
    fontFamily: "Noto Sans, sans-serif",
  },
  listItem: {
    padding: 0,
    display: "flex",
  },
  listItemFull: {
    padding: 0,
    width: "100%",
    display: "flex",
  },
  gridItem: {
    display: "flex",
  },
  noteTextArea: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
    border: "1px solid #ccc",
    borderRadius: 4,
    padding: "0.4rem",
    flex: 1,
  },
  paper: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
    padding: "1rem",
    maxWidth: "25rem",
    maxHeight: "15rem",
    overflow: "auto",
  },
});

class BlNotification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      hiddenInput: true,
      coordinator_note: this.props.bl.coordinator_note || "",
      popover: false,
      wmsStatus: this.props.bl.wmsStatus,
    };
    this.inputRef = null;
    this.anchorEl = null;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.bl.coordinator_note !== this.props.bl.coordinator_note) {
      this.setState({
        coordinator_note: nextProps.bl.coordinator_note,
        wmsStatus: nextProps.bl.wmsStatus,
      });
    }
  }

  collapsePaper = () =>
    this.setState({
      collapse: !this.state.collapse,
    });

  /* Hiển thị yêu cầu hủy
    - Đơn SO: hiển thị yêu cầu hủy từ OM (bl.last_om_cancel_request)
    - Đơn CKNB: hiển thị yêu cầu hủy từ WMS (bl.wms_status)
  */
  getCancelState = () => {
    const { bl } = this.props;
    const requestType = bl.requestType.toLowerCase();
    if (requestType === BlRequestType.wh_transfer) {
      if (bl.wmsStatus === WMS_STATUS.cancelled) {
        return (
          <span>
            Có<strong className="stressPink"> yêu cầu hủy </strong>từ Kho
          </span>
        );
      } else {
        return <span>Không có</span>;
      }
    } else if (requestType === BlRequestType.sale_order) {
      const cancelState = bl.last_om_cancel_request;
      if (cancelState && cancelState.action) {
        const cancelType = (
          <span>
            <strong className="stressPink">
              {cancelState.action === "full" ? "hoàn toàn bộ" : "hoàn một phần"}
            </strong>
            &nbsp;đơn hàng
          </span>
        );

        if (["init", "delivering"].includes(bl.state)) {
          return <span> Đang có yêu cầu {cancelType} </span>;
        } else if (["returning", "partial_returning"].includes(bl.state)) {
          return <span> Đang xử lý yêu cầu {cancelType} </span>;
        } else if (
          ["returned", "cancelled", "partial_returned"].includes(bl.state)
        ) {
          return <span> Đã xử lý yêu cầu {cancelType} </span>;
        } else if ("delivered" === bl.state) {
          return <span> Không xử lý yêu cầu {cancelType} </span>;
        }
      } else {
        return <span> Không có </span>;
      }
    }
  };

  handleSaveNote = async () => {
    const { bl, updateCoordinatorNote } = this.props;
    const { coordinator_note } = this.state;
    if (coordinator_note !== bl.coordinator_note) {
      let result = await updateCoordinatorNote(bl.id, coordinator_note);
      if (result.code === 200) {
        this.setState({
          hiddenInput: true,
        });
      }
    } else {
      this.setState({
        hiddenInput: true,
      });
    }
  };

  handleEditNote = () => {
    this.setState(
      {
        hiddenInput: false,
      },
      () => {
        this.inputRef.focus();
      }
    );
  };

  handleSaveWmsStatus = async () => {
    const { bl, updateWmsStatus } = this.props;
    const { wmsStatus } = this.state;
    if (wmsStatus !== bl.wmsStatus) {
      await updateWmsStatus(bl.id, wmsStatus);
    }
  };

  setWmsStatus = (e) => {
    this.setState({
      wmsStatus: e,
    });
  };

  renderEditableNote = () => {
    const { bl } = this.props;
    const colSpan = ["sale_order", "asia_order"].includes(
      bl.requestType.toLowerCase()
    )
      ? 12
      : 6;
    const { classes, coordinator_note_loading } = this.props;
    const { hiddenInput, coordinator_note, popover } = this.state;
    return (
      <Grid item md={colSpan} className={classes.gridItem}>
        <ListItem
          className={classes.listItem}
          style={{
            width: "100%",
          }}
        >
          <ListItemIcon>
            <i
              className="material-icons"
              style={{
                color: "#EE9F00",
              }}
            >
              event_note
            </i>
          </ListItemIcon>
          <strong
            style={{
              whiteSpace: "nowrap",
            }}
          >
            Ghi chú: &nbsp;
          </strong>
          {hiddenInput && (
            <span
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              title={coordinator_note}
            >
              {coordinator_note}
            </span>
          )}
          <textarea
            type="text"
            ref={(input) => (this.inputRef = input)}
            value={coordinator_note}
            rows={4}
            className={classes.noteTextArea}
            onChange={(e) =>
              this.setState({
                coordinator_note: e.target.value,
              })
            }
            hidden={hiddenInput}
          />
          &nbsp;
          {coordinator_note_loading ? (
            <CircularProgress />
          ) : hiddenInput ? (
            <React.Fragment>
              <IconButton onClick={this.handleEditNote}>
                <EditIcon />
              </IconButton>
              {coordinator_note && (
                <IconButton
                  buttonRef={(node) => {
                    this.anchorEl = node;
                  }}
                  onClick={() =>
                    this.setState({
                      popover: true,
                    })
                  }
                  className={classes.iconButton}
                >
                  <ZoomInIcon />
                </IconButton>
              )}
              <Popover
                open={popover}
                anchorEl={this.anchorEl}
                onClose={() =>
                  this.setState({
                    popover: false,
                  })
                }
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Paper className={classes.paper}>
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: 14,
                      whiteSpace: "pre-line",
                    }}
                  >
                    {coordinator_note}
                  </pre>
                </Paper>
              </Popover>
            </React.Fragment>
          ) : (
            <IconButton
              onClick={this.handleSaveNote}
              className={classes.iconButton}
            >
              <SaveIcon />
            </IconButton>
          )}
        </ListItem>
      </Grid>
    );
  };

  renderWmsStatus = () => {
    const { bl } = this.props;
    if (bl.wmsStatus === WMS_STATUS.cancelled) {
      return "Yêu cầu hủy từ kho";
    } else if (bl.wmsStatus === WMS_STATUS.partial_import) {
      return "Đã nhập kho 1 phần";
    } else if (bl.wmsStatus === WMS_STATUS.pack) {
      return `Đã đóng gói (${formatDateTime(bl.packed_at)})`;
    } else if (bl.wmsStatus === WMS_STATUS.pick) {
      return "Chưa đóng gói";
    } else if (bl.wmsStatus === WMS_STATUS.init) {
      return "Chờ soạn hàng";
    }
  };

  renderPackingStatus = () => {
    const { bl, classes } = this.props;
    const { wmsStatus } = this.state;
    const isAsia =
      bl.exportSource.toLowerCase() === "asia" &&
      ["waiting_for_pick", "waiting_for_confirm"].includes(bl.status);
    const isAllowChange = isAsia && bl.wmsStatus === WMS_STATUS.pick;
    return (
      <Grid item md={12} className={classes.gridItem}>
        <ListItem className={classes.listItemFull}>
          <ListItemIcon>
            <i
              className="material-icons"
              style={{
                color: "#21A0FF",
              }}
            >
              ballot
            </i>
          </ListItemIcon>
          <strong> Trạng thái kiện hàng: &nbsp; </strong>
          {isAllowChange ? (
            <span
              style={{
                width: "50%",
                display: "inline-block",
              }}
            >
              <OtherSystemStatusFilter
                value={wmsStatus}
                onChange={(e) => this.setWmsStatus(e)}
              />
            </span>
          ) : (
            this.renderWmsStatus()
          )}
          {isAllowChange && (
            <IconButton
              onClick={this.handleSaveWmsStatus}
              className={classes.iconButton}
            >
              <SaveIcon />
            </IconButton>
          )}
        </ListItem>
      </Grid>
    );
  };

  render3plStatus = () => {
    const { bl } = this.props;
    if (
      bl.lastTransport &&
      bl.lastTransport.three_pl_state === THREE_PL_STATE.return_confirm
    ) {
      return "3PLs xác nhận khách hoàn";
    } else if (
      bl.lastTransport &&
      bl.lastTransport.three_pl_state === THREE_PL_STATE.on_return
    ) {
      return "3PLs đang đem hàng về kho";
    } else if (
      bl.lastTransport &&
      bl.lastTransport.three_pl_state === THREE_PL_STATE.return_received
    ) {
      return "3Pls đã đem hàng tới kho";
    }
    return "Không có";
  };

  renderReturnStatus = () => {
    const { classes } = this.props;
    return (
      <Grid item md={12} className={classes.gridItem}>
        <ListItem className={classes.listItemFull}>
          <ListItemIcon>
            <i
              className="material-icons"
              style={{
                color: "#21A0FF",
              }}
            >
              settings_backup_restore
            </i>
          </ListItemIcon>
          <strong> Thông tin hoàn hàng từ 3pl: &nbsp; </strong>
          <span>{this.render3plStatus()}</span>
        </ListItem>
      </Grid>
    );
  };

  renderCancelStatus = () => {
    const { bl, classes } = this.props;
    const exportSource = bl.exportSource.toLowerCase();
    if (exportSource === "wms") {
      return (
        <Grid item md={6} className={classes.gridItem}>
          <ListItem className={classes.listItem}>
            <ListItemIcon>
              <i className="material-icons" style={{ color: "#FF0404" }}>
                cancel
              </i>
            </ListItemIcon>
            <strong>Yêu cầu hủy:&nbsp;</strong>
            {this.getCancelState()}
          </ListItem>
        </Grid>
      );
    }
  };

  /* Thứ tự hiển thị thông báo
  - Đơn từ ASIA: Ghi chú
  - Đơn CKNB: Trạng thái kiện hàng, Ghi chú
  - Đơn từ OM: Trạng thái kiện hàng, Yêu cầu hủy, Ghi chú
*/
  render() {
    const { collapse } = this.state;
    const { classes } = this.props;

    return (
      <div className={classes.text}>
        <div className={classes.productHeading}>
          <Typography type="title" className={classes.subTitle}>
            Thông báo từ hệ thống khác
          </Typography>
        </div>
        <Collapse in={!collapse} unmountOnExit>
          <Grid container spacing={1}>
            <Grid item md={12}>
              <List
                style={{
                  marginTop: "0.5rem",
                }}
              >
                <Grid
                  container
                  spacing={1}
                  style={{
                    marginBottom: "10px",
                  }}
                >
                  {this.renderPackingStatus()}
                  {this.renderReturnStatus()}
                  {this.renderCancelStatus()}
                  {this.renderEditableNote()}
                </Grid>
              </List>
            </Grid>
          </Grid>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  coordinator_note_loading: state.admin.detail.coordinator_note_loading,
});
const mapDispatchToProps = (dispatch) => ({
  updateCoordinatorNote: (blId, note) =>
    dispatch(updateCoordinatorNote(blId, note)),
  updateWmsStatus: (blId, wmsStatus) =>
    dispatch(updateWmsStatus(blId, wmsStatus)),
});
export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(BlNotification)
);
