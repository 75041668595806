import { API_URL } from "@config";
import axios from "axios";
import { store } from "../../../index";
import { openNotiDialog } from "@shared";
import TekoID from "teko-oauth2";

axios.defaults.baseURL = API_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.validateStatus = function (status) {
  return true;
};

const apiService = async (config) => {
  const { user } = TekoID;

  const instance = axios.create();
  instance.interceptors.request.use(function (config) {
    if (user && user.isLoggedIn()) {
      config.headers.Authorization = `Bearer ${user.getAccessToken()}`;
    }
    if (config.params) {
      Object.keys(config.params).forEach((x) => {
        if (
          config.params[x] === null ||
          config.params[x] === "" ||
          config.params[x] === undefined ||
          (Array.isArray(config.params[x]) && config.params[x].length === 0)
        ) {
          delete config.params[x];
        }
      });
    }
    if (config.data) {
      Object.keys(config.data).forEach((x) => {
        if (
          config.data[x] === null ||
          config.data[x] === "" ||
          config.data[x] === undefined ||
          (Array.isArray(config.data[x]) && config.data[x].length === 0)
        ) {
          delete config.data[x];
        }
      });
    }
    return config;
  });
  // Add a response interceptor
  instance.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      store.dispatch(openNotiDialog("alert", error.message));
      return Promise.reject(error);
    }
  );
  return await instance.request(config);
};

export default apiService;
