import apiService from "./base";

export const getCategories = (params) => {
  return apiService({
    url: "/admin/product/categories",
    params: params,
  });
};

export const dumpProductApi = null;
