import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import {
  ShipDateSummaryReportFilter,
  ShipDateSummaryReportTable,
} from "./components";
import {
  changeFilter,
  changePagination,
  onSearch,
  getReasons,
} from "./actions";

//styles
const styles = (theme) => ({
  text: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: 14,
  },
});

class ShipDateSummaryReport extends React.Component {
  async componentDidMount() {
    const { getReasons } = this.props;
    getReasons("edit_ship_date");
  }

  render() {
    const {
      filter,
      loading,
      data,
      pagination,
      onSearch,
      changeFilter,
      changePagination,
      classes,
    } = this.props;
    return (
      <React.Fragment>
        <Grid item md={12} className={classes.text}>
          <ShipDateSummaryReportFilter
            filter={filter}
            onSearch={onSearch}
            onChange={changeFilter}
          />
        </Grid>
        <Grid
          item
          md={12}
          className={classes.text}
          style={{
            overflow: "auto",
            boxShadow: "0 1px 5px rgba(0, 0, 0, 0.15)",
            borderRadius: "0 0 1rem 1rem",
          }}
        >
          <ShipDateSummaryReportTable
            loading={loading}
            data={data}
            pagination={pagination}
            onChangePagination={changePagination}
          />
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  filter: state.admin.ShipDateSummaryReport.filter,
  loading: state.admin.ShipDateSummaryReport.loading,
  data: state.admin.ShipDateSummaryReport.data,
  pagination: state.admin.ShipDateSummaryReport.pagination,
});

const mapDispatchToProps = (dispatch) => ({
  changeFilter: (filter) => dispatch(changeFilter(filter)),
  changePagination: (pagination) => dispatch(changePagination(pagination)),
  onSearch: () => dispatch(onSearch()),
  getReasons: (type) => dispatch(getReasons(type)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ShipDateSummaryReport)
);
