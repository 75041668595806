import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Paper, LinearProgress } from "@material-ui/core";
import { setMap } from "../actions";
import DateBlock from "./DateBlock";

const styles = (theme) => ({
  dateBlock: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dateShip: {
    marginLeft: 10,
    fontSize: 14,
    fontFamily: "Noto Sans, sans-serif",
    textTransform: "uppercase",
    fontWeight: 600,
    letterSpacing: 1,
    color: "black",
  },
  ribbon: {
    display: "flex",
    alignItems: "center",
    color: "black",
    fontWeight: "bold",
    flex: 1,
    paddingLeft: "0.5rem",
  },
  weather: {
    width: "4rem",
  },
  weatherImg: {
    width: "10rem",
  },
  history: {
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
    },
  },
  paper: {
    padding: "1rem 2rem",
    borderRadius: "0rem 0rem 1rem 1rem",
    paddingBottom: "2rem",
  },
  arrowRight: {
    width: 0,
    height: 0,
    borderTop: "30px solid transparent",
    borderBottom: "30px solid transparent",
    borderLeft: "30px solid",
    display: "inline-block",
  },
  action: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "1rem",
    width: "7rem",
    color: "black",
  },
  location: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "1rem",
    color: "black",
    width: "40%",
  },
  table: {
    background: "white",
    fontSize: 14,
    fontFamily: "Noto Sans, sans-serif",
  },
  row: {
    cursor: "pointer",
    "&:hover": {
      background: "#959fc738",
    },
  },
  label: {
    fontWeight: "bold",
    letterSpacing: 1,
  },
  blockDate: {
    "&:hover": {
      opacity: 0.8,
      background: "#959fc738",
    },
  },
  "@global": {
    "#ShippingLogTable > thead > tr > td": {
      padding: "0.5rem",
      background: "#324B78",
      fontWeight: "bold",
      color: "white",
      border: "1px solid #515177",
    },
    "#ShippingLogTable > thead > tr:first-child > td:first-child": {
      borderRadius: "1rem 0 0 0",
    },
    "#ShippingLogTable > thead > tr:first-child > td:last-child": {
      borderRadius: "0 1rem 0 0",
    },

    "#ShippingLogTable > tbody > tr:not(:last-child) > td": {
      borderBottom: "1px solid #0000001c",
    },
  },
});

// function mapActionTypeToColor(type) {
//   switch (type) {
//     case "pick":
//       return "#6495ed9c";
//     case "at_cs_house":
//       return "#008000a1";
//     case "retry":
//       return "orange";
//     case "partial_returning":
//       return "purple";
//     case "returning":
//       return "purple";
//   }
// }

// function mapActionTypeToTextColor(type) {
//   switch (type) {
//     case "pick":
//       return "white";
//     case "at_cs_house":
//       return "white";
//     case "retry":
//       return "black";
//     case "partial_returning":
//       return "white";
//     case "returning":
//       return "white";
//   }
// };

class ShippingLog extends React.Component {
  renderElement = (data, id) => {
    const { setMap } = this.props;
    return <DateBlock data={data} setMap={setMap} />;
  };

  render() {
    const { loading, classes, data } = this.props;
    return (
      <Grid container>
        <Grid item xs={12}>
          <Paper elevation={0} className={classes.paper}>
            {loading && <LinearProgress />}
            <table className={classes.table} id="ShippingLogTable">
              <thead>
                <tr>
                  <td rowSpan={2} align="center">
                    STT
                  </td>
                  <td rowSpan={2}>Thời gian</td>
                  <td rowSpan={2}>Loại thao tác</td>
                  <td rowSpan={2}>Mã đơn</td>
                  <td rowSpan={2}>Vị trí thao tác</td>
                  <td rowSpan={2} align="right">
                    Khoảng cách (km)
                  </td>
                  <td colSpan={2} align="center">
                    Nghi vấn
                  </td>
                </tr>
                <tr>
                  <td align="right">Khoảng cách (km)</td>
                  <td align="right">Tốc độ (km/h)</td>
                </tr>
              </thead>
              <tbody>
                {data && data.length > 0 ? (
                  data.map((x, id) => this.renderElement(x, id))
                ) : (
                  <tr>
                    <td>Không có thao tác nào</td>
                  </tr>
                )}
              </tbody>
            </table>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.admin.ShipperDailyReport.data,
  loading: state.admin.ShipperDailyReport.loading,
});

const mapDispatchToProps = (dispatch) => ({
  setMap: (config) => dispatch(setMap(config)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ShippingLog)
);
