import React from "react";
import Select from "react-select";
import { withStyles } from "@material-ui/core/styles";
import { isMaxTrustUser } from "@utils";

const styles = (theme) => ({
  formControl: {
    margin: "0 0 16px 8px",
  },
  text: {
    fontFamily: "Noto Sans, sans-serif",
  },
  radioGroup: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 24,
  },
  label: {
    marginRight: 64,
  },
  radio: {
    marginRight: 8,
  },
});

const options = [
  { value: "asia_order,sale_order,wh_transfer", label: "Tất cả vận đơn" },
  { value: "asia_order", label: "Vận đơn khách hàng (Asia)" },
  { value: "sale_order", label: "Vận đơn khách hàng (WMS)" },
  { value: "wh_transfer", label: "Vận đơn chuyển kho" },
];

const optionsForMaxtrust = [
  { value: "sale_order", label: "Vận đơn khách hàng (WMS)" },
];

class RequestTypeFilter extends React.Component {
  render() {
    const { value, onChange, multi, sellerId } = this.props;
    const sellerIds = sellerId.split(",");
    let _options = [...options];
    if (sellerIds.length === 1 && sellerIds[0] === "2") {
      _options = _options.slice(2, 4);
    }
    if (
      value.includes("sale_order") &&
      value.includes("asia_order") &&
      value.includes("wh_transfer")
    ) {
      _options = _options.slice(1);
    }

    return (
      <Select
        options={isMaxTrustUser() ? optionsForMaxtrust : _options}
        placeholder="Chọn loại vận đơn..."
        value={value}
        simpleValue
        onChange={(v) => onChange(v)}
        wrapperStyle={{ width: "100%" }}
        multi={multi}
      />
    );
  }
}

export default withStyles(styles)(RequestTypeFilter);
