import React, { useState, useEffect } from "react";
import {
  Grid,
  ListItem,
  List,
  FormControlLabel,
  Checkbox,
  Fade,
  Snackbar,
  Alert,
} from "@material-ui/core";
import { ThreePlsServiceSelection } from "..";
import _ from "lodash";
import { EstimatedDeliveryTimePicker } from "@shared";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { NoteInput } from "../../../shared/components/create_transport_form";
import { makeStyles } from "@material-ui/core/styles";
import { Home, People } from "@material-ui/icons";
import AdidiServiceQuantitySelection from "./AdidiServiceQuantitySelection";

const color = [
  {
    background: "#2f4f4f",
    color: "white",
  },
  {
    background: "#228b22",
    color: "white",
  },
  {
    background: "#b03060",
    color: "white",
  },
  {
    background: "#ff4500",
    color: "white",
  },
  {
    background: "#ffff00",
    color: "black",
  },
  {
    background: "#00ff00",
    color: "black",
  },
  {
    background: "#9400d3",
    color: "black",
  },
  {
    background: "#00ffff",
    color: "black",
  },
  {
    background: "#1e90ff",
    color: "black",
  },
  {
    background: "#ffe4b5",
    color: "black",
  },
];

const useStyles = makeStyles({
  icon: {
    width: "2em",
  },
  subTitle: {
    fontSize: 12,
    color: "gray",
  },
  listItem: {
    userSelect: "none",
    marginBottom: 10,
    padding: 0,
  },
  listContent: {
    border: "1px solid #0000001a",
    padding: "10px !important",
    borderRadius: "0 5px 5px 0",
    background: "white",
  },
  listItemNumbering: {
    borderRadius: "5px 0 0 5px",
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
    justifyContent: "center",
  },
  dndContainer: {
    padding: 10,
  },
});

function AdidiServiceSelection(props) {
  const { onChange, points, serviceType } = props;
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const [transition, setTransition] = useState(true);
  const [snackbar, setSnackbar] = useState(false);

  useEffect(() => {
    setTransition(false);
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, [activeTab]);

  const changePointInfo = (info) => {
    let newPoints = _.cloneDeep(points);
    newPoints[activeTab] = {
      ...newPoints[activeTab],
      ...info,
    };
    onChange(newPoints);
  };

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#e6e6e66b" : "white",
    overflow: "auto",
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    //find active item
    const activeItem = points[activeTab];
    const items = reorder(
      points,
      result.source.index,
      result.destination.index
    );
    //check if hub point goes after customer point
    const draggedPoint = items[result.destination.index];
    if (draggedPoint.type === 1) {
      //search if there is any destination before it
      const destination = items.findIndex(
        (x) => x.group === draggedPoint.group && x.type !== 1
      );
      if (destination >= 0 && destination < result.destination.index) {
        setSnackbar("Điểm lấy hàng không thể đặt sau điểm giao hàng");
        return;
      }
    } else {
      //search if there is any origin before it
      const origin = items.findIndex(
        (x) => x.group === draggedPoint.group && x.type === 1
      );
      if (origin < 0 || origin > result.destination.index) {
        setSnackbar("Điểm giao hàng không thể đặt trước điểm lấy hàng");
        return;
      }
    }
    //find active item index in reordered list
    setActiveTab(items.findIndex((x) => x.id === activeItem.id));
    onChange(items);
  };

  const renderAdidiItems = () => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <List
              component="nav"
              aria-label="contacts"
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              className={`styledScrollbar ${classes.dndContainer}`}
            >
              {points.map((x, key) => (
                <Draggable key={x.id} draggableId={String(x.id)} index={key}>
                  {(provided, snapshot) => (
                    <ListItem
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      onClick={() => {
                        setActiveTab(key);
                        props.onChangeCurrentBl(x.bl_id);
                      }}
                      style={provided.draggableProps.style}
                      className={classes.listItem}
                    >
                      <Grid container alignItems="stretch">
                        <Grid
                          item
                          md={1}
                          xs={3}
                          style={{ ...color[x.group] }}
                          className={classes.listItemNumbering}
                        >
                          {key + 1}
                        </Grid>
                        <Grid
                          item
                          md={11}
                          xs={9}
                          className={classes.listContent}
                        >
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item md={2} xs={1}>
                              {x.type === 1 ? (
                                <Home className={classes.icon} />
                              ) : (
                                <People className={classes.icon} />
                              )}
                            </Grid>
                            <Grid item>
                              <ul style={{ margin: 0 }}>
                                {x.requestId.map((x, id) => (
                                  <li key={id}>{x}</li>
                                ))}
                              </ul>
                            </Grid>

                            {key === activeTab && (
                              <Grid item xs={1}>
                                <i className="fas fa-check" />
                              </Grid>
                            )}
                          </Grid>
                          <br />
                          <Grid container>
                            <Grid item xs={12} className={classes.subTitle}>
                              [{x.contact.name}] {x.location.address}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </ListItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    );
  };
  return (
    <React.Fragment>
      {points.length > 0 && (
        <React.Fragment>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} className={classes.pointList}>
              {renderAdidiItems()}
            </Grid>
            <Fade in={transition} timeout={200}>
              <Grid item xs={12} md={8}>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs={12} md={3} className={classes.label}>
                    <strong>Thời gian giao dự kiến</strong>
                  </Grid>
                  <Grid item xs={12} md={5} className={classes.right}>
                    <EstimatedDeliveryTimePicker
                      value={points[activeTab].expectedTime}
                      changeEDT={(e) => changePointInfo({ expectedTime: e })}
                    />
                  </Grid>
                </Grid>
                {serviceType != "installation" && (
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12} md={3} className={classes.label}>
                      <strong>Hàng hóa</strong>
                    </Grid>
                    <Grid item xs={12} md={8} lg={5} className={classes.right}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={points[activeTab].goodsCheckRequired}
                            onChange={(e) =>
                              changePointInfo({
                                goodsCheckRequired: e.target.checked,
                              })
                            }
                          />
                        }
                        label="Cho xem hàng"
                      />
                    </Grid>
                  </Grid>
                )}
                {points[activeTab].type !== 1 && (
                  <Grid
                    container
                    className={classes.row}
                    wrap="nowrap"
                    spacing={1}
                  >
                    <Grid item xs={12} md={3} className={classes.label}>
                      <strong>Dịch vụ</strong>
                    </Grid>
                    <Grid item xs={12} md={8} className={classes.right}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <ThreePlsServiceSelection
                          data={points[activeTab].services}
                          onChange={(v) => changePointInfo({ services: v })}
                        />
                        &nbsp;&nbsp;
                      </div>
                      <AdidiServiceQuantitySelection
                        services={points[activeTab].services}
                        onChange={(v) => changePointInfo({ services: v })}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid
                  container
                  alignItems="center"
                  style={{ marginBottom: "10px" }}
                  spacing={1}
                >
                  <Grid item xs={12} md={3} className={classes.label}>
                    <strong>Ghi chú</strong>
                  </Grid>
                  <Grid item xs={12} md={5} className={classes.right}>
                    <NoteInput
                      note={points[activeTab].note}
                      changeNote={(e) => changePointInfo({ note: e })}
                      style={{ width: `calc(100% - 15px)` }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Fade>
          </Grid>
        </React.Fragment>
      )}
      <Snackbar
        open={Boolean(snackbar)}
        autoHideDuration={1500}
        onClose={() => setSnackbar(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        message={snackbar}
      ></Snackbar>
    </React.Fragment>
  );
}

export default AdidiServiceSelection;
