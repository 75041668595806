import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { RequestType } from "../../../../../../models";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";

const styles = (theme) => ({
  stepLabel: {
    color: "#fff",
    fontSize: 12,
    fontWeight: 800,
    padding: 4,
    borderRadius: 4,
  },
  priceCell: {
    color: "#797979",
    fontWeight: 500,
    textAlign: "right",
    paddingTop: "4px !important",
    paddingBottom: "4px !important",
  },
  codTitle: {
    textAlign: "left",
    fontWeight: "bold",
    paddingTop: "4px !important",
    paddingBottom: "4px !important",
    paddingLeft: "8px !important",
    whiteSpace: "nowrap",
  },
  totalTable: {
    width: 400,
  },
  totalRow: {
    height: 32,
  },
});

const RowDetail = ({ row, user, classes }) => {
  return (
    <div style={{ paddingLeft: 24 }}>
      <p>Ghi chú: {row.note}</p>
      {row.requestType !== RequestType.promotion && (
        <Table className={classes.totalTable}>
          <TableBody>
            <TableRow className={classes.totalRow}>
              <TableCell className={classes.codTitle}>Tổng cộng:</TableCell>
              <TableCell className={classes.priceCell}>
                {row.formattedGrandTotal}
              </TableCell>
              <TableCell className={classes.codTitle} width="25%">
                COD
              </TableCell>
              <TableCell className={classes.priceCell}>
                {row.formattedCOD}
              </TableCell>
            </TableRow>
            <TableRow className={classes.totalRow}>
              <TableCell className={classes.codTitle}>Tiền cọc:</TableCell>
              <TableCell className={classes.priceCell}>
                {row.formattedPrePaid}
              </TableCell>
              <TableCell className={classes.codTitle}>Paid COD</TableCell>
              <TableCell className={classes.priceCell}>
                {row.formattedPaidCOD}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
      {/* {[RequestType.promotion, RequestType.wh_transfer].indexOf(
        row.requestType
      ) === -1 &&
        [BlState.partial_returning, BlState.partial_returned].indexOf(
          row.state
        ) > -1 && (
          <p>
            COD đã thu:&nbsp;
            {row.paidCOD.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
              " VNĐ"}
          </p>
        )} */}
      {![
        "waiting_for_confirm",
        "delivered",
        "returned",
        "partial_delivered",
        "partial_returned",
      ].includes(row.status) &&
        row.lastTransport && (
          <p>
            Lộ trình hiện tại: Từ{" "}
            {row.lastTransport.fromAddress.type === "ktv"
              ? `KTV ${row.lastTransport.fromAddress.name}(${row.lastTransport.fromAddress.objId})`
              : row.lastTransport.fromAddress.name}{" "}
            tới{" "}
            {row.lastTransport.toAddress.type === "customer"
              ? "khách hàng"
              : row.lastTransport.toAddress.type === "ktv"
              ? `KTV ${row.lastTransport.toAddress.name}(${row.lastTransport.toAddress.objId})`
              : row.lastTransport.toAddress.name}
          </p>
        )}
    </div>
  );
};

export default withStyles(styles)(RowDetail);
