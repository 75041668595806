import moment from "moment";
import { MAX_TRUST_DOMAINS } from "../constants";

export const checkPhoneNumber = (phone) => {
  if (!phone) {
    return false;
  } else {
    if (phone.startsWith("0")) {
      return true;
    } else if (phone.startsWith("+84")) {
      return true;
    } else if (phone.startsWith("84")) {
      return true;
    } else if (phone.length >= 8 && phone.length <= 12) {
      return true;
    }
  }

  return false;
};

export const checkAddress = (address, code) => {
  if (!address) {
    return false;
  } else if (!code) {
    return false;
  }

  return true;
};

export const formatNumber = (num) => {
  return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : num;
};

export const formatCurrency = (num) => {
  return num ? num.toLocaleString("en-US") : num;
};

export const formatDateTime = (datetime) => {
  return datetime ? moment(datetime).format("YYYY-MM-DD HH:mm:ss") : "";
};

export const getKtvLabel = (ktv_id, ktv_list) => {
  if (ktv_list && ktv_list.length > 0) {
    const result = ktv_list.find((x) => x.value === ktv_id);
    return result ? result.label : ktv_id;
  } else {
    return ktv_id;
  }
};

export const getKtvLabelFromModel = (ktv) => {
  if (!ktv.name) {
    return "";
  }
  return ktv.asia_id
    ? `${ktv.asia_id.toString().padStart(0, 4)}-${ktv.id}-${ktv.name}`
    : `${ktv.id}-${ktv.name}`;
};

export const getShortenName = (name) => {
  const split_name = name.split(" ");
  let formattedName = "";
  for (let i = 0; i < split_name.length - 1; i++) {
    if (split_name[i] !== "") formattedName += `${split_name[i].slice(0, 1)}.`;
  }
  formattedName += split_name[split_name.length - 1];
  return formattedName;
};

export const mapSnackbarBackground = (type) => {
  switch (type) {
    case "success":
      return "#43A047";
    case "failed":
      return "#D32F2F";
    case "warning":
      return "#FFA000";
    default:
      return "#1976D2";
  }
};

export const mapSnackbarIcon = (type) => {
  switch (type) {
    case "success":
      return "fas fa-check-circle";
    case "failed":
      return "fas fa-exclamation-circle";
    case "warning":
      return "fas fa-exclamation-triangle";
    default:
      return "fas fa-info-circle";
  }
};

export const mapVehicleReportShipMethodType = (key) => {
  switch (key) {
    case "xe_tai_pv":
      return "Xe tải Phong Vũ";
    case "3pl":
      return "CPN";
    case "shipper_pv":
      return "Shipper PV";
    case "ktv":
      return "Kĩ thuật viên";
    case "dai_ly":
      return "Đại lý";
    case "others":
      return "Khác";
    default:
      return key;
  }
};

export const getReportCycleRange = (type) => {
  switch (type) {
    case "day":
      return [1, 31];
    case "month":
      return [1, 12];
    case "trimester":
      return [1, 4];
    case "year":
      return [1, 10];
  }
};

/**
 * @returns boolean
 */
export const isMaxTrustUser = () => {
  const currentSellerId = Number(window?.userInfo?.meta_data?.seller_id);
  return (
    window.MAX_TRUST_SELLER_ID === currentSellerId &&
    MAX_TRUST_DOMAINS.includes(window.location.origin)
  );
};
