import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid, Paper, CircularProgress } from "@material-ui/core";
import DatePicker from "react-date-picker";
import { changeFilter, onSearch } from "./actions";

const styles = (theme) => ({
  loadingProgress: {
    color: "white",
    marginRight: 15,
  },
  paper: {
    borderRadius: "1rem !important",
    padding: "2rem 1rem 2rem 1rem !important",
  },
  "@global": {
    ".react-date-picker__wrapper": {
      borderTop: "thin solid #80808054",
      borderRight: "none",
      borderBottom: "thin solid #80808054",
      borderLeft: "none",
      padding: "0.3rem 0.4rem",
    },
    ".rowStretch": {
      display: "flex",
      alignItems: "stretch",
      height: 38,
    },
    ".rowStretch > div.dateRangeLabel": {
      flex: 1,
    },
    ".dateRangeLabel": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "#324b7814",
      borderTop: "thin solid #80808054",
      borderBottom: "thin solid #80808054",
    },
    ".dateRangeFrom": {
      borderRadius: "5px 0 0 5px",
      borderLeft: "thin solid #80808054",
    },
    ".dateRangeTo > .react-date-picker__wrapper": {
      borderRadius: "0 5px 5px 0",
      borderRight: "thin solid #80808054",
    },
  },
});

class ShipperSummaryFuelCostReport extends React.Component {
  setFilter = async (obj) => {
    const { filter, changeFilter } = this.props;
    await changeFilter({
      ...filter,
      ...obj,
    });
  };

  handleStartDateChange = (e) => {
    const { filter } = this.props;
    this.setFilter({
      start_date: e,
      end_date: e > filter.end_date ? e : filter.end_date,
    });
  };

  render() {
    const { filter, onSearch, loading, classes } = this.props;
    return (
      <Grid item xs={12}>
        <Paper className={`filterContainer ${classes.paper}`}>
          <Grid
            container
            alignItems="flex-end"
            className="filterRow"
            spacing={1}
          >
            <Grid item xs={12}>
              <span className="title">BÁO CÁO PHÍ XĂNG XE TỔNG HỢP</span>
            </Grid>
            <Grid item xs={4} md={4}>
              <span className="label ">Thời gian báo cáo</span>
              <div className="rowStretch">
                <div className="dateRangeLabel dateRangeFrom">Từ </div>
                <DatePicker
                  value={filter.start_date}
                  showLeadingZeros
                  onChange={this.handleStartDateChange}
                  locale="vi-VN"
                  calendarIcon={null}
                  clearIcon={null}
                />
                <div className="dateRangeLabel">đến</div>
                <DatePicker
                  className="dateRangeTo"
                  value={filter.end_date}
                  minDate={filter.start_date}
                  showLeadingZeros
                  onChange={(e) => this.setFilter({ end_date: e })}
                  locale="vi-VN"
                  calendarIcon={null}
                  clearIcon={null}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                className="searchBtn"
                onClick={onSearch}
                disabled={loading}
              >
                {loading && (
                  <CircularProgress
                    size={20}
                    className={classes.loadingProgress}
                  />
                )}
                Xuất excel
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  filter: state.admin.ShipperSummaryFuelCostReport.filter,
  loading: state.admin.ShipperSummaryFuelCostReport.loading,
});

const mapDispatchToProps = (dispatch) => ({
  changeFilter: (filter) => dispatch(changeFilter(filter)),
  onSearch: () => dispatch(onSearch()),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ShipperSummaryFuelCostReport)
);
