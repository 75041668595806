import React from "react";
import Select from "react-select";

class ProvinceSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      provinceList: [],
    };
  }

  componentDidMount() {
    const { province, setDefaultProvince } = this.props;
    const list = [];
    this.props.fetchArea().then((areas) => {
      areas.forEach((area) => {
        if (area.code === province) {
          setDefaultProvince(area.name);
        }
        list.push({
          value: area.code,
          label: area.name,
        });
      });
      this.setState({
        provinceList: list,
      });
    });
  }

  _handleSelectChange = (e) => {
    const label = e && e.label;
    const value = e && e.value;
    this.props.changeProvince(value, label);
  };

  render() {
    const { provinceList } = this.state;
    const { province } = this.props;
    return (
      <Select
        value={province}
        onChange={this._handleSelectChange}
        options={provinceList}
        wrapperStyle={{ flex: 1 }}
        placeholder="Tỉnh/thành"
        style={{ height: 40 }}
      />
    );
  }
}

export default ProvinceSelector;
